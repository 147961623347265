import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/address';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;

function* getLocationData() {
  const successAction = { type: actionTypes.LOCATION_DATA_SUCCESS };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(config.apiPath.locationData));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    console.log('Error fetching location data...');
  }
}

function* getUserAddress({ hideLoader }) {
  const successAction = { type: actionTypes.USER_ADDRESS_SUCCESS };
  const errorAction = { type: actionTypes.USER_ADDRESS_FAIL };
  const client = _client;
  client.setHeader();
  try {
    const data = yield call(async () => client.get(config.apiPath.userAddress));
    successAction.hideLoader = hideLoader;
    data.result.sort(a => (a.defaultAddress ? -1 : 1));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
  }
}

function* getPincodeData({ pincode }) {
  const successAction = { type: actionTypes.PINCODE_DATA_SUCCESS };
  const errorAction = { type: actionTypes.PINCODE_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.get(config.apiPath.pincodeCheck + pincode));
    successAction.result = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* updateUserAddress({ addressJson, addressId, pageName }) {
  const successAction = { type: actionTypes.UPDATE_USER_ADDRESS_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_USER_ADDRESS_FAIL };
  const loadAction = { type: actionTypes.USER_ADDRESS_LOAD };
  const client = { ..._client };
  const method = addressId ? 'put' : 'post';
  const apiUrl = `${config.apiPath.userAddress}${addressId ? `${addressId}` : ''}`;
  client.setHeader();
  try {
    const response = yield call(async () => client[method](apiUrl, addressJson));
    successAction.result = response.result;
    yield put(successAction);
    if (pageName === 'checkout') yield put(loadAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}
function* deleteUserAddress({ addressId }) {
  const successAction = { type: actionTypes.DELETE_USER_ADDRESS_SUCCESS };
  const errorAction = { type: actionTypes.DELETE_USER_ADDRESS_FAIL };
  const client = { ..._client };
  const apiUrl = `${config.apiPath.userAddress}${addressId}`;
  client.setHeader();
  try {
    const response = yield call(async () => client.delete(apiUrl));
    successAction.response = response;
    successAction.addressId = addressId;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

export function* runAddressCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.LOCATION_DATA_LOAD, getLocationData),
    takeLatest(actionTypes.USER_ADDRESS_LOAD, getUserAddress),
    takeLatest(actionTypes.PINCODE_DATA_LOAD, getPincodeData),
    takeLatest(actionTypes.UPDATE_USER_ADDRESS_LOAD, updateUserAddress),
    takeLatest(actionTypes.DELETE_USER_ADDRESS_LOAD, deleteUserAddress),
  ]);
}
