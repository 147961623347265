import {
  BOOK_APPOINTMENT,
  BOOK_APPOINTMENT_FAILURE,
  BOOK_APPOINTMENT_SUCCESS,
  RESET_BOOK_APPOINTMENT,
  BOOK_TIME,
  BOOK_TIME_FAILURE,
  BOOK_TIME_SUCCESS,
  GET_STORES,
  GET_STORES_FAILURE,
  GET_STORES_REQUEST,
  GET_STORES_SUCCESS,
  GET_TIME_SLOT,
  GET_TIME_SLOT_FAILURE,
  GET_TIME_SLOT_SUCCESS,
  STUDIO_FLOW_MODAL,
  STUDIO_FLOW_RESET,
  STUDIO_FLOW_SHOW_THANK_YOU,
  STUDIO_FLOW_TIME_LOADER,
  BOOK_TEST_YOUR_VISION,
  BOOK_TEST_YOUR_VISION_SUCCESS,
  BOOK_TEST_YOUR_VISION_FAILURE,
} from '../actionTypes/studio-flow';

export const getStudioList = payload => {
  return {
    type: GET_STORES,
    payload,
  };
};

export const getStudioListRequest = payload => {
  return {
    type: GET_STORES_REQUEST,
    payload,
  };
};

export const getStudioListSuccess = payload => {
  return {
    type: GET_STORES_SUCCESS,
    payload,
  };
};

export const getStudioListFailure = payload => {
  return {
    type: GET_STORES_FAILURE,
    payload,
  };
};

export const bookAppointment = payload => {
  return {
    type: BOOK_APPOINTMENT,
    payload,
  };
};

export const bookAppointmentSuccess = payload => {
  return {
    type: BOOK_APPOINTMENT_SUCCESS,
    payload,
  };
};

export const bookAppointmentFailure = payload => {
  return {
    type: BOOK_APPOINTMENT_FAILURE,
    payload,
  };
};

export const resetBookAppointment = () => {
  return {
    type: RESET_BOOK_APPOINTMENT,
  };
};

export const bookTestYourVisionAppointment = payload => {
  return {
    type: BOOK_TEST_YOUR_VISION,
    payload,
  };
};

export const bookTestYourVisionAppointmentSuccess = payload => {
  return {
    type: BOOK_TEST_YOUR_VISION_SUCCESS,
    payload,
  };
};

export const bookTestYourVisionAppointmentFailure = payload => {
  return {
    type: BOOK_TEST_YOUR_VISION_FAILURE,
    payload,
  };
};

export const bookTime = payload => {
  return {
    type: BOOK_TIME,
    payload,
  };
};

export const bookTimeSuccess = payload => {
  return {
    type: BOOK_TIME_SUCCESS,
    payload,
  };
};

export const bookTimeFailure = payload => {
  return {
    type: BOOK_TIME_FAILURE,
    payload,
  };
};

export const getTimeSlot = payload => {
  return {
    type: GET_TIME_SLOT,
    payload,
  };
};

export const getTimeSlotSuccess = payload => {
  return {
    type: GET_TIME_SLOT_SUCCESS,
    payload,
  };
};

export const getTimeSlotFailure = payload => {
  return {
    type: GET_TIME_SLOT_FAILURE,
    payload,
  };
};

export const showLoader = payload => {
  return {
    type: STUDIO_FLOW_TIME_LOADER,
    payload,
  };
};

export const resetStudioFLow = payload => {
  return {
    type: STUDIO_FLOW_RESET,
    payload,
  };
};

export const studioFlowModal = payload => {
  return {
    type: STUDIO_FLOW_MODAL,
    payload,
  };
};

export const showThankYouReducer = payload => {
  return {
    type: STUDIO_FLOW_SHOW_THANK_YOU,
    payload,
  };
};
