import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/checkout';
import { UPDATE_CART_COUNT } from '../actionTypes/common';
import { getPresaleInfo } from './common';
// import { SET_ORDER } from '../actionTypes/order';
import config from 'config';
import { delay } from 'redux-saga';
import apiClient from '../helpers/apiClient';
import { setCountryData } from '../helpers/countryConfig';

let _client = null;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

function* abandonLeadApi(data) {
  const client = { ..._client };
  client.setHeader();
  try {
    yield call(async () => client.post(`${config.apiPath.cartApi}/abandonedleads`, data.data));
  } catch (error) {
    console.log('Error: /abandonedleads :', error);
  }
}

function* htoLeadApi(data) {
  const client = { ..._client };
  client.setHeader();
  try {
    yield call(async () => client.post(`${config.apiPath.cartApi}/htoLeads`, data.data));
  } catch (error) {
    console.log('Error: /htoLeads :', error);
  }
}

function* getPaymentMethod({ orderId, isExchange, paymentMethod = 'cc,dc', isSalesman }) {
  const successAction = { type: actionTypes.CARTSTEP3_SUCCESS };
  if (setCountryData()?.countryCode === 'in') {
    const errorAction = { type: actionTypes.CARTSTEP3_FAIL };
    const client = { ..._client };
    const header = isExchange ? { 'X-Service-Type': 'exchange' } : null;
    client.setHeader(header);
    try {
      let param = orderId ? `?orderId=${orderId}` : '';
      if (paymentMethod) {
        param = `${param}${param ? '&' : '?'}paymentMethod=${paymentMethod}`;
      }
      if (isSalesman) {
        param = `${param}${param ? '&' : '?'}isSalesman=${isSalesman}`;
      }
      const result = yield call(async () => client.get(`${config.apiPath.paymentMethod}${param}`));
      successAction.result = result;
      yield put(successAction);
    } catch (error) {
      errorAction.error = error;
      yield put(errorAction);
    }
  } else {
    successAction.result = { result: null };
    yield delay(1000);
    yield put(successAction);
  }
}

function* orderPayment(data) {
  const successAction = { type: actionTypes.PAYMENT_SUCCESS };
  const errorAction = { type: actionTypes.PAYMENT_FAIL };
  const client = { ..._client };
  const header = data.isExchange ? { 'X-Service-Type': 'exchange' } : null;
  client.setHeader(header);
  try {
    const result = yield call(async () =>
      _client.customPost(
        config.apiPath.orderPayment,
        data.orderPaymentData,
        'X-Session-Token',
        xApiClient,
        'nocache'
      )
    );
    successAction.result = result;
    yield put(successAction);
    yield put({ type: UPDATE_CART_COUNT, data: 0 });

    // need changes in success
    // const dataResult = { result: result.result.order, status: result.status };
    // yield put({ type: SET_ORDER, orderDetails: dataResult });
  } catch (error) {
    console.log(error);
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* saveShippingAddress(action) {
  const successAction = { type: actionTypes.SAVE_SHIPPING_ADDRESS_SUCCESS };
  const errorAction = { type: actionTypes.SAVE_SHIPPING_ADDRESS_FAIL };
  const client = { ..._client };
  const header = action.isExchange ? { 'X-Service-Type': 'exchange' } : null;
  client.setHeader(header);
  try {
    const response = yield call(async () =>
      client.post(config.apiPath.saveShippingAddress, action.data)
    );
    successAction.result = response;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getDeliveryOptions({ postcode, country }) {
  const successAction = { type: actionTypes.GET_DELIVERY_OPTIONS_SUCCESS };
  const errorAction = { type: actionTypes.GET_DELIVERY_OPTIONS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () =>
      client.get(`${config.apiPath.checkDeliveryOption}${postcode}&shippingCountry=${country}`)
    );
    successAction.result = result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* updateDeliveryOption({ data }) {
  const successAction = { type: actionTypes.GET_CART_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CART_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.post(
        `${config.apiPath.updateDeliveryOption}${data.type}?pincode=${data.postcode}&shippingCountry=${data.country}`
      )
    );
    successAction.result = response;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getCaptcha() {
  const successAction = { type: actionTypes.GET_CAPTCHA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CAPTCHA_FAIL };
  const client = { ..._client };
  client.setHeader();
  delete client.defaults.headers['X-Session-Token']; // JUNO-8870
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.captchaUrl}?v=${Date.now()}`)
    );
    successAction.result = response && response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

// Bin Series
function* getCardOffers({ cardNumber }) {
  const successAction = { type: actionTypes.GET_CARDOFFER_SUCCESS };
  const errorAction = { type: actionTypes.GET_CARDOFFER_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.getCardOffers}?cardNumber=${cardNumber}`)
    ); // ${config.apiPath.captchaUrl}?v=${Date.now()}
    successAction.result = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getOffers() {
  const successAction = { type: actionTypes.GET_OFFERS_SUCCESS };
  const errorAction = { type: actionTypes.GET_OFFERS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.get(config.apiPath.paymentOffers));
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* preSalesUserLogin({ data }) {
  const successAction = { type: actionTypes.PRE_SALES_USER_LOGIN_SUCCESS };
  const errorAction = { type: actionTypes.PRE_SALES_USER_LOGIN_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.post(config.apiPath.preSalesDualLogin, data));
    successAction.data = response.result;
    yield put(successAction);
    yield fork(getPresaleInfo);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* validateVpa({ data }) {
  const successAction = { type: actionTypes.VALIDATE_VPA_SUCCESS };
  const errorAction = { type: actionTypes.VALIDATE_VPA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.post(config.apiPath.validateVpa, data));
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = config.apiPath.validateVpa;
    yield put(errorAction);
  }
}

function* getUpiTransStatus({ orderId }) {
  const successAction = { type: actionTypes.GET_TRANS_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.GET_TRANS_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.upiTransactionStatus}${orderId}/UPI/status`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* initiateJusPaySdk(data) {
  const { payload } = data;
  const { orderId } = payload || {};
  const successAction = { type: actionTypes.CHECKOUT_INITIATE_JUSPAY_SUCCESS };
  const errorAction = { type: actionTypes.CHECKOUT_INITIATE_JUSPAY_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const url = orderId
      ? `${config.apiPath.juspaySdkInitiate}?orderId=${orderId}`
      : config.apiPath.juspaySdkInitiate;
    const response = yield call(async () => client.get(url));
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getJusPaymentStatus(data) {
  const { payload } = data;
  const { orderId } = payload;
  const successAction = { type: actionTypes.GET_JUSPAYMENT_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.GET_JUSPAYMENT_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.qrPaymentsApi}${orderId}/juspay/status`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

export function* runCheckoutCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.LOAD_ABANDON_LEAD, abandonLeadApi),
    takeLatest(actionTypes.LOAD_HTO_LEAD, htoLeadApi),
    takeLatest(actionTypes.CARTSTEP3_LOAD, getPaymentMethod),
    takeLatest(actionTypes.LOAD_PAYMENT_SUCCESS, orderPayment),
    takeLatest(actionTypes.CHECKOUT_INITIATE_JUSPAY, initiateJusPaySdk),
    takeLatest(actionTypes.SAVE_SHIPPING_ADDRESS, saveShippingAddress),
    takeLatest(actionTypes.GET_DELIVERY_OPTIONS, getDeliveryOptions),
    takeLatest(actionTypes.UPDATE_DELIVERY_OPTION, updateDeliveryOption),
    takeLatest(actionTypes.GET_CAPTCHA, getCaptcha),
    takeLatest(actionTypes.GET_CARDOFFER, getCardOffers),
    takeLatest(actionTypes.GET_OFFERS_LOAD, getOffers),
    takeLatest(actionTypes.PRE_SALES_USER_LOGIN, preSalesUserLogin),
    takeLatest(actionTypes.VALIDATE_VPA, validateVpa),
    takeLatest(actionTypes.GET_TRANS_STATUS_LOAD, getUpiTransStatus),
    takeLatest(actionTypes.GET_JUSPAYMENT_STATUS_LOAD, getJusPaymentStatus),
  ]);
}
