import React from 'react';
import './Articles.scss';
import { ARTICLES } from './Articles';
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';

const Articles = () => {
  const crumbs = [
    { label: 'Homepage', path: '/sg/en/' },
    { label: 'All Tips', path: '/sg/en/articles' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <div className="articles">
        <div className="articles__container">
          <h2 className="articles__title">OWNDAYS Tips</h2>
          <ul className="articles__list">
            {ARTICLES?.map(article => (
              <li key={article?.permalink}>
                <a href={`/sg/en/articles/${article?.permalink}`}>
                  <img src={article?.img} alt={article?.title} loading="lazy" />
                  <h3>{article?.title}</h3>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Articles;
