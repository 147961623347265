import React from 'react'
import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs'

const ArticleDetailsBreadcrumb = ({article}) => {
  const crumbs = [
    { label: 'Homepage', path: '/sg/en/' },
    { label: 'All Tips', path: '/sg/en/articles' },
    { label: `${article.title}`, path: `/sg/en/articles/${article.permalink}` },
  ];

  return (
    <Breadcrumbs crumbs={crumbs} />
  )
}

export default ArticleDetailsBreadcrumb