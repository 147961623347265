import React from 'react';

const NOTE = [
  {
    title: 'Pre-Consultation',
    text: 'It is a must to go through a pre-consultation with an Eye Care Professional before any purchase.',
  },
  {
    title: 'Proper use of contact lenses',
    text: `Please read through the Product Instruction Guide to understand it fully before using the lenses.<br/>
The recommended wearing schedule varies for each individual. Please consult your Eye Care Professional.<br/>
Daily contact lenses are meant for one-time daily use only. Do not re-use them upon removal.<br/>
Monthly contact lenses expire 30 days after first opening. Do not re-use them after 30 days from first opening.`,
  },
  {
    title: 'Regular eye check',
    text: 'To ensure good eye health, we recommend that you go for an <a href="https://www.owndays.com/stores/sg">eye test</a> every half yearly.',
  },
  {
    title: 'Proper lens care',
    text: `Improper handling of lenses may cause irritation and infections to the eye.<br/>
Always practise proper care when handling your contact lenses.`,
  },
];

const FooterContacts = () => {
  return (
    <>
      <section className="contacts-page__htu">
        <div className="contacts-page__container">
          <h2 className="contacts-page__heading">HOW TO USE</h2>
          <img
            className="contacts-page__htu-img"
            loading="lazy"
            src="https://static1.lenskart.com/media/owndays/img/contacts/eye.webp"
            alt="Eye icon"
          />
          <p className="contacts-page__htu-text">
            Correct way of using OWNDAYS CLEAR VISION contact lenses.
            <br />
            To protect the health of your eyes, here’s a guide on how to use the contact lenses
            correctly.
          </p>
          <a className="contacts-page__btn" href="/sg/en/contacts/clear-contactlens/how-to-use">
            VIEW MORE
          </a>
        </div>
      </section>
      <section className="contacts-page__sec">
        <div className="contacts-page__container">
          <p>
            For those with astigmatism, it is crucial to consult with an Eye Care Professional to
            determine the best contact lens options available. OWNDAYS Clear Vision is our premium
            in-house contact lens line. With our commitment to quality and choice of materials,
            users can trust in the reliability and durability of our contact lenses. Each pair is
            crafted with meticulous attention to detail, prioritizing both comfort and clarity,
            ensuring a seamless fit for all-day wear. Whether users have myopia or astigmatism, our
            Clear Vision lenses offer comfort and quality performance, allowing them to enjoy clear,
            crisp vision throughout their day.
          </p>
        </div>
      </section>
      <section className="contacts-page__note">
        <div className="contacts-page__container">
          <h2 className="contacts-page__heading">NOTE</h2>
          <p className="contacts-page__note-text">
            Contact lenses are considered medical devices. With proper handling and use, you will be
            able to enjoy a comfortable wearing experience. Please take note of the following when
            using the lenses.
          </p>
          {NOTE && (
            <ul className="contacts-page__note-list">
              {NOTE.map((note, index) => (
                <li key={note}>
                  <h3>
                    {index + 1}. {note.title}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: note.text }} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default FooterContacts;
