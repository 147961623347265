import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/auth';
import * as commonActionTypes from '../actionTypes/common';
import { RESET_CART as LOGOUT_USER_CART } from '../actionTypes/cart';
import { LOGOUT_USER as LOGOUT_USER_CHECKOUT } from '../actionTypes/checkout';
import apiClient from '../helpers/apiClient';
import config from 'config';
import getSetCookie from '../cookie';
import localStorageHelper from '../utils/localStorageHelper';
import { CLEAR_STATE as LOGOUT_USER_MYACCOUNT } from '../actionTypes/myAccount';
import { RESET_ORDERDETAILS } from '../actionTypes/order';
import { RESET_USER_SAVED_CARDS } from '../actionTypes/savedCards';
import { RESET_ADDRESS } from '../actionTypes/address';

let _client = null;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

function convertToDateString(originalDate) {
  // Create a new Date object using the original date string
  const dateObject = new Date(originalDate);

  // Extract the date components
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, '0');
  const day = String(dateObject.getDate()).padStart(2, '0');

  // Create the new date string with the desired format
  const convertedDate = `${year}-${month}-${day}`;

  return convertedDate;
}

function* updateCustomer({ data }) {
  const successAction = { type: actionTypes.UPDATE_CUSTOMER_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_CUSTOMER_FAIL };

  const client = { ..._client };
  client.setHeader();

  try {
    const response = yield call(async () =>
      client.post(config.apiPath.updateCustomer, { ...data })
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* registerUser({ data }) {
  const successAction = { type: actionTypes.REGISTER_SUCCESS };
  const commonSuccessAction = { type: commonActionTypes.LOGIN_SUCCESS };
  const errorAction = { type: actionTypes.REGISTER_FAIL };
  try {
    const response = yield call(async () =>
      _client.customPost(
        config.apiPath.registerCustomer,
        { ...data, dob: `${convertToDateString(data.dob)}T00:00:00.000Z` },
        'X-Session-Token',
        xApiClient,
        null
      )
    );
    successAction.data = response;
    getSetCookie.deleteCookie('clientV1');
    // console.log('clientV1 auth.js registerUser')
    getSetCookie.setCookie('clientV1', response.result.token, 30);
    getSetCookie.deleteCookie('isLogined');
    getSetCookie.setCookieWithDomain('isLogined', 1, 7889400000);
    getSetCookie.setCookie('log_in_status', 'loggedin', 30);
    yield put(successAction);
    yield put(commonSuccessAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* authenticateUser({ data }) {
  const successAction = { type: actionTypes.LOGIN_SUCCESS };
  const commonSuccessAction = { type: commonActionTypes.LOGIN_SUCCESS };
  const customerSuccessAction = { type: commonActionTypes.GET_USER_SUCCESS };
  const errorAction = { type: actionTypes.LOGIN_FAIL };
  const requestUrl = __SERVER__ ? config.apiPath.albGetUserInfo : config.apiPath.getUserInfo;
  const client = { ..._client };
  try {
    const response = yield call(async () =>
      client.customPost(config.apiPath.userAuthenticate, data, 'X-Session-Token', xApiClient, null)
    );
    successAction.data = response;
    getSetCookie.deleteCookie('clientV1');
    // console.log('clientV1 auth.js authenticateUser')
    getSetCookie.setCookie('clientV1', response.result.token, 30);
    getSetCookie.deleteCookie('isLogined');
    getSetCookie.setCookieWithDomain('isLogined', 1, 7889400000);
    getSetCookie.setCookie('log_in_status', 'loggedin', 30);
    if (response?.result?.cygnus?.cygnusId)
      getSetCookie.setCookie('isDittoID', response?.result?.cygnus?.cygnusId);
    getSetCookie.deleteCookie('guest_dittoid');
    client.setHeader();
    const userInfoResponse = yield call(async () => client.get(requestUrl));
    getSetCookie.setCookie('hasPlacedOrder', userInfoResponse.result.hasPlacedOrder, 30);
    customerSuccessAction.data = userInfoResponse;
    customerSuccessAction.data.result.login = true;
    yield put(successAction);
    yield put(customerSuccessAction);
    yield put(commonSuccessAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* validateUserInput({ data }) {
  const successAction = { type: actionTypes.USER_INPUT_SUCCESS };
  const errorAction = { type: actionTypes.USER_INPUT_FAIL };
  const client = _client;
  client.setHeader();
  let params = {};
  if (data.validBlock === 'email') {
    params = { email: data.username, isMobileUserOnly: false };
  }
  if (data.validBlock === 'mobile') {
    params = { telephone: data.username, isMobileUserOnly: true, phoneCode: data.phoneCode };
  }
  try {
    const response = yield call(async () =>
      client.get(config.apiPath.mobileLoginAccountInfo, { params })
    );
    successAction.data = response;
    successAction.data.result.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* sendValidateOtp({ data }) {
  const successAction = { type: actionTypes.USER_OTP_SUCCESS };
  const errorAction = { type: actionTypes.USER_OTP_FAIL };
  const client = _client;
  client.setHeader();
  const requestUrl =
    data.type === 'SEND_OTP'
      ? config.apiPath.mobileLoginSendOTP
      : config.apiPath.mobileLoginAuthenticateMobileUser;
  const dataModify =
    data.type === 'SEND_OTP'
      ? {
          telephone: data.data.username,
          captcha: data.captchaKey ? data.captchaKey : null,
          phoneCode: data.phoneCode,
        }
      : { telephone: data.username, code: data.code, phoneCode: data.phoneCode };
  try {
    const response = yield call(async () => client.post(requestUrl, dataModify));
    successAction.data = response;
    successAction.data.result.data = data;
    if (data.type === 'VALIDATE_OTP') {
      getSetCookie.deleteCookie('clientV1');
      getSetCookie.deleteCookie('frontend');
      // console.log('clientV1 auth.js sendValidateOtp')
      getSetCookie.setCookie('clientV1', response.result.token, 30);
      getSetCookie.deleteCookie('isLogined');
      getSetCookie.setCookieWithDomain('isLogined', 1, 7889400000);
      getSetCookie.setCookie('log_in_status', 'loggedin', 30);
      if (response?.result?.cygnus?.cygnusId)
        getSetCookie.setCookie('isDittoID', response?.result?.cygnus?.cygnusId);
      getSetCookie.deleteCookie('guest_dittoid');
    }
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* forgotPasswordAction({ data }) {
  const successAction = { type: actionTypes.FORGET_PASSWORD_SUCCESS };
  const errorAction = { type: actionTypes.FORGET_PASSWORD_FAIL };
  const client = _client;
  try {
    const payloadData = { emailAddress: data.email };
    const response = yield call(async () =>
      client.customPost(
        config.apiPath.forgotPasswordToken,
        payloadData,
        'X-Session-Token',
        xApiClient,
        null
      )
    );
    successAction.data = response;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* socialAuth({ data, account }) {
  const successAction = { type: actionTypes.SOCIAL_SUCCESS };
  const commonSuccessAction = { type: commonActionTypes.LOGIN_SUCCESS };
  const errorAction = { type: actionTypes.SOCIAL_FAIL };
  const client = _client;
  try {
    const response = yield call(async () =>
      client.customPost(
        config.apiPath.socialAuth + account,
        data,
        'X-Session-Token',
        xApiClient,
        null
      )
    );
    getSetCookie.deleteCookie('clientV1');
    // console.log('clientV1 auth.js socialAuth')
    getSetCookie.deleteCookie('frontend');
    getSetCookie.setCookie('clientV1', response.result.token, 30);
    getSetCookie.deleteCookie('isLogined');
    getSetCookie.setCookieWithDomain('isLogined', 1, 7889400000);
    getSetCookie.setCookie('log_in_status', 'loggedin', 30);
    successAction.data = response.result;
    yield put(successAction);
    yield put(commonSuccessAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* fetchEmailForMobile({ number }) {
  const successAction = { type: actionTypes.FETCH_EMAILS_SUCCESS };
  const errorAction = { type: actionTypes.FETCH_EMAILS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.get(config.apiPath.mobileEmailFetch + number));
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* linkEmailToMobile({ email }) {
  const successAction = { type: actionTypes.LINK_EMAIL_SUCCESS };
  const errorAction = { type: actionTypes.LINK_EMAIL_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.post(config.apiPath.mobileEmailLink, { email }));
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* logoutUser() {
  console.log('inside logout user =====>');
  getSetCookie.deleteCookie('isLogined');
  getSetCookie.deleteCookie('log_in_status');
  getSetCookie.deleteCookie('guest_dittoid');
  getSetCookie.deleteCookie('showMeDitto');
  getSetCookie.deleteCookie('dittoid');
  getSetCookie.deleteCookie('isDittoID');
  getSetCookie.deleteCookie('dittoSignature');
  getSetCookie.deleteCookie('dittoAccessID');
  getSetCookie.deleteCookie('hasPlacedOrder');
  yield put({ type: commonActionTypes.LOGOUT_USER });
  yield put({ type: LOGOUT_USER_CART });
  yield put({ type: LOGOUT_USER_CHECKOUT });
  yield put({ type: LOGOUT_USER_MYACCOUNT, values: { persistOrder: null } });
  yield put({ type: RESET_ORDERDETAILS });
  yield put({ type: RESET_USER_SAVED_CARDS });
  yield put({ type: RESET_ADDRESS });
  const client = { ..._client };
  client.setHeader();
  try {
    console.log('inside logout user =====> try');
    console.log('inside logout user =====> url', config.apiPath.logOut);
    const response = yield call(async () => client.post(config.apiPath.logOut));
    const newSessionToken = response && response.result.id;
    console.log('inside logout user =====> new session token', newSessionToken);
    getSetCookie.deleteCookie('clientV1');
    if (newSessionToken) {
      getSetCookie.deleteCookie('frontend');
      // console.log('clientV1 auth.js logoutUser')
      // getSetCookie.setCookie('clientV1', newSessionToken);
    }
  } catch (er) {
    console.log('inside logout user =====> catch', er);
    console.log(er);
  }
}

function* mergeMobileWithEmail({ data }) {
  const successAction = { type: actionTypes.MERGE_MOBILE_EMAIL_SUCCESS };
  const errorAction = { type: actionTypes.MERGE_MOBILE_EMAIL_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.post(config.apiPath.verifyOtp, data));
    getSetCookie.deleteCookie('isLogined');
    getSetCookie.setCookieWithDomain('isLogined', 1, 7889400000);
    getSetCookie.setCookie('log_in_status', 'loggedin', 30);
    const userDetails = localStorageHelper.getItem('userDetails') || {};
    userDetails.emailVerificationStatus = response.result.emailVerificationStatus;
    userDetails.isTelephoneVerified = true;
    localStorageHelper.setItem('userDetails', userDetails);
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runAuthCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.REGISTER_LOAD, registerUser),
    takeLatest(actionTypes.USER_INPUT_LOAD, validateUserInput),
    takeLatest(actionTypes.USER_OTP_LOAD, sendValidateOtp),
    takeLatest(actionTypes.LOGIN_LOAD, authenticateUser),
    takeLatest(actionTypes.FORGET_PASSWORD_LOAD, forgotPasswordAction),
    takeLatest(actionTypes.SOCIAL_LOAD, socialAuth),
    takeLatest(actionTypes.FETCH_EMAILS_LOAD, fetchEmailForMobile),
    takeLatest(actionTypes.LINK_EMAIL_LOAD, linkEmailToMobile),
    takeLatest(actionTypes.LOGOUT_USER, logoutUser),
    takeLatest(actionTypes.MERGE_MOBILE_EMAIL_LOAD, mergeMobileWithEmail),
    takeLatest(actionTypes.UPDATE_CUSTOMER_LOAD, updateCustomer),
  ]);
}
