// import { Primer } from '@primer-io/checkout-web';
import { debounce } from './helper';

const cardHolderInputId = 'checkout-card-holder-input';
const cardNumberInputId = 'checkout-card-number-input';
const cardExpiryInputId = 'checkout-card-expiry-input';
const cardCvvInputId = 'checkout-card-cvv-input';
const buttonId = 'submit-button';
const validateButtonId = 'validate-button-primer';
const primer3dsWrapperId = 'primer-checkout-scene-3ds';

const fieldStyles = {
  desktop: {
    fontFaces: [
      {
        fontFamily: 'NeuzeitGroReg',
        src: 'url("https://static.lenskart.com/media/owndays/desktop/fonts/NeuzeitGro-Reg/NeuzeitGro-Reg.ttf") format("truetype")',
        fontDisplay: 'swap',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    ],
    input: {
      base: {
        height: '24px',
        border: 'none',
        padding: '0',
        color: '#444',
        fontFamily: '"NeuzeitGroReg", sans-serif',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        backgroundColor: 'transparent',
        outline: 'none',
        placeholder: {
          opacity: '0.2',
        },
      },
    },
  },
  mobile: {
    fontFaces: [
      {
        fontFamily: 'NeuzeitGroReg',
        src: 'url("https://static.lenskart.com/media/owndays/desktop/fonts/NeuzeitGro-Reg/NeuzeitGro-Reg.ttf") format("truetype")',
        fontDisplay: 'swap',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
    ],
    input: {
      base: {
        height: '52px',
        width: '100%',
        border: 'none',
        padding: '0',
        color: '#444',
        fontFamily: '"NeuzeitGroReg", sans-serif',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '52px',
        backgroundColor: '#FAFAFA',
        outline: 'none',
        placeholder: {
          opacity: '0.5',
        },
      },
    },
  },
};

const fieldPlaceholders = {
  desktop: {
    cardNumberInput: '0000 0000 0000 0000',
    expiryInput: 'mm/yy',
    cvvInput: '000',
  },
  mobile: {
    cardNumberInput: 'Card number',
    expiryInput: 'Expiry',
    cvvInput: 'CVV',
  },
};

const clientDevice = process.env.CLIENT_TYPE;

export const initPrimerHeadless = async params => {
  const { token, onAvailablePaymentMethodsLoad, onCheckoutComplete, onCheckoutFail } = params || {};
  const { Primer } = window || {};

  const headless = await Primer.createHeadless(token);
  // Configure headless
  headless.configure({
    card: {
      cardholderName: { required: true },
      cardNumber: { required: true },
      expiryDate: { required: true },
      cvv: { required: true },
    },
    onAvailablePaymentMethodsLoad(paymentMethods) {
      if (onAvailablePaymentMethodsLoad) onAvailablePaymentMethodsLoad(paymentMethods);
    },
    onCheckoutComplete(param) {
      // Notifies you that a payment was created
      // Move on to next step in your checkout flow:
      // e.g. Show a success message, giving access to the service, fulfilling the order, ...
      if (onCheckoutComplete) onCheckoutComplete(param);
    },
    onCheckoutFail(param) {
      // Notifies you that the checkout flow has failed and a payment could not be created
      // This callback can also be used to display an error state within your own UI.
      if (onCheckoutFail) onCheckoutFail(param);
    },
  });
  return headless;
};

function createCardFormUI() {
  const container = document.getElementById('my-container');
  const cardHolderInputEl = document.getElementById(cardHolderInputId);
  const cardNumberInputEl = document.getElementById(cardNumberInputId);
  const cardExpiryInputEl = document.getElementById(cardExpiryInputId);
  const cardCvvInputEl = document.getElementById(cardCvvInputId);
  const submitButton = document.getElementById(buttonId);
  if (container)
    container.append(
      cardNumberInputEl,
      cardExpiryInputEl,
      cardCvvInputEl,
      cardHolderInputEl,
      submitButton
    );
}

async function configureCardForm(params) {
  const { headless, orderPayment, primerToken, device, orderId, consent } = params || {};
  // Create the payment method manager
  const cardManager = await headless.createPaymentMethodManager('PAYMENT_CARD');
  // Create the hosted inputs
  const { cardNumberInput, expiryInput, cvvInput } = cardManager.createHostedInputs();
  await Promise.all([
    cardNumberInput.render(cardNumberInputId, {
      placeholder: fieldPlaceholders[clientDevice].cardNumberInput,
      ariaLabel: 'Card Number',
      style: fieldStyles[clientDevice],
    }),
    expiryInput.render(cardExpiryInputId, {
      placeholder: fieldPlaceholders[clientDevice].expiryInput,
      ariaLabel: 'Expiry date',
      style: fieldStyles[clientDevice],
    }),
    cvvInput.render(cardCvvInputId, {
      placeholder: fieldPlaceholders[clientDevice].cvvInput,
      ariaLabel: 'CVV',
      style: fieldStyles[clientDevice],
    }),
  ]);

  function validationError(args, type, preSubmit) {
    const setErrorText = (fieldId, errorId, errorText) => {
      const field = document.getElementById(fieldId);
      const errorField = document.getElementById(errorId);
      if (field) {
        field.innerText = errorText;
      }
      if (errorField) {
        errorField.innerText = errorText || '';
      }
    };

    switch (type) {
      case 'cardNumber': {
        if (preSubmit) {
          setErrorText(null, 'cardNumberRequiredError', args.error);
        } else {
          setErrorText('cardNumberRequired', 'cardNumberRequiredError', args.error);
        }
        break;
      }
      case 'expiry': {
        if (preSubmit) {
          setErrorText(null, 'expiryRequiredError', args.error);
        } else {
          setErrorText('expiryRequired', 'expiryRequiredError', args.error);
        }
        break;
      }
      case 'cvv': {
        if (preSubmit) {
          setErrorText(null, 'cvvRequiredError', args.error);
        } else {
          setErrorText('cvvRequired', 'cvvRequiredError', args.error);
        }
        break;
      }
      default:
        break;
    }
  }

  function validationCardName(val = '') {
    let isValid = true;
    const cardnameRequiredField = document.getElementById('cardnameRequired');
    const cardnameRequiredError = document.getElementById('cardnameRequiredError');
    if (cardnameRequiredField && val.trim().length === 0) {
      cardnameRequiredField.innerText = 'Card Name is Required';
      isValid = false;
    } else if (cardnameRequiredField && val.trim().length > 0) {
      if (/[^a-zA-Z\ \/]/.test(val.trim())) {
        cardnameRequiredField.innerText = 'Card Name is Invalid';
        cardnameRequiredError.innerText = 'Card Name is Invalid';
        isValid = false;
      } else {
        cardnameRequiredField.innerText = '';
        cardnameRequiredError.innerText = '';
        isValid = true;
      }
    }
    return isValid;
  }

  // Set the cardholder name if it changes
  document.getElementById(cardHolderInputId).addEventListener('change', e => {
    cardManager.setCardholderName(e.target.value);
    validationCardName(e.target.value);
  });
  // Configure event listeners for supported events
  cardNumberInput.addEventListener(
    'change',
    debounce(args => {
      if (args?.submitted) {
        validationError(args, 'cardNumber');
      } else {
        validationError(args, 'cardNumber', true);
      }
    }, 700)
  );
  expiryInput.addEventListener(
    'change',
    debounce(args => {
      if (args?.submitted) {
        validationError(args, 'expiry');
      } else {
        validationError(args, 'expiry', true);
      }
    }, 700)
  );
  cvvInput.addEventListener(
    'change',
    debounce(args => {
      if (args.submitted) {
        validationError(args, 'cvv');
      } else {
        validationError(args, 'cvv', true);
      }
    }, 700)
  );

  // cardNumberInput.addEventListener('change', args => {
  //   console.log('CardNumber onchange : ', args)
  // });
  // expiryInput.addEventListener('change', args => {
  //   console.log('Expiry onchange : ', args)
  // });
  // cvvInput.addEventListener('change', args => {
  //   console.log('CVV onchange : ', args)
  // });

  cardNumberInput.focus();

  const validateButton = document.getElementById(validateButtonId);
  if (validateButton)
    validateButton.addEventListener('click', async () => {
      // Validate your card input data
      const { valid } = await cardManager.validate();
      const cardNameInputEle = document.getElementById(cardHolderInputId);
      if (Boolean(cardNameInputEle) && validationCardName(cardNameInputEle?.value) && valid) {
        // JUNO order creation call
        const reqData = {
          device,
          paymentInfo: {
            primerClientSessionToken: primerToken,
            gatewayId: 'PRIMER',
            paymentMethod: 'primer',
            saveCard: false,
          },
        };
        if (orderId) reqData.orderId = orderId;
        if (consent) reqData.consent = consent;
        if (orderPayment && primerToken) orderPayment(reqData);
      }
    });

  const submitButton = document.getElementById(buttonId);
  if (submitButton)
    submitButton.addEventListener('click', async () => {
      // Validate your card input data
      const { valid } = await cardManager.validate();
      if (valid) {
        // Submit the card input data to Primer for tokenization
        await cardManager.submit();

        // To make 3ds screen on top
        setTimeout(() => {
          let countSec = 0;
          const interval = setInterval(MoveToTop3ds, 1000); // 1000 ms = start after 1sec
          function MoveToTop3ds() {
            countSec++;
            if (document.getElementById(primer3dsWrapperId)) {
              document.getElementById(primer3dsWrapperId).style.zIndex = '99999999999999';
              clearInterval(interval);
            }
            if (countSec === 10) {
              clearInterval(interval);
            }
          }
        }, 2000);
      }
    });
}

// Handle redirect payment methods (see Step 4.c)
// configureRedirectPaymentMethod(paymentMethod);
async function configureRedirectPaymentMethod(params) {
  console.log('configure redirect payment ===>', params);
  const { headless, orderPayment, primerToken, device, orderId, consent } = params || {};
  const manager = await headless.createPaymentMethodManager('ATOME');
  // const submitButton = document.createElement('input');
  const buttonId = 'submit-button';
  // const submitButton = document.getElementById(buttonId);
  // submitButton.setAttribute('type', 'button');
  // submitButton.setAttribute('id', buttonId);
  // submitButton.value = 'Submit Rdirect';

  const validateButton = document.getElementById(validateButtonId);
  if (validateButton) {
    validateButton.addEventListener('click', () => {
      // JUNO order creation call
      const reqData = {
        device,
        paymentInfo: {
          primerClientSessionToken: primerToken,
          gatewayId: 'PRIMER',
          paymentMethod: 'ATOME',
          saveCard: false,
        },
      };
      if (orderId) reqData.orderId = orderId;
      if (consent) reqData.consent = consent;
      if (orderPayment && primerToken) orderPayment(reqData);
    });
  }

  const submitButton = document.getElementById(buttonId);
  if (submitButton) {
    submitButton.addEventListener('click', async () => {
      await manager.start();
    });
  }
}

export const showPaymentMethodsUI = params => {
  const { methodToShow } = params || {};
  switch (methodToShow) {
    case 'PAYMENT_CARD': {
      // Configure your card form (see Step 4.a)
      createCardFormUI();
      configureCardForm(params);
      break;
    }
    case 'ATOME': {
      configureRedirectPaymentMethod(params);
      break;
    }
    default: {
      break;
    }
  }
};
