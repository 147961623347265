import * as actionTypes from './../../actionTypes/checkout';

const initialState = {
  checkoutBasicData: {
    address: {
      addressType: 'billing',
      addressline1: '',
      addressline2: '',
      alternatePhone: '',
      city: '',
      country: '',
      firstName: '',
      lastName: '',
      email: '',
      floor: 0,
      gender: '',
      landmark: '',
      liftAvailable: false,
      locality: '',
      phone: '',
      postcode: '',
      state: '',
      comment: '',
    },
    giftMessage: {
      from: '',
      message: '',
      to: '',
    },
  },
  paymentMethods: null,
  thirdTabKey: '',
  leadSource: false,
  preSalesInfo: null,
  checkoutStepKey: 1,
  paymentData: null,
  paymentError: null,
  frameProduct: true,
  allowCartModification: true,
  showOOS: false,
  renderBankOffer: [],
  bankOffers: null,
  offers: null,
  preSalesUserData: null,
  preSalesloading: false,
  vpaLoading: false,
  vpaStatus: null,
  transStatusLoading: false,
  orderPlaced: false,
  pmLoading: false,
  loaded: true,
  jusPaymentStatusLoading: false,
  jusPaymentStatusData: undefined,
  addNewShippingAddressClick: false,
};

export default function checkout(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_VPA:
      return Object.assign({}, prevState, {
        vpaStatus: null,
        transStatus: null,
        orderPlaced: false,
      });
    case actionTypes.GET_TRANS_STATUS_LOAD:
      return Object.assign({}, prevState, {
        transStatusLoading: true,
        transStatus: null,
        vpaStatus: null,
      });
    case actionTypes.GET_TRANS_STATUS_SUCCESS:
      return Object.assign({}, prevState, {
        transStatusLoading: false,
        transStatus: action.data.status,
        vpaStatus: null,
      });
    case actionTypes.GET_TRANS_STATUS_FAIL:
      return Object.assign({}, prevState, {
        transStatusLoading: false,
        transStatus: action.data.status,
        vpaStatus: null,
      });
    case actionTypes.CHECKOUT_INITIATE_JUSPAY:
      return Object.assign({}, prevState, { jusPayInitiated: false, jusPayData: null });
    case actionTypes.CHECKOUT_INITIATE_JUSPAY_SUCCESS:
      return Object.assign({}, prevState, { jusPayInitiated: true, jusPayData: action.data });
    case actionTypes.CHECKOUT_INITIATE_JUSPAY_FAIL:
      return Object.assign({}, prevState, { jusPayInitiated: false, jusPayData: null });
    case actionTypes.VALIDATE_VPA:
      return Object.assign({}, prevState, { vpaLoading: true });
    case actionTypes.VALIDATE_VPA_SUCCESS:
      return Object.assign({}, prevState, { vpaLoading: false, vpaStatus: action.data.isValidVpa });
    case actionTypes.VALIDATE_VPA_FAIL:
      return Object.assign({}, prevState, { vpaLoading: false, vpaStatus: action.data.isValidVpa });
    case actionTypes.PRE_SALES_USER_LOGIN:
      return Object.assign({}, prevState, { preSalesloading: true });
    case actionTypes.PRE_SALES_USER_LOGIN_SUCCESS:
      return Object.assign({}, prevState, { preSalesloading: true, preSalesUserData: action.data });
    case actionTypes.PRE_SALES_USER_LOGIN_FAIL:
      return Object.assign({}, prevState, {
        preSalesloading: false,
        preSalesUserData: action.error,
      });
    case actionTypes.CHECKOUT_STEP_KEY:
      return Object.assign({}, prevState, { checkoutStepKey: action.key });
    case actionTypes.SELECTED_PAYMENT_METHOD:
      return Object.assign({}, prevState, { selectedPaymentMethod: action.key });
    case actionTypes.SAVE_GUEST_INFO:
      return Object.assign({}, prevState, { guestInfo: action.data });
    case actionTypes.MANAGE_CHECKOUT_DATA:
      return Object.assign({}, prevState, { checkoutBasicData: action.data });
    case actionTypes.CART_MODIFICATION:
      return Object.assign({}, prevState, { allowCartModification: action.allow });
    case actionTypes.CARTSTEP3_LOAD:
      return Object.assign({}, prevState, {
        pmLoading: true,
        loaded: false,
        paymentMethodLoadError: null,
        transStatus: null,
        paymentMethods: undefined,
      });
    case actionTypes.CARTSTEP3_SUCCESS:
      return Object.assign({}, prevState, {
        pmLoading: false,
        loaded: true,
        paymentMethods: action.result.result,
        paymentMethodLoadError: null,
      });
    case actionTypes.CARTSTEP3_FAIL:
      return Object.assign({}, prevState, {
        pmLoading: false,
        loaded: true,
        paymentMethods: null,
        paymentMethodLoadError: action.error,
      });
    case actionTypes.LOAD_PAYMENT_SUCCESS:
      return Object.assign({}, prevState, { loadingPlaceOrder: true, orderPlaced: false });
    case actionTypes.PAYMENT_SUCCESS:
      return Object.assign({}, prevState, {
        loadingPlaceOrder: false,
        paymentData: action.result,
        paymentError: null,
        orderPlaced: true,
      });
    case actionTypes.PAYMENT_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        loadingPlaceOrder: false,
        paymentData: null,
        paymentError: action.error,
        orderPlaced: false,
      });
    case actionTypes.SAVE_SHIPPING_ADDRESS:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        shippingLoader: true,
        saveShippingAddress: null,
        saveShippingError: null,
      });
    case actionTypes.SAVE_SHIPPING_ADDRESS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        shippingLoader: false,
        saveShippingAddress: action.result,
        saveShippingError: null,
      });
    case actionTypes.SAVE_SHIPPING_ADDRESS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        shippingLoader: false,
        saveShippingAddress: null,
        error: action.error,
        saveShippingError: action.error,
      });
    case actionTypes.ADD_NEW_SHIPPING_ADDRESS_CLICK:
      return Object.assign({}, prevState, {
        addNewShippingAddressClick: action.data,
      });
    case actionTypes.GET_DELIVERY_OPTIONS:
      return Object.assign({}, prevState, { deliveryData: null, deliveryLoader: true });
    case actionTypes.GET_DELIVERY_OPTIONS_SUCCESS:
      return Object.assign({}, prevState, { deliveryData: action.result, deliveryLoader: false });
    case actionTypes.GET_DELIVERY_OPTIONS_FAIL:
      return Object.assign({}, prevState, { deliveryData: null, deliveryLoader: false });
    case actionTypes.SHOW_OOS:
      return Object.assign({}, prevState, { showOOS: action.val });
    case actionTypes.GET_PRESALE_INFO_SUCCESS:
      return Object.assign({}, prevState, {
        preSalesInfo: action.result,
        leadSource: Boolean(action.result && action.result.result.attrs.preSalesUserIsLoggedIn),
      });
    case actionTypes.GET_PRESALE_INFO_FAIL:
      return Object.assign({}, prevState, { preSalesInfo: null, leadSource: false });
    case actionTypes.LOGOUT_USER:
      return initialState;
    case actionTypes.GET_CAPTCHA:
      return Object.assign({}, prevState, { captcha: { ...prevState.captcha, loading: true } });
    case actionTypes.GET_CAPTCHA_SUCCESS:
      return Object.assign({}, prevState, { captcha: { loading: false, ...action.result } });
    case actionTypes.GET_CAPTCHA_FAIL:
      return Object.assign({}, prevState, { captcha: { loading: false, data: null } });
    case actionTypes.GET_CARDOFFER:
      return Object.assign({}, prevState, { loading: true });
    case actionTypes.GET_CARDOFFER_SUCCESS:
      return Object.assign({}, prevState, { loading: false, renderBankOffer: action.result });
    case actionTypes.GET_CARDOFFER_FAIL:
      return Object.assign({}, prevState, { loading: false, renderBankOffer: action.result });
    case actionTypes.GET_OFFERS_LOAD:
      return Object.assign({}, prevState, { loading: true, offers: null });
    case actionTypes.GET_OFFERS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        offers: action.data && action.data.offers,
      });
    case actionTypes.GET_OFFERS_FAIL:
      return Object.assign({}, prevState, { loading: false, offers: action.error });
    case actionTypes.GET_JUSPAYMENT_STATUS_LOAD:
      return Object.assign({}, prevState, {
        jusPaymentStatusLoading: true,
        jusPaymentStatusData: undefined,
      });
    case actionTypes.GET_JUSPAYMENT_STATUS_SUCCESS:
      return Object.assign({}, prevState, {
        jusPaymentStatusLoading: false,
        jusPaymentStatusData: action.data,
      });
    case actionTypes.GET_JUSPAYMENT_STATUS_FAIL:
      return Object.assign({}, prevState, {
        jusPaymentStatusLoading: false,
        jusPaymentStatusData: action.error,
      });
    default:
      return prevState;
  }
}
