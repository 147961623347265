import React from 'react';
import './Loader.scss';
const CommonLoader = () => {
  return (
    <div className="common-loader">
      <div className="common-loader_overlay" />
      <img
        alt="Loading"
        src="https://static.lenskart.com/media/owndays/desktop/img/od-loaders/loader-no-bg.gif"
        title="Loading"
      />
    </div>
  );
};

export default CommonLoader;
