import { createStore as _createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import scrollRestorationMiddleware from 'redux-router-scroll-restoration';
import createReducers from './reducer';
import createSagaMiddleware, { END } from 'redux-saga';
import { setCountryData } from '../helpers/countryConfig';

function combine(reducers) {
  return combineReducers(reducers);
}

function getNoopReducers(reducers, data) {
  if (!data) return {};
  return Object.keys(data).reduce(
    (prev, next) => (reducers[next] ? prev : { ...prev, [next]: (state = {}) => state }),
    {}
  );
}

export function inject(store, reducers) {
  Object.entries(reducers).forEach(([name, reducer]) => {
    if (store.asyncReducers[name]) return;
    store.asyncReducers[name] = reducer.__esModule ? reducer.default : reducer;
  });
  store.replaceReducer(combine(createReducers(store.asyncReducers)));
}

export default function createStore({ history, data }) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [routerMiddleware(history), sagaMiddleware];

  if (__CLIENT__ && __DEVELOPMENT__) {
    const logger = require('redux-logger').createLogger({
      collapsed: true,
    });
    middleware.push(logger.__esModule ? logger.default : logger);
  }

  if (__CLIENT__ && process.env.CLIENT_TYPE === 'mobile') {
    middleware.push(scrollRestorationMiddleware);
  }

  const enhancers = [applyMiddleware(...middleware)];

  if (__CLIENT__ && __DEVTOOLS__) {
    const { persistState } = require('redux-devtools');
    const DevTools = require('../DevTools/DevTools');
    Array.prototype.push.apply(enhancers, [
      window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
        : DevTools.default.instrument(),
      persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)),
    ]);
  }
  const finalCreateStore = compose(...enhancers)(_createStore);
  const reducers = createReducers();
  const noopReducers = getNoopReducers(reducers, data);
  const store = finalCreateStore(combine({ ...noopReducers, ...reducers }), data);
  const state = store.getState();
  if (state.common.localeInfo) {
    setCountryData(state.common.localeInfo);
  }
  store.asyncReducers = {};
  store.inject = _reducers => inject(store, _reducers);

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./reducer', () => {
      let reducer = require('./reducer');
      reducer = combine((reducer.__esModule ? reducer.default : reducer)(store.asyncReducers));
      store.replaceReducer(reducer);
    });
  }

  return store;
}
