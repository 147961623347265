import React, { useEffect, useState } from 'react';
import './OdRetryPayment.scss';

import SuccessGif from '../../../../../static/assets/img/success.gif';
import CartFeatureGlasses from '../../../../../static/assets/img/cart/cart-feature-glasses.png';
import CartFeatureShipping from '../../../../../static/assets/img/cart/cart-feature-shipping.png';
import CartFeatureWarranty from '../../../../../static/assets/img/cart/cart-feature-warranty.png';
import PaymentMethods from '../../../Desktop/PaymentMethods/PaymentMethods';
import { PaymentPageSubmitShimmer, PaymentPageShimmer } from '../PaymentPage/PaymentPageShimmer';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';

import { useSelector, useDispatch } from 'react-redux';
import { getQueryParam, appendScriptToDOM } from '../../../../utils/helper';
import getSetCookie from '../../../../cookie';
import {
  getClientToken,
  getPaymentMethodsWithPrimer,
  primerPaymentStatusUpdate,
} from '../../../../actionCreators/primer';
import { getOrder } from '../../../../actionCreators/order';
import { initPrimerHeadless } from '../../../../utils/primerUtils';
import { odPushGA4Event } from '../../../../utils/gaFour';
import { odPushTTQEvent } from '../../../../utils/tiktokPixel';
import { initiateJusPaySdk } from '../../../../actionCreators/checkout.js';
import apiClient from '../../../../helpers/apiClient.js';
import { apiPath } from '../../../../config.js';

const OdRetryPayment = props => {
  const { history } = props;

  let defaultOrderId = getQueryParam(props.location.search.substr(1), 'orderId');
  let email = '';
  const showFailedPaymentStrip = Boolean(getQueryParam(props.location.search.substr(1), 'oid'));

  if (!defaultOrderId && showFailedPaymentStrip) {
    defaultOrderId = __CLIENT__ ? atob(getQueryParam(props.location.search.substr(1), 'oid')) : '';
    email = __CLIENT__ ? atob(getQueryParam(props.location.search.substr(1), 'eid')) : '';

    if (__CLIENT__) localStorage.setItem('order-email', email);
  }

  const [orderId, setOrderId] = useState(defaultOrderId);
  const [headless, setHeadless] = useState(undefined);
  const [isPaymentStarted, setIsPaymentStarted] = useState(false);
  const [successOrderId, setSuccessOrderId] = useState(null);
  const [fakeLoading, setFakeLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hidePaynow, setHidePaynow] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('PRIMER_CARD');
  const [showPaymentMethods, setShowPaymentMethods] = useState(true);

  const paymentData = useSelector(state => state.checkout.paymentData);
  const userInfo = useSelector(state => state.common.userInfo);
  const {
    NO_EXTRA_CHARGE_FOR_HIGH_POWER,
    FREE_DELIVERY_NATION_WIDE,
    A_COMPLETE_GUARANTEE,
    BILL_DETAILS,
    ITEM_TOTAL,
    TAXES,
    CURRENCY_CODE,
    ORDER_TOTAL,
    PAY_NOW,
  } = useSelector(state => state.locale);
  const clientTokenData = useSelector(state => state.primer?.clientTokenData);
  const redisCommonData = useSelector(state => state.common.redisCommonData);
  const isPrimerLoading = useSelector(state => state.primer?.isLoading);
  const order = useSelector(state => state.order?.orderDetails?.result);
  const jusPayData = useSelector(state => state.checkout?.jusPayData);
  const primerPaymentStatusUpdateData = useSelector(
    state => state.primer?.primerPaymentStatusUpdateData
  );
  const clientTokenErrorData = useSelector(state => state.primer?.clientTokenErrorData);
  const currencyMark = useSelector(state => state.common.currencyMark);

  const currencySymbol = currencyMark[order?.amount?.currencyCode || CURRENCY_CODE];

  const dispatch = useDispatch();

  const initPrimerSdk = async () => {
    const getHeadless = await initPrimerHeadless({
      token: clientTokenData.result.primerClientSessionToken,
      onAvailablePaymentMethodsLoad: paymentMethods => {
        if (paymentMethods)
          dispatch(
            getPaymentMethodsWithPrimer({
              paymentMethods,
              orderId,
              redisCommonData,
              client: 'desktop',
            })
          );
      },
      createOrderPayment: self.createOrderPayment,
      onCheckoutComplete: param => {
        setIsPaymentStarted(false);
        const { id, orderId } = param?.payment || {};
        dispatch(
          primerPaymentStatusUpdate({
            paymentRefId: id,
            // paymentId: paymentData?.result?.payment?.paymentId,
            paymentId: orderId,
          })
        );
        setSuccessOrderId(orderId?.split('_')?.[0]);
      },
      onCheckoutFail: () => {
        history.push(
          `/retry-payment?oid=${window.btoa(orderId)}&eid=${window.btoa(userInfo?.result?.email)}`
        );
      },
    });

    setHeadless(getHeadless);
  };

  useEffect(() => {
    odPushGA4Event('page_view', {
      screenName: 'payment-page',
      userInfo,
    });

    // window.clevertapScreenName = 'payment-page';

    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('page_view', {
    //     screen_name: window.clevertapScreenName,
    //   });
    // }

    if (redisCommonData?.ACTIVATE_PRIMER && !window.Primer) {
      // Primer Script Load
      appendScriptToDOM(
        `https://sdk.primer.io/web/v${redisCommonData.PRIMER_VERSION}/Primer.min.js`,
        '',
        true,
        () => {
          // Juno API call to get client token from primer
          if (orderId) dispatch(getClientToken(orderId));
        }
      );
    } else if (redisCommonData?.ACTIVATE_PRIMER && window.Primer) {
      // Juno API call to get client token from primer
      if (orderId) dispatch(getClientToken(orderId));
    }

    dispatch(initiateJusPaySdk());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPaymentStarted) {
      const gaPLPSelectItemData =
        JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

      const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

      odPushGA4Event('add_payment_info', {
        userInfo,
        cartData: order,
        gaPLPSelectItemData,
        gaAddToCartData,
      });

      // odPushClevertapEvent('Proceed to payment', {
      //   userInfo,
      //   cartData: order,
      // });

      odPushTTQEvent('AddPaymentInfo', {
        userInfo,
        cartData: order,
      });
    }
  }, [isPaymentStarted]);

  useEffect(() => {
    if (!orderId) setOrderId(getSetCookie.getCookie('orderId'));

    if (orderId) {
      dispatch(getOrder({ orderId, dontInitSession: true, email }));
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false);
    }, 3000);
  }, []);

  // This useEffect will check --> is payment status is success...
  // If its success show success screen.
  useEffect(() => {
    if (primerPaymentStatusUpdateData?.result?.status === 'SUCCESS') {
      odPushTTQEvent('CompletePayment', {
        userInfo,
        cartData: order,
      });

      setIsSuccess(true);

      console.log('when order is success ===>', order);

      setTimeout(() => {
        history.push({
          pathname: '/payment-success',
          state: { cartData: order, orderId: successOrderId, paymentSuccess: true },
        });
      }, [3000]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primerPaymentStatusUpdateData]);

  useEffect(() => {
    if (clientTokenData?.result?.primerClientSessionToken) {
      initPrimerSdk();
    } else {
      // If something went wrong show message clientTokenErrorData?.message
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientTokenData]);

  useEffect(() => {
    console.log('is primer loading', isPrimerLoading, headless);
  }, [isPrimerLoading, headless]);

  useEffect(() => {
    if (headless) headless.start();
  }, [headless]);

  useEffect(() => {
    console.log('This is payment data/retrypayment ===>', paymentData);
  }, [paymentData]);

  function hyperCallbackHandler(eventData) {
    setShowPaymentMethods(false);
    try {
      if (eventData) {
        const eventJSON = typeof eventData === 'string' ? JSON.parse(eventData) : eventData;
        const event = eventJSON.event;
        // Check for event key
        if (event === 'initiate_result') {
          console.log('initiate result ====>', event);
          // Handle initiate result here
        } else if (event === 'process_result') {
          // Handle process result here
        } else if (event === 'user_event') {
          // Handle Payment Page events
        } else {
          console.log('Unhandled event', event, ' Event data', eventData);
        }
      } else {
        console.log('No data received in event', eventData);
      }
    } catch (error) {
      console.log('Error in hyperSDK response', error);
    }
  }

  const handleGrabpayPayment = () => {
    console.log('this is jus pay data ===>', jusPayData);

    if (jusPayData && jusPayData?.payload) {
      const clientId = jusPayData?.payload?.clientId;
      const merchantId = jusPayData?.payload?.merchantId;
      const environment = jusPayData?.payload?.environment;

      const payload = {
        requestId: jusPayData?.requestId,
        service: 'in.juspay.hyperpay',
        payload: {
          clientId,
        },
      };
      window.HyperServices.preFetch(payload);

      const initiatePayload = {
        ...jusPayData?.payload,
        integrationType: 'iframe',
        hyperSDKDiv: 'grabpayIframe', // Div ID to be used for rendering
        logLevel: 1,
      };

      // odPushClevertapEvent('Payment Initiated', {
      //   paymentMethod: selectedPaymentMethod,
      // });

      const sdkPayload = {
        service: jusPayData?.service,
        requestId: jusPayData?.requestId,
        payload: initiatePayload,
      };

      window.hyperServiceObject = new window.HyperServices();
      window.hyperServiceObject.initiate(sdkPayload, hyperCallbackHandler);

      const client = apiClient();
      client.setHeader();
      client
        .post(`${apiPath.orderPayment}`, {
          device: 'desktop',
          leadSource: null,
          nonFranchiseOrder: false,
          orderId, // Retry
          paymentInfo: {
            card: {},
            gatewayId: 'JUSPAY',
            netbanking: {},
            partialPaymentInfo: {
              partialPayment: false,
              partialPaymentAmount: 0,
              partialPaymentMethod: null,
            },
            paymentMethod: 'juspay',
            subscriptionOrderId: null,
          },
        })
        .then(res => {
          const { payload, requestId, service } = res?.result?.payment?.actionInfo?.requestParams;
          const parsePayload = JSON.parse(payload || '');

          const {
            language,
            orderId,
            clientId,
            merchantKeyId,
            orderDetails,
            signature,
            amount,
            customerId,
            customerEmail,
            customerMobile,
            environment,
            merchantId,
            action,
          } = parsePayload;
          const processPayload = {
            requestId,
            service,
            payload: {
              // ...parsePayload,
              action,
              merchantId,
              clientId,
              orderId,
              amount,
              customerId,
              customerEmail,
              customerMobile,
              orderDetails,
              signature,
              merchantKeyId,
              language,
              environment,
            },
          };

          if (payload) {
            console.log('Callback hyper api response orderpayment ==>', processPayload);
            window.hyperServiceObject.process(processPayload);
            setHidePaynow(true);
          }
        })
        .catch(() => {});
    }
  };

  if (clientTokenErrorData)
    return (
      <div>
        <h1>Something went wrong</h1>
      </div>
    );

  if (isSuccess)
    return (
      <div className="od-retry-payment__success">
        <img alt="payment-success" className="od-retry-payment__success-image" src={SuccessGif} />
      </div>
    );

  return (
    <div className="od-retry-payment">
      <div>
        {headless && !isPrimerLoading && !fakeLoading ? (
          <>
            <div id="grabpayIframe" style={showPaymentMethods ? { height: 0 } : { height: 800 }} />
            {showPaymentMethods && (
              <PaymentMethods
                isRetry
                headless={headless}
                isLoading={isPaymentStarted}
                orderId={orderId}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
              />
            )}

            {isPaymentStarted && selectedPaymentMethod !== 'GRABPAY' ? (
              <PaymentPageSubmitShimmer />
            ) : null}

            {!hidePaynow ? (
              <ButtonOwnDays
                fontClass="od-font-bold"
                id="validate-button-primer"
                mode="grey"
                style={{
                  padding: '16px 24px',
                  marginTop: 40,
                  display: isPaymentStarted ? 'none' : 'block',
                }}
                text={PAY_NOW}
                width="100%"
                onClick={() => {
                  setIsPaymentStarted(true);
                  if (selectedPaymentMethod === 'GRABPAY') {
                    handleGrabpayPayment();
                  }
                }}
              />
            ) : null}
          </>
        ) : (
          <PaymentPageShimmer />
        )}
      </div>

      {order && (
        <div className="od-retry-payment-bill-details">
          <div className="od-retry-payment-bill-details__title od-font-bold">{BILL_DETAILS}</div>

          <div className="od-retry-payment-bill-details__rows">
            <div className="od-retry-payment-bill-details__total od-font-bold">{ITEM_TOTAL}</div>
            <div className="od-retry-payment-bill-details__total-price od-font-bold">
              {currencySymbol}
              {order?.amount?.subTotal}
            </div>
            <hr className="od-retry-payment-bill-details__divider" />

            <div className="od-retry-payment-bill-details__total od-font-reg">{TAXES}</div>
            <div className="od-retry-payment-bill-details__total-price od-font-bold">
              {currencySymbol}
              {order?.amount?.taxes &&
                order?.amount?.taxes.length > 0 &&
                order?.amount?.taxes[0]?.amount}
            </div>

            {/* Total price */}
            <hr className="od-retry-payment-bill-details__divider" />
            <div className="od-retry-payment-bill-details__total od-font-bold">
              {ORDER_TOTAL.toLowerCase()}
            </div>
            <div className="od-retry-payment-bill-details__total-price od-font-bold">
              {currencySymbol}
              {order?.amount?.total}
            </div>
          </div>

          <div className="od-retry-payment-bill-details__features">
            <div className="od-retry-payment-bill-details__feature">
              <img
                alt="true"
                className="od-retry-payment-bill-details__image"
                src={CartFeatureGlasses}
              />
              <div className="od-retry-payment-bill-details__feature-title od-font-reg">
                {NO_EXTRA_CHARGE_FOR_HIGH_POWER}
              </div>
            </div>
            <div className="od-retry-payment-bill-details__feature">
              <img
                alt="true"
                className="od-retry-payment-bill-details__image"
                src={CartFeatureShipping}
              />
              <div className="od-retry-payment-bill-details__feature-title od-font-reg">
                {FREE_DELIVERY_NATION_WIDE}
              </div>
            </div>
            <div className="od-retry-payment-bill-details__feature">
              <img
                alt="true"
                className="od-retry-payment-bill-details__image"
                src={CartFeatureWarranty}
              />
              <div className="od-retry-payment-bill-details__feature-title od-font-reg">
                {A_COMPLETE_GUARANTEE}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OdRetryPayment;
