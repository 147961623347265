import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCart, removeGiftCard } from '../../../actionCreators/cart';
import { v4 } from 'uuid';
import './GiftCardList.scss';

const GiftCardList = () => {
  const cartData = useSelector(state => state.cart.cartData.result);

  const [removeGiftClick, setRemoveGiftClick] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (removeGiftClick) {
      dispatch(getCart());
      setRemoveGiftClick(false);
    }
  }, [dispatch, removeGiftClick]);

  return cartData.giftCards && cartData.giftCards.length > 0 ? (
    <div className="od-applied-gift-cards">
      {cartData.giftCards.map(card => {
        return (
          <div key={v4()} className="od-applied-gift-cards__card od-font-bold">
            <div className="od-applied-gift-cards__card__text">{card.code}</div>
            <img
              alt="remove gift card"
              src="https://static1.lenskart.com/media/desktop/img/DesignStudioIcons/CrossBlue.svg"
              onClick={() => {
                dispatch(removeGiftCard(card.code));
                setRemoveGiftClick(true);
              }}
            />
          </div>
        );
      })}
    </div>
  ) : null;
};

export default GiftCardList;
