import React from 'react';
import './GiftCardsSection.scss';

const GiftCardsSection = props => {
  const { title, html, device, classes = '' } = props;
  const mainClass = `od-gc-section--${device}`;
  return (
    <section className={`od-gc-section ${mainClass}  ${classes}`}>
      {title ? (
        <h2 className={`${mainClass}__title od-font-bold`}>{title}</h2>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className={`${mainClass}__title od-font-bold`}
        />
      )}
      <div className={`${mainClass}__main`}>{props.children}</div>
    </section>
  );
};

export default GiftCardsSection;
