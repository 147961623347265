export const GET_HTO_CONFIG = 'GET_HTO_CONFIG';
export const GET_HTO_CONFIG_SUCCESS = 'GET_HTO_CONFIG_SUCCESS';
export const GET_HTO_CONFIG_FAIL = 'GET_HTO_CONFIG_FAIL';

export const GET_LOCATION_SERVICE_DETAILS = 'GET_LOCATION_SERVICE_DETAILS';
export const GET_LOCATION_SERVICE_DETAILS_SUCCESS = 'GET_LOCATION_SERVICE_DETAILS_SUCCESS';
export const GET_LOCATION_SERVICE_DETAILS_FAIL = 'GET_LOCATION_SERVICE_DETAILS_FAIL';

export const GET_SLOTS = 'hto/GET_SLOTS';
export const GET_SLOTS_SUCCESS = 'hto/GET_SLOTS_SUCCESS';
export const GET_SLOTS_FAIL = 'hto/GET_SLOTS_FAIL';

export const GET_CANCEL_REASONS = 'hto/GET_CANCEL_REASONS';
export const GET_CANCEL_REASONS_SUCCESS = 'hto/GET_CANCEL_REASONS_SUCCESS';
export const GET_CANCEL_REASONS_FAIL = 'hto/GET_CANCEL_REASONS_FAIL';

export const CANCEL_HTO_ORDER_LOAD = 'hto/CANCEL_HTO_ORDER_LOAD';
export const CANCEL_HTO_ORDER_SUCCESS = 'hto/CANCEL_HTO_ORDER_SUCCESS';
export const CANCEL_HTO_ORDER_FAIL = 'hto/CANCEL_HTO_ORDER_FAIL';

export const GET_AGENT_INFO = 'hto/GET_AGENT_INFO';
export const GET_AGENT_INFO_SUCCESS = 'hto/GET_AGENT_INFO_SUCCESS';
export const GET_AGENT_INFO_FAIL = 'hto/GET_AGENT_INFO_FAIL';

export const BOOK_APPOINTMENT = 'hto/BOOK_APPOINTMENT';
export const BOOK_APPOINTMENT_SUCCESS = 'hto/BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_FAIL = 'hto/BOOK_APPOINTMENT_FAIL';

export const RESET_ERROR_LOCATION_SERVICE_DETAILS = 'hto/RESET_ERROR_LOCATION_SERVICE_DETAILS';
