import * as actionTypes from '../actionTypes/myAccount';

export function getOrders(pageName, size) {
  return { type: actionTypes.ORDER_LIST_LOAD, pageName, size };
}

export function getOrdersLoadMore(pageName, size, shouldReset) {
  return { type: actionTypes.ORDER_LIST_LOAD_MORE, pageName, size, shouldReset };
}

export function updateUserInfo({ firstName, lastName, gender, email }) {
  return { type: actionTypes.UPDATE_USER_INFO_LOAD, firstName, lastName, gender, email };
}

export function resetPassword({ oldPassword, newPassword, email, token }) {
  return { type: actionTypes.RESET_PASSWORD_LOAD, oldPassword, newPassword, email, token };
}

export function checkVoucherBalance(voucherCode) {
  return { type: actionTypes.CHECK_VOUCHER_LOAD, voucherCode };
}
export function updateAddress(orderId, address) {
  return { type: actionTypes.UPDATE_ADDRESS_LOAD, orderId, address };
}
export function checkStoreCreditBal(voucherCode) {
  return { type: actionTypes.STORE_CREDIT_BAL_LOAD, voucherCode };
}
export function getStoreCreditOrders(pageName, size) {
  return { type: actionTypes.STORE_CREDIT_ORDERS_LOAD, pageName, size };
}
export function getStoreCreditOrderHistory(orderId) {
  return { type: actionTypes.STORE_CREDIT_ORDER_HISTORY_LOAD, orderId };
}
export function sendStoreCreditSMS(orderId) {
  return { type: actionTypes.STORE_CREDIT_ORDER_SMS_LOAD, orderId };
}
export function saveNeftValues(values) {
  return { type: actionTypes.EDIT_NEFT_LOAD, values };
}
export function clearState(values) {
  return { type: actionTypes.CLEAR_STATE, values };
}
export function getReturnOrderConfig() {
  return { type: actionTypes.RETURN_ORDER_CONFIG_LOAD };
}
export function getPfuPdRulesConfig() {
  return { type: actionTypes.PFU_PD_RULES_CONFIG_LOAD };
}
export function newOrderCheck() {
  return { type: actionTypes.NEW_ORDER_CHECK };
}
export function isExchangeFlowSet(data) {
  return { type: actionTypes.EXCHANGE_FLOW_SET, data };
}
