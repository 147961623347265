import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import apiClient from '../helpers/apiClient';
import * as actionTypes from '../actionTypes/frameSize';

let _client = null;
let xApiClient = 'desktop';
// let authToken = '129d50a00cf9b34b1c9b4603t6945e74369b68a8e75ed60263f423bee01dbc91';
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
// authToken = '9235f53b0d1753253nf799b2712482fb4c05a8224a8ua885d07502e0ea81e3fb';
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

function* getFrameSizeData({ formData, authToken, phone }) {
  const successAction = { type: actionTypes.GET_FRAME_SIZE_SUCCESS };
  const errorAction = { type: actionTypes.GET_FRAME_SIZE_FAIL };
  try {
    const response = yield call(async () =>
      _client.post(`${config.apiPath.frameSize}${phone ? '?phone=' + phone : ''}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Client-Auth-Token': authToken,
          'X-Api-Client': xApiClient,
        },
      })
    );
    successAction.data = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* storeFrameSize({ formData, authToken }) {
  const successAction = { type: actionTypes.STORE_FRAME_SIZE_SUCCESS };
  const errorAction = { type: actionTypes.STORE_FRAME_SIZE_FAIL };
  try {
    const response = yield call(async () =>
      _client.post(`${config.apiPath.storeFrameSize}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Client-Auth-Token': authToken,
          'X-Api-Client': xApiClient,
        },
      })
    );
    successAction.data = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runFrameSizeCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_FRAME_SIZE, getFrameSizeData),
    takeLatest(actionTypes.STORE_FRAME_SIZE, storeFrameSize),
  ]);
}
