import { call, put, all, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/primer';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;

function* getClientTokenGen(action) {
  const successAction = { type: actionTypes.GET_CLIENT_TOKEN_SUCCESS };
  const errorAction = { type: actionTypes.GET_CLIENT_TOKEN_FAIL };
  const client = { ..._client };
  client.setHeader();
  let URL = `${config.apiPath.getClientTokenPrimer}`;
  if (action?.orderId) URL += `?orderId=${action?.orderId}`;
  try {
    const data = yield call(async () => _client.get(URL));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getPaymentMethodsWithPrimerGen(params) {
  const successAction = { type: actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_SUCCESS };
  const errorAction = { type: actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_FAIL };
  const client = { ..._client };
  client.setHeader();
  const { redisCommonData } = params?.data || {};
  let URL = `${
    params?.data?.client === 'mobile' && redisCommonData?.MSITE_NEW_PAYMENT_DESIGN
      ? redisCommonData?.PAYMENT_METHOD_NEW_VERSION
        ? config.apiPath.paymentMethodNewV5
        : config.apiPath.paymentMethodNewV4
      : config.apiPath.paymentMethod
  }`;
  let methodsInString = '';
  if (params?.data?.paymentMethods) {
    // To append Methods in API call
    params.data.paymentMethods.map((obj, index) => {
      methodsInString = `${methodsInString}${obj.type}${
        params.data.length - 1 !== index ? ',' : ''
      }`;
    });
    // 'methodsInString' will looks like : 'ATOME,PAYMENT_CARD'
    URL += `?paymentMethod=primer&primerPaymentMethods=ATOME,PAYMENT_CARD`;
  }
  if (params?.data?.orderId) {
    // To append OrderId in API call
    URL += `${methodsInString && methodsInString.length === 0 ? '?' : '&'}orderId=${
      params?.data?.orderId
    }`;
  }
  try {
    const data = yield call(async () => _client.get(URL));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getPrimerSavedCardsGen() {
  const successAction = { type: actionTypes.GET_PRIMER_SAVED_CARDS_SUCCESS };
  const errorAction = { type: actionTypes.GET_PRIMER_SAVED_CARDS_FAIL };
  const client = { ..._client };
  client.setHeader();
  const URL = `${config.apiPath.primerSavedCards}`;
  try {
    const data = yield call(async () => _client.get(URL));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* primerPaymentStatusUpdateGen(params) {
  const successAction = { type: actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_SUCCESS };
  const errorAction = { type: actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_FAIL };
  const client = { ..._client };
  client.setHeader();
  const { paymentRefId, paymentId } = params?.data || {};
  const URL = `${config.apiPath.primerPayment}/${paymentRefId}/status?paymentId=${paymentId}`;
  try {
    const data = yield call(async () => _client.get(URL));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runPrimerCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeEvery(actionTypes.GET_CLIENT_TOKEN_LOAD, getClientTokenGen)]);
  yield all([
    takeEvery(actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_LOAD, getPaymentMethodsWithPrimerGen),
  ]);
  yield all([takeEvery(actionTypes.GET_PRIMER_SAVED_CARDS_LOAD, getPrimerSavedCardsGen)]);
  yield all([
    takeEvery(actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_LOAD, primerPaymentStatusUpdateGen),
  ]);
}
