export const COUNTRIES = [
  {
    name: '日本',
    img: 'https://www.owndays.com/images/flags/jp.webp',
    languages: [
      { name: '日本語', link: 'https://www.owndays.com/jp/ja' },
      { name: 'English', link: 'https://www.owndays.com/jp/en' },
      { name: '中文 (简体)', link: 'https://www.owndays.com/jp/zh_cn' },
      { name: '中文 (繁體)', link: 'https://www.owndays.com/jp/zh_tw' },
    ],
    order: {
      desktop: 1,
      mobile: 1
    }
  },
  {
    name: 'SINGAPORE',
    img: 'https://www.owndays.com/images/flags/sg.webp',
    languages: [{ name: 'English', link: '/sg/en' }],
    order: {
      desktop: 7,
      mobile: 2
    }
  },
  {
    name: '台灣',
    img: 'https://www.owndays.com/images/flags/tw.webp',
    languages: [
      { name: '中文 (繁體)', link: 'https://www.owndays.com/tw/zh_tw' },
    ],
    order: {
      desktop: 2,
      mobile: 3
    }
  },
  {
    name: 'ไทย',
    img: 'https://www.owndays.com/images/flags/th.webp',
    languages: [
      { name: 'ภาษาไทย', link: 'https://www.owndays.com/th/th' },
      { name: 'English', link: 'https://www.owndays.com/th/en' },
      { name: '日本語', link: 'https://www.owndays.com/th/ja' },
      { name: '中文 (简体)', link: 'https://www.owndays.com/th/zh_cn' },
    ],
    order: {
      desktop: 8,
      mobile: 4
    }
  },
  {
    name: 'ព្រះរាជាណាចក្រ',
    img: 'https://www.owndays.com/images/flags/kh.webp',
    languages: [
      { name: 'ភាសាខ្មែរ', link: 'https://www.owndays.com/kh/km' },
    ],
    order: {
      desktop: 3,
      mobile: 5
    }
  },
  {
    name: 'PHILIPPINES',
    img: 'https://www.owndays.com/images/flags/ph.webp',
    languages: [
      { name: 'English', link: 'https://www.owndays.com/ph/en' },
    ],
    order: {
      desktop: 9,
      mobile: 6
    }
  },
  {
    name: 'AUSTRALIA',
    img: 'https://www.owndays.com/images/flags/au.webp',
    languages: [
      { name: 'English', link: 'https://www.owndays.com/au/en' },
    ],
    order: {
      desktop: 4,
      mobile: 7
    }
  },
  {
    name: 'MALAYSIA',
    img: 'https://www.owndays.com/images/flags/my.webp',
    languages: [
      { name: 'English', link: 'https://www.owndays.com/my/en' },
    ],
    order: {
      desktop: 10,
      mobile: 8
    }
  },
  {
    name: 'Việt Nam',
    img: 'https://www.owndays.com/images/flags/vn.webp',
    languages: [
      { name: 'Tiếng Việt', link: 'https://www.owndays.com/vn/vi' },
      { name: 'English', link: 'https://www.owndays.com/vn/en' },
    ],
    order: {
      desktop: 5,
      mobile: 9
    }
  },
  {
    name: 'INDONESIA',
    img: 'https://www.owndays.com/images/flags/id.webp',
    languages: [
      { name: 'Indonesia', link: 'https://www.owndays.com/id/in' },
    ],
    order: {
      desktop: 11,
      mobile: 10
    }
  },
  {
    name: '香港',
    img: 'https://www.owndays.com/images/flags/hk.webp',
    languages: [
      { name: '中文 (繁體)', link: 'https://www.owndays.com/hk/zh_hk' },
    ],
    order: {
      desktop: 6,
      mobile: 11
    }
  },
];

export const COUNTRIES_DESKTOP = COUNTRIES.slice().sort((a, b) => a.order.desktop - b.order.desktop);
export const COUNTRIES_MOBILE = COUNTRIES.slice().sort((a, b) => a.order.mobile - b.order.mobile);
