import * as actionTypes from './../../actionTypes/cart';

const initialState = {
  cartData: { result: { items: [], itemsQty: 0, itemsCount: 0, totals: { discounts: [] } } },
  isCartLoader: false,
  isCartDataLoaded: false,
  gvAmt: 0,
  implicitAmt: 0,
  scAmt: 0,
  gvMessage: null,
  scMessage: null,
  netAmountCart: 0,
  isframeProduct: true,
  cardOfferApplied: null,
  cartError: null,
  applyCouponError: null,
  applyCouponData: null,
  updatePowerFail: null,
  updatePowerSucess: false,
  isCouponApplied: false,
  giftCardsdata: null,
  giftCardsError: false,
  removeGiftCard: {
    removeGiftCardsdata: null,
    removeGiftCardsError: false,
  },
};

export default function cart(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.APPLY_GIFT_SUCCESS:
      return Object.assign({}, prevState, {
        giftCardsdata: action.data,
        giftCardsError: false,
      });
    case actionTypes.APPLY_GIFT_FAIL:
      return Object.assign({}, prevState, {
        giftCardsdata: null,
        giftCardsError: action.error,
      });
    case actionTypes.REMOVE_GIFT_SUCCESS:
      return Object.assign({}, prevState, {
        removeGiftCard: {
          removeGiftCardsdata: action.data,
          removeGiftCardsError: false,
        },
      });
    case actionTypes.REMOVE_GIFT_FAIL:
      return Object.assign({}, prevState, {
        removeGiftCard: {
          removeGiftCardsdata: action.data,
          removeGiftCardsError: false,
        },
      });
    case actionTypes.ADD_TO_CART_LOAD:
      return Object.assign({}, prevState, {
        // isCartLoader: true,
        isCartLoader: false,
        addToCartSuccess: false,
        addToCartFailed: null,
      });
    case actionTypes.UPDATE_CART:
    case actionTypes.UPDATE_CART_LOAD:
      return Object.assign({}, prevState, {
        isCartLoader: true,
        isCartDataLoaded: false,
      });
    case actionTypes.DISCOUNT_CODE_LOAD:
      return Object.assign({}, prevState, {
        isCartLoader: true,
        isCartDataLoaded: true,
        gvMessage: action.url?.indexOf('giftVoucher') > -1 ? null : prevState.gvMessage,
        scMessage: action.url?.indexOf('giftVoucher') === -1 ? null : prevState.scMessage,
      });
    case actionTypes.GET_CART_DATA_LOAD:
      return Object.assign({}, prevState, {
        isCartLoader: action.isCartUpdating || action.applyWallet === null || action.isExchange,
        isCartDataLoaded: Boolean(action.isCartUpdating),
        gvMessage: null,
      });
    case actionTypes.DELETE_CART_LOAD:
      return Object.assign({}, prevState, {
        cartData: {
          result: { items: [], itemsQty: 0, itemsCount: 0, totals: { discounts: [] } },
        },
      });
    case actionTypes.GET_CART_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        isCartLoader: false,
        isCartDataLoaded: true,
        cartData: action.data,
        gvAmt: action.data.gvAmt || 0,
        scAmt: action.data.scAmt || 0,
        implicitAmt: action.data.implicitAmt || 0,
        updateCartSuccess: action.data.isUpdateCartCalled ? true : prevState.updateCartSuccess,
        addToCartSuccess: action.data.isAddToCartCalled ? true : prevState.addToCartSuccess,
        addToCartFailed: action.data.isAddToCartCalled ? null : prevState.addToCartFailed,
        showCartPopup: action.data.showCartPopup,
        gvMessage: action.data.isGvApplied ? null : '', // prevState.gvMessage
        scMessage: action.data.isScApplied ? null : prevState.scMessage,
        error: null,
        cartError: null,
      });
    case actionTypes.GET_CART_DATA_FAIL:
      const cart = {};
      if (action.error.status === 401 || action.error.status === 404) {
        cart.cartData = initialState.cartData;
      }
      return Object.assign(
        {},
        prevState,
        {
          isCartLoader: false,
          isCartDataLoaded: true,
          updateCartFail: action.error.isUpdateCartCalled ? true : prevState.updateCartFail,
          addToCartSuccess: action.error.isAddToCartCalled ? false : prevState.addToCartSuccess,
          addToCartFailed: action.error.isAddToCartCalled
            ? action.error
            : prevState.addToCartFailed,
          gvAmt: action.error.isGvApplied ? 0 : prevState.gvAmt,
          gvMessage: action.error.isGvApplied ? action.error : prevState.gvMessage,
          scMessage: action.error.isScApplied ? action.error : prevState.scMessage,
          error: action.error,
          cartError: action.error,
        },
        cart
      );
    case actionTypes.RESET_CART:
      return Object.assign({}, prevState, initialState);
    case actionTypes.LOGOUT_USER:
      return Object.assign({}, prevState, {
        cartData: {
          result: { items: [], itemsQty: 0, itemsCount: 0, totals: { discounts: [] } },
        },
        gvAmt: 0,
        implicitAmt: 0,
      });

    case actionTypes.FRAME_ONLY_PRODUCT:
      return Object.assign({}, prevState, {
        isframeProduct: action.isframeProduct,
      });
    case actionTypes.GET_CART_NET_AMOUNT:
      return Object.assign({}, prevState, {
        netAmountCart: action.amount,
      });
    case actionTypes.SHIPPING_CHECK_LOAD:
      return Object.assign({}, prevState, { shippingError: null });
    case actionTypes.SHIPPING_CHECK_SUCCESS:
      return Object.assign({}, prevState, { cartData: action.result, shippingError: null });
    case actionTypes.SHIPPING_CHECK_FAIL:
      return Object.assign({}, prevState, { shippingError: action.error });
    case actionTypes.APPLY_OFFER:
      return Object.assign({}, prevState, { cardOfferLoading: true, cartError: null });
    case actionTypes.APPLY_OFFER_SUCCESS:
      return Object.assign({}, prevState, { cardOfferLoading: false, cartData: action.result });
    case actionTypes.APPLY_OFFER_FAIL:
      const cartApplicable = {};
      cartApplicable.cartData = prevState.cartData;
      return Object.assign(
        {},
        prevState,
        {
          isCartLoader: false,
          isCartDataLoaded: true,
          cartError: action.error,
          cardOfferLoading: false,
        },
        cartApplicable
      );
    case actionTypes.REMOVE_OFFER:
      return Object.assign({}, prevState, { loading: true });
    case actionTypes.REMOVE_OFFER_SUCCESS:
      return Object.assign({}, prevState, { loading: false, cartData: action.result });
    case actionTypes.REMOVE_OFFER_FAIL:
      const removeCartApplicable = {};
      removeCartApplicable.cartData = prevState.cartData;
      return Object.assign(
        {},
        prevState,
        {
          isCartLoader: false,
          isCartDataLoaded: true,
          cartError: action.error,
          loading: false,
        },
        removeCartApplicable
      );
    case actionTypes.UPDATE_POWER_LOAD:
      return Object.assign({}, prevState, { updatePowerFail: null, updatePowerSucess: false });
    case actionTypes.UPDATE_POWER_SUCCESS:
      return Object.assign({}, prevState, {
        cartData: action.result,
        updatePowerFail: null,
        updatePowerSucess: true,
      });
    case actionTypes.UPDATE_POWER_FAIL:
      return Object.assign({}, prevState, {
        updatePowerFail: action.error,
        updatePowerSucess: false,
      });
    case actionTypes.APPLY_COUPON_CLICKED:
      return { ...prevState, isCouponApplied: action.clicked };
    case actionTypes.APPLY_COUPON_ERROR:
      return { ...prevState, applyCouponError: action.error, applyCouponData: null };
    case actionTypes.RESET_APPLY_COUPON:
      return { ...prevState, applyCouponData: null };
    case actionTypes.APPLY_COUPON_SUCCESS:
      return { ...prevState, applyCouponData: action.data, applyCouponError: null };
    case actionTypes.RESET_CART_ERROR:
      return { ...prevState, cartError: null };
    default:
      return prevState;
  }
}
