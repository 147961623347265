import Loadable from 'react-loadable';

const AuthModalNewLoadable = Loadable({
  loader: () =>
    import('./../../../own-days/views/desktop/AuthModalOD/AuthModalOD').then(
      module => module.default
    ),
  loading: () => null,
});

export default AuthModalNewLoadable;
