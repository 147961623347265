import React from 'react';
import './UserInputErrorOD.scss';

import { replaceLenskartBackendError } from '../../../../../utils/helper';

const UserInputErrorOD = props => {
  const { message } = props;

  if (!message) {
    return null;
  }
  return (
    <div className="od-custom-error-msg od-font-reg">{replaceLenskartBackendError(message)}</div>
  );
};

export default UserInputErrorOD;
