import React, { Fragment } from 'react';

import { DescriptionPolicy, FormPolicy } from '../CommonComponents/Policy';

const PolicyHTML = () => {
  return (
    <Fragment>
      <DescriptionPolicy />
      <FormPolicy />
    </Fragment>
  );
};

export default PolicyHTML;
