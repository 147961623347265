import React, { Fragment, useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';

import {
  FEATURES,
  LINE_UP_TOP,
  SKU_OR2081L4S,
  SKU_OR2083L4S,
  SKU_OR2061L2S,
  SKU_OR2080L4S,
  SKU_OR2084L4S,
  SKU_OR2065L2S,
  SKU_OR2082L4S,
  SKU_OR2064L2S,
  SKU_OR2063L2S,
} from './constants';
import {
  featureSetting,
  settingsForOR2081L4S,
  settingsForOR2083L4S,
  settingsForOR2061L2S,
  settingsForOR2080L4S,
  settingsForOR2084L4S,
  settingsForOR2065L2S,
  settingsForOR2082L4S,
  settingsForOR2064L2S,
  settingsForOR2063L2S,
} from './configSliders';

import './BodyOwndaysPlus.scss';

export const BodyOwndaysPlus = ({ baseImgUrl }) => {
  const [currentSlideFeature, setCurrentSlideFeature] = useState(0);

  const featureSliderRef = useRef(null);
  const basicRef = useRef(null);
  const businessRef = useRef(null);
  const trendRef = useRef(null);

  const renderProducts = products => {
    return products?.map(item => (
      <div key={item.alt} className="body-owndays-plus__lineup-list__main__lineup-slider__slide">
        <img
          alt={item.alt}
          className="body-owndays-plus__lineup-list__main-img lazyload"
          data-sizes="auto"
          src={item.src}
        />
        <h4 className="body-owndays-plus__lineup-list__main-code">{item.sku}</h4>

        <small className="body-owndays-plus__lineup-list__main-color">{item.colorName}</small>

        <div className="text-center">
          <a className="base-owndays-plus__btn" href={item.link}>
            {item.buttonName}
          </a>
        </div>
      </div>
    ));
  };

  const handleScrollToView = target => {
    window.location.hash = target;
    let topPos = basicRef.current.offsetTop - 200;

    switch (target) {
      case '#basic':
        topPos = basicRef.current.offsetTop - 200;
        break;

      case '#business':
        topPos = businessRef.current.offsetTop - 320;
        break;

      case '#trend':
        topPos = trendRef.current.offsetTop - 200;
        break;

      default:
        break;
    }
    window.scrollTo({
      top: topPos,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      handleScrollToView(hash);
    }
  }, []);

  return (
    <Fragment>
      <section className="body-owndays-plus__feature">
        <div className="base-owndays-plus__container">
          <h2 className="base-owndays-plus__heading">
            <strong>Features</strong>
            <small>Unique to OWNDAYS+</small>
          </h2>
        </div>

        <Slider
          ref={featureSliderRef}
          {...featureSetting}
          afterChange={currentSlide => setCurrentSlideFeature(currentSlide)}
          className="body-owndays-plus__feature__slider"
          initialSlide={currentSlideFeature}
        >
          {FEATURES.map((item, index) => {
            return (
              <div
                key={`feature-owndays-plus-${index}`}
                className="body-owndays-plus__feature__slider__item"
              >
                <picture>
                  <source media="(min-width: 768px)" srcSet={item.image.pc} type="image/webp" />
                  <img
                    alt={item.title}
                    className="body-owndays-plus__feature__slider__image"
                    src={item.image.sp}
                  />
                </picture>

                <div className="body-owndays-plus__feature__slider__text">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>

                  <div className="body-owndays-plus__feature__slider__pagination">
                    {FEATURES.map((_, bulletIndex) => (
                      <span
                        key={`pagination-bullet-${bulletIndex}`}
                        className={`body-owndays-plus__feature__slider__pagination-bullet ${
                          currentSlideFeature === bulletIndex
                            ? 'body-owndays-plus__feature__slider__pagination-bullet--active'
                            : ''
                        }`}
                      ></span>
                    ))}
                  </div>
                </div>

                <div className="body-owndays-plus__feature__slider__arrows">
                  <img
                    alt="left arrow"
                    className="body-owndays-plus__feature__slider__arrow"
                    src={`${baseImgUrl}/arrow-left.webp`}
                    onClick={() => featureSliderRef.current.slickPrev()}
                  />
                  <img
                    alt="left arrow"
                    className="body-owndays-plus__feature__slider__arrow"
                    src={`${baseImgUrl}/arrow-right.webp`}
                    onClick={() => featureSliderRef.current.slickNext()}
                  />
                </div>
              </div>
            );
          })}
        </Slider>
      </section>

      <section className="body-owndays-plus__lineup-top">
        <div className="body-owndays-plus__lineup-top__layout">
          <div className="body-owndays-plus__lineup-top__header">
            <h2 className="base-owndays-plus__heading">
              <strong>Lineup</strong>
              <small>OWNDAYS+ Models</small>
            </h2>
          </div>

          <div className="body-owndays-plus__lineup-top__body">
            <img
              alt=""
              className="body-owndays-plus__lineup-top__person-image lazyload"
              src={`${baseImgUrl}/line-up-main-02.webp`}
            />

            <div className="body-owndays-plus__lineup-top__body-content-layout">
              <div className="body-owndays-plus__lineup-top__glasses-layout">
                <img
                  alt="OR2080L-4S"
                  className="body-owndays-plus__lineup-top__glasses lazyload"
                  src={`${baseImgUrl}/line-up-main-01.webp`}
                />
              </div>

              <div className="body-owndays-plus__lineup-top__body-content__text-layout">
                <div className="body-owndays-plus__lineup-top__body-content__text-layout__title-wrapper">
                  <p className="body-owndays-plus__lineup-top__body-content__text-layout__title">
                    inclusive of high index aspheric lenses
                    <br />
                    <strong className="body-owndays-plus__lineup-top__body-content__text-layout__price">
                      S$98
                    </strong>
                  </p>
                </div>
                <p className="body-owndays-plus__lineup-top__body-content__text-layout__detail">
                  Minimalist eyewear made of Japanese resin, where resonances of classic, modest
                  Japanese design come to life. Functional, versatile and perfect for any face
                  shape.
                </p>
              </div>
            </div>
          </div>

          <ul className="body-owndays-plus__lineup-top__footer">
            {LINE_UP_TOP.map(item => {
              return (
                <li key={item.title}>
                  <a onClick={() => handleScrollToView(item.href)}>
                    <div className="body-owndays-plus__lineup-top__footer__item">
                      <div className="body-owndays-plus__lineup-top__footer__image-layout">
                        <img
                          alt={item.title}
                          className="body-owndays-plus__lineup-top__footer__item-image lazyload"
                          src={item.src}
                        />
                        <div className="body-owndays-plus__lineup-top__footer__title-layout">
                          <h4 className="body-owndays-plus__lineup-top__footer__item-title">
                            {item.title}
                          </h4>
                        </div>
                      </div>
                      <p className="body-owndays-plus__lineup-top__footer__item-detail">
                        {item.detail}
                      </p>
                      <img
                        alt="arrow"
                        className="body-owndays-plus__lineup-top__footer__item-button lazyload"
                        src={`${baseImgUrl}/line-up-main-button-inactive.svg`}
                      />
                    </div>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      <section className="body-owndays-plus__lineup-list">
        <ul>
          <li ref={basicRef} id="basic">
            <div className="base-owndays-plus__container">
              <div className="body-owndays-plus__lineup-list__head">
                <img
                  alt="Basic"
                  className="body-owndays-plus__lineup-list__head-img lazyload"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-basic-main.webp`}
                />
                <div
                  className="body-owndays-plus__lineup-list__head-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-delay="1000"
                >
                  <small>Timeless classics great for all</small>
                  <h2>BASIC</h2>
                  <p>
                    Designed with sophisticated functionality and style, these versatile frames fit
                    people of all ages and are great for everyday use.
                  </p>
                </div>
              </div>

              <div className="body-owndays-plus__lineup-list__main">
                <Slider
                  {...settingsForOR2081L4S}
                  className="body-owndays-plus__lineup-list__main-card body-owndays-plus__lineup-list__main-card--new"
                >
                  {renderProducts(SKU_OR2081L4S)}
                </Slider>

                <Slider
                  {...settingsForOR2083L4S}
                  className="body-owndays-plus__lineup-list__main-card body-owndays-plus__lineup-list__main-card--new"
                >
                  {renderProducts(SKU_OR2083L4S)}
                </Slider>

                <Slider
                  {...settingsForOR2061L2S}
                  className="body-owndays-plus__lineup-list__main-card"
                >
                  {renderProducts(SKU_OR2061L2S)}
                </Slider>
              </div>
            </div>
          </li>

          <li ref={businessRef} id="business">
            <div className="base-owndays-plus__container">
              <div className="body-owndays-plus__lineup-list__head">
                <img
                  alt="Business"
                  className="body-owndays-plus__lineup-list__head-img lazyload"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-business-main.webp`}
                />
                <div
                  className="body-owndays-plus__lineup-list__head-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-delay="1000"
                >
                  <small>A great combination with suit outfits</small>
                  <h2>BUSINESS</h2>
                  <p>
                    Coming in sleek designs that give a smart look, these frames are perfect for
                    formal, business settings. They complement the wearer’s facial features and can
                    be worn comfortably by anyone regardless of age.
                  </p>
                </div>
              </div>

              <div className="body-owndays-plus__lineup-list__main">
                <Slider
                  {...settingsForOR2080L4S}
                  className="body-owndays-plus__lineup-list__main-card body-owndays-plus__lineup-list__main-card--new"
                >
                  {renderProducts(SKU_OR2080L4S)}
                </Slider>

                <Slider
                  {...settingsForOR2084L4S}
                  className="body-owndays-plus__lineup-list__main-card body-owndays-plus__lineup-list__main-card--new"
                >
                  {renderProducts(SKU_OR2084L4S)}
                </Slider>

                <Slider
                  {...settingsForOR2065L2S}
                  className="body-owndays-plus__lineup-list__main-card"
                >
                  {renderProducts(SKU_OR2065L2S)}
                </Slider>
              </div>
            </div>
          </li>

          <li ref={trendRef} id="trend">
            <div className="base-owndays-plus__container">
              <div className="body-owndays-plus__lineup-list__head">
                <img
                  alt="Trend"
                  className="body-owndays-plus__lineup-list__head-img lazyload"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-sizes="auto"
                  src={`${baseImgUrl}/lineup-trend-main.webp`}
                />
                <div
                  className="body-owndays-plus__lineup-list__head-content"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-delay="1000"
                >
                  <small>A fashionable accent</small>
                  <h2>TREND</h2>
                  <p>
                    Featuring shapes that offer an edgy appeal, these frames are great fashion
                    accessories to jazz up your outfit.
                  </p>
                </div>
              </div>

              <div className="body-owndays-plus__lineup-list__main">
                <Slider
                  {...settingsForOR2082L4S}
                  className="body-owndays-plus__lineup-list__main-card body-owndays-plus__lineup-list__main-card--new"
                >
                  {renderProducts(SKU_OR2082L4S)}
                </Slider>

                <Slider
                  {...settingsForOR2064L2S}
                  className="body-owndays-plus__lineup-list__main-card"
                >
                  {renderProducts(SKU_OR2064L2S)}
                </Slider>

                <Slider
                  {...settingsForOR2063L2S}
                  className="body-owndays-plus__lineup-list__main-card"
                >
                  {renderProducts(SKU_OR2063L2S)}
                </Slider>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </Fragment>
  );
};
