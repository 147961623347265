import React, { useCallback, useEffect, useRef, useState } from 'react';
import PowerDetails from '../../../../CommonComponents/PowerDetails/PowerDetails';
import { addToCartGA4, removeFromCartGA4 } from '../../../../utils/gaFour';
import './CartItem.scss';

const cartCondition = data => {
  return (
    data.classification === 'services' ||
    data.classification === 'loyalty_services' ||
    data.classification === 'contact_lens' ||
    data.classification === 'contact_lens_solution'
  );
};

const CartItem = props => {
  const {
    currencyMark,
    data,
    decrementItem,
    incrementItem,
    deleteCartItem,
    index,
    dataLocale,
    login,
  } = props;
  const {
    QUANTITY,
    PRODUCT_PRICE,
    FRAME_PRICE,
    LENS_PACKAGE,
    ADD_ON,
    SUBTOTAL,
    VIEW_POWERS,
    SELLING_FAST_FEW_PIECES_LEFT,
    ONLY_ONE_QUANTITY_LEFT,
    CURRENCY_CODE,
  } = dataLocale;
  const currencyCode = data && data.amount ? data.amount.currencyCode : CURRENCY_CODE;
  const currencySymbol = currencyMark[currencyCode];
  const smlImgPattern = /(thumbnail\/628x301\/)/gm;
  const smlProductImg = 'thumbnail/100x100/';
  const url = new URL(data.productUrl);

  const [showPowers, setShowVisible] = useState(false);
  const powerDetailsRef = useRef(null);
  const updatePowerState = useCallback(() => {
    setShowVisible(!showPowers);
  });

  useEffect(() => {
    let timeout;
    if (showPowers) {
      timeout = setTimeout(() => {
        powerDetailsRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }, 0);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [showPowers]);

  return (
    <div aria-label="cart item slider" className="row-fluid cart-item">
      <i
        className="cart-item_remove-icon"
        data-testid="crossBtn"
        onClick={() => {
          deleteCartItem(data.id, data.productId);
          removeFromCartGA4(data, login);
        }}
      ></i>
      <div aria-label="cart item" className="cart-item_container blck-imgq">
        <div className="fs12 text-center">{data.name.substring(0, 39)}...</div>
        <span className="cart-item_container_thumb">
          <a
            data-testid="productUrl"
            href={url.pathname + url.hash}
            rel="noopener noreferrer"
            target="_blank"
          >
            {data.thumbnail ? (
              <img
                alt={data.name}
                className="max-width-100"
                src={data.thumbnail.replace(smlImgPattern, smlProductImg)}
              />
            ) : (
              <img alt={data.name} src={data.thumbnail} />
            )}
          </a>
        </span>
        <div className="cart-item_container_qty-block">
          <span className="cart-item_container_qty-block_title">{QUANTITY}</span>
          <span className={cartCondition(data) ? 'blck-qty margin-t21' : 'blck-qty'}>
            {cartCondition(data) ? (
              ''
            ) : (
              <i
                aria-label="decrease product item quantity"
                className="icon-qtyMinus"
                onClick={() => {
                  decrementItem(data.id, data.productId);
                  removeFromCartGA4(data, login);
                }}
              ></i>
            )}
            <input
              readOnly
              aria-label="item quantity"
              className="qty-value"
              type="text"
              value={data.quantity}
            />
            {cartCondition(data) ? (
              ''
            ) : (
              <i
                aria-label="increase product item quantity"
                className="icon- plus"
                style={{
                  pointerEvents: data.availableQuantity === 1 && 'none',
                  opacity: data.availableQuantity === 1 && '0.2',
                }}
                onClick={() => {
                  incrementItem(data.id);
                  addToCartGA4(data, login);
                }}
              ></i>
            )}
          </span>
        </div>
      </div>
      <div className="cart-item_amount">
        <div aria-label="cart item amount" className="crt-frm-prce">
          {data.classification !== 'eyeframe' ? (
            <div className="text-uppercase">{PRODUCT_PRICE}</div>
          ) : (
            <div className="text-uppercase">{FRAME_PRICE}</div>
          )}
          <span className="off-price color-yellow">
            {data.catalogPrices[0].value > data.catalogPrices[1].value && (
              <span aria-label="item price">
                <s>
                  {currencySymbol}
                  {data.catalogPrices[0].value * data.quantity}
                </s>
                <br />
                {currencySymbol}
                {data.catalogPrices[1].value * data.quantity}
              </span>
            )}
            {data.catalogPrices[0].value === data.catalogPrices[1].value &&
              typeof data.catalogPrices[2] !== 'undefined' && (
                <span>
                  {currencySymbol}
                  {data.catalogPrices[1].value * data.quantity}
                </span>
              )}
            {data.catalogPrices[0].value === data.catalogPrices[1].value &&
              typeof data.catalogPrices[2] === 'undefined' && (
                <span>
                  {currencySymbol}
                  {data.catalogPrices[1].value * data.quantity}
                </span>
              )}
          </span>
        </div>
        {data.classification !== undefined &&
          data.classification === 'contact_lens' &&
          data.prescription && (
            <div className={`crt-lns-prce view-power-toggle${index}`}>
              <div
                aria-label="contact lens view power"
                className={`text-center toggle-arrow${showPowers ? ' toggle-arrow-view' : ''}`}
                onClick={updatePowerState}
              >
                {VIEW_POWERS}
              </div>
            </div>
          )}
        {data.options.length > 0 && (
          <div>
            <div className="pull-left pls-crt-sign">+</div>
            <div className="crt-lns-prce">
              <div className="text-center">{LENS_PACKAGE} </div>
              <table className="lns-detail-tab">
                {data.options.map((optData, index) => {
                  return (
                    <tbody key={index}>
                      {optData.type === 'COATING' && index === 1 && (
                        <tr className="Lens-Package futura-h">
                          <td>
                            <strong className="f12 text-capitalize">{ADD_ON}</strong>
                          </td>
                        </tr>
                      )}
                      <tr key={index} className="Lens-Package">
                        <td>
                          <strong className="f12">
                            {optData.type === 'COATING' ? `-${optData.name}` : optData.name}
                          </strong>
                        </td>
                        {optData.catalogPrices[0].value !== optData.catalogPrices[1].value && (
                          <td aria-label="show market price" className="old-price packges-block">
                            {' '}
                            {currencySymbol}
                            {optData.catalogPrices[0].value}
                          </td>
                        )}
                        <td className="packges-block">
                          {' '}
                          {currencySymbol}
                          {optData.catalogPrices[1].value}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        )}
        <div aria-label="cart subtotal" className="pull-right">
          <div className="col-lg-4">
            <div className="pull-left margin-t10 fs24">=</div>
          </div>
          <div className="col-lg-8">
            <div className="text-uppercase">{SUBTOTAL}</div>
            <div className="margin-t0 f14">
              {currencySymbol}
              {data.price.value * data.quantity}
            </div>
          </div>
        </div>
        {data.classification !== 'loyalty_services' && (
          <div className="row-fluid cart-item_amount_status">
            <span>{SELLING_FAST_FEW_PIECES_LEFT}</span>
          </div>
        )}
      </div>
      {data.availableQuantity === 1 && (
        <div className="cart-item_message">
          <span>
            <i aria-hidden="true" className="fa fa-2x fa-bullhorn"></i>
            <span className="oos-text">{ONLY_ONE_QUANTITY_LEFT}</span>
          </span>
        </div>
      )}
      {showPowers && data.prescriptionView && (
        <div
          ref={powerDetailsRef}
          className="power-details-cart padding-r10 padding-b10 padding-l10 bora-4"
        >
          <PowerDetails
            dataLocale={dataLocale}
            prescription={data.prescriptionView}
            view="desktop"
          />
        </div>
      )}
    </div>
  );
};

export default CartItem;
