import { all } from 'redux-saga/effects';
import { runCommonCalls } from './common';
import { runHeaderCalls } from './header';
import { runFooterCalls } from './footer';
import { runCategoryCalls } from './category';
import { runProductCalls } from './product';
import { runHomeCalls } from './home';
import { runCartCalls } from './cart';
import { runAuthCalls } from './auth';
import { runDittoCalls } from './ditto';
import { runDittoShareCalls } from './dittoShare';
import { runSuccessCalls } from './success';
import { runOrderCalls } from './order';
import { runCategoryCarouselCalls } from './categoryCarousel';
import { runCheckoutCalls } from './checkout';
import { runAddressCalls } from './address';
import { runPhoneNumberCaptureCalls } from './phoneNumberCapture';
import { runCollectionPageCalls } from './collectionPage';
import { runShortlistCalls } from './shortlist';
import { runDTMCalls } from './DTM';
import { runHtoCalls } from './hto';
import { runMyAccountCalls } from './myAccount';
import { runSavedCardsCalls } from './savedCards';
import { runNeftCalls } from './neft';
import { runProductCarouselCalls } from './productCarousel';
import { runWhatsappStatusCalls } from './gupshupWhatsapp';
import { runReturnExchangeCalls } from './returnExchange';
import { runFrameSizeCalls } from './frameSize';
import { runPLPBannerCalls } from './plpBanner';
import { runStoreCalls } from './store';
import { runQRCodeCalls } from './qrCode';
import { runGetLocaleCalls } from './locale';
import { runNewMSiteOfflineSalesFlowCalls } from './newMSiteOfflineSalesFlow';
import { runReviewCalls } from './review';
import { runOfferCampaignCalls } from './offerCampaign';
import { runLensPackageUpgradeCalls } from './lensPackageUpgrade';
import { runCancelOrderCalls } from './cancelOrder';
import { runStudioFlow } from './studioflow';
import { runPDCalls } from './PD';
import { runPrimerCalls } from './primer';
import { runMembershipPointsCalls } from './membershipPoints';
import { runSearchCalls } from './search';

export default function* rootSaga(client, location) {
  yield all([
    runCommonCalls(client, location),
    runHeaderCalls(client),
    runFooterCalls(client),
    runCategoryCalls(client, location),
    runHomeCalls(client, location),
    runCartCalls(client),
    runCategoryCarouselCalls(client),
    runProductCalls(client),
    runAuthCalls(client),
    runDittoCalls(client),
    runDittoShareCalls(client),
    runCheckoutCalls(client),
    runAddressCalls(client),
    runSuccessCalls(client),
    runOrderCalls(client),
    runPhoneNumberCaptureCalls(client),
    runCollectionPageCalls(client),
    runDTMCalls(client),
    runShortlistCalls(client),
    runMyAccountCalls(client),
    runSavedCardsCalls(client),
    runNeftCalls(client),
    runProductCarouselCalls(client),
    // runYotpoCalls(client),
    runHtoCalls(client),
    runWhatsappStatusCalls(client),
    runReturnExchangeCalls(client),
    runCancelOrderCalls(client),
    runFrameSizeCalls(client),
    runPLPBannerCalls(client),
    runStoreCalls(client),
    runQRCodeCalls(client),
    runNewMSiteOfflineSalesFlowCalls(client),
    runGetLocaleCalls(client),
    runReviewCalls(client),
    runOfferCampaignCalls(client),
    runLensPackageUpgradeCalls(client),
    runStudioFlow(client),
    runPDCalls(client),
    runPrimerCalls(client),
    runMembershipPointsCalls(client),
    runSearchCalls(client),
  ]);
}
