import React, { Fragment, useState, useRef, useEffect } from 'react';

import { FAQS } from './constants';
import './FooterOrthoKLens.scss';

export const FooterOrthoKLens = ({ baseImgUrl }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const contentRefs = useRef([]);

  const toggleItem = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    contentRefs.current.forEach((content, i) => {
      if (content) {
        if (openIndex === i) {
          content.style.height = content.scrollHeight + 'px';
        } else {
          content.style.height = '0';
        }
      }
    });
  }, [openIndex]);

  return (
    <div className="base-ortho-k-lens__container">
      <section className="footer-ortho-k-lens__faq">
        <h2 className="base-ortho-k-lens__title">FAQ</h2>

        <div className="footer-ortho-k-lens__faq__q-a">
          <div className="footer-ortho-k-lens__faq__q-a__content">
            <ul className="footer-ortho-k-lens__faq__q-a__accordion">
              {FAQS.map((faq, index) => (
                <li key={index}>
                  <div
                    className="footer-ortho-k-lens__faq__q-a__accordion__title"
                    onClick={() => toggleItem(index)}
                  >
                    <h3 className="footer-ortho-k-lens__faq__q-a__q-title">{faq.question}</h3>
                  </div>
                  <div
                    ref={el => {
                      contentRefs.current[index] = el;
                    }}
                    className={`footer-ortho-k-lens__faq__q-a__accordion__content ${
                      openIndex ? 'footer-ortho-k-lens__faq__q-a__accordion__content--open' : ''
                    }`}
                  >
                    <div className="footer-ortho-k-lens__faq__q-a__a-inner">
                      <p>
                        {faq.answer}
                        {faq.links &&
                          faq.links.map((link, i) => (
                            <Fragment key={i}>
                              <br />
                              <a className="footer-ortho-k-lens__faq__link-color" href={link.url}>
                                {link.text}
                              </a>
                              {` ${link.suffix}`}
                            </Fragment>
                          ))}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="footer-ortho-k-lens__contact-lenses">
        <div className="footer-ortho-k-lens__contact-lenses__title">
          <h3>
            Soft
            <br />
            Contact Lenses
          </h3>
        </div>

        <div className="footer-ortho-k-lens__contact-lenses__list">
          <ul>
            <li>
              <a href="/sg/en/contacts/clear-contactlens">
                <img alt="CLEAR CONTACT LENS" src={`${baseImgUrl}/clear-contact-menu.webp`} />
              </a>
            </li>
            <li>
              <a href="/sg/en/contacts/clear-contactlens">
                <img alt="COLOUR CONTACT LENS" src={`${baseImgUrl}/color-contact-menu.webp`} />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
