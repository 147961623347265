// 2nd param set false if you do not want to trigger VirtualPageView
export function setAndTriggerDataLayer(data, pushDataLayer = true) {
  if (typeof window !== 'undefined') {
    const newDataLayer = { ...window.persistDataLayer, ...data, event: 'VirtualPageView' };
    const { visitorEmail, visitorMobile, gaMobile, userType, website_source } = newDataLayer;
    window.persistDataLayer = { visitorEmail, visitorMobile, gaMobile, userType, website_source };
    if (pushDataLayer) {
      if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push(newDataLayer);
      } else {
        setTimeout(() => {
          setAndTriggerDataLayer(data, pushDataLayer);
        }, 2000);
      }
    }
  }
}

export function pushDataLayerEvent(data) {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'undefined') {
    const { gaMobile, userType, website_source } = window.persistDataLayer || {};
    data = { ...data, mobile: gaMobile || '', userType: userType || '', website_source };
    window.dataLayer.push(data);
    console.log(
      '🚀 ~ file: DataLayer.js:24 ~ pushDataLayerEvent ~ window.dataLayer:',
      window.dataLayer
    );
  } else {
    setTimeout(() => {
      pushDataLayerEvent(data);
    }, 2000);
  }
}
