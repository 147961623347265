import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setToastMessage } from './../../../actionCreators/common';
import './ToastMessage.scss';

class ToastMessage extends PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { toastMessage, setToastMessage } = this.props;
    if (toastMessage !== prevProps.toastMessage && toastMessage) {
      const { timeout = 2000, callback } = toastMessage;
      setTimeout(() => {
        setToastMessage(null);
        if (typeof callback === 'function') callback();
      }, timeout);
    }
  }

  render() {
    const { toastMessage } = this.props;
    const ctaVisible = __CLIENT__ && document.getElementsByClassName('cta-btns').length;
    let bottomPx = toastMessage ? '24px' : '-999px';
    if (toastMessage && ctaVisible) bottomPx = '54px';
    if (process.env.CLIENT_TYPE === 'desktop') {
      let toastBgColor = 'background-dark_grey_blue';
      if (toastMessage?.success) toastBgColor = 'background-green';
      if (toastMessage?.error) toastBgColor = 'background-red';
      if (toastMessage?.warning) toastBgColor = 'background-orange';
      return (
        <div
          className="message-toast pos-fix"
          style={{ bottom: bottomPx, right: bottomPx, textAlign: 'end' }}
        >
          {toastMessage && toastMessage.message && (
            <span className={`bora-4 toast-text inline-block fs16 color-white ${toastBgColor}`}>
              {toastMessage.message}
            </span>
          )}
        </div>
      );
    }
    return (
      <div className="message-toast text-center pos-fix" style={{ bottom: bottomPx }}>
        {toastMessage && toastMessage.message && (
          <span
            className={`text-color_white bora-4 toast-text inline-block white-space-pre ${
              toastMessage.warning ? 'bg-orange' : 'bg-dark_grey_blue'
            }`}
          >
            {toastMessage.message}
          </span>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  toastMessage: state.common.toastMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    setToastMessage: data => dispatch(setToastMessage(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage);
