// CATEGORY DATA
export const CATEGORY_DATA_LOAD = 'category/CATEGORY_DATA_LOAD';
export const SEARCH_CATEGORY_DATA_LOAD = 'category/SEARCH_CATEGORY_DATA_LOAD';
export const CATEGORY_DATA_SUCCESS = 'category/CATEGORY_DATA_SUCCESS';
export const CATEGORY_DATA_FAIL = 'category/CATEGORY_DATA_FAIL';

// category And Filter Data
export const CATEGORY_AND_FILTER_DATA_LOAD = 'category/CATEGORY_AND_FILTER_DATA_LOAD';

// verticle filter
export const FILTER_DATA_LOAD = 'category/FILTER_DATA_LOAD';
export const FILTER_DATA_SUCCESS = 'category/FILTER_DATA_SUCCESS';
export const FILTER_DATA_FAIL = 'category/FILTER_DATA_FAIL';
export const ADD_FILTERDATA = 'category/ADD_FILTERDATA';
export const HANDLE_FILTER_FUNC = 'category/HANDLE_FILTER_FUNC';
export const MODIFY_DATA = 'category/MODIFY_DATA';
export const FILTER_DROPDOWN = 'category/FILTER_DROPDOWN';
export const FILTER_STATE_UNSET = 'category/FILTER_STATE_UNSET';

// search filters
export const SEARCH_FILTER_LOAD = 'category/SEARCH_FILTER_LOAD';
export const SEARCH_FILTER_SUCCESS = 'category/SEARCH_FILTER_SUCCESS';
export const SEARCH_FILTER_FAIL = 'category/SEARCH_FILTER_FAIL';

// virtulized scroll
export const APPEND_DATA_LOAD = 'category/APPEND_DATA_LOAD';
export const APPEND_DATA_AFTER = 'category/APPEND_DATA_AFTER';
export const FAIL_APPEND_DATA = 'category/FAIL_APPEND_DATA';

// on scroll category change
export const SAVE_CATEGORY_DATA_ON_SCROLL = 'category/SAVE_CATEGORY_DATA_ON_SCROLL';
export const DEBOUNCE_CATEGORY_DATA_ON_SCROLL = 'category/DEBOUNCE_CATEGORY_DATA_ON_SCROLL';

// udpate ditto on switchbar change
export const UPDATE_DITTO_ENABLE = 'category/UPDATE_DITTO_ENABLE';

// cache data show
export const SHOW_CACHED_DATA = 'category/SHOW_CACHED_DATA';
export const SAVE_CATEGORYDATA_TO_REDUX = 'category/SAVE_CATEGORYDATA_TO_REDUX';

export const DELETE_CATEGORYDATA = 'category/DELETE_CATEGORYDATA';

//  Subcategories load
export const SUBCATEGORY_DATA_LOAD = 'category/SUBCATEGORY_DATA_LOAD';
export const SUBCATEGORY_DATA_SUCCESS = 'category/SUBCATEGORY_DATA_SUCCESS';
export const SUBCATEGORY_DATA_FAIL = 'category/SUBCATEGORY_DATA_FAIL';

// Category listing config load
export const CATEGORY_CONFIG_JSON_LOAD = 'category/CATEGORY_CONFIG_JSON_LOAD';
export const CATEGORY_CONFIG_JSON_SUCCESS = 'category/CATEGORY_CONFIG_JSON_SUCCESS';
export const CATEGORY_CONFIG_JSON_FAIL = 'category/CATEGORY_CONFIG_JSON_FAIL';
