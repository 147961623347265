import React, { useEffect } from 'react';

import { HeaderOrthoKLens, BodyOrthoKLens, FooterOrthoKLens } from '../CommonComponents/OrthoKLens';

import '../CommonComponents/OrthoKLens/BaseOrthoKLens.scss';
import { odPushClevertapEvent } from '../../utils/clevertap';

const OrthoKLensHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/ortho-k-lens';

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Ortho-K Lens',
      pageUrl: window.location.href,
    });
  }, []);

  return (
    <div className="base-ortho-k-lens">
      <HeaderOrthoKLens baseImgUrl={baseImgUrl} />
      <BodyOrthoKLens baseImgUrl={baseImgUrl} />
      <FooterOrthoKLens baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default OrthoKLensHTML;
