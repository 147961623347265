import React, { Fragment, useEffect } from 'react';

import { LineupNav, LineupBanner, LineupProducts } from './Lineup/index';
import {
  settingsForSUN2107N4S,
  settingsForSUN2104B4S,
  settingsForSUN2093T2S,
  settingsForSUN8007J2S,
  settingsForSUN7001B2S,
  settingsForSUN7002B2S,
  settingsForSUN2102B4S,
  settingsForSUN1073B4S,
  settingsForSUN2103B4S,
  settingsForSUN1062T1S,
  settingsForSUN1063T1S,
  settingsForSUN2100T2S,
  settingsForSUN2106J4S,
  settingsForSUN1074B4S,
  settingsForSUN1075G4S,
  settingsForSUN1068T2S,
  settingsForSUN1069T2S,
  settingsForSUN8009B3S,
} from './configSliders';
import {
  SKU_SUN2107N_4S,
  SKU_SUN2104B_4S,
  SKU_SUN2093T_2S,
  SKU_SUN8007J_2S,
  SKU_SUN7001B_2S,
  SKU_SUN7002B_2S,
  SKU_SUN2102B_4S,
  SKU_SUN1073B_4S,
  SKU_SUN2103B_4S,
  SKU_SUN1062T_1S,
  SKU_SUN1063T_1S,
  SKU_SUN2100T_2S,
  SKU_SUN2106J_4S,
  SKU_SUN1074B_4S,
  SKU_SUN1075G_4S,
  SKU_SUN1068T_2S,
  SKU_SUN1069T_2S,
  SKU_SUN8009B_3S,
} from './constants';

import './BodyOwndaysSunglasses.scss';

export const BodyOwndaysSunglasses = ({ baseImgUrl }) => {
  const essentialProductSettings = [
    { settings: settingsForSUN2107N4S, sku: SKU_SUN2107N_4S },
    { settings: settingsForSUN2104B4S, sku: SKU_SUN2104B_4S },
    { settings: settingsForSUN2093T2S, sku: SKU_SUN2093T_2S },
    { settings: settingsForSUN8007J2S, sku: SKU_SUN8007J_2S },
    { settings: settingsForSUN7001B2S, sku: SKU_SUN7001B_2S },
    { settings: settingsForSUN7002B2S, sku: SKU_SUN7002B_2S },
  ];

  const modernProductSettings = [
    { settings: settingsForSUN2102B4S, sku: SKU_SUN2102B_4S },
    { settings: settingsForSUN1073B4S, sku: SKU_SUN1073B_4S },
    { settings: settingsForSUN2103B4S, sku: SKU_SUN2103B_4S },
    { settings: settingsForSUN1062T1S, sku: SKU_SUN1062T_1S },
    { settings: settingsForSUN1063T1S, sku: SKU_SUN1063T_1S },
    { settings: settingsForSUN2100T2S, sku: SKU_SUN2100T_2S },
  ];

  const classicProductSettings = [
    { settings: settingsForSUN2106J4S, sku: SKU_SUN2106J_4S },
    { settings: settingsForSUN1074B4S, sku: SKU_SUN1074B_4S },
    { settings: settingsForSUN1075G4S, sku: SKU_SUN1075G_4S },
    { settings: settingsForSUN1068T2S, sku: SKU_SUN1068T_2S },
    { settings: settingsForSUN1069T2S, sku: SKU_SUN1069T_2S },
    { settings: settingsForSUN8009B3S, sku: SKU_SUN8009B_3S },
  ];

  const scrollToSection = target => {
    const targetElement = document.querySelector(target);

    if (targetElement) {
      const scrollPosition = targetElement.offsetTop - 78;

      window.location.hash = target;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, [window.location.hash]);

  return (
    <Fragment>
      <section data-aos className="body-owndays-sunglasses__lineup-nav" data-aos-offset="-500">
        <div className="base-owndays-sunglasses__container">
          <h2 className="base-owndays-sunglasses__title">
            Lineup <small>8 designs with 4 colour options each</small>
          </h2>
          <p className="body-owndays-sunglasses__lineup-nav__price">S$78~</p>

          <LineupNav onClick={scrollToSection} />
        </div>
      </section>

      <section className="body-owndays-sunglasses__lineup">
        <div className="base-owndays-sunglasses__container">
          <LineupBanner
            baseImgUrl={baseImgUrl}
            desc="An everyday essential that everyone needs to own, featuring a selection of designs that are simple, yet perfectly solid and versatile. These are must-haves that will outlast any runway trend and always be in vogue."
            id="essential"
            imgPc="lineup-banner-1-pc"
            imgSp="lineup-banner-1-sp"
            subtitle="Versatile and suitable for everyone"
            title="Essential"
          />
          <LineupProducts productSettings={essentialProductSettings} />

          <LineupBanner
            isRight
            baseImgUrl={baseImgUrl}
            desc="Riding on the see-through trend and the popularity of oversized metal frames and edgy temple designs, we have a lineup of simple yet quirky sunglasses that embody all of the above."
            id="modern"
            imgPc="lineup-banner-2-pc"
            imgSp="lineup-banner-2-sp"
            subtitle="Elevate your style and unleash your personality"
            title="Modern"
          />
          <LineupProducts productSettings={modernProductSettings} />

          <LineupBanner
            baseImgUrl={baseImgUrl}
            desc="Created for those who are eyeing sunglasses with a unique twist, these classic, timeless designs are the perfect fusion of the old and the new. Available in an assortment of styles and vibrant colours to give you a chic summer vibe."
            id="classic"
            imgPc="lineup-banner-3-pc"
            imgSp="lineup-banner-3-sp"
            subtitle="An expression of modern sensibility paired with timeless appeal"
            title="Classic"
          />
          <LineupProducts productSettings={classicProductSettings} />

          <div className="base-owndays-sunglasses__btn-gp">
            <a
              className="base-owndays-sunglasses__btn base-owndays-sunglasses__btn--black"
              href="/sg/en/eyewear/sunglasses.html"
            >
              See All Sunglasses
            </a>
            <a
              className="base-owndays-sunglasses__btn base-owndays-sunglasses__btn--black"
              href="/stores/sg"
            >
              Search For Nearest OWNDAYS Stores
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
