import * as actionTypes from './../../actionTypes/collectionPage';

const initialState = {
  colUrl: '',
  collectionData: {},
  renderCollectionData: [],
  pristineCollectionData: {},
};

export default function collectionPage(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.COLLECTION_PAGE_DATA_SUCCESS:
      if (action?.data?.result?.result) {
        return Object.assign({}, prevState, {
          collectionData: action.data,
          pristineCollectionData: Object.assign({}, action.data),
          colUrl: action.url,
          renderCollectionData: action?.isBot
            ? action?.data?.result?.result
            : action?.data?.result?.result?.slice(0, 5),
        });
      }
      return Object.assign({}, prevState, {
        collectionData: action.data,
        pristineCollectionData: Object.assign({}, action.data),
        colUrl: action.url,
        renderCollectionData: action?.isBot
          ? action?.data?.result
          : action?.data?.result?.slice(0, 5),
      });

    case actionTypes.COLLECTION_PAGE_DATA_FAIL:
      return Object.assign({}, prevState, {
        collectionData: action.error,
      });
    case actionTypes.COLLECTION_PAGE_RENDER_DATA_UPDATE:
      return Object.assign({}, prevState, {
        renderCollectionData: prevState.renderCollectionData
          ? prevState.renderCollectionData.concat(action.data)
          : [].concat(action.data),
      });
    case actionTypes.COLLECTION_MERGED_DATA_UPDATE:
      const colData = prevState.collectionData;
      colData.result = action.data;
      return Object.assign({}, prevState, {
        collectionData: colData,
        renderCollectionData: colData?.result?.result?.slice(0, 5),
      });
    default:
      return prevState;
  }
}
