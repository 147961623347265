import * as actionTypes from '../../actionTypes/membershipPoints';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  applyMembershipData: null,
  applyMembershipError: null,
  removeMembership: null,
  removeMembershipError: null,
  pointsHistory: null,
  pointsHistoryError: null,
};

export default function membershipPoints(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_MEMBERSHIP_POINTS:
    case actionTypes.APPLY_MEMBERSHIP_POINTS:
    case actionTypes.REMOVE_MEMBERSHIP_POINTS:
      return {
        ...prevState,
        isLoading: true,
        error: null,
      };
    case actionTypes.GET_MEMBERSHIP_POINTS_SUCCESS:
      return {
        ...prevState,
        isLoading: false,
        data: { ...prevState.data, ...action.data },
      };
    case actionTypes.GET_MEMBERSHIP_POINTS_FAIL:
      return {
        ...prevState,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.APPLY_MEMBERSHIP_POINTS_SUCCESS:
      return {
        ...prevState,
        applyMembershipData: action.data,
        applyMembershipError: null,
        removeMembership: null,
        removeMembershipError: null,
      };
    case actionTypes.APPLY_MEMBERSHIP_POINTS_FAIL:
      return {
        ...prevState,
        applyMembershipData: null,
        applyMembershipError: action.error,
        removeMembership: null,
        removeMembershipError: null,
      };
    case actionTypes.REMOVE_MEMBERSHIP_POINTS_SUCCESS:
      return {
        ...prevState,
        removeMembership: action.data,
        removeMembershipError: null,
      };
    case actionTypes.REMOVE_MEMBERSHIP_POINTS_FAIL:
      return {
        ...prevState,
        removeMembership: null,
        removeMembershipError: action.error,
      };
    case actionTypes.GET_MEMBERSHIP_POINTS_HISTORY_SUCCESS:
      return {
        ...prevState,
        pointsHistory: action.data,
      };
    case actionTypes.GET_MEMBERSHIP_POINTS_HISTORY_FAIL:
      return {
        ...prevState,
        pointsHistoryError: action.error,
      };
    default:
      return prevState;
  }
}
