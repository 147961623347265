import React, { useEffect, useState } from 'react';

import { BlogNews } from './BlogNews';
import { NEWS_LIST } from './getNews';

import './BodyNews.scss';

export const BodyNews = ({ baseImgUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mainClassName, setMainClassName] = useState('body-news__news');

  const mainNew = NEWS_LIST.length ? NEWS_LIST[0] : null;

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
        setMainClassName('body-news__news body-news__news--active');
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
    return null;
  }, [isLoading]);

  return (
    mainNew && (
      <div className="body-news">
        <section
          className={`${mainClassName} body-news__l-content--inner body-news__l-content--inner--full-sp`}
        >
          <a href={mainNew.slug}>
            <figure className="body-news__news__card">
              <div className="body-news__news-thumbnail">
                <img alt="main-news-thumbnail" src={mainNew.image} />
              </div>
              <figcaption>
                <div className="body-news__news-details">
                  <div className="body-news__news-latest">
                    <img alt="Owndays Latest news" src={`${baseImgUrl}/latest-news.png`} />
                  </div>
                  <div className="body-news__news-text">
                    <p className="body-news__news-category">{mainNew.category}</p>
                    <h2 className="body-news__news-title">{mainNew.title}</h2>
                    <p className="body-news__news-description">{mainNew.description}</p>
                  </div>
                  <p className="body-news__news-link">VIEW MORE</p>
                </div>
              </figcaption>
            </figure>
          </a>
        </section>

        <BlogNews />
      </div>
    )
  );
};
