import { call, put, takeLatest, all, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/product';
import {
  GET_LENS_PACKAGES,
  GET_LENS_PACKAGES_SUCCESS,
  GET_LENS_PACKAGES_FAIL,
} from '../actionTypes/editPower';
import config from 'config';
import apiClient from '../helpers/apiClient';
import getSetCookie from '../cookie';
import { modifyProductData, setProductStaticDTM } from './responseDataManipulator';
import sessionStorageHelper from '../utils/sessionStorageHelper';
import { commonState } from './state';

let _client = null;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line
function* getProductData({ pID, params }) {
  const successAction = { type: actionTypes.PRODUCT_DATA_SUCCESS };
  const errorAction = { type: actionTypes.PRODUCT_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  let isExchange = false;
  if (__SERVER__) {
    const getCookies = client.getServerRequestHeader().cookies;
    if (getCookies.exchangeMethod) {
      isExchange = getCookies.exchangeMethod;
    }
  }
  if (__CLIENT__) {
    const isExchangeSessionValid =
      sessionStorageHelper.getItem('returnAction') ||
      sessionStorageHelper.getItem('returnProduct')?.returnAction;
    if (getSetCookie.getCookie('exchangeMethod') && isExchangeSessionValid) {
      isExchange = getSetCookie.getCookie('exchangeMethod');
    } else {
      isExchange = false;
    }
  }
  try {
    const data = yield call(async () => client.getProductData(pID, params, isExchange));
    const modifiedProductData = modifyProductData(data.result);
    const staticDTM = setProductStaticDTM(data.result, modifiedProductData, isExchange);
    successAction.data = data;
    successAction.modifiedProductData = modifiedProductData;
    successAction.staticDTM = staticDTM;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* checkStoreAvailability({ productName, nationNo, productId, offset, limit }) {
  const successAction = { type: actionTypes.CHECK_STORE_AVAILABILITY_SUCCESS };
  const errorAction = { type: actionTypes.CHECK_STORE_AVAILABILITY_FAIL };
  const client = { ..._client };

  try {
    const response = yield call(async () =>
      client.post(`${config.apiPath.checkStoreAvailability}`, {
        productName,
        nationNo,
        productId,
        offset,
        limit,
      })
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getColoredLensData({ pID, params }) {
  const successAction = { type: actionTypes.COLORED_LENS_DATA_SUCCESS };
  const errorAction = { type: actionTypes.COLORED_LENS_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  let isExchange = false;
  if (__SERVER__) {
    const getCookies = client.getServerRequestHeader().cookies;
    if (getCookies.exchangeMethod) {
      isExchange = getCookies.exchangeMethod;
    }
  }
  if (__CLIENT__) {
    const isExchangeSessionValid =
      sessionStorageHelper.getItem('returnAction') ||
      sessionStorageHelper.getItem('returnProduct')?.returnAction;
    if (getSetCookie.getCookie('exchangeMethod') && isExchangeSessionValid) {
      isExchange = getSetCookie.getCookie('exchangeMethod');
    } else {
      isExchange = false;
    }
  }
  try {
    const data = yield call(async () => client.getColoredLensData(pID, params, isExchange));
    const modifiedProductData = modifyProductData(data.result);
    const staticDTM = setProductStaticDTM(data.result, modifiedProductData, isExchange);
    successAction.data = data;
    successAction.modifiedProductData = modifiedProductData;
    successAction.staticDTM = staticDTM;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getExpressTimeData({ productId, pincode, countryCode }) {
  const successAction = { type: actionTypes.EXPRESS_TIME_SUCCESS };
  const errorAction = { type: actionTypes.EXPRESS_TIME_FAIL };
  const client = apiClient();
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(
        `${config.apiPath.estimateDelivery}${productId}/shipping/estimate?pinCode=${pincode}&country=${countryCode}&isExpress=true`
      )
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getDeliveryDetails({ productId, pincode, countryCode }) {
  const successAction = { type: actionTypes.GET_DELIVERY_DETAILS_SUCCESS };
  const errorAction = { type: actionTypes.GET_DELIVERY_DETAILS_FAILED };
  const client = apiClient();
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(
        `${config.apiPath.estimateDelivery}${productId}/shipping/estimate?pinCode=${pincode}&country=${countryCode}&isExpress=true`
      )
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* sendDittoEmail(emailActionData) {
  const successAction = { type: actionTypes.DITTO_IMG_EMAIL_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_IMG_EMAIL_FAIL };
  try {
    const response = yield call(async () =>
      _client.customPost(
        config.apiPath.emailDitto,
        emailActionData.data,
        'X-Session-Token',
        xApiClient,
        null
      )
    );
    successAction.data = response;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* loadClData({ id, powerOption }) {
  const successAction = { type: actionTypes.CONTACT_LENS_SUCCESS };
  const errorAction = { type: actionTypes.CONTACT_LENS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.dataProductUnSecure}${id}/powers?${powerOption}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* loadClSavedPrescription() {
  const successAction = { type: actionTypes.CL_SAVED_PRESCRIPTION_SUCCESS };
  const errorAction = { type: actionTypes.CL_SAVED_PRESCRIPTION_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.getSavedPrescription}?powerType=CONTACT_LENS`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* validateClPrescription({ powerData, productID }) {
  const successAction = { type: actionTypes.CL_PRESCRIPTION_VALID_SUCCESS };
  const errorAction = { type: actionTypes.CL_PRESCRIPTION_VALID_FAIL };
  try {
    const response = yield call(async () =>
      _client.customPost(
        `${
          !productID
            ? `${config.apiPath.cartApi}/validateCart`
            : `${config.apiPath.dataProductUnSecure}${productID}/powers/validate`
        }`,
        powerData,
        'X-Session-Token',
        xApiClient,
        null
      )
    );
    successAction.data = response;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* subscribeOOS(oosData) {
  const successAction = { type: actionTypes.OOS_SUCCESS };
  const errorAction = { type: actionTypes.OOS_FAIL };
  try {
    const response = yield call(async () =>
      _client.customPost(
        config.apiPath.oosSubscription,
        oosData.data,
        'X-Session-Token',
        xApiClient,
        null
      )
    );
    successAction.data = response;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* checkLensOnlyAvailability({ pincode }) {
  const successAction = { type: actionTypes.LENS_LOCATION_AVAILABILITY_SUCCESS };
  const errorAction = { type: actionTypes.LENS_LOCATION_AVAILABILITY_FAIL };
  try {
    const response = yield call(async () =>
      _client.get(`${config.apiPath.lensOnlyShippinglocation}${pincode}`, null, xApiClient, null)
    );
    successAction.data = response;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* loadLensPackages({
  prodId,
  powerType,
  frameType,
  isExpress = false,
  packages,
  isEditPower,
}) {
  const successAction = {
    type: isEditPower ? GET_LENS_PACKAGES_SUCCESS : actionTypes.LENS_PACKAGES_SUCCESS,
  };
  const errorAction = {
    type: isEditPower ? GET_LENS_PACKAGES_FAIL : actionTypes.LENS_PACKAGES_FAIL,
  };
  const client = { ..._client };
  let isExchange = false;
  if (__CLIENT__) {
    const isExchangeSessionValid =
      sessionStorageHelper.getItem('returnAction') ||
      sessionStorageHelper.getItem('returnProduct')?.returnAction;
    if (getSetCookie.getCookie('exchangeMethod') && isExchangeSessionValid) {
      isExchange = getSetCookie.getCookie('exchangeMethod');
    } else {
      isExchange = false;
    }
  }
  if (isExchange) {
    client.setHeader({ 'X-Service-Type': 'exchange' });
  } else {
    client.setHeader();
  }
  let allPackages = '';
  if (packages === 'all') allPackages = '&allPackages=true';
  try {
    const response = yield call(async () =>
      client.get(
        `${
          config.apiPath.lensPackages
        }${prodId}/packages?power_type=${powerType}&frame_type=${frameType}&is_express=${isExpress}&audienceType=${getSetCookie.getCookie(
          'audienceType'
        )}${allPackages}`
      )
    );
    successAction.data = response;
    successAction.pid = prodId;
    successAction.powerType = powerType;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    errorAction.pid = prodId;
    errorAction.powerType = powerType;
    yield put(errorAction);
  }
}

function* getCLSubscription({ productId, isBothEye }) {
  const successAction = { type: actionTypes.GET_CLSUBSCRIPTION_SUCCESS };
  const errorAction = { type: actionTypes.GET_CLSUBSCRIPTION_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.lensPackages}/${productId}/subscriptions?isBothEye=${isBothEye}`)
    );
    successAction.data = response ? response.result : {};
    successAction.data.productId = productId;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getSubscriptionDiscounts({ productId, subscriptionType }) {
  const successAction = { type: actionTypes.GET_SUBSCRIPTION_DISCOUNT_SUCCESS };
  const errorAction = { type: actionTypes.GET_SUBSCRIPTION_DISCOUNT_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(
        `${config.apiPath.subscriptionDiscounts}?productId=${productId}&subscriptionType=${subscriptionType}`
      )
    );
    const result = response ? response.result : {};
    successAction.data = result[productId] || {};
    successAction.data.productId = productId;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getCLSolutions({ productId }) {
  const successAction = { type: actionTypes.GET_CLSOLUTIONS_SUCCESS };
  const errorAction = { type: actionTypes.GET_CLSOLUTIONS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.lensPackages}/solutions?productId=${productId}`)
    );
    successAction.data = response ? response.result : [];
    successAction.data.productId = productId;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* postReview(params) {
  const successAction = { type: actionTypes.REVIEW_PRODUCT_SUCCESS };
  const errorAction = { type: actionTypes.REVIEW_PRODUCT_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    let response = yield call(async () =>
      client.post(`${config.apiPath.reviewProduct}${params.id}/review`, params.data)
    );
    response = response ? response.result : {};
    successAction.message = response.message;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getPackageOrCoatingGroup({ packageId, coatingId }) {
  const successAction = { type: actionTypes.GET_PACKAGE_OR_COATING_GROUP_SUCCESS };
  const errorAction = { type: actionTypes.GET_PACKAGE_OR_COATING_GROUP_FAIL };
  const client = { ..._client };
  client.setHeader();
  const params = {};
  if (packageId) params.packageId = packageId;
  if (coatingId) params.coatingId = coatingId;
  try {
    const response = yield call(async () =>
      client.get(config.apiPath.lensPackageAndCoating, { params })
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* get360viewStatus({ pid }) {
  const successAction = { type: actionTypes.GET_THREE_SIXTY_VIEW_SUCCESS };
  const errorAction = { type: actionTypes.GET_THREE_SIXTY_VIEW_FAIL };
  const client = { ..._client };
  try {
    const response = yield call(async () =>
      client.post(config.apiPath.threeSixtyViewStatus, { sku_id: pid })
    );
    successAction.data = response.data;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getSimilarProducts({ catID, paramName, paramValue, params }) {
  const successAction = { type: actionTypes.SIMILAR_PRODUCTS_SUCCESS };
  const errorAction = { type: actionTypes.SIMILAR_PRODUCTS_FAIL };
  const client = { ..._client };
  const reduxState = yield select(commonState);
  const listPageSize =
    xApiClient === 'mobilesite'
      ? reduxState.redisCommonData.LISTING_PAGE_SIZE
      : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
  const categoryPageSizeRedisCount = listPageSize === undefined ? 15 : listPageSize;
  // In case redis category page count set is not divisible by 3
  // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
  // ends
  client.setHeader();

  try {
    const categoryData = yield call(async () =>
      client.get(
        `${config.apiPath.dataCategory}${catID}?page-size=${categoryPageSizeRedisCount}&page=1${
          params ? `&${params}` : ''
        }&filter_${paramName}=${paramValue}`
      )
    );

    successAction.paramName = paramName;
    successAction.data = categoryData.result.product_list.map(product => ({
      ...product,
      colorOptions: product.color_options,
      productStatus: product.product_status,
      brandName: product.brand_name,
      avgRating: product.avg_rating,
      image: product.image_url,
      link: product.product_url,
      url: product.product_url,
    }));
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runProductCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.PRODUCT_DATA_LOAD, getProductData),
    takeEvery(actionTypes.SIMILAR_PRODUCTS_LOAD, getSimilarProducts),
    takeLatest(actionTypes.COLORED_LENS_DATA_LOAD, getColoredLensData),
    takeLatest(actionTypes.DITTO_IMG_EMAIL_SEND, sendDittoEmail),
    takeLatest(actionTypes.CONTACT_LENS_LOAD, loadClData),
    takeLatest(actionTypes.CL_SAVED_PRESCRIPTION_LOAD, loadClSavedPrescription),
    takeLatest(actionTypes.CL_PRESCRIPTION_VALID_TEST, validateClPrescription),
    takeLatest(actionTypes.EXPRESS_TIME_LOAD, getExpressTimeData),
    takeLatest(actionTypes.OOS_SUBSCRIPTION, subscribeOOS),
    takeLatest(actionTypes.LENS_LOCATION_AVAILABILITY_CHECK, checkLensOnlyAvailability),
    takeLatest(actionTypes.LENS_PACKAGES_LOAD, loadLensPackages),
    takeEvery(GET_LENS_PACKAGES, loadLensPackages),
    takeLatest(actionTypes.GET_CLSUBSCRIPTION, getCLSubscription),
    takeLatest(actionTypes.GET_SUBSCRIPTION_DISCOUNT, getSubscriptionDiscounts),
    takeLatest(actionTypes.GET_CLSOLUTIONS, getCLSolutions),
    takeLatest(actionTypes.GET_DELIVERY_DETAILS_LOAD, getDeliveryDetails),
    takeLatest(actionTypes.REVIEW_PRODUCT, postReview),
    takeLatest(actionTypes.GET_PACKAGE_OR_COATING_GROUP_LOAD, getPackageOrCoatingGroup),
    takeLatest(actionTypes.GET_THREE_SIXTY_VIEW_LOAD, get360viewStatus),
    takeLatest(actionTypes.CHECK_STORE_AVAILABILITY_LOAD, checkStoreAvailability),
  ]);
}
