import React from 'react';

import LinkProvider from '../../../CommonComponents/LinkProvider/LinkProvider';
import { onImageLoadError } from '../../../utils/helper';

import FBIcon from '../../../../static/icons/facebook-square.svg';
import xTwitterIcon from '../../../../static/icons/x-twitter.svg';
import lineIcon from '../../../../static/icons/line.svg';
import backIcon from '../../../../static/icons/back.svg';

import './FooterOwndaysRevampsBrand.scss';

export const FooterOwndaysRevampsBrand = () => {
  return (
    <div className="od-footer-owndays-revamps-brand">
      <ul>
        <li>
          <LinkProvider to="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/information/5001">
            <img
              alt=""
              className="od-footer-owndays-revamps-brand__icon"
              src={FBIcon}
              onError={e => onImageLoadError(e)}
            />
          </LinkProvider>
        </li>
        <li>
          <LinkProvider to="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/information/5001">
            <img
              alt=""
              className="od-footer-owndays-revamps-brand__icon"
              src={xTwitterIcon}
              onError={e => onImageLoadError(e)}
            />
          </LinkProvider>
        </li>
        <li>
          <LinkProvider to="https://line.me/R/msg/text/?https://www.owndays.com/sg/en/information/5001">
            <img
              alt=""
              className="od-footer-owndays-revamps-brand__icon"
              src={lineIcon}
              onError={e => onImageLoadError(e)}
            />
          </LinkProvider>
        </li>
      </ul>

      <LinkProvider
        className="od-footer-owndays-revamps-brand__back-nav"
        target="_self"
        to="/information"
      >
        <img
          alt=""
          className="od-footer-owndays-revamps-brand__icon"
          src={backIcon}
          onError={e => onImageLoadError(e)}
        />
        <span>Back</span>
      </LinkProvider>
    </div>
  );
};
