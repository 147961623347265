import * as actionTypes from './../../actionTypes/neft';

const initialState = {
  loading: false,
  editNeft: null,
};

export default function neft(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.EDIT_NEFT_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        editNeft: null,
      });
    case actionTypes.EDIT_NEFT_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        editNeft: action.data,
      });
    case actionTypes.EDIT_NEFT_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        editNeft: action.error,
      });
    default:
      return prevState;
  }
}
