import * as actionTypes from './../../actionTypes/store';

const initialState = { appointmentProcessing: null };

export default function store(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.STORE_LOAD:
      return Object.assign({}, prevState, {
        stores: null,
        storesLoading: true,
      });
    case actionTypes.STORE_LOAD_SUCCESS:
      return Object.assign({}, prevState, {
        stores: action.data,
        storesLoading: false,
      });
    case actionTypes.STORE_LOAD_FAIL:
      return Object.assign({}, prevState, {
        stores: null,
        storesLoading: false,
      });
    case actionTypes.RESET_STORE_DATA:
      return Object.assign({}, prevState, {
        stores: undefined,
        storesLoading: undefined,
        appointmentProcessing: null,
        appointmentDetails: null,
        appointmentError: null,
      });
    case actionTypes.CREATE_APPOINTMENT:
      return Object.assign({}, prevState, {
        appointmentProcessing: true,
        appointmentDetails: null,
        appointmentError: null,
      });
    case actionTypes.CREATE_APPOINTMENT_SUCCESS:
      return Object.assign({}, prevState, {
        appointmentProcessing: null,
        appointmentDetails: action.data,
        appointmentError: null,
      });
    case actionTypes.CREATE_APPOINTMENT_FAIL:
      return Object.assign({}, prevState, {
        appointmentProcessing: null,
        appointmentDetails: null,
        appointmentError: action.error,
      });
    default:
      return prevState;
  }
}
