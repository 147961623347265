import { call, put, takeLatest, all, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/success';
import { SET_ORDER, POWER_SUMBMIT_LOAD, POWER_SUMBMIT_FAIL } from '../actionTypes/order';
import { GET_POWERS, GET_POWERS_SUCCESS, GET_POWERS_FAIL } from '../actionTypes/editPower';
import config from 'config';
import getSetCookie from '../cookie';
import apiClient from '../helpers/apiClient';
const delay = ms => new Promise(res => setTimeout(res, ms));

let _client = null;
function* getResource(resource, successAction, errorAction) {
  try {
    const client = { ..._client };
    client.setHeader();
    const data = yield call(async () => client.get(resource));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getPowers({ productId, powerType, isEditPower }) {
  let url = `${config.apiPath.dataProduct}${productId}/powers${
    powerType && powerType !== 'CONTACT_LENS' ? '?power_type=' + powerType : ''
  }`;
  ('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
  url += powerType && powerType !== 'CONTACT_LENS' ? '&view=consumer' : '';
  ('#endif'); // eslint-disable-line
  yield call(
    getResource,
    url,
    {
      type: isEditPower ? GET_POWERS_SUCCESS : actionTypes.POWERS_DETAIL_SUCCESS,
      pid: productId,
      powerType,
    },
    {
      type: isEditPower ? GET_POWERS_FAIL : actionTypes.POWERS_DETAIL_FAIL,
      pid: productId,
      powerType,
    }
  );
}

function* getSavedPrescription({ powerType }) {
  const url = `${config.apiPath.getSavedPrescription}${powerType ? '?powerType=' + powerType : ''}`;
  yield call(
    getResource,
    url,
    { type: actionTypes.SAVED_PRESCRIPTION_SUCCESS },
    { type: actionTypes.SAVED_PRESCRIPTION_FAIL }
  );
}

function* submitPowers({ itemid, data, userEmail, orderId }) {
  yield put({ type: POWER_SUMBMIT_LOAD });
  const successAction = { type: actionTypes.SUBMIT_POWERS_SUCCESS };
  const errorAction = { type: actionTypes.SUBMIT_POWERS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    let reqUrl = `${config.apiPath.v2Orders}/${
      orderId || getSetCookie.getCookie('orderId')
    }/items/${itemid}/prescription`;
    if (userEmail !== '') reqUrl = `${reqUrl}?email=${userEmail}`;
    const dataResult = yield call(async () => client.put(reqUrl, data));
    successAction.data = dataResult;
    successAction.itemId = itemid;
    yield put(successAction);
    yield put({ type: SET_ORDER, orderDetails: dataResult });
  } catch (er) {
    errorAction.error = er;
    errorAction.itemId = itemid;
    yield put(errorAction);
    yield put({ type: POWER_SUMBMIT_FAIL, error: er });
  }
}

function* getStoreSlots({ storeCode }) {
  const successAction = { type: actionTypes.SLOTS_SUCCESS };
  const errorAction = { type: actionTypes.SLOTS_FAIL };
  const client = { ..._client };
  const skipCurrentDate = 0;
  const duration = 30;
  const classification = 1;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(`${config.apiPath.pfuStoreSlots}`, {
        storeCode,
        classification,
        duration,
        skipCurrentDate,
      })
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* pfuStoreBookAppointment({ orderId, itemId, appointmentData }) {
  const successAction = { type: actionTypes.APPOINTMENT_BOOK_SUCCESS };
  const errorAction = { type: actionTypes.APPOINTMENT_BOOK_FAIL };
  const client = { ..._client };
  // const { slotId, customerName, customerNumber, countryCode, date, storeCode, appointmentType } = appointmentData;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(
        `${config.apiPath.pfuStoreAppointment}?orderId=${orderId}&itemId=${itemId}`,
        appointmentData
      )
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getAppointmnetDetails({ appointmentId, source }) {
  const successAction = { type: actionTypes.GET_APPOINTMENT_DETAILS_SUCCESS };
  const errorAction = { type: actionTypes.GET_APPOINTMENT_DETAILS_FAIL };
  const client = { ..._client };
  // const { slotId, customerName, customerNumber, countryCode, date, storeCode, appointmentType } = appointmentData;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.getPfuAppointmentDetail}/${appointmentId}?source=${source}`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* cancelPfuAppointment({ orderId, itemId, params }) {
  const successAction = { type: actionTypes.CANCEL_PFU_APPOINTMNET_SUCCESS };
  const errorAction = { type: actionTypes.CANCEL_PFU_APPOINTMNET_FAIL };
  const client = { ..._client };
  // const { slotId, customerName, customerNumber, countryCode, date, storeCode, appointmentType } = appointmentData;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(
        `${config.apiPath.cancelPfuAppointment}?orderId=${orderId}&itemId=${itemId}`,
        params
      )
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getHecAppointmentDetail({ appointmentId }) {
  const successAction = { type: actionTypes.HEC_APPOINTMNET_SUCCESS };
  const errorAction = { type: actionTypes.HEC_APPOINTMNET_FAIL };
  const client = { ..._client };
  // const { slotId, customerName, customerNumber, countryCode, date, storeCode, appointmentType } = appointmentData;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.getHecDetail}/${appointmentId}`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* hecDetailJunoUpdate({ appointmentId }) {
  const successAction = { type: actionTypes.HEC_DETAIL_JUNO_UPDATE_SUCCESS };
  const errorAction = { type: actionTypes.HEC_DETAIL_JUNO_UPDATE_FAIL };
  const client = { ..._client };
  // const { slotId, customerName, customerNumber, countryCode, date, storeCode, appointmentType } = appointmentData;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.hecDetailJunoUpdate}/${appointmentId}?source=HEC`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* cancelHecAppointmnet({ appointmentId, reason }) {
  const successAction = { type: actionTypes.HEC_CANCEL_SUCCESS };
  const errorAction = { type: actionTypes.HEC_CANCEL_FAIL };
  const client = { ..._client };
  // const { slotId, customerName, customerNumber, countryCode, date, storeCode, appointmentType } = appointmentData;
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(
        `${config.apiPath.cancelHecAppointmnet}?orderId=${appointmentId}&reasonCancellation=${reason}`
      )
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* updatePackages({ itemid, data, orderId }) {
  yield delay(100);
  const successAction = { type: actionTypes.SUBMIT_PACKAGE_SUCCESS };
  const errorAction = { type: actionTypes.SUBMIT_PACKAGE_FAIL };
  const client = Object.assign({}, _client);
  client.defaults.headers = {};
  try {
    const reqUrl = `${config.apiPath.v2Orders}/${
      orderId || getSetCookie.getCookie('orderId')
    }/items/${itemid}/package`;
    const dataResult = yield call(async () =>
      client.put(reqUrl, data, {
        headers: {
          'X-Api-Client': 'desktop',
          'Cache-Control': 'no-cache, no-store',
          'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91',
          'X-Session-Token': getSetCookie.getCookie('clientV1'),
        },
      })
    );
    successAction.data = dataResult;
    successAction.itemId = itemid;
    yield put(successAction);
    yield put({ type: SET_ORDER, orderDetails: dataResult });
  } catch (er) {
    errorAction.error = er;
    errorAction.itemId = itemid;
    yield put(errorAction);
  }
}

function* getSubmittedPowers({ itemid, userEmail }) {
  let reqUrl = `${config.apiPath.v2Orders}/${getSetCookie.getCookie(
    'orderId'
  )}/items/${itemid}/prescription`;
  if (userEmail !== '') {
    reqUrl = `${reqUrl}?email=${userEmail}`;
  }
  yield call(
    getResource,
    reqUrl,
    { type: actionTypes.SUBMITTED_POWERS_SUCCESS },
    { type: actionTypes.SUBMITTED_POWERS_FAIL }
  );
}

function* getSubmittedOtp({ otp, email }) {
  const successAction = { type: actionTypes.OTP_SUBMISSION_SUCCESS };
  const errorAction = { type: actionTypes.OTP_SUBMISSION_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const reqUrl = `${config.apiPath.v2Orders}/${getSetCookie.getCookie(
      'orderId'
    )}/otp?value=${otp}&email=${email}`;
    const dataResult = yield call(async () => client.put(reqUrl));
    successAction.data = dataResult;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* uploadCameraPdValue({ formData }) {
  const successAction = { type: actionTypes.PD_CAMERA_UPLOAD_SUCCESS };
  const errorAction = { type: actionTypes.PD_CAMERA_UPLOAD_FAIL };
  const client = { ..._client };
  client.setHeader({ 'Content-Type': 'multipart/form-data' });
  try {
    const data = yield call(async () => client.post(`${config.apiPath.cameraPdUpload}`, formData));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runSuccessCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.POWERS_DETAIL_LOAD, getPowers),
    takeEvery(GET_POWERS, getPowers),
    takeLatest(actionTypes.SAVED_PRESCRIPTION_LOAD, getSavedPrescription),
    takeLatest(actionTypes.SUBMITTED_POWERS_LOAD, getSubmittedPowers),
    takeLatest(actionTypes.SUBMIT_POWERS_LOAD, submitPowers),
    takeLatest(actionTypes.OTP_SUBMISSION_LOAD, getSubmittedOtp),
    takeLatest(actionTypes.SUBMIT_PACKAGE_LOAD, updatePackages),
    takeLatest(actionTypes.PD_CAMERA_UPLOAD_LOAD, uploadCameraPdValue),
    takeLatest(actionTypes.SLOTS_LOAD, getStoreSlots),
    takeLatest(actionTypes.APPOINTMENT_BOOK_LOAD, pfuStoreBookAppointment),
    takeLatest(actionTypes.GET_APPOINTMENT_DETAILS_LOAD, getAppointmnetDetails),
    takeLatest(actionTypes.CANCEL_PFU_APPOINTMNET_LOAD, cancelPfuAppointment),
    takeLatest(actionTypes.HEC_APPOINTMNET_LOAD, getHecAppointmentDetail),
    takeLatest(actionTypes.HEC_CANCEL_LOAD, cancelHecAppointmnet),
    takeLatest(actionTypes.HEC_DETAIL_JUNO_UPDATE_LOAD, hecDetailJunoUpdate),
  ]);
}
