import React from 'react';
import Redirect from '../Redirect/Redirect';
import KimetsuCollectionHTML from '../../own-days/html/KimetsuCollectionHTML';

const TimerKimetsu = () => {
  const currentDate = new Date();
  const startDeactivate = new Date('2024-10-10T03:00:00Z');
  const endDeactivate = new Date('2024-10-10T04:00:00Z');
  const inStartEvent = currentDate >= startDeactivate;
  const inEndEvent = currentDate <= endDeactivate;

  if (inStartEvent && inEndEvent) {
    return <Redirect to="/news" />;
  } else {
    return <KimetsuCollectionHTML />;
  }
};

export default TimerKimetsu;
