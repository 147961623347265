import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../src/desktop/components/Common/BlankLoader.js';

const CheckoutLoadable = Loadable({
  loader: () =>
    import('./OdCheckout.js' /* webpackChunkName: 'checkout' */).then(module => module.default),
  loading: () => <Loader />,
});

export default CheckoutLoadable;
