import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../src/desktop/components/Common/BlankLoader.js';

const ResetPasswordLoadable = Loadable({
  loader: () =>
    import('./ResetPassword.js' /* webpackChunkName: 'resetPassword' */).then(
      module => module.default
    ),
  loading: () => <Loader />,
});

export default ResetPasswordLoadable;
