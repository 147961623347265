import React from 'react';

import { DotPalette } from '../DotPalette/DotPalette';

const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-pc';

const defaultSetting = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  fade: true,
  draggable: false,
  infinite: true,
  swipe: false,
  touchMove: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        draggable: true,
        infinite: true,
        swipe: true,
        touchMove: true,
      },
    },
  ],
};

const CustomDots = props => {
  const backgroundColors = {
    'PC2007N-2S': ['#3E3A39', '#8A4B3C', '#1A406B', '#7E6839'],
    'PC2008N-2S': ['#3E3A39', `${baseImgUrl}/brown-demi.jpg`, '#8A4B3C', '#20565D'],
  };

  return (
    <DotPalette {...props} backgroundColors={backgroundColors} containerClassName="product-od-pc" />
  );
};

const initialSlides = {
  'PC2007N-2S': 2,
  'PC2008N-2S': 0,
};

const createSettings = sku => {
  const initialSlide = initialSlides[sku];

  return {
    ...defaultSetting,
    initialSlide,
    beforeChange: (oldIndex, newIndex) => {
      initialSlides[sku] = newIndex;
    },
    appendDots: dots => <CustomDots dots={dots} initialSlide={initialSlides[sku]} sku={sku} />,
  };
};

// Generate settings for each SKU
export const settingsForPC2007N2S = createSettings('PC2007N-2S');
export const settingsForPC2008N2S = createSettings('PC2008N-2S');
