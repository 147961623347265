import React from 'react';
import { useSelector } from 'react-redux';

const CartSummary = ({ cartObj, currencySymbol }) => {
  const dataLocale = useSelector(state => state.locale);
  const {
    SUBTOTAL,
    EXTRA_DISCOUNT,
    NET_AMOUNT,
    TAX_COLLECTED,
    PART_TAX_COLLECTED,
    CONVENIENCE_FEES,
    FREE,
  } = dataLocale || {};

  return (
    <div className="row-fluid pyment-crt-blck overflow-visible">
      <table className="row-fluid payment-crt-tb">
        <tbody>
          <tr>
            <td>
              <div className="pric-hedng text-capitalize">{SUBTOTAL}</div>
              <div>
                {currencySymbol}
                {cartObj.totals.subTotal}
                <div></div>
              </div>
            </td>
            {cartObj.totals.totalDiscount > 0 && (
              <>
                <td>
                  <i className="icon- icn sgnminus"></i>
                </td>
                <td>
                  <div className="pric-hedng">
                    {EXTRA_DISCOUNT}
                    {cartObj.discountDescription && (
                      <span id="discount-cart">
                        <i className="icon- mob-icon-question-mark tip">
                          <span className="discount-cont">
                            {cartObj.discountDescription}
                            <span className="discount-arrow"></span>
                          </span>
                        </i>
                      </span>
                    )}
                  </div>
                  <div>
                    <span>
                      {currencySymbol}
                      {cartObj.totals.totalDiscount}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="pls-crt-sign">=</div>
                </td>
              </>
            )}
            {cartObj.totals.totalDiscount === 0 && (
              <td>
                <i className="icon- icn fs20 font-style-normal">=</i>
              </td>
            )}
            <td>
              <div className="pric-hedng">{NET_AMOUNT}</div>
              <div>
                <span>
                  {currencySymbol}
                  {(cartObj.totals.subTotal - cartObj.totals.totalDiscount)
                    .toString()
                    ?.indexOf('.') !== -1
                    ? (cartObj.totals.subTotal - cartObj.totals.totalDiscount).toFixed(2)
                    : cartObj.totals.subTotal - cartObj.totals.totalDiscount}
                </span>
              </div>
            </td>
            {cartObj.totals.totalTax > 0 && (
              <>
                <td>
                  <i className="icon- icn sgnplus"></i>
                </td>
                <td>
                  <div className="pric-hedng">
                    {TAX_COLLECTED}.
                    <span id="discount-cart">
                      <i className="icon- mob-icon-question-mark tip discount-cart">
                        <span className="discount-cont">
                          {PART_TAX_COLLECTED}
                          <span className="discount-arrow"></span>
                        </span>
                      </i>
                    </span>
                  </div>
                  <div>
                    {currencySymbol}
                    {cartObj.totals.totalTax}
                  </div>
                </td>
              </>
            )}
            <td>
              <i className="icon- icn sgnplus"></i>
            </td>
            <td>
              <div className="pric-hedng">{CONVENIENCE_FEES}</div>
              <div>
                {cartObj.totals.shipping > 0 ? (
                  <span>
                    {currencySymbol}
                    {cartObj.totals.shipping}
                  </span>
                ) : (
                  <span>{FREE}</span>
                )}
              </div>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </table>
    </div>
  );
};

export default CartSummary;
