import React, { useEffect, useRef } from 'react';
import Close from '../../../../static/assets/svg/mui-icons/Close.svg';
import './sidebarpopup.scss';

function SideBarPopup(props) {
  const { dialogClassName, open, onClose, width, crossIconStyle = '' } = props;
  const mySidenavRef = useRef();
  const backdropRef = useRef();

  useEffect(() => {
    if (mySidenavRef) {
      mySidenavRef.current.style.width = width || '55vw';
    }
    if (backdropRef) {
      backdropRef.current.style.display = 'block';
    }
    return () => {
      if (mySidenavRef) {
        mySidenavRef.current.style.width = 0;
      }
      if (backdropRef) {
        backdropRef.current.style.display = 'none';
      }
    };
  }, [open, width]);

  return (
    <>
      <div
        ref={mySidenavRef}
        className={`navigation-drawer-sidenav ${dialogClassName || ''}`}
        id="mySidenav"
      >
        <div id="idToScrollTop"></div>
        <div className={`closeicon ${crossIconStyle || ''}`} onClick={() => onClose(false)}>
          <img alt="close-icon" src={Close} />
        </div>
        {props.children}
      </div>
      <div ref={backdropRef} className="backdrop-container" id="navigation-drawer-backdrop"></div>
    </>
  );
}

export default SideBarPopup;
