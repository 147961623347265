import * as actionTypes from '../actionTypes/common';
import { CLEAR_CMS_DATA } from '../actionTypes/cmsMapping';

export function getRedisRouter(args) {
  return { type: actionTypes.GET_REDIS_ROUTER_LOAD, ...args };
}

export function localeInfo(data) {
  return { type: actionTypes.SET_COUNTRY_INFO, data };
}

export function getRedisData(isBot = false) {
  return { type: actionTypes.GET_REDIS_DATA, isBot };
}

export function getSharktankData(isBot = false) {
  return { type: actionTypes.GET_SHARKTANK_DATA, isBot };
}

export function getLensMappingData() {
  return { type: actionTypes.GET_LENS_MAPPING_DATA };
}

export function validateSession(path) {
  return { type: actionTypes.GET_USER, path };
}

export function validateSessionV2(path) {
  return { type: actionTypes.VALIDATE_SESSION, path };
}

export function setJunoSession() {
  return { type: actionTypes.LOAD_JUNO_SESSION };
}

export function shortlist(pid, method, query) {
  if (method.toLowerCase() === 'get') {
    return { type: actionTypes.SHORTLIST_LOAD, pid, method, query };
  } else if (method.toLowerCase() === 'post') {
    return { type: actionTypes.SHORTLIST_ADD, pid, method, query };
  }
  return { type: actionTypes.SHORTLIST_DELETE, pid, method, query };
}

export function showWishlist(value) {
  return { type: actionTypes.WISHLIST_TRUE, value };
}

export function shortListType(shortListValue) {
  return { type: actionTypes.SHORTLIST_TYPE, shortListValue };
}

export function getUserInfo() {
  return { type: actionTypes.GET_USER_LOAD };
}

export function toggleCart(value, dtmActionType) {
  window.dtm.actiontype = dtmActionType;
  return {
    type: actionTypes.CART_POPUP,
    value,
  };
}

export function changeSection(value, isDataLoaded) {
  return { type: actionTypes.CHANGE_SECTION, value, isDataLoaded };
}

export function getDittoProfiles() {
  return { type: actionTypes.DITTO_PROFILE_LOAD };
}

export function saveDittoName(dittoNameObj) {
  return { type: actionTypes.DITTO_NAME_SAVE, dittoNameObj };
}

export function setDitto(dittoId) {
  return { type: actionTypes.DITTO_DEFAULT_SET, dittoId };
}

export function updateDittoChangedState(changedState) {
  return { type: actionTypes.DITTO_CHANGED_STATUS_UPDATE, changedState };
}

export function deleteDitto(dittoId) {
  return { type: actionTypes.DITTO_DELETE, dittoId };
}

export function dittoAuth(dittoId) {
  return { type: actionTypes.DITTO_AUTH_LOAD, dittoId };
}

export function logoutUser() {
  return { type: actionTypes.LOGOUT_USER };
}

export function modifyData(data) {
  return { type: actionTypes.MODIFY_DATA, data };
}

export function changeLogo(logoType, pageType) {
  return { type: actionTypes.CHANGE_LOGO, logoType, pageType };
}

export function askUserLogin(data) {
  return { type: actionTypes.ASK_USER_LOGIN, data };
}

export function setToastMessage(data) {
  return { type: actionTypes.SET_TOAST_MESSAGE, data };
}

export function setSizeDetail(data) {
  return { type: actionTypes.SET_SIZE_DETAIL, data };
}

export function getCountryData(code) {
  return { type: actionTypes.COUNTRY_DATA_LOAD, code };
}

export function clearCmsData(data) {
  return { type: CLEAR_CMS_DATA, data };
}

export function getCmsPageData(cmsPath) {
  return { type: actionTypes.PDP_CMS_POPUP_LOAD, cmsPath };
}
export function setYotpoWidgetsState(data) {
  clearTimeout(this.yotpoRefreshTimeout);
  if (window && window.Yotpo && window.yotpo && window.yotpo.initialized) {
    const yotpoApi = new window.Yotpo.API(window.yotpo);
    if (yotpoApi) {
      this.yotpoRefreshTimeout = setTimeout(() => yotpoApi.refreshWidgets(), 500);
    }
  }
  return { type: actionTypes.YOTPO_WIDGETS_SHOW, data };
}
export function getAudienceType() {
  return { type: actionTypes.AUDIENCE_TYPE_LOAD };
}

export function updateTryOnSwitch(data) {
  return { type: actionTypes.TRY_ON_SWITCH, data };
}
export function getCustomerInfo(customerId) {
  return { type: actionTypes.GET_CUSTOMER_INFO_LOAD, customerId };
}
export function embedVideo(data) {
  return { type: actionTypes.EMBED_VIDEO, data };
}
export function setIsWebView() {
  return { type: actionTypes.SET_IS_WEBVIEW };
}
export function setPhonepeView(flag) {
  return { type: actionTypes.IS_PHONEPEVIEW, flag };
}
export function passUtmData(data) {
  return { type: actionTypes.PASS_UTM_DATA_LOAD, data };
}

export function saveLeadGenData(data) {
  return { type: actionTypes.LEAD_GEN_DATA_LOAD, data };
}

export function saveMarketingSubscriptionDataFunc(data) {
  return { type: actionTypes.MARKETING_SUBSCRIPTION_LOAD, data };
}

export function getMarketingSubscriptionDataFunc() {
  return { type: actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_LOAD };
}

export function plpMobileViewChange() {
  return { type: actionTypes.PLP_MOBILE_VIEW_LAYOUT_CHANGE };
}

export function fetchPidDataFromBarcode(dataObj) {
  return { type: actionTypes.FETCH_PID_DATA_FROM_BARCODE_LOAD, data: dataObj };
}

export function showBarcodeModal(boolean) {
  return { type: actionTypes.BARCODE_MODAL_STATE, data: boolean };
}
