import * as actionTypes from './../../actionTypes/ditto';

const initialState = {
  dittoSaved: false,
  addDittoProfile: null,
};

export default function ditto(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_IMAGE_FROM_CYGNUS_LOAD:
      return Object.assign({}, prevState, {
        overlayedDittoLoading: true,
      });
    case actionTypes.GET_IMAGE_FROM_CYGNUS_SUCCESS:
      return Object.assign({}, prevState, {
        overlayedDittoLoading: false,
        overlayedDittoData: action.data,
        overLayedDittoError: null,
      });
    case actionTypes.GET_IMAGE_FROM_CYGNUS_FAIL:
      return Object.assign({}, prevState, {
        overlayedDittoLoading: false,
        overlayedDittoData: null,
        overLayedDittoError: action.error,
      });
    case actionTypes.UPDATE_CUSTOMER_CYGNUS_LOAD:
      return Object.assign({}, prevState, {
        updateCustomerCygnusLoading: true,
      });
    case actionTypes.UPDATE_CUSTOMER_CYGNUS_SUCCESS:
      return Object.assign({}, prevState, {
        updateCustomerCygnusLoading: false,
        updateCustomerCygnusData: action.data,
        updateCustomerCygnusError: null,
      });
    case actionTypes.UPDATE_CUSTOMER_CYGNUS_FAIL:
      return Object.assign({}, prevState, {
        updateCustomerCygnusLoading: false,
        updateCustomerCygnusData: null,
        updateCustomerCygnusError: action.error,
      });
    case actionTypes.UPLOAD_IMAGE_TO_CYGNUS_LOAD:
      return Object.assign({}, prevState, {
        dittoUploading: true,
        uploadedDittoData: null,
        dittoError: null,
      });
    case actionTypes.UPLOAD_IMAGE_TO_CYGNUS_SUCCESS:
      return Object.assign({}, prevState, {
        dittoUploading: false,
        uploadedDittoData: action.data,
        dittoGuestId: action.data?.isGuest && action.data.id,
        dittoError: null,
      });
    case actionTypes.UPLOAD_IMAGE_TO_CYGNUS_FAIL:
      return Object.assign({}, prevState, {
        dittoUploading: false,
        uploadedDittoData: null,
        dittoError: action.error,
      });
    case actionTypes.SAVE_UPDATE_DITTO_LOAD:
      return Object.assign({}, prevState, {
        dittoSaved: false,
      });
    case actionTypes.SAVE_UPDATE_DITTO_SUCCESS:
      return Object.assign({}, prevState, {
        dittoSaved: true,
      });
    case actionTypes.SAVE_UPDATE_DITTO_FAIL:
      return Object.assign({}, prevState, {
        dittoSaved: false,
      });
    case actionTypes.ADD_DITTO_LOAD:
      return Object.assign({}, prevState, {
        dittoSaved: false,
      });
    case actionTypes.ADD_DITTO_SUCCESS:
      return Object.assign({}, prevState, {
        addDittoProfile: action.data,
        dittoSaved: true,
      });
    case actionTypes.ADD_DITTO_FAIL:
      return Object.assign({}, prevState, {
        addDittoProfile: action.error,
        dittoSaved: false,
      });
    default:
      return prevState;
  }
}
