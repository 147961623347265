import React from 'react';
import { Link } from 'react-router-dom';
import './CartFooter.scss';

const CartFooter = props => {
  const {
    checkOutHandler,
    checkoutLoader,
    checkoutCTA,
    isframeProduct,
    dataLocale,
    redisCommonData = {},
    cartObj,
  } = props;
  return (
    <div aria-label="cart footer container" className="cart-footer col">
      {!checkoutLoader && (
        <Link
          aria-label="proceed to checkout"
          className="button-green"
          to="/checkout/onepage/"
          onClick={() => {
            checkOutHandler(true);
            _gaq.push(
              ['_trackEvent', 'Place Order', 'Cod'],
              ['ninja._trackEvent', 'Place Order', 'Cod']
            );
          }}
        >
          {redisCommonData?.STUDIO_FLOW_WEB && cartObj?.studioFlow ? (
            <span>{dataLocale.CONTINUTE_SELECT_STORE}</span>
          ) : (
            <>
              <span>
                {checkoutCTA && checkoutCTA.mainText
                  ? checkoutCTA.mainText
                  : dataLocale.PROCEED_TO_CHECKOUT}
              </span>
              <i className="icon-chkout-arw"></i>
              {isframeProduct && checkoutCTA && checkoutCTA.subText && (
                <span className="sub-text">{checkoutCTA.subText}</span>
              )}
            </>
          )}
        </Link>
      )}
      {checkoutLoader && (
        <div aria-label="cart is loading" className="loaderLK proceed-checkout-loader">
          <div className="barLK"></div>
          <div className="barLK"></div>
          <div className="barLK"></div>
        </div>
      )}
    </div>
  );
};

export default CartFooter;
