import React, { Fragment } from 'react';

import { DescriptionPolicy, FormPolicyWithEmail } from '../CommonComponents/Policy';

const PolicyWithEmailHTML = () => {
  return (
    <Fragment>
      <DescriptionPolicy />
      <FormPolicyWithEmail />
    </Fragment>
  );
};

export default PolicyWithEmailHTML;
