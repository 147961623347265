import * as actionTypes from './../../actionTypes/footer';

const initialState = {
  loaded: false,
  loading: false,
  enterPowerRespMsg: null,
};
export default function footer(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.ENTER_POWER_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.ENTER_POWER_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        enterPowerRespMsg: action.data,
      });
    case actionTypes.ENTER_POWER_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        enterPowerRespMsg: 'System error occurred. Please try after some time...',
      });
    default:
      return prevState;
  }
}
