import React from 'react';

const ArticleDetailsHeader = ({article}) => {
  return (
    <>
      <div className="article-details__header">
        <div className="article-details__container">
          <p>{article?.date}</p>
          <h1>{article?.title}</h1>
        </div>
        <img src={article?.img} alt={article?.title} loading="lazy" />
      </div>
    </>
  );
};

export default ArticleDetailsHeader;
