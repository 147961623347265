export const GET_CLIENT_TOKEN_LOAD = 'GET_CLIENT_TOKEN_LOAD';
export const GET_CLIENT_TOKEN_SUCCESS = 'GET_CLIENT_TOKEN_SUCCESS';
export const GET_CLIENT_TOKEN_FAIL = 'GET_CLIENT_TOKEN_FAIL';

export const GET_PAYMENT_METHODS_WITH_PRIMER_LOAD = 'GET_PAYMENT_METHODS_WITH_PRIMER_LOAD';
export const GET_PAYMENT_METHODS_WITH_PRIMER_SUCCESS = 'GET_PAYMENT_METHODS_WITH_PRIMER_SUCCESS';
export const GET_PAYMENT_METHODS_WITH_PRIMER_FAIL = 'GET_PAYMENT_METHODS_WITH_PRIMER_FAIL';

export const GET_PRIMER_SAVED_CARDS_LOAD = 'GET_PRIMER_SAVED_CARDS_LOAD';
export const GET_PRIMER_SAVED_CARDS_SUCCESS = 'GET_PRIMER_SAVED_CARDS_SUCCESS';
export const GET_PRIMER_SAVED_CARDS_FAIL = 'GET_PRIMER_SAVED_CARDS_FAIL';

export const PRIMER_PAYMENT_STATUS_UPDATE_LOAD = 'PRIMER_PAYMENT_STATUS_UPDATE_LOAD';
export const PRIMER_PAYMENT_STATUS_UPDATE_SUCCESS = 'PRIMER_PAYMENT_STATUS_UPDATE_SUCCESS';
export const PRIMER_PAYMENT_STATUS_UPDATE_FAIL = 'PRIMER_PAYMENT_STATUS_UPDATE_FAIL';

export const RESET_PRIMER_DATA = 'RESET_PRIMER_DATA';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
