import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/cancelOrder';
import apiClient from '../helpers/apiClient';
import config from 'config';
import sessionStorageHelper from '../utils/sessionStorageHelper';

let _client = null;

function* getCancelReasons({ orderId }) {
  const successAction = { type: actionTypes.GET_CANCEL_REASONS_SUCCESS };
  const errorAction = { type: actionTypes.GET_CANCEL_REASONS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.cancelReasons}${orderId}/cancel/reasons`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* cancelOrder({ formData, orderId }) {
  const successAction = { type: actionTypes.CANCEL_ORDER_SUCCESS };
  const errorAction = { type: actionTypes.CANCEL_ORDER_FAIL };

  const client = { ..._client };
  client.setHeader();

  try {
    const data = yield call(async () =>
      client.post(`${config.apiPath.cancelReasons}${orderId}/cancel`, formData)
    );
    successAction.data = data;
    sessionStorageHelper.setItem('cancelProductResult', data);
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runCancelOrderCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_CANCEL_REASONS, getCancelReasons),
    takeLatest(actionTypes.CANCEL_ORDER_LOAD, cancelOrder),
  ]);
}
