import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/newMSiteOfflineSalesFlow';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;
function* pushToCampaignOfflineSales({ data }) {
  const url = config.apiPath.campaignData + '/camp_MSiteStoreStaffSalesData';
  const client = { ..._client };
  client.setHeader();
  try {
    yield call(async () => client.post(url, { collectionData: [data] }));
  } catch (err) {
    console.log(err);
  }
}

function* pushToPosOfflineSales({ data }) {
  const url = config.apiPath.posSeamlessLink;
  try {
    yield call(async () =>
      _client.post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'X-Lenskart-App-Id': 'connect',
          'X-Lenskart-API-Key': 'valyoo123',
        },
      })
    );
  } catch (err) {
    console.log(err);
  }
}

function* verifySalesman({ id }) {
  const successAction = { type: actionTypes.SALESMAN_ID_VERIFY_SUCCESS };
  const errorAction = { type: actionTypes.SALESMAN_ID_VERIFY_FAIL };
  const url = config.apiPath.getSalesmanDetails;
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () => client.get(`${url}/${id}`));
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

export function* runNewMSiteOfflineSalesFlowCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.PUSH_TO_CAMPAIGN_OFFLINE_SALES, pushToCampaignOfflineSales),
    takeLatest(actionTypes.PUSH_TO_POS_OFFLINE_SALES, pushToPosOfflineSales),
    takeLatest(actionTypes.SALESMAN_ID_VERIFY, verifySalesman),
  ]);
}
