import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no">
<meta name="og:title" content="Online Order Form | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="This is the page to pre-order Gundam Head Case." />
<meta name="keywords" content="Online Order Form,Limited Quantity,GUNDAM HEAD CASE,GUNDAM,40th Anniversary,Collaboration,spectacles,glasses,eye glasses,sunglasses,OWNDAYS,SNAP" />
<meta name="og:description" content="This is the page to pre-order Gundam Head Case." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
<meta name="format-detection" content="telephone=no">
<meta name="og:title" content="GUNDAM HEAD CASE [Mobile Suit Gundam 40th Anniversary Merchandise] | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="description" content="As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for." />
<meta name="keywords" content="HEAD CASE,GUNDAM,40th Anniversary,Collaboration,spectacles,glasses,eye glasses,sunglasses,OWNDAYS" />
<meta name="og:description" content="As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for." />
<meta property="og:title" content="GUNDAM HEAD CASE [Mobile Suit Gundam 40th Anniversary Merchandise]" />
<meta property="og:description" content="As one of Japan’s most iconic anime series, Mobile Suit Gundam has transcended the boundaries of anime, earning fans all over the world. Similarly, OWNDAYS has pushed past the eyewear frontier to launch this collaboration, adding new dimensions to the repertoire of Mobile Suit Gundam. Careful attention to detail and the intricate finishing exclusive to this collaboration have given it an unparalleled charm that is over and beyond what a spectacle storage case would usually call for." />
<meta name="robots" content="noindex,nofollow" />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/collabo/gundam/pj-head/reservation">
                <meta property="og:image" content="https://www.owndays.com/images/collabo/gundam/pj-head/ogp_1200x630.jpg?1666001661">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link href="https://www.owndays.com/web/css/login.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/collabo-gundam-form.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/cart-checkout.css" rel="stylesheet">
<style>
    #walletWidgetDiv {
        width: 100%;
        height: 228px;
        margin-top: 15px;
        min-width: 300px;
        max-width: 600px;
        min-height: 228px;
        max-height: 400px;
    }

    .StripeElement {
        box-sizing: border-box;
        height: 40px;
        padding: 10px 12px;
        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;
        box-shadow: 0 1px 3px 0 #a5a5a5;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }

    #stripe-card-element {
        width: 100%;
    }
</style>
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">Online Order Form | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <section class="l-gundam__head--default l-gundam__form l-content--inner">
    <span class="category">GUNDAM HEAD CASE</span>
    <h1>Online Order Form</h1>
    <ul class="p-flow--horizontal">
        <li class="active">
            <p class="u-pc-only">Enter details</p>
            <p class="u-sp-only">Enter details</p>
        </li>
        <li>
            <p class="u-pc-only">Confirm Details</p>
            <p class="u-sp-only">Confirm Details</p>
        </li>
        <li>Order Completed</li>
    </ul>
    <p class="u-mt__30 u-mb__30 sp-wide">Please click “Confirm Details” after completing the form.<br><span class="u-text-important">*</span>Required fields</p>
    
    <form class="frm-input" method="POST" action="/sg/en/collabo/gundam/pj-head/reservation/confirm" enctype="multipart/form-data">
        <input type="hidden" name="_token" value="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
        <section class="l-gundam__form-content c-form">
            <section class="l-gundam__form-block l-gundam__form-products">
                <h2>Product</h2>
                <div class="l-gundam__form-head">
                    <img src="https://www.owndays.com/images/collabo/gundam/pj-head/head-image.jpg" alt="">
                    <div>
                        <p>[Limited Quantity Available]<br>Gundam Head Case</p>
                        <p class="c-price c-price__default">S$ 580<small></small></p>
                    </div>
                </div>
            </section>
            <hr>
            <section class="l-gundam__form-block l-gundam__form-quantity">
                <h2>Quantity</h2>
                <div class="l-gundam__form-head">
                    <div class="c-form__select">
                        <select name="quantity" id="quantity">
                             <option value="1" >
                                1
                                </option>
                                 <option value="2" >
                                2
                                </option>
                                 <option value="3" >
                                3
                                </option>
                                 <option value="4" >
                                4
                                </option>
                                 <option value="5" >
                                5
                                </option>
                                 <option value="6" >
                                6
                                </option>
                                 <option value="7" >
                                7
                                </option>
                                 <option value="8" >
                                8
                                </option>
                                 <option value="9" >
                                9
                                </option>
                                 <option value="10" >
                                10
                                </option>
                                                        </select>
                    </div>
                    <button type="ิbutton" class="reload"><i class="fas fa-sync-alt"></i>Recalculation</button>
                </div>
            </section>
            <hr>
            <section class="l-gundam__form-block l-gundam__form-quantity">
                <h2>Total</h2>
                <div class="l-gundam__form-head">
                    <p class="c-price c-price__default">S$<span id="total-price-with-tax"></span></p>
                </div>
            </section>
            <hr>
                        <section class="l-gundam__form-block l-gundam__form-payment">
                <label for="name">Payment Method<span class="u-text-important">*</span></label>
                <div>
                                        <label>
                        <input type="radio" class="c-form__radio" name="payment_id" value="3" data-payment_type="credit_card" >
                        <span class="c-form__radio-name">Credit Card</span>
                    </label>

                                        <div id="stripe-card-element" class="mt-3"></div>
                    <p class="validation u-ml__0 u-mt__0 v-card-error" style="display: none;"></p>
                    
                                                        </div>
                            </section>
            <hr>
                        <section class="l-gundam__form-login">
                <div>
                    <p><strong>If you are already registered with OWNDAYS Online Store, please <a class="link-color-blue u-dib" href="/sg/en/login">Log In</a> to your account.</strong><br>Your details and shipping information will be filled in automatically.</p>
                    <p class="u-text-comment">>* Unregistered items are not displayed in the member information</p>
                </div>
                <div class="c-btn c-btn--secondary">
                    <a href="" data-toggle="modal" data-target="#login">Log In</a>
                </div>
            </section>
                        <section class="l-gundam__form-address">
                <h2>Shipping information</h2>
                                                <div class="l-gundam__form-block">
                    <label for="name">Name<span class="u-text-important">*</span></label>
                    <div class="l-gundam__form-input">
                        <input type="text" placeholder="First Name" id="first_name" name="first_name" value="">
                        <input type="text" placeholder="Last Name" id="last_name" name="last_name" value="">
                    </div>
                                    </div>
                                                <div class="l-gundam__form-block line-top">
                    <label for="name">Address<span class="u-text-important">*</span></label>
                    <div>
                        <div class="l-gundam__form-input l-gundam__form-input--other">
                            <input type="text" placeholder=Postal Code name="zip_code" id="zip_code" value="">
                                                    </div>
                        <div class="sp-form">
                            <input type="text" placeholder=Street Address name="street" id="street" value="">
                                                                                    <input type="text" placeholder=City name="state" id="state" value="">
                                                    </div>
                                            </div>
                </div>
                <div class="l-gundam__form-block">
                    <label for="name">Phone Number<span class="u-text-important">*</span></label>
                                                            <input type="tel" placeholder=Telephone number id="phone-number" name="phone" maxlength="8" value="">
                                                        </div>
            </section>

                        <section class="l-gundam__form-registration">
                <h2>OWNDAYS <br class="u-sp-only">Online Store Registration</h2>
                <div class="l-gundam__form-block line-top">
                    <label for="name">Email Address<span class="u-text-important">*</span></label>
                    <div>
                        <input type="email" placeholder="owndays@example.com" id="email" name="email" class="u-mb__10" value="">
                        <input type="email" placeholder="owndays@example.com" id="email_confirmation" name="email_confirmation" value="">
                        <p class="u-text-comment">※* Please re-enter to confirm. </p>
                    </div>
                                    </div>
                <div class="l-gundam__form-block line-top">
                    <label for="name">Password<span class="u-text-important">*</span></label>
                    <div>
                        <input type="password" id="password" name="password">
                        <p class="u-text-comment">* Must be between 8 to 32 characters and contain at least 1 number and 1 uppercase letter</p>
                        <input type="password" id="password_confirmation" name="password_confirmation">
                        <p class="u-text-comment">* Please re-enter to confirm. </p>
                    </div>
                                    </div>
                <div class="l-gundam__form-agree">
                    <label for="agreement">
                        <input type="checkbox" class="c-form__checkbox" id="agreement" name="agreement" value="1" >
                        <span class="c-form__checkbox-name">By signing up, you agree to our <a href="" data-toggle="modal" data-target="#rules">Terms and Conditions</a></span>
                    </label>
                                    </div>
            </section>
                        <hr>
                                    <ul class="l-gundam__form-notes">
                <li>- Payment must be settled in full prior to delivery </li>
                <li>- Due to limited production quantity, there will be no cancellation, change of order details, refund or exchange once an order is placed</li>
                <li>- Resale or transfer is strictly prohibited </li>
                <li>- Delivery within Singapore only</li>
                <li>- Delivery will be within 2 weeks from the date of purchase</li>
            </ul>
                        <div class="c-btn c-btn--secondary c-btn--secondary-center l-gundam__form-btn">
                <a href="javascript:void(0);" class="btn-submit">Confirm Details</a>
            </div>
        </section>

    </form>

</section>
<!-- Modal / rules -->
<modal id="login">
    <section class="l-login">
        <section class="l-login__content">
            <div>
                <h2>Log in with an OWNDAYS account</h2>
                
                <form class="l-login__list c-form" method="POST" action="/sg/en/login?_target=%2Fsg%2Fen%2Fcollabo%2Fgundam%2Fpj-head%2Freservation">
                    <input type="hidden" name="_token" value="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">                                        <input type="email" name="email" placeholder="Email Address" value="">
                                        <input type="password" name="password" placeholder="Password " class="u-mt__20">
                                        <div class="l-login__forgot u-text-link-blue">
                        <a href="/sg/en/password/reset">Forgot password?</a>
                    </div>
                    <button class="c-btn c-btn--type c-btn--fullwidth c-btn--tertiary u-mb__20" type="submit">Log In</button>
                </form>
            </div>
            <div>
                <h2>Non-registered customer</h2>
                                                
                <a href="/sg/en/login/facebook?_target=%2Fsg%2Fen%2Fcollabo%2Fgundam%2Fpj-head%2Freservation" class="c-btn c-btn--type c-btn--fullwidth l-login__facebook u-mb__20"><i class="fab fa-facebook-square"></i>Log in with Facebook</a>
                                                <a href="/sg/en/login/google?_target=%2Fsg%2Fen%2Fcollabo%2Fgundam%2Fpj-head%2Freservation" class="c-btn c-btn--type c-btn--fullwidth l-login__google"><img src="https://www.owndays.com/images/svg/google-logo.svg" alt="">Log in with Google</a>
                            </div>
        </section>
    </section>
</modal>
<!-- Modal / rules -->
<modal id="rules">
            <div class="l-login__rules">
            <h1>TERMS & CONDITIONS</h1>
            <div class="l-login__rules-inner">
                <div class="text">
                    <p>Please read these Terms & Conditions carefully before ordering. You should understand that by ordering any of our products, you agree to be bound by these Terms & Conditions.</p>
                    <p>Do check the details of your purchase before checking out and ensure prescription has been issued by a registered optometrist within the last 1 year and inform the optometrist about your intention to buy spectacles from an online source and the details of such source.</p>
                    <p>The prescription for purchase of spectacles online shall contain the following information in order to be considered a valid prescription:</p>
                    <ol class="l-child-ol">
                        <li>Details of consumer including full name, date-of-birth and unique identification number (e.g. NRIC number);</li>
                        <li>Date of eye examination done;</li>
                        <li>Issue date and expiration date of the prescription;</li>
                        <li>Name, work and/or contact address and contact number of the prescriber;</li>
                        <li>Refractive error of the consumer (indicate spherical and cylindrical readings);</li>
                        <li>Inter-pupillary distance reading;</li>
                        <li>Back Vertex Distance (if applicable);</li>
                        <li>Written assessment that the consumer does not have any existing organic eye disease or systemic medical condition that will affect vision; and</li>
                        <li>Statement of advice acknowledgement by the consumer to undergo eye checks if the spectacle causes discomfort.</li>
                    </ol>
                </div>
                <ul class="l-parent-ul">
                    <li class="l-paragraph">
                        <h3>Error in prescription & address</h3>
                        <p>For errors in prescription after making payment, kindly send your invoice details along with the corrected prescription to: ecsg@owndays.com</p>
                        <p>OWNDAYS will return a call to confirm the prescription.</p>
                        <p>Entering an incomplete or incorrect address may result in complication such as lost or delayed shipping.</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>Prices</h3>
                        <p>All prices are inclusive of GST.</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>Vouchers</h3>
                        <p>Use of voucher(s) for online purchase will not be applicable.</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>Product quality</h3>
                        <p>Before shipping is processed, our team will ensure quality is in top condition. If you receive any damaged goods received, kindly send an email to ecsg@owndays.com for further investigation.</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>Warranty</h3>
                        <p>‘Expiration date’ refers to the date beyond which the prescription is no longer valid, and it shall be a period not longer than 2 years. The prescribing optometrist shall inform the consumer that the prescription does not give any form of assurance with regard to the ocular status of the eye at any point of time.</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>Shipping policy</h3>
                        <p>Delivery will take about 5 - 10 working days upon successful payment.</p>
                        <p>Should there be any delays, OWNDAYS will inform the affected customers via email/call. </p>
                        <p>International Customs and Duties: Many countries impose customs, duties, tariffs or taxes on items being imported from overseas.</p>
                        <p>We are NOT responsible for any of these costs if they are assessed by your local authorities. Customers are required to bear any additional charges.</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>After sales services</h3>
                        <p>Should there be any fitting/adjustment needed, customers may visit any OWNDAYS to do so. Adjustments includes:</p>
                        <ul>
                            <li>Changing of nose-pads</li>
                            <li>Adjustments to the screws</li>
                            <li>Adjustment to the fitting of spectacles/sunglasses and</li>
                            <li>Complimentary re-checking of your vision</li>
                        </ul>
                        <p>* Change of nose-pads is limited to one time only (warranty card required).</p>
                    </li>
                    <li class="l-paragraph">
                        <h3>Amendment of Terms & Conditions</h3>
                        <p>OWNDAYS reserve the right to amend these Terms & Conditions at any time without prior notice. All amendments to these Terms & Conditions will be posted online. However, continuous usage of the Site will be deemed to constitute acceptance of the new Terms & Conditions.</p>
                    </li>
                </ul>
                <div class="rag_f">
                    <p>Version: April 2018</p>
                </div>
            </div>
        </div>
        </modal>
        </main>
            </div>

       
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
    <script src="https://code.jquery.com/jquery-migrate-3.0.1.js"></script>
<script src="https://ajaxzip3.github.io/ajaxzip3.js" charset="UTF-8"></script>
<script async type='text/javascript' src='https://static-fe.payments-amazon.com/OffAmazonPayments/jp/lpa/js/Widgets.js'></script>
<script src="https://cdn.jsdelivr.net/npm/lodash@4.17.20/lodash.min.js" charset="UTF-8"></script>
<script async type='text/javascript' src='https://www.owndays.com/web/js/jquery-mask.min.js'></script>
<script src="https://js.stripe.com/v3/"></script>

<script>
    var sellerId = 'A2D524NYY5UBZ4';
    var clientId = 'amzn1.application-oa2-client.d9a54c76ade24aa7bae9e38f88f04c25';
    var sandboxMode = false;
    var price = '580';
    var tax = '0';
    var stripe = Stripe('pk_live_SnOEzMQJ3rXC2P7KWyxDXmeu00Wgb13KRV', {
            locale: 'sg'
        });

    window.onAmazonLoginReady = function() {
        amazon.Login.setClientId(clientId)
        amazon.Login.setUseCookie(true)
        amazon.Login.setSandboxMode(sandboxMode)
    }

    $(document).ready(function($) {
        //mask
        $('.cart_number').mask('####-####-####-####')
        $('.exp').mask('##/##')

        var frm = $('.frm-input');
        var storagePayment = sessionStorage.getItem('payment');
        var oldPayment = parseInt('');

        $('select').on('change', function() {
            displayPrice(price);
        });

        $('#zip_code').keyup(function() {
            AjaxZip3.zip2addr('zip_code', '', 'state', 'street', 'building');
        });

        $('.btn-submit').click(function(e) {
                        e.preventDefault();

            var payment_type = $("input[name=payment_id]:checked").attr("data-payment_type")
            var payment_token = $("#payment_token").val();
            if (payment_type === 'credit_card' && !payment_token) {
                var cardErrorElement = $('.v-card-error');
                cardErrorElement.html('Please enter your card.').show();
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(".l-gundam__form-payment").offset().top
                }, 400);
                return false
            }

            var frm = $('.frm-input');
            frm.submit();
        });

        $('.amazon-login-payment').click(function() {
            var options = {
                scope: 'profile postal_code payments:widget payments:shipping_address',
                popup: true
            }
            amazon.Login.authorize(options, 'https://www.owndays.com/jp/amazon-callback?%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20_target%20=%2Fsg%2Fen%2Fcollabo%2Fgundam%2Fpj-head%2Freservation?is_payment=1')
        });

        $('input[name="payment_id"]').click(function() {
            if (parseInt($(this).val()) === 7) {
                $('.amazon-widget').show();
                $('.amazon-login-payment').show();
                $('#credit-card').hide();
                loadAmazonPayment();
                clearCreditCard();
            } else {
                $('.amazon-widget').hide();
                $('.amazon-login-payment').hide();
                $('#credit-card').show();
                $('.btn-submit').prop("disabled", true)
            }

            sessionStorage.setItem('payment', parseInt($(this).val()));
        });

        if (storagePayment) {
            $('input[name="payment_id"][value=' + storagePayment + ']').prop('checked', true);
            if (isPaymentWithAmazon(storagePayment)) {
                $('.amazon-widget').show();
                $('.amazon-login-payment').show();
                $('#credit-card').hide();
                loadAmazonPayment();
            }
        }

        $('.reload').on('click', function() {
            $('#quantity').prop('selectedIndex', 0);
            displayPrice(price);
            return false;
        });
        displayPrice(price);

        $('.input-credit-card').on('keyup keypress blur change', _.debounce(function() {
            confirmCreditCard();
        }, 1000));

                initializeStripeElement();
            });


    
    function clearAmazonLogin() {
        if (!isPaymentWithAmazon(storagePayment)) {
            amazon.Login.logout()
        }
    }

    function loadAmazonPayment() {
        clearAmazonLogin();
        setTimeout(function() {
            new OffAmazonPayments.Widgets.Wallet({
                sellerId: sellerId,
                onOrderReferenceCreate: function(orderReference) {
                    var frm = $('.frm-input');
                    $('.amazon-login-payment').hide();
                    frm.append('<input type="hidden" name="billingAgreementId" value="' + orderReference.getAmazonOrderReferenceId() + '">');
                },
                design: {
                    designMode: 'responsive'
                },
                onError: function(error) {
                    $('.amazon-login-payment').show();
                }
            }).bind('walletWidgetDiv')
        }, 500)
    }

    function clearCreditCard() {
        
        $('#payment_token').val('');
    }

    function reportError(error) {
        console.log(error.getErrorMessage())
    }

    function isPaymentWithAmazon(paymentId) {
        if (parseInt(paymentId) === 7) {
            return true;
        } else {
            return false;
        }
    }

    function displayPrice(price) {
        var quantity = $('#quantity').val();
        var totalPriceWithTax = (price * quantity) + (((price * quantity) * tax) / 100);

        $('#total-price-with-tax').html(formatNumber(totalPriceWithTax));
    }

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    function confirmCreditCard() {
        var self = this;

        var inputCardNumber = $('#cardNumber');
        var inputExp = $('#exp');
        var inputCvc = $('#cvc');
        var inputPaymentToken = $('#payment_token');
        var btnConfirmElement = $('.btn-submit');
        var cardErrorElement = $('.v-card-error');
        var successIcon = $('.success-icon');

        cardErrorElement.html('').hide();
        inputPaymentToken.remove();

        if (!inputCardNumber.val() || !inputExp.val() || !inputCvc.val()) {
            if (inputCardNumber.val().length != 19 && inputExp.val().length != 5 && inputCvc.val().length < 3) {
                return false;
            }
            return false;
        }

        let param = {
            card_number: inputCardNumber.val().replace(/-/g, ''),
            card_expire: inputExp.val(),
            security_code: inputCvc.val(),
            token_api_key: '4f3713e4-4d1a-4ab2-8207-ec0770c8d0d4',
            lang: 'ja'
        }

        axios.post('https://api.veritrans.co.jp/4gtoken', param)
            .then(res => {
                appendInputVeritransTokenToForm(_.get(res, 'data.token'));
                cardErrorElement.html('').hide();
                successIcon.show();
            })
            .catch(err => {
                cardErrorElement.html(_.get(err, 'response.data.message')).show();
                successIcon.hide();
            });
    }

    function appendInputVeritransTokenToForm(vertransPaymentToken) {
        var form = $('.frm-input');
        var inputVaritransToken = $('#payment_token');

        if (inputVaritransToken.length) {
            inputVaritransToken.remove();
        }

        form.append('<input type="hidden" name="payment_token" id="payment_token" value="' + vertransPaymentToken + '">');
    }

    function initializeStripeElement() {
        var stripeDiv = document.getElementById("#stripe-card-element");
        var stripeCardErrorElement = $('.v-card-error');

        // if (typeof element != "undefined" && element != null) {
        //     return false;
        // }

        var vm = this;
        var elements = stripe.elements();
        var style = {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        };
        var stripeCard = elements.create("card", {
            hidePostalCode: true,
            style: style,
        });

        clearCreditCard();
        stripeCard.mount("#stripe-card-element");
        stripeCard.on("change", function(event) {

            if (event.error) {
                stripeCardErrorElement.text(event.error.message);
            } else {
                stripeCardErrorElement.text('').hide();

                if (event.complete) {
                    stripeCreatePaymentMethod(stripe, stripeCard);
                }
            }
        });
    }

    function stripeCreatePaymentMethod(stripeElement, stripeCard) {
        var fullName = $('#first_name').val() + ' ' + $('#last_name').val();
        var phone = $('#phone-number').val();
        var email = $('#email').val();
        var params = {
            type: "card",
            card: stripeCard,
            billing_details: {
                name: fullName,
                phone: phone,
                email: email,
            },
        };
        var stripeCardErrorElement = $('.v-card-error');
        var inputPaymentToken = $('#payment_token');

        inputPaymentToken.remove();
        stripeElement.createPaymentMethod(params).then((result) => {
            if (result.error) {
                stripeCardErrorElement.text(result.error.message).show();
            } else {
                appendInputVeritransTokenToForm(_.get(result, 'paymentMethod.id'));
            }
        });
    }
</script>

</body>

</html>`;

const GundamOnlineOrderHTML = () => {
  return (
    <>
      <MetaTagsManager
        canonical="/collabo/gundam/pj-head/reservation"
        description="Pre-order the exclusive OWNDAYS x Mobile Suit Gundam Head Case. A fusion of iconic anime artistry with premium eyewear storage. Secure yours now!"
        title="Mobile Suit Gundam Head Case Online Order Form  | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default GundamOnlineOrderHTML;
