import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { langCodeAllowed } from '../../locales';
class AppHelmet extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { pathname, yotpoSiteRating, yotpoSiteReviewCount, section, location, localeInfo } =
      this.props;
    const { domain } = localeInfo;
    const keysArray = Object.keys(langCodeAllowed);
    const LinkArray =
      section !== 'cat' && section !== 'prod'
        ? keysArray
            .map(keys => {
              if (keys === 'sg') {
                return {
                  rel: 'alternate',
                  hrefLang: `${langCodeAllowed[keys]?.hrefLang}`,
                  href: location.search
                    ? `${langCodeAllowed[keys].domain}${pathname}${location.search}`
                    : `${langCodeAllowed[keys].domain}${pathname}`,
                };
              }
              return null;
            })
            .filter(link => link !== null)
        : [];
    return (
      <Helmet
        link={LinkArray}
        script={[
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              ...{
                '@context': 'https://schema.org',
                '@type': 'Organization',
                url: `${domain}`,
                name: 'OWNDAYS',
                contactPoint: {
                  '@type': 'ContactPoint',
                  telephone: '6222-0588',
                  contactType: 'Customer Service',
                },
                sameAs: [
                  'https://www.facebook.com/owndays.sg',
                  'https://www.instagram.com/owndays_sg/',
                  'https://www.tiktok.com/@owndays_sg',
                  'https://www.youtube.com/channel/UCSu2yqzCpnTtGXi05WtsiZg',
                ],
                logo: 'https://static1.lenskart.com/media/owndays/desktop/img/od-logos-new/logo-type-black.png',
              },
              ...(['/', '/reviews'].includes(pathname) && yotpoSiteRating
                ? {
                    aggregateRating: {
                      '@type': 'AggregateRating',
                      ratingValue: yotpoSiteRating,
                      bestRating: 5,
                      reviewCount: yotpoSiteReviewCount,
                    },
                  }
                : {}),
            }),
          },
          {
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'WebSite',
              url: `${domain}`,
              potentialAction: {
                '@type': 'SearchAction',
                target: `${domain}/search?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
              },
            }),
          },
        ]}
      ></Helmet>
    );
  }
}

const mapStateToProps = state => ({
  yotpoSiteRating: state.yotpo.yotpoSiteRating,
  yotpoSiteReviewCount: state.yotpo.yotpoSiteReviewCount,
});

export default connect(mapStateToProps)(AppHelmet);
