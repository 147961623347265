import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        
<head prefix="og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# website: http://ogp.me/ns/websaite#">
    <meta property="og:url" content="https://www.owndays.com/sg/en/information/244">
    <meta property="og:type" content="article">
    <meta property="og:title" content="2022 Lunar New Year Operating Hours">

    <meta property="og:description" content="OWNDAYS Online Store

Orders received from 6pm on 31 January to 02 February will be processed on 03 February.

Customer Service Hotline

Customer Service Hotline will not be available from 6pm on 31 January to 02 February. Normal operating hours will resume on 03 February.

OWNDAYS Retail Stores

Selected OWNDAYS stores will open throughout the Lunar New Year to serve your eyewear needs. Please refer to the list below on each store&amp;#39;s opening hours.



	
		
			Store
			31 January
			Eve of Lunar New Year
			01 February
			Lunar New Year Day 1
			02 February
			Lunar New Year Day 2
			03 February
			Lunar New Year Day 3
		
	
	
		
			OWNDAYS ION Orchard
			10:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS 313@somerset
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Suntec City
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Plaza Singapura
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Bedok Mall
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS nex
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Jurong Point
			11:00 - 18:00
			12:00 - 21:00
			12:00 - 22:00
			Business as usual
		
		
			OWNDAYS Bugis+
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS White Sands
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS JCube
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS IMM Outlet
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Vivo City
			11:00 - 18:00
			12:00 - 21:00
			12:00 - 22:00
			Business as usual
		
		
			OWNDAYS Waterway Point
			11:00 - 18:00
			12:00 - 21:00
			12:00 - 22:00
			Business as usual
		
		
			OWNDAYS Tiong Bahru Plaza
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Compass One
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS City Square Mall
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Westgate
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS 100AM
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Clementi Mall
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS SingPost Centre
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Seletar Mall
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Northpoint City
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS AMK Hub
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Bukit Panjang Plaza
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Century Square
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Jewel Changi
			10:00 - 18:00
			12:00 - 21:00
			12:00 - 21:00
			Business as usual
		
		
			OWNDAYS PLQ Mall
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Raffles City
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Causeway Point
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS Parkway Parade
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
		
			OWNDAYS MBS Premium Concept Store
			11:00 - 22:00
			11:00 - 22:00
			11:00 - 22:00
			Business as usual
		
		
			OWNDAYS BUGIS JUNCTION
			11:00 - 18:00
			Closed
			Closed
			Business as usual
		
	

">

    <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
        <meta property="og:image" content="https://www.owndays.com/images/information/no-image.jpg">
    </head>
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/information/244">
                    <meta property="og:image" content="https://www.owndays.com/images/information/no-image.jpg">
                    <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link href="https://www.owndays.com/web/css/faq.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/products-detail.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/information.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">    2022 Lunar New Year Operating Hours
</title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
<div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/information">See all the Information</a>
                                                                                </li>
                <li>
                            2022 Lunar New Year Operating Hours                    </li>
        
            </ul>
</div>


<section class="p-information p-information-details l-container">
    <div class="p-information-details__content">
        <p class="days">
                    </p>
        <h1>2022 Lunar New Year Operating Hours</h1>
        <div class="u-mb__30">
            <img src="https://www.owndays.com/storage" onerror="this.remove()">
        </div>
        <div class="p-information-details__inner">
            <h3>OWNDAYS Online Store</h3>

<p>Orders received from 6pm on 31 January to 02 February will be processed on 03 February.</p>

<h3>Customer Service Hotline</h3>

<p>Customer Service Hotline will not be available from 6pm on 31 January to 02 February. Normal operating hours will resume on 03 February.</p>

<h3>OWNDAYS Retail Stores</h3>

<p class="u-mb__20">Selected OWNDAYS stores will open throughout the Lunar New Year to serve your eyewear needs. Please refer to the list below on each store&#39;s opening hours.</p>

<div class="scroll">
<table class="sheet">
	<thead>
		<tr>
			<th>Store</th>
			<th>31 January<br />
			Eve of Lunar New Year</th>
			<th>01 February<br />
			Lunar New Year Day 1</th>
			<th>02 February<br />
			Lunar New Year Day 2</th>
			<th>03 February<br />
			Lunar New Year Day 3</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/920">OWNDAYS ION Orchard</a></td>
			<td>10:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/907">OWNDAYS 313@somerset</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/924">OWNDAYS Suntec City</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/905/">OWNDAYS Plaza Singapura</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/908">OWNDAYS Bedok Mall</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/909">OWNDAYS nex</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/928">OWNDAYS Jurong Point</a></td>
			<td>11:00 - 18:00</td>
			<td>12:00 - 21:00</td>
			<td>12:00 - 22:00</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/915">OWNDAYS Bugis+</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/927">OWNDAYS White Sands</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/926">OWNDAYS JCube</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/922">OWNDAYS IMM Outlet</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/931">OWNDAYS Vivo City</a></td>
			<td>11:00 - 18:00</td>
			<td>12:00 - 21:00</td>
			<td>12:00 - 22:00</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/933">OWNDAYS Waterway Point</a></td>
			<td>11:00 - 18:00</td>
			<td>12:00 - 21:00</td>
			<td>12:00 - 22:00</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/937">OWNDAYS Tiong Bahru Plaza</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/948">OWNDAYS Compass One</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/961">OWNDAYS City Square Mall</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/962">OWNDAYS Westgate</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/963">OWNDAYS 100AM</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/965">OWNDAYS Clementi Mall</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/972">OWNDAYS SingPost Centre</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/974">OWNDAYS Seletar Mall</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/968">OWNDAYS Northpoint City</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/976">OWNDAYS AMK Hub</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/977">OWNDAYS Bukit Panjang Plaza</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/983">OWNDAYS Century Square</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/989">OWNDAYS Jewel Changi</a></td>
			<td>10:00 - 18:00</td>
			<td>12:00 - 21:00</td>
			<td>12:00 - 21:00</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/994">OWNDAYS PLQ Mall</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/1004">OWNDAYS Raffles City</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/1005">OWNDAYS Causeway Point</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/1006">OWNDAYS Parkway Parade</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a></td>
			<td>11:00 - 22:00</td>
			<td>11:00 - 22:00</td>
			<td>11:00 - 22:00</td>
			<td>Business as usual</td>
		</tr>
		<tr>
			<td><a href="https://www.owndays.com/sg/en/shops/1011">OWNDAYS BUGIS JUNCTION</a></td>
			<td>11:00 - 18:00</td>
			<td class="h_bk">Closed</td>
			<td class="h_bk">Closed</td>
			<td>Business as usual</td>
		</tr>
	</tbody>
</table>
</div>
        </div>
        
    <div class="l-product-details__sns-share">
        <ul class="sns-list">
            <li class="sns-item">
                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/information/244" target="_blank">
                    <i class="fab fa-facebook-square"></i>
                </a>
            </li>
            <li class="sns-item">
                <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/information/244" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa-twitter"></i>
                </a>
            </li>
            <li class="sns-item">
                <a href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/information/244" target="_blank" rel="noopener noreferrer">
                    <i class="fab fa-line"></i>
                </a>
            </li>
        </ul>
    </div>
    <a href="/sg/en/information" class="u-mt__20"><i class="fas fa-chevron-left"></i>Back</a>
    </div>
</section>
        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>


<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://www.owndays.com/web/js/information.js" defer></script>
<script src="https://www.owndays.com/web/js/faq.js" defer></script>
<script src="https://www.owndays.com/web/js/scroll_internal-link.js" defer></script>

<script>
    $(function() {
        console.log("test");
    });
</script>
 
</body>

</html>`;

const Lunar2022NewYearOperatingHoursHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Check out the 2022 Lunar New Year operating hours for OWNDAYS Singapore. Plan your visit accordingly during the festive season."
        title="2022 Lunar New Year Operating Hours | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default Lunar2022NewYearOperatingHoursHTML;
