import React from 'react';

const ModalTitle = props => {
  const { className, children, ...rest } = props;
  return (
    <h2 className={className} {...rest}>
      {children}
    </h2>
  );
};

export default ModalTitle;
