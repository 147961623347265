import React, { useState } from 'react';

const Lineup = ({ baseImgUrl, isSP }) => {
  const defaultLineupTabs = {
    'prod-1': 0,
    'prod-2': 1,
    'prod-3': 0,
    'prod-4': 0,
    'prod-5': 0,
    'prod-6': 1,
  };
  const [activeTab, setActiveTab] = useState(defaultLineupTabs);
  const handleTabClick = (productId, tabIndex) => {
    setActiveTab(prevActiveTab => ({
      ...prevActiveTab,
      [productId]: tabIndex,
    }));
  };
  const products = [
    {
      id: 'prod-1',
      prodCode: 'HP1001B-4S',
      number: '01',
      title: 'Quidditch',
      desc: 'Inspired by Quidditch, the most well-known sport in the wizarding world, this lightweight, round metal frame showcases temples reminiscent of Harry Potter’s iconic broomstick with “Nimbus 2000” engraved on its temple tip. The nose pads, resembling the wings of the Golden Snitch, add a whimsical touch to the design. Silicone can be added to enhance comfort and fit. The frame is available in gold and brown options.',
      middleImages: [
        { desc: 'Round frame inspired <br/>by Quidditch' },
        { desc: 'Wings of the Golden Snitch featured <br/>on the rim and nose pads' },
        { desc: 'Temples reminiscent <br/>of the Nimbus 2000 flying broomstick' },
      ],
      tabs: [
        {
          sku: 'C1',
          colorClass: 'cl-gold-2',
          colorHex: '',
          images: [
            { src: `${baseImgUrl}HP1001B-3A/C1/HP1001B-3A_C1_01.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C1/HP1001B-3A_C1_02.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C1/HP1001B-3A_C1_03.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C1/HP1001B-3A_C1_04.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C1/HP1001B-3A_C1_05.webp` },
          ],
        },
        {
          sku: 'C2',
          colorClass: '',
          colorHex: '#826f66',
          images: [
            { src: `${baseImgUrl}HP1001B-3A/C2/HP1001B-3A_C2_01.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C2/HP1001B-3A_C2_02.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C2/HP1001B-3A_C2_03.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C2/HP1001B-3A_C2_04.webp` },
            { src: `${baseImgUrl}HP1001B-3A/C2/HP1001B-3A_C2_05.webp` },
          ],
        },
      ],
    },
    {
      id: 'prod-2',
      prodCode: 'HP1002B-4S',
      title: 'Hermione',
      number: '02',
      desc: 'Made of lightweight stainless steel, this Boston-style metal frame is a homage to Hermione Granger. The rim features a pattern inspired by her Time-Turner necklace – a magical device that takes the wearer back in time, while the temples are designed to resemble Hermione’s wand. This beautifully crafted frame is both enchanting and elegant.',
      middleImages: [
        { desc: 'Pattern inspired <br/>by Hermione’s Time-Turner' },
        { desc: 'Temple tips feature motif <br/>from Hermione’s Time-Turner' },
        { desc: 'Temples reminiscent <br/>of Hermione’s wand' },
      ],
      tabs: [
        {
          sku: 'C1',
          colorClass: 'cl-gold-1',
          colorHex: '',
          images: [
            { src: `${baseImgUrl}HP1002B-3A/C1/HP1002B-3A_C1_01.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C1/HP1002B-3A_C1_02.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C1/HP1002B-3A_C1_03.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C1/HP1002B-3A_C1_04.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C1/HP1002B-3A_C1_05.webp` },
          ],
        },
        {
          sku: 'C2',
          colorClass: 'cl-gold-2',
          colorHex: '',
          images: [
            { src: `${baseImgUrl}HP1002B-3A/C2/HP1002B-3A_C2_01.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C2/HP1002B-3A_C2_02.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C2/HP1002B-3A_C2_03.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C2/HP1002B-3A_C2_04.webp` },
            { src: `${baseImgUrl}HP1002B-3A/C2/HP1002B-3A_C2_05.webp` },
          ],
        },
      ],
    },
    {
      id: 'prod-3',
      prodCode: 'HP1003B-4S',
      title: 'Acceptance Letter',
      number: '03',
      desc: 'This Boston-style frame is lightweight and a great addition to jazz up any outfit. The metal wire core serves as a canvas adorned with iconic artifacts associated with Harry Potter, namely his signature spectacles, the lightning bolt scar on his forehead and his Hogwarts acceptance letter. The temple tips are given metallic accents as a final touch of luxury.',
      middleImages: [
        { desc: 'Engravings featuring artifacts associated <br/>with Harry Potter' },
        { desc: 'Lightweight, <br/>Boston-style frame' },
        { desc: 'Metallic accents <br/>on the temple tips' },
      ],
      tabs: [
        {
          sku: 'C1',
          colorClass: '',
          colorHex: '#000',
          images: [
            { src: `${baseImgUrl}HP1003B-3A/C1/HP1003B-3A_C1_01.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C1/HP1003B-3A_C1_02.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C1/HP1003B-3A_C1_03.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C1/HP1003B-3A_C1_04.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C1/HP1003B-3A_C1_05.webp` },
          ],
        },
        {
          sku: 'C2',
          colorClass: '',
          colorHex: '#f9c967',
          images: [
            { src: `${baseImgUrl}HP1003B-3A/C2/HP1003B-3A_C2_01.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C2/HP1003B-3A_C2_02.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C2/HP1003B-3A_C2_03.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C2/HP1003B-3A_C2_04.webp` },
            { src: `${baseImgUrl}HP1003B-3A/C2/HP1003B-3A_C2_05.webp` },
          ],
        },
      ],
    },
    {
      id: 'prod-4',
      prodCode: 'HP2001B-4S',
      title: 'Hedwig',
      number: '04',
      desc: 'A must-have for Harry Potter fans, this acetate frame in the classic Wellington style offers unmatched versatility. The frame front features two star-shaped studs that lend a glimmer of luxury to the frame design. To take things up a notch, the temple wire cores are decorated with intricate engravings on both the outer and inner surfaces. Harry Potter’s owl, Hedwig, and the Hogwarts acceptance letter are made into emblems embedded on the temple tips.',
      middleImages: [
        { desc: 'Frame front features a metal wire core <br/>and star-shaped stubs' },
        { desc: 'Engravings <br/>on the temples exude old-school appeal' },
        {
          desc: 'Emblems of Hedwig and the Hogwarts acceptance letter <br/>embedded on the temple tips',
        },
      ],
      tabs: [
        {
          sku: 'C1',
          colorClass: '',
          colorHex: '#000',
          images: [
            { src: `${baseImgUrl}HP2001B-3A/C1/HP2001B-3A_C1_01.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C1/HP2001B-3A_C1_02.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C1/HP2001B-3A_C1_03.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C1/HP2001B-3A_C1_04.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C1/HP2001B-3A_C1_05.webp` },
          ],
        },
        {
          sku: 'C2',
          colorClass: '',
          colorHex: '#4c4e64',
          images: [
            { src: `${baseImgUrl}HP2001B-3A/C2/HP2001B-3A_C2_01.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C2/HP2001B-3A_C2_02.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C2/HP2001B-3A_C2_03.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C2/HP2001B-3A_C2_04.webp` },
            { src: `${baseImgUrl}HP2001B-3A/C2/HP2001B-3A_C2_05.webp` },
          ],
        },
      ],
    },
    {
      id: 'prod-5',
      prodCode: 'HP2002B-4S',
      title: 'Platform 9¾',
      number: '05',
      desc: 'Drawing inspiration from Hogwarts Express, this 2-tone acetate frame in rectangle shape comes in two colour options – the red option echoes the iconic colour of the steam locomotive while the grey option denotes the steam emitted from its engine. To add a touch of magic to every glance, the wheels of the Hogwarts Express are transformed into motifs adorning the sides of the frame front. Emblems in the symbol of Platform 9¾ and the Hogwarts School of Witchcraft and Wizardry crest are embedded on the temple tips, serving as a reminder of the journey to Hogwarts and the adventures that await Harry Potter and his friends.',
      middleImages: [
        { desc: 'Frame design inspired by the Hogwarts Express' },
        {
          desc: 'Wheels of the Hogwarts Express transformed into design<br/> accents on the frame front',
        },
        {
          desc: 'Emblems of the Platform 9¾ symbol and the Hogwarts School <br/>of Witchcraft and Wizardry crest embedded on the temple tips',
        },
      ],
      tabs: [
        {
          sku: 'C1',
          colorClass: '',
          colorHex: '#870114',
          images: [
            { src: `${baseImgUrl}HP2002B-3A/C1/HP2002B-3A_C1_01.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C1/HP2002B-3A_C1_02.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C1/HP2002B-3A_C1_03.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C1/HP2002B-3A_C1_04.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C1/HP2002B-3A_C1_05.webp` },
          ],
        },
        {
          sku: 'C2',
          colorClass: '',
          colorHex: '#4C4E64',
          images: [
            { src: `${baseImgUrl}HP2002B-3A/C2/HP2002B-3A_C2_01.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C2/HP2002B-3A_C2_02.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C2/HP2002B-3A_C2_03.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C2/HP2002B-3A_C2_04.webp` },
            { src: `${baseImgUrl}HP2002B-3A/C2/HP2002B-3A_C2_05.webp` },
          ],
        },
      ],
    },
    {
      id: 'prod-6',
      prodCode: 'HP2003B-4S',
      title: 'Deathly Hallows ',
      number: '06',
      desc: 'This Boston-style frame is arguably the most mysterious of the lot. Its acetate frame front stands out with a deliberate pattern that accentuates its mystique. An ominous skull motif is added to each side on the frame front and the temples are designed to resemble the Elder Wand. Its temple tips feature the Deathly Hallows symbol.',
      middleImages: [
        { desc: 'Decorative accents denoting <br/>the Dark Mark' },
        { desc: 'Temples inspired <br/>by the Elder Wand' },
        { desc: 'Temple tips featuring <br/>the Deathly Hallows symbol' },
      ],
      tabs: [
        {
          sku: 'C1',
          colorClass: '',
          colorHex: '#8c7e80',
          images: [
            { src: `${baseImgUrl}HP2003B-3A/C1/HP2003B-3A_C1_01.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C1/HP2003B-3A_C1_02.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C1/HP2003B-3A_C1_03.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C1/HP2003B-3A_C1_04.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C1/HP2003B-3A_C1_05.webp` },
          ],
        },
        {
          sku: 'C2',
          colorClass: '',
          colorHex: '#51633b',
          images: [
            { src: `${baseImgUrl}HP2003B-3A/C2/HP2003B-3A_C2_01.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C2/HP2003B-3A_C2_02.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C2/HP2003B-3A_C2_03.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C2/HP2003B-3A_C2_04.webp` },
            { src: `${baseImgUrl}HP2003B-3A/C2/HP2003B-3A_C2_05.webp` },
          ],
        },
      ],
    },
  ];
  const getProductImageSrc = (prodCode, baseImgUrl, index) => {
    switch (prodCode) {
      case 'HP1001B-4S':
      case 'HP1002B-4S':
      case 'HP1003B-4S':
      case 'HP2001B-4S':
      case 'HP2002B-4S':
      case 'HP2003B-4S':
        return `${baseImgUrl}${prodCode.replace('4S', '3A')}/slide-${index + 1}.webp`;
      default:
        return `${baseImgUrl}${prodCode}/slide-${index + 1}.webp`;
    }
  };
  const createMarkup = htmlString => ({ __html: htmlString });
  const stripHtml = html => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  };
  return (
    <section className="lineup" data-aos="show-letter" id="lineup">
      <div className="l-hp__container-sm">
        <h2 className="l-hp__title text-effect" data-aos="fade-up">
          <span>LINEUP</span>
        </h2>
        <p className="lineup__price aos-init aos-animate" data-aos="fade-up">
          6 models / 2 colours{' '}
          <strong className="lineup__price-strong">
            <span className="lineup__price-span">S$</span>178
          </strong>
        </p>
        <ol className="lineup__nav">
          {products.map((nav, index) => (
            <li key={index} data-aos="fade-up">
              <a data-scroll-offset={isSP ? 30 : 100} href={`#${nav.id}`}>
                <img alt={nav.prodCode} src={`${baseImgUrl}lineup-nav-${index + 1}.webp`} />
                <div className="lineup__nav-badge">
                  <img alt={nav.title} src={`${baseImgUrl}badge-${index + 1}.webp`} />
                </div>
                <div className="lineup__nav-details">
                  <span className="lineup__nav-details-model">Model</span>
                  <span className="lineup__nav-details-title">{nav.title}</span>
                </div>
              </a>
            </li>
          ))}
        </ol>
      </div>
      <div className="l-hp__container">
        <ul className="lineup__products">
          {products.map((product, index) => (
            <li key={index} id={product.id}>
              <div className="lineup__products-top">
                <div data-aos className="swiper lineup__products-top-swiper" data-aos-offset="0">
                  <div className="swiper-wrapper">
                    {[...Array(2)].map((_, index) => (
                      <div key={index} className="swiper-slide">
                        <picture>
                          {!isSP && (
                            <source
                              media="(min-width:768px)"
                              srcSet={`${baseImgUrl}${product.number}-slider-${index + 1}.webp`}
                              type="image/webp"
                            />
                          )}
                          <img
                            alt={product.prodCode}
                            src={`${baseImgUrl}${product.number}-slider-${index + 1}-sp.webp`}
                          />
                        </picture>
                      </div>
                    ))}
                  </div>
                  <div className="lineup__products-top-swiper-pagination"></div>
                </div>
                <div className="lineup__products-top-text">
                  <h4 data-aos className="lineup__title">
                    <div className="lineup__title-badge">
                      <img alt={product.title} src={`${baseImgUrl}badge-${index + 1}.webp`} />
                    </div>
                    <span className="lineup__title-text">
                      <span className="lineup__title-text-main">{product.title}</span>
                      <span className="lineup__title-text-sub">Model</span>
                    </span>
                  </h4>
                  <p data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                    {product.desc}
                  </p>
                </div>
              </div>

              <div className="lineup__products-middle">
                <div className="swiper lineup__products-middle-swiper">
                  <ul className="swiper-wrapper">
                    {product.middleImages.map((image, index) => (
                      <li
                        key={index}
                        className="swiper-slide"
                        data-aos="fade-up"
                        data-aos-offset="300"
                      >
                        <img
                          alt={stripHtml(image.desc)}
                          src={getProductImageSrc(product.prodCode, baseImgUrl, index)}
                        />
                        <p dangerouslySetInnerHTML={createMarkup(image.desc)} />
                      </li>
                    ))}
                  </ul>
                  <div className="lineup__default-pagination lineup__products-middle-swiper-pagination"></div>
                </div>
              </div>

              <div className="lineup__products-bottom">
                <div className="lineup__products-bottom-head">
                  <h6 className="lineup__products-bottom-head-title">{product.prodCode}</h6>
                  <ul className="nav">
                    {product.tabs.map((tab, index) => (
                      <li key={index} className="nav-item">
                        <button
                          className={`nav-link ${tab.colorClass} ${
                            activeTab[product.id] === index ? 'active' : ''
                          }`}
                          style={{ backgroundColor: tab.colorHex }}
                          onClick={() => handleTabClick(product.id, index)}
                        >
                          {tab.sku}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="tab-contents">
                  {product.tabs.map((tab, tabIndex) => (
                    <div
                      key={tabIndex}
                      className={`tab-content ${
                        activeTab[product.id] === tabIndex ? 'active' : ''
                      }`}
                    >
                      <div className="swiper lineup__products-bottom-swiper">
                        <ul className="swiper-wrapper">
                          {tab.images.map((image, imageIndex) => (
                            <li key={imageIndex} className="swiper-slide">
                              <img alt={product.prodCode} src={image.src} />
                            </li>
                          ))}
                        </ul>
                        <div className="lineup__default-pagination lineup__products-bottom-swiper-pagination"></div>
                        <button className="button-next" type="button">
                          Next
                        </button>
                        <button className="button-prev" type="button">
                          Previous
                        </button>
                      </div>
                      <a
                        className="l-hp__btn"
                        href={`/sg/en/harry-potter-${product.prodCode.toLowerCase()}-${tab.sku.toLowerCase()}-eyeglasses.html`}
                      >
                        <span className="l-hp__btn-inner">ONLINE STORE</span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Lineup;
