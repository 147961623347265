import React, { useEffect } from 'react';
import './SearchBarStatic.scss';

import SearchBarResults from '../SearchBarResults/SearchBarResults';

import { readQueryParam } from '../../../../utils/helper';

const SearchBarStatic = props => {
  const {
    classes,
    placeholder,
    inputRef,
    handleBlur,
    handleFocus,
    handleKeyUp,
    handleKeyDown,
    getSearchResult,
    showAllResults,
    showSearchResult,
    showTrending,
    location,
    FilterDataObj,
    filterData,
  } = props;

  const searchTextParam = readQueryParam('q', location.search, true);

  const cleanedFilterDataObj = Object.entries(FilterDataObj).reduce(
    (acc, [filterName, selectedOptions]) => {
      if (selectedOptions.length) {
        acc[filterName] = selectedOptions;
      } else {
        delete FilterDataObj[filterName];
      }

      return acc;
    },
    {}
  );

  const filterDataAll = filterData && filterData.result && filterData.result.filters;

  const handleClearSearchText = () => {
    if (filterDataAll && Object.keys(cleanedFilterDataObj)?.length) {
      window.location.href = '/eyeglasses/frame-shape/round.html';
    } else {
      window.history.back();
    }
  };

  useEffect(() => {
    inputRef.current.value = searchTextParam;
  }, [inputRef, searchTextParam]);

  return (
    <div className={`search-bar-static ${classes}`}>
      <div className="search-bar-static__layout">
        <input
          ref={inputRef}
          autoComplete="off"
          className="search-bar-static__input od-font-reg"
          name="q"
          placeholder={placeholder}
          type="text"
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={event => handleKeyDown(event)}
          onKeyUp={event => handleKeyUp(event)}
        />
        {inputRef?.current?.value ? (
          <img
            alt="true"
            className="search-bar-static__icon search-bar-static__icon--close"
            src="/assets/svg/od-cross-icon.svg"
            onClick={handleClearSearchText}
          />
        ) : (
          <img
            alt="true"
            className="search-bar-static__icon"
            src="https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg"
            onClick={() => inputRef.current.focus()}
          />
        )}
      </div>

      <SearchBarResults
        getSearchResult={getSearchResult}
        showAllResults={showAllResults}
        showSearchResult={showSearchResult}
        showTrending={showTrending}
      />
    </div>
  );
};

export default SearchBarStatic;
