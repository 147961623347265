import React, { useState } from 'react';
import Slider from 'react-slick';

import './SliderOwndaysPc.scss';

export const SliderOwndaysPc = props => {
  const { sliderRef, images, baseImgUrl } = props;
  const [, setCurrentIndex] = useState(0);

  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    variableWidth: true,
    slidesToShow: 1,
    centerPadding: '0px',
    arrows: false,
    beforeChange: (current, next) => {
      setCurrentIndex(next);
    },
    afterChange: current => {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(current, true);
        }
      }, 0);
    },
    responsive: [
      {
        breakpoint: 1296,
        settings: {
          centerMode: true,
          variableWidth: false,
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          variableWidth: false,
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="slider-owndays-pc">
      <div className="slider-owndays-pc__btn-prev">
        <button
          className="base-owndays-pc__btn base-owndays-pc__btn--left base-owndays-pc__btn--circle"
          onClick={handlePrev}
        ></button>
      </div>

      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <li key={index}>
            <img alt="OWNDAYS PC" src={`${baseImgUrl}/${image}`} />
          </li>
        ))}
      </Slider>

      <div className="slider-owndays-pc__btn-next">
        <button
          className="base-owndays-pc__btn base-owndays-pc__btn--right base-owndays-pc__btn--circle"
          onClick={handleNext}
        ></button>
      </div>
    </div>
  );
};
