import * as actionTypes from './../../actionTypes/frameSize';

export default function frameSize(prevState = {}, action) {
  switch (action.type) {
    case actionTypes.GET_FRAME_SIZE:
      return Object.assign({}, prevState, { loading: true });
    case actionTypes.GET_FRAME_SIZE_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        frameSizeData: action.data,
        frameSizeError: null,
      });
    case actionTypes.GET_FRAME_SIZE_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        frameSizeData: null,
        frameSizeError: action.error,
      });
    case actionTypes.STORE_FRAME_SIZE:
      return Object.assign({}, prevState, { storeFrameSizeData: null, storeFrameSizeError: null });
    case actionTypes.STORE_FRAME_SIZE_SUCCESS:
      return Object.assign({}, prevState, {
        storeFrameSizeData: action.data,
        storeFrameSizeError: null,
      });
    case actionTypes.STORE_FRAME_SIZE_FAIL:
      return Object.assign({}, prevState, {
        storeFrameSizeData: null,
        storeFrameSizeError: action.error,
      });
    default:
      return prevState;
  }
}
