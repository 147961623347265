import React from 'react';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import './css/ServicesCareHTML.scss';
import { FEATURES, MAIN, TERMS } from './constants/ServicesCareHTML';

const ServicesCareHTML = () => {
  const crumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/services', label: 'Services' },
    { path: '/services/care', label: 'OWNDAYS care+' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="l-care">
        {MAIN && (
          <section className="l-care__main">
            <div className="l-care__main-logo">
              <img src={MAIN.logoSrc} alt={MAIN.logoAlt} />
            </div>
            <div className="l-care__main-content">
              <h1
                className="l-care__main-content-heading"
                dangerouslySetInnerHTML={{ __html: MAIN.heading }}
              />
              <img className="l-care__main-content-img" src={MAIN.imgSrc} alt={MAIN.imgAlt} />
            </div>
            <p className="l-care__main-footer">{MAIN.footer}</p>
          </section>
        )}
        {FEATURES && (
          <section className="l-care__features">
            <h2 className="l-care__features-title">{FEATURES.title}</h2>
            <ul className="l-care__features-list">
              {FEATURES.list.map((feature, featureIndex) => (
                <li key={featureIndex} className="l-care__features-item">
                  <p className="l-care__features-item-title">
                    <span dangerouslySetInnerHTML={{ __html: feature.title }} />
                  </p>
                  <p className="l-care__features-item-text">{feature.text}</p>
                </li>
              ))}
            </ul>
          </section>
        )}
        {TERMS && (
          <section className="l-care__terms">
            <div className="l-care__terms-inner">
              <h3 className="l-care__terms-title">{TERMS.title}</h3>
              {TERMS.text.map((term, termIndex) => (
                <p key={termIndex} className="l-care__terms-text">{term}</p>
              ))}
            </div>
          </section>
        )}
      </main>
    </>
  );
};

export default ServicesCareHTML;
