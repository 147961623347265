import * as actionTypes from './../../actionTypes/primer';

const initialState = {
  clientTokenData: null,
  clientTokenErrorData: null,
};

export default function primer(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_CLIENT_TOKEN_LOAD:
      return Object.assign({}, prevState, {
        clientTokenData: null,
        clientTokenErrorData: null,
        isLoading: true,
      });
    case actionTypes.GET_CLIENT_TOKEN_SUCCESS:
      return Object.assign({}, prevState, {
        clientTokenData: action.data,
        clientTokenErrorData: null,
        isLoading: false,
      });
    case actionTypes.GET_CLIENT_TOKEN_FAIL:
      return Object.assign({}, prevState, {
        clientTokenData: null,
        clientTokenErrorData: action.error,
        isLoading: false,
      });
    case actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_LOAD:
      return Object.assign({}, prevState, {
        paymentMethodsWithPrimerData: null,
        paymentMethodsWithPrimerErrorData: null,
        isLoading: true,
      });
    case actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_SUCCESS:
      return Object.assign({}, prevState, {
        paymentMethodsWithPrimerData: action.data,
        paymentMethodsWithPrimerErrorData: null,
        isLoading: false,
      });
    case actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_FAIL:
      return Object.assign({}, prevState, {
        paymentMethodsWithPrimerData: null,
        paymentMethodsWithPrimerErrorData: action.error,
        isLoading: false,
      });
    case actionTypes.GET_PRIMER_SAVED_CARDS_LOAD:
      return Object.assign({}, prevState, {
        primerSavedCardsData: null,
        primerSavedCardsErrorData: null,
        isLoading: true,
      });
    case actionTypes.GET_PRIMER_SAVED_CARDS_SUCCESS:
      return Object.assign({}, prevState, {
        primerSavedCardsData: action.data,
        primerSavedCardsErrorData: null,
        isLoading: false,
      });
    case actionTypes.GET_PRIMER_SAVED_CARDS_FAIL:
      return Object.assign({}, prevState, {
        primerSavedCardsData: null,
        primerSavedCardsErrorData: action.error,
        isLoading: false,
      });
    case actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_LOAD:
      return Object.assign({}, prevState, {
        primerPaymentStatusUpdateData: null,
        primerPaymentStatusUpdateErrorData: null,
        isLoading: true,
      });
    case actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_SUCCESS:
      return Object.assign({}, prevState, {
        primerPaymentStatusUpdateData: action.data,
        primerPaymentStatusUpdateErrorData: null,
        isLoading: false,
      });
    case actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_FAIL:
      return Object.assign({}, prevState, {
        primerPaymentStatusUpdateData: null,
        primerPaymentStatusUpdateErrorData: action.error,
        isLoading: false,
      });
    case actionTypes.RESET_PRIMER_DATA:
      return Object.assign({}, prevState, {
        primerPaymentStatusUpdateData: null,
        primerPaymentStatusUpdateErrorData: null,
        primerSavedCardsData: null,
        primerSavedCardsErrorData: null,
        paymentMethodsWithPrimerData: null,
        paymentMethodsWithPrimerErrorData: null,
        clientTokenData: null,
        clientTokenErrorData: null,
        isLoading: false,
      });
    case actionTypes.SHOW_LOADER:
      return Object.assign({}, prevState, {
        isLoading: true,
      });
    case actionTypes.HIDE_LOADER:
      return Object.assign({}, prevState, {
        isLoading: false,
      });
    default:
      return prevState;
  }
}
