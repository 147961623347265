import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/store';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;

function* getStoresByAddress({ address, pid }) {
  const successAction = { type: actionTypes.STORE_LOAD_SUCCESS };
  const errorAction = { type: actionTypes.STORE_LOAD_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.storeApi}/stores/nearby?area=${address}&pid=${pid}`)
    );
    successAction.data = data ? data.result : [];
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* createAppointment(data) {
  const successAction = { type: actionTypes.CREATE_APPOINTMENT_SUCCESS };
  const errorAction = { type: actionTypes.CREATE_APPOINTMENT_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.post(`${config.apiPath.storeApi}/appointments`, data.data)
    );
    successAction.data = response ? response.result : {};
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* storeProductForPincode({ pincode, productId }) {
  const client = { ..._client };
  client.setHeader();
  try {
    yield call(async () =>
      client.post(`${config.apiPath.storeApi}/pincode/${pincode}/product`, { productId })
    );
  } catch (er) {
    console.log(er);
  }
}

export function* runStoreCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.STORE_LOAD, getStoresByAddress),
    takeLatest(actionTypes.CREATE_APPOINTMENT, createAppointment),
    takeLatest(actionTypes.STORE_PRODUCT_FOR_PINCODE, storeProductForPincode),
  ]);
}
