import * as actionTypes from './../../actionTypes/header';

const initialState = {
  showGlobalHeader: true,
  showGlobalSecondaryHeader: false,
  showGlobalFooter: true,
  showLoginPopup: false,
  loaded: false,
  isMenuVisible: true,
  loading: false,
  mainNavigationData: {},
  customHeader: { type: 'NA' },
  appDownloadHeader: false,
};

export default function header(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.LOAD_NAVIGATION:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        mainNavigationData: action.data,
      });
    case actionTypes.FAIL_NAVIGATION:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        mainNavigationData: action.error,
      });
    case actionTypes.UPDATE_MAIN_NAVIGATION_LOCAL:
      return Object.assign({}, prevState, { mainNavigationData: action.data });
    case actionTypes.MENU_RENDER:
      return Object.assign({}, prevState, { isMenuVisible: action.value });
    case actionTypes.SHOW_GLOBAL_HEADER_FOOTER:
      '#if CLIENT_TYPE === "mobile"'; // eslint-disable-line
      if (typeof window !== 'undefined' && !(action.value && action.value.header))
        window.appNavContainer = null;
      '#endif'; // eslint-disable-line
      return Object.assign({}, prevState, {
        showGlobalHeader: action.value.header,
        showGlobalFooter: action.value.footer,
        showGlobalSecondaryHeader: action.value.secondaryHeader || false,
      });
    case actionTypes.GET_MSITE_NAV_SUCCESS:
      return Object.assign({}, prevState, { mobileNavigationData: action.data });
    case actionTypes.APP_DOWNLOAD_HEADER_SHOW:
      return Object.assign({}, prevState, {
        appDownloadHeader: action.show,
      });
    default:
      return prevState;
  }
}
