import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiClient from '../helpers/apiClient';
import * as actionTypes from '../actionTypes/PD';
import config from 'config';
import { dataURItoBlob } from '../utils/helper';

let _client = null;

function* getPD({ image, phNumber, phoneCode, countryCode }) {
  const successAction = { type: actionTypes.GET_PD_SUCCESS };
  const errorAction = { type: actionTypes.GET_PD_FAILURE };
  const binaryImage = dataURItoBlob(image);
  const formData = new FormData();
  formData.append('file', binaryImage);

  try {
    const headers =
      phNumber && phoneCode
        ? {
            'X-Customer-Phone': phNumber,
            'X-Customer-Phone-Code': phoneCode,
            'X-Country-Code': countryCode,
          }
        : {};
    const response = yield call(async () =>
      _client.post(`${config.apiPath.pd}`, formData, {
        headers,
      })
    );
    if (response?.faces?.attributes?.pd?.value) {
      successAction.data = response?.faces?.attributes?.pd?.value;
      yield put(successAction);
    } else {
      errorAction.error = { message: 'Some error occured. Please try again.' };
      yield put(errorAction);
    }
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* setPD({ pdValue }) {
  const action = { type: actionTypes.SET_PD_SUCCESS, data: pdValue };
  yield put(action);
}

function* clearError() {
  const action = { type: actionTypes.CLEAR_ERROR_SUCCESS, error: null };
  yield put(action);
}

export function* runPDCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_PD, getPD),
    takeLatest(actionTypes.SET_PD, setPD),
    takeLatest(actionTypes.CLEAR_ERROR, clearError),
  ]);
}
