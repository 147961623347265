import React from 'react';

const Spec = ({ baseImgUrl, isSP }) => {
  const list1 = (
    <li className="item01">
      <h3>
        <span>
          <img alt="Speakers" src={`${baseImgUrl}broadcast.svg`} />
        </span>
        <span>Speakers</span>
      </h3>
      <div className="detail-spec">
        <p>Co-directional dual diaphragm dynamic driver</p>
        <p>Audio formats: SBC/AAC</p>
        <p>Frequency response range: 100Hz - 12,000Hz</p>
      </div>
    </li>
  );
  const list2 = (
    <li className="item02">
      <h3>
        <span>
          <img alt="Microphones" src={`${baseImgUrl}microphone-icon.svg`} />
        </span>
        <span>Microphones</span>
      </h3>
      <div className="detail-spec">
        <p>Dual-mic design</p>
        <p>Call noise cancellation: supported</p>
      </div>
    </li>
  );
  const list3 = (
    <li className="item03">
      <h3>
        <span>
          <img alt="Battery" src={`${baseImgUrl}battery-icon.svg`} />
        </span>
        <span>Battery</span>
      </h3>
      <div className="detail-spec">
        <p>Music playback: up to 11 hours</p>
        <p>Voice call: up to 9 hours</p>
        <p>Standby: 96 hours</p>
        <p>(not in active use when worn)</p>
        <p>Time required for full charge: approximately 50 minutes</p>
        <div className="note note01">
          <p>
            Battery life and charging data are based on audio played at 60% volume level in AAC
            format with default settings used for other features. Battery life may vary depending on
            the volume level, audio source, environment interference, product settings and usage.
          </p>
          <p>
            Charging data comes from Huawei lab tests conducted at a 25℃ ambient temperature with a
            Type-C charging cable and a standard charger of 5V1A or higher. Charging speed slows
            down as the ambient temperature decreases.
          </p>
        </div>
      </div>
    </li>
  );
  const list4 = (
    <li className="item04">
      <h3>
        <span>
          <img alt="Main Product Weight" src={`${baseImgUrl}scales-icon.svg`} />
        </span>
        <span>Main Product Weight</span>
        <span className="small-title">* excluding lenses</span>
      </h3>
      <div className="detail-spec">
        <p>HWF2003N-3A: 29.5g</p>
        <p>HWF2004N-3A: 32.5g</p>
        <p>HWF2005N-3A: 31.0g</p>
        <p>HWF2006N-3A: 31.0g</p>
        <div className="note note02">
          <p>
            Test data comes from Huawei labs and OWNDAYS. Dimensions, weight and specifications may
            vary from the actual product. Actual product specifications shall prevail.
          </p>
        </div>
      </div>
    </li>
  );
  const list5 = (
    <li className="item05">
      <h3>
        <span>
          <img alt="Others" src={`${baseImgUrl}setting-icon.svg`} />
        </span>
        <span>Others</span>
      </h3>
      <div className="detail-spec">
        <p>Connectivity: Bluetooth 5.3</p>
        <p>Dual device connection: supported</p>
        <p>Wearing detection: supported</p>
        <p>Controls: long tap / slide / double tap</p>
        <p>Water resistance: IP54</p>

        <div className="note note03">
          <p>
            This product is dust and splash resistant during normal use. It is rated IP54 under the
            IEC standard 60529 when tested under controlled lab conditions. It is recommended that
            you avoid wearing the product during water-related activities. Splash and dust resistant
            is not permanent and the protection may decrease with daily wear and tear. Do not charge
            the product in a damp environment.
          </p>
        </div>
        <p>OS compatibility: iOS / Android / Mac / Windows</p>
      </div>
    </li>
  );
  const list6 = (
    <li className="item06">
      <h3>
        <span>
          <img alt="In the Box" src={`${baseImgUrl}box-icon.svg`} />
        </span>
        <span>In the Box</span>
      </h3>
      <div className="detail-spec">
        <p className="manual">
          <a download href={`${baseImgUrl}HUAWEI-Eyewear2_QuickStartGuide.pdf`}>
            <u>Quick Start Guide</u>
            <img alt="Quick Start Guide" src={`${baseImgUrl}icon_pdf.svg`} />
          </a>
        </p>
        <p>Information on warranty and after-sales services</p>
        <p>Spectacle cloth</p>
        <div className={isSP ? '' : 'place-list'}>
          <div className="detail-list-box">
            <div className="list-box">
              <div>
                <div className="img">
                  <picture>
                    <img alt="Foldable case" src={`${baseImgUrl}accessories_case.webp`} />
                  </picture>
                </div>
                <div className="txt">
                  <p>Foldable case</p>
                </div>
              </div>
              <div>
                <div className="img">
                  <picture>
                    <img alt="Charging converter" src={`${baseImgUrl}accessories_charger.webp`} />
                  </picture>
                </div>
                <div className="txt">
                  <p>Charging converter</p>
                </div>
              </div>
              <div>
                <div className="img">
                  <picture>
                    <img
                      alt="Spare hinges (1 set in size S)"
                      src={`${baseImgUrl}accessories_hinge.webp`}
                    />
                  </picture>
                </div>
                <div className="txt">
                  <p>Spare hinges (1 set in size S)</p>
                </div>
              </div>
            </div>
          </div>
          <div className="box-arrow-wrap-pc">
            <div className="box-arrow">
              <p>
                This product can be used with two types of hinges with different lengths. All online
                orders, except those who have selected the Lens Replacement Voucher option, are
                pre-fitted with size L hinges. Please bring the smart audio glasses along with the
                spare hinges to your{' '}
                <a href="https://www.owndays.com/stores/sg" target="blank">
                  nearest OWNDAYS store
                </a>{' '}
                if you would like to change them to size S hinges. Changing of hinges is
                complimentary.
              </p>
            </div>
          </div>
        </div>
        <div className="note note04">
          <p>
            Immersing the spectacle case in water may cause permanent damage to the smart audio
            glasses stored inside the case.
          </p>
          <p>Do not store charging converter in the spectacle case with the smart audio glasses.</p>
        </div>
      </div>
    </li>
  );

  return (
    <section className="l-huawei2-spec" data-aos="fade-up" data-aos-offset="300">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2__heading l-huawei2__heading--lg">SPECIFICATIONS</h2>
        {isSP && (
          <ul className="l-huawei2-spec-list-sp">
            {list1}
            {list2}
            {list3}
            {list4}
            {list5}
            {list6}
          </ul>
        )}
        {!isSP && (
          <div className="l-huawei2-spec-list-pc">
            <div className="l-huawei2-spec-list-pc-top">
              <ul>
                {list1}
                {list2}
                {list3}
              </ul>
              <ul>
                {list4}
                {list5}
              </ul>
            </div>
            <div className="l-huawei2-spec-list-pc-bottom">
              <ul>{list6}</ul>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Spec;
