import * as actionTypes from '../actionTypes/search';

export function searchInputChange(value) {
  return { type: actionTypes.SEARCH_INPUT_CHANGE, value };
}

export function resetSearchData() {
  return { type: actionTypes.RESET_SEARCH_DATA };
}

export function suggestionClick(suggestionValue) {
  return { type: actionTypes.SUGGESTION_CLICK, suggestionValue };
}
