import React from 'react';

const BackIcon = ({ stroke = '#546073', strokeWidth = 2 }) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18L9 12L15 6" stroke={stroke} strokeLinecap="square" strokeWidth={strokeWidth} />
    </svg>
  );
};

export default BackIcon;
