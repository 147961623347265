import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import * as reviewActionTypes from '../actionTypes/review';
import apiClient from '../helpers/apiClient';

let _client = null;
function* getproductReviewListData(action) {
  const { productId, page, pageCount } = action.payload;
  const successAction = { type: reviewActionTypes.GET_PRODUCT_REVIEW_LIST_SUCCESS };
  const errorAction = { type: reviewActionTypes.GET_PRODUCT_REVIEW_LIST_FAIL };
  const client = { ..._client };
  const url = `${config.apiPath.productReviewList}${productId}/review?count=${pageCount}&page=${page}`;
  try {
    client.setHeader();
    const response = yield call(async () => client.get(url));
    successAction.data = response;
    yield put(successAction);
  } catch (err) {
    errorAction.data = err;
    yield put(errorAction);
  }
}

function* postReview(action) {
  const { productId, postObj } = action.payload;
  const successAction = { type: reviewActionTypes.POST_REVIEW_SUCCESS };
  const errorAction = { type: reviewActionTypes.POST_REVIEW_FAIL };
  const client = { ..._client };
  const url = `${config.apiPath.productReviewList}${productId}/review`;
  try {
    client.setHeader();
    const response = yield call(async () => client.post(url, postObj));
    successAction.data = response;
    yield put(successAction);
  } catch (err) {
    errorAction.data = err;
    yield put(errorAction);
  }
}

export function* runReviewCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(reviewActionTypes.GET_PRODUCT_REVIEW_LIST_LOAD, getproductReviewListData),
    takeLatest(reviewActionTypes.POST_REVIEW_LOAD, postReview),
  ]);
}
