import React from 'react';

const BodyContacts = ({ imgUrl, lineup }) => {
  return (
    <>
      <section className="contacts-page__banner">
        <div className="contacts-page__container contacts-page__container--lg">
          <a className="contacts-page__banner-link" href="/sg/en/news/bundle-sale">
            <img
              loading="lazy"
              src="https://static1.lenskart.com/media/owndays/desktop/img/contact-lens-contactlenspromo.webp"
              alt="BUNDLE SALE"
            />
          </a>
        </div>
      </section>
      <section className="contacts-page__lineup">
        <div className="contacts-page__container">
          <h3 className="contacts-page__lineup-heading">
            Browse our Clear Vision Contact Lens Ranges
          </h3>
          <h2 className="contacts-page__heading">LINE UP</h2>
          {lineup && (
            <ul className="contacts-page__lineup-list">
              {lineup.map(item => (
                <li key={item.title} className="contacts-page__lineup-item">
                  <img
                    className="contacts-page__lineup-item-img"
                    loading="lazy"
                    src={`${imgUrl}/${item.imgSrc}`}
                    alt={item.imgAlt}
                    style={{ maxHeight: item.imgH }}
                  />
                  {item.tags && (
                    <div className="contacts-page__lineup-item-tags">
                      {item.tags.map(tag => (
                        <span key={tag}>{tag}</span>
                      ))}
                    </div>
                  )}
                  <h4
                    className="contacts-page__lineup-item-title"
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <p className="contacts-page__lineup-item-price">Price: {item.price}</p>
                  <a className="contacts-page__lineup-item-btn" href={item.href}>
                    VIEW MORE
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default BodyContacts;
