import React, { useState } from 'react';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import HeaderFreeMyopiaControl2Lenses from './HeaderFreeMyopiaControl2Lenses.jsx';
import BodyFreeMyopiaControl2Lenses from './BodyFreeMyopiaControl2Lenses.jsx';
import FooterFreeMyopiaControl2Lenses from './FooterFreeMyopiaControl2Lenses.jsx';
import ModalFreeMyopiaControl2Lenses from './ModalFreeMyopiaControl2Lenses.jsx';
import YouTubeModal from '../YouTubeModal/YouTubeModal';
import './FreeMyopiaControl2Lenses.scss';

const FreeMyopiaControl2Lenses = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalTermsOpen, setIsModalTermsOpen] = useState(false);
  const videoId = '1h1d81s9p84';
  const crumbs = [
    { path: '/', label: 'Home' },
    { path: '/news', label: 'News' },
    { path: 'news/free-myopia-control-2-lenses', label: 'Free Myopia Control 2 Lenses' },
  ];
  const baseImgUrl =
    'https://static.lenskart.com/media/owndays/img/news/free-myopia-control-2-lenses';

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="fmc2l">
        <HeaderFreeMyopiaControl2Lenses baseImgUrl={baseImgUrl} />
        <BodyFreeMyopiaControl2Lenses baseImgUrl={baseImgUrl} setIsModalOpen={setIsModalOpen} setIsModalTermsOpen={setIsModalTermsOpen} />
        <FooterFreeMyopiaControl2Lenses />
      </main>
      <YouTubeModal videoId={videoId} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <ModalFreeMyopiaControl2Lenses isOpen={isModalTermsOpen} onClose={() => setIsModalTermsOpen(false)} />
    </>
  );
};

export default FreeMyopiaControl2Lenses;
