import React from 'react';
import './ProductPurchaseSection.scss';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import ExpiryInfo from '../../../views/desktop/GiftCards/ExpiryInfo/ExpiryInfo';
import CartIcon from '../../../../../static/assets/svg/od-gift-cart-white-icon.svg';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../../../utils/helper';

const ProductPurchaseSection = ({ purchaseAction, device }) => {
  const dataLocale = useSelector(state => state.locale);

  const { ADD_TO_CART } = dataLocale;

  return (
    <div className="product-purchase-section">
      <ButtonOwnDays
        classes={
          device === 'desktop'
            ? 'od-gift-cards__main__right__btn-atc'
            : 'od-gift-cards-m__main__right__btn-atc'
        }
        fontClass="od-font-bold"
        fontSize="20px"
        mode="grey"
        px="0"
        py="16px"
        width="100%"
        onClick={purchaseAction}
      >
        <span>{capitalizeFirstLetter(ADD_TO_CART.toLowerCase())}</span>
        {device === 'mobile' && <img alt="cart" height={16} src={CartIcon} width={16} />}
      </ButtonOwnDays>
      {device === 'desktop' ? <ExpiryInfo /> : null}
    </div>
  );
};

export default ProductPurchaseSection;
