import React from 'react';
import './Modal.scss';

const Modal = ({ className = '', isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className={`modal__overlay ${className}`} onClick={onClose}>
      <div className="modal__content" onClick={e => e.stopPropagation()}>
        <button className="modal__close" onClick={onClose}>
          <img
            src="https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/icon-close.svg"
            alt="Icon close"
          />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
