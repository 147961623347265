import * as actionTypes from './../../actionTypes/success';

const initialState = {
  productsDetail: null,
  powersDetail: null,
  activeFrameData: null,
  classIndex: 0,
  savedPrescription: null,
  powerSubmitted: null,
  showRefDD: null,
  powerSubmitFail: false,
  productLoad: false,
  errorMsg: '',
  enterData: { left: {}, right: {} },
  successOtp: false,
  powerSubmittedItemId: null,
  packageSubmitted: null,
  packageSubmitFail: false,
  uploadPd: null,
  slotsData: null,
  appointmentData: null,
  appointmentFail: false,
  pfuAppointmentDetail: null,
  cancelAppointmentDetail: null,
  hecAppointmentDetail: null,
  hecCancelDetail: null,
  hecDetailupdate: null,
};

export default function reducer(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.POWERS_DETAIL_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        powersDetail: action.result,
      });
    case actionTypes.POWERS_DETAIL_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        powersDetail: action.result,
      });
    case actionTypes.POWERS_DETAIL_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        powersDetail: action.data,
      });
    case actionTypes.SUBMITTED_POWERS_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        itemSubmittedPrescription: action.data,
      });
    case actionTypes.SUBMITTED_POWERS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        itemSubmittedPrescription: action.data,
      });
    case actionTypes.SUBMITTED_POWERS_FAIL:
      return Object.assign({}, prevState, {
        itemSubmittedPrescription: null,
      });
    case actionTypes.PD_CAMERA_UPLOAD_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
      });
    case actionTypes.PD_CAMERA_UPLOAD_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        uploadPd: action.data,
      });
    case actionTypes.PD_CAMERA_UPLOAD_FAIL:
      return Object.assign({}, prevState, {
        uploadPd: null,
      });
    case actionTypes.SLOTS_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        slotsData: action.data,
      });
    case actionTypes.SLOTS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        slotsData: action.data,
      });
    case actionTypes.SLOTS_FAIL:
      return Object.assign({}, prevState, {
        slotsData: action.error,
      });
    case actionTypes.APPOINTMENT_BOOK_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        appointmentFail: false,
      });
    case actionTypes.APPOINTMENT_BOOK_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        appointmentData: action.data,
        appointmentFail: false,
      });
    case actionTypes.APPOINTMENT_BOOK_FAIL:
      return Object.assign({}, prevState, {
        appointmentData: null,
        appointmentFail: true,
      });
    case actionTypes.GET_APPOINTMENT_DETAILS_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
      });
    case actionTypes.GET_APPOINTMENT_DETAILS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        pfuAppointmentDetail: action.data,
      });
    case actionTypes.GET_APPOINTMENT_DETAILS_FAIL:
      return Object.assign({}, prevState, {
        pfuAppointmentDetail: null,
      });
    case actionTypes.CANCEL_PFU_APPOINTMNET_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
      });
    case actionTypes.CANCEL_PFU_APPOINTMNET_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        cancelAppointmentDetail: action.data,
      });
    case actionTypes.CANCEL_PFU_APPOINTMNET_FAIL:
      return Object.assign({}, prevState, {
        cancelAppointmentDetail: action.error,
      });
    case actionTypes.HEC_APPOINTMNET_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
      });
    case actionTypes.HEC_APPOINTMNET_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        hecAppointmentDetail: action.data,
      });
    case actionTypes.HEC_APPOINTMNET_FAIL:
      return Object.assign({}, prevState, {
        hecAppointmentDetail: action.error,
      });
    case actionTypes.HEC_CANCEL_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.HEC_CANCEL_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        hecCancelDetail: action.data,
      });
    case actionTypes.HEC_CANCEL_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        hecCancelDetail: null,
      });
    case actionTypes.HEC_DETAIL_JUNO_UPDATE_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
      });
    case actionTypes.HEC_DETAIL_JUNO_UPDATE_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        hecDetailupdate: action.data,
      });
    case actionTypes.HEC_DETAIL_JUNO_UPDATE_FAIL:
      return Object.assign({}, prevState, {
        hecDetailupdate: null,
      });
    case actionTypes.OTP_SUBMISSION_LOAD:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        OtpAction: action.result,
        successOtp: true,
      });
    case actionTypes.OTP_SUBMISSION_FAIL:
      return Object.assign({}, prevState, {
        OtpAction: action.error,
        successOtp: false,
      });
    case actionTypes.OTP_SUBMISSION_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        OtpAction: action.data,
        successOtp: true,
      });
    case actionTypes.SAVED_PRESCRIPTION_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        savedPrescription: action.data,
      });
    case actionTypes.SAVED_PRESCRIPTION_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        savedPrescription: action.data,
      });
    case actionTypes.SAVED_PRESCRIPTION_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        savedPrescription: action.error,
      });
    case actionTypes.SUBMIT_POWERS_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        powerSubmittedItemId: null,
        packageSubmittedItemId: null,
        powerSubmitted: null,
        powerSubmitFail: false,
      });
    case actionTypes.SUBMIT_POWERS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        powerSubmitted: null,
        powerSubmitFail: action.error,
        powerSubmittedItemId: action.itemId,
      });
    case actionTypes.SUBMIT_POWERS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        powerSubmitted: action.data,
        powerSubmitFail: false,
        powerSubmittedItemId: action.itemId,
      });
    case actionTypes.SUBMIT_PACKAGE_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        packageSubmitted: null,
        packageSubmitFail: false,
        powerSubmittedItemId: null,
        packageSubmittedItemId: null,
      });
    case actionTypes.SUBMIT_PACKAGE_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        packageSubmitted: null,
        packageSubmitFail: true,
        packageSubmittedItemId: action.itemId,
      });
    case actionTypes.SUBMIT_PACKAGE_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        packageSubmitted: action.data,
        packageSubmitFail: false,
        packageSubmittedItemId: action.itemId,
      });
    case actionTypes.MSG_ERROR:
      return Object.assign({}, prevState, {
        errorMsg: action.data,
      });
    case actionTypes.ENTER_DATA:
      return Object.assign({}, prevState, {
        enterData: action.data,
      });
    case actionTypes.ACTIVE_FRAME_DATA:
      return Object.assign({}, prevState, {
        activeFrameData: action.data,
        classIndex: action.index,
      });
    case actionTypes.SHOW_DD:
      return Object.assign({}, prevState, {
        showRefDD: action.data,
      });
    case actionTypes.RESET_STATE:
      return Object.assign({}, prevState, initialState);
    default:
      return prevState;
  }
}
