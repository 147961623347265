import React, { Fragment, useEffect } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderOrthoKWebinar.scss';

export const HeaderOrthoKWebinar = ({ baseImgUrl }) => {
  const breadCrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'OWNDAYS Ortho-K Webinar' },
  ];

  const mainAlt = 'OWNDAYS Ortho-K Webinar';
  const aboutTitle = 'What’s Ortho-K ?';

  const sessions = [
    { session: 'Session 1', time: '10:00am - 11:00am' },
    { session: 'Session 2', time: '2:00pm - 3:00pm' },
  ];

  useEffect(() => {
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadCrumbs} />

      <section className="header-ortho-webinar__banner__bg base-ortho-k-webinar__bg">
        <img
          alt={mainAlt}
          className="header-ortho-webinar__banner__bg-line--sp-top"
          src={`${baseImgUrl}/main-line-sp-1.webp`}
        />
        <img
          alt={mainAlt}
          className="header-ortho-webinar__banner__bg-line--sp-bottom"
          src={`${baseImgUrl}/main-line-sp-2.webp`}
        />
        <img
          alt={mainAlt}
          className="header-ortho-webinar__banner__bg-line--pc"
          src={`${baseImgUrl}/main-line-pc.webp`}
        />

        <div className="header-ortho-webinar__banner__container">
          <div className="header-ortho-webinar__banner__text__container">
            <div className="header-ortho-webinar__banner__text__top">
              <h3 className="header-ortho-webinar__banner__text__top__od">OWNDAYS</h3>
              <span className="header-ortho-webinar__banner__text__top__free">Free</span>
              <img
                alt={mainAlt}
                className="header-ortho-webinar__banner__text__top__zoom"
                src={`${baseImgUrl}/zoom.webp`}
              />
            </div>

            <h1 className="header-ortho-webinar__banner__text__title">Ortho-K Webinar</h1>

            <div className="header-ortho-webinar__banner__text__date">
              <h3 className="header-ortho-webinar__banner__text__date__dd-mm-yyyy">
                16 November 2024
              </h3>
              <span className="header-ortho-webinar__banner__text__date__day">Sat.</span>
            </div>

            <ul className="header-ortho-webinar__banner__text__list">
              {sessions.map((item, index) => (
                <li key={index} className="header-ortho-webinar__banner__text__item">
                  <h5 className="header-ortho-webinar__banner__text__item__session">
                    {item.session}
                  </h5>
                  <p className="header-ortho-webinar__banner__text__item__duration-time">
                    {item.time}
                  </p>
                </li>
              ))}
            </ul>

            <p className="header-ortho-webinar__banner__text__remark">* Limited slots available</p>

            <span className="header-ortho-webinar__banner__text__receive">
              Receive an exclusive perk for joining us!
            </span>
          </div>

          <picture className="header-ortho-webinar__banner__img__main">
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
            <img alt={mainAlt} src={`${baseImgUrl}/main-sp.webp`} />
          </picture>
        </div>
      </section>

      <section className="base-ortho-k-webinar__bg">
        <div className="base-ortho-k-webinar__container">
          <h2 className="header-ortho-webinar__about__title header-ortho-webinar__about__title--pc">
            {aboutTitle}
          </h2>

          <div className="header-ortho-webinar__about">
            <a data-fancybox="etc" href="https://youtu.be/CH8DcBcvgNw">
              <picture className="header-ortho-webinar__about__vdo">
                <source media="(min-width:768px)" srcSet={`${baseImgUrl}/vdo-about-pc.webp`} />
                <img alt={mainAlt} src={`${baseImgUrl}/vdo-about-sp.webp`} />
              </picture>
            </a>

            <h2 className="header-ortho-webinar__about__title header-ortho-webinar__about__title--sp">
              {aboutTitle}
            </h2>

            <div>
              <div className="header-ortho-webinar__about__desc">
                <p>
                  Ortho-K, also known as orthokeratology, is the use of customised contact lenses
                  that temporarily reshape the cornea to improve vision during treatment. It is a
                  safe and reversible process that allows you to enjoy spectacle-free vision and is
                  effective in slowing down myopia progression for children.
                </p>

                <p>
                  When worn overnight, the Ortho-K lens gently presses the cornea, causing the
                  centre of the cornea to gradually flatten and moulding the cornea to the correct
                  shape that focuses light onto the retina. When removed, the cornea remains
                  flattened and vision is corrected without the need for spectacles during the day.
                </p>
              </div>

              <div className="header-ortho-webinar__about__more-detail">
                <a
                  className="base-ortho-k-webinar__btn base-ortho-k-webinar__btn--black"
                  href="/sg/en/contacts/orthok-contactlens"
                >
                  More details
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
