import { pushDataLayerEvent } from './DataLayer';

export const triggerDataLayerOnCtaClick = ({ ctaName, ctaFlowAndPage }) => {
  ctaName = ctaName.trim().toLowerCase().replace(/[ ]+/gi, '-');
  ctaFlowAndPage = ctaFlowAndPage.trim().toLowerCase().replace(/[ ]+/gi, '-');
  if (ctaName && ctaFlowAndPage) {
    const data = {
      event: 'cta_click',
      cta_name: ctaName,
      cta_flow_and_page: ctaFlowAndPage,
    };
    pushDataLayerEvent(data);
  }
};

export const triggerDataLayerOnPageView = ({
  // loginStatus,
  ecommerce,
  event = 'view_item_list',
  isPlano,
  clPowerSubmission,
}) => {
  if (ecommerce) {
    pushDataLayerEvent({ ecommerce: null });
    const data = {
      event,
      // login_status: loginStatus ? 'loggedIn' : 'guest',
      ecommerce,
    };
    if (typeof isPlano !== 'undefined') {
      data.isPlano = isPlano;
    }
    if (clPowerSubmission) {
      data.cl_power_submission = clPowerSubmission;
    }
    pushDataLayerEvent(data);
  }
};

export const triggerPurchaseEcomEvent = ({
  // loginStatus,
  ecommerce,
  event = 'purchase',
  order_content,
  delivery_option,
  gokwik_risk_flag,
  gokwik_order_type,
  last_order_city,
  last_transaction_date,
  loyalty_start_date = '',
  loyalty_end_date = '',
  user_loyalty = 'na',
  number_of_items = 0,
}) => {
  if (ecommerce) {
    pushDataLayerEvent({ ecommerce: null });
    const data = {
      event,
      // login_status: loginStatus ? 'loggedIn' : 'guest',
      ecommerce,
      order_content,
      delivery_option,
      gokwik_risk_flag,
      gokwik_order_type,
      last_order_city,
      last_transaction_date,
      loyalty_start_date,
      loyalty_end_date,
      user_loyalty,
      number_of_items,
    };
    pushDataLayerEvent(data);
  }
};

export const triggerDataLayer = details => {
  pushDataLayerEvent(details);
};
