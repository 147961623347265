import React from 'react';
import './SearchBarResults.scss';

import { connect } from 'react-redux';
import { v4 } from 'uuid';

const SearchBarResults = props => {
  const { getSearchResult, showSearchResult, algoliaSearchData } = props;

  const keywordSuggestions = [];

  if (algoliaSearchData) {
    algoliaSearchData.hits.forEach((item, index) => {
      keywordSuggestions.push({
        name: item.objectID,
        uniqueId: `type${index}`,
        objectID: item.objectID,
        query: item.query,
      });
    });
  }

  if (algoliaSearchData && showSearchResult)
    return (
      <div className="search-bar-results od-font-bold">
        {keywordSuggestions.map(data => (
          <div
            key={v4()}
            className="search-bar-results__result"
            onClick={() => getSearchResult(data)}
          >
            {data.name}
          </div>
        ))}
        {}
      </div>
    );

  return null;
};

const mapStateToProps = state => ({
  algoliaSearchData: state.search.algoliaSearchData,
  dataLocale: state.locale,
  localeInfo: state.common.localeInfo,
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarResults);
