import React, { Fragment } from 'react';
import {
  HeaderLensReplacement,
  BodyLensReplacement,
  FooterLensReplacement,
} from '../CommonComponents/LensReplacement';

import '../CommonComponents/LensReplacement/BaseLensReplacement.scss';

const LensReplacementHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/lens-replacement';

  return (
    <Fragment>
      <HeaderLensReplacement baseImgUrl={baseImgUrl} />
      <BodyLensReplacement baseImgUrl={baseImgUrl} />
      <FooterLensReplacement baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default LensReplacementHTML;
