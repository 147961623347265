import * as actionTypes from './../../actionTypes/phoneNumberCapture';
import localStorageHelper from '../../utils/localStorageHelper';

const initialState = {
  hasCookieExpired: null,
  numberSubmitting: false,
  numberSubmitted: null,
  numberSubmitError: null,
  phoneCapturing: false,
  phoneCaptured: null,
};

export default function reducer(prevState = initialState, action) {
  let hasCookieExpired;
  let numberSubmitted;
  let exp;
  switch (action.type) {
    case actionTypes.CHECK_PHONE_NUMBER_EXPIRED:
      const today = new Date();
      const storageExpiry = localStorageHelper.getItem('new-mobile-captured-expiry');
      // const numberGiven = localStorageHelper.getItem('new-mobile-captured');
      hasCookieExpired = false;
      // numberSubmitted = false;
      if (!storageExpiry) {
        hasCookieExpired = true;
      } else if (Number(storageExpiry) < today.getTime()) {
        localStorageHelper.removeItem('new-mobile-captured-expiry');
        hasCookieExpired = true;
      }
      // if (!hasCookieExpired && numberGiven) {
      //     numberSubmitted = true;
      // }
      return { ...prevState, hasCookieExpired };
    case actionTypes.CLOSE_PHONE_NUMBER_CAPTURE:
      exp = new Date(new Date().getTime() + Number(action.expiry)).getTime();
      localStorageHelper.setItem('new-mobile-captured-expiry', JSON.stringify(exp));
      hasCookieExpired = false;
      return { ...prevState, hasCookieExpired };
    case actionTypes.PHONE_NUMBER_CAPTURE_SUBMIT:
      window.sessionStorage.setItem('userPh', action.data.phNo);
      return { ...prevState, numberSubmitting: true, numberSubmitError: null };
    case actionTypes.PHONE_NUMBER_CAPTURE_SUCCESS:
      exp = new Date(new Date().getTime() + Number(action.expiry)).getTime();
      localStorageHelper.setItem('new-mobile-captured-expiry', JSON.stringify(exp));
      localStorageHelper.setItem('new-mobile-captured', action.phNo);
      // hasCookieExpired = false;
      numberSubmitted = true;
      return { ...prevState, numberSubmitted, numberSubmitting: false };
    case actionTypes.PHONE_NUMBER_CAPTURE_FAILED:
      return { ...prevState, numberSubmitting: false, numberSubmitError: action.error };
    case actionTypes.ONLY_PHONE_CAPTURE_SUBMIT:
      return Object.assign({}, prevState, {
        phoneCapturing: true,
        phoneCaptured: null,
      });
    case actionTypes.ONLY_PHONE_CAPTURE_SUCCESS:
      return Object.assign({}, prevState, {
        phoneCaptured: action.data,
        phoneCapturing: false,
      });
    case actionTypes.ONLY_PHONE_CAPTURE_FAILED:
      return Object.assign({}, prevState, {
        phoneCapturing: false,
        phoneCaptured: action.error,
      });
    default:
      return prevState;
  }
}
