function removeFalsyValues(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] == null) delete obj[key];
  });
  return obj;
}

// 2nd param set true if you want to merge with DTM object
export function setWindowDtm(dtm, mergeWindowDtm = false) {
  if (typeof window !== 'undefined') {
    if (window.dataLayer) {
      let newDtm = {};
      if (mergeWindowDtm) {
        newDtm = { ...window.dtm, ...dtm };
      } else {
        newDtm = { ...window.persistDtm, ...dtm };
      }
      window.dtm = removeFalsyValues(newDtm);
      const {
        deviceType,
        site,
        loginStatus,
        customerID,
        email,
        mobile,
        mobileNumber,
        userType,
        lastPurchaseDate,
        platform,
      } = newDtm;
      window.persistDtm = removeFalsyValues({
        deviceType,
        site,
        loginStatus,
        customerID,
        email,
        mobile,
        mobileNumber,
        userType,
        lastPurchaseDate,
        platform,
      });
      window.dataLayer.push(newDtm);
    } else {
      setTimeout(() => {
        setWindowDtm(dtm, mergeWindowDtm);
      }, 2010);
    }
  }
}

export function getDTMCategory(path) {
  let dtmCMSCategory = '';
  if (path?.indexOf('premium-eyeglasses') > -1) {
    dtmCMSCategory = 'premium-eyeglasses';
  } else if (path?.indexOf('premium-sunglasses') > -1) {
    dtmCMSCategory = 'premium-sunglasses';
  } else if (path?.indexOf('eyeglasses.html') > -1) {
    dtmCMSCategory = 'eyeglasses';
  } else if (path?.indexOf('sunglasses.html') > -1) {
    dtmCMSCategory = 'sunglasses';
  } else if (path?.indexOf('contact-lenses.html') > -1) {
    dtmCMSCategory = 'contact-lenses';
  }
  return dtmCMSCategory;
}
