import React from 'react';

import './DescriptionPolicy.scss';

export const DescriptionPolicy = () => {
  const link = (
    <a className="description-policy__link" href="https://www.owndays.com/sg/en/privacy">
      https://www.owndays.com/sg/en/privacy
    </a>
  );
  return (
    <section className="description-policy__container">
      <div className="description-policy__header">
        <h1 className="description-policy__header__title">
          Policy on how Personal Details are kept
        </h1>
        <h2 className="description-policy__header__subtitle">Please read through and confirm</h2>
      </div>

      <p className="description-policy__message">
        We use your or your child/ward’s personal information in compliance with the Personal Data
        Protection Act and our Privacy Policy. Our Privacy Policy can be found on our website (
        {link}). Our primary purposes in collecting personal information is, without limitation, to
        provide you or your child/ward with a smooth and efficient service which includes
        after-sales service, resolving issues related to your or your child/ward’s purchase and
        responding to your or your child/ward’s feedback, and one or more of the other purposes as
        stated in our Privacy Policy. We may also use your or your child/ward’s information for
        digital advertising and marketing purposes, including sending you or your child/ ward
        information relating to our services, promotions, offers through text messages, emails and
        other digital media. We may also disclose your or your child/ward’s personal information to
        third party vendors engaged to perform processes required for the administration of your or
        your child/ ward’s account, for data analytics or for the aforementioned advertising and
        marketing purposes, where applicable. We will protect the information you or your child/ward
        have /has provided us against any unauthorised access or use to the best of our ability.
      </p>

      <h4 className="description-policy__title">Acknowledgement</h4>

      <p className="description-policy__message">
        I hereby consent to the collection, use and disclosure of my personal information by OWNDAYS
        Singapore Pte. Ltd. (OWNDAYS) for the intended purposes mentioned above. / I hereby consent
        to the collection, use and disclosure of my child/ward’s personal information by OWNDAYS
        Singapore Pte.Ltd. (OWNDAYS) for the intended purposes mentioned above.*
      </p>

      <p className="description-policy__message">
        My/my child/ward’s personal information may/will be disclosed by OWNDAYS to its third party
        service providers or agent, which may be situated outside of Singapore, for one or more the
        intended purposes mentioned above, as such third party service providers, if engaged by
        OWNDAYS, would be processing my/my child/ward’s personal data for OWNDAYS for one or more of
        the intended purposes mentioned above.
      </p>

      <p className="description-policy__message">
        By clicking Agree, I represent and warrant that I have read and understood all of the above
        provisions, including the Privacy Policy. 
      </p>

      <p className="description-policy__message">
        *Customers who are below the age of 13 years old have to ask a parent or legal guardian to
        give consent to the collection, use and disclosure of their personal information on their
        behalf.
      </p>
    </section>
  );
};
