import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import apiClient from '../helpers/apiClient';
import * as actionTypes from '../actionTypes/DTM';

let _client = null;
function* getLatestOrder() {
  const url = config.apiPath.latestOrder;
  const successAction = { type: actionTypes.SET_LP_DATE };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => _client.get(url));
    let lastPurchaseDate = null;
    if (data.result) {
      const latestOrder = data.result;
      if (latestOrder.createdAt) {
        const lPDO = new Date(latestOrder.createdAt);
        lastPurchaseDate = `${lPDO.getFullYear()}-${lPDO.getMonth() + 1}-${lPDO.getDate()}`;
      }
    }
    successAction.lastPurchaseDate = lastPurchaseDate;
    yield put(successAction);
  } catch (err) {
    successAction.lastPurchaseDate = null;
    successAction.error = err;
    yield put(successAction);
  }
}

export function* runDTMCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.GET_LP_DATE, getLatestOrder)]);
}
