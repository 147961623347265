import React, { useState, useEffect } from 'react';
import MainNav from '../../../../desktop/components/MainNav/MainNav';
import './SecondaryHeader.scss';
import DiscountBanner from '../../../../CommonComponents/DiscountBanner/DiscountBanner';

const SecondaryHeader = props => {
  const [bannerHeight, setBannerHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const headerClass = 'header--fixed';

  useEffect(() => {
    const bannerElement = document.querySelector('.discount-banner');
    if (bannerElement) {
      const bannerHeight = bannerElement.offsetHeight;
      setBannerHeight(bannerHeight);
    }
  }, [bannerHeight]);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <DiscountBanner />
      <header
        className={headerClass}
        id="header"
        style={{ top: scrollPosition < bannerHeight / 2 ? bannerHeight : 0 }}
      >
        <MainNav {...props} />
      </header>
    </>
  );
};

export default SecondaryHeader;
