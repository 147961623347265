import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.scss';

const Navigation = ({ currentPath, size }) => {
  const navs = [
    {
      path: '/services',
      label: 'Services',
    },
    {
      path: '/services/price',
      label: 'Retail Price',
    },
    {
      path: '/services/lens',
      label: 'Lens',
    },
    {
      path: '/services/lens-replacement',
      label: 'Lens Replacement',
    },
    {
      path: '/services/warranty',
      label: 'Customer Guarantees',
    },
    {
      path: '/services/staff',
      label: 'Staff',
    },
  ];

  return (
    <nav className={`servicers__nav servicers__nav--${size}`}>
      <ul className="servicers__nav-list">
        {navs.map((nav, index) => (
          <li key={index} className="servicers__nav-item">
            <Link
              className={`servicers__nav-link ${currentPath === nav.path ? 'active' : ''}`}
              to={nav.path}
            >
              {nav.label}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
