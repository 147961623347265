import React from 'react';

const BlankLoader = props => {
  const { style, imageStyle } = props;
  return (
    <div
      className="col-md-12 col-sm-12 no-padding"
      data-testid="blankLoader"
      style={
        style || { textAlign: 'center', clear: 'both', minHeight: '500px', marginTop: '-100px' }
      }
    >
      <img
        alt="loader"
        src="//static.lenskart.com/media/owndays/mobile/img/od-loaders/loader-no-bg.gif"
        style={typeof imageStyle !== 'undefined' ? imageStyle : { marginTop: '150px' }}
        title="loader"
      />
    </div>
  );
};

export default BlankLoader;
