import axios from 'axios';

const getErrorMessage = status => {
  switch (status) {
    case 400:
      return 'Input parameter is invalid';

    case 500:
    default:
      return 'Unexpected error';
  }
};

export const updateCustomerByCustomerNo = async (customerNo, updateData) => {
  const params = new URLSearchParams(window.location.search);
  const customerToken = params.get('customerToken');

  if (!customerNo) {
    return;
  }

  try {
    const updateResponse = await axios.put(`/sg/en/api/customers/${customerNo}`, {
      ...updateData,
      karteEmail: updateData.karteEmail || null,
      customerToken,
    });

    if (updateResponse.status === 200) {
      window.location.href = '/sg/en/pdpa/completed';
    }
  } catch (error) {
    console.error('Error updating customer data:', error);

    const errorMessage = getErrorMessage(error?.status);

    alert(errorMessage);
  }
};

export const checkCustomersByToken = async setCustomerData => {
  const params = new URLSearchParams(window.location.search);
  const customerToken = params.get('customerToken');

  if (!customerToken) {
    console.error('Error customerToken not found');
    return;
  }

  try {
    const response = await axios.post(`/sg/en/api/customers/token/check`, {
      token: customerToken,
    });

    setCustomerData(response.data);
  } catch (error) {
    console.error('Error fetching customer data:', error);
  }
};
