/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
// import "core-js/stable";
// import "regenerator-runtime/runtime";
// import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'react-router-redux';
import { renderRoutes } from 'react-router-config';
import { trigger } from 'redial';
import { createBrowserHistory } from 'history';
import Loadable from 'react-loadable';
import { AppContainer as HotEnabler } from 'react-hot-loader';
import createStore from 'redux/create';
import apiClient from 'helpers/apiClient';
import { routes } from './routes/desktop-routes';
import asyncMatchRoutes from 'utils/asyncMatchRoutes';
import ReduxAsyncConnect from 'ReduxAsyncConnect/ReduxAsyncConnect';
import { Provider } from 'react-redux';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import rootSaga from './sagas';
// import { langCodeAllowed } from './locales';

const dest = document.getElementById('content');

const client = apiClient();
const providers = {
  client,
};

(async () => {
  // const countryCode = process.env.COUNTRY?.toLowerCase();
  // if (process.env.NODE_ENV === 'production' && langCodeAllowed?.[countryCode]?.showSentry ) {
  //   Sentry.init({
  //     dsn: "https://e7938fd271b24a5e98409569e886ba51@o344121.ingest.sentry.io/1892178",
  //     integrations: [new BrowserTracing()],
  //     tracesSampleRate: 1.0,
  //   });
  // }

  const history = createBrowserHistory({
    basename: '/sg/en',
  });
  const store = createStore({
    history,
    data: {
      ...window.__data,
    },
    helpers: providers,
  });

  store.runSaga(rootSaga);

  const hydrate = async _routes => {
    // sagas.done.then(() => { });
    const { components, match, params } = await asyncMatchRoutes(
      _routes,
      history.location.pathname
    );
    const triggerLocals = {
      ...providers,
      store,
      match,
      params,
      history,
      location: history.location,
    };
    if (window.__data) {
      delete window.__data;
    } else {
      await trigger('fetch', components, triggerLocals);
    }
    await trigger('defer', components, triggerLocals);

    ReactDOM.hydrate(
      <HotEnabler>
        <Provider store={store} {...providers}>
          <ConnectedRouter history={history} store={store}>
            <ReduxAsyncConnect helpers={providers} routes={_routes} store={store}>
              {renderRoutes(_routes)}
            </ReduxAsyncConnect>
          </ConnectedRouter>
        </Provider>
      </HotEnabler>,
      dest
    );
  };

  await Loadable.preloadReady();

  await hydrate(routes);

  // Hot reload
  if (module.hot) {
    module.hot.accept('./routes/desktop-routes', () => {
      const nextRoutes = require('./routes/desktop-routes');
      hydrate(nextRoutes).catch(err => {
        console.error('Error on routes reload:', err);
      });
    });
  }

  // Server-side rendering check
  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger

    if (
      !dest ||
      !dest.firstChild ||
      !dest.firstChild.attributes ||
      !dest.firstChild.attributes['data-reactroot']
    ) {
      console.error(
        'Server-side React render was discarded.\n' +
          'Make sure that your initial render does not contain any client-side code.'
      );
    }
  }

  // Dev tools
  if (__DEVTOOLS__ && !window.devToolsExtension) {
    const devToolsDest = document.createElement('div');
    window.document.body?.insertBefore(devToolsDest, null);
    const DevTools = require('./DevTools/DevTools');
    ReactDOM.hydrate(
      <Provider store={store}>
        <DevTools />
      </Provider>,
      devToolsDest
    );
  }

  // Service worker
  if (!__DEVELOPMENT__) {
    try {
      // console.log('Service Worker functionality removed.');
    } catch (error) {
      console.log('Error in execution: ', error);
    }
  }
})();
