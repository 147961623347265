import React from 'react';
import './WriteYourMessage.scss';
import { useSelector } from 'react-redux';

const writeMessageTextMaxLength = 260;

const WriteYourMessage = ({ handleWriteMessageText, writeMessageText, device }) => {
  const dataLocale = useSelector(state => state.locale);
  const { WRITE_YOUR_MESSAGE_HERE } = dataLocale;

  const mainClass = `od-write-message--${device}`;

  return (
    <div className={`od-write-message ${mainClass}`}>
      <textarea
        className={`${mainClass}__textarea od-font-reg`}
        maxLength={writeMessageTextMaxLength}
        placeholder={`${WRITE_YOUR_MESSAGE_HERE}...`}
        rows={5}
        type="text"
        value={writeMessageText}
        onChange={handleWriteMessageText}
      />
    </div>
  );
};

export default WriteYourMessage;
