import * as actionTypes from './../../actionTypes/DTM';
import { setWindowDtm } from '../../utils/DTM';

let device = '';
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
device = 'msite';
('#endif'); // eslint-disable-line
const initialState = {
  deviceType: device === 'msite' ? 'msite' : 'desktop',
  site: 'new react',
  loginStatus: 'guest',
  customerID: 0,
  email: null,
  mobileNumber: null,
  mobile: null,
  lastPurchaseDate: null,
  userType: null,
  platform: device === 'msite' ? 'msite' : 'desktop', // android or ios in case of webview
};

export default function reducer(prevState = initialState, action) {
  let dtm;
  switch (action.type) {
    case actionTypes.SET_DTM_USERINFO:
      const mobile = action?.data?.result?.telephone || '';
      dtm = {
        ...prevState,
        loginStatus: 'registered',
        customerID: action.data.result.id,
        email: action.data.result.email || '',
        mobileNumber:
          typeof window !== 'undefined' && typeof window.btoa !== 'undefined'
            ? window.btoa(mobile)
            : '',
        mobile:
          typeof window !== 'undefined' && typeof window.btoa !== 'undefined'
            ? window.btoa(mobile)
            : '',
        userType: action.data.result.hasPlacedOrder ? 'old' : 'new',
      };
      setWindowDtm(dtm, true);
      return dtm;
    case actionTypes.RESET_DTM_USERINFO:
      dtm = {
        ...prevState,
        loginStatus: 'guest',
        customerID: 0,
        email: null,
        mobileNumber: null,
        mobile: null,
        userType: null,
        lastPurchaseDate: null,
      };
      setWindowDtm(dtm, true);
      return dtm;
    case actionTypes.SET_LP_DATE:
      dtm = {
        ...prevState,
        lastPurchaseDate: action.lastPurchaseDate,
      };
      setWindowDtm(dtm, true);
      return dtm;
    case actionTypes.SET_PLATFORM:
      dtm = {
        ...prevState,
        platform: action.platform,
      };
      setWindowDtm(dtm, true);
      return dtm;
    default:
      return prevState;
  }
}
