import React, { useEffect, useState } from 'react';
import './MembershipStatus.scss';
import ButtonOwnDays from '../../../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';

const statuses = {
  bronze: 'bronze',
  silver: 'silver',
  gold: 'gold',
};

const MembershipStatus = ({ status, title, points, percentsPoints }) => {
  const [statusColor, setStatusColor] = useState('');
  const statusSilverOrGold = statusColor === statuses.silver || statusColor === statuses.gold;

  useEffect(() => {
    if (status === statuses.bronze) setStatusColor('bronze');
    if (status === statuses.silver) setStatusColor('silver');
    if (status === statuses.gold) setStatusColor('gold');
  }, [status]);

  return (
    <div className={`od-membership-status block-color-${statusColor}`}>
      <div
        className={`od-membership-status__title od-font-bold od-membership-status-${statusColor}`}
      >
        {title}
      </div>
      <div className="od-membership-status__points od-font-bold">{points}</div>
      <div className="od-membership-status__btns">
        <ButtonOwnDays
          fontClass="od-font-reg"
          fontSize="16px"
          mode="white-secondary"
          padding="10px 10px"
          style={{ minHeight: '60px' }}
          text={`${percentsPoints}% points rebate`}
          width="100%"
        />
        {statusColor === statuses.bronze && (
          <ButtonOwnDays
            fontClass="od-font-reg"
            fontSize="16px"
            mode="white-secondary"
            padding="10px 10px"
            style={{ minHeight: '60px' }}
            text="Member Sale"
            width="100%"
          />
        )}
        {statusSilverOrGold && (
          <ButtonOwnDays
            fontClass="od-font-reg"
            fontSize="16px"
            mode="white-secondary"
            padding="10px 10px"
            style={{ minHeight: '60px' }}
            text="Early Access to Member Sale"
            width="100%"
          />
        )}
        {statusSilverOrGold && (
          <ButtonOwnDays
            fontClass="od-font-reg"
            fontSize="16px"
            mode="white-secondary"
            padding="10px 10px"
            style={{ minHeight: '60px' }}
            text="Early Access to Exclusive Product Launches"
            width="100%"
          />
        )}
      </div>
    </div>
  );
};

export default MembershipStatus;
