import * as actionTypes from '../actionTypes/product';

export function checkStoreAvailability(productName, nationNo, productId, offset, limit) {
  return {
    type: actionTypes.CHECK_STORE_AVAILABILITY_LOAD,
    productName,
    nationNo,
    productId,
    offset,
    limit,
  };
}

export function getProductData(pID, params) {
  return { type: actionTypes.PRODUCT_DATA_LOAD, pID, params };
}

export function getSimilarProducts(catID, paramName, paramValue, params) {
  return { type: actionTypes.SIMILAR_PRODUCTS_LOAD, catID, paramName, paramValue, params };
}

export function getColoredLensData(pID, params) {
  return { type: actionTypes.COLORED_LENS_DATA_LOAD, pID, params };
}

export function sendDittoImageEmail(data) {
  return { type: actionTypes.DITTO_IMG_EMAIL_SEND, data };
}

export function loadContactLens(id, powerOption) {
  return { type: actionTypes.CONTACT_LENS_LOAD, id, powerOption };
}

export function handleClDropdownOpenList(data) {
  return { type: actionTypes.CL_DROPDOWN_OPEN, data };
}

export function getCLSavedPrescription() {
  return { type: actionTypes.CL_SAVED_PRESCRIPTION_LOAD };
}

export function isContactPrescValid(powerData, productID) {
  return { type: actionTypes.CL_PRESCRIPTION_VALID_TEST, powerData, productID };
}

export function updateFrontImg(imgUrl) {
  return { type: actionTypes.FRONT_IMG, imgUrl };
}

export function saveProductData(data, path = null) {
  return { type: actionTypes.GOTO_PDP_ID, data, path };
}

export function getExpressTime(productId, pincode, countryCode) {
  return { type: actionTypes.EXPRESS_TIME_LOAD, productId, pincode, countryCode };
}

export function resetPincodeForm() {
  return { type: actionTypes.RESET_PINCODE_FORM };
}

export function oosSubscription(data) {
  return { type: actionTypes.OOS_SUBSCRIPTION, data };
}

export function lensOnlyLocation(pincode) {
  return { type: actionTypes.LENS_LOCATION_AVAILABILITY_CHECK, pincode };
}

export function getLensPackages(prodId, powerType, frameType, isExpress, packages) {
  return {
    type: actionTypes.LENS_PACKAGES_LOAD,
    prodId,
    powerType,
    frameType,
    isExpress,
    packages,
  };
}

export function flushProductData() {
  return { type: actionTypes.FLUSH_PRODUCT_DATA };
}

export function changeTabIndex(value) {
  return { type: actionTypes.CHANGE_PRODUCTTABINDEX, value };
}

export function resetProductValue(args) {
  return { type: actionTypes.RESET_PRODUCT_VALUE, args };
}

export function setPowerType(value) {
  return { type: actionTypes.SET_POWER_TYPE, value };
}

export function getCLSubscription(productId, isBothEye) {
  return { type: actionTypes.GET_CLSUBSCRIPTION, productId, isBothEye };
}

export function getSubscriptionDiscounts(productId, subscriptionType) {
  return { type: actionTypes.GET_SUBSCRIPTION_DISCOUNT, productId, subscriptionType };
}

export function getCLSolutions(productId) {
  return { type: actionTypes.GET_CLSOLUTIONS, productId };
}

export function getDeliveryDetails(productId, pincode, countryCode) {
  return { type: actionTypes.GET_DELIVERY_DETAILS_LOAD, productId, pincode, countryCode };
}

export function setAppPrice(price, discount, appCTAText) {
  return { type: actionTypes.SET_APP_PRICE, price, discount, appCTAText };
}

export function postReview(id, data) {
  return { type: actionTypes.REVIEW_PRODUCT, id, data };
}

// for Exchange
export function updateExchangeRemaining(amount) {
  return { type: actionTypes.SET_EXCHANGE_BALANCE, amount };
}

export function getPackageOrCoatingGroup(packageId, coatingId) {
  return { type: actionTypes.GET_PACKAGE_OR_COATING_GROUP_LOAD, packageId, coatingId };
}

export function dittoImgEmailReset() {
  return { type: actionTypes.DITTO_IMG_EMAIL_RESET };
}

export function get360viewStatus(pid) {
  return { type: actionTypes.GET_THREE_SIXTY_VIEW_LOAD, pid };
}
export function toggleLensesSelectionPopup(isLensesSelectionPopupOpen) {
  return { type: actionTypes.TOGGLE_LENSES_SELECTION_POPUP, isLensesSelectionPopupOpen };
}
