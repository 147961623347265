import React from 'react';
import HarryPotterBase from '../../../CommonComponents/SpecialPages/HarryPotter/HarryPotterBase';
import Breadcrumb from '../../../../desktop/components/Common/Breadcrumb';
import './HarryPotterPC.scss';

const HarryPotterPC = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbData={[
          {
            label: 'Homepage',
            link: 'www.lenskart.com',
          },
          {
            label: 'News',
            link: 'sg/en/news',
          },
          {
            label: 'Harry Potter × OWNDAYS',
          },
        ]}
        dataLocale={{
          SYMBOL_GREATER: '>',
        }}
      />
      <HarryPotterBase className="l-hp" />
    </>
  );
};

export default HarryPotterPC;
