import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../src/desktop/components/Common/BlankLoader.js';

const MyAccountLoadable = Loadable({
  loader: () =>
    import('../../../../src/own-days/views/desktop/MyAccountOD/MyAccountOD').then(
      module => module.default
    ),
  loading: () => <Loader />,
});

export default MyAccountLoadable;
