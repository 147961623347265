// Check whether Order Status on Whatsapp is already opted by customer or not
export const CHECK_CURRENT_WHATSAPP_OPTIN_STATUS =
  'gupshupWhatsapp/CHECK_CURRENT_WHATSAPP_OPTIN_STATUS';
export const CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_SUCCESS =
  'gupshupWhatsapp/CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_SUCCESS';
export const CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_FAIL =
  'gupshupWhatsapp/CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_FAIL';

// Set Order Status on Whatsapp Opt-in/Opt-out at Success Page
export const SET_WHATSAPP_OPTIN_STATUS = 'gupshupWhatsapp/SET_WHATSAPP_OPTIN_STATUS';
export const SET_WHATSAPP_OPTIN_STATUS_SUCCESS =
  'gupshupWhatsapp/SET_WHATSAPP_OPTIN_STATUS_SUCCESS';
export const SET_WHATSAPP_OPTIN_STATUS_FAIL = 'gupshupWhatsapp/SET_WHATSAPP_OPTIN_STATUS_FAIL';
