import React, { Fragment } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderOwndays1For1ContactLensDeal.scss';

export const HeaderOwndays1For1ContactLensDeal = ({ baseImgUrl }) => {
  const breadCrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: '1-for-1 Contact Lens Offer' },
  ];

  return (
    <Fragment>
      <div className="header-owndays-1-for-1-contact-lens-deal__custom-breadcrumbs">
        <Breadcrumbs crumbs={breadCrumbs} />
      </div>

      <section className="header-owndays-1-for-1-contact-lens-deal base-owndays-1-for-1-contact-lens-deal__container">
        <h1 className="header-owndays-1-for-1-contact-lens-deal__title">
          1-for-1 Contact Lens Offer
        </h1>
        <img
          alt="1-for-1 Contact Lens Offer"
          className="header-owndays-1-for-1-contact-lens-deal__banner"
          src={`${baseImgUrl}/thumbnail.webp`}
        />
      </section>
    </Fragment>
  );
};
