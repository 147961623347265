import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BUNDLE_SALE_CONTENT, BUNDLE_SALE_HIGHLIGHTS } from './BundleSaleConstant';
import './BundleSale.scss';
import TermsModal from './BundleSaleTerms';

const BundleSale = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTerms, setCurrentTerms] = useState(null);

  const openModal = terms => {
    setCurrentTerms(terms);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentTerms(null);
  };

  return (
    <main className="bundle-sale">
      <div className="bundle-sale__container">
        <h1 className="bundle-sale__title">{BUNDLE_SALE_CONTENT.title}</h1>
      </div>
      <img
        className="bundle-sale__thumbnail"
        src={BUNDLE_SALE_CONTENT.thumbSrc}
        alt={BUNDLE_SALE_CONTENT.thumbAlt}
      />
      <div className="bundle-sale__container">
        <p
          className="bundle-sale__text"
          dangerouslySetInnerHTML={{ __html: BUNDLE_SALE_CONTENT.duration }}
        />
        <p className="bundle-sale__text">{BUNDLE_SALE_CONTENT.description}</p>
        <h2 className="bundle-sale__subtitle">Highlights</h2>
        <ul className="bundle-sale__highlights">
          {BUNDLE_SALE_HIGHLIGHTS.map((highlight, highlightIndex) => (
            <li key={highlightIndex} className="bundle-sale__highlights-li">
              <img
                className="bundle-sale__highlights-img"
                src={highlight.imgSrc}
                alt={highlight.imgAlt}
              />
              <div className="bundle-sale__highlights-details">
                <h3>{highlight.title}</h3>
                <h4>Participating Products:</h4>
                {highlight.products && (
                  <ul>
                    {highlight.products.map((product, productIndex) => (
                      <li key={productIndex}>
                        <strong>
                          <Link to={product.path}>{product.title}</Link>
                        </strong>
                        {product.price}
                      </li>
                    ))}
                  </ul>
                )}
                <button
                  className="bundle-sale__highlights-terms"
                  onClick={() => openModal(highlight.terms)}
                >
                  Terms & Conditions
                  <img
                    src="https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/icon-external-link.svg"
                    alt="Icon external link"
                  />
                </button>
              </div>
            </li>
          ))}
        </ul>
        <TermsModal isOpen={isModalOpen} onClose={closeModal} terms={currentTerms} />
      </div>
    </main>
  );
};

export default BundleSale;
