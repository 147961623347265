import React from 'react';
import './MembershipDiscount.scss';

import OdCheckboxCheckedIcon from '../../../../static/assets/svg/od-checkbox-checked-icon.svg';

import { useSelector, useDispatch } from 'react-redux';
import { removeMembershipPoints } from '../../../actionCreators/membershipPoints';
import { getCart } from '../../../actionCreators/cart';

const MembershipDiscount = ({
  setIsApplyMembershipDiscount,
  currencySymbol,
  isMobile,
  cartObj,
}) => {
  const dataLocale = useSelector(state => state.locale);
  const { MEMBERSHIP_DISCOUNT, MEMBERSHIP_POINTS, USE } = dataLocale;

  const membershipData = useSelector(state => state.membershipPoints.data);

  const dispatch = useDispatch();

  const removemembershipPoints = () => {
    dispatch(removeMembershipPoints());
    dispatch(getCart());
    setIsApplyMembershipDiscount(false);
  };

  const path = window.location.pathname;

  const isCartPage = path.includes('/cart');

  return membershipData && cartObj ? (
    <div className="membership-discount__container od-font-bold">
      {isCartPage && (
        <div className="membership-discount__title" onClick={removemembershipPoints}>
          <img alt="checkbox" src={OdCheckboxCheckedIcon} />
          <span>
            {USE} {cartObj?.membershipPoints?.membershipPointsDiscount?.applicableAmount}{' '}
            {MEMBERSHIP_POINTS.toLowerCase()}
          </span>
        </div>
      )}

      <div className="membership-discount__details od-font-reg">
        <span>{MEMBERSHIP_DISCOUNT}</span>
        <span>{`- ${currencySymbol} ${cartObj?.membershipPoints?.membershipPointsDiscount?.appliedAmount}`}</span>
      </div>
    </div>
  ) : null;
};

export default MembershipDiscount;
