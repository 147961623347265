import React from 'react';
import './Packages.scss';
import Collapse from './Collapse';

const Packages = ({ packages, setSelectedPackage, selectedPackage }) => {
  if (packages.length > 0) {
    return (
      <div className="odslv-p">
        {packages.map(pack => (
          <Collapse
            key={pack?.id}
            description={pack?.subtitle}
            icon={pack?.brand_image_url}
            isSelected={selectedPackage?.id === pack.id}
            title={pack?.label}
            onSelect={() => setSelectedPackage(pack)}
          />
        ))}
      </div>
    );
  }

  return <div>No Packages found</div>;
};

export default Packages;
