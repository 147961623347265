import React, { useState, useEffect } from 'react';

import { checkCustomersByToken, updateCustomerByCustomerNo } from '../../../servicesOwndaysNet';

import './FormPolicyWithEmail.scss';

const OPTIONS = [
  { name: 'Email', isChecked: true, key: 'email', inputType: 'checkbox' },
  { name: '', isChecked: false, key: 'input-email', inputType: 'email' },
  { name: 'Text Message', isChecked: true, key: 'sms', inputType: 'checkbox' },
];

export const FormPolicyWithEmail = () => {
  const [listReceiveNews, setListReceiveNews] = useState(OPTIONS);
  const [email, setEmail] = useState('');
  const [customerData, setCustomerData] = useState(null);
  const [isAgree, setIsAgree] = useState(false);

  const toggleCheckboxChange = selectedItem => {
    setListReceiveNews(prevList => {
      const updatedList = prevList.map(item =>
        item.key === selectedItem.key ? { ...item, isChecked: !item.isChecked } : item
      );

      const emailItem = updatedList.find(item => item.key === 'email');

      if (emailItem?.isChecked) {
        if (!updatedList.some(item => item.key === 'input-email')) {
          const inputEmailItem = {
            name: '',
            isChecked: false,
            key: 'input-email',
            inputType: 'email',
          };
          updatedList.splice(1, 0, inputEmailItem);
        }
      } else {
        return updatedList.filter(item => item.key !== 'input-email');
      }

      return updatedList;
    });
  };

  const handleRadioChange = () => {
    setIsAgree(prevState => !prevState);
  };

  const handleChangeText = e => {
    setEmail(e.target.value);
  };

  const handleSubmitWithEmail = e => {
    if (!customerData) {
      alert('Unexpected error');
      return;
    }

    e.preventDefault();

    const {
      customerNo,
      storeCode,
      name,
      firstName,
      lastName,
      kana,
      firstKana,
      lastKana,
      telNo,
      birthDay,
      gender,
      isAgreedToSendWhatsApp,
      isAgreedToCall,
      hasInternalMemo,
      karteEmail,
    } = customerData;

    const updateData = {
      storeCode,
      name,
      firstName,
      lastName,
      kana,
      firstKana,
      lastKana,
      telNo,
      birthDay,
      gender,
      karteEmail: email || karteEmail,
      isAgreedToSendWhatsApp,
      isAgreedToCall,
      hasInternalMemo,
      isAgreedToSendMail: listReceiveNews.find(item => item.key === 'email').isChecked,
      isAgreedToSendSMS: listReceiveNews.find(item => item.key === 'sms').isChecked,
      isAgreedToPrivacyPolicy: isAgree,
    };

    updateCustomerByCustomerNo(customerNo, updateData);
  };

  useEffect(() => {
    checkCustomersByToken(setCustomerData);
  }, []);

  return (
    <form
      className="form-policy-with-email__container"
      id="policyWithEmailForm"
      onSubmit={handleSubmitWithEmail}
    >
      <div className="form-policy-with-email__box">
        <p className="form-policy-with-email__question">
          I consent that you use my personal information for the purposes mentioned above.
        </p>

        <div className="form-policy-with-email__agree-layout">
          <input
            checked={isAgree}
            className="form-policy-with-email__radio"
            type="radio"
            value="Agree"
            onClick={handleRadioChange}
          />
          <label className="form-policy-with-email__name" htmlFor="Agree">
            Agree
          </label>
        </div>
      </div>

      <div className="form-policy-with-email__box">
        <p className="form-policy-with-email__question">
          How would you like to receive news and special offers from us ?
        </p>

        <ul className="form-policy-with-email__list">
          {listReceiveNews.map(item => (
            <li
              key={`form-policy-with-email-option-${item.name.toLowerCase()}`}
              className={item.key === 'input-email' ? '' : 'form-policy-with-email__item'}
            >
              <input
                checked={item.isChecked}
                className={
                  item.inputType === 'checkbox'
                    ? 'form-policy-with-email__checkbox'
                    : 'form-policy-with-email__input'
                }
                name={item.name}
                placeholder={item.key === 'input-email' ? 'Please enter your email address' : ''}
                required={item.key === 'input-email'}
                type={item.inputType}
                value={item.key === 'input-email' ? email : item.name}
                onChange={e =>
                  item.key === 'input-email' ? handleChangeText(e) : toggleCheckboxChange(item)
                }
              />

              {item.inputType === 'checkbox' && (
                <label className="form-policy-with-email__name" htmlFor={item.name}>
                  {item.name}
                </label>
              )}
            </li>
          ))}
        </ul>
      </div>

      <button
        className={`form-policy-with-email__confirm-button ${
          isAgree
            ? 'form-policy-with-email__confirm-button--active'
            : 'form-policy-with-email__confirm-button--inactive'
        }`}
        disabled={!isAgree}
        form="policyWithEmailForm"
        type="submit"
      >
        Update My Preference
      </button>
    </form>
  );
};
