import React from 'react';
import Huawei2Base from '../../../CommonComponents/Huawei2/Huawei2Base';
import Breadcrumb from '../../../../desktop/components/Common/Breadcrumb';
import './Huawei2PC.scss';

const Huawei2PC = () => {
  return (
    <>
      <Breadcrumb
        breadcrumbData={[
          {
            label: 'Homepage',
            link: 'www.lenskart.com',
          },
          {
            label: 'News',
            link: 'sg/en/news',
          },
          {
            label: 'OWNDAYS × HUAWEI Eyewear 2',
          },
        ]}
        dataLocale={{
          SYMBOL_GREATER: '>',
        }}
      />
      <Huawei2Base className="l-huawei2" />
    </>
  );
};

export default Huawei2PC;
