import React, { useEffect } from 'react';
import AOS from 'aos';

import {
  HeaderOwndaysPlus,
  BodyOwndaysPlus,
  FooterOwndaysPlus,
} from '../CommonComponents/OwndaysPlus';

import '../CommonComponents/OwndaysPlus/BaseOwndaysPlus.scss';

const OwndaysPlusHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-plus';

  useEffect(() => {
    // Initialize AOS
    AOS.init({ once: true, duration: 1000 });

    // Load Typekit fonts
    (function (d) {
      const config = {
        kitId: 'sdi8fhd',
        scriptTimeout: 3000,
        async: true,
      };
      const h = d.documentElement;
      const t = setTimeout(function () {
        h.className = h.className.replace(/\bwf-loading\b/g, '') + ' wf-inactive';
      }, config.scriptTimeout);
      const tk = d.createElement('script');
      let f = false;
      const s = d.getElementsByTagName('script')[0];
      let a;
      h.className += ' wf-loading';
      tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
      tk.async = true;
      tk.onload = tk.onreadystatechange = function () {
        a = this.readyState;
        if (f || (a && a !== 'complete' && a !== 'loaded')) return;
        f = true;
        clearTimeout(t);
        try {
          Typekit.load(config);
        } catch (e) {}
      };
      s.parentNode.insertBefore(tk, s);
    })(document);
  }, []);

  return (
    <div className="base-owndays-plus">
      <HeaderOwndaysPlus baseImgUrl={baseImgUrl} />
      <BodyOwndaysPlus baseImgUrl={baseImgUrl} />
      <FooterOwndaysPlus baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default OwndaysPlusHTML;
