import React from 'react';

export const Breadcrumbs = () => {
  return (
    <div className="od-c-breadcrumb">
      <ul>
        <li>
          <a href="/sg/en">Homepage</a>
        </li>

        <li>
          <a href="/sg/en/information">See all the Information</a>
        </li>

        <li>
          <a href="#">Announcement on Product Line Changes</a>
        </li>
      </ul>
    </div>
  );
};
