import React, { useEffect, useState } from 'react';
import './YouTubeModal.scss';

const YouTubeModal = ({ videoId, isOpen, onClose }) => {
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    if (isOpen) {
      setVideoUrl(`https://www.youtube.com/embed/${videoId}?autoplay=1`);
    } else {
      setVideoUrl('');
    }
  }, [isOpen, videoId]);

  if (!isOpen) return null;

  return (
    <div className="yt-modal">
      <div className="yt-modal__overlay" onClick={onClose}>
        <button onClick={onClose} className="yt-modal__close">
          &#10005;
        </button>
        <div className="yt-modal__content">
          <iframe
            src={videoUrl}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="yt-modal__iframe"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default YouTubeModal;
