import React, { useEffect } from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">
    <head>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
        <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>
        <script>
            if (window.location.href.indexOf('jp.owndays.com') != -1) {
                window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
            }
        </script>
        <!-- Google Tag Manager -->
        <script>
            (function(w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0]
                  , j = d.createElement(s)
                  , dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            }
            )(window, document, 'script', 'dataLayer', 'GTM-5WRT43B');
        </script>
        <!-- End Google Tag Manager -->
        <!-- Rtoaster -->
        <!-- /Rtoaster -->
        <!-- CleverTap -->
        <!-- /CleverTap -->
        <script>
            /*TAIWAN 20150119
     window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
     d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
     _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute('charset','utf-8');
     $.src='//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';z.t=+new Date;$.
     type='text/javascript';e.parentNode.insertBefore($,e)})(document,'script');
     */
            window.$zopim || (function(d, s) {
                var z = $zopim = function(c) {
                    z._.push(c)
                }
                  , $ = z.s = d.createElement(s)
                  , e = d.getElementsByTagName(s)[0];
                z.set = function(o) {
                    z.set._.push(o)
                }
                ;
                z._ = [];
                z.set._ = [];
                $.async = !0;
                $.setAttribute('charset', 'utf-8');
                $.src = '//v2.zopim.com/?2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS';
                z.t = +new Date;
                $.type = 'text/javascript';
                e.parentNode.insertBefore($, e)
            }
            )(document, 'script');
        </script>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
        <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
        <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
        <!-- CSRF Token -->
        <meta name="csrf-token" content="rqMtz1YsPMa51zwCnkZgFHEME92kH2ipF6WYR8qI">
        <meta property="og:locale" content="en_SG"/>
        <meta name="locale-country" content="sg"/>
        <meta name="locale-language" content="en"/>
        <meta name="og:title" content="10th Anniversary Collection | OWNDAYS ONLINE STORE - OPTICAL SHOP"/>
        <meta name="description" content="A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear."/>
        <meta name="keywords" content="10thAnniversaryCollection,Singapore,since0713,glasses,sunglasses,lenses,eyewear,lineup,OWNDAYS"/>
        <meta name="og:description" content="A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear."/>
        <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
        <meta property="og:title" content="10th Anniversary Collection | OWNDAYS ONLINE STORE - OPTICAL SHOP"/>
        <meta property="og:description" content="A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear."/>
        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/10th-anniversary-collection">
        <meta property="og:image" content="https://www.owndays.com/images/specials/products/10th-anniversary-collection/ogp_1200x630.jpg?1688355295">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
        <link rel="preconnect" href="https://ka-f.fontawesome.com">
        <link rel="preconnect" href="https://use.typekit.net">
        <link rel="preconnect" href="https://cdn.jsdelivr.net">
        <!-- Fonts -->
        <link rel="dns-prefetch" href="https://fonts.gstatic.com">
        <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
        <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
        <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
        <!-- Images -->
        <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
        <!-- Styles -->
        <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=660b37960541a180cc0c?20191018" as="style">
        <link href="https://www.owndays.com/web/css/app.css?id=660b37960541a180cc0c?20191018" rel="stylesheet">
        <link rel="preload" href="/fonts/Tahoma/Tahoma-Regular.woff2" as="font" type="font/woff2" crossorigin>
        <link rel="preload" href="/fonts/Miller-Banner/Miller-Banner.woff2" as="font" type="font/woff2" crossorigin>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/video.js/7.21.0/video-js.min.css">
        <link rel="preload" href="https://www.owndays.com/web/css/specials-10th-anniversary-collection.css?v=1707083913" as="style">
        <link href="https://www.owndays.com/web/css/specials-10th-anniversary-collection.css?v=1707083913" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css?v=1" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css?v=1" rel="stylesheet">
        <!-- fontawesome 5.x -->
        <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
        <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
        <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
        <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
        <title id="title">10th Anniversary Collection | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
    </head>
    <!-- If open with LINE add class ".is-line-mini" to body tag -->
    <body id="main-body" style="display: block" class="is-sale-time">
        <div id="app" data-nosnippet>
            <main class="l-content
        
        
        
        
        
        ">
                <div class="od-c-breadcrumb">
                    <ul id="breadcrumb-list">
                        <li>
                            <a href="/sg/en">Homepage</a>
                        </li>
                        <li>
                            <a href="/sg/en/news">News</a>
                        </li>
                        <li>10th Anniversary Collection                    </li>
                    </ul>
                </div>
                <section class="l-anniversary">
                    <section class="main">
                        <picture>
                            <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/main-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/main-pc.jpg" type="image/jpg">
                            <source media="(max-width:767px)" srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/main-sp.webp" type="image/webp">
                            <img src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/main-sp.jpg" alt="10th Anniversary Collection">
                        </picture>
                        <div class="main__text">
                            <h1>
                                <strong class="swift-up-text" data-aos="fade">10th Anniversary</strong>
                                <strong class="swift-up-text" data-aos="fade">Collection</strong>
                                <small class="swift-up-text" data-aos="fade">In Singapore Since 07.13</small>
                            </h1>
                        </div>
                    </section>
                    <section class="concept">
                        <div class="l-anniversary__container">
                            <p data-aos="fade-up">A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear. The lineup, consisting of 2 metal frames and 2 plastic frames, embodies our aesthetic interpretation and creative vision to the classic eyewear perfect for Singaporeans – minimalist with a bit of nostalgia to channel the art of quiet confidence.</p>
                            <a data-toggle="modal" href="#jsVideoModal" data-youtube="oLblIgUWD3U">
                                <div class="l-anniversary__frame" data-aos>
                                    <picture>
                                        <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/video-thumbnail.webp" type="image/webp">
                                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/video-thumbnail.png" alt="10th Anniversary Collection">
                                    </picture>
                                </div>
                            </a>
                        </div>
                    </section>
                    <section class="lineup">
                        <div class="l-anniversary__fullpage">
                            <div class="l-anniversary__container">
                                <div class="start-lineup">
                                    <div class="row-title div1">
                                        <h2>Line up</h2>
                                    </div>
                                    <div class="banner-start-color div2">
                                        <div class="text-banner">
                                            <p>
                                                4 designs with 2 colour <br>variations each
                                            </p>
                                        </div>
                                        <div class="price-banner">
                                            <p>S$100</p>
                                        </div>
                                    </div>
                                    <div class="banner-image div3">
                                        <div class="l-anniversary__frame" data-aos>
                                            <img src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/line-up01.webp" alt="10th Anniversary Collection">
                                        </div>
                                    </div>
                                    <div class="l-anniversary__container div4">
                                        <div class="scrolldown-lineup">
                                            <ul>
                                                <li>
                                                    <a href="#plastic">
                                                        <div class="scrolldown-title-txt">Plastic Frame</div>
                                                        <div class="scrolldown-image">
                                                            <img src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/glass02.webp" alt="Plastic Frame">
                                                        </div>
                                                        <div class="scrolldown-arrow">
                                                            <img src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/arrow-down.webp" alt="Plastic Frame">
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#metal">
                                                        <div class="scrolldown-title-txt">Metal Frame</div>
                                                        <div class="scrolldown-image">
                                                            <img src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/glass01.webp" alt="Metal Frame">
                                                        </div>
                                                        <div class="scrolldown-arrow">
                                                            <img src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/arrow-down.webp" alt="Metal Frame">
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="l-anniversary__container"> -->
                        <div class="section-lineup">
                            <div class="section-lineup__row" id="plastic">
                                <div class="l-anniversary__container position-relative">
                                    <div class="section-lineup__row__title">
                                        <h3 class="l-anniversary__heading">
                                            <span data-aos="fade-up" data-aos-delay="300">Plastic Frame</span>
                                        </h3>
                                    </div>
                                    <div class="section-lineup__row__banner">
                                        <div class="l-anniversary__frame" data-aos>
                                            <picture>
                                                <source media="(min-width: 768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/line-up-banner01.webp" type="image/webp">
                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/line-up-banner01-sp.webp" alt="Plastic Frame">
                                            </picture>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-lineup__row__glass">
                                    <div class="section-lineup__row__glass__detail" data-aos="fade">
                                        <div class="l-anniversary__container">
                                            <div class="title-tabs">
                                                <div class="id-sku">
                                                    <h4>SGSP2001B-3S</h4>
                                                </div>
                                                <div class="tabs-bullet">
                                                    <ul>
                                                        <li>
                                                            <a href="javascript:voide(0);" data-id="#SGSP2001B-3S-01" class="circle-tabs-toggle">
                                                                <span class="circle color-clear-grey active"></span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:voide(0);" data-id="#SGSP2001B-3S-02" class="circle-tabs-toggle">
                                                                <span class="circle color-clear-brown"></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="detail-tabs">
                                                <p>Wellington frame in a mixed material construction with the frame front in clear plastic and the temples in titanium. This is a style that is super easy to pull off and will always be pleasing to the eye.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="SGSP2001B-3S-01" class="detail-toggle" style="display:block;">
                                        <div class="l-anniversary__container">
                                            <div class="section-dot">
                                                <picture>
                                                    <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1.webp" type="image/webp">
                                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1.jpg" alt="SGSP2001B-3S">
                                                </picture>
                                                <div class="num-circle-dot">
                                                    <div class="num-circle">
                                                        <div class="num-list active-num">
                                                            <p>01</p>
                                                        </div>
                                                        <div class="num-list">
                                                            <p>02</p>
                                                        </div>
                                                        <div class="num-list">
                                                            <p>03</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="slide-glass" data-aos="fade">
                                                <ul class="slick-glass">
                                                    <li>
                                                        <div class="title-slick u-sp-only">
                                                            <h3>Feature 01</h3>
                                                        </div>
                                                        <div class="image-slick">
                                                            <picture>
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-1-pc.webp" type="image/webp">
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-1-pc.jpg" type="image/jpeg">
                                                                <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-1-sp.webp" type="image/webp">
                                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-1-sp.jpg" alt="SGSP2001B-3S">
                                                            </picture>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="title-slick u-sp-only">
                                                            <h3>Feature 02</h3>
                                                        </div>
                                                        <div class="image-slick">
                                                            <picture>
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-2-pc.webp" type="image/webp">
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-2-pc.jpg" type="image/jpeg">
                                                                <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-2-sp.webp" type="image/webp">
                                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-2-sp.jpg" alt="SGSP2001B-3S">
                                                            </picture>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="title-slick u-sp-only">
                                                            <h3>Feature 03</h3>
                                                        </div>
                                                        <div class="image-slick">
                                                            <picture>
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-3-pc.webp" type="image/webp">
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-3-pc.jpg" type="image/jpeg">
                                                                <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-3-sp.webp" type="image/webp">
                                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c1-point-3-sp.jpg" alt="SGSP2001B-3S">
                                                            </picture>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul class="slick-detail">
                                                    <li>
                                                        <div class="detail-slick">
                                                            <div class="title-slick u-pc-only">
                                                                <h3>Feature 01</h3>
                                                            </div>
                                                            <p>Frame front in clear plastic as a nod to the see-through trend that is highly popular </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="detail-slick">
                                                            <div class="title-slick u-pc-only">
                                                                <h3>Feature 02</h3>
                                                            </div>
                                                            <p>Titanium temples for enhanced durability while allowing the frame to remain lightweight    </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="detail-slick">
                                                            <div class="title-slick u-pc-only">
                                                                <h3>Feature 03</h3>
                                                            </div>
                                                            <p>A unique, serialised number on the temple tip so you finally have a frame to call your own   </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row-purchase l-anniversary__container" data-aos="fade">
                                            <a href="/sg/en/products/SGSP2001B-3S?sku=6997" class="l-anniversary__btn">Purchase Online</a>
                                        </div>
                                    </div>
                                    <div id="SGSP2001B-3S-02" class="detail-toggle">
                                        <div class="l-anniversary__container">
                                            <div class="section-dot">
                                                <picture>
                                                    <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C2.webp" type="image/webp">
                                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C2.jpg" alt="SGSP2001B-3S">
                                                </picture>
                                                <div class="num-circle-dot">
                                                    <div class="num-circle">
                                                        <div class="num-list active-num">
                                                            <p>01</p>
                                                        </div>
                                                        <div class="num-list">
                                                            <p>02</p>
                                                        </div>
                                                        <div class="num-list">
                                                            <p>03</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="slide-glass" data-aos="fade">
                                                <ul class="slick-glass">
                                                    <li>
                                                        <div class="title-slick u-sp-only">
                                                            <h3>Feature 01</h3>
                                                        </div>
                                                        <div class="image-slick">
                                                            <picture>
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-1-pc.webp" type="image/webp">
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-1-pc.jpg" type="image/jpeg">
                                                                <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-1-sp.webp" type="image/webp">
                                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-1-sp.jpg" alt="SGSP2001B-3S">
                                                            </picture>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="title-slick u-sp-only">
                                                            <h3>Feature 02</h3>
                                                        </div>
                                                        <div class="image-slick">
                                                            <picture>
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-2-pc.webp" type="image/webp">
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-2-pc.jpg" type="image/jpeg">
                                                                <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-2-sp.webp" type="image/webp">
                                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-2-sp.jpg" alt="SGSP2001B-3S">
                                                            </picture>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="title-slick u-sp-only">
                                                            <h3>Feature 03</h3>
                                                        </div>
                                                        <div class="image-slick">
                                                            <picture>
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-3-pc.webp" type="image/webp">
                                                                <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-3-pc.jpg" type="image/jpeg">
                                                                <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-3-sp.webp" type="image/webp">
                                                                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic1-c2-point-3-sp.jpg" alt="SGSP2001B-3S">
                                                            </picture>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <ul class="slick-detail">
                                                    <li>
                                                        <div class="detail-slick">
                                                            <div class="title-slick u-pc-only">
                                                                <h3>Feature 01</h3>
                                                            </div>
                                                            <p>Frame front in clear plastic as a nod to the see-through trend that is highly popular</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="detail-slick">
                                                            <div class="title-slick u-pc-only">
                                                                <h3>Feature 02</h3>
                                                            </div>
                                                            <p>Titanium temples for enhanced durability while allowing the frame to remain lightweight</p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div class="detail-slick">
                                                            <div class="title-slick u-pc-only">
                                                                <h3>Feature 03</h3>
                                                            </div>
                                                            <p>A unique, serialised number on the temple tip so you finally have a frame to call your own</p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row-purchase l-anniversary__container" data-aos="fade">
                                            <a href="/sg/en/products/SGSP2001B-3S?sku=6998" class="l-anniversary__btn">Purchase Online</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="section-lineup__row__glass">
                                    <div class="section-lineup__row__glass__detail" data-aos="fade">
                                        <div class="l-anniversary__container">
                                            <div class="title-tabs">
                                                <div class="id-sku">
                                                    <h4>SGSP2002B-3S</h4>
                                                </div>
                                                <div class="tabs-bullet">
                                                    <ul>
                                                        <li>
                                                            <a href="javascript:voide(0);" data-id="#SGSP2002B-3S-01" class="circle-tabs-toggle">
                                                                <span class="circle color-clear-grey active"></span>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="javascript:voide(0);" data-id="#SGSP2002B-3S-02" class="circle-tabs-toggle">
                                                                <span class="circle color-olive-green"></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="detail-tabs">A true presentation of the less-is-more look, featuring a lightweight frame in the classic rectangle style. The usually humble, often-overlooked spectacle hinges have been reworked with a one-of-a-kind design that keeps the overall frame looking sharp and polished.
</p></div></div></div>
<div id="SGSP2002B-3S-01" class="detail-toggle" style="display:block;">
    <div class="l-anniversary__container">
        <div class="section-dot">
            <picture>
                <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_02.webp" type="image/webp">
                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_02.jpg" alt="SGSP2001B-3S">
            </picture>
            <div class="num-circle-dot">
                <div class="num-circle">
                    <div class="num-list active-num">
                        <p>01</p>
                    </div>
                    <div class="num-list">
                        <p>02</p>
                    </div>
                    <div class="num-list">
                        <p>03</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide-glass" data-aos="fade">
            <ul class="slick-glass">
                <li>
                    <div class="title-slick u-sp-only">
                        <h3>Feature 01</h3>
                    </div>
                    <div class="image-slick">
                        <picture>
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-1-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-1-pc.jpg" type="image/jpeg">
                            <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-1-sp.webp" type="image/webp">
                            <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-1-sp.jpg" alt="SGSP2001B-3S">
                        </picture>
                    </div>
                </li>
                <li>
                    <div class="title-slick u-sp-only">
                        <h3>Feature 02</h3>
                    </div>
                    <div class="image-slick">
                        <picture>
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-2-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-2-pc.jpg" type="image/jpeg">
                            <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-2-sp.webp" type="image/webp">
                            <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-2-sp.jpg" alt="SGSP2001B-3S">
                        </picture>
                    </div>
                </li>
                <li>
                    <div class="title-slick u-sp-only">
                        <h3>Feature 03</h3>
                    </div>
                    <div class="image-slick">
                        <picture>
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-3-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-3-pc.jpg" type="image/jpeg">
                            <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-3-sp.webp" type="image/webp">
                            <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c1-point-3-sp.jpg" alt="SGSP2001B-3S">
                        </picture>
                    </div>
                </li>
            </ul>
            <ul class="slick-detail">
                <li>
                    <div class="detail-slick">
                        <div class="title-slick u-pc-only">
                            <h3>Feature 01</h3>
                        </div>
                        <p>Classic rectangle style that is both chic and business-formal</p>
                    </div>
                </li>
                <li>
                    <div class="detail-slick">
                        <div class="title-slick u-pc-only">
                            <h3>Feature 02</h3>
                        </div>
                        <p>Hinges are specially designed to keep the overall frame looking sharp and polished</p>
                    </div>
                </li>
                <li>
                    <div class="detail-slick">
                        <div class="title-slick u-pc-only">
                            <h3>Feature 03</h3>
                        </div>
                        <p>A unique, serialised number on the temple tip so you finally have a frame to call your own   </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row-purchase l-anniversary__container" data-aos="fade">
        <a href="/sg/en/products/SGSP2002B-3S?sku=6999" class="l-anniversary__btn">Purchase Online</a>
    </div>
</div>
<div id="SGSP2002B-3S-02" class="detail-toggle">
    <div class="l-anniversary__container">
        <div class="section-dot">
            <picture>
                <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_02_02.webp" type="image/webp">
                <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_02_02.jpg" alt="SGSP2001B-3S">
            </picture>
            <div class="num-circle-dot">
                <div class="num-circle">
                    <div class="num-list active-num">
                        <p>01</p>
                    </div>
                    <div class="num-list">
                        <p>02</p>
                    </div>
                    <div class="num-list">
                        <p>03</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="slide-glass" data-aos="fade">
            <ul class="slick-glass">
                <li>
                    <div class="title-slick u-sp-only">
                        <h3>Feature 01</h3>
                    </div>
                    <div class="image-slick">
                        <picture>
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-1-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-1-pc.jpg" type="image/jpeg">
                            <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-1-sp.webp" type="image/webp">
                            <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-1-sp.jpg" alt="SGSP2001B-3S">
                        </picture>
                    </div>
                </li>
                <li>
                    <div class="title-slick u-sp-only">
                        <h3>Feature 02</h3>
                    </div>
                    <div class="image-slick">
                        <picture>
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-2-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-2-pc.jpg" type="image/jpeg">
                            <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-2-sp.webp" type="image/webp">
                            <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-2-sp.jpg" alt="SGSP2001B-3S">
                        </picture>
                    </div>
                </li>
                <li>
                    <div class="title-slick u-sp-only">
                        <h3>Feature 03</h3>
                    </div>
                    <div class="image-slick">
                        <picture>
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-3-pc.webp" type="image/webp">
                            <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-3-pc.jpg" type="image/jpeg">
                            <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-3-sp.webp" type="image/webp">
                            <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/plastic2-c2-point-3-sp.jpg" alt="SGSP2001B-3S">
                        </picture>
                    </div>
                </li>
            </ul>
            <ul class="slick-detail">
                <li>
                    <div class="detail-slick">
                        <div class="title-slick u-pc-only">
                            <h3>Feature 01</h3>
                        </div>
                        <p>Classic rectangle style that is both chic and business-formal</p>
                    </div>
                </li>
                <li>
                    <div class="detail-slick">
                        <div class="title-slick u-pc-only">
                            <h3>Feature 02</h3>
                        </div>
                        <p>Hinges are specially designed to keep the overall frame looking sharp and polished</p>
                    </div>
                </li>
                <li>
                    <div class="detail-slick">
                        <div class="title-slick u-pc-only">
                            <h3>Feature 03</h3>
                        </div>
                        <p>A unique, serialised number on the temple tip so you finally have a frame to call your own</p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="row-purchase l-anniversary__container" data-aos="fade">
        <a href="/sg/en/products/SGSP2002B-3S?sku=7000" class="l-anniversary__btn">Purchase Online</a>
    </div>
</div>
</div></div>
<div class="section-lineup__row metal-lineup" id="metal">
    <div class="l-anniversary__container position-relative">
        <div class="section-lineup__row__title">
            <h3 class="l-anniversary__heading l-anniversary__heading--left">
                <span data-aos="fade-up" data-aos-delay="300">Metal Frame</span>
            </h3>
        </div>
        <div class="section-lineup__row__banner section-lineup__row__banner--mod">
            <div class="l-anniversary__frame" data-aos>
                <picture>
                    <source media="(min-width: 768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/line-up-banner02.webp">
                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/line-up-banner02-sp.webp" alt="Metal Frame">
                </picture>
            </div>
        </div>
    </div>
    <div class="section-lineup__row__glass">
        <div class="section-lineup__row__glass__detail" data-aos="fade">
            <div class="l-anniversary__container">
                <div class="title-tabs">
                    <div class="id-sku">
                        <h4>SGSP1001B-3S</h4>
                    </div>
                    <div class="tabs-bullet">
                        <ul>
                            <li>
                                <a href="javascript:voide(0);" data-id="#SGSP1001B-3S-01" class="circle-tabs-toggle">
                                    <span class="circle color-navy-blue active"></span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:voide(0);" data-id="#SGSP1001B-3S-02" class="circle-tabs-toggle">
                                    <span class="circle color-olive-green"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="detail-tabs">
                    <p>A crossover between the classic rectangle style and the currently-trending polygonal style. The frame body features dark-toned, matte finishing that adds a utilitarian touch to the frame design. Being lightweight, durable and anti-rust, this titanium frame is perfectly poised between form and function.</p>
                </div>
            </div>
        </div>
        <div id="SGSP1001B-3S-01" class="detail-toggle" style="display:block;">
            <div class="l-anniversary__container">
                <div class="section-dot">
                    <picture>
                        <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_0202.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_0202.jpg" alt="SGSP2001B-3S">
                    </picture>
                    <div class="num-circle-dot">
                        <div class="num-circle">
                            <div class="num-list active-num">
                                <p>01</p>
                            </div>
                            <div class="num-list">
                                <p>02</p>
                            </div>
                            <div class="num-list">
                                <p>03</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slide-glass" data-aos="fade">
                    <ul class="slick-glass">
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 01</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-1-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-1-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-1-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-1-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 02</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-2-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-2-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-2-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-2-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 03</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-3-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-3-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-3-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c1-point-3-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                    </ul>
                    <ul class="slick-detail">
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 01</h3>
                                </div>
                                <p>Frame shape that is a crossover between rectangle and polygon</p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 02</h3>
                                </div>
                                <p>Frame body made of titanium that is known for being lightweight, durable and anti-rust</p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 03</h3>
                                </div>
                                <p>A unique, serialised number on the temple tip so you finally have a frame to call your own</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row-purchase l-anniversary__container" data-aos="fade">
                <a href="/sg/en/products/SGSP1001B-3S?sku=6990" class="l-anniversary__btn">Purchase Online</a>
            </div>
        </div>
        <div id="SGSP1001B-3S-02" class="detail-toggle">
            <div class="l-anniversary__container">
                <div class="section-dot">
                    <picture>
                        <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_0223.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_0223.svg" alt="SGSP2001B-3S">
                    </picture>
                    <div class="num-circle-dot">
                        <div class="num-circle">
                            <div class="num-list active-num">
                                <p>01</p>
                            </div>
                            <div class="num-list">
                                <p>02</p>
                            </div>
                            <div class="num-list">
                                <p>03</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slide-glass" data-aos="fade">
                    <ul class="slick-glass">
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 01</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-1-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-1-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-1-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-1-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 02</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-2-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-2-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-2-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-2-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 03</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-3-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-3-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-3-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal1-c2-point-3-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                    </ul>
                    <ul class="slick-detail">
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 01</h3>
                                </div>
                                <p>Frame shape that is a crossover between rectangle and polygon</p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 02</h3>
                                </div>
                                <p>Frame body made of titanium that is known for being lightweight, durable and anti-rust</p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 03</h3>
                                </div>
                                <p>A unique, serialised number on the temple tip so you finally have a frame to call your own</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row-purchase l-anniversary__container" data-aos="fade">
                <a href="/sg/en/products/SGSP1001B-3S?sku=6991" class="l-anniversary__btn">Purchase Online</a>
            </div>
        </div>
    </div>
    <div class="section-lineup__row__glass">
        <div class="section-lineup__row__glass__detail" data-aos="fade">
            <div class="l-anniversary__container">
                <div class="title-tabs">
                    <div class="id-sku">
                        <h4>SGSP1002B-3S</h4>
                    </div>
                    <div class="tabs-bullet">
                        <ul>
                            <li>
                                <a href="javascript:voide(0);" data-id="#SGSP1002B-3S-01" class="circle-tabs-toggle">
                                    <span class="circle color-gunmetal active"></span>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:voide(0);" data-id="#SGSP1002B-3S-02" class="circle-tabs-toggle">
                                    <span class="circle color-silver"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="detail-tabs">
                    <p>Polygonal frame with a double-bridge design that oozes '80s retro vibes. Inspired by Singaporeans’ new-found love for vintage wear, this oversized titanium frame with a sleek silhouette is set to be a showstopper that will stand the test of time.</p>
                </div>
            </div>
        </div>
        <div id="SGSP1002B-3S-01" class="detail-toggle" style="display:block;">
            <div class="l-anniversary__container">
                <div class="section-dot">
                    <picture>
                        <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_0231.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_0231.jpg" alt="SGSP2001B-3S">
                    </picture>
                    <div class="num-circle-dot">
                        <div class="num-circle">
                            <div class="num-list active-num">
                                <p>01</p>
                            </div>
                            <div class="num-list">
                                <p>02</p>
                            </div>
                            <div class="num-list">
                                <p>03</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slide-glass" data-aos="fade">
                    <ul class="slick-glass">
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 01</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-1-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-1-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-1-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-1-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 02</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-2-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-2-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-2-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-2-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 03</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-3-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-3-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-3-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c1-point-3-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                    </ul>
                    <ul class="slick-detail">
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 01</h3>
                                </div>
                                <p>Polygonal frame in oversized style with double-bridge design </p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 02</h3>
                                </div>
                                <p>Frame body made of titanium that is known for being lightweight, durable and anti-rust </p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 03</h3>
                                </div>
                                <p>A unique, serialised number on the temple tip so you finally have a frame to call your own</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row-purchase l-anniversary__container" data-aos="fade">
                <a href="/sg/en/products/SGSP1002B-3S?sku=6993" class="l-anniversary__btn">Purchase Online</a>
            </div>
        </div>
        <div id="SGSP1002B-3S-02" class="detail-toggle">
            <div class="l-anniversary__container">
                <div class="section-dot">
                    <picture>
                        <source data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_02111.webp" type="image/webp">
                        <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/SGSP2001B-3S-C1_02111.jpg" alt="SGSP2001B-3S">
                    </picture>
                    <div class="num-circle-dot">
                        <div class="num-circle">
                            <div class="num-list active-num">
                                <p>01</p>
                            </div>
                            <div class="num-list">
                                <p>02</p>
                            </div>
                            <div class="num-list">
                                <p>03</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="slide-glass" data-aos="fade">
                    <ul class="slick-glass">
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 01</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-1-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-1-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-1-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-1-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 02</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-2-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-2-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-2-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-2-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                        <li>
                            <div class="title-slick u-sp-only">
                                <h3>Feature 03</h3>
                            </div>
                            <div class="image-slick">
                                <picture>
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-3-pc.webp" type="image/webp">
                                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-3-pc.jpg" type="image/jpeg">
                                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-3-sp.webp" type="image/webp">
                                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/metal2-c2-point-3-sp.jpg" alt="SGSP2001B-3S">
                                </picture>
                            </div>
                        </li>
                    </ul>
                    <ul class="slick-detail">
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 01</h3>
                                </div>
                                <p>Polygonal frame in oversized style with double-bridge design</p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 02</h3>
                                </div>
                                <p>Frame body made of titanium that is known for being lightweight, durable and anti-rust</p>
                            </div>
                        </li>
                        <li>
                            <div class="detail-slick">
                                <div class="title-slick u-pc-only">
                                    <h3>Feature 03</h3>
                                </div>
                                <p>A unique, serialised number on the temple tip so you finally have a frame to call your own</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row-purchase l-anniversary__container" data-aos="fade">
                <a href="/sg/en/products/SGSP1002B-3S?sku=6994" class="l-anniversary__btn">Purchase Online</a>
            </div>
        </div>
    </div>
</div>
</div>
<!-- </div> -->
</section>
<section class="case-cloth">
    <div class="l-anniversary__container">
        <h3 class="l-anniversary__heading">
            <span data-aos="fade-up" data-aos-delay="300">
                EXCLUSIVE CASE<br>AND CLOTH
            </span>
        </h3>
        <p data-aos="fade-up" data-aos-delay="300">Each frame comes with an exclusive 10th anniversary-edition spectacle case and lens cleaning cloth.</p>
        <div class="case-cloth__img">
            <div class="l-anniversary__frame" data-aos>
                <picture>
                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/case-cloth-pc.webp" type="image/webp">
                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/case-cloth-pc.jpg" type="image/jpeg">
                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/10th-anniversary-collection/case-cloth-sp.webp" type="image/webp">
                    <img class="lazyload" src="https://www.owndays.com/images/specials/products/10th-anniversary-collection/case-cloth-sp.jpg" alt="Exclusive Case and Cloth">
                </picture>
            </div>
        </div>
    </div>
</section>
<section class="l-anniversary__btn-gp l-anniversary__container">
    <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-anniversary__btn l-anniversary__btn--black l-anniversary__btn--small-sp">Search for nearest OWNDAYS stores</a>
    <a href="/sg/en/products?keyword=%20SGSP&submitFilter=true&page=1" class="l-anniversary__btn l-anniversary__btn--black l-anniversary__btn--small-sp">
        See all 10th Anniversary <br>Collection products
    </a>
</section>
<section class="terms">
    <div class="l-anniversary__container">
        <h4 class="l-anniversary__heading">Terms of Purchase</h4>
        <ul>
            <li>Frames are available while stocks last</li>
            <li>Frames are available at random and there is no selection of specific serialised number</li>
            <li>OWNDAYS Care+ is not available for purchase with frames in the 10th Anniversary Collection</li>
            <li>The 20%-off-new-frame-purchase OWNDAYS Care+ benefit is not valid for frames in the 10th Anniversary Collection</li>
            <li>Frames are not valid in conjunction with any discount and/or promotion</li>
            <li>The management reserves the right to amend these Terms of Purchase at any time without prior notice</li>
        </ul>
    </div>
</section>
<section class="shops">
    <div class="l-anniversary__container">
        <h4 class="l-anniversary__heading">Product Availability</h4>
        <ul class="l-anniversary__shops">
            <li>
                <a href="/sg/en/shops/909">OWNDAYS nex</a>
            </li>
            <li>
                <a href="/sg/en/shops/928">OWNDAYS Jurong Point</a>
            </li>
            <li>
                <a href="/sg/en/shops/924">OWNDAYS Suntec City</a>
            </li>
            <li>
                <a href="/sg/en/shops/933">OWNDAYS Waterway Point</a>
            </li>
            <li>
                <a href="/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a>
            </li>
            <li>
                <a href="/sg/en/shops/922">OWNDAYS IMM Outlet</a>
            </li>
            <li>
                <a href="/sg/en/shops/1020">OWNDAYS Takashimaya S.C Premium Concept Store</a>
            </li>
        </ul>
    </div>
</section>
</section></main></div>
<div class="whats-app noprint">
    <a href="https://wa.me/6598214268" target="_blank" rel="nofollow">
        <img src="https://www.owndays.com/images/common/logo-whatapp.png" alt="WhatsApp">
    </a>
</div>
<!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>
<footer class="l-footer l-container-fluid">
    <!-- MENU -->
    <div class="l-footer__menu l-container">
        <div class="l-footer__menu__categories">
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>
                        PRODUCTS<span class="c-menu--arrows u-sp-only"></span>
                    </p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li>
                        <a href="/sg/en/eyeglasses">Frames</a>
                    </li>
                    <li>
                        <a href="/sg/en/sunglasses">Sunglasses</a>
                    </li>
                    <li>
                        <a href="/sg/en/contacts/clear-contactlens">Contact Lens</a>
                    </li>
                    <li>
                        <a href="/sg/en/brands">All Collections</a>
                    </li>
                    <li>
                        <a href="/sg/en/news">Campaigns</a>
                    </li>
                </ul>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>
                        PURCHASE<span class="c-menu--arrows u-sp-only"></span>
                    </p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li>
                        <a href="/sg/en/services/lens">Lens Guide</a>
                    </li>
                    <li>
                        <a href="/sg/en/services/warranty">OWNDAYS Customer Guarantees</a>
                    </li>
                </ul>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another">
                    <a href="/sg/en/shops?autoSearchCurrentLocation=true">
                        Shops<span class="c-menu--arrows c-menu--arrows-link u-sp-only"></span>
                    </a>
                </div>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>
                        ABOUT<span class="c-menu--arrows u-sp-only"></span>
                    </p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li class="u-pc-only">
                        <a href="/sg/en/shops?autoSearchCurrentLocation=true">Shops</a>
                    </li>
                    <li>
                        <a href="/sg/en/services">Services</a>
                    </li>
                    <li>
                        <a href="/sg/en/services/lens-replacement">Lens Replacement</a>
                    </li>
                    <li class="sg-none">
                        <a href="/sg/en/account">OWNDAYS MEMBERSHIP</a>
                    </li>
                    <li>
                        <a href="/sg/en/account">Membership</a>
                    </li>
                    <li>
                        <a href="/sg/en/company">Corporate Information</a>
                    </li>
                    <li>
                        <a href="https://www.odsaiyou.com/sg/" target="_blank">Recruitment </a>
                    </li>
                    <li>
                        <a href="/sg/en/company/eyecamp">Eye Camp</a>
                    </li>
                </ul>
            </div>
            <div class="l-footer__menu__category">
                <div class="l-footer__menu__category-name">
                    <p>
                        SUPPORT<span class="c-menu--arrows c-menu--arrows u-sp-only"></span>
                    </p>
                </div>
                <ul class="l-footer__menu__category-list">
                    <li class="u-pc-only"></li>
                    <li>
                        <a href="/sg/en/faq">FAQs</a>
                    </li>
                    <li>
                        <a href="/sg/en/requirement">System Requirements</a>
                    </li>
                    <li>
                        <a href="/sg/en/sitemap">Site Map</a>
                    </li>
                    <li class="u-pc-only u-mt__20">
                        <a href="/sg/en/services/onlinestore">New Users</a>
                    </li>
                    <li class="u-pc-only">
                        <a href="/sg/en/register">Sign Up an Account</a>
                    </li>
                </ul>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another">
                    <a href="/sg/en/contact">
                        Contact Us<span class="c-menu--arrows c-menu--arrows-link u-sp-only"></span>
                    </a>
                </div>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another">
                    <a href="/sg/en/services/onlinestore">New Users</a>
                </div>
            </div>
            <div class="l-footer__menu__category u-sp-only">
                <div class="l-footer__menu__category-name l-footer__menu__category-name--another">
                    <a href="/sg/en/register">
                        Sign Up an Account<span class="c-menu--arrows c-menu--arrows-link"></span>
                    </a>
                </div>
            </div>
            <div class="l-footer__contact">
                <div class="l-footer__menu__category-list u-pc-only">CONTACT US</div>
                <div class="u-sp-only">Contact Us</div>
                <p class="l-footer__contact-tel">
                    <a id="footer_modal_contact" href="tel:62220588">6222-0588</a>
                    <span>
                        Operating Hours <br>10:00 - 19:00
                    </span>
                </p>
            </div>
        </div>
        <!-- SNS -->
        <ul class="l-footer__sns-link">
            <li>
                <a href="https://www.facebook.com/owndays.sg/" target="_blank" rel="nofollow" title="Facebook" aria-label="Facebook">
                    <i class="fab fa-facebook"></i>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/owndays_sg/" target="_blank" rel="nofollow" title="instagram" aria-label="Instagram">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCSu2yqzCpnTtGXi05WtsiZg" target="_blank" rel="nofollow" title="youtube" aria-label="Youtube">
                    <i class="fab fa-youtube"></i>
                </a>
            </li>
        </ul>
    </div>
    <div class="l-footer__bottom">
        <div class="l-footer__bottom-inner">
            <div class="l-footer__copyright">
                <p>COPYRIGHT (C) OWNDAYS co., ltd. ALL RIGHTS RESERVED.</p>
            </div>
            <div class="l-footer__bottom-grid">
                <div class="l-footer__language">
                    <button type="button" data-toggle="modal" data-target="#modalCountryLang" class="l-footer__country-lang border-0">
                        <span>
                            <picture>
                                <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                <source srcset="https://www.owndays.com/images/flags/sg.png" type="image/png">
                                <img class="lazyload" src="https://www.owndays.com/images/flags/sg.png" alt="sg" data-sizes="auto">
                            </picture>
                            Singapore                        
                        </span>
                    </button>
                </div>
                <ul class="l-footer__language-link">
                    <li>
                        <a href="https://www.owndays.com/sg/en/news/10th-anniversary-collection">English</a>
                    </li>
                   
                </ul>
                <ul class="l-footer__agreement">
                    <li>
                        <a href="/sg/en/privacy">PRIVACY POLICY</a>
                    </li>
                    <li>
                        <a href="/sg/en/terms">TERMS & CONDITIONS</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                        <li class="modal-list-item">
                            <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">日本語
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">English
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">中文 (简体)
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">中文 (繁體)
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">English
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">ภาษาไทย
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">English
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">日本語
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">中文 (简体)
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">Tiếng Việt
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">English
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            
                            </a>
                        </li>
                    </ul>
                    <!-- For Desktop -->
                    <ul class="modal-list u-pc-only">
                        <li class="modal-list-item">
                            <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">日本語
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">English
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">中文 (简体)
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">中文 (繁體)
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">Tiếng Việt
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">English
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            
                            </a>
                        </li>
                    </ul>
                    <ul class="modal-list u-pc-only">
                        <li class="modal-list-item">
                            <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">English
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">ภาษาไทย
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">English
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">日本語
                                        </a>
                                </li>
                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">中文 (简体)
                                        </a>
                                </li>
                            </ul>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            
                            </a>
                        </li>
                        <li class="modal-list-item">
                            <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source data-srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Scripts -->
<script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
<script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
<script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script>
    lazySizes.init();
    const baseUrl = "https://www.owndays.com"

    //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

    if (!Object.entries) {
        Object.entries = function(obj) {
            var ownProps = Object.keys(obj)
              , i = ownProps.length
              , resArray = new Array(i);
            // preallocate the Array
            while (i--)
                resArray[i] = [ownProps[i], obj[ownProps[i]]];

            return resArray;
        }
        ;
    }
    if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, 'find', {
            value: function(predicate) {
                // 1. Let O be ? ToObject(this value).
                if (this == null) {
                    throw new TypeError('"this" is null or not defined');
                }

                var o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                var len = o.length >>> 0;

                // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                if (typeof predicate !== 'function') {
                    throw new TypeError('predicate must be a function');
                }

                // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                var thisArg = arguments[1];

                // 5. Let k be 0.
                var k = 0;

                // 6. Repeat, while k < len
                while (k < len) {
                    // a. Let Pk be ! ToString(k).
                    // b. Let kValue be ? Get(O, Pk).
                    // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                    // d. If testResult is true, return kValue.
                    var kValue = o[k];
                    if (predicate.call(thisArg, kValue, k, o)) {
                        return kValue;
                    }
                    // e. Increase k by 1.
                    k++;
                }

                // 7. Return undefined.
                return undefined;
            },
            configurable: true,
            writable: true
        });
    }

    window.site_url = ''
</script>
<script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
<script src="https://www.owndays.com/web/js/app.js?id=63b50ca8cf73fb967649?20230222"></script>
<script src="https://www.owndays.com/web/js/helper.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
<script src="https://cdn.xapping.com/libs.js"></script>
<script>
    var uri = window.location.toString();
    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
        window.history.replaceState({}, document.title, clean_uri);
    }

    $('#main-body').show();

    function openMenu(evt, menuName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tab__content");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tab__link");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(menuName).style.display = "flex";
        evt.currentTarget.className += " active";
    }

    function supportWebP() {
        var rv = $.Deferred()
          , img = new Image();
        img.onload = function() {
            rv.resolve();
        }
        ;
        img.onerror = function() {
            rv.reject();
        }
        ;
        img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
        return rv.promise();
    }

    supportWebP().then(function() {
        $('body').addClass('webp');
    }, function() {
        $('body').addClass('no-webp');
    });
</script>
<script>
    // handle suffix breadcrumb in profile page
    appendBreadcrumb($('.is-active'))

    $('.profile-nav').on('click', function() {
        appendBreadcrumb(this)
    })
    function appendBreadcrumb(element) {
        var breadcrumb = $(element).data('breadcrumb')
        $('#suffix-breadcrumb').remove();
        if (breadcrumb) {
            $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>" + breadcrumb + "</li>")
        }
    }
</script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/video.js/7.21.0/video.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/videojs-youtube/2.6.1/Youtube.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-10th-anniversary-collection.js?v=1707083913"></script>
<script>
    var $slider = $('.slick-glass');
    var $customDots = $('.num-circle');
    var $customDetail = $('.slick-detail');

    $('.slide-glass').each(function() {
        var $this = $(this);
        var $slickGlass = $this.find('.slick-glass');
        var $slickDetail = $this.find('.slick-detail');

        $slickGlass.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            dots: true,
            lazyLoad: 'progressive',
            asNavFor: $slickDetail
        });

        $slickDetail.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            dots: false,
            asNavFor: $slickGlass
        });
    });

    // $slider.slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     fade: true,
    //     dots: true,
    //     asNavFor: '.slick-detail'
    // });
    // $slider.slick({
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    //     fade: true,
    //     dots: false,
    // });
    $('.circle-tabs-toggle').click(function() {

        var i, tablinks;

        $(this).parents('.section-lineup__row__glass').find('.detail-toggle').hide();
        $(this).parents('.section-lineup__row__glass').find('.circle').removeClass('active');
        var urlTxt = $(this).attr('data-id');
        $(this).find('span').addClass('active');
        $(this).parents('.section-lineup__row__glass').find(urlTxt).show();
        $slider.slick('setPosition');
        $customDetail.slick('setPosition');
    });

    $slider.on('init beforeChange', function(event, slick, currentSlide, nextSlide) {
        $(this).parents('.detail-toggle').find('.num-list').removeClass('active-num');
        $(this).parents('.detail-toggle').find('li').removeClass('active-detail');
        $(this).parents('.detail-toggle').find('.num-list').eq(nextSlide).addClass('active-num');
        $(this).parents('.detail-toggle').find('li').eq(nextSlide).addClass('active-detail');
    });
    // $slider.on('setPosition', function(event, slick, currentSlide){
    //     $(this).parents('.detail-toggle').find('.l-anniversary__container').css('opacity','1');
    // });
    $customDots.on('click', '.num-list', function() {
        var $this = $(this);
        var dotIndex = $this.index();
        $this.parents('.detail-toggle').find('.slick-glass').slick('slickGoTo', dotIndex);
    });
</script>
<!-- Google Tag Manager -->
<script>
    (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0]
          , j = d.createElement(s)
          , dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    }
    )(window, document, 'script', 'dataLayer', 'GTM-5WRT43B');
</script>
<!-- End Google Tag Manager -->
<!-- Google Tag Manager (noscript) -->
<noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5WRT43B" height="0" width="0" style="display:none;visibility:hidden"></iframe>
</noscript>
<!-- End Google Tag Manager (noscript) -->
<!-- Facebook Pixel Code -->
<script>
    !function(f, b, e, v, n, t, s) {
        if (f.fbq)
            return;
        n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        ;
        if (!f._fbq)
            f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1510426245838375');
    // Insert your pixel ID here.
    fbq('track', 'PageView');
</script>
<noscript>
    <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1510426245838375&ev=PageView&noscript=1"/>
</noscript>
<!-- DO NOT MODIFY -->
<!-- User Insight PCDF Code Start : owndays.com -->
<script type="text/javascript">
    var _uic = _uic || {};
    var _uih = _uih || {};
    _uih['id'] = 52715;
    _uih['lg_id'] = '';
    _uih['fb_id'] = '';
    _uih['tw_id'] = '';
    _uih['uigr_1'] = '';
    _uih['uigr_2'] = '';
    _uih['uigr_3'] = '';
    _uih['uigr_4'] = '';
    _uih['uigr_5'] = '';
    _uih['uigr_6'] = '';
    _uih['uigr_7'] = '';
    _uih['uigr_8'] = '';
    _uih['uigr_9'] = '';
    _uih['uigr_10'] = '';

    /* DO NOT ALTER BELOW THIS LINE */
    /* WITH FIRST PARTY COOKIE */
    (function() {
        var bi = document.createElement('script');
        bi.type = 'text/javascript';
        bi.async = true;
        bi.src = '//cs.nakanohito.jp/b3/bi.js';
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(bi, s);
    }
    )();
</script>
<!-- User Insight PCDF Code End : owndays.com -->
<script>
    !function(w, d, t) {
        w.TiktokAnalyticsObject = t;
        var ttq = w[t] = w[t] || [];
        ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"],
        ttq.setAndDefer = function(t, e) {
            t[e] = function() {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
            }
        }
        ;
        for (var i = 0; i < ttq.methods.length; i++)
            ttq.setAndDefer(ttq, ttq.methods[i]);
        ttq.instance = function(t) {
            for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
                ttq.setAndDefer(e, ttq.methods[n]);
            return e
        }
        ,
        ttq.load = function(e, n) {
            var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
            ttq._i = ttq._i || {},
            ttq._i[e] = [],
            ttq._i[e]._u = i,
            ttq._t = ttq._t || {},
            ttq._t[e] = +new Date,
            ttq._o = ttq._o || {},
            ttq._o[e] = n || {};
            var o = document.createElement("script");
            o.type = "text/javascript",
            o.async = !0,
            o.src = i + "?sdkid=" + e + "&lib=" + t;
            var a = document.getElementsByTagName("script")[0];
            a.parentNode.insertBefore(o, a)
        }
        ;

        ttq.load('CGHAVFBC77UA88R0IJ2G');
        ttq.page();
    }(window, document, 'ttq');
</script>
</body></html>
`;

const AnniversaryCollection10thHTML = () => {
  useEffect(() => {
    const loadScript = src => {
      return new Promise(resolve => {
        const script = document.createElement('script');
        script.src = src;
        script.defer = true;
        script.onload = resolve;
        document.head.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        const externalScripts = [
          'https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap',
          'https://www.owndays.com/web/js/active-nav-menu.js?v=1.1',
          'https://www.owndays.com/web/js/lazysizes.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js',
          'https://www.owndays.com/web/js/jquery.easing.1.3.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js',
          'https://www.owndays.com/web/js/app.js?id=63b50ca8cf73fb967649?20230222',
          'https://www.owndays.com/web/js/helper.js',
          'https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js',
          'https://cdn.xapping.com/libs.js',
          'https://www.owndays.com/web/js/aos.js',
          'https://www.owndays.com/web/js/specials-10th-anniversary-collection.js?v=1707083913',
          'https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js',
          'https://cdnjs.cloudflare.com/ajax/libs/video.js/7.21.0/video.min.js',
          'https://cdnjs.cloudflare.com/ajax/libs/videojs-youtube/2.6.1/Youtube.min.js',
        ];

        await Promise.all(externalScripts.map(src => loadScript(src)));

        const linkSlickStyles = document.createElement('link');
        linkSlickStyles.rel = 'stylesheet';
        linkSlickStyles.type = 'text/css';
        linkSlickStyles.href =
          'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.css';
        document.head.appendChild(linkSlickStyles);
      } catch (error) {
        console.error('Failed to load scripts:', error);
      }
    };

    loadScripts();
  }, []);

  useEffect(() => {
    const loadScript = async () => {
      try {
        const scriptjQuery = document.createElement('script');
        scriptjQuery.src = 'https://code.jquery.com/jquery-3.6.4.min.js';
        scriptjQuery.type = 'text/javascript';
        scriptjQuery.async = true;
        document.head.appendChild(scriptjQuery);

        await new Promise(resolve => {
          scriptjQuery.onload = resolve;
        });

        const scriptSlick = document.createElement('script');
        scriptSlick.src = 'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js';
        scriptSlick.type = 'text/javascript';
        scriptSlick.async = true;
        document.head.appendChild(scriptSlick);

        await new Promise(resolve => {
          scriptSlick.onload = resolve;
        });

        const $slider = $('.slick-glass');
        const $customDots = $('.num-circle');
        const $customDetail = $('.slick-detail');

        $('.slide-glass').each(function () {
          const $this = $(this);
          const $slickGlass = $this.find('.slick-glass');
          const $slickDetail = $this.find('.slick-detail');

          $slickGlass.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            dots: true,
            lazyLoad: 'progressive',
            asNavFor: $slickDetail,
          });

          if ($slickDetail.length > 0) {
            $slickDetail.slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
              fade: true,
              dots: false,
              asNavFor: $slickGlass,
            });
          }
        });

        $('.circle-tabs-toggle').click(function () {
          $(this).parents('.section-lineup__row__glass').find('.detail-toggle').hide();
          $(this).parents('.section-lineup__row__glass').find('.circle').removeClass('active');
          const urlTxt = $(this).attr('data-id');
          $(this).find('span').addClass('active');
          $(this).parents('.section-lineup__row__glass').find(urlTxt).show();
          $slider.slick('setPosition');
          $customDetail.slick('setPosition');
        });

        $slider.on('init beforeChange', function (event, slick, currentSlide, nextSlide) {
          $(this).parents('.detail-toggle').find('.num-list').removeClass('active-num');
          $(this).parents('.detail-toggle').find('li').removeClass('active-detail');
          $(this).parents('.detail-toggle').find('.num-list').eq(nextSlide).addClass('active-num');
          $(this).parents('.detail-toggle').find('li').eq(nextSlide).addClass('active-detail');
        });

        $customDots.on('click', '.num-list', function () {
          const $this = $(this);
          const dotIndex = $this.index();
          $this.parents('.detail-toggle').find('.slick-glass').slick('slickGoTo', dotIndex);
        });
      } catch (error) {
        console.error('Failed to load scripts:', error);
      }
    };

    loadScript();
  }, []);
  return (
    <>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear.',
          },
          {
            name: 'twitter:description',
            content:
              'A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear.',
          },
          {
            name: 'og:title',
            content: '10th Anniversary Collection | OWNDAYS ONLINE STORE - OPTICAL SHOP',
          },
          {
            name: 'twitter:title',
            content: '10th Anniversary Collection | OWNDAYS ONLINE STORE - OPTICAL SHOP',
          },
        ]}
        description="A commemorative collection to mark 10 amazing years in Singapore, featuring frame designs created specifically for the discerning local customers who manifest in the love for tasteful yet functional eyewear."
        title="10th Anniversary Collection | OWNDAYS ONLINE STORE - OPTICAL SHOP"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default AnniversaryCollection10thHTML;
