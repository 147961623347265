import * as actionTypes from '../actionTypes/primer';

export function getClientToken(orderId) {
  return { type: actionTypes.GET_CLIENT_TOKEN_LOAD, orderId };
}

// Params : PaymentMethods of Primer
// Ex. : [{"type":"ATOME","managerType":"REDIRECT"},{"type":"PAYMENT_CARD","managerType":"CARD"}]
export function getPaymentMethodsWithPrimer(data) {
  return { type: actionTypes.GET_PAYMENT_METHODS_WITH_PRIMER_LOAD, data };
}

export function getPrimerSavedCards() {
  return { type: actionTypes.GET_PRIMER_SAVED_CARDS_LOAD };
}

// Params : { paymentRefId: '123', paymentId: '456' }
export function primerPaymentStatusUpdate(data) {
  return { type: actionTypes.PRIMER_PAYMENT_STATUS_UPDATE_LOAD, data };
}

export function resetPrimerData() {
  return { type: actionTypes.RESET_PRIMER_DATA };
}

export function showLoader() {
  return { type: actionTypes.SHOW_LOADER };
}

export function hideLoader() {
  return { type: actionTypes.HIDE_LOADER };
}
