import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './FaqPage.scss';
import { useSelector } from 'react-redux';
import BlackPlus from '../../../../../static/assets/svg/od-plus-black.svg';
import { v4 } from 'uuid';
import Breadcrumbs from '../../../CommonComponents/Breadcrumbs/Breadcrumbs';

const FaqPage = () => {
  const dataLocale = useSelector(state => state.locale);
  const { FAQ_PAGE } = dataLocale;
  const { title, FAQ_LIST } = FAQ_PAGE;

  const [faqList, setFaqList] = useState(null);

  useEffect(() => {
    if (FAQ_LIST && FAQ_LIST.length > 0) {
      const faqDataWithId = FAQ_LIST.map(faq => {
        return {
          ...faq,
          id: v4(),
        };
      });
      setFaqList(faqDataWithId);
    }
  }, [FAQ_LIST]);

  const faqItemHandler = id => {
    setFaqList(prevState =>
      prevState.map(faq => {
        if (faq.id === id) {
          return {
            ...faq,
            handler: !faq.handler,
          };
        }
        return faq;
      })
    );
  };

  const renderTextWithLink = (text, linkUrl) => {
    const regex = /(Please click here for)(.*?)\./i;
    const match = text.match(regex);

    if (match && linkUrl) {
      const [fullMatch, prefix, rest] = match;
      return (
        <>
          {text.slice(0, match.index)}
          {prefix}
          <Link to={linkUrl}>{rest.trim() || 'more details'}</Link>.
          {text.slice(match.index + fullMatch.length)}
        </>
      );
    }

    return text;
  };

  const breadCrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '#', label: 'FAQ' },
  ];

  return (
    <>
      <main>
        <Breadcrumbs crumbs={breadCrumbs} />
        <section className="od-faq">
          <div className="od-faq__title od-font-bold">{title}</div>
          {faqList && faqList.length > 0 && (
            <div className="od-faq__content">
              <dl className="od-faq__content__list">
                {faqList.map(el => {
                  return (
                    <React.Fragment key={el.id}>
                      <dt onClick={() => faqItemHandler(el.id)}>
                        <div className="od-faq__content__list__item-title od-font-bold">
                          <span>{el.title}</span>
                          <img alt="black plus" height="16px" src={BlackPlus} width="16px" />
                        </div>
                      </dt>
                      {el.handler && (
                        <dd className="od-faq__content__list__item-description od-font-reg">
                          <span>{el.description}</span>
                          {el.answersList && el.answersList.length > 0 && (
                            <ul className="od-faq__content__list__item-description__answer-sheet">
                              {el.answersList.map(answer => (
                                <li key={v4()}>
                                  {renderTextWithLink(answer.title, answer.linkUrl)}
                                </li>
                              ))}
                            </ul>
                          )}
                        </dd>
                      )}
                    </React.Fragment>
                  );
                })}
              </dl>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default FaqPage;
