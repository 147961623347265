import * as actionTypes from './../../actionTypes/yotpo';

const initialState = {
  yotpoSiteRating: 0,
  yotpoSiteReviewCount: 0,
};

export default function yotpo(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.YOTPO_SITE_RATINGS_SUCCESS:
      return Object.assign({}, prevState, {
        yotpoSiteRating: action.data.response.bottomline.average_score,
        yotpoSiteReviewCount: action.data.response.bottomline.total_reviews,
      });
    case actionTypes.YOTPO_SITE_RATINGS_TIMEOUT:
      return Object.assign({}, prevState, {
        yotpoSiteRating: 0,
        yotpoSiteReviewCount: 0,
      });
    case actionTypes.YOTPO_SITE_RATINGS_FAILURE:
      return Object.assign({}, prevState, {
        yotpoSiteRating: 0,
        yotpoSiteReviewCount: 0,
      });
    default:
      return prevState;
  }
}
