import React from 'react';
import './YourName.scss';
import { useSelector } from 'react-redux';

const YourName = ({ yourName, setYourName, device }) => {
  const dataLocale = useSelector(state => state.locale);
  const { WRITE_YOUR_NAME_HERE } = dataLocale;

  const handleInputChange = event => {
    const newText = event.target.value;
    setYourName(newText);
  };

  const mainClass = `od-gc-your-name--${device}`;

  return (
    <div className={`od-gc-your-name ${mainClass}`}>
      <input
        className={`${mainClass}__input`}
        placeholder={`${WRITE_YOUR_NAME_HERE}...`}
        type="text"
        value={yourName}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default YourName;
