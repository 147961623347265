import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { LINEUP, ACCESSORIES, SHOPS } from './constants';
import ProductTabs from './ProductTabs';
import AOS from 'aos';
import 'aos/dist/aos.css';

const BodyBlackCollection = ({ imgUrl }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <>
      <section className="black-collection__about">
        <div className="black-collection__container">
          <p className="black-collection__about-text">
            The Black Collection comprises approximately 30 frame designs, all centred around
            OWNDAYS’ <br />
            brand colour—black. From timeless everyday essentials to sleek designs and functional
            elements <br />
            like photochromic lenses, the collection offers versatile options for any occasion.{' '}
            <br />
            <strong>Meet your classic, go-to black frame.</strong>
          </p>
          {/* <a
            href="/sg/en/eyeglasses/brands/black-collection"
            className="black-collection__btn black-collection__btn--black"
          >
            View Products
          </a> */}
        </div>
      </section>
      <section className="black-collection__lineup">
        <div className="black-collection__lineup-top">
          <div className="black-collection__container black-collection__container--lg">
            <h2 className="black-collection__title">LINEUP</h2>
            <p>BLACK COLLECTION Spectacle Frames・Sunglasses</p>
          </div>
        </div>
        {LINEUP && (
          <ul className="black-collection__lineup-collection">
            {LINEUP.map((item, itemIdx) => (
              <li key={itemIdx} className="black-collection__lineup-collection-item">
                <div className="black-collection__lineup-collection-img">
                  {item.images?.map((img, imgIdx) => (
                    <div
                      key={img.imgAlt}
                      className={`black-collection__lineup-collection-img-${imgIdx + 1}`}
                      data-aos="fade-up"
                      data-aos-delay={(imgIdx + 1) * 100}
                    >
                      <picture>
                        <source media="(min-width: 768px)" srcSet={`${imgUrl}/${img.imgSrcPc}`} />
                        <img src={`${imgUrl}/${img.imgSrcSp}`} alt={img.imgAlt} />
                      </picture>
                    </div>
                  ))}
                </div>
                <div className="black-collection__container black-collection__container--lg">
                  <div data-aos="fade-up">
                    <h3 className="black-collection__lineup-collection-title">{item.title}</h3>
                    <p className="black-collection__lineup-collection-des">{item.des}</p>
                  </div>
                  {item.products && <ProductTabs products={item.products} imgUrl={imgUrl} />}
                </div>
              </li>
            ))}
          </ul>
        )}
      </section>
      <section className="black-collection__accessories">
        <div className="black-collection__container">
          <h2 className="black-collection__title black-collection__title--center">ACCESSORIES</h2>
          {ACCESSORIES && (
            <ul className="black-collection__accessories-list">
              {ACCESSORIES.map(item => (
                <li className="black-collection__accessories-item" key={item.title}>
                  <Slider {...settings}>
                    {item.imgSrc?.map(img => (
                      <div key={img}>
                        <img src={`${imgUrl}/${img}`} alt={item.title} />
                      </div>
                    ))}
                  </Slider>
                  <h3 className="black-collection__accessories-title">{item.title}</h3>
                  <p className="black-collection__accessories-price">{item.price}</p>
                  <p className="black-collection__accessories-description">{item.description}</p>
                  {/* <a href={item.href} className="black-collection__btn black-collection__btn--dark">
                    View Product
                  </a> */}
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
      <section className="black-collection__shops">
        <div className="black-collection__container black-collection__container--sm">
          <h2 className="black-collection__title black-collection__title--center">
            AVAILABLE SHOPS
          </h2>
          {SHOPS && (
            <ul className="black-collection__shops-list">
              {SHOPS.map(shop => (
                <li key={shop}>{shop}</li>
              ))}
            </ul>
          )}
          <div className="black-collection__shops-actions">
            {/* <a href="/sg/en/eyeglasses/brands/black-collection" className="black-collection__btn">
              View All Black Collection Products
            </a> */}
            <a href="https://www.owndays.com/stores/sg" className="black-collection__btn">
              Search for OWNDAYS stores
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default BodyBlackCollection;
