import React, { Fragment } from 'react';

import './BodyOrthoKWebinar.scss';

export const BodyOrthoKWebinar = ({ baseImgUrl }) => {
  const renderJoinUs = () => (
    <p className="body-ortho-k-webinar__detail__join-us">
      Join us for an exclusive webinar to learn more about the technology behind Ortho-K, its key
      benefits and whether this treatment is suitable for you or your child!{' '}
    </p>
  );

  const renderSchedule = () => {
    const details = [
      { title: 'Date', desc: '16 November 2024 (Sat.)' },
      { title: 'Session 1', desc: '10:00am - 11:00am' },
      { title: 'Session 2', desc: '2:00pm - 3:00pm' },
    ];

    return (
      <div>
        <ul className="body-ortho-k-webinar__detail__list">
          {details.map((item, index) => (
            <li key={index} className="body-ortho-k-webinar__detail__item">
              <h4 className="body-ortho-k-webinar__detail__item__title">{item.title}</h4>
              <p className="body-ortho-k-webinar__detail__item__desc">{item.desc}</p>
            </li>
          ))}
        </ul>
        <p className="body-ortho-k-webinar__detail__zoom-link-text">
          The webinar ZOOM link will be sent via email by 14 November 2024.
        </p>
      </div>
    );
  };

  const renderAllParticipants = () => {
    return (
      <div>
        <p className="body-ortho-k-webinar__detail__all-participants">
          All participants will receive an exclusive $300 voucher* for Ortho-K lenses at the end of
          the webinar!
        </p>
        <p className="body-ortho-k-webinar__detail__remark">*T&Cs apply</p>
      </div>
    );
  };

  const renderSpeakerDesc = () => {
    return (
      <p>
        Our expert speaker Danny brings over 10 years of experience in the optical industry. As the
        product specialist for Ortho-K, he will provide valuable insights into how Ortho-K lenses
        can effectively manage myopia progression and offer spectacle-free vision.
      </p>
    );
  };

  return (
    <Fragment>
      <section className="body-ortho-k-webinar__detail">
        <h2 className="body-ortho-k-webinar__detail__title">Webinar Details</h2>

        <div className="body-ortho-k-webinar__detail__message-sp base-ortho-k-webinar__container">
          {renderJoinUs()}
          {renderSchedule()}
          {renderAllParticipants()}
        </div>

        <div className="body-ortho-k-webinar__detail__message-pc base-ortho-k-webinar__container">
          <div className="body-ortho-k-webinar__detail__box-left">
            {renderJoinUs()}
            {renderAllParticipants()}
          </div>

          <div>{renderSchedule()}</div>
        </div>

        <div className="base-ortho-k-webinar__container">
          <div className="body-ortho-k-webinar__speaker">
            <div className="body-ortho-k-webinar__speaker__header">
              <img
                alt="webinar speaker-Danny Toh"
                className="body-ortho-k-webinar__speaker__img"
                src={`${baseImgUrl}/speaker.webp`}
              />

              <div>
                <div className="body-ortho-k-webinar__speaker__title">
                  <h4>Danny Toh</h4>
                  <span>Speaker</span>
                </div>

                <p className="body-ortho-k-webinar__speaker__sub-title">
                  Optometrist Trainer at OWNDAYS SINGAPORE
                </p>

                <div className="body-ortho-k-webinar__speaker__desc-pc">{renderSpeakerDesc()}</div>
              </div>
            </div>

            <div className="body-ortho-k-webinar__speaker__desc-sp">{renderSpeakerDesc()}</div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
