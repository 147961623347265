import React, { useEffect, useState } from 'react';
import './GiftCardPreview.scss';
import { useSelector } from 'react-redux';
import HeadingPreviewImg from './../../../../../../static/assets/img/giftCard/mix-gift-preview.png';
import OdLogoDarkIcon from './../../../../../../static/assets/svg/od-logo-dark-icon.svg';
import OdLogoWhiteIcon from './../../../../../../static/assets/svg/od-logo-white-icon.svg';
// import GiftCard from '../GiftCard/GiftCard';

const GiftCardPreview = ({ url, writeMessageText, previewType, yourName }) => {
  const dataLocale = useSelector(state => state.locale);
  const { PREVIEW, FROM } = dataLocale;

  const [previewDesignType, setPreviewDesignType] = useState(false);

  useEffect(() => {
    if (previewType > 1) {
      setPreviewDesignType(true);
    } else {
      setPreviewDesignType(false);
    }
  }, [previewType]);

  return (
    <div className="od-gift-card-preview">
      <div className="od-gift-card-preview__title od-font-bold">{PREVIEW}</div>
      {url ? (
        <div className="od-gift-card-preview__content">
          <div
            className={`od-gift-card-preview__content__heading od-gift-card-preview__content__heading--${
              previewDesignType ? 'third' : 'second'
            }`}
          >
            <img
              alt="preview heading"
              className="od-gift-card-preview__content__heading__backdrop"
              src={HeadingPreviewImg}
            />

            <div className="od-gift-card-preview__content__heading__logo">
              <img
                alt="gift preview logo"
                src={previewDesignType ? OdLogoWhiteIcon : OdLogoDarkIcon}
              />
            </div>
            <div
              className={`od-gift-card-preview__content__heading__description od-gift-card-preview__content__heading__description--${
                previewDesignType ? 'third' : 'second'
              }`}
            >
              {writeMessageText}
            </div>

            <div
              className={`od-gift-card-preview__content__heading__name od-gift-card-preview__content__heading__name--${
                previewDesignType ? 'third' : 'second'
              }`}
            >
              {FROM.toLowerCase()}: {yourName}
            </div>
          </div>
          <div
            className={`od-gift-card-preview__content__card od-gift-card-preview__content__card--${
              previewDesignType ? 'third' : 'second'
            }`}
          >
            {url ? (
              <img
                key={url}
                alt="gift card"
                className="od-gift-card-preview__content__card__img"
                src={url}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="od-gift-card-preview__empty-card">
          <div className="od-gift-card-preview__empty-card__text od-font-bold">
            Select a design to preview
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftCardPreview;
