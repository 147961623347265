import React from 'react';
import MembershipStatus from './MembershipStatus/MembershipStatus';
import './MembershipStatuses.scss';

const MembershipStatuses = () => {
  return (
    <div className="od-membership-statuses-container">
      <MembershipStatus
        percentsPoints={4}
        points="0 to 199 points"
        status="bronze"
        title="Bronze"
      />
      <MembershipStatus
        percentsPoints={6}
        points="200 to 599 points"
        status="silver"
        title="Silver"
      />
      <MembershipStatus percentsPoints={8} points="600 above" status="gold" title="Gold" />
    </div>
  );
};

export default MembershipStatuses;
