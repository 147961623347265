export const CHECKOUT_INITIATE_JUSPAY = 'checkout/CHECKOUT_INITIATE_JUSPAY';
export const CHECKOUT_INITIATE_JUSPAY_SUCCESS = 'checkout/CHECKOUT_INITIATE_JUSPAY_SUCCESS';
export const CHECKOUT_INITIATE_JUSPAY_FAIL = 'checkout/CHECKOUT_INITIATE_JUSPAY_FAIL';
export const CHECKOUT_STEP_KEY = 'checkout/CHECKOUT_STEP_KEY';
export const SELECTED_PAYMENT_METHOD = 'checkout/SELECTED_PAYMENT_METHOD';
export const LOAD_ABANDON_LEAD = 'checkout/LOAD_ABANDON_LEAD';
export const LOAD_HTO_LEAD = 'checkout/LOAD_HTO_LEAD';
export const SAVE_GUEST_INFO = 'checkout/SAVE_GUEST_INFO';
export const MANAGE_CHECKOUT_DATA = 'checkout/MANAGE_CHECKOUT_DATA';
export const CART_MODIFICATION = 'common/CART_MODIFICATION';

export const SAVE_SHIPPING_ADDRESS = 'checkout/SAVE_SHIPPING_ADDRESS';
export const SAVE_SHIPPING_ADDRESS_SUCCESS = 'checkout/SAVE_SHIPPING_ADDRESS_SUCCESS';
export const SAVE_SHIPPING_ADDRESS_FAIL = 'checkout/SAVE_SHIPPING_ADDRESS_FAIL';

export const ADD_NEW_SHIPPING_ADDRESS_CLICK = 'checkout/ADD_NEW_SHIPPING_ADDRESS_CLICK';

export const GET_DELIVERY_OPTIONS = 'checkout/GET_DELIVERY_OPTIONS';
export const GET_DELIVERY_OPTIONS_SUCCESS = 'checkout/GET_DELIVERY_OPTIONS_SUCCESS';
export const GET_DELIVERY_OPTIONS_FAIL = 'checkout/GET_DELIVERY_OPTIONS_FAIL';

export const UPDATE_DELIVERY_OPTION = 'checkout/UPDATE_DELIVERY_OPTION';
export const GET_CART_DATA_SUCCESS = 'checkout/GET_CART_DATA_SUCCESS';
export const GET_CART_DATA_FAIL = 'checkout/GET_CART_DATA_FAIL';

/* STEP 3 */
export const CARTSTEP3_LOAD = 'routemodule/CARTSTEP3_LOAD';
export const CARTSTEP3_SUCCESS = 'routemodule/CARTSTEP3_SUCCESS';
export const CARTSTEP3_FAIL = 'routemodule/CARTSTEP3_FAIL';
export const LOAD_PAYMENT_SUCCESS = 'checkout/LOAD_PAYMENT_SUCCESS';
export const PAYMENT_SUCCESS = 'checkout/PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'checkout/PAYMENT_FAIL';

/* STEP 3 */

export const SHOW_OOS = 'checkout/SHOW_OOS';

export const LOGOUT_USER = 'checkout/LOGOUT_USER';

export const GET_PRESALE_INFO = 'checkout/GET_PRESALE_INFO';
export const GET_PRESALE_INFO_SUCCESS = 'checkout/GET_PRESALE_INFO_SUCCESS';
export const GET_PRESALE_INFO_FAIL = 'checkout/GET_PRESALE_INFO_FAIL';

// captcha
export const GET_CAPTCHA = 'checkout/GET_CAPTCHA';
export const GET_CAPTCHA_SUCCESS = 'checkout/GET_CAPTCHA_SUCCESS';
export const GET_CAPTCHA_FAIL = 'checkout/GET_CAPTCHA_FAIL';

// Bin Series
export const GET_CARDOFFER = 'checkout/GET_CARDOFFER';
export const GET_CARDOFFER_SUCCESS = 'checkout/GET_CARDOFFER_SUCCESS';
export const GET_CARDOFFER_FAIL = 'checkout/GET_CARDOFFER_FAIL';

export const GET_BANK_DETAIL_OFFER = 'checkout/GET_BANK_DETAIL_OFFER';
export const GET_BANK_DETAIL_OFFER_SUCCESS = 'checkout/GET_BANK_DETAIL_OFFER_SUCCESS';
export const GET_BANK_DETAIL_OFFER_FAIL = 'checkout/GET_BANK_DETAIL_OFFER_FAIL';

export const GET_OFFERS_LOAD = 'checkout/GET_OFFERS_LOAD';
export const GET_OFFERS_SUCCESS = 'checkout/GET_OFFERS_SUCCESS';
export const GET_OFFERS_FAIL = 'checkout/GET_OFFERS_FAIL';

// Pre Sales User Login
export const PRE_SALES_USER_LOGIN = 'checkout/PRE_SALES_USER_LOGIN';
export const PRE_SALES_USER_LOGIN_SUCCESS = 'checkout/PRE_SALES_USER_LOGIN_SUCCESS';
export const PRE_SALES_USER_LOGIN_FAIL = 'checkout/PRE_SALES_USER_LOGIN_FAIL';

// Validate VPA
export const VALIDATE_VPA = 'checkout/VALIDATE_VPA';
export const VALIDATE_VPA_SUCCESS = 'checkout/VALIDATE_VPA_SUCCESS';
export const VALIDATE_VPA_FAIL = 'checkout/VALIDATE_VPA_FAIL';
// Reset VPA
export const RESET_VPA = 'checkout/RESET_VPA';

// UPI Transaction Status
export const GET_TRANS_STATUS_LOAD = 'checkout/GET_TRANS_STATUS_LOAD';
export const GET_TRANS_STATUS_SUCCESS = 'checkout/GET_TRANS_STATUS_SUCCESS';
export const GET_TRANS_STATUS_FAIL = 'checkout/GET_TRANS_STATUS_FAIL';

export const GET_JUSPAYMENT_STATUS_LOAD = 'GET_JUSPAYMENT_STATUS_LOAD';
export const GET_JUSPAYMENT_STATUS_SUCCESS = 'GET_JUSPAYMENT_STATUS_SUCCESS';
export const GET_JUSPAYMENT_STATUS_FAIL = 'GET_JUSPAYMENT_STATUS_FAIL';
