import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import * as actionTypes from '../actionTypes/order';
import getSetCookie from '../cookie';
import apiClient from '../helpers/apiClient';

let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

let _client = null;

function* getOrder({
  data: { orderId, dontInitSession, email, isExchange, isMyAcct, isLensPackageUpgrade },
}) {
  let url = isMyAcct ? config.apiPath.getOrderDetailsInv : `${config.apiPath.v2Orders}/`;
  const successAction = { type: actionTypes.GET_ORDER_SUCCESS };
  const errorAction = { type: actionTypes.GET_ORDER_FAIL };
  const client = { ..._client };
  const client2 = { ...client };
  const header = isExchange ? { 'X-Service-Type': 'exchange' } : {};
  if (isLensPackageUpgrade) header['X-Auth-Token'] = '8e8b0816-4c73-4f08-8f7d-022dcd186a91';
  client.setHeader(header);
  client2.setHeader(header);
  if (__SERVER__) {
    url = `${url}${orderId || client.req.cookies.orderId}${email ? `?email=${email}` : ''}`;
  } else if (__CLIENT__) {
    if (getSetCookie.getCookie('uploadPrescriptonSMS') !== '') {
      url = `${url}${getSetCookie.getCookie('orderId')}?sha1Email=${getSetCookie.getCookie(
        'uploadPrescriptonSMS'
      )}`;
    } else {
      url = `${url}${orderId || getSetCookie.getCookie('orderId')}`;
      url = email ? `${url}?email=${email}` : url;
    }
  }
  try {
    if (xApiClient === 'mobilesite') {
      const responseData = {};
      const data = yield call(async () => client.get(url));
      responseData.result = isMyAcct ? data.result.orders[0] : data.result;
      successAction.data = responseData;
    } else {
      const data = yield call(async () => client.get(url));
      successAction.data = data;
    }
    yield put(successAction);
  } catch (err) {
    if (err.status === 401 && __CLIENT__ && !dontInitSession) {
      const data = yield call(async () => client.post(config.apiPath.userSession));
      getSetCookie.deleteCookie('clientV1');
      getSetCookie.deleteCookie('frontend');
      getSetCookie.setCookie('clientV1', data && data.result ? data.result.id : '', 30);
    }
    errorAction.error = err;
    yield put(errorAction);
  }
}

function* getRecentOrder({ pageNo, pageSize }) {
  const url = config.apiPath.v2Orders + '?page=' + pageNo + '&page-size=' + pageSize;
  const successAction = { type: actionTypes.PREV_ORDER_SUCCESS };
  const errorAction = { type: actionTypes.PREV_ORDER_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(url));
    successAction.data = data;
    yield put(successAction);
  } catch (err) {
    errorAction.error = err;
    yield put(errorAction);
  }
}

function* getOrderCancelRefundDetails({ orderId }) {
  const url = `${config.apiPath.v2Orders}/${orderId}/refund/details/`;
  const successAction = { type: actionTypes.ORDER_CANCEL_REFUND_DETAILS_SUCCESS };
  const errorAction = { type: actionTypes.ORDER_CANCEL_REFUND_DETAILS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(url));
    successAction.data = data.result;
    yield put(successAction);
  } catch (err) {
    errorAction.error = err;
    yield put(errorAction);
  }
}

export function* runOrderCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_ORDER, getOrder),
    takeLatest(actionTypes.PREV_ORDER_LOAD, getRecentOrder),
    takeLatest(actionTypes.ORDER_CANCEL_REFUND_DETAILS, getOrderCancelRefundDetails),
  ]);
}
