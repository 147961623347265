import React from 'react';

const Item = ({ brand, openCard, setOpenCard, setSelectedBrand, brandIndex, setBrandIndex }) => {
  return (
    <li
      key={brand.title}
      onClick={() => {
        setOpenCard(brand.title);
        setSelectedBrand(brand);
        setBrandIndex(brandIndex);
      }}
    >
      <div className={`brands__card ${openCard === brand.title ? 'is-open' : ''}`}>
        <img src={brand.thumbnail} alt={brand.alt} loading="lazy" />
      </div>
    </li>
  );
};

export default Item;
