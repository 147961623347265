import React, { Fragment } from 'react';

import './FooterOwndaysSunglasses.scss';

export const FooterOwndaysSunglasses = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="footer-owndays-sunglasses__tips">
        <div className="footer-owndays-sunglasses__tips__banner">
          <div className="base-owndays-sunglasses__container">
            <h3 className="base-owndays-sunglasses__title">Tips</h3>
            <h4 className="footer-owndays-sunglasses__tips__banner__sub-title">
              Did you know that you can get a suntan through your eyes?
            </h4>
            <p className="footer-owndays-sunglasses__tips__banner__desc">
              When UV rays enter the eyes, the brain sends a signal to the body to produce melanin
              which causes the skin to turn dark. Your sunscreen products will therefore be less
              effective if your eyes are left without UV protection. Don’t forget to safeguard your
              eyes against UV rays too!
            </p>
          </div>

          <picture>
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/tips-pc-bg.webp`} />
            <img
              alt="Did you know that you can get a suntan through your eyes?"
              className="lazyload"
              src={`${baseImgUrl}/tips-sp-bg.webp`}
            />
          </picture>
        </div>

        <div className="base-owndays-sunglasses__container">
          <ul className="footer-owndays-sunglasses__tips__list">
            <li className="footer-owndays-sunglasses__tips__item">
              <div className="footer-owndays-sunglasses__tips__item-header">
                <img
                  alt="Suntan on the skin starts from the eyes!"
                  className="lazyload"
                  src={`${baseImgUrl}/tips-icon-01.svg`}
                />
                <h4 className="footer-owndays-sunglasses__tips__item-header__title">
                  Suntan on the skin starts from the eyes!
                </h4>
              </div>

              <p className="footer-owndays-sunglasses__tips__item-desc">
                When your eyes are exposed to UV rays, the brain sends a signal to the body to
                produce melanin that causes tanning to the skin even if you have sunscreen and other
                forms of UV protection in place.
              </p>
            </li>

            <li className="footer-owndays-sunglasses__tips__item">
              <div className="footer-owndays-sunglasses__tips__item-header">
                <img
                  alt="UV rays are scarier than you thought!"
                  className="lazyload"
                  src={`${baseImgUrl}/tips-icon-02.svg`}
                />
                <h4 className="footer-owndays-sunglasses__tips__item-header__title">
                  UV rays are scarier than you thought!
                </h4>
              </div>

              <p className="footer-owndays-sunglasses__tips__item-desc">
                Excessive exposure to UV rays increases the risk of eye diseases such as cataracts,
                glaucoma and keratitis.
              </p>
            </li>

            <li className="footer-owndays-sunglasses__tips__item">
              <div className="footer-owndays-sunglasses__tips__item-header">
                <img
                  alt="Let’s protect the delicate eyes of our children!"
                  className="lazyload"
                  src={`${baseImgUrl}/tips-icon-03.svg`}
                />
                <h4 className="footer-owndays-sunglasses__tips__item-header__title">
                  Let’s protect the delicate eyes of our children!
                </h4>
              </div>

              <p className="footer-owndays-sunglasses__tips__item-desc">
                Children are more vulnerable to the adverse effects of UV rays compared to the
                adults as their eyes are very delicate. Therefore, extra care should be taken to
                protect their eyes from overexposure to UV rays.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
