import React from 'react';
import './GiftCardsPriceSection.scss';
import Pricing from '../../../../../CommonComponents/ProductCard/Pricing';
import { useSelector } from 'react-redux';

const GiftCardsPriceSection = ({ price = '' }) => {
  const dataLocale = useSelector(state => state.locale);
  const { CURRENCY_SYMBOL, POINTS_EARNED, POINTS } = dataLocale;
  const localeInfo = useSelector(state => state.common.localeInfo);

  return (
    <div className="od-gc-price-section">
      <Pricing
        doNotShowTaxes
        giftPrice
        currencySymbol={CURRENCY_SYMBOL}
        dataLocale={dataLocale}
        finalprice={price}
        localeInfo={localeInfo}
      />
      {/* <div className="od-gc-price-section__earned-points od-font-reg">
        {POINTS_EARNED}: 7{POINTS}(4%)
      </div> */}
    </div>
  );
};

export default GiftCardsPriceSection;
