export const STUDIO_FLOW_TIME_LOADER = 'STUDIO_FLOW_TIME_LOADER';

export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';

export const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT';
export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_FAILURE = 'BOOK_APPOINTMENT_FAILURE';
export const RESET_BOOK_APPOINTMENT = 'RESET_BOOK_APPOINTMENT';

export const BOOK_TIME = 'BOOK_TIME';
export const BOOK_TIME_SUCCESS = 'BOOK_TIME_SUCCESS';
export const BOOK_TIME_FAILURE = 'BOOK_TIME_FAILURE';

export const BOOK_TEST_YOUR_VISION = 'BOOK_TIME_TEST_YOUR_VISION';
export const BOOK_TEST_YOUR_VISION_SUCCESS = 'BOOK_TIME_TEST_YOUR_VISION_SUCCESS';
export const BOOK_TEST_YOUR_VISION_FAILURE = 'BOOK_TIME_TEST_YOUR_VISION_FAILURE';

export const GET_TIME_SLOT = 'GET_TIME_SLOT';
export const GET_TIME_SLOT_SUCCESS = 'GET_TIME_SLOT_SUCCESS';
export const GET_TIME_SLOT_FAILURE = 'GET_TIME_SLOT_GET_TIME_SLOT_FAILURE';

export const STUDIO_FLOW_RESET = 'STUDIO_FLOW_RESET';

export const STUDIO_FLOW_MODAL = 'STUDIO_FLOW_MODAL';

export const STUDIO_FLOW_SHOW_THANK_YOU = 'STUDIO_FLOW_SHOW_THANK_YOU';

export const RESET_STUDIO_FLOW_FORMS = 'RESET_STUDIO_FLOW_FORMS';
