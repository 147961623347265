import React from 'react';
import Button from '../../../CommonComponents/BootstrapComponents/Button/Button';
import { deleteExchangeStorageData } from '../../../utils/helper';
import LKModal from '../Modal/Modal';
import './exchangeSessionDesktop.scss';

export default function ExchangeSessionDesktop(props) {
  const { dataLocale } = props;
  const { ANOTHER_TAB_OPEN, CONTINUE, CONTINUE_EXCHANGE } = dataLocale;

  const closeExchangeFlow = () => {
    if (props.cleanReturnExchange) {
      props.cleanReturnExchange();
    }
    deleteExchangeStorageData();
    window.location.reload();
  };

  return (
    <div className="lk-exchange-modal">
      <LKModal showModal backdrop="static" size="medium">
        <div className="align-items-center display-flex flex-direction-column">
          <div className="margin-b15 fs16">{ANOTHER_TAB_OPEN}</div>
          <Button
            aria-label="Click To Close The Exchange Session"
            className="theme-button margin-b15"
            onClick={closeExchangeFlow}
          >
            <div className="fs16">{CONTINUE}</div>
            <div className="fs12">(Close Exchange Process)</div>
          </Button>
          <div className="fs12 fw500 text-color_pale_black">{CONTINUE_EXCHANGE}</div>
        </div>
      </LKModal>
    </div>
  );
}
