import React from 'react';
import { Link } from 'react-router-dom';
import './CategoryMenuOD.scss';
import SubMenuOD from './../SubMenuOD/SubMenuOD';
import { setDataAttr } from './../../../../../utils/helper';
import { capitalizeLetters } from './../../../../helper';
import { v4 } from 'uuid';

// need to send the key and parent key to dtm.
const onCategoryHover = context => {
  context.stopPropagation();
  context.preventDefault();
  const siblingContext = context.currentTarget.parentNode.childNodes;
  siblingContext.forEach(element => {
    element.classList.remove('active');
  });
  context.currentTarget.classList.add('active');
};

const CategoryMenuOD = props => {
  const {
    categoryMenuData,
    updateNavValueDtm,
    section,
    subSection,
    dataLocale: { SELECT_CATEGORY },
  } = props;
  return (
    <div className="od-category-menu menu-line-section">
      {categoryMenuData.url && categoryMenuData.url?.indexOf('lenskart.com') === -1 ? (
        <Link
          className="nav-head-category od-font-bold"
          to={categoryMenuData.url}
          onClick={() => updateNavValueDtm(section, subSection, categoryMenuData.url)}
        >
          {capitalizeLetters(SELECT_CATEGORY)}
        </Link>
      ) : (
        <a
          className="nav-head-category od-font-bold"
          href={categoryMenuData.url}
          onClick={() => updateNavValueDtm(section, subSection, categoryMenuData.url)}
        >
          {capitalizeLetters(SELECT_CATEGORY)}
        </a>
      )}

      {categoryMenuData.submenu.map((categoryMenu, index) => {
        const dataAttr =
          categoryMenu.gaObj && Object.keys(categoryMenu.gaObj).length > 0
            ? setDataAttr(categoryMenu.gaObj)
            : null;
        return (
          <div
            key={v4()}
            className={`od-category-menu_data category ${index === 0 ? 'active' : ''}`}
            onFocus={ev => onCategoryHover(ev)}
            onMouseOver={ev => onCategoryHover(ev)}
          >
            {categoryMenu.url && categoryMenu.url?.indexOf('lenskart.com') === -1 ? (
              <Link
                {...dataAttr}
                className="getGaData"
                to={categoryMenu.url}
                onClick={e =>
                  updateNavValueDtm(
                    section,
                    subSection,
                    categoryMenu.title,
                    null,
                    e,
                    section + ' ' + subSection + ' ' + categoryMenu.title
                  )
                }
              >
                <div className="od-category-menu_list od-font-bold">
                  <div className="od-category-menu_items">
                    <span>{categoryMenu.title}</span>
                    <span dangerouslySetInnerHTML={{ __html: categoryMenu.subtext }}></span>
                  </div>
                  <i className="fa fa-angle-right"></i>
                </div>
              </Link>
            ) : (
              <a
                {...dataAttr}
                className="od-category-menu_list getGaData od-font-bold"
                href={categoryMenu.url}
                onClick={e =>
                  updateNavValueDtm(
                    section,
                    subSection,
                    categoryMenu.title,
                    null,
                    e,
                    section + ' ' + subSection + ' ' + categoryMenu.title
                  )
                }
              >
                <div className="od-category-menu_items od-font-bold">
                  <span>{categoryMenu.title}</span>
                  <span dangerouslySetInnerHTML={{ __html: categoryMenu.subtext }} />
                </div>
                <i className="fa fa-angle-right"></i>
              </a>
            )}
            <div className="display-none select-submenu">
              {categoryMenu.submenu && (
                <SubMenuOD
                  categoryName={categoryMenu.title}
                  parentKey={categoryMenu}
                  section={section}
                  subMenuData={categoryMenu}
                  subSection={subSection}
                  submenu={categoryMenu.submenu}
                  updateNavValueDtm={updateNavValueDtm}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CategoryMenuOD;
