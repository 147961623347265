import React, { Fragment, useEffect } from 'react';

import { Breadcrumbs } from './Breadcrumbs';

import './HeaderMemoryMetal.scss';

export const HeaderMemoryMetal = props => {
  const { isLoading, setIsLoading, baseImgUrl } = props;

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isLoading, setIsLoading]);

  return (
    <Fragment>
      <Breadcrumbs />

      {isLoading && (
        <div className="loading">
          <span>
            <img alt="Memory Metal" src={`${baseImgUrl}/brand-logo.svg`} style={{ width: 250 }} />
          </span>
        </div>
      )}

      <section className="main">
        <div className="main__bg">
          <picture>
            <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
            <img alt="Memory Metal" data-aos="fade-down" src={`${baseImgUrl}/main-sp.webp`} />
          </picture>
        </div>
        <div className="main__content">
          <div className="base-memory-metal__container">
            <div className="main__heading">
              <p
                className="main__heading-logo"
                data-aos="fade-right"
                data-aos-delay="300"
                data-aos-offset="50"
              >
                <img alt="Memory Metal" src={`${baseImgUrl}/brand-logo.svg`} />
              </p>
              <p
                className="main__heading-text"
                data-aos="fade-right"
                data-aos-delay="600"
                data-aos-offset="50"
              >
                Great Elasticity and Durability, The New Standards of Spectacle Frames
              </p>
            </div>
            <div className="main__points" data-aos="fade-up" data-aos-delay="900">
              <ul className="main__points-list">
                <li className="main__points-list-item point">
                  <div className="point__icon">
                    <img alt="Unparalleled Elasticity" src={`${baseImgUrl}/point-1.svg`} />
                  </div>
                  <div className="point__details">
                    <h5 className="point__details-title">Unparalleled Elasticity</h5>
                    <p className="point__details-text">
                      Made of Nitinol is able to retain its shape despite being bent by force
                    </p>
                  </div>
                </li>
                <li className="main__points-list-item point">
                  <div className="point__icon">
                    <img alt="Amazingly Lightweight" src={`${baseImgUrl}/point-2.svg`} />
                  </div>
                  <div className="point__details">
                    <h5 className="point__details-title">Amazingly Lightweight</h5>
                    <p className="point__details-text">
                      So light you will forget you are wearing spectacles
                    </p>
                  </div>
                </li>
                <li className="main__points-list-item point">
                  <div className="point__icon">
                    <img alt="Durable and Rust-Resistant" src={`${baseImgUrl}/point-3.svg`} />
                  </div>
                  <div className="point__details">
                    <h5 className="point__details-title">Durable and Rust-Resistant</h5>
                    <p className="point__details-text">
                      Applying ion plating to enhance durability and resistance to rust
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
