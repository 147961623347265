export const setPdStateHandler = (imageFileName, pdImageFileName, setPdState) => {
  let PdSection;
  if ((imageFileName && pdImageFileName) || pdImageFileName) {
    PdSection = 'SHOW_POWER_PD';
  } else if (imageFileName && !pdImageFileName) {
    PdSection = 'SHOW_POWER';
  }
  setPdState(PdSection);
};

export const pdIconStateHandler = (section, imageFileName, redisCommonData, dataLocale) => {
  let icon;
  let message;

  if (['POWER_UPLOAD', 'CONTACT_FOR_POWER'].includes(section) || imageFileName) {
    const powerStatusMessage =
      redisCommonData?.POWER_STATUS_MESSAGE && JSON.parse(redisCommonData.POWER_STATUS_MESSAGE);
    if (section === 'POWER_UPLOAD' || imageFileName) {
      icon =
        powerStatusMessage?.powerUploadText.icon ||
        'https://static.lenskart.com/media/mobile/universal/assets/power-upload.svg';
      message =
        powerStatusMessage?.powerUploadText.message ||
        dataLocale?.PRESCRIPTION_UPLOADED_WILL_ASSIST_YOU;
    } else {
      icon =
        powerStatusMessage?.dontKnowPowerText.icon ||
        'https://static.lenskart.com/media/mobile/universal/assets/dont-know-power.svg';
      message =
        powerStatusMessage?.dontKnowPowerText.message ||
        dataLocale?.DONT_KNOW_PRESCRIPTION_WILL_ASSIST_YOU;
    }
  }

  return { icon, message };
};
