import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/neft';
import apiClient from '../helpers/apiClient';
import config from 'config';

let _client = null;

function* saveNeftValues({ values }) {
  const successAction = { type: actionTypes.EDIT_NEFT_SUCCESS };
  const errorAction = { type: actionTypes.EDIT_NEFT_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.post(`${config.apiPath.editNeft}`, values));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runNeftCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.EDIT_NEFT_LOAD, saveNeftValues)]);
}
