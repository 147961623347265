import React, { useState, useEffect } from 'react';
import './DontKnowPowerPage.scss';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { useSelector } from 'react-redux';
import * as registerActions from '../../../../actionCreators/auth';
import AuthModalNewLoadable from '../../../../desktop/components/AuthModalNew/AuthModalNewLoadable';
import AuthModal from '../../../../desktop/components/AuthModal/AuthModalLoadable';
import { Helmet } from 'react-helmet';

const DontKnowPowerPage = () => {
  const { CONTACT_US_PHONE } = useSelector(state => state.locale);
  const redisCommonData = useSelector(state => state.common.redisCommonData);
  const login = useSelector(state => state.common.login);
  const askUserLogin = useSelector(state => state.common.askUserLogin);

  const isNewAuthPopUpAvailable = !!(
    redisCommonData?.NEW_AUTH_POPUP_DESKTOP &&
    JSON.parse(redisCommonData?.NEW_AUTH_POPUP_DESKTOP)?.enable === 'ON'
  );

  const [showAuthPopUp, setShowAuthPopUp] = useState(false);
  const [customerMode, setCustomerMode] = useState(false);

  const mainClass = 'od-dkp';

  const hideAuthPopUp = () => {
    clearInterval(window.secondsTimer);
    setShowAuthPopUp(false);
  };

  useEffect(() => {
    const type = location.search?.indexOf('type=login') > -1;

    setTimeout(() => {
      if (type) {
        setShowAuthPopUp(Boolean(type));
      }
    }, 100);
  }, []);

  const handleContactUsButton = () => {
    window.location.assign(`tel:${CONTACT_US_PHONE}`);
  };

  const handleContactUsOnlineChatButton = () => {
    window.location.assign(
      `https://v2.zopim.com/widget/livechat.html?api_calls=%5B%5D&hostname=www.owndays.com&key=2PhNsmwAr5W5EPKtRPYjMEkRJtl41ZhS&lang=en&`
    );
  };

  const handleContactUsFacebookButton = () => {
    window.location.assign(`https://www.facebook.com/owndays.sg/`);
  };

  const displayAuthPopUp = () => {
    setShowAuthPopUp(true);

    registerActions.resetMobileLoginProps();
  };

  return (
    <>
      <Helmet
        meta={[
          {
            name: 'description',
            content:
              'New to OWNDAYS or a returning customer? Shop online and easily use your in-store prescription records. Discover seamless eyewear shopping with OWNDAYS now!',
          },
        ]}
        title="Get Your Perfect Glasses Online | OWNDAYS Singapore"
      />
      <div className={`${mainClass}`}>
        <div className={`${mainClass}-banner`}>
          <div className={`${mainClass}-banner__img`}>
            {/* <img alt="don't know power banner" src={DontKnowPowerBanner} /> */}
            <img
              alt="don't know power banner"
              src="https://static.lenskart.com/media/owndays/desktop/img/owndays/dontKnowPower/dont-know-power-banner.webp"
            />
          </div>
          <div className={`${mainClass}-banner__title od-font-bold`}>
            It’s okay even if you don’t know the lens power required
          </div>
          <div className={`${mainClass}-banner__description od-font-reg`}>
            OWNDAYS Online Store offers you different options of buying prescription glasses.
          </div>
        </div>
        <div className={`${mainClass}-separator`} />
        <div className={`${mainClass}-btns`}>
          <ButtonOwnDays
            fontClass="od-font-bold"
            fontSize="20px"
            mode={customerMode ? 'white' : 'grey'}
            px="120px"
            py="30px"
            style={{ minHeight: '108px' }}
            text="Customers who purchased at physical store"
            width="487px"
            onClick={() => {
              if (customerMode) setCustomerMode(false);
            }}
          />
          <ButtonOwnDays
            fontClass="od-font-bold"
            fontSize="20px"
            mode={customerMode ? 'grey' : 'white'}
            px="120px"
            py="30px"
            style={{ minHeight: '108px' }}
            text="New OWNDAYS customers"
            width="487px"
            onClick={() => {
              if (!customerMode) setCustomerMode(true);
            }}
          />
        </div>
        <div className={`${mainClass}-options`}>
          <div className={`${mainClass}__option-one`}>
            <div className={`${mainClass}__option-one__info`}>
              <div className={`${mainClass}__option-one__info__left`}>
                <div className={`${mainClass}__option-one__info__left__title od-font-reg`}>
                  option 1
                </div>
                <div className={`${mainClass}__option-one__info__left__subTitle od-font-bold`}>
                  {customerMode
                    ? 'Place order after taking an eye test'
                    : 'Getting spectacles with the same power as the in-store purchase'}
                </div>
                <div className={`${mainClass}__option-one__info__left__description od-font-reg`}>
                  <div>
                    {customerMode
                      ? 'If you do not know your required lens power or have never made a purchase at OWNDAYS before, please visit an eye doctor to obtain a prescription.'
                      : 'Your prescription records from the physical stores will be automatically saved in your account.'}
                  </div>
                  {!customerMode && (
                    <div>
                      During checkout, please select the prescription record you would like to use.
                    </div>
                  )}
                </div>
              </div>
              {customerMode ? (
                <div className={`${mainClass}__option-one__info__right-new-customer`}>
                  <img
                    alt="option 1"
                    src="https://static.lenskart.com/media/owndays/desktop/img/owndays/dontKnowPower/dkp-option1-new-customer.webp"
                  />
                </div>
              ) : (
                <div className={`${mainClass}__option-one__info__right`}>
                  <img
                    alt="option 1"
                    src="https://static.lenskart.com/media/owndays/desktop/img/owndays/dontKnowPower/dont-know-power-option1.webp"
                  />
                </div>
              )}
            </div>
            <div className={`${mainClass}-separator`} />
            <div className={`${mainClass}__option-one__register-btn`}>
              <ButtonOwnDays
                fontClass="od-font-bold"
                fontSize="20px"
                mode="grey"
                px="0"
                py="30px"
                text="Register an OWNDAYS account"
                width="504px"
                onClick={displayAuthPopUp}
              />
              <div className={`${mainClass}__option-one__register-btn__description od-font-reg`}>
                Click here if you do not have an OWNDAYS account
              </div>
            </div>
          </div>
          <div
            className={`${mainClass}__option-two ${
              customerMode ? 'option-two-new-customer' : 'option-two-customer'
            }`}
          >
            <div className={`${mainClass}__option-two__info`}>
              <div className={`${mainClass}__option-two__title od-font-reg`}>option 2</div>
              <div className={`${mainClass}__option-two__subTitle od-font-bold`}>
                {customerMode
                  ? 'Place order with Lens Replacement Voucher to fitthe frame with prescription lenses at OWNDAYS store'
                  : 'Contact Customer Service Team via online chat, Facebook or hotline'}
              </div>
              {customerMode ? (
                <ul className={`${mainClass}__option-two__description-list od-font-reg`}>
                  <li>Select “Lens Replacement Voucher” when you place an order online.</li>
                  <li>
                    You will receive the spectacle frame and a voucher for a complimentary
                    replacement to standard high index aspheric lenses at our store.
                  </li>
                  <li>This option is recommended if you are placing an order as a gift.</li>
                </ul>
              ) : (
                <div className={`${mainClass}__option-two__description od-font-reg`}>
                  If you have purchased spectacles from our physical store previously, please
                  contact the Customer Service Team via online chat, Facebook or hotline to retrieve
                  your prescription record.You will be required to provide us with your name,
                  contact number and the store of purchase.
                </div>
              )}

              {!customerMode && (
                <>
                  {/* <div className={`${mainClass}__option-two__btns`}>
                    <ButtonOwnDays
                      fontClass="od-font-bold"
                      fontSize="20px"
                      mode="white-ternary"
                      px="0"
                      py="30px"
                      text="Contact us via online chat"
                      width="100%"
                      onClick={handleContactUsOnlineChatButton}
                    />
                    <ButtonOwnDays
                      fontClass="od-font-bold"
                      fontSize="20px"
                      mode="white-ternary"
                      px="0"
                      py="30px"
                      text="Contact us via facebook"
                      width="100%"
                      onClick={handleContactUsFacebookButton}
                    />
                  </div> */}
                  <ButtonOwnDays
                    fontClass="od-font-bold"
                    fontSize="20px"
                    mode="white-ternary"
                    px="120px"
                    py="30px"
                    style={{ marginTop: '30px' }}
                    width="100%"
                    onClick={handleContactUsButton}
                  >
                    <div className="od-contact-us-content">
                      <span>Contact us via hotline</span>
                      <div className="od-contact-us-content__separator" />
                      <span>{CONTACT_US_PHONE}</span>
                    </div>
                  </ButtonOwnDays>
                </>
              )}
            </div>
            {customerMode && (
              <img
                alt="frequency place order"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/dontKnowPower/frequency-howto-part4-img%201.webp"
              />
            )}
          </div>
        </div>
      </div>
      {showAuthPopUp &&
        (isNewAuthPopUpAvailable ? (
          <AuthModalNewLoadable
            allowSignUp
            handleModalClose={hideAuthPopUp}
            hideLogin={hideAuthPopUp}
            login={login}
            openTab="signin"
          />
        ) : (
          <AuthModal
            allowSignUp
            askUserLogin={askUserLogin}
            hideLogin={hideAuthPopUp}
            openTab="signin"
          />
        ))}
    </>
  );
};

export default DontKnowPowerPage;
