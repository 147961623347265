import React, { Fragment } from 'react';

import {
  HeaderOwndaysProgressiveCP,
  BodyOwndaysProgressiveCP,
  FooterOwndaysProgressiveCP,
} from '../CommonComponents/OwndaysProgressiveCP';

const OwndaysProgressiveCPHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/progressive-cp';

  return (
    <Fragment>
      <HeaderOwndaysProgressiveCP baseImgUrl={baseImgUrl} />
      <BodyOwndaysProgressiveCP baseImgUrl={baseImgUrl} />
      <FooterOwndaysProgressiveCP baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default OwndaysProgressiveCPHTML;
