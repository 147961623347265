import React from 'react';
import { Link } from 'react-router-dom';
import './SubMenuOD.scss';
import { setDataAttr } from './../../../../../utils/helper';
import { v4 } from 'uuid';
import SubMenuList from './SubMenuList';

const SubMenuOD = props => {
  const {
    submenu,
    updateNavValueDtm,
    section = '',
    subSection = '',
    categoryName = '',
    parentKey,
  } = props;

  const createSubMenu = (dataObj, key, section) => {
    const isKidsGlassesSection = section === 'Kids Glasses';
    const isImageType = dataObj.type === 'image';
    const isExternalUrl = dataObj.url && !dataObj.url.includes('lenskart.com');
    const dataAttr =
      dataObj.gaObj && Object.keys(dataObj.gaObj).length > 0 ? setDataAttr(dataObj.gaObj) : null;

    const imageTypeClass = isImageType ? 'nav-level-3 flex-img-grow' : 'nav-level-3 custom-width';
    const kidsGlassesClass = isKidsGlassesSection ? 'display-flex justify-content-center' : '';
    const customWidthClass = isKidsGlassesSection ? 'display-none' : '';

    let layoutClass = '';
    if (isImageType) {
      layoutClass = isKidsGlassesSection
        ? 'justify-space-evenly justify-content-end align-items-center'
        : 'flex-column align-items-start';
    }

    const linkProps = {
      ...dataAttr,
      className: `od-font-bold nav-head-category-submenu display-block ${
        dataObj.url ? '' : 'cursor-auto'
      }`,
      style: { display: `${section === 'Kids Glasses' ? 'none' : ''}` },
    };

    return (
      <div key={v4()} className={`${imageTypeClass} ${customWidthClass} ${kidsGlassesClass}`}>
        {isExternalUrl ? (
          <a {...linkProps} href={dataObj.url}>
            <span>{key}</span>
            {isExternalUrl && <i className="fa fa-angle-right hide"></i>}
          </a>
        ) : (
          <Link {...linkProps} to={dataObj.url}>
            <span>{key}</span>
          </Link>
        )}
        <div className={isImageType ? `layout ${layoutClass} ` : ''}>
          <SubMenuList
            key={key}
            categoryName={categoryName}
            data={dataObj}
            parentKey={parentKey}
            section={section}
            subSection={subSection}
            updateNavValueDtm={updateNavValueDtm}
          />
        </div>
      </div>
    );
  };

  const fetchSubMenu = submenu
    ? submenu.map(data => Object.keys(data).map(key => createSubMenu(data[key], key, section)))
    : [];

  return (
    <div
      className={
        parentKey === 'See All'
          ? 'nav-subcategory-level fixFromTopDiv'
          : `nav-subcategory-level count_${submenu && submenu.length}`
      }
    >
      {fetchSubMenu}
    </div>
  );
};

export default SubMenuOD;
