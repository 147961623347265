import { extractUtmSourceFromUrl } from '../../utils/helper';

export function userProperties(userInfo, pageName, platform, dataLocale) {
  const clientID = function () {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    const raw = match ? decodeURIComponent(match[1]) : null;
    if (raw) {
      match = raw.match(/(\d+\.\d+)$/);
    }
    return match ? match[1] : null;
  };
  const {
    utm_source = null,
    utm_medium = null,
    utm_campaign = null,
    utm_term = null,
    utm_content = null,
  } = extractUtmSourceFromUrl(window.location.search);
  if (utm_source) {
    localStorage.setItem(
      'utmSource',
      JSON.stringify({ utm_source, utm_medium, utm_campaign, utm_term, utm_content })
    );
  } else if (!localStorage.getItem('utmSource')) {
    localStorage.setItem(
      'utmSource',
      JSON.stringify({ utm_source, utm_medium, utm_campaign, utm_term, utm_content })
    );
  }
  const utmSource = JSON.parse(localStorage.getItem('utmSource'));
  const obj = {
    event: 'page_load',
    country_code: userInfo?.countryCode,
    currency_code: dataLocale?.CURRENCY_CODE,
    client_id: clientID(),
    platform,
    lk_user_type: userInfo?.hasPlacedOrder ? 'lk-repeat' : 'lk-new',
    login_status: userInfo?.login ? 'loggedin' : 'guest',
    customer_id: userInfo?.login ? userInfo?.id : '',
    mobile: userInfo?.telephone ? btoa(userInfo?.telephone) : '',
    email: userInfo?.email ? btoa(userInfo?.email) : '',
    page_url: window?.location?.href,
    page_name: pageName,
    web_utm_source: utmSource.utm_source || '(direct)',
    web_utm_medium: utmSource.utm_medium || '(none)',
    web_utm_campaign: utmSource.utm_campaign || '(direct)',
    web_utm_term: utmSource.utm_term || '',
    web_utm_content: utmSource.utm_content || '',
  };
  if (typeof window.dataLayer !== 'undefined') window.dataLayer.push(obj);
  else {
    setTimeout(() => {
      window?.dataLayer?.push(obj);
    }, 2000);
  }
}

export function ctaClickProperties(cta_name) {
  const obj = {
    event: 'cta_click',
    cta_flow_and_page: 'pc-exchange-options',
    cta_name,
  };
  if (typeof window.dataLayer !== 'undefined') window.dataLayer.push(obj);
  else {
    setTimeout(() => {
      window.dataLayer.push(obj);
    }, 2000);
  }
}
