import './panel.scss';
import React from 'react';

const Panel = props => {
  const { expanded, className } = props;
  return (
    <div className={`lkpanel ${className || ''}`} data-testid="panel">
      <div className={`collapse ${expanded ? 'in' : ''}`}>{props.children}</div>
    </div>
  );
};

export default Panel;
