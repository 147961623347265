import React from 'react';

import './TermsAndConditionsText.scss';

const TermsAndConditionsText = () => {
  return (
    <div className="terms-and-conditions-text">
      By completing the account signup, you consent to our{' '}
      <a className="terms-and-conditions-text__link" href="/sg/en/terms">
        terms & conditions
      </a>{' '}
      and the use of your personal data in accordance with our{' '}
      <a className="terms-and-conditions-text__link" href="/sg/en/privacy">
        privacy policy
      </a>
      .
    </div>
  );
};

export default TermsAndConditionsText;
