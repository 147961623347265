import React from 'react';
import GundamZakuheadBase from '../../../CommonComponents/GundamZakuhead/GundamZakuheadBase';
import './GundamZakuhead.scss';

const GundamZakuhead = () => {
  return (
    <>
      <GundamZakuheadBase className="l-gundamzakuhead" />
    </>
  );
};

export default GundamZakuhead;
