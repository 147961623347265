import React from 'react';
import './Header.scss';

const Header = ({ headerContent }) => {
  return (
    <div className="services__header">
      <div className="services__header-bg">
        <picture>
          <source media="(min-width:768px)" srcSet={headerContent.bgPc} />
          <img alt={headerContent.bgAlt} src={headerContent.bgSp} />
        </picture>
      </div>
      <div
        className={`services__header-content services__header-content--${headerContent.align} services__header-content--${headerContent.theme}`}
      >
        <h1 dangerouslySetInnerHTML={{ __html: headerContent.title }} />
        <p dangerouslySetInnerHTML={{ __html: headerContent.description }} />
      </div>
    </div>
  );
};

export default Header;
