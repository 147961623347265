import React, { Fragment } from 'react';

import { HeaderGiftTicket, BodyGiftTicket, FooterGiftTicket } from '../CommonComponents/GiftTicket';

const GiftTicketHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/gift-ticket';

  return (
    <Fragment>
      <HeaderGiftTicket baseImgUrl={baseImgUrl} />
      <BodyGiftTicket baseImgUrl={baseImgUrl} />
      <FooterGiftTicket baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default GiftTicketHTML;
