import React from 'react';

export const LENS_REPLACEMENT_IMG_OPTIONS = [
  { alt: 'eye-test', src: '/eye-test.svg', text: 'Eyesight\nMeasurement' },
  { alt: 'lens-edit', src: '/lens-edit.svg', text: 'Lens\nProcessing' },
  { alt: 'lens', src: '/lens.svg', text: 'Lens' },
  {
    alt: 'cleaning',
    src: '/cleaning.svg',
    text: 'Frame\nCleaning',
    additionalClass: 'body-lens-replacement__price__images__option--hide-plus',
  },
];

export const LENS_REPLACEMENT_STEPS = [
  {
    stepNumber: 'STEP1',
    title: 'Bring your glasses to the nearest OWNDAYS shop',
    description:
      'Bring your glasses to any OWNDAYS shop. The staff would first assess the condition of your glasses and recommend you the most suitable solution, which may include replacing the frame, cleaning the lenses, or adjusting the fitting.',
    additionalInfo: null,
  },
  {
    stepNumber: 'STEP2',
    title: 'Select Types of Lenses',
    description:
      'OWNDAYS offers a complimentary eye test. Besides the standard lenses, you can choose to upgrade to PC lenses, color lenses, or other option lenses.',
    additionalInfo: (
      <ul className="base-lens-replacement__list-remark">
        <li>
          Processing time of 7 working days is required for option lenses and lenses of certain
          prescriptions.
        </li>
        <li>Not available in online store.</li>
      </ul>
    ),
  },
  {
    stepNumber: 'STEP3',
    title: 'Collection',
    description:
      'You can collect your glasses in as little as 20 minutes after payment is made. You will also enjoy a 1-year warranty on lens visual performance under the OWNDAYS Customer Guarantees programme.',
    additionalInfo: (
      <p>
        <a href="/sg/en/services/warranty">Click here for Customer Guarantees</a>
      </p>
    ),
  },
];

export const LENS_REPLACEMENT_FAQ_ITEMS = [
  {
    question: 'Can you replace the lenses of the glasses from another shop?',
    answer:
      'Yes, we can replace them. However, we are unable to do replacement for some glasses due to the shape and parts as well as the condition of the frame. Please let our staff see the frame first.',
  },
  {
    question: 'Can you make lenses with the same prescription as the ones I’m currently using?',
    answer:
      'Yes, we can. Please bring your glasses or any record stating your strength to any of our shops.',
  },
  {
    question: 'What kind of lenses do you use?',
    answer:
      'All OWNDAYS lenses are produced by major Japanese manufacturers and have UV protection and anti-reflective coating.',
  },
  {
    question: 'Can you replace the lenses of rimless frames?',
    answer:
      'Yes, we can.However, as a rimless frame requires precision edging, it might take a few days to be processed.                        ',
  },
  {
    question: 'How long does it take to complete my glasses?',
    answer: `Your glasses can be ready in as little as 20 minutes when we have the lenses in stock at the shop. For some lenses such as color lenses and progressive lenses, it takes about 2 weeks since they're custom-made.`,
  },
  {
    question: `I don't think there is any charge for special processing for rimless frames?`,
    answer:
      'If special processing is required (such as for rimless frames), an additional cost may apply.',
  },
];

export const LENS_REPLACEMENT_OPTIONS = [
  {
    class: '',
    name: 'Lens High Index Aspheric',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+S$0', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67, 1.74',
    features: ['99% UV Protection', 'Anti-Reflection', 'Scratch Resistant', 'Dust-Repellent'],
  },
  {
    class: 'progressive',
    name: 'Enhanced Progressive Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: [],
  },
  {
    class: 'progressive',
    name: 'Prestige Progressive',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      { label: '+ S$200', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    additionalInfo: '^Upgrade to 1.74 lenses at additional S$100',
    features: [],
  },
  {
    class: 'progressive',
    name: 'Prestige Gold',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      { label: '+ S$400', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    additionalInfo: '^Upgrade to 1.74 lenses at additional S$100',
    features: [],
  },
  {
    class: 'progressive',
    name: 'Prestige Platinum',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      { label: '+ S$600', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    additionalInfo: '^Upgrade to 1.74 lenses at additional S$100',
    features: [],
  },
  {
    class: 'pc',
    name: 'Blue Light Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: ['Blue Light Reduction'],
  },
  {
    class: 'pc',
    name: 'PC420',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67, 1.74',
    features: [],
  },
  {
    class: 'transitions',
    name: 'Transitions® Light Intelligent Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$200', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: ['Lens colour changes according to the amount of UV'],
    notes: [
      'Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.',
    ],
  },
  {
    class: 'transitions',
    name: 'Transitions® XTRActive® New Generation',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$200', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: ['Lens colour changes according to the amount of UV'],
    notes: [
      'Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.',
    ],
  },
  {
    class: 'transitions',
    name: 'Transitions® XTRActive® Polarized™',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$300', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: ['Lens colour changes according to the amount of UV'],
    notes: [
      'Transitions is a registered trademark of Transitions Optical, Inc. used under license by Transitions Optical Limited.',
    ],
  },
  {
    class: 'polarised',
    name: 'Polarised Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60',
    features: ['UV Protection / Anti-Reflection'],
  },
  {
    class: 'color',
    name: 'Colour Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60',
    features: [],
    colors: ['Grey / Brown / Green / Blue'],
  },
  {
    class: 'antifog',
    name: 'Anti-Fog Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: [],
  },
  {
    class: '',
    name: 'Myopia Control Lenses',
    tags: [
      { label: 'With Prescription', class: 'body-lens-replacement__lens__tag--prescription' },
      {
        label: 'Without Prescription',
        class: 'body-lens-replacement__lens__tag--with-out-prescription',
      },
      { label: '+ S$100', class: 'body-lens-replacement__lens__tag--price' },
    ],
    refractiveIndex: '1.60, 1.67',
    features: [],
  },
];
