import * as actionTypes from '../../actionTypes/gupshupWhatsapp';

const initialState = {
  loading: false,
  isOptWhatsApp: false,
  isUserAction: false,
  error: null,
};

export default function gupshupWhatsapp(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.CHECK_CURRENT_WHATSAPP_OPTIN_STATUS:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        isOptWhatsApp: false,
        isUserAction: false,
        error: null,
      });
    case actionTypes.CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        isOptWhatsApp: action.result.result,
      });
    case actionTypes.CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        isOptWhatsApp: false,
      });
    case actionTypes.SET_WHATSAPP_OPTIN_STATUS:
      return Object.assign({}, prevState, {
        isUserAction: action.isUserAction,
        error: null,
      });
    case actionTypes.SET_WHATSAPP_OPTIN_STATUS_SUCCESS:
      return Object.assign({}, prevState, {
        isOptWhatsApp: action.data.optin,
      });
    case actionTypes.SET_WHATSAPP_OPTIN_STATUS_FAIL:
      return Object.assign({}, prevState, {
        error: action.error,
      });
    default:
      return prevState;
  }
}
