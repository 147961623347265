import * as actionTypes from '../actionTypes/header';

// export function getTopNavDataFromLocal() {
//     return { type: actionTypes.LOAD_NAVIGATION_LOCAL };
// }

export function getMainNav() {
  return { type: actionTypes.LOAD };
}

export function getMobileNavigationData() {
  return { type: actionTypes.GET_MSITE_NAV };
}

export function rerenderMenu(value) {
  return { type: actionTypes.MENU_RENDER, value };
}

export function showGlobalHeaderFooter(value) {
  return { type: actionTypes.SHOW_GLOBAL_HEADER_FOOTER, value };
}

export function setAppDownloadHeaderState(show) {
  return { type: actionTypes.APP_DOWNLOAD_HEADER_SHOW, show };
}
