import React from 'react';
import { isRelativeUrl, isObject } from '../../utils/helper';
import { Link } from 'react-router-dom';

const LinkProvider = props => {
  const { to: link, children } = props;
  if (!link) {
    return <div {...props}> {children} </div>;
  }
  let isValidLinkObj = false;
  if (link) {
    isValidLinkObj = isObject(link) || isRelativeUrl(link);
  }
  if (
    typeof link.indexOf === 'function' &&
    (link?.indexOf('/home-try-on/phone') > -1 || link?.indexOf('tel:') > -1)
  ) {
    isValidLinkObj = false;
  }
  return (
    <React.Fragment>
      {isValidLinkObj ? (
        <Link {...props}>{children}</Link>
      ) : (
        <a href={link} {...props}>
          {children}
        </a>
      )}
    </React.Fragment>
  );
};

export default LinkProvider;
