import React from 'react';
import './AboutReferral.scss';

import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import ReferralCard from '../../../../../static/assets/img/referral-card.png';

import { connect, useDispatch, useSelector } from 'react-redux';
import ClipboardJS from 'clipboard';
import { setToastMessage } from '../../../../actionCreators/common';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const AboutReferral = props => {
  const { dataLocale } = props;

  const {
    ABOUT_REFERRAL_TITLE,
    ABOUT_REFERRAL_CTA_INFO_1,
    ABOUT_REFERRAL_CTA_INFO_2,
    ABOUT_REFERRAL_FRIENDS_BENEFIT,
    ABOUT_REFERRAL_FRIENDS_BENEFIT_CONDITION_1,
    ABOUT_REFERRAL_YOUR_BENEFIT,
    ABOUT_REFERRAL_YOUR_BENEFIT_CONDITION_1,
    ABOUT_REFERRAL_YOUR_BENEFIT_CONDITION_2,
    ABOUT_REFERRAL_CONDITION_1,
    ABOUT_REFERRAL_CONDITION_2,
    ABOUT_REFERRAL_CONDITION_3,
    ABOUT_REFERRAL_CONDITION_4,
    ABOUT_REFERRAL_CONDITION_5,
    SEND_REFERRAL_CODE,
    REFERRAL_CODE_WAS_COPIED,
    YOUR_FRIEND,
    YOU,
  } = dataLocale;

  const userInfo = useSelector(state => state.common.userInfo?.result);
  const dispatch = useDispatch();

  const handleCopyClick = e => {
    if (userInfo?.inviteCode) {
      const clipboard = new ClipboardJS(e.target, {
        text: () => userInfo?.inviteCode,
      });

      clipboard.on('success', () => {
        dispatch(setToastMessage({ message: REFERRAL_CODE_WAS_COPIED, timeout: 3000 }));
        clipboard.destroy();
      });

      clipboard.on('error', e => {
        console.error('ref code copy error', e.action);
      });

      clipboard.onClick(e);
    }
  };

  return (
    <main className="about-referral main-content-wrapper">
      <MetaTagsManager
        description="Referral Invite code"
        title="Referral Invite code | OWNDAYS Singapore"
      />
      <h1 className="about-referral__title od-font-bold">{ABOUT_REFERRAL_TITLE}</h1>

      <section className="about-referral-hero">
        <div>
          <p className="about-referral-hero__subtitle od-font-reg">{ABOUT_REFERRAL_CTA_INFO_1}</p>
          <p className="about-referral-hero__title od-font-reg">{SEND_REFERRAL_CODE}</p>
          <p className="about-referral-hero__subtitle od-font-reg">{ABOUT_REFERRAL_CTA_INFO_2}</p>
          <ButtonOwnDays
            classes="about-referral-hero__button"
            fontClass="od-font-reg"
            mode="black"
            px="15"
            py="50"
            onClick={handleCopyClick}
          >
            {userInfo?.inviteCode}
          </ButtonOwnDays>
        </div>
        <img alt={SEND_REFERRAL_CODE} src={ReferralCard} />
      </section>

      <section className="about-referral-benefits">
        <div className="about-referral-benefits__block">
          <p className="about-referral-benefits__title od-font-reg">
            <span className="about-referral-benefits__beneficiary od-font-bold">
              {YOUR_FRIEND}:
            </span>{' '}
            {ABOUT_REFERRAL_FRIENDS_BENEFIT}
          </p>
          <ul className="about-referral-benefits__list">
            <li className="about-referral-benefits__benefit od-font-reg">
              {ABOUT_REFERRAL_FRIENDS_BENEFIT_CONDITION_1}
            </li>
          </ul>
        </div>
        <div className="about-referral-benefits__block">
          <p className="about-referral-benefits__title od-font-reg">
            <span className="about-referral-benefits__beneficiary od-font-bold">{YOU}:</span>{' '}
            {ABOUT_REFERRAL_YOUR_BENEFIT}
          </p>
          <ul className="about-referral-benefits__list">
            <li className="about-referral-benefits__benefit od-font-reg">
              {ABOUT_REFERRAL_YOUR_BENEFIT_CONDITION_1}
            </li>
            <br />
            <li className="about-referral-benefits__benefit od-font-reg">
              {ABOUT_REFERRAL_YOUR_BENEFIT_CONDITION_2}
            </li>
          </ul>
        </div>
      </section>

      <section className="about-referral-conditions">
        <ul className="about-referral-conditions__list">
          <li className="about-referral-conditions__condition od-font-reg">
            {ABOUT_REFERRAL_CONDITION_1}
          </li>
          <br />
          <li className="about-referral-conditions__condition od-font-reg">
            {ABOUT_REFERRAL_CONDITION_2}
          </li>
          <br />
          <li className="about-referral-conditions__condition od-font-reg">
            {ABOUT_REFERRAL_CONDITION_3}
          </li>
          <br />
          <li className="about-referral-conditions__condition od-font-reg">
            {ABOUT_REFERRAL_CONDITION_4}
          </li>
          <br />
          <li className="about-referral-conditions__condition od-font-reg">
            {ABOUT_REFERRAL_CONDITION_5}
          </li>
        </ul>
      </section>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutReferral);
