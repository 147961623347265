// export const LOAD_NAVIGATION_LOCAL = 'header/LOAD_NAVIGATION_LOCAL';
export const LOAD_NAVIGATION = 'header/LOAD_NAVIGATION';
export const LOAD = 'header/LOAD';
export const FAIL_NAVIGATION = 'header/FAIL_NAVIGATION';
export const UPDATE_MAIN_NAVIGATION_LOCAL = 'header/UPDATE_MAIN_NAVIGATION_LOCAL';
export const MENU_RENDER = 'header/MENU_RENDER';
export const SHOW_GLOBAL_HEADER_FOOTER = 'header/SHOW_GLOBAL_HEADER_FOOTER';

// msite navigation
export const GET_MSITE_NAV = 'header/GET_MSITE_NAV';
export const GET_MSITE_NAV_SUCCESS = 'header/GET_MSITE_NAV_SUCCESS';
export const GET_MSITE_NAV_FAIL = 'header/GET_MSITE_NAV_FAIL';

export const APP_DOWNLOAD_HEADER_SHOW = 'header/APP_DOWNLOAD_HEADER_SHOW';
