export const ADD_DITTO_LOAD = 'ditto/ADD_DITTO_LOAD';
export const ADD_DITTO_SUCCESS = 'ditto/ADD_DITTO_SUCCESS';
export const ADD_DITTO_FAIL = 'ditto/ADD_DITTO_FAIL';

export const SAVE_UPDATE_DITTO_LOAD = 'ditto/SAVE_UPDATE_DITTO_LOAD';
export const SAVE_UPDATE_DITTO_SUCCESS = 'ditto/SAVE_UPDATE_DITTO_SUCCESS';
export const SAVE_UPDATE_DITTO_FAIL = 'ditto/SAVE_UPDATE_DITTO_FAIL';

export const UPLOAD_IMAGE_TO_CYGNUS_LOAD = 'ditto/UPLOAD_IMAGE_TO_CYGNUS_LOAD';
export const UPLOAD_IMAGE_TO_CYGNUS_SUCCESS = 'ditto/UPLOAD_IMAGE_TO_CYGNUS_SUCCESS';
export const UPLOAD_IMAGE_TO_CYGNUS_FAIL = 'ditto/UPLOAD_IMAGE_TO_CYGNUS_FAIL';

export const GET_IMAGE_FROM_CYGNUS_LOAD = 'ditto/GET_IMAGE_FROM_CYGNUS_LOAD';
export const GET_IMAGE_FROM_CYGNUS_SUCCESS = 'ditto/GET_IMAGE_FROM_CYGNUS_SUCCESS';
export const GET_IMAGE_FROM_CYGNUS_FAIL = 'ditto/GET_IMAGE_FROM_CYGNUS_FAIL';

export const UPDATE_CUSTOMER_CYGNUS_LOAD = 'ditto/UPDATE_CUSTOMER_CYGNUS_LOAD';
export const UPDATE_CUSTOMER_CYGNUS_SUCCESS = 'ditto/UPDATE_CUSTOMER_CYGNUS_SUCCESS';
export const UPDATE_CUSTOMER_CYGNUS_FAIL = 'ditto/UPDATE_CUSTOMER_CYGNUS_FAIL';
