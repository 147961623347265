import React, { useEffect, useState } from 'react';
import Main from './Main';
import Concept from './Concept';
import Feature from './Feature';
import Scene from './Scene';
import Lineup from './Lineup';
import Spec from './Spec';
import Attention from './Attention';
import Stores from './Stores';
import Sns from './Sns';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Huawei2Base = ({ className }) => {
  const baseUrl = 'https://www.owndays.com/sg/en/';
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/huawei2/';
  const currentUrl = window.location.href;
  const pageName = currentUrl.split('/').pop();
  const [isSP, setIsSP] = useState(window.innerWidth < 768);

  const handleSmoothScroll = e => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute('href').split('#')[1];
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const headerPanelElement = document.querySelector('.od-header__panel');
      const headerElement = document.getElementById('header');
      const offsetHeight = headerPanelElement
        ? headerPanelElement.offsetHeight
        : headerElement
        ? headerElement.offsetHeight
        : 0;

      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offsetHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  document.body.classList.add('body-huawei2');

  useEffect(() => {
    const addScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = onLoad;
      document.head.appendChild(script);
      return script;
    };

    const addStylesheet = href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
      return link;
    };

    const scripts = [
      { src: 'https://cdn-script.com/ajax/libs/jquery/3.7.1/jquery.js', onLoad: null },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.7/swiper-bundle.min.js',
        onLoad: null,
      },
    ];

    const stylesheets = [
      'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.7/swiper-bundle.css',
      'https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.css',
    ];

    const scriptElements = scripts.map(({ src, onLoad }) => addScript(src, onLoad));
    const styleElements = stylesheets.map(href => addStylesheet(href));

    const initializeAOS = () => {
      AOS.init({
        duration: 1000,
        once: true,
      });
    };
    initializeAOS();

    const handleLoad = () => {
      const scriptElementAfterLoad = document.createElement('script');
      scriptElementAfterLoad.src =
        'https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.min.js';
      document.body.appendChild(scriptElementAfterLoad);

      document
        .querySelectorAll('img')
        .forEach(img => img.addEventListener('load', () => AOS.refresh()));
    };
    window.addEventListener('load', handleLoad);
    function getBodyWidth() {
      document.documentElement.style.setProperty('--body-w', document.body.clientWidth + 'px');
    }
    getBodyWidth();
    const handleResize = () => {
      setIsSP(window.innerWidth < 768);
      getBodyWidth();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('load', handleLoad);
      window.removeEventListener('resize', handleResize);
      scriptElements.forEach(script => document.head.removeChild(script));
      styleElements.forEach(link => document.head.removeChild(link));
    };
  }, []);
  return (
    <>
      <div className={className}>
        <Main baseImgUrl={baseImgUrl} isSP={isSP} />
        <Concept baseImgUrl={baseImgUrl} onSmoothScroll={handleSmoothScroll} />
        <Feature baseImgUrl={baseImgUrl} isSP={isSP} />
        <Scene baseImgUrl={baseImgUrl} isSP={isSP} />
        <Lineup baseImgUrl={baseImgUrl} />
        <Spec baseImgUrl={baseImgUrl} isSP={isSP} />
        <Attention />
        <Stores />
        <Sns baseImgUrl={baseImgUrl} baseUrl={baseUrl} pageName={pageName} />
      </div>
    </>
  );
};

export default Huawei2Base;
