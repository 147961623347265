import React, { Fragment } from 'react';

import {
  HeaderOwndaysRevampsBrand,
  BodyOwndaysRevampsBrand,
  FooterOwndaysRevampsBrand,
} from '../CommonComponents/OwndaysRevampsBrand';

const OwndaysRevampsBrandHTML = () => {
  return (
    <Fragment>
      <HeaderOwndaysRevampsBrand />
      <BodyOwndaysRevampsBrand />
      <FooterOwndaysRevampsBrand />
    </Fragment>
  );
};

export default OwndaysRevampsBrandHTML;
