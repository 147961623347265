import React from 'react';
import Shimmer from '../Shimmer';

const PaymentOrderSummaryShimmer = () => {
  return (
    <div style={{ width: '100%' }}>
      <Shimmer line height={30} style={{ marginBottom: 30, maxWidth: 150 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={3} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={3} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 35 }} />
    </div>
  );
};

export default PaymentOrderSummaryShimmer;
