import React, { useEffect, useState, useRef } from 'react';

import SearchBarStatic from './SearchBarStatic/SearchBarStatic';
import SearchBarNascent from './SearchBarNascent/SearchBarNascent';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as searchActions from '../../../actionCreators/search';
import Customevent from '../../../utils/Customevent';

const SearchBar = props => {
  const {
    searchActions,
    variant = 'nascent',
    classes,
    placeholder = '',
    history,
    secondaryNav,
    location,
    FilterDataObj,
    filterData,
    algoliaSearchData,
    setShowBookEyeTest,
    triggerDataLayerOnCtaClick,
  } = props;

  const [focusedData, setFocusedData] = useState({});
  const [showTrending, setShowTrending] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setShowBookEyeTest(showInput);
  }, [setShowBookEyeTest, showInput]);

  const inputRef = useRef(null);

  // const popularProducts = [];
  const keywordSuggestions = [];

  if (algoliaSearchData) {
    // const updatedProductRecomendation = searchData.productRecommendations.map((item, index) => {
    //   item.uniqueId = `product${index}`;
    //   return item;
    // });

    // searchData.typeAhead.forEach((item, index) => {
    //   keywordSuggestions.push({
    //     name: item,
    //     uniqueId: `type${index}`,
    //   });
    // });

    algoliaSearchData.hits.forEach((item, index) => {
      keywordSuggestions.push({
        name: item.objectID,
        uniqueId: `type${index}`,
        objectID: item.objectID,
        query: item.query,
      });
    });

    // popularProducts = [...updatedProductRecomendation];
  }

  // const modifiedSearchResult = [].concat(keywordSuggestions, popularProducts);
  const modifiedSearchResult = keywordSuggestions;

  const getSearchResult = data => {
    const searchValue = typeof data === 'object' ? data.objectID : data;
    const input = searchValue;

    if (typeof data === 'object' && data) {
      setSearchInputValue(data.query.trim());
      searchActions.suggestionClick(data);
    } else {
      setTimeout(() => {
        if (searchValue.length) {
          const redirectPath = `/search?q=${input}${
            location.pathname === '/search' && location.hash.length > 1 ? location.hash : ''
          }`;

          window.ReactPixel.default.track('Search', {
            content_type: 'product',
            search_string: searchValue,
            success: 1,
          });

          if (window.dtm.LenskartRewamp) {
            window.dtm.LenskartRewamp.searchpage.click.searchSuggestion(input);
          }

          _gaq.push(
            [
              '_trackEvent',
              'Header',
              'search',
              `page: ${document.location.href}${document.location.href}`,
            ],
            [
              'ninja._trackEvent',
              'Header',
              'search',
              `page: ${document.location.href}${document.location.href}`,
            ]
          );

          setShowTrending(false);
          setShowSearchResult(false);

          history.push(redirectPath);
        }
      }, 300);
    }
  };

  const handleKeyUp = event => {
    if (event.target.value.length === 0) {
      setShowTrending(true);
      setShowSearchResult(false);
      setSearchInputValue(event.target.value.trim());
    } else if (
      event.target.value.length > 0 &&
      event.target.value !== searchInputValue &&
      !Object.keys(focusedData).length
    ) {
      setShowTrending(false);
      setShowSearchResult(true);
      setSearchInputValue(event.target.value.trim());
      searchActions.searchInputChange(event.target.value);
    }
  };

  const showAllResults = input => {
    if (window.dtm.LenskartRewamp) {
      window.dtm.LenskartRewamp.searchpage.click.searchSuggestion(input);
    }

    setFocusedData({});
    getSearchResult(input);
  };

  const handleKeyDown = event => {
    if (event.which === 38) {
      if (focusedData?.uniqueId) {
        const currentIndex = modifiedSearchResult.findIndex(
          data => data.uniqueId === this?.focusedData?.uniqueId
        );
        const nextSelection = modifiedSearchResult[currentIndex - 1] || modifiedSearchResult[0];

        document
          .getElementById(modifiedSearchResult[currentIndex].uniqueId)
          .classList.remove('hover');
        document.getElementById(nextSelection.uniqueId).classList.add('hover');
        setFocusedData(nextSelection);
      } else {
        setFocusedData(modifiedSearchResult[0]);
        document.getElementById(focusedData.name).classList.add('hover');
      }

      event.target.value = focusedData.name.trim();
    }

    if (event.which === 40) {
      if (focusedData?.uniqueId) {
        const currentIndex = modifiedSearchResult.findIndex(
          data => data.uniqueId === focusedData?.uniqueId
        );
        const nextSelection = modifiedSearchResult[currentIndex + 1] || modifiedSearchResult[0];

        document
          .getElementById(modifiedSearchResult[currentIndex].uniqueId)
          .classList.remove('hover');
        document.getElementById(nextSelection.uniqueId).classList.add('hover');
        setFocusedData(nextSelection);
      } else {
        setFocusedData(modifiedSearchResult[0]);
        document.getElementById(focusedData?.uniqueId).classList.add('hover');
      }

      event.target.value = focusedData.name.trim();
    }

    if (event.which === 13) {
      event.preventDefault();

      const input = event.target.value.trim();

      showAllResults(input);
    }

    if (
      event.which === 220 ||
      event.which === 219 ||
      event.which === 221 ||
      event.key === '(' ||
      event.key === ')' ||
      event.key === '?' ||
      event.key === '%'
    ) {
      event.preventDefault();
    }
  };

  const handleFocus = () => {
    setTimeout(() => {
      setShowTrending(true);
      setShowSearchResult(false);
      setFocusedData({});
    }, 200);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowTrending(false);
      setShowSearchResult(false);
      setFocusedData({});
      setShowInput(false);
    }, 200);
  };

  const displayInput = () => {
    setShowInput(true);

    triggerDataLayerOnCtaClick('Search');

    setTimeout(() => {
      inputRef.current.focus();
    });
  };

  useEffect(() => {
    window.callback = response => {
      const event = new Customevent('SEARCH_CALLBACK', { detail: { response } });
      event.dispatch();
    };
  }, []);

  useEffect(() => {
    if (searchInputValue) {
      // OD__CLEVERTAP__EVENT (Search Click)
      // if (typeof window.clevertap !== 'undefined') {
      //   window.clevertap.event.push('Search Click', {
      //     searchName: searchInputValue,
      //   });
      //   console.log(
      //     `🚀 ~ Search Click ~ searchInputValue: ${searchInputValue}:`,
      //     window.clevertap.event
      //   );
      // }
      // odPushClevertapEvent('Search', {
      //   searchQuery: searchInputValue,
      // });
    }
  }, [searchInputValue]);

  if (variant === 'static')
    return (
      <SearchBarStatic
        FilterDataObj={FilterDataObj}
        classes={classes}
        displayInput={displayInput}
        filterData={filterData}
        getSearchResult={getSearchResult}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        handleKeyDown={handleKeyDown}
        handleKeyUp={handleKeyUp}
        inputRef={inputRef}
        location={location}
        placeholder={placeholder}
        showAllResults={() => showAllResults(inputRef.current.value)}
        showSearchResult={showSearchResult}
        showTrending={showTrending}
      />
    );

  return (
    <SearchBarNascent
      classes={classes}
      displayInput={displayInput}
      getSearchResult={getSearchResult}
      handleBlur={handleBlur}
      handleFocus={handleFocus}
      handleKeyDown={handleKeyDown}
      handleKeyUp={handleKeyUp}
      inputRef={inputRef}
      placeholder={placeholder}
      secondaryNav={secondaryNav}
      showAllResults={() => showAllResults(inputRef.current.value)}
      showInput={showInput}
      showSearchResult={showSearchResult}
      showTrending={showTrending}
    />
  );
};

const mapStateToProps = state => ({
  algoliaSearchData: state.search.algoliaSearchData,
  dataLocale: state.locale,
  localeInfo: state.common.localeInfo,
  location: state.router.location,
  FilterDataObj: state.category.FilterDataObj,
  filterData: state.category.filterData,
});

function mapDispatchToProps(dispatch) {
  return {
    searchActions: bindActionCreators(searchActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
