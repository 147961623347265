import React from 'react';

import './FooterAiruitem.scss';

export const FooterAiruitem = () => {
  return (
    <div className="footer-airuitem">
      <div className="base-airuitem__container">
        <div className="footer-airuitem__actions">
          <div data-aos="fade-up">
            <a
              className="base-airuitem__btn base-airuitem__btn--black"
              href="/sg/en/eyeglasses/brands/AIRUltem.html"
            >
              See All AIR Ultem Products
            </a>
          </div>
          <div data-aos="fade-up" data-aos-delay="300">
            <a className="base-airuitem__btn base-airuitem__btn--black" href="/stores/sg">
              Search For Nearest OWNDAYS Stores
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
