import React, { Fragment, useState, useEffect } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderNews.scss';

export const HeaderNews = ({ baseImgUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mainClassName, setMainClassName] = useState('header-news');
  const [textClassName, setTextClassName] = useState('');

  const breadCrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
  ];

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setIsLoading(false);
        setMainClassName('header-news header-news--active');

        setTimeout(() => {
          setTextClassName('header-news__title-text header-news__title-text--active');
        }, 200);
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
    return null;
  }, [isLoading]);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadCrumbs} />

      <section className={mainClassName}>
        <div className="header-news__bg">
          <picture>
            <source media="(min-width:1296px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}/main-tb.webp`} />
            <img alt="main-news" src={`${baseImgUrl}/main-sp.webp`} />
          </picture>
        </div>

        <div className="header-news__title">
          <h1 className={textClassName}>NEWS</h1>
          <span className="header-news__line-curve"></span>
        </div>
      </section>
    </Fragment>
  );
};
