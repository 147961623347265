import React from 'react';

export const Breadcrumbs = () => {
  return (
    <div className="od-c-breadcrumb">
      <ul>
        <li>
          <a href="/sg/en">Homepage</a>
        </li>

        <li>
          <a href="/sg/en/information">See all the Information</a>
        </li>

        <li>
          <a href="#">
            OWNDAYS REVAMPS BRAND IDENTITY, DEBUTING NEW RETAIL DESIGN IN JAPAN AND SINGAPORE THIS
            JULY
          </a>
        </li>
      </ul>
    </div>
  );
};
