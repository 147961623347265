import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/qrCode';
import apiClient from '../helpers/apiClient';
import config from 'config';

let _client = null;

function* getQRScanStatus({ orderId }) {
  const successAction = { type: actionTypes.GET_QR_SCAN_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.GET_QR_SCAN_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.qrPaymentsApi}${orderId}/qr/status`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* generateQRCode({ orderId }) {
  const successAction = { type: actionTypes.GET_QR_CODE_SUCCESS };
  const errorAction = { type: actionTypes.GET_QR_CODE_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.post(`${config.apiPath.qrPaymentsApi}${orderId}/qr/generate`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

export function* runQRCodeCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_QR_SCAN_STATUS_LOAD, getQRScanStatus),
    takeLatest(actionTypes.GET_QR_CODE_LOAD, generateQRCode),
  ]);
}
