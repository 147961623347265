import { isEmptyObject } from '../utils/helper';
import getSetCookie from '../cookie';

export function modifyProductData(data) {
  /* eslint one-var-declaration-per-line: 0 */
  /* eslint one-var: 0 */
  let dtmPType,
    generalSpecification,
    newFrameInfoObj = [],
    technicalSpecification,
    frameType,
    frameShape,
    clType,
    frameColor,
    frameSize,
    gender,
    marketPrice,
    lenskartPrice,
    firstFrameFree,
    firstFrameFreePrice,
    firstFrameFreeDLPrice,
    powerOption,
    productLensTypeDesc,
    size,
    measure,
    productDetailsImages,
    suitedFor;
  const {
    specifications,
    type,
    classification,
    prices,
    prescriptionType,
    fullName,
    frameDetails,
    imageUrlsDetail = [],
    review = {},
  } = data;
  let { reviews = [], imageReviews = [] } = review;
  // Fixing URIMalformed issue
  if (reviews.length) {
    reviews.map(rev => {
      if (rev)
        Object.keys(rev).map(key => {
          rev[key] = encodeURIComponent(rev[key]);
          return true;
        });
      return true;
    });
  }
  if (imageReviews.length) {
    imageReviews.map(rev => {
      if (rev)
        Object.keys(rev).map(key => {
          rev[key] = encodeURIComponent(rev[key]);
          return true;
        });
      return true;
    });
  }
  // Set pType
  const pCat =
    __CLIENT__ && getSetCookie.getCookie('product_category') === 'power_sunglasses'
      ? 'Power Sunglasses'
      : type;
  dtmPType = pCat ? pCat.toLowerCase() : '';
  if (dtmPType === 'contact lens') {
    dtmPType = 'contact-lenses';
  }

  // Set Specifications
  specifications.filter(obj => {
    if (obj.name === 'general') {
      generalSpecification = Object.assign([], obj.items);
    }
    if (obj.name === 'technical') {
      technicalSpecification = Object.assign([], obj.items);
    }
    return true;
  });
  technicalSpecification.forEach(obj => {
    if (obj.name === 'Frame Type') {
      /* Frame Type for buy Options API */
      frameType = obj.value;
    }
    if (obj.name === 'Frame Shape') {
      frameShape = obj.value ? obj.value : '';
    }
    if (obj.name === 'Type') {
      clType = classification === 'contact_lens' ? obj.value : null;
    }
  });
  for (let i = 0; i < generalSpecification.length; i += 1) {
    const obj = generalSpecification[i];
    if (obj.name === 'Frame colour') {
      frameColor = obj.value;
    }
    if (obj.name === 'Frame Size') {
      frameSize = obj.value;
    }
    if (obj.name === 'Suited For') {
      suitedFor = obj.value;
    }
    if (obj.name === 'Gender') {
      gender = obj.value;
    }
    if (
      obj.name === 'Model No.' ||
      obj.name === 'Frame Material' ||
      obj.name === 'Collection' ||
      obj.name === 'Temple Material' ||
      obj.name === 'Weight'
    ) {
      newFrameInfoObj.push(obj);
      generalSpecification.splice(i, 1);
      i -= 1;
    }
  }
  generalSpecification = [...newFrameInfoObj, ...generalSpecification];

  // Set Prices
  const firstFrameFreeObj = prices.find(price => price.name === 'First Buy Price');
  if (firstFrameFreeObj && !isEmptyObject(firstFrameFreeObj)) {
    firstFrameFree = true;
    firstFrameFreePrice = firstFrameFreeObj.price;
    firstFrameFreeDLPrice = 1000;
  }
  /* eslint prefer-const: 0 */
  marketPrice = prices[0].price;
  /* eslint prefer-const: 0 */
  lenskartPrice = prices[1].price;
  /* Power Option And productLensTypeDesc */
  if (classification === 'eyeframe') {
    if (prescriptionType.length) {
      powerOption = prescriptionType[0].title + '';
    }
    if (fullName?.indexOf('Computer') > -1) {
      if (prescriptionType.length) {
        powerOption = 'Zero Power';
      }
    } else if (fullName?.indexOf('Swimming Goggles') > -1 || fullName?.indexOf('Reading') > -1) {
      powerOption = 'Frame Only';
    }
    productLensTypeDesc = {
      'Single Vision': {
        desc: 'For distance or near vision <br/>(Thin, anti-glare, blue-cut options)',
        image: true,
      },
      'Bifocal/Progressive': {
        desc: 'Bifocal and Progressives <br/>(For two powers in same lens)',
        image: true,
      },
      'Zero Power': {
        desc: 'For computer smartphones & fashion <br/>(Anti-glare and blue-cut options)',
        image: true,
      },
      'Frame Only': { desc: 'Buy only the frame without any lenses', icon: 'icon-without_power' },
      'Tinted Single Vision': {
        desc: '(Photochromatic, Grey, Brown, Dark Green Color Options)<br/>Available in Single Vision Only',
        image: true,
      },
    };
  } else if (classification === 'sunglasses') {
    powerOption = 'Without Power';
    if (
      __CLIENT__ &&
      prescriptionType.length > 1 &&
      getSetCookie.getCookie('product_category') === 'power_sunglasses'
    ) {
      powerOption = 'With Power';
    }
    productLensTypeDesc = {
      'With Power': {
        desc: 'Buy powered sunglasses with either distance (far) or reading (near) vision',
        icon: 'icon-power_sunglasses',
      },
      'Without Power': { desc: 'Buy a non-power sunglasses', icon: 'icon-without_power' },
    };
  }
  /* Frame Size Details */
  frameDetails.forEach(obj => {
    if (obj.name === 'Size') {
      size = obj.value;
    }
    if (obj.name === 'MEASURE') {
      measure = obj.value;
    }
  });
  productDetailsImages = imageUrlsDetail
    .filter(img => img.label?.indexOf('Image_About_Product') > -1)
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });

  return {
    dtmPType,
    generalSpecification,
    newFrameInfoObj,
    technicalSpecification,
    frameType,
    frameShape,
    clType,
    frameColor,
    frameSize,
    suitedFor,
    gender,
    marketPrice,
    lenskartPrice,
    firstFrameFree,
    firstFrameFreePrice,
    firstFrameFreeDLPrice,
    powerOption,
    productLensTypeDesc,
    size,
    measure,
    productDetailsImages,
  };
}

export function setProductStaticDTM(data, modifiedProductData, isExchange) {
  const { id, brandName, colorOptions, relatedItems, fullName } = data;
  const {
    dtmPType,
    frameColor,
    frameType,
    gender,
    frameShape,
    frameSize,
    lenskartPrice,
    firstFrameFree,
  } = modifiedProductData;
  const staticDTM = {
    pageType: 'pdp page',
    pageName: `web|${dtmPType}|pdp page`,
    channel: 'web|pdp page',
    subSection1: `web|pdp page|${dtmPType}`,
    subSection2: `web|pdp page|${dtmPType}${isExchange ? '-exchange product' : ''}`,
    category: dtmPType,
    subcategory: dtmPType,
    productID: id,
    brand: brandName,
    framecolor: frameColor,
    frametype: frameType,
    gender,
    frameshape: frameShape,
    framesize: frameSize,
    colorOptions: colorOptions && colorOptions.length ? 'yes' : 'no',
    similarOptions: relatedItems && relatedItems.length ? 'yes' : 'no',
    lenskartPrice,
    discountPrice: firstFrameFree ? lenskartPrice : 0,
    productName: fullName,
  };
  return staticDTM;
}

// export function modifyReturnEligibiltyForMock(order) {
//     const { result: orderDetails } = order;
//     const { id, items } = orderDetails;
//     const itemReturnEligibilityArr = [];
//     let count = 0;
//     items.forEach(item => {
//         if (item.classification === 'eyeframe' || item.classification === 'sunglasses') {
//             itemReturnEligibilityArr.push({
//                 id: item.id,
//                 returnable: Math.random() < 0.5,
//                 isExchangeable: Math.random() < 0.5,
//                 isRefundable: Math.random() < 0.5,
//                 refundMethods: Math.random() < 0.5 ? [{ name: 'storecredit', enabled: true }, { name: 'cashfree', enabled: true }, { name: 'source', enabled: true }, { name: 'neft', enabled: false }, { name: 'customer_wallet', enabled: true }, { name: 'multipleRefund', enabled: true }] : [{ name: 'storecredit', enabled: true }, { name: 'cashfree', enabled: true }, { name: 'source', enabled: true }, { name: 'neft', enabled: false }, { name: 'customer_wallet', enabled: false }, { name: 'multipleRefund', enabled: false }],
//                 refundAmount: {
//                     amountToRefund: item.amount.total - count * 300,
//                     lkCashAmountToRefund: count * 100,
//                     lkCashPlusAmountToRefund: (count + 1) * 100,
//                     currencyCode: 'INR',
//                     itemPrice: item.amount.total,
//                     itemRefundedAmount: 0
//                 },
//                 exchangeMethods: [{ name: 'SAMEPRODUCT', enabled: true }, { name: 'CHANGELENS', enabled: true }, { name: 'NEWPRODUCT', enabled: true }],
//                 returnEligibleTillDate: item.createdAt + (25 * 24 * 3600000),
//                 refundMethodRequest: '' // intent
//             });
//             count++;
//         }
//     });
//     return { result: { id, isFraud: Math.random() < 0.5, returnable: Math.random() < 0.5, items: itemReturnEligibilityArr } };
// }

// isExchangeable = true/false, isRefundable = true/false, returnEligibleTillDate < / > currDate
// returnable flag check with anmol, returnid when customer care raises the request?
// Return Not Allowed, Not to show return at all, returnEligibleTillDate > currDate but flags are false? - KK

// currDate - deliveryDate => 30- days && returnEligibleTillDate => Show window closed on, if 30+ days dont show anything
// currDate - deliveryDate => 30- days && !returnEligibleTillDate => Show return not allowed, if 30+ days dont show anything
// if(returnEligibleTillDate > currDate && !isExchangeable && !isRefundable) => return not allowed => dont show anything

// OldFlow return

// 2 items:
// 1. isRefundable true || isExchangeable true, returnId (orderDetails. item.itemId) , Returned without any intent => Handling => Return Details Page
// 2. isRefundable true || isExchangeable true, returnId is not present (orderDetails. item.itemId) , Returned without any intent => Handling => Return Details Page => url => /sales/my/order/return/reason/${itemId}/${id}
