import * as actionTypes from './../../actionTypes/myAccount';

const initialState = {
  ordersList: null,
  persistOrder: null,
  loading: false,
  userInfoUpdated: false,
  passwordReset: false,
  voucherResult: null,
  storeCreditBalance: null,
  editNeft: null,
  storeCreditOrders: null,
  storeCreditOrderHistory: null,
  storeCreditOrderSMS: null,
  returnConfig: null,
  orderStatuses: null,
  storeCreditOrdersTotal: null,
  exchangeFlowSet: false,
  pfuConfig: null,
  updatedAddress: null,
  updatedAddressError: null,
  updateAddressloading: false,
  passwordResetData: null,
};

export default function ditto(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.EXCHANGE_FLOW_SET:
      return Object.assign({}, prevState, {
        exchangeFlowSet: action.data,
      });
    case actionTypes.NEW_ORDER_CHECK:
      return Object.assign({}, prevState, {
        // newOrderSet: true,
        ordersList: null,
        persistOrder: null,
      });
    case actionTypes.ORDER_LIST_LOAD:
      return Object.assign({}, prevState, {
        ordersList: null,
        loading: true,
      });
    case actionTypes.ORDER_LIST_SUCCESS:
      return Object.assign({}, prevState, {
        ordersList: action.data.result,
        persistOrder:
          prevState.persistOrder && !action.shouldReset
            ? [].concat([], prevState.persistOrder, action.data.result.orders)
            : action.data.result.orders,
        totalOrders: action.data.result.totalOrder,
        loading: false,
      });
    case actionTypes.ORDER_LIST_FAIL:
      return Object.assign({}, prevState, {
        ordersList: action.error,
        persistOrder: action.error,
        loading: false,
      });
    case actionTypes.UPDATE_ADDRESS_LOAD:
      return Object.assign({}, prevState, {
        updateAddressloading: true,
        updatedAddress: null,
      });
    case actionTypes.UPDATE_ADDRESS_SUCCESS:
      return Object.assign({}, prevState, {
        updateAddressloading: false,
        updatedAddress: action.data,
      });
    case actionTypes.UPDATE_ADDRESS_FAIL:
      return Object.assign({}, prevState, {
        updateAddressloading: false,
        updatedAddressError: action.error,
      });
    case actionTypes.UPDATE_USER_INFO_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        userInfoUpdated: false,
      });
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        userInfoUpdated: true,
      });
    case actionTypes.UPDATE_USER_INFO_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        userInfoUpdated: action.error,
      });
    case actionTypes.RESET_PASSWORD_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        passwordReset: false,
      });
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        passwordReset: true,
        passwordResetData: { password: action.password, email: action.email },
      });
    case actionTypes.RESET_PASSWORD_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        passwordReset: action.error,
      });
    case actionTypes.CHECK_VOUCHER_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        voucherResult: null,
      });
    case actionTypes.CHECK_VOUCHER_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        voucherResult: action.data,
      });
    case actionTypes.CHECK_VOUCHER_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        voucherResult: action.error,
      });
    case actionTypes.STORE_CREDIT_BAL_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        storeCreditBalance: null,
      });
    case actionTypes.STORE_CREDIT_BAL_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditBalance: action.data,
      });
    case actionTypes.STORE_CREDIT_BAL_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditBalance: action.error,
      });
    case actionTypes.STORE_CREDIT_ORDERS_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        storeCreditOrders: null,
      });
    case actionTypes.STORE_CREDIT_ORDERS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditOrders: action.data,
        storeCreditOrdersTotal: action.data.result.numOfOrders,
      });
    case actionTypes.STORE_CREDIT_ORDERS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditOrders: action.error,
        storeCreditOrdersTotal: null,
      });
    case actionTypes.STORE_CREDIT_ORDER_HISTORY_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        storeCreditOrderHistory: null,
      });
    case actionTypes.STORE_CREDIT_ORDER_HISTORY_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditOrderHistory: action.data,
      });
    case actionTypes.STORE_CREDIT_ORDER_HISTORY_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditOrderHistory: action.error,
      });
    case actionTypes.STORE_CREDIT_ORDER_SMS_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        storeCreditOrderSMS: null,
      });
    case actionTypes.STORE_CREDIT_ORDER_SMS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditOrderSMS: action.data,
      });
    case actionTypes.STORE_CREDIT_ORDER_SMS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        storeCreditOrderSMS: action.error,
      });
    case actionTypes.EDIT_NEFT_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        editNeft: null,
      });
    case actionTypes.EDIT_NEFT_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        editNeft: action.data,
      });
    case actionTypes.EDIT_NEFT_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        editNeft: action.error,
      });
    case actionTypes.CLEAR_STATE:
      return Object.assign({}, prevState, {
        ...action.values,
      });
    case actionTypes.RETURN_ORDER_CONFIG_LOAD:
      return Object.assign({}, prevState, {
        returnConfig: null,
        loading: true,
      });
    case actionTypes.RETURN_ORDER_CONFIG_SUCCESS:
      return Object.assign({}, prevState, {
        returnConfig: action.data,
        loading: false,
      });
    case actionTypes.RETURN_ORDER_CONFIG_FAIL:
      return Object.assign({}, prevState, {
        returnConfig: action.error,
        loading: false,
      });
    case actionTypes.PFU_PD_RULES_CONFIG_LOAD:
      return Object.assign({}, prevState, {
        pfuConfig: null,
        loading: true,
      });
    case actionTypes.PFU_PD_RULES_CONFIG_SUCCESS:
      return Object.assign({}, prevState, {
        pfuConfig: action.data,
        loading: false,
      });
    case actionTypes.PFU_PD_RULES_CONFIG_FAIL:
      return Object.assign({}, prevState, {
        pfuConfig: action.error,
        loading: false,
      });
    case actionTypes.REFRESH_ORDER:
      const newPersistOrder = prevState.persistOrder;
      if (newPersistOrder) {
        const indexInArray = newPersistOrder.findIndex(item => item.id === action.payload.id);
        if (indexInArray > -1) newPersistOrder[indexInArray] = action.payload;
      }
      return { ...prevState, persistOrder: newPersistOrder };
    default:
      return prevState;
  }
}
