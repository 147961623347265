import React from 'react';
import { ARTICLES } from '../Articles/Articles';
import ArticleDetailsBreadcrumb from './ArticleDetailsBreadcrumb';
import ArticleDetailsHeader from './ArticleDetailsHeader';
import ArticleDetailsFooter from './ArticleDetailsFooter';
import './ArticleDetails.scss';

const SG003 = () => {
  const permalink = 'sg-003';
  const article = ARTICLES?.find(article => article?.permalink === permalink);

  return (
    <>
      <ArticleDetailsBreadcrumb article={article} />
      <div className="article-details">
        <ArticleDetailsHeader article={article} />
        <div className="article-details__content">
          <div className="article-details__container">
            <p>{article.description}</p>
            <div className='article-details__offer'>
              <h4>How often should I get my eyes tested?</h4>
              <p>
                Generally, those who do not experience any major issues with their vision are
                advised to follow this schedule for their eye check: Children under 16 years old
                should be screened annually, adults aged 16-59 every two years, and those 60 and
                above annually.
              </p>
              <div className="button-group">
                <a href="/stores/sg" className="button button--primary">
                  Book Free Eye Test
                </a>
              </div>
            </div>
            <div>
              <h2>Top 4 Signs You Need an Eye Check Now</h2>
              <p>
                If you detect changes in your vision or experience any of the following warning
                signs, you may want to address the issue as soon as possible. Start by scheduling a
                complimentary eye check at OWNDAYS today!
              </p>
              <ol>
                <li>
                  <h3>
                    <span>Sign #1</span>You Encounter Blurry Or Double Vision
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-003/main-2.webp"
                      alt="You Encounter Blurry Or Double Vision"
                      loading="lazy"
                    />
                    If your vision is causing you to squint and strain your eyes, there is no need
                    to panic. It usually indicates that you require glasses or that your
                    prescription is changing. However, if your blurry vision comes and goes or is
                    limited to one eye, it could be a sign of a larger health issue. In this case,
                    you should schedule an eye test as soon as possible.
                  </p>
                  <p>
                    Likewise, it is advisable to consult an eye care practitioner if you are
                    experiencing double vision. They can assess your eyes to identify the root cause
                    of the problem. Sometimes, double vision is the result of minor issues, like dry
                    eyes, or a sign of astigmatism or keratoconus. In specific cases, abnormalities
                    in the brain, cornea, lens, retina, or nerves can also cause double vision.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Sign #2</span>You Have A Family History Of Eye Conditions
                  </h3>
                  <p>
                    Specific vision-related conditions, such as age-related macular degeneration
                    (AMD), glaucoma, and cataracts, are hereditary. In particular, the first two are
                    the leading causes of blindness in adults. Our stores at Marina Bay Sands (MBS),
                    Takashimaya S.C., and Jurong Point offer complimentary fundus photography
                    service, enabling customers to test for indications of AMD and glaucoma.
                    Meanwhile, we utilise a slit lamp to examine customers for cataracts. The slit
                    lamp is available at OWNDAYS stores that retail contact lenses.
                  </p>
                  <p className="p-img-single">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-003/main-3.webp"
                      alt="You Have A Family History Of Eye Conditions"
                      loading="lazy"
                    />
                  </p>
                  <p>
                    So, if a family member has a medical history of vision-related issues, consider
                    heading down to one of our various stores to undergo regular eye screenings, as
                    you may be more prone to a similar high-risk eye disease. Prompt detection can
                    enable you to manage the condition as early as possible, preventing it from
                    deteriorating and affecting your eyesight.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Sign #3</span>Your Eyes Are Sensitive To Light
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-003/main-6.webp"
                      alt="Your Eyes Are Sensitive To Light"
                      loading="lazy"
                    />
                    Light sensitivity and eye health are closely linked. So, if you find being
                    exposed to the sun or bright indoor lights uncomfortable for your eyes, it is
                    worth consulting an eye care practitioner. Light sensitivity may indicate a more
                    severe problem, like cataract, and is a common symptom of various eye disorders,
                    diseases, and infections.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Sign #4</span>You Cannot Remember When Your Last Eye Exam Was
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-003/main-4.webp"
                      alt="You Cannot Remember When Your Last Eye Exam Was"
                      loading="lazy"
                    />
                    If you cannot remember the date of your last eye test, it is likely time to
                    schedule a new appointment. Even if your vision appears fine without any
                    noticeable issues, it is still recommended to get your eyes tested periodically.
                    If you wear glasses, doing so can also ensure your prescription remains
                    up-to-date, while maintaining vision clarity.
                  </p>
                  <p>
                    Book a free eye test online with OWNDAYS today! Our online booking system is
                    available 24/7, enabling you to secure an appointment with minimal fuss and
                    effort.
                  </p>
                  <div className="button-group">
                    <a href="/stores/sg" className="button button--primary">
                      Book Free Eye Test
                    </a>
                  </div>
                </li>
              </ol>
            </div>
            <div className="article-details__related">
              <h3>OWNDAYS Customer Guarantees</h3>
              <div className="article-details__related-grid">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/warranty03.svg"
                  alt=""
                  loading="lazy"
                />
                <div>
                  <p>
                    Proper care and regular maintenance can extend the lifespan of your spectacles.
                    OWNDAYS offers you the most comprehensive after-sales service in the optical
                    industry so you can enjoy your OWNDAYS spectacles at all times.
                  </p>
                  <a className="link-arrow-right" href="/sg/en/services/warranty">
                    More Details
                  </a>
                </div>
              </div>
            </div>
            <div className="button-group button-group--2">
              <a href="/sg/en/eyewear/eyeglasses.html" className="button button--primary">
                See All Eyeglasses
              </a>
              <a href="/stores/sg" className="button button--primary">
                Search For Nearest OWNDAYS Stores
              </a>
            </div>
          </div>
        </div>
        <ArticleDetailsFooter permalink={permalink} />
      </div>
    </>
  );
};

export default SG003;
