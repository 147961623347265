// common actions for checkout and myaccount
export const LOCATION_DATA_LOAD = 'address/LOCATION_DATA_LOAD';
export const LOCATION_DATA_SUCCESS = 'address/LOCATION_DATA_SUCCESS';
export const USER_ADDRESS_LOAD = 'address/USER_ADDRESS_LOAD';
export const USER_ADDRESS_SUCCESS = 'address/USER_ADDRESS_SUCCESS';
export const USER_ADDRESS_FAIL = 'address/USER_ADDRESS_FAIL';
export const PINCODE_DATA_LOAD = 'address/PINCODE_DATA_LOAD';
export const PINCODE_DATA_SUCCESS = 'address/PINCODE_DATA_SUCCESS';
export const PINCODE_DATA_FAIL = 'address/PINCODE_DATA_FAIL';
export const UPDATE_USER_ADDRESS_LOAD = 'address/UPDATE_USER_ADDRESS_LOAD';
export const UPDATE_USER_ADDRESS_SUCCESS = 'address/UPDATE_USER_ADDRESS_SUCCESS';
export const UPDATE_USER_ADDRESS_FAIL = 'address/UPDATE_USER_ADDRESS_FAIL';
export const DELETE_USER_ADDRESS_LOAD = 'address/DELETE_USER_ADDRESS_LOAD';
export const DELETE_USER_ADDRESS_SUCCESS = 'address/DELETE_USER_ADDRESS_SUCCESS';
export const DELETE_USER_ADDRESS_FAIL = 'address/DELETE_USER_ADDRESS_FAIL';
export const CLEAR_STATE = 'address/CLEAR_STATE';
export const RESET_ADDRESS = 'address/RESET_ADDRESS';
export const REST_ADDRESS_ERROR_STATE = 'address/REST_ADDRESS_ERROR_STATE';
