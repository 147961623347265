export const LOAD = 'success/LOAD';

export const POWERS_DETAIL_LOAD = 'success/POWERS_DETAIL_LOAD';
export const POWERS_DETAIL_FAIL = 'success/POWERS_DETAIL_FAIL';
export const POWERS_DETAIL_SUCCESS = 'success/POWERS_DETAIL_SUCCESS';

// export const SET_ACTIVE_FRAME = 'success/SET_ACTIVE_FRAME';

export const SAVED_PRESCRIPTION_LOAD = 'success/SAVED_PRESCRIPTION_LOAD';
export const SAVED_PRESCRIPTION_FAIL = 'success/SAVED_PRESCRIPTION_FAIL';
export const SAVED_PRESCRIPTION_SUCCESS = 'success/SAVED_PRESCRIPTION_SUCCESS';

export const SUBMIT_POWERS_LOAD = 'success/SUBMIT_POWERS_LOAD';
export const SUBMIT_POWERS_FAIL = 'success/SUBMIT_POWERS_FAIL';
export const SUBMIT_POWERS_SUCCESS = 'success/SUBMIT_POWERS_SUCCESS';

export const SLOTS_LOAD = 'success/SLOTS_LOAD';
export const SLOTS_FAIL = 'success/SLOTS_FAIL';
export const SLOTS_SUCCESS = 'success/SLOTS_SUCCESS';

export const APPOINTMENT_BOOK_LOAD = 'success/APPOINTMENT_BOOK_LOAD';
export const APPOINTMENT_BOOK_FAIL = 'success/APPOINTMENT_BOOK_FAIL';
export const APPOINTMENT_BOOK_SUCCESS = 'success/APPOINTMENT_BOOK_SUCCESS';

export const GET_APPOINTMENT_DETAILS_LOAD = 'success/GET_APPOINTMENT_DETAILS_LOAD';
export const GET_APPOINTMENT_DETAILS_FAIL = 'success/GET_APPOINTMENT_DETAILS_FAIL';
export const GET_APPOINTMENT_DETAILS_SUCCESS = 'success/GET_APPOINTMENT_DETAILS_SUCCESS';

export const CANCEL_PFU_APPOINTMNET_LOAD = 'success/CANCEL_PFU_APPOINTMNET_LOAD';
export const CANCEL_PFU_APPOINTMNET_FAIL = 'success/CANCEL_PFU_APPOINTMNET_FAIL';
export const CANCEL_PFU_APPOINTMNET_SUCCESS = 'success/CANCEL_PFU_APPOINTMNET_SUCCESS';

export const HEC_APPOINTMNET_LOAD = 'success/HEC_APPOINTMNET_LOAD';
export const HEC_APPOINTMNET_FAIL = 'success/HEC_APPOINTMNET_FAIL';
export const HEC_APPOINTMNET_SUCCESS = 'success/HEC_APPOINTMNET_SUCCESS';

export const HEC_CANCEL_LOAD = 'success/HEC_CANCEL_LOAD';
export const HEC_CANCEL_FAIL = 'success/HEC_CANCEL_FAIL';
export const HEC_CANCEL_SUCCESS = 'success/HEC_CANCEL_SUCCESS';

export const HEC_DETAIL_JUNO_UPDATE_LOAD = 'success/HEC_DETAIL_JUNO_UPDATE_LOAD';
export const HEC_DETAIL_JUNO_UPDATE_FAIL = 'success/HEC_DETAIL_JUNO_UPDATE_FAIL';
export const HEC_DETAIL_JUNO_UPDATE_SUCCESS = 'success/HEC_DETAIL_JUNO_UPDATE_SUCCESS';

export const SUBMITTED_POWERS_LOAD = 'success/SUBMITTED_POWERS_LOAD';
export const SUBMITTED_POWERS_FAIL = 'success/SUBMITTED_POWERS_FAIL';
export const SUBMITTED_POWERS_SUCCESS = 'success/SUBMITTED_POWERS_SUCCESS';

export const OTP_SUBMISSION_LOAD = 'success/OTP_SUBMISSION_LOAD';
export const OTP_SUBMISSION_FAIL = 'success/OTP_SUBMISSION_FAIL';
export const OTP_SUBMISSION_SUCCESS = 'success/OTP_SUBMISSION_SUCCESS';

export const PD_CAMERA_UPLOAD_LOAD = 'success/PD_CAMERA_UPLOAD_LOAD';
export const PD_CAMERA_UPLOAD_FAIL = 'success/PD_CAMERA_UPLOAD_FAIL';
export const PD_CAMERA_UPLOAD_SUCCESS = 'success/PD_CAMERA_UPLOAD_SUCCESS';

export const SUBMISSION_SUCCESS = 'success/SUBMISSION_SUCCESS';

export const MSG_ERROR = 'success/MSG_ERROR';

export const ENTER_DATA = 'success/ENTER_DATA';

export const ACTIVE_FRAME_DATA = 'success/ACTIVE_FRAME_DATA';

export const SHOW_DD = 'success/SHOW_DD';

export const SUBMIT_PACKAGE_LOAD = 'success/SUBMIT_PACKAGE_LOAD';
export const SUBMIT_PACKAGE_FAIL = 'success/SUBMIT_PACKAGE_FAIL';
export const SUBMIT_PACKAGE_SUCCESS = 'success/SUBMIT_PACKAGE_SUCCESS';

export const RESET_STATE = 'success/RESET_STATE';
