import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LKModal from '../../Modal/Modal';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CartItems from './CartItems';
import * as cartActions from '../../../../actionCreators/cart';
import { hasFrameOnlyProduct } from '../../../../utils/helper';
import './Cart.scss';
import { addToCartGA4, beginCheckoutGA4, viewCartGA4 } from '../../../../utils/gaFour';
import { userProperties } from '../../../../CommonComponents/UserPropertiesForGA/UserProperties';
import { v4 } from 'uuid';

class Cart extends Component {
  static propTypes = {
    cartData: PropTypes.any,
    addToCartFailed: PropTypes.any,
    isCartLoader: PropTypes.bool,
    redisCommonData: PropTypes.object,
    section: PropTypes.string,
    updateCartSuccess: PropTypes.bool,
    updateCartFail: PropTypes.bool,
    cartOmniture: PropTypes.func,
    isframeProduct: PropTypes.bool,
  };
  constructor(props, context) {
    super(props, context);
    this.state = {
      showCart: true,
      dtmOnLoad: true,
      dtmRemoveOnLoad: true,
      checkoutLoader: false,
    };
  }

  componentDidMount() {
    // Set a session storage item to indicate the component's mount event.
    sessionStorage.setItem('dtmOnLoadData', 'cartSummaryInner');

    // Destructure the required props.
    const { cartData, updateCartSuccess, updateCartFail, cartActions, cartOmniture, login } =
      this.props;

    // GA4 - View cart
    // Perform GA4 tracking for cart view.
    viewCartGA4(cartData?.result, login);
    // Send user properties for cart view.
    userProperties(this.props.userInfo, 'cart-view', 'desktop', this.props.dataLocale);

    // Retrieve the value of the 'cartItem' from session storage.
    const cartItem = sessionStorage.getItem('cartItem');
    // Check if there's an update to the cart (increase/decrease item quantity).
    const isUpdateCart = updateCartSuccess || updateCartFail;

    // Determine the event type based on the cartItem value.
    let eventType;
    if (isUpdateCart && (cartItem === 'increase' || cartItem === 'decrease')) {
      eventType = cartItem === 'increase' ? 'Update Qty Success' : 'decrease Qty Success';
    } else {
      eventType = cartItem === 'increase' ? 'Update Qty Failure' : 'decrease Qty Failure';
    }

    // Create a page info string with current page and timestamp.
    const pageInfo = `page: ${document.location.pathname}${
      document.location.search
    } | time: ${Date()}`;

    // Track cart item quantity update success or failure events.
    _gaq.push(['_trackEvent', 'A2C Popup', eventType, pageInfo]);
    _gaq.push(['ninja._trackEvent', 'A2C Popup', eventType, pageInfo]);

    // Track successful removal of a product from the cart.
    if (isUpdateCart && cartItem === 'delete') {
      _gaq.push(['_trackEvent', 'A2C Popup', 'Remove Product Success', pageInfo]);
      _gaq.push(['ninja._trackEvent', 'A2C Popup', 'Remove Product Success', pageInfo]);
    }

    // Check if the cart contains only frame products and dispatch the result to Redux store.
    const isframeProduct = hasFrameOnlyProduct(cartData.result.items);
    cartActions.frameOnlyProduct({ isframeProduct });

    // Check if there's cart data and previous DTM events, then call the cartOmniture function.
    if (
      cartData.result.id &&
      this.state.dtmOnLoad &&
      this.state.dtmRemoveOnLoad &&
      sessionStorage.getItem('dtmOnLoadData') !== 'cartSummary'
    ) {
      cartOmniture(cartData.result.items);
    }
  }

  componentDidUpdate(prevProps) {
    const { cartData, cartActions, cartOmniture, login } = this.props;
    if (
      cartData?.result?.id &&
      cartData?.result?.items?.length > prevProps?.cartData?.result?.items?.length
    ) {
      // HIT GA-4 while add to cart
      addToCartGA4(cartData?.result?.items, login, prevProps?.cartData?.result?.items);
    }
    if (
      cartData.result.id &&
      this.state.dtmOnLoad &&
      this.state.dtmRemoveOnLoad &&
      sessionStorage.getItem('dtmOnLoadData') !== 'cartSummary'
    ) {
      cartOmniture(cartData.result.items);
    }
    if (prevProps.cartData !== cartData) {
      const isframeProduct = hasFrameOnlyProduct(cartData.result.items);
      cartActions.frameOnlyProduct({ isframeProduct });
    }
    sessionStorage.setItem('cartItem', '');
  }

  dtmStateLoad = flag => {
    this.setState({ dtmRemoveOnLoad: flag });
  };
  checkoutLoaderFunc = (bool, ev) => {
    ev?.preventDefault();
    const { cartData, login } = this.props;
    // GA4 - Add to cart
    beginCheckoutGA4(cartData, login);
    const scriptEl = document.querySelector('#yotpo_widget_lk');
    if (scriptEl) {
      document.head.removeChild(scriptEl);
    }
    this.setState({ checkoutLoader: bool });
    this.props.hideHandler();
  };

  emptyCartView = () => {
    const {
      dataLocale: {
        SEARCH_FOR,
        /* CONTACT_LENSES, SUNGLASSES, EYEGLASSES, */ CONTINUE_SHOPPING,
        THERE_IS_PROBLEM_ADDING_ITEM,
        YOUR_SHOPPING_CART_EMPTY,
        SHOP_FOR_PRODUCTS_ADD_ITEMS,
      },
      redisCommonData: { EMPTY_CART_LINKS },
    } = this.props;
    const emptyCartLinks = EMPTY_CART_LINKS && JSON.parse(EMPTY_CART_LINKS);
    return (
      <div className="cart-popup_slide_empty">
        <span className="cart-popup_slide_empty_message">
          {this.props.addToCartFailed && this.props.addToCartFailed.message ? (
            <span>
              {THERE_IS_PROBLEM_ADDING_ITEM}{' '}
              <span className="hidden">{this.props.addToCartFailed.message}</span>
            </span>
          ) : (
            YOUR_SHOPPING_CART_EMPTY
          )}
        </span>
        <div>{SHOP_FOR_PRODUCTS_ADD_ITEMS}</div>
        <div className="cart-popup_slide_empty_footer">
          <a
            className="title"
            href={typeof window !== 'undefined' && window.location.origin}
            title={CONTINUE_SHOPPING}
          >
            <span className="text-uppercase">{CONTINUE_SHOPPING}</span>{' '}
            <i className="icon-chkout-arw" />
          </a>
        </div>
        <div className="cart-popup_slide_empty_links row-fluid f18">
          <div className="span2 bold">{SEARCH_FOR}:</div>
          {emptyCartLinks?.map(el => (
            <div key={v4()} className="span3 color-yellow">
              <a href={typeof window !== 'undefined' && el.link}>{el.name}</a>
            </div>
          ))}
          {/* <div className="span3 color-yellow">
                        <a href={typeof window !== 'undefined' && `${window.location.origin}/eyeglasses.html`}>{EYEGLASSES}</a>
                    </div>
                    <div className="span3 color-yellow">
                        <a href={typeof window !== 'undefined' && `${window.location.origin}/sunglasses.html`}>{SUNGLASSES}</a>
                    </div>
                    <div className="span3 color-yellow">
                        <a href={typeof window !== 'undefined' && `${window.location.origin}/contact-lenses.html`}>{CONTACT_LENSES}</a>
                    </div> */}
        </div>
      </div>
    );
  };

  render() {
    const { cartData, isCartLoader, hideHandler, redisCommonData, isframeProduct, dataLocale } =
      this.props;
    const { SHOPPING_CART } = dataLocale;
    return (
      <div>
        <LKModal
          backdrop
          keyboard
          dialogClassName="cart-popup"
          hideHandler={hideHandler}
          showModal={this.state.showCart}
          size="large"
        >
          <div className="cart-popup_slide row" style={{ overflow: 'hidden' }}>
            <div className="cart-popup_slide_header">
              <i className="icon-cart-close" onClick={hideHandler} />
              <h4 className="title col-sm-6">
                {SHOPPING_CART} ({cartData.result.itemsCount}){' '}
              </h4>
            </div>

            {isCartLoader && (
              <div className="text-center">
                <img
                  alt="loader"
                  src="https://static.lenskart.com/media/owndays/desktop/img/od-loaders/loader-no-bg.gif"
                />
              </div>
            )}

            {/* render cart items */}
            {!isCartLoader &&
              (cartData.result.itemsQty !== 0 ? (
                <CartItems
                  {...this.props}
                  cartObj={cartData.result}
                  checkoutLoader={this.state.checkoutLoader}
                  checkoutLoaderFunc={bool => {
                    this.checkoutLoaderFunc(bool);
                  }}
                  dtmStateLoad={this.dtmStateLoad}
                  isframeProduct={isframeProduct}
                  redisCommonData={redisCommonData}
                />
              ) : (
                this.emptyCartView()
              ))}
          </div>
        </LKModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isCartLoader: state.cart.isCartLoader,
  isAlreadyLoyal: state.common.isAlreadyLoyal,
  cartData: state.cart.cartData,
  updateCartSuccess: state.cart.updateCartSuccess,
  updateCartFail: state.cart.updateCartFail,
  addToCartFailed: state.cart.addToCartFailed,
  showCartPopup: state.cart.showCartPopup,
  section: state.common.section,
  isframeProduct: state.cart.isframeProduct,
  redisCommonData: state.common.redisCommonData,
  login: state.common.login,
  dataLocale: state.locale,
  currencyMark: state.common.currencyMark,
  localeInfo: state.common.localeInfo,
  userInfo: state.common.userInfo?.result,
});

function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(cartActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
