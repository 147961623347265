import React from 'react';

const Concept = ({ baseImgUrl }) => {
  return (
    <section className="concept">
      <div className="l-hp__container-sm">
        <h2 className="concept__title" data-aos="fade-up">
          Experience the magical <br />
          world of Harry Potter
        </h2>
        <p data-aos="fade-up">
          Embark on an enchanting journey with the Harry Potter × OWNDAYS frame collection.
          Consisting of 6 models in an assortment of metal and plastic materials, the
          limited-edition collection is a tribute to the beloved film series. Every frame in the
          collection encapsulates the essence of the magical universe where the boy wizard and his
          friends have to navigate through. Expect iconic motifs and elements such as wands,
          broomsticks, the Deathly Hallows symbol and Hermione’s Time-Turner among others
          incorporated into the frame design.
        </p>
        <a className="l-hp__btn" data-aos="fade-up" href="/sg/en/eyeglasses/brands/harry-potter.html">
          <span className="l-hp__btn-inner">ALL ITEMS</span>
        </a>
        <img
          alt="bg"
          className="concept__el concept__el--1"
          src={`${baseImgUrl}concept-el-1.svg`}
        />
        <img
          alt="Gryffindor"
          className="concept__el concept__el--2"
          src={`${baseImgUrl}gryffindor.svg`}
        />
        <img
          alt="Slytherin"
          className="concept__el concept__el--3"
          src={`${baseImgUrl}slytherin.svg`}
        />
        <img
          alt="Hufflepuff"
          className="concept__el concept__el--4"
          src={`${baseImgUrl}hufflepuff.svg`}
        />
        <img
          alt="ravenclaw"
          className="concept__el concept__el--5"
          src={`${baseImgUrl}ravenclaw.svg`}
        />
        <img
          alt="bg"
          className="concept__el concept__el--6"
          src={`${baseImgUrl}concept-el-2.svg`}
        />
      </div>
    </section>
  );
};

export default Concept;
