import * as actionTypes from './../../actionTypes/review';

const initialState = {
  isReviewListLoading: false,
  productReviewList: undefined,
  reviewPageSize: 10,
  postReviewProccessing: false,
  postReviewData: undefined,
};

export default function review(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_REVIEW_LIST_LOAD:
      return Object.assign({}, prevState, { isReviewListLoading: true });
    case actionTypes.GET_PRODUCT_REVIEW_LIST_SUCCESS:
      const { productReviewList } = prevState;
      const result = action.data;
      if (productReviewList) {
        result.result.review.reviews = productReviewList?.result?.review?.reviews.concat(
          result.result?.review?.reviews
        );
      }
      return Object.assign({}, prevState, {
        isReviewListLoading: false,
        productReviewList: result,
      });
    case actionTypes.GET_PRODUCT_REVIEW_LIST_FAIL:
      return Object.assign({}, prevState, {
        isReviewListLoading: false,
        productReviewList: action.data,
      });
    case actionTypes.GET_PRODUCT_REVIEW_LIST_RESET:
      return Object.assign({}, prevState, {
        isReviewListLoading: false,
        productReviewList: undefined,
      });
    case actionTypes.POST_REVIEW_LOAD:
      return Object.assign({}, prevState, {
        postReviewProccessing: true,
        postReviewData: undefined,
      });
    case actionTypes.POST_REVIEW_SUCCESS:
      return Object.assign({}, prevState, {
        postReviewProccessing: false,
        postReviewData: action.data,
      });
    case actionTypes.POST_REVIEW_FAIL:
      return Object.assign({}, prevState, {
        postReviewProccessing: false,
        postReviewData: action.data,
      });
    case actionTypes.POST_REVIEW_RESET:
      return Object.assign({}, prevState, {
        postReviewProccessing: false,
        postReviewData: undefined,
      });
    default:
      return prevState;
  }
}
