import React, { useState, useEffect } from 'react';

export const DotPalette = ({
  dots,
  sku,
  initialSlide = 0,
  backgroundColors,
  containerClassName,
  showSku = false,
  customClassName = '',
}) => {
  const [currentSlide, setCurrentSlide] = useState(initialSlide);

  const getBackgroundColor = (sku, index) => {
    const colors = backgroundColors[sku] || backgroundColors.default;
    return colors[index] || colors[0];
  };

  const isImageUrl = value => value.startsWith('http');

  useEffect(() => {
    setCurrentSlide(initialSlide);
  }, [initialSlide]);

  return (
    <div className={`${containerClassName}__container ${customClassName}`}>
      {showSku && <div className={`${containerClassName}__code`}>{sku}</div>}

      <div className={`${containerClassName}__palette`}>
        {dots?.map((dot, index) => {
          const backgroundColor = getBackgroundColor(sku, index);

          return (
            <span
              key={dot?.key}
              className={`${containerClassName}__color ${
                index === currentSlide ? `${containerClassName}__color--selected` : ''
              }`}
              style={{
                backgroundColor: isImageUrl(backgroundColor) ? 'transparent' : backgroundColor,
                backgroundImage: isImageUrl(backgroundColor) ? `url(${backgroundColor})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              onClick={dot?.props?.children?.props?.onClick}
              onMouseDown={() => setCurrentSlide(index)}
            ></span>
          );
        })}
      </div>
    </div>
  );
};
