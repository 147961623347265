export const MAIN = {
  logoSrc: 'https://static.lenskart.com/media/owndays/img/services/care/logo-owndays_care_plus.svg',
  logoAlt: 'OWNDAYS care+',
  heading: 'Enhance Your Spectacle Frame <br />Warranty for 1 Year',
  imgSrc: 'https://static.lenskart.com/media/owndays/img/services/care/main.webp',
  imgAlt: 'Glasses image',
  footer: 'Pay 10% of price indicated on the frame to enjoy!'
}

export const FEATURES = {
  title: 'FEATURES',
  list: [
    {
      title: '1-TIME <strong>FREE</strong> REPLACEMENT',
      text: 'of damaged frame^',
    },
    {
      title:
        'UNLIMITED <img src="https://static.lenskart.com/media/owndays/img/services/care/off_20.svg" alt="20%OFF" />',
      text: 'new frame purchases*',
    },
  ],
};

export const TERMS = {
  title: 'Terms & Conditions',
  text: [
    'OWNDAYS Care+ is available for purchase with sunglasses, OWNDAYS PC glasses and all spectacle frames, including frames from collaboration collections, Limited Edition collection and frames purchased with the same-day-2nd-pair-50%-off promotion. OWNDAYS Care+ expires one year from the date of collection. There is strictly no extension of expiry date and/or renewal of OWNDAYS Care+. 10% of the original retail price indicated on the product, rounded down to the nearest dollar, must be paid at the time of purchase. There is strictly no refund upon purchase of OWNDAYS Care+. OWNDAYS Care+ is non-transferrable and is valid for eyewear used by the same wearer only.',
    '^OWNDAYS Care+ covers damages to the frame only. Damaged frame must be returned to OWNDAYS and will be replaced with a new frame of the same model. In the event the same model is no longer in stock, a replacement product of the same or lower price will be provided. There will be no refund of the price difference if the replacement product is of a lower price. Replacement of damaged frame is accepted at OWNDAYS retail stores in Singapore only.',
    '* 20%-off-new-frame-purchase is valid for the purchase of spectacle frames with standard high index aspheric lenses only and excludes collaboration merchandise, limited-edition merchandise, sale items, sunglasses, OWNDAYS PC glasses, other ready-to-wear merchandise, accessories and all lens upgrades. The discount can be redeemed unlimited number of times within the OWNDAYS Care+ validity period. The discount cannot be used in conjunction with the same-day-2nd-pair-50%-off promotion and/or other offers and privileges.',
    'OWNDAYS Care+ is valid in Singapore only. Please retain receipt as proof of purchase. The management reserves the right to amend the terms & conditions without prior notice.',
  ],
};
