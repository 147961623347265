import React, { Fragment } from 'react';

import './FooterOwndaysPlus.scss';

export const FooterOwndaysPlus = ({ baseImgUrl }) => {
  const options = [
    {
      url: '/sg/en/services/lens#blue-light-lenses',
      src: `${baseImgUrl}/option-item-01.webp`,
      name: 'Blue Light Lenses',
      alt: 'Blue Light Lenses',
      price: '+S$100',
      tax: '',
      detail: 'Protect your eyes from blue light emitted by computer and smartphone screens.',
    },
    {
      url: '/sg/en/services/lens#color',
      src: `${baseImgUrl}/option-item-02.webp`,
      name: 'Colour Lenses',
      alt: 'Colour Lenses',
      price: '+S$100',
      tax: '',
      detail: 'Convert your favourite frames into trendy sunglasses.',
    },
    {
      url: '/sg/en/services/lens#transitions-light',
      src: `${baseImgUrl}/option-item-03.webp`,
      name: (
        <Fragment>
          Transitions<small>®</small> Lenses
        </Fragment>
      ),
      alt: 'Transitions®️ Lenses',
      price: '+S$200',
      tax: '',
      detail:
        'Protect your vision in any light as they adapt from clear to dark, indoors to outdoors and back..',
    },
  ];

  return (
    <section className="footer-owndays-plus">
      <div className="base-owndays-plus__container">
        <h2 className="base-owndays-plus__heading">
          <strong>Optional</strong>
          <small>Recommended Lens Upgrades</small>
        </h2>

        <ul className="footer-owndays-plus__list">
          {options.map(option => {
            return (
              <li key={option.name} className="footer-owndays-plus__item">
                <a href={option.url} rel="noreferrer" target="_blank">
                  <img
                    alt={option.alt}
                    className="lazyload footer-owndays-plus__item-image"
                    src={option.src}
                  />
                  <div className="footer-owndays-plus__item-title">
                    {option.alt === 'Transitions®️ Lenses' ? (
                      <img
                        alt="Transitions®️ Lenses"
                        className="lazyload"
                        src={`${baseImgUrl}/option-transitions-logo.png`}
                        style={{ width: '37%' }}
                      />
                    ) : (
                      <h3 className="footer-owndays-plus__item-name">{option.name}</h3>
                    )}

                    <h4 className="footer-owndays-plus__item-price">
                      <strong>{option.price}</strong>
                      <small>{option.tax}</small>
                    </h4>
                  </div>
                  <p className="footer-owndays-plus__item-detail">{option.detail}</p>
                  <div className="footer-owndays-plus-footer">
                    <div className="footer-owndays-plus__item-button">
                      <p className="footer-owndays-plus__item-button--text">VIEW MORE</p>
                      <div className="footer-owndays-plus__item-button--circle"></div>
                    </div>
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
