import React, { useState, useRef, useEffect } from 'react';

import Navigation from '../Services/Navigation/Navigation';
import { LENS_REPLACEMENT_FAQ_ITEMS } from './constants';

import './FooterLensReplacement.scss';

export const FooterLensReplacement = () => {
  const [openItems, setOpenItems] = useState([]);

  const contentRefs = useRef([]);

  const currentPath = '/services/lens-replacement';

  const toggleItem = index => {
    setOpenItems(prev => (prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]));
  };

  useEffect(() => {
    openItems.forEach(index => {
      if (contentRefs.current[index]) {
        contentRefs.current[index].style.maxHeight =
          contentRefs.current[index].scrollHeight + 20 + 'px';
      }
    });

    contentRefs.current.forEach((ref, index) => {
      if (!openItems.includes(index) && ref) {
        ref.style.maxHeight = '0';
      }
    });
  }, [openItems]);

  return (
    <div className="base-lens-replacement__container footer-lens-replacement">
      <section className="footer-lens-replacement__qa base-lens-replacement__content--inner">
        <h2>Lens Replacement Q&A</h2>

        <ul className="footer-lens-replacement__qa__list">
          {LENS_REPLACEMENT_FAQ_ITEMS.map((item, index) => (
            <li key={index}>
              <div
                className={`footer-lens-replacement__qa__item-q ${
                  index === 0 && 'footer-lens-replacement__qa__item-q--border-top-none'
                }`}
                onClick={() => toggleItem(index)}
              >
                <h3>{item.question}</h3>
                <span className="footer-lens-replacement__qa__item-navigate">
                  {openItems.includes(index) ? 'close' : 'open'}
                </span>
              </div>

              <div
                ref={el => {
                  contentRefs.current[index] = el;
                }}
                className={`footer-lens-replacement__qa__item-a ${
                  openItems.includes(index) ? 'footer-lens-replacement__qa__item__open' : ''
                }`}
              >
                <p>{item.answer}</p>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <Navigation currentPath={currentPath} size="mobile" />
    </div>
  );
};
