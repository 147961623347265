import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/returnExchange';
import apiClient from '../helpers/apiClient';
import config from 'config';
// import { modifyReturnEligibiltyForMock } from './responseDataManipulator';

let _client = null;

// return
function* getStoreList({ address, radius, pageSize, pageNumber }) {
  const successAction = { type: actionTypes.STORE_LOCATION_SUCCESS };
  const errorAction = { type: actionTypes.STORE_LOCATION_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(
        `${config.apiPath.nearbyStore}?address=${address}&radius=${radius || '20'}&pageSize=${
          pageSize || '10'
        }&pageNumber=${pageNumber || '0'}`
      )
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* createReturn({ orderId, reqData }) {
  const successAction = { type: actionTypes.CREATE_RETURN_SUCCESS };
  const errorAction = { type: actionTypes.CREATE_RETURN_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(`${config.apiPath.v2Orders}/${orderId}/return`, reqData)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getReturnReasons({ classification }) {
  const successAction = { type: actionTypes.GET_RETURN_REASONS_SUCCESS };
  const errorAction = { type: actionTypes.GET_RETURN_REASONS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.returnReasons}?classification=${classification}`)
    );
    successAction.data = data.result && data.result.reasons;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getReturnModes({ pincode }) {
  const successAction = { type: actionTypes.GET_RETURN_MODES_SUCCESS };
  const errorAction = { type: actionTypes.GET_RETURN_MODES_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.returnModes}?pincode=${pincode}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* checkServiceableAddress({ pincode }) {
  const successAction = { type: actionTypes.GET_SERVICEABLE_ADDRESS_SUCCESS };
  const errorAction = { type: actionTypes.GET_SERVICEABLE_ADDRESS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.validateServiceableAddress}?pincode=${pincode}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* getEligibilityReturnStatusForOrder({ orderId }) {
  const successAction = { type: actionTypes.GET_ELIGIBILITY_RETURN_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.GET_ELIGIBILITY_RETURN_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.v2Orders}/${orderId}/return/eligibility`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getReturnLabelStatus({ groupId }) {
  const successAction = { type: actionTypes.GET_RETURN_LABEL_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.GET_RETURN_LABEL_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.returnLabelStatus}${groupId}`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

// post return
function* getReturnDetails({ orderId, itemId }) {
  const successAction = { type: actionTypes.GET_RETURN_DETAILS_SUCCESS };
  const errorAction = { type: actionTypes.GET_RETURN_DETAILS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.v2Orders}/${orderId}/return/details/${itemId}`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getReturnActions({ orderId, itemId }) {
  const successAction = { type: actionTypes.GET_RETURN_ACTIONS_SUCCESS };
  const errorAction = { type: actionTypes.GET_RETURN_ACTIONS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.v2Orders}/${orderId}/refund/methods/${itemId}`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

// refund
function* createRefund({ itemId, orderId, refundMethod }) {
  const successAction = { type: actionTypes.CREATE_REFUND_SUCCESS };
  const errorAction = { type: actionTypes.CREATE_REFUND_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(`${config.apiPath.v2Orders}/${orderId}/refund/return`, { itemId, refundMethod })
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getRefundEstimate({ orderId, itemId }) {
  const successAction = { type: actionTypes.GET_REFUND_ESTIMATE_SUCCESS };
  const errorAction = { type: actionTypes.GET_REFUND_ESTIMATE_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.v2Orders}/${orderId}/refund/return/estimate/${itemId}`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getOrderItemsReturnElgibilty({ orderId }) {
  const successAction = { type: actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY_SUCCESS };
  const errorAction = { type: actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(
        `${config.apiPath.v2Orders}/${orderId}/return/eligibility?fetchRefundExchangeDetails=true`
      )
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

// Cancel Return
function* cancelReturn({ orderId, returnId }) {
  const successAction = { type: actionTypes.RETURN_CANCEL_SUCCESS };
  const errorAction = { type: actionTypes.RETURN_CANCEL_FAIL };
  const client = { ..._client };
  try {
    const data = yield call(async () =>
      client.post(`${config.apiPath.v2Orders}/${orderId}/return/${returnId}/cancel`)
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runReturnExchangeCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    // return
    takeLatest(actionTypes.STORE_LOCATION_LOAD, getStoreList),
    takeLatest(actionTypes.CREATE_RETURN_LOAD, createReturn),
    takeLatest(actionTypes.GET_RETURN_REASONS, getReturnReasons),
    takeLatest(actionTypes.GET_RETURN_MODES_LOAD, getReturnModes),
    takeLatest(actionTypes.GET_SERVICEABLE_ADDRESS_LOAD, checkServiceableAddress),
    takeLatest(actionTypes.GET_ELIGIBILITY_RETURN_STATUS, getEligibilityReturnStatusForOrder),
    takeLatest(actionTypes.GET_RETURN_LABEL_STATUS_LOAD, getReturnLabelStatus),
    // post return
    takeLatest(actionTypes.GET_RETURN_DETAILS_LOAD, getReturnDetails),
    takeLatest(actionTypes.GET_RETURN_ACTIONS_LOAD, getReturnActions),
    // refund
    takeLatest(actionTypes.CREATE_REFUND_LOAD, createRefund),
    takeLatest(actionTypes.GET_REFUND_ESTIMATE_LOAD, getRefundEstimate),
    // new return refund exchange
    takeLatest(actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY, getOrderItemsReturnElgibilty),
    // Cancel Return
    takeLatest(actionTypes.RETURN_CANCEL_LOAD, cancelReturn),
  ]);
}
