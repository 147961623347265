import React from 'react';
import Shimmer from '../../CommonComponents/Shimmer/Shimmer';

export const PrimerCardShimmer = () => (
  <>
    <Shimmer line height={64} />
    <div style={{ display: 'grid', marginTop: 24, gridTemplateColumns: 'repeat(2, 1fr)', gap: 24 }}>
      <Shimmer line height={64} />
      <Shimmer line height={64} />
    </div>
    <Shimmer line height={64} style={{ marginTop: 24 }} />
  </>
);
