import React, { Fragment, useRef, useEffect } from 'react';
import Slider from 'react-slick';

import { SliderOwndaysPc } from './SliderOwndaysPc';
import { ABOUT_IMAGES, POINT_IMAGES, POINTS } from './constants';
import './BodyOwndaysPc.scss';

export const BodyOwndaysPc = ({ baseImgUrl }) => {
  const listItemsRef = useRef([]);
  const sectionRef = useRef(null);
  const sliderAboutRef = useRef(null);
  const sliderPointRef = useRef(null);

  const shuffleLetters = (element, options = {}) => {
    const { fps = 60, duration = 1000 } = options;
    const text = element.innerText;
    let iteration = 0;
    const totalIterations = fps * (duration / 1000);

    const shuffleInterval = setInterval(() => {
      element.innerText = text
        .split('')
        .map((char, i) => {
          if (i < iteration) {
            return text[i];
          }

          const array = new Uint32Array(1);
          crypto.getRandomValues(array);
          return String.fromCharCode(33 + (array[0] % (126 - 33 + 1)));
        })
        .join('');

      iteration += text.length / totalIterations;

      if (iteration >= text.length) {
        clearInterval(shuffleInterval);
        element.innerText = text;
      }
    }, 1000 / fps);
  };

  const renderShuffleList = () => {
    return (
      <div ref={sectionRef} className="base-owndays-pc__container-bg-grid" id="about">
        <ul className="shuffle">
          <li>
            <h5>International Standard</h5>
            <p
              ref={el => {
                listItemsRef.current[0] = el;
              }}
              className="shuffle-letter shuffle-letter-1"
            >
              ISO 12312-1:2013/A1:2015
            </p>
          </li>
          <li>
            <h5>Blue Light</h5>
            <p
              ref={el => {
                listItemsRef.current[1] = el;
              }}
              className="shuffle-letter shuffle-letter-2"
            >
              385-485nm
            </p>
          </li>
          <li>
            <h5>Reduction</h5>
            <p
              ref={el => {
                listItemsRef.current[2] = el;
              }}
              className="shuffle-letter shuffle-letter-3"
            >
              25%
            </p>
          </li>
        </ul>
      </div>
    );
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            listItemsRef.current.forEach(element => {
              shuffleLetters(element, {
                fps: 30,
              });
            });
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const whatSettings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: '0px',
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <section className="body-owndays-pc__about" id="about">
        <div className="body-owndays-pc__about__top">
          <div className="base-owndays-pc__container-bg-grid">
            <div className="base-owndays-pc__title-grid">
              <div className="base-owndays-pc__title-heading">
                <h2 className="base-owndays-pc__title" data-aos="fade-down">
                  <span className="base-owndays-pc__title-no">01</span>
                  <span className="base-owndays-pc__title-name">ABOUT</span>
                </h2>
              </div>

              <div className="base-owndays-pc__title-content">
                <p data-aos="fade-left">
                  OWNDAYS PC is developed for people in today’s digital world who tend to experience
                  excessive screen time. It protects your eyes from blue light emitted by digital
                  screens such as computers, TV, mobile phones and gaming devices. By reducing the
                  brightness and glare of these screens, OWNDAYS PC helps to ease eyestrain, thereby
                  allowing you to enjoy clear, comfortable vision.
                </p>

                <p data-aos="fade-left">
                  Lightweight and designed for comfort, OWNDAYS PC is also suitable for people who
                  have never worn spectacles before.
                </p>

                <div data-aos="fade-left">
                  <a
                    className="base-owndays-pc__btn base-owndays-pc__btn--right"
                    href="/sg/en/search?q=PC"
                  >
                    See All Products
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="body-owndays-pc__about__middle">{renderShuffleList()}</div>

        <SliderOwndaysPc baseImgUrl={baseImgUrl} images={ABOUT_IMAGES} sliderRef={sliderAboutRef} />
      </section>

      <section className="body-owndays-pc__points">
        <div className="base-owndays-pc__container-bg-grid">
          <div className="base-owndays-pc__title-heading">
            <h2 className="base-owndays-pc__title" data-aos="fade-down">
              <span className="base-owndays-pc__title-no">02</span>
              <span className="base-owndays-pc__title-name">FEATURES</span>
            </h2>

            <ul className="body-owndays-pc__points__list">
              {POINTS.map((point, index) => (
                <li key={point.title} data-aos="fade-left">
                  <img alt={point.title} src={`${baseImgUrl}/${point.image}`} />
                  <h5>{point.title}</h5>
                  <p>{point.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <SliderOwndaysPc baseImgUrl={baseImgUrl} images={POINT_IMAGES} sliderRef={sliderPointRef} />
      </section>

      <section className="body-owndays-pc__what">
        <div className="body-owndays-pc__what__top">
          <div className="base-owndays-pc__container-bg-grid">
            <div className="base-owndays-pc__title-grid">
              <div className="base-owndays-pc__title-heading">
                <h2 className="base-owndays-pc__title" data-aos="fade-down">
                  <span className="base-owndays-pc__title-no">03</span>
                  <span className="base-owndays-pc__title-name">WHAT</span>
                </h2>
              </div>

              <div className="base-owndays-pc__title-content">
                <p data-aos="fade-left">
                  Staring at computers, mobile phones or video game consoles for a long period of
                  time may strain your eyes without you realising it. OWNDAYS PC can reduce this
                  blue light from digital devices, thereby easing eyestrain, headache and neck pain.
                </p>

                <p data-aos="fade-left">
                  Blue light is a visible light with wavelength of 385-485nm which is the closest to
                  that of harmful ultraviolet (UV) light. It is able to reach the human retina,
                  causing eyestrain and eye diseases.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="base-owndays-pc__container">
          <figure className="body-owndays-pc__what__blue-light" data-aos="fade-up">
            <img alt="blue-light" src={`${baseImgUrl}/bluelight.png`} />
          </figure>
        </div>

        <Slider {...whatSettings} className="body-owndays-pc__what__slider">
          <div data-aos="fade-up">
            <picture>
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}/where1-pc.webp`} />
              <img alt="mobilephone" src={`${baseImgUrl}/where1-sp.webp`} />
            </picture>
          </div>
          <div data-aos="fade-up" data-aos-delay="200">
            <picture>
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}/where2-pc.webp`} />
              <img alt="tablet" src={`${baseImgUrl}/where2-sp.webp`} />
            </picture>
          </div>
          <div data-aos="fade-up" data-aos-delay="400">
            <picture>
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}/where3-pc.webp`} />
              <img alt="tv" src={`${baseImgUrl}/where3-sp.webp`} />
            </picture>
          </div>
          <div data-aos="fade-up" data-aos-delay="600">
            <picture>
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}/where4-pc.webp`} />
              <img alt="laptop" src={`${baseImgUrl}/where4-sp.webp`} />
            </picture>
          </div>
        </Slider>
      </section>
    </Fragment>
  );
};
