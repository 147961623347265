const localStorageHelper = {
  getItem(key) {
    try {
      if (typeof window !== 'undefined') {
        const value = window.localStorage.getItem(key);
        const ngValue = window.localStorage.getItem(`ng2-webstorage|${key}`);
        const returnValue = value || ngValue;
        return JSON.parse(returnValue);
      }
    } catch (err) {
      console.log(err);
      return null;
    }
    return undefined;
  },
  setItem(key, value) {
    try {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value || null));
      }
    } catch (err) {
      console.log(err);
    }
  },
  removeItem(key) {
    try {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem(key);
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default localStorageHelper;
