import React, { useState } from 'react';

const ProductItem = ({ product, imgUrl }) => {
  // State to track the currently selected SKU
  const [selectedSkuIndex, setSelectedSkuIndex] = useState(product.defaultSku || 0);

  const selectedSku = product.skus[selectedSkuIndex];

  return (
    <li className="black-collection__lineup-products-item">
      <div className="black-collection__lineup-products-img">
        <img
          src={`${imgUrl}/${selectedSku.productSrc}`}
          alt={`${product.no} - ${selectedSku.colorAlt}`}
        />
      </div>
      <div className="black-collection__lineup-products-details">
        <div className="black-collection__lineup-products-left">
          <p className="black-collection__lineup-products-no">{product.no}</p>
          <p className="black-collection__lineup-products-price">S${product.price}</p>
          <div className="black-collection__lineup-products-skus">
            {product.skus.map((sku, idx) => (
              <button
                key={sku.colorAlt}
                onClick={() => setSelectedSkuIndex(idx)}
                className={`black-collection__lineup-products-skus-item ${
                  selectedSkuIndex === idx ? 'active' : ''
                }`}
              >
                <img src={`${imgUrl}/${sku.colorSrc}`} alt={sku.colorAlt} />
              </button>
            ))}
          </div>
        </div>
        {/* <a href={selectedSku.href} className="black-collection__btn black-collection__btn--product">
          View Product
        </a> */}
      </div>
    </li>
  );
};

export default ProductItem;
