import React from 'react';
import Shimmer from '../../../CommonComponents/Shimmer/Shimmer';

export const PaymentPageSubmitShimmer = () => <Shimmer height={56} style={{ marginTop: 48 }} />;

export const PaymentPageShimmer = () => (
  <>
    <Shimmer line height={24} style={{ marginBottom: 8, maxWidth: 250 }} />
    <Shimmer line height={24} style={{ marginBottom: 32, maxWidth: 400 }} />
    <Shimmer line height={24} style={{ marginBottom: 16, maxWidth: 125 }} />
    <Shimmer height={364} />
    <PaymentPageSubmitShimmer />
  </>
);
