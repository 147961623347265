import React from 'react';
import './PrescriptionType.scss';
import Collapse from './Collapse';

const prescriptions = [
  {
    id: 'single_vision',
    statusPrescription: 'With prescription',
    title: 'Single Vision',
    description: 'Ideal for those needing distance or near vision correction',
  },
  {
    id: 'zero_power',
    statusPrescription: 'Without prescription',
    title: 'Zero Power',
    description: 'Protects your eyes by blocking 98% of harmful rays',
  },
  {
    id: 'frame_only',
    statusPrescription: 'Prescription at store',
    title: 'Get frame & replace lens at store',
    description: 'Visit any owndays store and get a one time free lens replacement ',
  },
];

const PrescriptionType = ({ setSelectedPrescriptionType, selectedPrescriptionType }) => {
  const handleCheck = id => {
    setSelectedPrescriptionType(id);
  };

  return (
    <div className="odslv-modal-pt">
      <h1 className="odslv-modal-pt__heading">Select glasses type</h1>
      {prescriptions.map(prescription => (
        <React.Fragment key={prescription.id}>
          <div className="odslv-modal-pt__status-prescription od-font-reg">
            {prescription.statusPrescription}
          </div>
          <Collapse
            description={prescription.description}
            isSelected={selectedPrescriptionType === prescription.id}
            title={prescription.title}
            titleIcon={prescription.titleIcon}
            onSelect={() => handleCheck(prescription.id)}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default PrescriptionType;
