import { useEffect } from "react";

const useAdobeFont = (kitId) => {
  useEffect(() => {
    const loadAdobeFont = () => {
      const script = document.createElement("script");
      script.src = `https://use.typekit.net/${kitId}.js`;
      script.async = true;
      script.onload = () => {
        try {
          window.Typekit.load({ async: true });
        } catch (e) {
          console.error("Adobe font failed to load", e);
        }
      };
      document.head.appendChild(script);
    };
    
    loadAdobeFont();
  }, [kitId]);
};

export default useAdobeFont;
