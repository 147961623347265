import React, { Fragment } from 'react';

import './FooterOwndaysSnap.scss';

export const FooterOwndaysSnap = ({ baseImgUrl }) => {
  const noticeList = [
    'Frame and SNAP LENS are sold as a set. Changing or purchasing only the SNAP LENS in the set is not available.',
    'If you experience any discomfort while using the product, please discontinue use immediately.',
    'Remove SNAP LENS when folding and unfolding the frame temples.',
    'SNAP LENS may be detached under impact. Please exercise caution when using the product.',
    'Lens replacement is not available for SNAP LENS.',
    'Avoid using the product for prolonged period of time before you are accustomed to the vision.',
    'Avoid using the product with high lens colour intensity when driving at night.',
    'The level of polarisation varies according to lens colour.',
    'If you experience headaches or any other discomfort while using the product, please discontinue use immediately and seek medical attention.',
    'Always detach SNAP LENS and store it in the dedicated SNAP LENS case to avoid scratching the lens.',
  ];

  return (
    <Fragment className="footer-owndays-snap">
      <section className="footer-owndays-snap__case__layout">
        <div className="footer-owndays-snap__case__messages">
          <h2 className="base-owndays-snap__title footer-owndays-snap__case__messages__title">
            <span className="base-owndays-snap__title--blue">O</span>RIGINAL{' '}
            <span className="base-owndays-snap__title--green">C</span>
            <span>ASE</span>
          </h2>
          <h4 className="footer-owndays-snap__case__messages__tag">
            Designed specifically for SNAP LENS
          </h4>
          <p className="footer-owndays-snap__case__messages__description">
            A handy case for carrying the SNAP LENS
          </p>
        </div>

        <img
          alt="OWNDAYS SNAP ORIGINAL CASE"
          className="lazyload footer-owndays-snap__case__image"
          height="330"
          src={`${baseImgUrl}/case.webp`}
          width="600"
        />
      </section>

      <section className="footer-owndays-snap__layout">
        <h2 className="base-owndays-snap__title footer-owndays-snap__title">
          <span className="base-owndays-snap__title--blue">N</span>
          <span>O</span>
          <span className="base-owndays-snap__title--green">T</span>
          <span>ICE</span>
        </h2>

        <h4 className="footer-owndays-snap__tag">Precautions for Use</h4>

        <ul className="footer-owndays-snap__list">
          {noticeList.map((notice, index) => (
            <li key={index} className="footer-owndays-snap__item">
              {notice}
            </li>
          ))}
        </ul>
      </section>
    </Fragment>
  );
};
