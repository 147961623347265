import React from 'react';
import './Pricing.scss';

const Pricing = ({
  currencySymbol,
  productOfferIdDetails,
  finalprice,
  dataLocale,
  localeInfo,
  isMobile = false,
  // isExchange,
  // exchangePrice,
  // exchangeMarketPrice,
  // exchangeText,
  // exchangeFreeText,
  // marketPrice,
  // inclusiveText = '',
  // blueStyle = {},
  // greyStyle = {},
  // secondaryStyle = {},
  productDetailsPrice = false,
  giftPrice = false,
  doNotShowTaxes = false,
  stylePricing = {},
  font,
  isPLP,
}) => {
  const tax = (dataLocale && dataLocale?.TAX) || 'tax';
  const currencyCode = dataLocale?.CURRENCY_CODE || '';

  return (
    // OD_NOT_REQ: _______Product exchange price_______
    // <>
    //   <div className="product-price-wrapper">
    //     {isExchange && exchangePrice && (
    //       <>
    //         <span className="text-color-dark-blue" style={blueStyle}>
    //           {currencySymbol}
    //           {Math.round(exchangePrice.price)}
    //         </span>
    //         &nbsp;
    //         {exchangeMarketPrice.price > exchangePrice.price && (
    //           <span className="text-color-light-grey strike-through" style={greyStyle}>
    //             {currencySymbol}
    //             {exchangeMarketPrice.price}
    //           </span>
    //         )}
    //         <div className="mr-t4">
    //           <span className="exchange-price text-color-dark-blue">
    //             {exchangePrice && exchangePrice.price > 0 ? exchangeText : exchangeFreeText}
    //           </span>
    //         </div>
    //       </>
    //     )}

    //     {!isExchange && (
    //     )}
    //   </div>
    // </>
    <div className={`product-price ${isMobile ? 'product-price--mobile' : ''}`}>
      <span
        className={`product-price__cost ${!isMobile && !font && 'od-font-bold'} ${
          isMobile ? 'product-price__cost--mobile' : ''
        }`}
        style={stylePricing}
      >
        {productOfferIdDetails?.text1 && <span>{productOfferIdDetails.text1}</span>}
        <span>
          <span className="product-price__cost__currency-code">{currencyCode}</span>
          <span className="product-price__cost__currency-symbol">{currencySymbol}</span>
          {!productDetailsPrice && !giftPrice && !isPLP && ' '}
          <span className="product-price__cost__final-price">{finalprice}</span>
        </span>
      </span>

      {/* {!productDetailsPrice &&
        !doNotShowTaxes &&
        localeInfo?.countryCode.toLowerCase() === 'sg' && (
          <span
            className={`product-price__tax ${!isMobile && 'od-font-reg'} ${
              isMobile ? 'product-price__tax--mobile' : ''
            }`}
          >
            (+ {tax.toLowerCase()})
          </span>
        )} */}

      {/* OD_NOT_REQ: Inclusive text_______ */}
      {/* <span className="text-color-light-secondary">{inclusiveText}</span> */}
    </div>
  );
};

export default Pricing;
