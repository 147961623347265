import React from 'react';
import './ProductPreview.scss';

const ProductPreview = ({ description, src }) => {
  return (
    <div className="od-product-preview">
      <div className="od-product-preview__content">
        <img alt={description} className="od-product-preview__content__img" src={src} />
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="od-product-preview__content__description"
        />
      </div>
    </div>
  );
};

export default ProductPreview;
