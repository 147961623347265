import React from 'react';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import HeaderContacts from '../HeaderContacts';
import BodyContacts from '../BodyContacts';
import FooterContacts from '../FooterContacts';
import '../../../../theme/pages/contacts.scss';
import { IMG_URL, LINEUP } from './constants';

const ClearContactLens = () => {
  const crumbs = [
    { path: '/', label: 'Homepage' },
    { path: '#', label: 'Contacts' },
    { path: '#', label: 'OWNDAYS CLEAR VISION' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="contacts-page contacts-page--clear-contactlens">
        <HeaderContacts
          bgImg={`${IMG_URL}/main-bg.webp`}
          headingImg={`${IMG_URL}/logo-main.svg`}
          headingText="OWNDAYS CLEAR VISION"
          subHeading="CONTACT LENS"
        />
        <BodyContacts imgUrl={IMG_URL} lineup={LINEUP} />
        <FooterContacts />
      </main>
    </>
  );
};

export default ClearContactLens;
