import React from 'react';
import './PrivacyPolicyPage.scss';
import { useSelector } from 'react-redux';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const PrivacyPolicyPage = () => {
  const dataLocale = useSelector(state => state.locale);
  const { PRIVACY_POLICE_PAGE } = dataLocale;
  const { title, html } = PRIVACY_POLICE_PAGE;
  return (
    <main>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Explore OWNDAYS Privacy Policy. Your data’s security is our priority. Understand how we handle your personal information. Act now, stay informed and secure!',
          },
          {
            name: 'twitter:description',
            content:
              'Explore OWNDAYS Privacy Policy. Your data’s security is our priority. Understand how we handle your personal information. Act now, stay informed and secure!',
          },
          {
            name: 'og:title',
            content: 'Privacy Policy | OWNDAYS Singapore',
          },
          {
            name: 'twitter:title',
            content: 'Privacy Policy | OWNDAYS Singapore',
          },
        ]}
        description="Explore OWNDAYS Privacy Policy. Your data’s security is our priority. Understand how we handle your personal information. Act now, stay informed and secure!"
        title="Privacy Policy | OWNDAYS Singapore"
      />
      <section className="od-privacy">
        <div className="od-privacy__title od-font-bold">{title}</div>
        <div dangerouslySetInnerHTML={{ __html: html }} className="od-privacy__content" />
      </section>
    </main>
  );
};

export default PrivacyPolicyPage;
