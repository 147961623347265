import * as actionTypes from './../../actionTypes/cancelOrder';
const initialState = {
  loading: false,
  headerTitle: null,
  hideNav: false,
  cancelProducts: [],
  cancelProductsList: [],
  currentReturnItem: null,
};

export default function cancelProducts(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_CANCEL_PRODUCTS:
      return Object.assign({}, prevState, {
        cancelProducts: action.products,
      });
    case actionTypes.SET_CANCEL_PRODUCTS_LIST:
      return Object.assign({}, prevState, {
        cancelProductsList: action.products,
      });
    case actionTypes.GET_CANCEL_REASONS:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_CANCEL_REASONS_SUCCESS:
      return Object.assign({}, prevState, {
        cancelReasons: action.data.cancelReasons,
        refundMethods: action.data.refundMethods,
        loading: false,
      });
    case actionTypes.GET_CANCEL_REASONS_FAIL:
      return Object.assign({}, prevState, {
        cancelReasonsErr: action.error,
        loading: false,
      });
    case actionTypes.CANCEL_ORDER_LOAD:
      return Object.assign({}, prevState, {
        cancelOrderLoading: true,
      });
    case actionTypes.CANCEL_ORDER_SUCCESS:
      return Object.assign({}, prevState, {
        cancelOrderResult: action.data,
        cancelOrderLoading: false,
        cancelOrderErr: false,
      });

    case actionTypes.CANCEL_ORDER_FAIL:
      return Object.assign({}, prevState, {
        cancelOrderErr: true,
        cancelOrderLoading: false,
      });
    case actionTypes.SET_CURRENT_CANCEL_REASON:
      return Object.assign({}, prevState, {
        currentCancelReason: action.reason,
      });
    case actionTypes.SET_CURRENT_REFUND_METHOD:
      return Object.assign({}, prevState, {
        currentRefundMethod: action.method,
      });
    default:
      return prevState;
  }
}
