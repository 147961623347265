export const CONTACT_LENS_DEALS = [
  {
    name: 'Clear Vision Daily',
    price: '$30/box',
    url: '/contacts/clear-contactlens/daily',
  },
  {
    name: 'Clear Vision Monthly',
    price: '$30/box',
    url: '/contacts/clear-contactlens/monthly',
  },
  {
    name: 'Clear Vision Blue Daily',
    price: '$40/box',
    url: '/contacts/clear-contactlens/daily-blue',
  },
];

export const TERMS = [
  "Offer is exclusive to customers who have not purchased any contact lenses from OWNDAYS previously and is subject to customers' suitability for contact lens wear.",
  "Offer is valid for eligible customers' first purchase of contact lenses only, with a minimum purchase of 1 box and up to a maximum of 3 boxes from the same series (e.g. Get 3 boxes of Clear Vision Blue free with 3 boxes of Clear Vision Blue purchased).",
  'Offer is valid for the purchase of all clear contact lenses (namely Clear Vision Daily, Clear Vision Monthly, and Clear Vision Blue), while stocks last.',
  'Items sold are non-refundable.',
  'Offer is valid for lenses used by the same wearer only and there shall be no combining of receipts.',
  'Offer is valid at selected OWNDAYS stores in Singapore that retail contact lenses.',
  'Offer cannot be used in conjunction with other discounts, vouchers, and/or promotions.',
  'The management reserves the right to amend the offer and the terms and conditions at any time without prior notice.',
];
