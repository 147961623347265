import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/phoneNumberCapture';
import config from 'config';
import apiClient from '../helpers/apiClient';
import { commonState } from './state';

let _client = null;
function* submitPhoneNumber({ data: { jsonData, phNo, expiry } }) {
  const url = config.apiPath.campaignData + '/MobileData';
  const successAction = { type: actionTypes.PHONE_NUMBER_CAPTURE_SUCCESS };
  const errorAction = { type: actionTypes.PHONE_NUMBER_CAPTURE_FAILED };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => _client.post(url, jsonData));
    successAction.data = data;
    successAction.phNo = phNo;
    successAction.expiry = expiry;
    yield put(successAction);
  } catch (err) {
    errorAction.error = err;
    yield put(errorAction);
  }
}

function* whatsappCapturePhoneNumber() {
  const reduxState = yield select(commonState);
  const { login, userInfo } = reduxState || {};
  const userPhone = userInfo?.result?.telephone || null;

  if (login && userPhone) {
    const finalData = { collectionData: [{ phone: userPhone }] };
    const url = config.apiPath.campaignData + '/camp_whatsapp_consent';
    const successAction = { type: actionTypes.ONLY_PHONE_CAPTURE_SUCCESS };
    const errorAction = { type: actionTypes.ONLY_PHONE_CAPTURE_FAILED };
    const client = { ..._client };
    client.setHeader();
    try {
      const response = yield call(async () => _client.post(url, finalData));
      successAction.data = response;
      yield put(successAction);
    } catch (err) {
      errorAction.error = err;
      yield put(errorAction);
    }
  }
}

export function* runPhoneNumberCaptureCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.PHONE_NUMBER_CAPTURE_SUBMIT, submitPhoneNumber),
    takeLatest(actionTypes.ONLY_PHONE_CAPTURE_SUBMIT, whatsappCapturePhoneNumber),
  ]);
}
