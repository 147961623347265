import * as actionTypes from '../actionTypes/checkout';

export function initiateJusPaySdk(payload) {
  return { type: actionTypes.CHECKOUT_INITIATE_JUSPAY, payload };
}

export function checkoutStepUpdateKey(key) {
  return { type: actionTypes.CHECKOUT_STEP_KEY, key };
}

export function updateSeletedPaymentMethod(key) {
  return { type: actionTypes.SELECTED_PAYMENT_METHOD, key };
}

export function saveGuestDetail(data) {
  return { type: actionTypes.SAVE_GUEST_INFO, data };
}

export function abandonLeadApi(data) {
  return { type: actionTypes.LOAD_ABANDON_LEAD, data };
}

export function htoLeadApi(data) {
  return { type: actionTypes.LOAD_HTO_LEAD, data };
}

export function managecheckoutBasicData(data) {
  return { type: actionTypes.MANAGE_CHECKOUT_DATA, data };
}

export function toggleCartModification(allow) {
  return { type: actionTypes.CART_MODIFICATION, allow };
}

export function getPaymentMethod(orderId, isExchange, isSalesman) {
  return { type: actionTypes.CARTSTEP3_LOAD, orderId, isExchange, isSalesman };
}

export function orderPayment(orderPaymentData, isExchange) {
  return { type: actionTypes.LOAD_PAYMENT_SUCCESS, orderPaymentData, isExchange };
}

export function saveShippingAddress(data, isExchange) {
  return { type: actionTypes.SAVE_SHIPPING_ADDRESS, data, isExchange };
}

export function addNewShippingAddressClick(data) {
  return { type: actionTypes.ADD_NEW_SHIPPING_ADDRESS_CLICK, data };
}

export function getDeliveryOptions(postcode, country) {
  return { type: actionTypes.GET_DELIVERY_OPTIONS, postcode, country };
}

export function updateDeliveryOption(type, postcode, country) {
  return { type: actionTypes.UPDATE_DELIVERY_OPTION, data: { type, postcode, country } };
}

export function showOOSfunc(val) {
  return { type: actionTypes.SHOW_OOS, val };
}

export function logoutUser() {
  return { type: actionTypes.LOGOUT_USER };
}

export function getCaptcha() {
  return { type: actionTypes.GET_CAPTCHA };
}

// Bin series

export function getCardOffers(cardNumber) {
  return { type: actionTypes.GET_CARDOFFER, cardNumber };
}

export function getOffers() {
  return { type: actionTypes.GET_OFFERS_LOAD };
}
export function preSalesUserLogin(data) {
  return { type: actionTypes.PRE_SALES_USER_LOGIN, data };
}

// Validate VPA
export function validateVpa(data) {
  return { type: actionTypes.VALIDATE_VPA, data };
}

// Validate VPA
export function resetVpa() {
  return { type: actionTypes.RESET_VPA };
}

// UPI Transaction Status
export function getUpiTransStatus(orderId) {
  return { type: actionTypes.GET_TRANS_STATUS_LOAD, orderId };
}

export function getJusPaymentStatusLoad(payload) {
  return { type: actionTypes.GET_JUSPAYMENT_STATUS_LOAD, payload };
}

export function getJusPaymentStatusSuccess(payload) {
  return { type: actionTypes.GET_JUSPAYMENT_STATUS_SUCCESS, payload };
}

export function getJusPaymentStatusFail(payload) {
  return { type: actionTypes.GET_JUSPAYMENT_STATUS_FAIL, payload };
}
