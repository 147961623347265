import React, { Fragment, useEffect } from 'react';
import Slider from 'react-slick';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { settingsForPC2007N2S, settingsForPC2008N2S } from './configSliders';
import { SKU_PC2007N2S, SKU_PC2008N2S } from './constants';

import './HeaderOwndaysPc.scss';

export const HeaderOwndaysPc = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'OWNDAYS PC' },
  ];

  const scrollToSection = target => {
    const targetElement = document.querySelector(target);

    if (targetElement) {
      const scrollPosition = targetElement.offsetTop - 100;

      window.location.hash = target;
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const renderProducts = products => {
    return products.map(item => (
      <li
        key={item.alt}
        className="header-owndays-pc__nav-item"
        data-aos="fade-left"
        data-aos-delay="600"
      >
        <img alt={item.alt} src={`${baseImgUrl}/${item.src}`} />

        <div className="base-owndays-pc__container">
          <a
            data-scroll
            className="base-owndays-pc__btn base-owndays-pc__btn--down"
            onClick={() => scrollToSection(item.link)}
          >
            {item.buttonName}
          </a>
        </div>
      </li>
    ));
  };

  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        scrollToSection(window.location.hash);
      }, 0);
    }
  }, [window.location.hash]);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-owndays-pc__container__banner">
        <div className="base-owndays-pc__container">
          <h1 className="header-owndays-pc__logo" data-aos="fade-right">
            <img alt="OWNDAYS PC" src={`${baseImgUrl}/owndayspc-logo.svg`} />
          </h1>

          <p className="header-owndays-pc__heading" data-aos="fade-right" data-aos-delay="300">
            SAVE YOUR EYES,{` `}
            <br />
            SHIELD YOUR EYES
          </p>

          <p className="header-owndays-pc__tag">
            <img
              alt="BLUE LIGHT 25% CUT"
              data-aos="zoom-in"
              data-aos-delay="600"
              src={`${baseImgUrl}/main-tag.svg`}
            />
          </p>
        </div>

        <div className="header-owndays-pc__product">
          <img alt="OWNDAYS PC" src={`${baseImgUrl}/main-visual-large.webp`} />
        </div>
      </section>

      <section className="header-owndays-pc__container__nav">
        <div className="base-owndays-pc__container">
          <p
            className="header-owndays-pc__nav-title header-owndays-pc__nav-title--long"
            data-aos="fade-down"
          >
            Protect your eyes from harmful BLUE LIGHT{' '}
            <br className="header-owndays-pc__nav-title__hide-new-line" />
            emitted by digital screens.
            <br />
            OWNDAYS PC reduces up to 25% blue light.
          </p>

          <p
            className="header-owndays-pc__nav-title header-owndays-pc__nav-title--short"
            data-aos="fade-down"
          >
            <b>2 types / 4 colours / S$60</b>
          </p>

          <div className="header-owndays-pc___nav-list">
            <Slider {...settingsForPC2007N2S}>{renderProducts(SKU_PC2007N2S)}</Slider>
            <Slider {...settingsForPC2008N2S}>{renderProducts(SKU_PC2008N2S)}</Slider>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
