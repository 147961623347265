import { call, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/search';
import config from 'config';
import apiClient from '../helpers/apiClient';
import algoliasearch from 'algoliasearch';

let _client = null;

function* getSearchData({ value }) {
  const successAction = { type: actionTypes.SEARCH_ALGOLIA_DATA_SUCCESS };

  const query = value.trim();

  const client = { ..._client };
  client.setHeader();
  if (query.length > 0) {
    try {
      const searchClient = algoliasearch('CCONZUESNX', 'cda35e5c76194e11f05b93e48b1aa02b');
      const index = searchClient.initIndex(config.apiPath.algoliaIndex);

      const data = yield index.search(query);

      successAction.algoliaSearchData = data;
    } catch (error) {
      console.error('Error:', error);
    }

    yield put(successAction);
  }
}

function* suggestionClick({ suggestionValue }) {
  const successAction = { type: actionTypes.SEARCH_DATA_SUCCESS };

  let query;
  let objectID;

  if (typeof suggestionValue === 'object') {
    query = suggestionValue.query.trim();
    objectID = suggestionValue.objectID.trim();
  } else {
    query = suggestionValue;
    objectID = suggestionValue;
  }

  const client = { ..._client };
  client.setHeader();
  const { url } = config.searchBar;
  if (query.length > 0) {
    const searchBody = {
      query: decodeURIComponent(query),
      objectID: decodeURIComponent(objectID),
      page: 0,
      'page-size': 15,
      filtersMap: {},
      similarProductId: '',
    };

    const result = yield call(async () => client.post(url, searchBody));

    successAction.data = result.result;
    successAction.query = query;

    yield put(successAction);
  }
}

export function* runSearchCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeEvery(actionTypes.SUGGESTION_CLICK, suggestionClick),
    takeLatest(actionTypes.SEARCH_INPUT_CHANGE, getSearchData),
  ]);
}
