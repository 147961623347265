import { call, put, all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/categoryCarousel';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;
function* getResource(resource, successAction, errorAction) {
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(resource));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getCategoryCarouselData(data) {
  yield call(
    getResource,
    config.apiPath.categoryCarouselUrl + data.categoryId,
    { type: actionTypes.SUCCESS_CATEGORYCAROUSEL_DATA },
    { type: actionTypes.FAILURE_CATEGORYCAROUSEL_DATA }
  );
}

export function* runCategoryCarouselCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.LOAD_CATEGORYCAROUSEL_DATA, getCategoryCarouselData)]);
}
