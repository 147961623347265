import './bottom-sheet.scss';
import React from 'react';

const BottomSheet = props => {
  const {
    backdrop,
    onClickBackdrop,
    expanded,
    addTopRadius,
    pageContext,
    sixOverSix,
    borderRadius,
    addressEditOrderDetail,
    className = '',
    backdropClassname = '',
  } = props;
  return (
    <div
      aria-label="bottom-sheet-container"
      className={`bottom-sheet-container ${expanded ? 'bottom-sheet-container-in' : ''} ${
        addressEditOrderDetail ? 'height-expand' : ''
      }`}
    >
      <React.Fragment>
        {backdrop && expanded && (
          <div
            aria-label="backdrop"
            className={`backdrop  ${expanded ? 'backdrop-in' : ''} ${backdropClassname}`}
            onClick={() => typeof onClickBackdrop === 'function' && onClickBackdrop()}
          ></div>
        )}
        <div
          aria-label="bottom-sheet"
          className={`bottom-sheet bg-color_white ${expanded ? 'bottom-sheet-in' : ''} ${
            addressEditOrderDetail ? 'height-expand' : ''
          } ${sixOverSix ? 'six-over-six-height' : ''} ${addTopRadius ? 'top-border' : ''} ${
            pageContext === 'category' || pageContext === 'search' ? ' top-border' : ''
          } ${borderRadius ? 'border-radius16' : ''} ${className}`}
        >
          {props.children}
        </div>
      </React.Fragment>
    </div>
  );
};

export default BottomSheet;
