import * as actionTypes from './../../actionTypes/DataLayer';
import { setAndTriggerDataLayer } from '../../utils/DataLayer';

const initialState = {
  visitorEmail: '',
  visitorMobile: '',
};

export default function reducer(prevState = initialState, action) {
  let dL;
  switch (action.type) {
    case actionTypes.SET_DATALAYER_EMAIL_MOBILE:
      const mobile = action?.data?.result?.telephone || '';
      dL = {
        ...prevState,
        visitorEmail: action.data.result.email || '',
        visitorMobile:
          typeof window !== 'undefined' && typeof window.btoa !== 'undefined'
            ? window.btoa(mobile)
            : '',
        gaMobile:
          mobile !== undefined &&
          mobile !== null &&
          typeof window !== 'undefined' &&
          typeof window.btoa !== 'undefined'
            ? window.btoa(mobile)
            : '',
        userType: action.data.result.hasPlacedOrder ? 'old' : 'new',
        website_source: `${action.data.result.countryCode} headless`,
      };
      setAndTriggerDataLayer(dL, false);
      return dL;
    case actionTypes.RESET_DATALAYER_EMAIL_MOBILE:
      dL = {
        ...prevState,
        visitorEmail: '',
        visitorMobile: '',
        gaMobile: null,
        userType: null,
      };
      setAndTriggerDataLayer(dL, false);
      return dL;
    default:
      return prevState;
  }
}
