import React, { useEffect, useState } from 'react';
import './FadeOD.scss';

const FadeOD = ({ show, children }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    render &&
    children && (
      <div
        style={{
          animation: `${show ? 'fadeIn' : 'fadeOut'} 1s`,
          position: 'relative',
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default FadeOD;
