import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ReceiveNews.scss';

const LIST_RECEIVE_NEWS = [
  { name: 'Email', isChecked: false, key: 'email' },
  { name: 'Text Message', isChecked: false, key: 'sms' },
];

const ReceiveNews = ({ receiveNews, setReceiveNews, termsAccepted, setTermsAccepted, agreeAll, setAgreeAll }) => {
  const [listReceiveNews, setListReceiveNews] = useState(LIST_RECEIVE_NEWS);

  useEffect(() => {
    if (agreeAll) {
      setTermsAccepted(true);
      setReceiveNews({ email: true, sms: true });
      setListReceiveNews(listReceiveNews.map(item => ({ ...item, isChecked: true })));
    }
  }, [agreeAll]);

  useEffect(() => {
    if (!termsAccepted) {
      setAgreeAll(false);
    } else {
      const allChecked = receiveNews.email && receiveNews.sms;
      setAgreeAll(allChecked);
    }
  }, [termsAccepted, receiveNews]);

  const handleSetReceiveNews = (key, value) => {
    setReceiveNews(prev => ({ ...prev, [key]: value }));
  };

  const toggleCheckboxChange = (key) => {
    const newValue = !receiveNews[key];
    handleSetReceiveNews(key, newValue);
    setListReceiveNews(listReceiveNews.map(item => 
      item.key === key ? { ...item, isChecked: newValue } : item
    ));
  };

  const handleAgreeAll = () => {
    const newAgreeAll = !agreeAll;
    setAgreeAll(newAgreeAll);
    
    if (!newAgreeAll) {
      // Uncheck all other buttons when "Agree All" is unchecked
      setTermsAccepted(false);
      setReceiveNews({ email: false, sms: false });
      setListReceiveNews(listReceiveNews.map(item => ({ ...item, isChecked: false })));
    } else {
      // Check all buttons when "Agree All" is checked
      setTermsAccepted(true);
      setReceiveNews({ email: true, sms: true });
      setListReceiveNews(listReceiveNews.map(item => ({ ...item, isChecked: true })));
    }
  };

  const handleTermsAccepted = () => {
    const newTermsAccepted = !termsAccepted;
    setTermsAccepted(newTermsAccepted);
    if (!newTermsAccepted) {
      setAgreeAll(false);
    }
  };

  return (
    <div className="receive-news">
      <ul className="receive-news__list">
        <li className='receive-news__item'>
          <input
            type="checkbox"
            id="terms-consent"
            className="receive-news__item__checkbox"
            checked={termsAccepted}
            onChange={handleTermsAccepted}
          />
          <label className="receive-news__item__name" htmlFor="terms-consent">
            I consent to the {' '}
            <Link to="/terms" className="terms-and-conditions-text__link">
              terms & conditions
            </Link>{' '}
            and the use of my personal data in accordance with the {' '}
            <Link to="/privacy" className="terms-and-conditions-text__link">
              privacy policy
            </Link>.
          </label>
        </li>
        <span>I prefer to be notified on latest news, exclusive offers and rewards via:</span>

        {listReceiveNews.map(item => (
          <li key={`receive-new-${item.name.toLowerCase()}`} className="receive-news__item">
            <input
              checked={receiveNews[item.key]}
              className="receive-news__item__checkbox"
              id={item.key}
              type="checkbox"
              onChange={() => toggleCheckboxChange(item.key)}
            />
            <label className="receive-news__item__name" htmlFor={item.key}>
              {item.name}
            </label>
          </li>
        ))}
        <li className='receive-news__item'>
          <input
            type="checkbox"
            id="agree-all"
            className="receive-news__item__checkbox"
            checked={agreeAll}
            onChange={handleAgreeAll}
          />
          <label className="receive-news__item__name" htmlFor="agree-all">
            I read and agree to all of the above
          </label>
        </li>
      </ul>
    </div>
  );
};

export default ReceiveNews;
