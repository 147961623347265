import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getSharktankData } from '../../actionCreators/common';

function Sharktank(props) {
  const { sharktankData } = props;
  const { PAGE_DATA } = sharktankData;

  useEffect(() => {
    getSharktankData();
  }, []);

  return PAGE_DATA ? (
    <div
      dangerouslySetInnerHTML={{
        __html: PAGE_DATA,
      }}
    />
  ) : null;
}

function mapStateToProps(state) {
  return {
    sharktankData: state.common.sharktankCommonData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSharktankData: isBot => dispatch(getSharktankData(isBot)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sharktank);
