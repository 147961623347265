import React, { useState } from 'react';
import HeaderZakuhead from './HeaderZakuhead';
import BodyZakuhead from './BodyZakuhead';
import FooterZakuhead from './FooterZakuhead';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import YouTubeModal from '../YouTubeModal/YouTubeModal';


const GundamZakuheadBase = ({ className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoId = '__kz_GvsgzQ';

  const baseUrl = 'https://www.owndays.com/sg/en/';
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/news/gundam5-zakuhead/';
  const currentUrl = window.location.href;
  const pageName = currentUrl.split('/').pop();
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news#all', label: 'News' },
    { path: '#', label: 'ZEON × OWNDAYS ZAKU HEAD CASE' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={breadcrumbs} />
      <div className="l-gundam5">
        {/* Header */}
        <HeaderZakuhead baseImgUrl={baseImgUrl} setIsModalOpen={setIsModalOpen} />

        {/* Body */}
        <BodyZakuhead baseImgUrl={baseImgUrl} />

        {/* Footer */}
        <FooterZakuhead baseImgUrl={baseImgUrl} baseUrl={baseUrl} pageName={pageName} />

        <YouTubeModal
          videoId={videoId}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </>
  );
};

export default GundamZakuheadBase;
