import * as actionTypes from './../../actionTypes/address';

const initialState = {
  locationData: { result: { states: [], country: [] } },
  userAddress: { result: [] },
  updateUserAddressData: null,
  deleteUserAddressData: false,
  isUpdateAddressLoading: false,
};

export default function address(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.LOCATION_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        locationData: action.data,
      });
    case actionTypes.USER_ADDRESS_SUCCESS:
      return Object.assign({}, prevState, {
        userAddress: action.data,
        addressLoaded: true,
        hideAddressLoader: action.hideLoader ? true : prevState.hideAddressLoader,
      });
    case actionTypes.PINCODE_DATA_LOAD:
      return { ...prevState, isPincodeDataLoading: true };
    case actionTypes.PINCODE_DATA_SUCCESS:
      return {
        ...prevState,
        pincodeData: action.result,
        pincodeError: null,
        isPincodeDataLoading: false,
      };
    case actionTypes.PINCODE_DATA_FAIL:
      return {
        ...prevState,
        pincodeData: null,
        pincodeError: action.error,
        isPincodeDataLoading: false,
      };
    case actionTypes.UPDATE_USER_ADDRESS_LOAD:
      return { ...prevState, isUpdateAddressLoading: true, deleteUserAddressData: false };
    case actionTypes.UPDATE_USER_ADDRESS_SUCCESS:
      return {
        ...prevState,
        isUpdateAddressLoading: false,
        updateUserAddressData: action.result,
        updateUserAddressError: null,
      };
    case actionTypes.UPDATE_USER_ADDRESS_FAIL:
      return {
        ...prevState,
        isUpdateAddressLoading: false,
        updateUserAddressData: null,
        updateUserAddressError: action.error,
      };
    case actionTypes.DELETE_USER_ADDRESS_LOAD:
      return { ...prevState, deleteUserAddressData: false, deleteUserAddressError: false };
    case actionTypes.DELETE_USER_ADDRESS_SUCCESS:
      const userAddress = { result: [] };
      userAddress.result = prevState.userAddress.result.filter(
        address => address.id !== action.addressId
      );
      return {
        ...prevState,
        deleteUserAddressData: true,
        deleteUserAddressError: false,
        userAddress,
      };
    case actionTypes.DELETE_USER_ADDRESS_FAIL:
      return { ...prevState, deleteUserAddressData: false, deleteUserAddressError: true };
    case actionTypes.CLEAR_STATE:
      return { ...prevState, ...action.data };
    case actionTypes.RESET_ADDRESS:
      return initialState;
    case actionTypes.REST_ADDRESS_ERROR_STATE:
      return { ...prevState, updateUserAddressError: null };
    default:
      return prevState;
  }
}
