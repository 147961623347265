import axios from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  bookAppointmentFailure,
  bookAppointmentSuccess,
  bookTestYourVisionAppointmentFailure,
  bookTestYourVisionAppointmentSuccess,
  bookTimeFailure,
  bookTimeSuccess,
  getStudioListFailure,
  getStudioListSuccess,
  getTimeSlotFailure,
  getTimeSlotSuccess,
  showLoader,
} from '../actionCreators/studioflow';
import { REFRESH_ORDER } from '../actionTypes/myAccount';
import {
  BOOK_APPOINTMENT,
  BOOK_TEST_YOUR_VISION,
  BOOK_TIME,
  GET_STORES,
  GET_TIME_SLOT,
} from '../actionTypes/studio-flow';
import config from '../config';
import getSetCookie from '../cookie';
import apiClient from '../helpers/apiClient';

let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

let _client = null;

function* getStores() {
  try {
    const client = { ..._client };
    client.setHeader();
    const data = yield call(async () =>
      client.get(`${process.env.API_JUNO}/v2/utility/admin/store/studio`)
    );
    yield put(getStudioListSuccess(data?.result?.stores || []));
  } catch (err) {
    yield put(getStudioListFailure(err));
  }
}

function* bookAppointment(action) {
  try {
    const client = { ..._client };
    client.setHeader();
    const data = yield call(async () =>
      client.post(`${process.env.API_JUNO}/v2/carts/shippingAddress`, action.payload)
    );
    yield put(bookAppointmentSuccess({ payload: data.result }));
  } catch (err) {
    yield put(bookAppointmentFailure(err));
  }
}

function* bookTime(action) {
  const client = { ..._client };
  client.setHeader();
  try {
    let data = yield call(async () =>
      client.post(
        `${process.env.API_JUNO}/v2/orders/${action.payload.orderId}/studio/slot`,
        action.payload.obj
      )
    );
    let result = {};
    if (action.payload.bookedStatus === 'reschedule') {
      result = yield call(async () =>
        client.post(
          `${process.env.API_JUNO}/v2/orders/store/appointment/reschedule?orderId=${action.payload.orderId}`,
          { ...action.payload.data, appointmentId: action.payload.appointmentId }
        )
      );
    } else {
      result = yield call(async () =>
        client.post(
          `${process.env.API_JUNO}/v2/orders/store/appointment/book?orderId=${action.payload.orderId}`,
          action.payload.data
        )
      );
    }

    data = data.result || {};
    data.bookApiResponse = result?.result?.data;
    yield put({ type: REFRESH_ORDER, payload: data });
    yield put(bookTimeSuccess(data));
  } catch (err) {
    yield put(bookTimeFailure(err));
  }
}

function* bookTestYourVisionAppointment(action) {
  const client = { ..._client };
  client.setHeader();
  const options = {
    headers: {
      'X-Api-Client': xApiClient,
    },
  };
  try {
    let data = yield call(async () =>
      client.post(`${config.apiPath.bookTestYourVisionAppointment}`, action.payload.data, options)
    );
    data = data.result || {};
    yield put(bookTestYourVisionAppointmentSuccess(data));
    if (action?.payload?.callback) action.payload.callback();
  } catch (err) {
    yield put(bookTestYourVisionAppointmentFailure(err));
  }
}

function* getTimeSlotGen(action) {
  const client = { ..._client };
  client.setHeader();
  try {
    yield put(showLoader(true));
    let data = yield call(async () => {
      const value = await axios.post(
        `${process.env.API_JUNO}/v2/orders/store/appointment/slots`,
        action.payload.data,
        {
          headers: {
            'x-api-client': 'pos_ios',
            'x-country-code': 'sg',
            'x-session-token': getSetCookie.getCookie('clientV1'),
          },
        }
      );
      return value;
    });
    data = data.data.result || {};
    yield put(
      getTimeSlotSuccess({
        data,
        formData: Object.assign(
          {},
          action.payload.values,
          { selectedDate: action.payload.selectedDate },
          { selectedTime: action.payload.selectedTime }
        ),
      })
    );
    yield put(showLoader(false));
  } catch (err) {
    yield put(showLoader(false));
    yield put(getTimeSlotFailure(err));
  }
}

export function* runStudioFlow(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(GET_STORES, getStores)]);
  yield all([takeLatest(BOOK_APPOINTMENT, bookAppointment)]);
  yield all([takeLatest(BOOK_TIME, bookTime)]);
  yield all([takeLatest(BOOK_TEST_YOUR_VISION, bookTestYourVisionAppointment)]);
  yield all([takeLatest(GET_TIME_SLOT, getTimeSlotGen)]);
}
