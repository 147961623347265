import * as actionTypes from './../../actionTypes/categoryCarousel';

const initialState = {
  loaded: false,
  loading: false,
  categoryCarouselData: [],
};

export default function categoryCarousel(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_CATEGORYCAROUSEL_DATA:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.SUCCESS_CATEGORYCAROUSEL_DATA:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        categoryCarouselData: action.data.result,
      });
    case actionTypes.FAILURE_CATEGORYCAROUSEL_DATA:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        categoryCarouselData: [],
      });
    default:
      return prevState;
  }
}
