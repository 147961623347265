import React, { Fragment } from 'react';

import { onImageLoadError } from '../../../utils/helper';

import Breadcrumbs from '../../CommonComponents/Breadcrumbs/Breadcrumbs';

import './HeaderOwndaysProgressiveCP.scss';

export const HeaderOwndaysProgressiveCP = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'Free Enhanced Progressive Lenses' },
  ];

  return (
    <Fragment>
      <div className="od-header-owndays-progressive-cp__custom-breadcrumbs">
        <Breadcrumbs crumbs={breadcrumbs} />
      </div>

      <div className="od-header-owndays-progressive-cp">
        <img
          alt="OWNDAYS Free Enhanced Progressive Lenses"
          className="od-header-owndays-progressive-cp__container"
          src={`${baseImgUrl}/main-pc-08-2024.webp`}
          onError={e => onImageLoadError(e)}
        />

        <div className="od-header-owndays-progressive-cp__container__desc">
          <div className="od-header-owndays-progressive-cp__header">
            <h1 className="od-header-owndays-progressive-cp__header__main">
              <span className="od-header-owndays-progressive-cp__header--red">FREE</span>
              <span className="od-header-owndays-progressive-cp__header--black">
                PROGRESSIVE <br />
                LENSES
              </span>
            </h1>
          </div>

          <div className="od-header-owndays-progressive-cp__body">
            <h2 className="od-header-owndays-progressive-cp__body__tag-pc">
              Progressive glasses from{' '}
              <span className="od-header-owndays-progressive-cp__body__tag-pc__price">$98</span>,
              now for all customers!
            </h2>

            <h2 className="od-header-owndays-progressive-cp__body__tag-sp">
              Progressive glasses from{' '}
              <span className="od-header-owndays-progressive-cp__body__tag-sp__price">$98</span>
            </h2>

            <h2 className="od-header-owndays-progressive-cp__body__tag-sp od-header-owndays-progressive-cp__body__tag-sp--now">
              Now for all customers!
            </h2>

            <h3>
              One pair of glasses to see far, intermediate {`and `}
              <br />
              reading zones
            </h3>
          </div>
        </div>

        <span className="od-header-owndays-progressive-cp__remark">
          * Free Enhanced Progressive Lens only, other T&Cs apply
        </span>
      </div>
    </Fragment>
  );
};
