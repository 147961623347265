import * as actionTypes from './../../actionTypes/plpBanner';

const initialState = {
  plpBannerData: {},
  showBanner: false,
};

export default function plpBanner(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PLP_BANNER_CONFIG_SUCCESS:
      return Object.assign({}, prevState, {
        plpBannerData: action.data,
        showBanner: true,
      });
    case actionTypes.GET_PLP_BANNER_CONFIG_FAIL:
      return Object.assign({}, prevState, {
        plpBannerData: undefined,
      });
    case actionTypes.CLEAR_PLP_BANNER_CONFIG:
      return Object.assign({}, prevState, {
        plpBannerData: undefined,
        showBanner: false,
      });
    default:
      return prevState;
  }
}
