import { call, put, takeLatest, takeEvery, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/savedCards';
import apiClient from '../helpers/apiClient';
import config from 'config';

let _client = null;

function* getUsersSavedCards({ gatewayId, orderId, offer }) {
  let successActionOffer = null;
  let errorActionOffer = null;
  let successAction = null;
  let errorAction = null;
  let filterByOffer = '';
  if (offer) {
    successActionOffer = { type: actionTypes.GET_USER_SAVED_CARDS_OFFER_SUCCESS };
    errorActionOffer = { type: actionTypes.GET_USER_SAVED_CARDS_OFFER_FAIL };
    filterByOffer = '&filterByOffer=true';
  } else {
    successAction = { type: actionTypes.GET_USER_SAVED_CARDS_SUCCESS };
    errorAction = { type: actionTypes.GET_USER_SAVED_CARDS_FAIL };
  }
  const client = { ..._client };
  const gatewayID = gatewayId || 'PU';
  client.setHeader();
  try {
    const orderID = orderId ? `&orderId=${orderId}` : '';
    const result = yield call(async () =>
      client.get(
        `${config.apiPath.usersSavedCards}customer?gatewayId=${gatewayID}${orderID}${filterByOffer}`
      )
    );
    if (offer) {
      successActionOffer.result = result;
      yield put(successActionOffer);
    } else {
      successAction.result = result;
      yield put(successAction);
    }
  } catch (error) {
    if (offer) {
      errorActionOffer.error = error;
      yield put(errorActionOffer);
    } else {
      errorAction.error = error;
      yield put(errorAction);
    }
  }
}

function* deleteUserSavedCard(userData) {
  const successAction = { type: actionTypes.DELETE_USER_SAVED_CARD_SUCCESS };
  const errorAction = { type: actionTypes.DELETE_USER_SAVED_CARD_FAIL };
  const loadAction = { type: actionTypes.GET_USER_SAVED_CARDS_LOAD };
  const client = { ..._client };
  client.setHeader();
  try {
    const gatewayId = '?gatewayId=PU';
    const emailId = userData.emailId ? `&emailId=${userData.emailId}` : '';
    const cardToken = userData.cardToken ? userData.cardToken : '';
    const data = yield call(async () =>
      client.delete(`${config.apiPath.usersSavedCards}/${cardToken}${gatewayId}${emailId}`)
    );
    successAction.data = data;
    yield put(successAction);
    yield put(loadAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runSavedCardsCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeEvery(actionTypes.GET_USER_SAVED_CARDS_LOAD, getUsersSavedCards),
    takeLatest(actionTypes.DELETE_USER_SAVED_CARD_LOAD, deleteUserSavedCard),
  ]);
}
