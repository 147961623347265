export const LineupScript = () => {
  $('.slick--glasses').slick({
    arrows: false,
    dots: false,
    centerMode: true,
    variableWidth: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          variableWidth: false,
        },
      },
    ],
  });
  $('.slick--sunglasses').slick({
    arrows: false,
    dots: false,
    centerMode: true,
    variableWidth: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          variableWidth: false,
        },
      },
    ],
  });

  $('#glasses-nav .lineup__product-nav-btn--prev').on('click', function () {
    $('.slick--glasses').slick('slickPrev');
  });
  $('#glasses-nav .lineup__product-nav-btn--next').on('click', function () {
    $('.slick--glasses').slick('slickNext');
  });
  $('#sunglasses-nav .lineup__product-nav-btn--prev').on('click', function () {
    $('.slick--sunglasses').slick('slickPrev');
  });
  $('#sunglasses-nav .lineup__product-nav-btn--next').on('click', function () {
    $('.slick--sunglasses').slick('slickNext');
  });
  $('#glasses-colors li button').on('click', function () {
    const tab_target = $(this).attr('data-target');
    const tab_color = $(this).attr('data-color');
    const tab_url = $(this).attr('data-url');

    $('.slick--glasses').hide();

    $('#glasses-colors li button').removeClass('active');

    $('#' + tab_target).show();
    $('#' + tab_target).slick('setPosition');
    $('#glasses-color').text(tab_color);
    $('#glasses-link').attr('href', `${tab_url}`);
    $(this).addClass('active');

    $('#glasses-nav .lineup__product-nav-btn--prev').on('click', function () {
      $('#' + tab_target).slick('slickPrev');
    });
    $('#glasses-nav .lineup__product-nav-btn--next').on('click', function () {
      $('#' + tab_target).slick('slickNext');
    });
  });
  $('#sunglasses-colors li button').on('click', function () {
    const tab_target = $(this).attr('data-target');
    const tab_color = $(this).attr('data-color');
    const tab_url = $(this).attr('data-url');

    $('.slick--sunglasses').hide();

    $('#sunglasses-colors li button').removeClass('active');

    $('#' + tab_target).show();
    $('#' + tab_target).slick('setPosition');
    $('#sunglasses-color').text(tab_color);
    $('#sunglasses-link').attr('href', `${tab_url}`);
    $(this).addClass('active');

    $('#sunglasses-nav .lineup__product-nav-btn--prev').on('click', function () {
      $('#' + tab_target).slick('slickPrev');
    });
    $('#sunglasses-nav .lineup__product-nav-btn--next').on('click', function () {
      $('#' + tab_target).slick('slickNext');
    });
  });
};
