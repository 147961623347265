import React from 'react';
import { TERMS } from './constants';

const ModalFreeMyopiaControl2Lenses = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('fmc2l__modal-overlay')) {
      onClose();
    }
  };

  return (
    <div className="fmc2l__modal" onClick={handleOverlayClick}>
      <div className="fmc2l__modal-overlay">
        <div className="fmc2l__modal-content">
          <button className="fmc2l__modal-close" onClick={onClose}>
            <img
              src="https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/icon-close.svg"
              alt="Icon close"
            />
          </button>
          <h2>Terms & Conditions</h2>
          {TERMS && (
            <ul>
              {TERMS.map((term, index) => (
                <li key={index}>{term}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalFreeMyopiaControl2Lenses;
