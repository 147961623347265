import React from 'react';

import {
  LENS_REPLACEMENT_IMG_OPTIONS,
  LENS_REPLACEMENT_STEPS,
  LENS_REPLACEMENT_OPTIONS,
} from './constants';

import './BodyLensReplacement.scss';

export const BodyLensReplacement = ({ baseImgUrl }) => {
  const renderRefractiveIndex = (refractiveIndex, additionalInfo) => {
    const tooltipText = `The higher the refractive index, the thinner the lenses. Thickness of the lenses also depends on the power.\nDo note that thinner lenses may not always be the most suitable option. For instance, 1.60 lenses are less likely to crack compared to 1.74 lenses if the lens power is low.`;

    return (
      <tr>
        <th>
          Refractive Index
          <div className="body-lens-replacement__lens__tooltip-wrapper">
            <a className="body-lens-replacement__lens__table__tooltip" data-title={tooltipText}>
              <img alt="" src={`${baseImgUrl}/help-1.png`} />
            </a>
          </div>
        </th>
        <td>
          <p>{`${refractiveIndex} ${additionalInfo}`}</p>
        </td>
      </tr>
    );
  };

  return (
    <div className="base-lens-replacement__container">
      <section className="body-lens-replacement__price base-lens-replacement__content--inner">
        <h2>Basic Charge</h2>

        <table className="body-lens-replacement__price__images">
          <tbody>
            <tr>
              {LENS_REPLACEMENT_IMG_OPTIONS.map(
                ({ alt, src, text, additionalClass = '' }, index) => (
                  <td
                    key={index}
                    className={`body-lens-replacement__price__images__option ${additionalClass}`}
                  >
                    <img alt={alt} src={`${baseImgUrl}${src}`} />
                    <p dangerouslySetInnerHTML={{ __html: text.replace('\n', '<br />') }} />
                  </td>
                )
              )}

              <td className="body-lens-replacement__price__price-value body-lens-replacement__price__price-value--hide-sp">
                <p>S$98</p>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="body-lens-replacement__price__price-value body-lens-replacement__price__price-value--hide-pc">
          <p>S$98</p>
        </div>

        <div className="base-lens-replacement__content">
          <p className="body-lens-replacement__price__detail">
            Let us help you extend the lifespan of spectacles that you cherish by replacing the
            lenses. Simply bring the spectacles that you can no longer use due to changes in your
            prescription or damage to the lenses to any OWNDAYS store near you. We are happy to
            replace the lenses of spectacles purchased from other shops too.
          </p>

          <ul className="base-lens-replacement__list-remark">
            <li>Price includes eye test, frame fitting, lens processing and frame cleaning.</li>
            <li>
              Service may not be available for some spectacles due to constraints in frame condition
              and shape.
            </li>
          </ul>
        </div>
      </section>

      <section className="body-lens-replacement__lens base-lens-replacement__content--inner">
        <h2>Option Lenses</h2>

        <ul className="body-lens-replacement__lens">
          {LENS_REPLACEMENT_OPTIONS.map((lens, index) => (
            <li key={index} className={`body-lens-replacement__lens__option ${lens.class}`}>
              <div className="body-lens-replacement__lens__name">
                <h3>{lens.name}</h3>
                <div className="body-lens-replacement__lens__tag-layout">
                  {lens.tags.map((tag, tagIndex) => (
                    <p key={tagIndex} className={tag.class}>
                      <span>{tag.label}</span>
                    </p>
                  ))}
                </div>
              </div>

              <table className="body-lens-replacement__lens__table">
                <tbody>
                  {renderRefractiveIndex(lens.refractiveIndex, lens.additionalInfo || '')}
                  {lens.features.length > 0 && (
                    <tr>
                      <th>Features</th>
                      <td>
                        <ul>
                          {lens.features.map((feature, featureIndex) => (
                            <li key={featureIndex}>
                              <p>{feature}</p>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  )}

                  {lens.colors?.length > 0 && (
                    <tr>
                      <th>Color</th>
                      <td>
                        <ul>
                          {lens.colors.map((color, colorIndex) => (
                            <li key={colorIndex}>
                              <p>{color}</p>
                            </li>
                          ))}
                        </ul>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </li>
          ))}
        </ul>

        <div className="base-lens-replacement__content">
          <ul className="base-lens-replacement__list-remark">
            <li>Prices shown are for the replacement of a pair of lenses.</li>
            <li>OWNDAYS reserves the right to change the prices without prior notice.</li>
            <li>
              If special processing is required (such as for rimless frames), an additional cost may
              apply.
            </li>
            <li>
              Lens replacement service cannot be provided for some frames due to the condition and
              shape.
            </li>
          </ul>

          <div className="body-lens-replacement__lens__btn-layout">
            <a href="/sg/en/services/lens">Click here for Lenses details</a>
          </div>
        </div>
      </section>

      <section className="body-lens-replacement__steps base-lens-replacement__content--inner">
        <h2>Lens Replacement Steps</h2>
        <ul className="body-lens-replacement__steps__list">
          {LENS_REPLACEMENT_STEPS.map((step, index) => (
            <li
              key={index}
              className={`body-lens-replacement__steps__step body-lens-replacement__steps__step--${
                index + 1
              }`}
            >
              <h3>
                <span>{step.stepNumber}</span>
                {step.title}
              </h3>
              <p>{step.description}</p>
              {step.additionalInfo}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};
