import React from 'react';

import { LINEUP_MENU } from '../constants';

export const LineupNav = ({ onClick }) => (
  <ul className="body-owndays-sunglasses__lineup-nav__ul">
    {LINEUP_MENU.map(({ id, title, imgSrc, alt }) => (
      <li key={id}>
        <a onClick={() => onClick(`#${id}`)}>
          <img alt={alt} className="lazyload" src={imgSrc} />
          <h3>{title}</h3>
        </a>
      </li>
    ))}
  </ul>
);
