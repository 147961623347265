import React, { useEffect, useState } from 'react';

function Lineup({ baseImgUrl }) {
  const models = [
    /* type 1 */
    {
      sku: 'HWF2003N-3A',
      imgSrc: ['model-square-c1.webp', 'model-square-c2.webp'],
      title: 'RECTANGLE',
      titleTop: '-142px',
      description:
        'The popular rectangle style makes a return for Generation 2. Look smart with this classic design that is versatile enough for both casual and formal occasions.',
      tabs: [
        {
          id: 'C1',
          images: [
            'HWF2003N-3A_C1_08_02.jpg',
            'HWF2003N-3A_C1_01.jpg',
            'HWF2003N-3A_C1_02.jpeg',
            'HWF2003N-3A_C1_03.jpg',
            'HWF2003N-3A_C1_04.jpg',
            'HWF2003N-3A_C1_05.jpeg',
            'HWF2003N-3A_C1_06.jpeg',
            'HWF2003N-3A_C1_07.jpg',
            'HWF2003N-3A_C1_08_01.jpg',
          ],
          color: 'Black',
          colorCode: '#000',
          price: 'S$398',
          productUrl: '',
        },
        {
          id: 'C2',
          images: [
            'HWF2003N-3A_C2_08_02.jpg',
            'HWF2003N-3A_C2_01.jpg',
            'HWF2003N-3A_C2_02.jpeg',
            'HWF2003N-3A_C2_03.jpg',
            'HWF2003N-3A_C2_04.jpg',
            'HWF2003N-3A_C2_05.jpeg',
            'HWF2003N-3A_C2_06.jpeg',
            'HWF2003N-3A_C2_07.jpg',
            'HWF2003N-3A_C2_08_01.jpg',
          ],
          color: 'Clear Grey',
          colorCode: '#999',
          price: 'S$398',
          productUrl: '',
        },
      ],
      snap: {
        imgSrc: 'HWF2003N-3A_snap.jpg',
        productTitle: 'HWF2003Le-N Black',
        productPrice: 'S$20',
        productUrl: '',
      },
    },
    /* type 2 */
    {
      sku: 'HWF2004N-3A',
      imgSrc: ['model-browsquare-c1.webp', 'model-browsquare-c2.webp'],
      title: 'BROWLINE RECTANGLE',
      titleTop: '-174px',
      description:
        'A stylish browline design in rectangle style made of a combination of resin and metal. This is the perfect choice for those looking to try something less conventional.',
      tabs: [
        {
          id: 'C1',
          images: [
            'HWF2004N-3A_C1_08_02.jpg',
            'HWF2004N-3A_C1_01.jpg',
            'HWF2004N-3A_C1_02.jpeg',
            'HWF2004N-3A_C1_03.jpg',
            'HWF2004N-3A_C1_04.jpg',
            'HWF2004N-3A_C1_05.jpeg',
            'HWF2004N-3A_C1_06.jpeg',
            'HWF2004N-3A_C1_07.jpg',
            'HWF2004N-3A_C1_08_01.jpg',
          ],
          color: 'Black',
          colorCode: '#000',
          price: 'S$398',
          productUrl: '',
        },
        {
          id: 'C2',
          images: [
            'HWF2004N-3A_C2_08_02.jpg',
            'HWF2004N-3A_C2_01.jpg',
            'HWF2004N-3A_C2_02.jpeg',
            'HWF2004N-3A_C2_03.jpg',
            'HWF2004N-3A_C2_04.jpg',
            'HWF2004N-3A_C2_05.jpeg',
            'HWF2004N-3A_C2_06.jpeg',
            'HWF2004N-3A_C2_07.jpg',
            'HWF2004N-3A_C2_08_01.jpg',
          ],
          color: 'Clear Grey',
          colorCode: '#999',
          price: 'S$398',
          productUrl: '',
        },
      ],
      snap: {
        imgSrc: 'HWF2004N-3A_snap.jpg',
        productTitle: 'HWF2004Le-N Black',
        productPrice: 'S$20',
        productUrl: '',
      },
    },
    /* type 3 */
    {
      sku: 'HWF2005N-3A',
      imgSrc: ['model-wellington-c1.webp', 'model-wellington-c2.webp'],
      title: 'WELLINGTON',
      titleTop: '-142px',
      description:
        'A crowd-favourite featuring a rectangular shape with rounded edges. This is a style that will create a friendly, approachable first impression.',
      tabs: [
        {
          id: 'C1',
          images: [
            'HWF2005N-3A_C1_08_02.jpg',
            'HWF2005N-3A_C1_01.jpg',
            'HWF2005N-3A_C1_02.jpeg',
            'HWF2005N-3A_C1_03.jpg',
            'HWF2005N-3A_C1_04.jpg',
            'HWF2005N-3A_C1_05.jpeg',
            'HWF2005N-3A_C1_06.jpeg',
            'HWF2005N-3A_C1_07.jpg',
            'HWF2005N-3A_C1_08_01.jpg',
          ],
          color: 'Black',
          colorCode: '#000',
          price: 'S$398',
          productUrl: '',
        },
        {
          id: 'C2',
          images: [
            'HWF2005N-3A_C2_08_02.jpg',
            'HWF2005N-3A_C2_01.jpg',
            'HWF2005N-3A_C2_02.jpeg',
            'HWF2005N-3A_C2_03.jpg',
            'HWF2005N-3A_C2_04.jpg',
            'HWF2005N-3A_C2_05.jpeg',
            'HWF2005N-3A_C2_06.jpeg',
            'HWF2005N-3A_C2_07.jpg',
            'HWF2005N-3A_C2_08_01.jpg',
          ],
          color: 'Clear Brown',
          colorCode: '#7E756E',
          price: 'S$398',
          productUrl: '',
        },
      ],
      snap: {
        imgSrc: 'HWF2005N-3A_snap.jpg',
        productTitle: 'HWF2005Le-N Black',
        productPrice: 'S$20',
        productUrl: '',
      },
    },
    /* type 4 */
    {
      sku: 'HWF2006N-3A',
      imgSrc: ['model-bostonwellington-c1.webp', 'model-bostonwellington-c2.webp'],
      title: `BOSTON <br/>WELLINGTON`,
      titleTop: '-162px',
      description:
        'A unisex design that is in-between the round and the rectangle style. Being stylish, modern and retro all at once, this is the ideal option to stand out from the crowd.',
      tabs: [
        {
          id: 'C1',
          images: [
            'HWF2006N-3A_C1_08_02.jpg',
            'HWF2006N-3A_C1_01.jpg',
            'HWF2006N-3A_C1_02.jpeg',
            'HWF2006N-3A_C1_03.jpg',
            'HWF2006N-3A_C1_04.jpg',
            'HWF2006N-3A_C1_05.jpeg',
            'HWF2006N-3A_C1_06.jpeg',
            'HWF2006N-3A_C1_07.jpg',
            'HWF2006N-3A_C1_08_01.jpg',
          ],
          color: 'Black',
          colorCode: '#000',
          price: 'S$398',
          productUrl: '',
        },
        {
          id: 'C2',
          images: [
            'HWF2006N-3A_C2_08_02.jpg',
            'HWF2006N-3A_C2_01.jpg',
            'HWF2006N-3A_C2_02.jpeg',
            'HWF2006N-3A_C2_03.jpg',
            'HWF2006N-3A_C2_04.jpg',
            'HWF2006N-3A_C2_05.jpeg',
            'HWF2006N-3A_C2_06.jpeg',
            'HWF2006N-3A_C2_07.jpg',
            'HWF2006N-3A_C2_08_01.jpg',
          ],
          color: 'Clear Brown',
          colorCode: '#7E756E',
          price: 'S$398',
          productUrl: '',
        },
      ],
      snap: {
        imgSrc: 'HWF2006N-3A_snap.jpg',
        productTitle: 'HWF2006Le-N Black',
        productPrice: 'S$20',
        productUrl: '',
      },
    },
  ];
  const [activeTabs, setActiveTabs] = useState([1, 0, 1, 0]);

  const handleTabClick = (modelIndex, tabIndex) => {
    const newActiveTabs = [...activeTabs];
    newActiveTabs[modelIndex] = tabIndex;
    setActiveTabs(newActiveTabs);
  };

  useEffect(() => {
    const handleLoad = () => {
      document.querySelectorAll('.l-huawei2-lineup .tab-pane').forEach(function (tab, index) {
        index += 1;

        const swiperContainer = tab.querySelector('.swiper');
        const nextButton = tab.querySelector('.l-huawei2-lineup__slider-btn--next');
        const prevButton = tab.querySelector('.l-huawei2-lineup__slider-btn--prev');

        swiperContainer.classList.add('swiper--' + index);
        nextButton.classList.add('l-huawei2-lineup__slider-btn--next-' + index);
        prevButton.classList.add('l-huawei2-lineup__slider-btn--prev-' + index);

        const swiperLineup = new Swiper('.l-huawei2-lineup .swiper--' + index, {
          initialSlide: 1,
          slidesPerView: 'auto',
          spaceBetween: 13,
          loop: true,
          centeredSlides: true,
          navigation: {
            nextEl: '.l-huawei2-lineup__slider-btn--next-' + index,
            prevEl: '.l-huawei2-lineup__slider-btn--prev-' + index,
          },
          breakpoints: {
            768: {
              initialSlide: 0,
              slidesPerView: 2,
              spaceBetween: 17,
              centeredSlides: false,
            },
          },
        });

        swiperLineup;
      });
    };

    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <section className="l-huawei2-lineup" data-aos="fade-up" data-aos-offset="300" id="lineup">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2__heading l-huawei2__heading--lg">LINEUP</h2>
        {models.map((model, modelIndex) => (
          <div key={modelIndex} className="l-huawei2-lineup__section">
            <div className="l-huawei2-lineup__model-container">
              <div className="l-huawei2-lineup__model-img-wrapper">
                <div className="position-relative">
                  {model.tabs.map((tab, tabIndex) => (
                    <picture
                      key={tabIndex}
                      className={`${activeTabs[modelIndex] === tabIndex ? 'active' : ''}`}
                    >
                      <img
                        alt={`${model.sku} ${tab.id}`}
                        src={`${baseImgUrl}${model.imgSrc[tabIndex]}`}
                      />
                      {tabIndex === 1 && (
                        <span className="l-huawei2-lineup__model-img-note">
                          * SNAP LENS sold separately
                        </span>
                      )}
                    </picture>
                  ))}
                </div>
              </div>
              <div className="l-huawei2-lineup__model-detail">
                <h3
                  dangerouslySetInnerHTML={{ __html: model.title }}
                  className={`l-huawei2-lineup__title ${
                    modelIndex !== 2 && modelIndex !== 3 ? 'l-huawei2-lineup__title--large' : ''
                  }`}
                  style={{ '--t': model.titleTop }}
                ></h3>
                <p className="l-huawei2-lineup__desc">{model.description}</p>
              </div>
            </div>
            <div className="l-huawei2-lineup__tab-content tab-content">
              {model.tabs.map((tab, tabIndex) => (
                <div
                  key={tabIndex}
                  className={`tab-pane ${activeTabs[modelIndex] === tabIndex ? 'active' : ''}`}
                >
                  <div className="tab-pane-inner">
                    <div className="l-huawei2-lineup__slider">
                      <div className="swiper">
                        <ul className="swiper-wrapper">
                          {tab.images.map((imgSrc, imgIndex) => (
                            <li key={imgIndex} className="swiper-slide">
                              <img alt={`${model.sku} ${tab.id}`} src={`${baseImgUrl}${imgSrc}`} />
                              {(imgIndex === 5 || imgIndex === 6 || imgIndex === 7) && (
                                <p className="snap-text">* SNAP LENS sold separately</p>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="l-huawei2-lineup__proddetail">
                      <p className="l-huawei2-lineup__prodtitle">{model.sku}</p>
                      <div className="l-huawei2-lineup__prodcol">
                        <p className="l-huawei2-lineup__prodcolor">
                          {tab.id} {tab.color}
                        </p>
                        <p className="l-huawei2-lineup__prodprice">{tab.price}</p>
                      </div>
                      <div className="l-huawei2-lineup__slider-btn-container">
                        <button
                          className="l-huawei2-lineup__slider-btn l-huawei2-lineup__slider-btn--prev"
                          type="button"
                        ></button>
                        <button
                          className="l-huawei2-lineup__slider-btn l-huawei2-lineup__slider-btn--next"
                          type="button"
                        ></button>
                      </div>
                      <a className="l-huawei2__btn l-huawei2__btn--lg" href={tab.productUrl}>
                        Purchase Online
                      </a>
                    </div>
                  </div>
                </div>
              ))}
              <ul className="l-huawei2-lineup__nav nav">
                {model.tabs.map((tab, tabIndex) => (
                  <li key={tabIndex} className="nav-item">
                    <button
                      className={`nav-link ${activeTabs[modelIndex] === tabIndex ? 'active' : ''}`}
                      style={{ '--bg': tab.colorCode }}
                      type="button"
                      onClick={() => handleTabClick(modelIndex, tabIndex)}
                    ></button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="l-huawei2-lineup__snap">
              <div className="l-huawei2-lineup__snap-inner">
                <img alt={model.snap.productTitle} src={`${baseImgUrl}${model.snap.imgSrc}`} />
                <p className="l-huawei2-lineup__snap-des">
                  Fitted with polarised lenses that can reduce glare from <br />
                  the road and water surface
                </p>
                <div className="l-huawei2-lineup__snap-detail-container">
                  <h4 className="l-huawei2-lineup__snap-title">SNAP LENS sold separately</h4>
                  <p className="l-huawei2-lineup__snap-prodtitle">{model.snap.productTitle}</p>
                  <p className="l-huawei2-lineup__snap-prodprice">{model.snap.productPrice}</p>
                </div>
                <a className="l-huawei2__btn l-huawei2__btn--lg" href={model.snap.productUrl}>
                  Purchase Online
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
      <style>
        {`
          .l-huawei2-lineup__nav .nav-link {
            background-color: var(--bg);
          }
          .l-huawei2-lineup__model-detail .l-huawei2-lineup__title {
            top: var(--t);
          }
          @media(min-width: 1240px) {
            :root {
              --edge: calc(( 1200px - ( 20px * 2) - var(--body-w) ) / 2);
            }
            .l-huawei2-lineup__section:nth-child(even) .l-huawei2-lineup__model-img-wrapper,
            .l-huawei2 .lineup__section:nth-child(odd) .lineup__model-detail,
            .l-huawei2-lineup__section:nth-child(odd) .l-huawei2-lineup__model-detail {
              margin-right: var(--edge);
            }
          }
          @media(min-width: 2000px) {
            :root {
              --edge: calc(((2000px - 1240px) / 2 + 20px) * -1);
            }
          }
        `}
      </style>
    </section>
  );
}

export default Lineup;
