import React from 'react';
import ProductItem from './ProductItem';

const ProductTabs = ({ products, imgUrl }) => {
  return (
    <ul className="black-collection__lineup-products">
      {products.map(product => (
        <ProductItem key={product.no} product={product} imgUrl={imgUrl} />
      ))}
    </ul>
  );
};

export default ProductTabs;
