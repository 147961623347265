import * as actionTypes from '../actionTypes/cart';

export function getCart(
  reorderId,
  applyWallet = null,
  isCartUpdating = null,
  emailId = null,
  isExchange
) {
  return {
    type: actionTypes.GET_CART_DATA_LOAD,
    reorderId,
    applyWallet,
    isCartUpdating,
    emailId,
    isExchange,
  };
}

export function updateCartItem(itemid, count, flag) {
  return { type: actionTypes.UPDATE_CART_LOAD, itemid, count, flag };
}

export function updateCart(itemid, params) {
  return { type: actionTypes.UPDATE_CART, itemid, params };
}

export function applyCode(url, reqType) {
  return { type: actionTypes.DISCOUNT_CODE_LOAD, url, reqType };
}

export function addToCart(data, allPackage, showCartPopup) {
  if (allPackage === 'all') data.allPackages = true;
  return { type: actionTypes.ADD_TO_CART_LOAD, data, showCartPopup };
}

export function frameOnlyProduct(args) {
  return { type: actionTypes.FRAME_ONLY_PRODUCT, ...args };
}

export function resetCart() {
  return { type: actionTypes.RESET_CART };
}

export function deleteCart() {
  return { type: actionTypes.DELETE_CART_LOAD };
}

export function logoutUser() {
  return { type: actionTypes.LOGOUT_USER };
}

export function updateNetAmount(amount) {
  return { type: actionTypes.GET_CART_NET_AMOUNT, amount };
}

export function countryShippingCheck(country) {
  return { type: actionTypes.SHIPPING_CHECK_LOAD, country };
}

// Bin Series
export function applyOffer(data) {
  return { type: actionTypes.APPLY_OFFER, data };
}

export function removeOffer(offerId) {
  return { type: actionTypes.REMOVE_OFFER, offerId };
}

// update power prescription
export function updatePower(itemId, prescription) {
  return { type: actionTypes.UPDATE_POWER_LOAD, itemId, prescription };
}

export function applyCouponClicked(clicked) {
  return { type: actionTypes.APPLY_COUPON_CLICKED, clicked };
}

export function resetCartError() {
  return { type: actionTypes.RESET_CART_ERROR };
}

export function resetApplyCoupon() {
  return { type: actionTypes.RESET_APPLY_COUPON };
}

export function applyGiftCard(giftCode) {
  return { type: actionTypes.APPLY_GIFT_LOAD, giftCode };
}

export function removeGiftCard(giftCode) {
  return { type: actionTypes.REMOVE_GIFT_LOAD, giftCode };
}
