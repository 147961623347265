import React from 'react';
import { getCurrencyCode, getProductPrice } from '../../helper';
import { useSelector } from 'react-redux';
import Collapse from './Collapse';
import { v4 } from 'uuid';

const Addons = ({ packages, selectedCoating, setSelectedCoating, selectedPackage }) => {
  const { CURRENCY_SYMBOL, CURRENCY_CODE } = useSelector(state => state?.locale);

  if (packages.length > 0) {
    return (
      <div className="odslv-modal-p">
        <div className="select-lens-page-title">Select lens coating</div>
        {selectedPackage &&
          selectedPackage?.addons?.map(addon => {
            return (
              <Collapse
                key={v4()}
                description={`+${
                  getCurrencyCode(addon)
                    ? getCurrencyCode(addon)
                    : `${CURRENCY_CODE}${CURRENCY_SYMBOL}`
                } ${getProductPrice(addon)}`}
                id={addon?.id}
                isSelected={selectedCoating?.id === addon.id}
                title={addon?.title}
                titleIcon={addon?.imageUrl}
                onSelect={() => setSelectedCoating(addon)}
              />
            );
          })}
      </div>
    );
  }
  return null;
};

export default Addons;
