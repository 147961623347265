export const HEADER_UPDATE = 'returnExchange/HEADER_UPDATE';
export const HIDE_NAV = 'returnExchange/HIDE_NAV';

export const STORE_LOCATION_LOAD = 'returnExchange/STORE_LOCATION_LOAD';
export const STORE_LOCATION_SUCCESS = 'returnExchange/STORE_LOCATION_SUCCESS';
export const STORE_LOCATION_FAIL = 'returnExchange/STORE_LOCATION_FAIL';

export const USER_ADDRESS_UPDATE = 'returnExchange/USER_ADDRESS_UPDATE';
export const STORE_ADDRESS_UPDATE = 'returnExchange/STORE_ADDRESS_UPDATE';
export const EXCHANGE_ADDRESS_UPDATE = 'returnExchange/EXCHANGE_ADDRESS_UPDATE';

// return
export const SET_RETURN_PRODUCTS = 'returnExchange/SET_RETURN_PRODUCTS';
export const MERGE_RETURN_DETAILS = 'eturnExchange/MERGE_RETURN_DETAILS';

export const CREATE_RETURN_LOAD = 'returnExchange/CREATE_RETURN_LOAD';
export const CREATE_RETURN_SUCCESS = 'returnExchange/CREATE_RETURN_SUCCESS';
export const CREATE_RETURN_FAIL = 'returnExchange/CREATE_RETURN_FAIL';

export const GET_RETURN_MODES_LOAD = 'returnExchange/GET_RETURN_MODES_LOAD';
export const GET_RETURN_MODES_SUCCESS = 'returnExchange/GET_RETURN_MODES_SUCCESS';
export const GET_RETURN_MODES_FAIL = 'returnExchange/GET_RETURN_MODES_FAIL';

export const GET_RETURN_REASONS = 'returnExchange/GET_RETURN_REASONS';
export const GET_RETURN_REASONS_SUCCESS = 'returnExchange/GET_RETURN_REASONS_SUCCESS';
export const GET_RETURN_REASONS_FAIL = 'returnExchange/GET_RETURN_REASONS_FAIL';

export const GET_RETURN_REASONS_ARR_SUCCESS = 'returnExchange/GET_RETURN_REASONS_ARR_SUCCESS';

export const GET_SERVICEABLE_ADDRESS_LOAD = 'returnExchange/GET_SERVICEABLE_ADDRESS_LOAD';
export const GET_SERVICEABLE_ADDRESS_SUCCESS = 'returnExchange/GET_SERVICEABLE_ADDRESS_SUCCESS';
export const GET_SERVICEABLE_ADDRESS_FAIL = 'returnExchange/GET_SERVICEABLE_ADDRESS_FAIL';

export const GET_RETURN_LABEL_STATUS_LOAD = 'returnExchange/GET_RETURN_LABEL_STATUS_LOAD';
export const GET_RETURN_LABEL_STATUS_SUCCESS = 'returnExchange/GET_RETURN_LABEL_STATUS_SUCCESS';
export const GET_RETURN_LABEL_STATUS_FAIL = 'returnExchange/GET_RETURN_LABEL_STATUS_FAIL';

// exchange

export const GET_PAYMENT_DATA_LOAD = 'returnExchange/GET_PAYMENT_DATA_LOAD';
export const GET_PAYMENT_DATA_SUCCESS = 'returnExchange/GET_PAYMENT_DATA_SUCCESS';
export const GET_PAYMENT_DATA_FAIL = 'returnExchange/GET_PAYMENT_DATA_FAIL';

export const GET_PAYMENT_METHODS_LOAD = 'returnExchange/GET_PAYMENT_METHODS_LOAD';
export const GET_PAYMENT_METHODS_SUCCESS = 'returnExchange/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'returnExchange/GET_PAYMENT_DATA_FAIL';

export const SET_IS_EXCHANGE = 'returnExchange/SET_IS_EXCHANGE';

// Post Return
export const GET_RETURN_DETAILS_LOAD = 'returnExchange/GET_RETURN_DETAILS_LOAD';
export const GET_RETURN_DETAILS_SUCCESS = 'returnExchange/GET_RETURN_DETAILS_SUCCESS';
export const GET_RETURN_DETAILS_FAIL = 'returnExchange/GET_RETURN_DETAILS_FAIL';

export const GET_RETURN_ACTIONS_LOAD = 'returnExchange/GET_RETURN_ACTIONS_LOAD';
export const GET_RETURN_ACTIONS_SUCCESS = 'returnExchange/GET_RETURN_ACTIONS_SUCCESS';
export const GET_RETURN_ACTIONS_FAIL = 'returnExchange/GET_RETURN_ACTIONS_FAIL';

export const SET_EXCHANGE_ORDER_AND_ITEM = 'returnExchange/SET_EXCHANGE_ORDER_AND_ITEM';
export const SET_EXCHANGE_RETURN_CLEAN = 'returnExchange/SET_EXCHANGE_RETURN_CLEAN';

// refund
export const CREATE_REFUND_LOAD = 'returnExchange/CREATE_REFUND_LOAD';
export const CREATE_REFUND_SUCCESS = 'returnExchange/CREATE_REFUND_SUCCESS';
export const CREATE_REFUND_FAIL = 'returnExchange/CREATE_REFUND_FAIL';

export const GET_REFUND_ESTIMATE_LOAD = 'returnExchange/GET_REFUND_ESTIMATE_LOAD';
export const GET_REFUND_ESTIMATE_SUCCESS = 'returnExchange/GET_REFUND_ESTIMATE_SUCCESS';
export const GET_REFUND_ESTIMATE_FAIL = 'returnExchange/GET_REFUND_ESTIMATE_FAIL';

// elibility return status new flow
export const GET_ELIGIBILITY_RETURN_STATUS = 'returnExchange/GET_ELIGIBILITY_RETURN_STATUS';
export const GET_ELIGIBILITY_RETURN_STATUS_SUCCESS =
  'returnExchange/GET_ELIGIBILITY_RETURN_STATUS_SUCCESS';
export const GET_ELIGIBILITY_RETURN_STATUS_FAIL =
  'returnExchange/GET_ELIGIBILITY_RETURN_STATUS_FAIL';

export const GET_ORDERITEMS_RETURNELIGIBILITY = 'returnExchange/GET_ORDERITEMS_RETURNELIGIBILITY';
export const GET_ORDERITEMS_RETURNELIGIBILITY_SUCCESS =
  'returnExchange/GET_ORDERITEMS_RETURNELIGIBILITY_SUCCESS';
export const GET_ORDERITEMS_RETURNELIGIBILITY_FAIL =
  'returnExchange/GET_ORDERITEMS_RETURNELIGIBILITY_FAIL';

export const SET_RETURN_ITEM_DETAILS = 'returnExchange/SET_RETURN_ITEM_DETAILS';
export const SET_CURRENT_RETURN_ITEM = 'returnExchange/SET_CURRENT_RETURN_ITEM';
export const SET_NEW_EXCHANGE_FLOW = 'returnExchange/SET_NEW_EXCHANGE_FLOW';

// Cancel Return
export const RETURN_CANCEL_LOAD = 'returnExchange/RETURN_CANCEL_LOAD';
export const RETURN_CANCEL_SUCCESS = 'returnExchange/RETURN_CANCEL_SUCCESS';
export const RETURN_CANCEL_FAIL = 'returnExchange/RETURN_CANCEL_FAIL';

// Loader
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
