import * as actionTypes from '../actionTypes/order';

export function getOrder(data = {}) {
  return { type: actionTypes.GET_ORDER, data };
}

export function setOrder(orderDetails) {
  return { type: actionTypes.SET_ORDER, orderDetails };
}

export function getRecentOrder(pageNo, pageSize) {
  return { type: actionTypes.PREV_ORDER_LOAD, pageNo, pageSize };
}

export function clearState(data) {
  return { type: actionTypes.CLEAR_STATE, data };
}

export function resetOrderDetails(orderDetails) {
  return { type: actionTypes.RESET_ORDERDETAILS, orderDetails };
}

export function getOrderCancelRefundDetails(orderId) {
  return { type: actionTypes.ORDER_CANCEL_REFUND_DETAILS, orderId };
}
