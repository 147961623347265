import React, { useEffect } from 'react';
import './WishlistPage.scss';
import CommonLoader from '../../../../desktop/components/Common/Loader';
import Helmet from 'react-helmet';
import WishlistPageItems from './WishlistPageItems/WishlistPageItems';
import OdEmptyWishlistImage from '../../../../../static/assets/img/wishlist/od-empty-wishlist.svg';

import { connect } from 'react-redux';
import { odPushClevertapEvent } from '../../../../utils/clevertap';

const WishListPage = props => {
  const { dataLocale, shortListData, wishlistCount } = props;

  const {
    WISHLIST = 'Wishlist',
    ITEMS,
    YOUR_WISHLIST_IS_EMPTY = 'your wishlist is empty',
  } = dataLocale;

  useEffect(() => {
    odPushClevertapEvent('Page Viewed', {
      pageName: 'Wishlist',
      pageUrl: window.location.href,
    });
  }, []);

  useEffect(() => {
    const headerHeight = window.document.getElementById('header')?.offsetHeight;
    const wishlistPage = window.document.getElementById('wishlist-page');

    if (wishlistPage) {
      wishlistPage.style.minHeight = `calc(100vh - ${Math.floor(headerHeight)}px)`;
    }
  });

  return (
    <main className="wishlist-page" id="wishlist-page">
      <Helmet meta={[{ name: 'description', content: 'Wish List page' }]} title="Wish List" />
      <div className="wishlist-page__layout">
        <h1 className="wishlist-page__title od-font-bold">{`${WISHLIST} (${
          shortListData?.result?.numberOfProducts || 0
        } ${ITEMS})`}</h1>
        {!wishlistCount || shortListData?.result ? (
          <>
            {shortListData?.result?.numberOfProducts ? (
              <WishlistPageItems />
            ) : (
              <>
                <p className="wishlist-page__empty">{YOUR_WISHLIST_IS_EMPTY}</p>
                <img
                  alt={YOUR_WISHLIST_IS_EMPTY}
                  className="wishlist-page__empty-image"
                  height="360px"
                  src={OdEmptyWishlistImage}
                />
              </>
            )}
          </>
        ) : (
          <CommonLoader />
        )}
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
    shortListData: state.common.shortListData,
    wishlistCount: state.common.isWishlistCount,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WishListPage);
