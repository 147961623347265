import React, { Fragment } from 'react';

import { onImageLoadError } from '../../../utils/helper';

import { Breadcrumbs } from './Breadcrumbs';

import './HeaderOwndaysRevampsBrand.scss';

export const HeaderOwndaysRevampsBrand = () => {
  return (
    <Fragment>
      <Breadcrumbs />
      <div className="od-header-owndays-revamps-brand">
        <div className="od-header-owndays-revamps-brand__header">
          <span className="od-header-owndays-revamps-brand__day">2024.07.11 | Press Release</span>
          <h1 className="od-header-owndays-revamps-brand__title">
            OWNDAYS REVAMPS BRAND IDENTITY, DEBUTING NEW RETAIL DESIGN IN JAPAN AND SINGAPORE THIS
            JULY
          </h1>
        </div>

        <img
          alt=""
          className="od-header-owndays-revamps-brand__image--shop"
          src="https://www.owndays.com/images/information/20240711/676.webp"
          onError={e => onImageLoadError(e)}
        />

        <div className="od-header-owndays-revamps-brand__content">
          <h3 className="od-header-owndays-revamps-brand__content__header">
            A new reiteration of the brand logo, tagline, retail design and online store design will
            be rolled out progressively in all countries
          </h3>
          <p className="od-header-owndays-revamps-brand__content__desc">
            Japanese eyewear brand OWNDAYS has unveiled a new logo and brand identity today, marking
            a significant milestone aimed at creating a more unified retail experience globally.
            With the introduction of a new tagline “OWN ‘your’ DAYS”, the updated identity embodies
            OWNDAYS’ dedication to inspiring individuality and confidence among its customers.
          </p>
          <p className="od-header-owndays-revamps-brand__content__desc">
            As part of this initiative to elevate the shopping experience and maintain a cohesive
            brand identity across all touchpoints, OWNDAYS is also transforming its retail design at
            the LaLaport Tachikawa Tachihi store in Japan and the Northpoint City store in
            Singapore. The LaLaport Tachikawa Tachihi store reopens today while the Northpoint City
            store is scheduled to reopen on 19 July 2024. The refreshed assets are being implemented
            globally across both physical and digital channels while the updated retail design will
            be launched at new stores and rolled out to existing stores progressively.
          </p>
        </div>

        <img
          alt=""
          className="od-header-owndays-revamps-brand__image--text"
          src="https://www.owndays.com/images/information/20240711/OWNyourDAYS-logo.webp"
          onError={e => onImageLoadError(e)}
        />

        <div className="od-header-owndays-revamps-brand__content">
          <p className="od-header-owndays-revamps-brand__content__desc od-header-owndays-revamps-brand__mb-0">
            OWNDAYS, now Asia’s largest eyewear retailer following its merger with Lenskart in 2022,
            operates over 560 stores across 13 international locations. As the brand evolves,
            OWNDAYS remains committed to refining its identity to deliver a consistent global retail
            experience while upholding its core values of accessibility, innovation and
            customer-centricity.
          </p>
        </div>
      </div>
    </Fragment>
  );
};
