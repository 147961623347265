import React, { memo, useState } from 'react';
import { useEffect } from 'react';
import AccordionGroup from '../../mobile/components/AccordionGroup/AccordionGroup';
import './PowerGrid.scss';

const excludedPrescText = ['Call Me/Email Me for Power', 'Call Me/Email Me for Color', ''];

function excludePrescValues(prescValue, hyphenSybmol) {
  if (excludedPrescText.includes(prescValue) || !prescValue) return hyphenSybmol;
  return prescValue;
}

function PowerGrid(props) {
  const {
    prescription: { left, right, userName, labels },
    view,
    dataLocale = {},
    fromOrderDetails,
    preCheckout,
    newStyle = false,
    cartView = false,
    active = true,
    userNameList = [],
  } = props;

  const [isActive, setIsActive] = useState(true);
  const {
    YOUR_POWER,
    HYPHEN,
    LEFT,
    RIGHT,
    POWER,
    NO_EXTRA_CHARGES_FOR_HIGH_POWER,
    BUYING_FOR,
    EYE,
    L,
    R,
  } = dataLocale;
  let itemPrescriptions;
  if (left && Object.keys(left).length) {
    itemPrescriptions = left;
  } else if (right && Object.keys(right).length) {
    itemPrescriptions = right;
  }
  let userTitle = userName || YOUR_POWER;
  if (userTitle.toLowerCase() === 'user') {
    userTitle = YOUR_POWER;
  }

  if (cartView && userNameList) {
    if (userNameList?.indexOf(userTitle) > -1) {
      userTitle = '';
    }
  }
  const isDesktop = view === 'desktop';

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const header = !cartView ? (
    <div
      className={`name-strip text-capitalize fsp14 ${
        isDesktop ? 'fs12 padding-t10 margin-b5 padding-l15' : 'pd-b10 pd-t10'
      }`}
    >
      <span
        className={`fw700 inline-block ${
          isDesktop
            ? 'padding-b5 margin-l15'
            : 'display-flex justify-space-between align-items-center mr-l15 mr-r15'
        }`}
      >
        <span>{userTitle}</span>
        {!isDesktop && fromOrderDetails && (
          <span className="power-accordion-title text-color_link_blue">
            <span className="fsp12 icon icon-down-open"></span>
          </span>
        )}
      </span>
    </div>
  ) : (
    <>
      <div className="new-header-container" onClick={() => setIsActive(prev => !prev)}>
        <div className="new-header-content" style={view === 'desktop' ? { gap: '60px' } : {}}>
          <div>
            <span className="header-text-primary">{BUYING_FOR} </span>
            <span className="header-text-secondary">{userTitle} </span>
            {/* <span className={`icon ${isActive ? 'icon-up' : 'icon-down'}`}></span> */}
            <span className={`icon ${isActive ? 'arrow-up' : 'arrow-down'}`}></span>
          </div>
          <div>
            <span className="header-text-fix">{NO_EXTRA_CHARGES_FOR_HIGH_POWER}</span>
          </div>
        </div>
      </div>
    </>
  );
  const body = !cartView ? (
    <div
      className={`${
        isDesktop ? 'padding-t5 fs12 padding-l15 padding-r15' : 'text-color_grey_black'
      } ${isDesktop && newStyle ? 'table-new-style-container' : ''}`}
    >
      <div
        className={`${
          isDesktop
            ? 'power-item display-flex justify-content-end padding-b10'
            : 'power-item_msite pd-b10'
        } ${isDesktop && newStyle ? 'table-new-style' : ''}`}
      >
        <div
          className={`${
            isDesktop
              ? 'fw700 padding-t20 flex-1 display-flex flex-column'
              : 'power-container fw400 pd-t10'
          } ${isDesktop && newStyle ? 'col0' : ''}`}
        >
          {!isDesktop && <div className="pull-left text-capitalize">{POWER}</div>}
          {left && (
            <div
              className={`${isDesktop ? 'width25' : 'pull-right mr-l25 text-right'} ${
                isDesktop && newStyle ? 'left-text' : ''
              }`}
            >
              {LEFT}
            </div>
          )}
          {right && (
            <div
              className={`${isDesktop ? 'width25' : 'pull-right text-right'} ${
                isDesktop && newStyle ? 'right-text' : ''
              }`}
            >
              {RIGHT}
            </div>
          )}
        </div>
        {itemPrescriptions &&
          Object.keys(itemPrescriptions).map(keys => {
            if (
              (left &&
                left[keys] &&
                excludedPrescText.includes(left[keys]) &&
                right &&
                right[keys] &&
                excludedPrescText.includes(right[keys])) ||
              (left && !left[keys] && right && !right[keys]) ||
              ((!left || !left[keys]) && (!right || !right[keys]))
            ) {
              return null;
            }
            return (
              <div
                key={`#left_${keys}`}
                className={`overflow-hidden ${
                  isDesktop
                    ? 'full-width text-right flex-basis-20 display-flex flex-column'
                    : 'w100'
                } ${isDesktop && newStyle ? 'new-col1' : ''}`}
                data-testid="powerBlock"
              >
                <div
                  className={`float-left ${
                    isDesktop ? ' fw700 width100 pull-right fs12' : 'fsp12 fw400'
                  } ${isDesktop && newStyle ? 'col1-sub' : ''}`}
                >
                  {labels[keys]}
                </div>
                {left && (
                  <div
                    className={`text-right ${
                      isDesktop ? 'width100' : 'pull-right fw700 w10vw mr-l20'
                    } ${isDesktop && newStyle ? 'col1-sub' : ''}`}
                    data-testid="prescValues"
                  >
                    {excludePrescValues(left[keys], HYPHEN)}
                  </div>
                )}
                {right && (
                  <div
                    className={`text-right ${isDesktop ? 'width100' : 'pull-right fw700 w10vw'} ${
                      isDesktop && newStyle ? 'col1-sub' : ''
                    }`}
                    data-testid="prescValues"
                  >
                    {excludePrescValues(right[keys], HYPHEN)}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <>
      {/* {isActive ? ( */}
      <div className="cartView-body-container">
        <div className="cart-view-header">
          <div>{EYE}</div>
          {itemPrescriptions &&
            Object.keys(itemPrescriptions)?.map((item, key) => <div key={key}>{item}</div>)}
        </div>

        <div className="cart-view-table">
          <div className="table-row">
            <div className="table-row-item">{L}</div>
            {left &&
              Object.values(left).map((item, key) => (
                <div key={key} className="table-row-item">
                  {excludePrescValues(item, HYPHEN)}
                </div>
              ))}
          </div>
          <div className="table-row">
            <div className="table-row-item">{R}</div>
            {right &&
              Object.values(right).map((item, key) => (
                <div key={key} className="table-row-item">
                  {excludePrescValues(item, HYPHEN)}
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* ) : null} */}
    </>
  );

  if (cartView) {
    return (
      <>
        <AccordionGroup
          activeKey={0}
          className="order-power-accordion"
          content={[{ header, body }]}
        />
      </>
    );
  }

  return (
    <div
      className={`power-table-block ${
        isDesktop
          ? 'desktop bora-8 margin-l15 margin-r15 margin-b10 margin-b10 padding-0 padding_0_15'
          : 'shadow bora-4 mr-auto padding_0_7'
      } ${newStyle ? 'new-style' : ''}`}
      data-testid="powerTableBlock"
    >
      {view === 'desktop' &&
        (fromOrderDetails ? (
          <AccordionGroup className="order-power-accordion" content={[{ header, body }]} />
        ) : (
          <React.Fragment>
            {header}
            {body}
          </React.Fragment>
        ))}
      {view === 'mobile' &&
        (preCheckout ? (
          <AccordionGroup
            activeKey={0}
            className="order-power-accordion"
            content={[{ header, body }]}
          />
        ) : (
          <React.Fragment>{body}</React.Fragment>
        ))}
    </div>
  );
}

export default memo(PowerGrid);
