import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/lensPackageUpgrade';
import config from 'config';
import apiClient from '../helpers/apiClient';
import { setToastMessage } from '../actionCreators/common';

let _client = null;

function* getLensPackageDetails({ orderId, itemId, apiClient }) {
  const successAction = { type: actionTypes.GET_LENS_PACKAGE_DETAILS_SUCCESS };
  const errorAction = { type: actionTypes.GET_LENS_PACKAGE_DETAILS_FAIL };
  const client = { ..._client };
  if (apiClient) {
    client.setHeader({
      'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91',
      'X-Api-Client': apiClient,
    });
  } else {
    client.setHeader({ 'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91' });
  }
  try {
    const result = yield call(async () =>
      client.get(`${config.apiPath.v2Orders}/${orderId}/items/${itemId}/upgradepackage`)
    );
    successAction.data = result.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* updatePowerType({ payload }) {
  const successAction = { type: actionTypes.UPDATE_POWER_TYPE_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_POWER_TYPE_FAIL };
  const client = { ..._client };
  client.setHeader({ 'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91' });
  try {
    const result = yield call(async () =>
      client.put(
        `${config.apiPath.v2Orders}/${payload.orderId}/items/${payload.itemId}/powertype?from=${payload.from}&to=${payload.to}`
      )
    );
    successAction.data = result.result;
    yield put(successAction);
    yield put(
      setToastMessage({ success: true, message: 'Power updated successfully', timeout: 7000 })
    );
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
    yield put(
      setToastMessage({
        error: true,
        message: er?.message || 'Error while updating the Power',
        timeout: 7000,
      })
    );
  }
}

function* updatePackage({ payload, bodyData }) {
  const successAction = { type: actionTypes.UPDATE_PACKAGE_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_PACKAGE_FAIL };
  const client = { ..._client };
  client.setHeader({ 'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91' });
  try {
    const result = yield call(async () =>
      client.put(
        `${config.apiPath.v2Orders}/${payload.orderId}/items/${payload.itemId}/package?updateFrom=${payload.from}&updateTo=${payload.to}`,
        bodyData
      )
    );
    successAction.data = result.result;
    yield put(successAction);
    yield put(
      setToastMessage({ success: true, message: 'Package updated successfully', timeout: 7000 })
    );
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
    yield put(
      setToastMessage({
        error: true,
        message: er?.message || 'Error while updating the Package',
        timeout: 7000,
      })
    );
  }
}

function* updatePrescription({ payload, bodyData }) {
  const successAction = { type: actionTypes.UPDATE_PRESCRIPTION_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_PRESCRIPTION_FAIL };
  const client = { ..._client };
  client.setHeader({ 'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91' });
  try {
    const result = yield call(async () =>
      client.put(
        `${config.apiPath.v2Orders}/${payload.orderId}/items/${payload.itemId}/prescription?email=${payload.email}`,
        bodyData
      )
    );
    successAction.data = result.result;
    yield put(successAction);
    yield put(
      setToastMessage({
        success: true,
        message: 'Prescription updated successfully',
        timeout: 7000,
      })
    );
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
    yield put(
      setToastMessage({
        error: true,
        message: er?.message || 'Error while updating the Prescription',
        timeout: 7000,
      })
    );
  }
}

export function* runLensPackageUpgradeCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_LENS_PACKAGE_DETAILS, getLensPackageDetails),
    takeLatest(actionTypes.UPDATE_POWER_TYPE_LOAD, updatePowerType),
    takeLatest(actionTypes.UPDATE_PACKAGE_LOAD, updatePackage),
    takeLatest(actionTypes.UPDATE_PRESCRIPTION_LOAD, updatePrescription),
  ]);
}
