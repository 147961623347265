import {
  BOOK_APPOINTMENT,
  BOOK_APPOINTMENT_FAILURE,
  BOOK_APPOINTMENT_SUCCESS,
  BOOK_TEST_YOUR_VISION,
  BOOK_TEST_YOUR_VISION_FAILURE,
  BOOK_TEST_YOUR_VISION_SUCCESS,
  BOOK_TIME,
  BOOK_TIME_FAILURE,
  BOOK_TIME_SUCCESS,
  GET_STORES,
  GET_STORES_FAILURE,
  GET_STORES_SUCCESS,
  GET_TIME_SLOT,
  GET_TIME_SLOT_FAILURE,
  GET_TIME_SLOT_SUCCESS,
  RESET_BOOK_APPOINTMENT,
  RESET_STUDIO_FLOW_FORMS,
  STUDIO_FLOW_MODAL,
  STUDIO_FLOW_RESET,
  STUDIO_FLOW_SHOW_THANK_YOU,
  STUDIO_FLOW_TIME_LOADER,
} from '../../actionTypes/studio-flow';

const initialState = {
  stores: [],
  bookConfirmation: null,
  bookError: null,
  bookTimeError: null,
  bookTime: null,
  loader: false,
  loading: false,
  timeSlot: null,
  timeSlotErr: null,
  formData: null,
  error: null,
  modal: {
    open: false,
    data: {},
  },
  showThankYou: null,
  bookTimeLoading: false,
};

export default function studioFlowReducer(prevState = initialState, action) {
  switch (action.type) {
    case GET_STORES:
      return { ...prevState, loading: true };
    case GET_STORES_SUCCESS:
      return { ...prevState, loading: false, stores: action.payload };
    case GET_STORES_FAILURE:
      return { ...prevState, loading: false, error: action.payload };
    case BOOK_APPOINTMENT:
      return { ...prevState, bookConfirmation: null, bookError: null };
    case BOOK_APPOINTMENT_SUCCESS:
      return { ...prevState, bookConfirmation: action.payload, bookError: null };
    case BOOK_APPOINTMENT_FAILURE:
      return { ...prevState, bookError: action.payload, bookConfirmation: null };
    case BOOK_TEST_YOUR_VISION:
      return { ...prevState, bookConfirmation: null, bookError: null, loading: true };
    case BOOK_TEST_YOUR_VISION_SUCCESS:
      return { ...prevState, bookConfirmation: action.payload, bookError: null, loading: false };
    case BOOK_TEST_YOUR_VISION_FAILURE:
      return { ...prevState, bookConfirmation: null, bookError: action.payload, loading: false };
    case RESET_BOOK_APPOINTMENT:
      return { ...prevState, bookError: null, bookConfirmation: null };
    case BOOK_TIME:
      return { ...prevState, bookTimeLoading: true };
    case BOOK_TIME_SUCCESS:
      return {
        ...prevState,
        bookTime: action.payload,
        bookTimeError: null,
        bookTimeLoading: false,
      };
    case BOOK_TIME_FAILURE:
      return {
        ...prevState,
        bookTimeError: action.payload,
        bookTime: null,
        bookTimeLoading: false,
      };
    case GET_TIME_SLOT:
      return {
        ...prevState,
        loading: true,
      };
    case GET_TIME_SLOT_SUCCESS:
      return {
        ...prevState,
        loading: false,
        timeSlot: action.payload.data,
        formData: action.payload.formData,
        timeSlotErr: null,
      };
    case GET_TIME_SLOT_FAILURE:
      return {
        ...prevState,
        loading: false,
        timeSlot: null,
        timeSlotErr: action.payload,
      };
    case STUDIO_FLOW_TIME_LOADER:
      return { ...prevState, loader: action.payload };
    case STUDIO_FLOW_MODAL: {
      return { ...prevState, modal: action.payload, bookTime: null };
    }
    case STUDIO_FLOW_SHOW_THANK_YOU:
      return { ...prevState, showThankYou: action.payload };
    case STUDIO_FLOW_RESET:
      return initialState;
    case RESET_STUDIO_FLOW_FORMS:
      return Object.assign({}, initialState, { stores: prevState.stores });
    default:
      return prevState;
  }
}
