// import React from 'react';
import Loadable from 'react-loadable';
// import Loader from '../../../components/BlankLoader/BlankLoader';

const AuthModalLoadable = Loadable({
  loader: () =>
    import('./AuthModal' /* webpackChunkName: 'auth-modal' */).then(module => module.default),
  loading: () => null,
});

export default AuthModalLoadable;
