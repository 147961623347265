import React from 'react';
import './BlankWidgets.scss';

const BlankWidgets = props => {
  const blankWidgets = props.rowsArray || ['1', '2', '3', '4', '5', '6'];
  return (
    <div className={`blankwidgets ${props.styleObj}`}>
      {blankWidgets.map(key => {
        return (
          <div key={key} className="margin-b30">
            <div className="blankwidgets_box">
              <div className="blankwidgets_box_animatedBackground blankwidgets_box_headerWrap">
                <div className="blankwidgets_box_animatedBackground_header"></div>
              </div>
              <div className="blankwidgets_box_animatedBackground blankwidgets_box_imageWrap">
                <div className="blankwidgets_box_animatedBackground_image"></div>
              </div>
              <div className="blankwidgets_box_footerWrap">
                <div className="blankwidgets_box_footerWrap_left">
                  <div
                    className="blankwidgets_box_animatedBackground"
                    style={{ width: '50%' }}
                  ></div>
                  <div
                    className="blankwidgets_box_animatedBackground"
                    style={{ width: '70%' }}
                  ></div>
                  <div
                    className="blankwidgets_box_animatedBackground"
                    style={{ width: '30%' }}
                  ></div>
                </div>
                <div className="blankwidgets_box_footerWrap_right">
                  <div
                    className="blankwidgets_box_animatedBackground"
                    style={{ width: '100%' }}
                  ></div>
                  <div
                    className="blankwidgets_box_animatedBackground"
                    style={{ width: '100%' }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlankWidgets;
