import React from 'react';
import './BannerOwndays.scss';

const BannerOwndays = ({ data }) => {
  const { title, img, contentImg } = data;
  return (
    <div className="od-banner">
      <img alt="owndays banner" src={img.src} width="100%" />
      <div className="od-banner__content">
        {contentImg && (
          <div className="od-banner__content__img">
            <img
              alt={contentImg.alt}
              height={contentImg.height}
              src={contentImg.src}
              width={contentImg.width}
            />
          </div>
        )}
        <div className="od-banner__content__title od-font-bold">{title}</div>
      </div>
    </div>
  );
};

export default BannerOwndays;
