import { routerReducer } from 'react-router-redux';
import common from './reducers/common';
import header from './reducers/header';
import footer from './reducers/footer';
import category from './reducers/category';
import product from './reducers/product';
import cart from './reducers/cart';
import auth from './reducers/auth';
import cmsMapping from './reducers/cmsMapping';
import categoryCarousel from './reducers/categoryCarousel';
import order from './reducers/order';
import collectionPage from './reducers/collectionPage';
import DataLayer from './reducers/DataLayer';
import DTM from './reducers/DTM';
import myAccount from './reducers/myAccount';
import savedCards from './reducers/savedCards';
import neft from './reducers/neft';
import productCarousel from './reducers/productCarousel';
import yotpo from './reducers/yotpo';
import home from './reducers/home';
import gupshupWhatsapp from './reducers/gupshupWhatsapp';
import checkout from './reducers/checkout';
import returnExchange from './reducers/returnExchange';
import frameSize from './reducers/frameSize';
import address from './reducers/address';
import store from './reducers/store';
import hto from './reducers/hto';
import plpBanner from './reducers/plpBanner';
import newMSiteOfflineSalesFlow from './reducers/newMSiteOfflineSalesFlow';
import locale from './reducers/locale';
import success from './reducers/success';
import shortlist from './reducers/shortlist';
import ditto from './reducers/ditto';
import dittoShare from './reducers/dittoShare';
import editPower from './reducers/editPower';
import qrCode from './reducers/qrCode';
import phoneNumberCapture from './reducers/phoneNumberCapture';
import review from './reducers/review';
import offerCampaign from './reducers/offerCampaign';
import lensPackageUpgrade from './reducers/lensPackageUpgrade';
import cancelProducts from './reducers/cancelProducts';
import studioFlowReducer from './reducers/studioflow';
import PDReducer from './reducers/PD';
import primer from './reducers/primer';
import membershipPoints from './reducers/membershipPoints';
import search from './reducers/search';
import ui from './reducers/ui';

export default function createReducers(asyncReducers) {
  const returnObj = {
    router: routerReducer,
    common,
    header,
    cart,
    cmsMapping,
    category,
    product,
    auth,
    categoryCarousel,
    footer,
    order,
    collectionPage,
    DataLayer,
    DTM,
    myAccount,
    savedCards,
    neft,
    productCarousel,
    yotpo,
    gupshupWhatsapp,
    home,
    checkout,
    returnExchange,
    cancelProducts,
    frameSize,
    address,
    store,
    hto,
    plpBanner,
    newMSiteOfflineSalesFlow,
    success,
    locale,
    shortlist,
    ditto,
    dittoShare,
    editPower,
    qrCode,
    phoneNumberCapture,
    review,
    offerCampaign,
    lensPackageUpgrade,
    studioFlowReducer,
    PDReducer,
    primer,
    membershipPoints,
    search,
    ui,
    ...asyncReducers,
  };
  return returnObj;
}
