import React from 'react';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import HeaderContacts from '../HeaderContacts';
import BodyContacts from '../BodyContacts';
import FooterContacts from '../FooterContacts';
import '../../../../theme/pages/contacts.scss';
import { IMG_URL, LINEUP } from './constants';

const ColorContactLens = () => {
  const crumbs = [
    { path: '/', label: 'Homepage' },
    { path: '#', label: 'Contacts' },
    { path: '#', label: 'OWNDAYS COLOUR CONTACT LENS' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="contacts-page contacts-page--color-contactlens">
        <HeaderContacts
          bgImg={`${IMG_URL}/top_bk.webp`}
          headingImg={`${IMG_URL}/logo.svg`}
          headingText="OWNDAYS COLOUR CONTACT LENS"
        />
        <BodyContacts imgUrl={IMG_URL} lineup={LINEUP} />
        <FooterContacts />
      </main>
    </>
  );
};

export default ColorContactLens;
