import React, { Fragment } from 'react';

import { HeaderNews, BodyNews } from '../CommonComponents/News';

const News = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/news';

  return (
    <Fragment>
      <HeaderNews baseImgUrl={baseImgUrl} />
      <BodyNews baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default News;
