import React, { useMemo } from 'react';
import './MembershipCircle.scss';

const variants = {
  earnPoints: 'earn points',
  referFriend: 'refer friend',
  exclusivePerks: 'exclusive perks',
  benefits: 'benefits',
};

const MembershipCircle = ({ variant }) => {
  const { img, imgContent } = useMemo(() => {
    let img;
    let imgContent;
    switch (variant) {
      case variants.earnPoints:
        img =
          'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/od-black-white-circle.webp';
        imgContent = (
          <div className="od-circle__p">
            <img
              alt="p"
              src="https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/od-p-vector.webp"
            />
          </div>
        );
        break;
      case variants.referFriend:
        img =
          'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-refer-friend.webp';
        imgContent = (
          <>
            <div className="od-circle__smile-first">
              <img
                alt="smile white"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-smile.webp"
              />
            </div>
            <div className="od-circle__smile-second">
              <img
                alt="smile black"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-smile-black.webp"
              />
            </div>
          </>
        );
        break;
      case variants.exclusivePerks:
        img =
          'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-stairs.webp';
        imgContent = (
          <>
            <div className="od-circle__vector">
              <img
                alt="vector"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-vector.webp"
              />
            </div>
            <div className="od-circle__stairs-border">
              <img
                alt="stairs border"
                src="https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-stairs-border.webp"
              />
            </div>
          </>
        );
        break;
      case variants.benefits:
        img =
          'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-only-range.webp';
        imgContent = null;
        break;

      default:
        break;
    }
    return { img, imgContent };
  }, [variant]);

  return (
    <div className="od-circle">
      <div className="od-circle__img">
        <img alt="img" src={img} />
      </div>
      {imgContent || null}
    </div>
  );
};

export default MembershipCircle;
