const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-plus';

export const HEADERS = [
  {
    pc: `${baseImgUrl}/main-1_pc_2x.webp`,
    tb: `${baseImgUrl}/main-1_pc_1x.webp`,
    sp: `${baseImgUrl}/main-1.webp`,
  },
  {
    pc: `${baseImgUrl}/main-2_pc_2x.webp`,
    tb: `${baseImgUrl}/main-2_pc_1x.webp`,
    sp: `${baseImgUrl}/main-2.webp`,
  },
  {
    pc: `${baseImgUrl}/main-3_pc_2x.webp`,
    tb: `${baseImgUrl}/main-3_pc_1x.webp`,
    sp: `${baseImgUrl}/main-3.webp`,
  },
];

export const FEATURES = [
  {
    title: 'Made in Japan with Exemplary Quality',
    description: `Balancing exemplary, Made-in-Japan quality at a valuable price point of S$98 to attain excellent price-performance ratio.`,
    image: {
      pc: `${baseImgUrl}/feature-list-01.webp`,
      sp: `${baseImgUrl}/feature-list-01-sp.webp`,
    },
  },
  {
    title: 'Extremely Light while Offering Impeccable Fit',
    description: `Made of lightweight resin that is extremely flexible; with elastic temples that wrap snuggly around the head for an impeccable fit.`,
    image: {
      pc: `${baseImgUrl}/feature-list-02.webp`,
      sp: `${baseImgUrl}/feature-list-02-sp.webp`,
    },
  },
  {
    title: 'A Minimalist Style Designed to Last',
    description: `A minimalist style that will always be adored; featuring a sleek silhouette that works for any occasion.`,
    image: {
      pc: `${baseImgUrl}/feature-list-03.webp`,
      sp: `${baseImgUrl}/feature-list-03-sp.webp`,
    },
  },
];

export const LINE_UP_TOP = [
  {
    href: '#basic',
    src: `${baseImgUrl}/line-up-main-item-01.webp`,
    title: 'BASIC',
    detail: 'Timeless classics great for all',
  },
  {
    href: '#business',
    src: `${baseImgUrl}/line-up-main-item-02.webp`,
    title: 'BUSINESS',
    detail: 'A great combination with suit outfits',
  },
  {
    href: '#trend',
    src: `${baseImgUrl}/line-up-main-item-03.webp`,
    title: 'TREND',
    detail: 'A fashionable accent',
  },
];

export const SKU_OR2081L4S = [
  {
    alt: 'OR2081L-4S C1',
    src: `${baseImgUrl}/OR2081L-4S-C1.webp`,
    sku: 'OR2081L-4S',
    colorName: 'Black',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2081L-4S C2',
    src: `${baseImgUrl}/OR2081L-4S-C2.webp`,
    sku: 'OR2081L-4S',
    colorName: 'Clear Gray Halftone',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2081L-4S C3',
    src: `${baseImgUrl}/OR2081L-4S-C3.webp`,
    sku: 'OR2081L-4S',
    colorName: 'Brown Demi',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2081L-4S C4',
    src: `${baseImgUrl}/OR2081L-4S-C4.webp`,
    sku: 'OR2081L-4S',
    colorName: 'Clear Brown',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
];

export const SKU_OR2083L4S = [
  {
    alt: 'OR2083L-4S C1',
    src: `${baseImgUrl}/OR2083L-4S-C1.webp`,
    sku: 'OR2083L-4S',
    colorName: 'Black',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2083L-4S C2',
    src: `${baseImgUrl}/OR2083L-4S-C2.webp`,
    sku: 'OR2083L-4S',
    colorName: 'Brown Demi',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2083L-4S C3',
    src: `${baseImgUrl}/OR2083L-4S-C3.webp`,
    sku: 'OR2083L-4S',
    colorName: 'Clear Brown Halftone',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2083L-4S C4',
    src: `${baseImgUrl}/OR2083L-4S-C4.webp`,
    sku: 'OR2083L-4S',
    colorName: 'Clear Pink Brown',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
];

export const SKU_OR2061L2S = [
  {
    alt: 'OR2061L-2S C1',
    src: `${baseImgUrl}/OR2061L-2S-C1.webp`,
    sku: 'OR2061L-2S',
    colorName: 'Matte Black',
    link: '/sg/en/owndays-plus-or2061l-2s-c1-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2061L-2S C2',
    src: `${baseImgUrl}/OR2061L-2S-C2.webp`,
    sku: 'OR2061L-2S',
    colorName: 'Clear Gray',
    link: '/sg/en/owndays-plus-or2061l-2s-c2-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2061L-2S C3',
    src: `${baseImgUrl}/OR2061L-2S-C3.webp`,
    sku: 'OR2061L-2S',
    colorName: 'Navy',
    link: '/sg/en/owndays-plus-or2061l-2s-c3-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2061L-2S C4',
    src: `${baseImgUrl}/OR2061L-2S-C4.webp`,
    sku: 'OR2061L-2S',
    colorName: 'Khaki',
    link: '/sg/en/owndays-plus-or2061l-2s-c4-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
];

export const SKU_OR2080L4S = [
  {
    alt: 'OR2080L-4S C1',
    src: `${baseImgUrl}/OR2080L-4S-C1.webp`,
    sku: 'OR2080L-4S',
    colorName: 'Matte Black',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2080L-4S C2',
    src: `${baseImgUrl}/OR2080L-4S-C2.webp`,
    sku: 'OR2080L-4S',
    colorName: 'Clear Gray',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2080L-4S C3',
    src: `${baseImgUrl}/OR2080L-4S-C3.webp`,
    sku: 'OR2080L-4S',
    colorName: 'Clear Brown',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2080L-4S C4',
    src: `${baseImgUrl}/OR2080L-4S-C4.webp`,
    sku: 'OR2080L-4S',
    colorName: 'Clear Green',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
];

export const SKU_OR2084L4S = [
  {
    alt: 'OR2084L-4S C1',
    src: `${baseImgUrl}/OR2084L-4S-C1.webp`,
    sku: 'OR2084L-4S',
    colorName: 'Matte Black',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2084L-4S C2',
    src: `${baseImgUrl}/OR2084L-4S-C2.webp`,
    sku: 'OR2084L-4S',
    colorName: 'Clear Gray',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2084L-4S C3',
    src: `${baseImgUrl}/OR2084L-4S-C3.webp`,
    sku: 'OR2084L-4S',
    colorName: 'Brown Demi',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2084L-4S C4',
    src: `${baseImgUrl}/OR2084L-4S-C4.webp`,
    sku: 'OR2084L-4S',
    colorName: 'Clear Greige',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
];

export const SKU_OR2065L2S = [
  {
    alt: 'OR2065L-2S C1',
    src: `${baseImgUrl}/OR2065L-2S-C1.webp`,
    sku: 'OR2065L-2S',
    colorName: 'Brown Demi',
    link: '/sg/en/owndays-plus-or2065l-2s-c1-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2065L-2S C2',
    src: `${baseImgUrl}/OR2065L-2S-C2.webp`,
    sku: 'OR2065L-2S',
    colorName: 'Light Brown',
    link: '/sg/en/owndays-plus-or2065l-2s-c2-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2065L-2S C3',
    src: `${baseImgUrl}/OR2065L-2S-C3.webp`,
    sku: 'OR2065L-2S',
    colorName: 'Wine',
    link: '/sg/en/owndays-plus-or2065l-2s-c3-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2065L-2S C4',
    src: `${baseImgUrl}/OR2065L-2S-C4.webp`,
    sku: 'OR2065L-2S',
    colorName: 'Khaki',
    link: '/sg/en/owndays-plus-or2065l-2s-c4-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
];

export const SKU_OR2082L4S = [
  {
    alt: 'OR2082L-4S C1',
    src: `${baseImgUrl}/OR2082L-4S-C1.webp`,
    sku: 'OR2082L-4S',
    colorName: 'Black',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2082L-4S C2',
    src: `${baseImgUrl}/OR2082L-4S-C2.webp`,
    sku: 'OR2082L-4S',
    colorName: 'Navy',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2082L-4S C3',
    src: `${baseImgUrl}/OR2082L-4S-C3.webp`,
    sku: 'OR2082L-4S',
    colorName: 'Brown Demi',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
  {
    alt: 'OR2082L-4S C4',
    src: `${baseImgUrl}/OR2082L-4S-C4.webp`,
    sku: 'OR2082L-4S',
    colorName: 'Clear',
    link: '/stores/sg',
    buttonName: 'Available In-Store Only',
  },
];

export const SKU_OR2064L2S = [
  {
    alt: 'OR2064L-2S C1',
    src: `${baseImgUrl}/OR2064L-2S-C1.webp`,
    sku: 'OR2064L-2S',
    colorName: 'Black',
    link: '/sg/en/owndays-plus-or2064l-2s-c1-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2064L-2S C2',
    src: `${baseImgUrl}/OR2064L-2S-C2.webp`,
    sku: 'OR2064L-2S',
    colorName: 'Brown Demi',
    link: '/sg/en/owndays-plus-or2064l-2s-c2-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2064L-2S C3',
    src: `${baseImgUrl}/OR2064L-2S-C3.webp`,
    sku: 'OR2064L-2S',
    colorName: 'Brown',
    link: '/sg/en/owndays-plus-or2064l-2s-c3-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2064L-2S C4',
    src: `${baseImgUrl}/OR2064L-2S-C4.webp`,
    sku: 'OR2064L-2S',
    colorName: 'Beige',
    link: '/sg/en/owndays-plus-or2064l-2s-c4-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
];

export const SKU_OR2063L2S = [
  {
    alt: 'OR2063L-2S C1',
    src: `${baseImgUrl}/OR2063L-2S-C1.webp`,
    sku: 'OR2063L-2S',
    colorName: 'Matte Black',
    link: '/sg/en/owndays-plus-or2063l-2s-c1-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2063L-2S C2',
    src: `${baseImgUrl}/OR2063L-2S-C2.webp`,
    sku: 'OR2063L-2S',
    colorName: 'Brown Demi',
    link: '/sg/en/owndays-plus-or2063l-2s-c2-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2063L-2S C3',
    src: `${baseImgUrl}/OR2063L-2S-C3.webp`,
    sku: 'OR2063L-2S',
    colorName: 'Brown',
    link: '/sg/en/owndays-plus-or2063l-2s-c3-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
  {
    alt: 'OR2063L-2S C4',
    src: `${baseImgUrl}/OR2063L-2S-C4.webp`,
    sku: 'OR2063L-2S',
    colorName: 'Clear Gray',
    link: '/sg/en/owndays-plus-or2063l-2s-c4-eyeglasses.html',
    buttonName: 'ONLINE STORE',
  },
];
