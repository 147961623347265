import React, { Fragment } from 'react';

import './BodyOwndays1For1ContactLensDeal.scss';

export const BodyOwndays1For1ContactLensDeal = () => {
  return (
    <Fragment>
      <section className="base-owndays-1-for-1-contact-lens-deal__container body-owndays-1-for-1-contact-lens-deal__container-message">
        <p>
          <strong>Duration :</strong> From now till 31 Dec 2024, while stocks last
        </p>
        <p>
          Unlock incredible savings with our 1-for-1 clear contact lens deal. Exclusively for new
          contact lens customers.
          <br />
          <br />
          OWNDAYS Clear Vision contact lenses feature high water content, allowing the eye to feel
          hydrated and fresh all-day long. The lenses are infused with hyaluronic acid that acts as
          an ocular lubricant, cushioning the eye and minimising friction while maintaining
          hydration for enhanced comfort. Besides, the lenses block up to 80% UVA and 95% UVB,
          safeguarding your eyes from harmful UV rays.
        </p>
      </section>
    </Fragment>
  );
};
