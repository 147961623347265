import React from 'react';
import './Button.scss';
const Button = props => {
  const { children, onClick, className, variant = 'default', ...other } = props;
  return (
    <button
      className={`${className || ''} custom-btn ${variant}`}
      type="button"
      onClick={onClick}
      {...other}
      data-testid="button"
      style={{ ...props.style }}
    >
      {children}
    </button>
  );
};

export default Button;
