import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import { NEWS_CATEGORIES, NEWS_LIST, PRODUCT_NEWS, OWNDAYS_MEETS_NEWS, CAMPAING_NEWS } from './getNews';
import './BlogNews.scss';
import 'aos/dist/aos.css';

const NEWS_MAP = {
  '#all': NEWS_LIST,
  '#owndays-meets': OWNDAYS_MEETS_NEWS,
  '#products': PRODUCT_NEWS,
  '#campaign': CAMPAING_NEWS,
};

const NewsItem = React.memo(({ item, index }) => (
  <li data-aos="fade-up" data-aos-delay={index * 200}>
    <a href={item.slug}>
      <figure>
        <div className="blog-news__list-thumbnail">
          <img alt={item.title} src={item.image} />
        </div>
        <figcaption>
          <h4 className="blog-news__list-category">{item.category}</h4>
          <p className="blog-news__list-title">{item.title}</p>
          <p className="blog-news__list-view-more">VIEW MORE</p>
        </figcaption>
      </figure>
    </a>
  </li>
));

export const BlogNews = () => {
  const [currentCategory, setCurrentCategory] = useState('#all');
  const [listNews, setListNews] = useState(NEWS_LIST);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setCurrentCategory(hash);
    }
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    setListNews(NEWS_MAP[currentCategory] || NEWS_LIST);
  }, [currentCategory]);

  const handleCategoryClick = (e, category) => {
    e.preventDefault();
    setCurrentCategory(category);
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
  };

  const filteredNews = listNews.filter(item => {
    if (!item.startDeactivate || !item.endDeactivate) {
      return true;
    }

    const currentDate = new Date();
    const startDeactivate = new Date(item.startDeactivate);
    const endDeactivate = new Date(item.endDeactivate);
    const inStartEvent = currentDate >= startDeactivate;
    const inEndEvent = currentDate <= endDeactivate;

    return !(inStartEvent && inEndEvent);
  });

  return (
    <div className="blog-news">
      <section className="blog-news__categories">
        <ul>
          {NEWS_CATEGORIES.map(item => (
            <li
              key={item.name}
              className={currentCategory === item.hash ? 'selected-new-category' : ''}
              onClick={e => handleCategoryClick(e, item.hash)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </section>

      <section className="blog-news__container">
        <div className="blog-news__block blog-news__block--active">
          <ul className="blog-news__list blog-news__list--open">
            {filteredNews.map((item, index) => (
              <NewsItem key={item.title} item={item} index={index} />
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};
