export const SET_CANCEL_PRODUCTS = 'cancelProduct/SET_CANCEL_PRODUCTS';
export const SET_CANCEL_PRODUCTS_LIST = 'cancelProduct/SET_CANCEL_PRODUCTS_LIST';

export const GET_CANCEL_REASONS = 'cancelOrder/GET_CANCEL_REASONS';
export const GET_CANCEL_REASONS_SUCCESS = 'cancelOrder/GET_CANCEL_REASONS_SUCCESS';
export const GET_CANCEL_REASONS_FAIL = 'cancelOrder/GET_CANCEL_REASONS_FAIL';

export const SET_CURRENT_CANCEL_REASON = 'cancelOrder/SET_CURRENT_CANCEL_REASON';
export const SET_CURRENT_REFUND_METHOD = 'cancelOrder/SET_CURRENT_REFUND_METHOD';

export const CANCEL_ORDER_LOAD = 'cancelOrder/CANCEL_ORDER_LOAD';
export const CANCEL_ORDER_SUCCESS = 'cancelOrder/CANCEL_ORDER_SUCCESS';
export const CANCEL_ORDER_FAIL = 'cancelOrder/CANCEL_ORDER_FAIL';
