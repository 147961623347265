import React from 'react';

import './FooterMemoryMetal.scss';

export const FooterMemoryMetal = ({ baseImgUrl }) => {
  return (
    <section className="recommend">
      <p className="base-memory-metal__heading" data-aos="fade-left">
        RECOMMENDED BRANDS
      </p>
      <div className="base-memory-metal__container base-memory-metal__container--sm">
        <h2 data-aos className="base-memory-metal__title">
          <span>
            <small>Lightweight, Durable, Flexible</small>OWNDAYS Spectacle Frames{' '}
          </span>
        </h2>
        <div data-aos className="recommend__content">
          <span className="recommend__content-border recommend__content-border--top"></span>
          <span className="recommend__content-border recommend__content-border--left"></span>
          <span className="recommend__content-border recommend__content-border--right"></span>
          <span className="recommend__content-border recommend__content-border--bottom"></span>
          <ul className="recommend__list">
            <li className="recommend__list-item" data-aos="zoom-in">
              <div className="recommend__img">
                <picture>
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${baseImgUrl}/brand-airultem-pc.webp`}
                  />
                  <img alt="AIR Ultem" src={`${baseImgUrl}/brand-airultem-sp.webp`} />
                </picture>
              </div>
              <div className="recommend__details">
                <p className="recommend__text">
                  A collection of frames made of ultem, an ultra-flexible material that is amazingly
                  light and durable. These unique properties make AIR Ultem a highly functional
                  collection that is perfect for day-to-day use.
                </p>
                <a
                  className="base-memory-metal__btn base-memory-metal__btn--black"
                  href="/sg/en/news/airultem"
                >
                  <span data-hover="More Details on AIR Ultem">More Details on AIR Ultem</span>
                </a>
              </div>
            </li>
            <li className="recommend__list-item" data-aos="zoom-in">
              <div className="recommend__img">
                <picture>
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${baseImgUrl}/brand-airfit-pc.webp`}
                  />
                  <img alt="AIR FIT" src={`${baseImgUrl}/brand-airfit-sp.webp`} />
                </picture>
              </div>
              <div className="recommend__details">
                <p className="recommend__text">
                  So comfortable you feel like you’re not wearing glasses spectacles ― this is the
                  idea which has inspired us to create a collection featuring frames made of a
                  material that is both lightweight, flexible and corrosion-resistant.
                </p>
                <a
                  className="base-memory-metal__btn base-memory-metal__btn--black"
                  href="/sg/en/eyeglasses/brands/AIRFIT.html"
                >
                  <span data-hover="See All AIR FIT Products">See All AIR FIT Products</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};
