import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import * as actionTypes from '../actionTypes/offerCampaign';
import apiClient from '../helpers/apiClient';

let _client = null;

function* sendCampaignUserData(formData) {
  const successAction = { type: actionTypes.SAVE_CAMPAIGN_USER_DATA_SUCCESS };
  const errorAction = { type: actionTypes.SAVE_CAMPAIGN_USER_DATA_FAIL };
  const client = Object.assign({}, { ..._client });
  client.setHeader();
  try {
    yield call(async () => client.post(config.apiPath.campaignFirstDeal, formData?.data));
    successAction.data = formData?.data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* checkUserExists({ phone }) {
  const successAction = { type: actionTypes.CHECK_USER_EXISTS_SUCCESS };
  const errorAction = { type: actionTypes.CHECK_USER_EXISTS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.checkUserExists}?phone=${phone}`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* firstDealUserExists({ phone }) {
  const successAction = { type: actionTypes.FIRST_DEAL_USER_EXISTS_SUCCESS };
  const errorAction = { type: actionTypes.FIRST_DEAL_USER_EXISTS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.checkFirstDealUserExists}?phone=${phone}`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runOfferCampaignCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.SAVE_CAMPAIGN_USER_DATA_LOAD, sendCampaignUserData),
    takeLatest(actionTypes.CHECK_USER_EXISTS_LOAD, checkUserExists),
    takeLatest(actionTypes.FIRST_DEAL_USER_EXISTS_LOAD, firstDealUserExists),
  ]);
}
