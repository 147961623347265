import React, { useEffect, useRef, useState } from 'react';
import './ApplyGiftCard.scss';
import ButtonOwnDays from './../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { applyGiftCard, getCart } from '../../../../actionCreators/cart';
import { useDispatch, useSelector, connect } from 'react-redux';
import { setToastMessage } from '../../../../actionCreators/common';
import GiftCardList from '../../../CommonComponents/GiftCardList/GiftCardList';

const ApplyGiftCard = props => {
  const { setShowSideBar, dataLocale } = props;

  const cartData = useSelector(state => state.cart.cartData.result);
  const giftCardsData = useSelector(state => state.cart.giftCardsdata);
  const giftCardsErrorMessage = useSelector(state => state.cart.giftCardsError);

  const dispatch = useDispatch();

  const { ADD_GIFT_CARD = 'Add gift card', APPLY_GIFT_CARD = 'Apply Gift Card' } = dataLocale;

  const [giftCardClick, setGiftCardClick] = useState(false);

  const giftValue = useRef();

  useEffect(() => {
    if (giftCardClick && giftCardsData) dispatch(getCart());
  }, [dispatch, giftCardClick, giftCardsData]);

  const handleApplyGift = () => {
    if (!giftValue.current.value) {
      dispatch(
        setToastMessage({
          error: true,
          message: 'fill the gift card field',
        })
      );
    } else if (cartData.giftCards && cartData.giftCards.length < 5) {
      setGiftCardClick(true);
      dispatch(applyGiftCard(giftValue.current.value));
    }
  };

  return (
    <div className="od-gift-codes-wrapper">
      <div className="od-gift-codes">
        <div className="od-gift-codes-header">
          {/* <div className="od-gift-codes-header__icon" onClick={() => setShowSideBar(false)}>
            <img
              alt="back"
              src="https://static1.lenskart.com/media/desktop/img/DesignStudioIcons/LeftArrowBlue.svg"
            />
          </div> */}
          <div className="od-gift-codes-header__title od-font-bold">{APPLY_GIFT_CARD}</div>
          <div className="od-gift-codes-header__icon" onClick={() => setShowSideBar(false)}>
            <img
              alt="close"
              src="https://static1.lenskart.com/media/desktop/img/DesignStudioIcons/CrossBlue.svg"
            />{' '}
          </div>
        </div>

        <div>
          <GiftCardList />
          {!giftCardClick && cartData.giftCards && cartData.giftCards.length < 5 && (
            <div className="od-gift-codes-add">
              <input
                ref={giftValue}
                className="od-gift-codes-add__input"
                name="gift"
                placeholder="Enter gift card"
                type="text"
              />
            </div>
          )}

          {giftCardsErrorMessage && giftCardsErrorMessage.message && (
            <div className="od-gift-codes-add__error">{giftCardsErrorMessage.message}</div>
          )}
          {cartData.giftCards && cartData.giftCards.length < 5 && (
            <ButtonOwnDays
              borderless
              noHover
              fontClass="od-font-reg"
              fontSize="14px"
              mode="grey-secondary"
              px="20px"
              py="8px"
              style={{
                display: 'flex',
                gap: '16px',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '16px',
              }}
              width="168px"
              onClick={() => {
                if (giftCardClick) setGiftCardClick(false);
              }}
            >
              <span>{ADD_GIFT_CARD}</span>
              <img
                alt="plus black"
                height="16px"
                src="https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-plus-black-thin.svg"
                width="16px"
              />
            </ButtonOwnDays>
          )}
        </div>
        <div className="od-gift-codes-multiple">
          <ul className="od-gift-codes-multiple__description">
            <li>
              You can use multiple gift codes with one payment. Please add a frame and enter the
              code.
            </li>
            <li>
              It can be used for payments with less than the face value, but please note that we
              cannot offer change.
            </li>
          </ul>
        </div>
      </div>
      <div className="od-gift-codes-apply">
        <ButtonOwnDays mode="grey" style={{ flexShrink: 0 }} width="100%" onClick={handleApplyGift}>
          {APPLY_GIFT_CARD}
        </ButtonOwnDays>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const cartData = state.cart.cartData ? state.cart.cartData.result : null;
  return {
    cartData,
    currencyMark: state.common.currencyMark,
    dataLocale: state.locale,
    isCartUpdating: state.cart.isCartLoader,
    login: state.common.login,
    redisCommonData: state.common.redisCommonData,
    localeInfo: state.common.localeInfo,
  };
};

export default connect(mapStateToProps, null)(ApplyGiftCard);
