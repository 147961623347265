import React from 'react';
import './SelectLensesModalProgress.scss';

const SELECT_LENS = 'select_lens';
const SELECT_ADDONS = 'select_addons';
const SELECT_PRESCRIPTION = 'select_prescription';
const FRAME_ONLY = 'frame_only';

const SelectLensesModalProgress = ({ currentStep }) => {
  const getClassForStep = step => {
    switch (step) {
      case SELECT_PRESCRIPTION:
        return 'odslv-modal__progress__value--select-prescription';
      case SELECT_LENS:
        return 'odslv-modal__progress__value--select-lens';
      case FRAME_ONLY:
      case SELECT_ADDONS:
        return 'odslv-modal__progress__value--frame-only odslv-modal__progress__value--select-addons';
      default:
        return '';
    }
  };

  return (
    <div className="odslv-modal__progress">
      <div className={`odslv-modal__progress__value ${getClassForStep(currentStep)}`} />
    </div>
  );
};

export default SelectLensesModalProgress;
