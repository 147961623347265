import React, { Fragment } from 'react';

import {
  HeaderOrthoKWebinar,
  BodyOrthoKWebinar,
  FooterOrthoKWebinar,
} from '../CommonComponents/OrthoKWebinar';

import '../CommonComponents/OrthoKWebinar/BaseOrthoKWebinar.scss';

const OrthoKWebinarHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/ortho-k-webinar';

  return (
    <Fragment>
      <HeaderOrthoKWebinar baseImgUrl={baseImgUrl} />
      <BodyOrthoKWebinar baseImgUrl={baseImgUrl} />
      <FooterOrthoKWebinar baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default OrthoKWebinarHTML;
