import * as actionTypes from '../actionTypes/returnExchange';

export function updateHeader(title) {
  return { type: actionTypes.HEADER_UPDATE, title };
}

export function hideNav(flag) {
  return { type: actionTypes.HIDE_NAV, flag };
}

// return
export function setReturnProducts(products) {
  return { type: actionTypes.SET_RETURN_PRODUCTS, products };
}

export function mergeReturnDetails(data) {
  return { type: actionTypes.MERGE_RETURN_DETAILS, data };
}

export function getReturnModes(pincode) {
  return { type: actionTypes.GET_RETURN_MODES_LOAD, pincode };
}

export function getStoreList(address, radius, pageSize, pageNumber) {
  return { type: actionTypes.STORE_LOCATION_LOAD, address, radius, pageSize, pageNumber };
}

export function checkAddressServiceable(pincode) {
  return { type: actionTypes.GET_SERVICEABLE_ADDRESS_LOAD, pincode };
}

export function saveUserAddress(address) {
  return { type: actionTypes.USER_ADDRESS_UPDATE, address };
}

export function saveExchangeAddress(address) {
  return { type: actionTypes.EXCHANGE_ADDRESS_UPDATE, address };
}

export function saveStoreAddress(address) {
  return { type: actionTypes.STORE_ADDRESS_UPDATE, address };
}

export function getReturnReasons(classification) {
  return { type: actionTypes.GET_RETURN_REASONS, classification };
}

export function createReturn(orderId, reqData) {
  return { type: actionTypes.CREATE_RETURN_LOAD, orderId, reqData };
}

export function getReturnLabelStatus(groupId) {
  return { type: actionTypes.GET_RETURN_LABEL_STATUS_LOAD, groupId };
}

// exchange
export function setOrder() {
  return { type: actionTypes.SET_ORDER };
}

export function setIsExchange(flag) {
  return { type: actionTypes.SET_IS_EXCHANGE, flag };
}

// Post Return
export function getReturnDetails(orderId, itemId) {
  return { type: actionTypes.GET_RETURN_DETAILS_LOAD, orderId, itemId };
}

export function getReturnActions(orderId, itemId) {
  return { type: actionTypes.GET_RETURN_ACTIONS_LOAD, orderId, itemId };
}

export function setExchangeOrderAndItem(orderId, itemId) {
  return { type: actionTypes.SET_EXCHANGE_ORDER_AND_ITEM, orderId, itemId };
}

export function cleanReturnExchange() {
  return { type: actionTypes.SET_EXCHANGE_RETURN_CLEAN };
}

// refund
export function createRefund(reqData) {
  return {
    type: actionTypes.CREATE_REFUND_LOAD,
    orderId: reqData.orderId,
    itemId: reqData.itemId,
    refundMethod: reqData.refundMethod,
  };
}

export function getRefundEstimate(orderId, itemId) {
  return { type: actionTypes.GET_REFUND_ESTIMATE_LOAD, orderId, itemId };
}

export function getEligibilityReturnStatusForOrder(orderId) {
  return { type: actionTypes.GET_ELIGIBILITY_RETURN_STATUS, orderId };
}

export function getOrderItemsReturnElgibilty(orderId) {
  return { type: actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY, orderId };
}

// product return data for create return
export function setReturnItemDetails(data) {
  return { type: actionTypes.SET_RETURN_ITEM_DETAILS, data };
}

export function setCurrentReturnItem(data) {
  return { type: actionTypes.SET_CURRENT_RETURN_ITEM, data };
}

export function setNewExchangeFLow(flag) {
  return { type: actionTypes.SET_NEW_EXCHANGE_FLOW, flag };
}

export function cancelReturnItem(orderId, returnId) {
  return { type: actionTypes.RETURN_CANCEL_LOAD, orderId, returnId };
}

export function showLoader() {
  return { type: actionTypes.SHOW_LOADER };
}

export function hideLoader() {
  return { type: actionTypes.HIDE_LOADER };
}
