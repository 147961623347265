import * as actionTypes from './../../actionTypes/savedCards';

const initialState = {
  loading: false,
  savedCardDelete: false,
  getUsersSavedCards: null,
  getUsersSavedCardsOffer: null,
  error: null,
  deleteSaveCardFail: false,
};

export default function savedCards(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_USER_SAVED_CARDS_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        getUsersSavedCards: null,
      });
    case actionTypes.GET_USER_SAVED_CARDS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        getUsersSavedCards: action.result,
      });
    case actionTypes.GET_USER_SAVED_CARDS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        getUsersSavedCards: null,
        error: action.error,
      });
    case actionTypes.GET_USER_SAVED_CARDS_OFFER_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        getUsersSavedCardsOffer: null,
      });
    case actionTypes.GET_USER_SAVED_CARDS_OFFER_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        getUsersSavedCardsOffer: action.result,
      });
    case actionTypes.GET_USER_SAVED_CARDS_OFFER_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        getUsersSavedCardsOffer: null,
        error: action.error,
      });
    case actionTypes.DELETE_USER_SAVED_CARD_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        savedCardDelete: false,
      });
    case actionTypes.DELETE_USER_SAVED_CARD_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        savedCardDelete: true,
      });
    case actionTypes.DELETE_USER_SAVED_CARD_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        loaded: true,
        savedCardDelete: false,
        error: action.error,
        deleteSaveCardFail: true,
      });
    case actionTypes.RESET_USER_SAVED_CARDS:
      return initialState;
    default:
      return prevState;
  }
}
