export const GET_LENS_PACKAGE_DETAILS = 'GET_LENS_PACKAGE_DETAILS';
export const GET_LENS_PACKAGE_DETAILS_SUCCESS = 'GET_LENS_PACKAGE_DETAILS_SUCCESS';
export const GET_LENS_PACKAGE_DETAILS_FAIL = 'GET_LENS_PACKAGE_DETAILS_FAIL';

export const UPDATE_POWER_TYPE_LOAD = 'UPDATE_POWER_TYPE_LOAD';
export const UPDATE_POWER_TYPE_SUCCESS = 'UPDATE_POWER_TYPE_SUCCESS';
export const UPDATE_POWER_TYPE_FAIL = 'UPDATE_POWER_TYPE_FAIL';

export const UPDATE_PACKAGE_LOAD = 'UPDATE_PACKAGE_LOAD';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAIL = 'UPDATE_PACKAGE_FAIL';

export const UPDATE_PRESCRIPTION_LOAD = 'UPDATE_PRESCRIPTION_LOAD';
export const UPDATE_PRESCRIPTION_SUCCESS = 'UPDATE_PRESCRIPTION_SUCCESS';
export const UPDATE_PRESCRIPTION_FAIL = 'UPDATE_PRESCRIPTION_FAIL';

export const RESET_UPDATE_FIELDS = 'RESET_UPDATE_FIELDS';
