import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import apiClient from '../helpers/apiClient';
import * as actionTypes from '../actionTypes/plpBanner';

let _client = null;

function* getPlpBannerConfig() {
  const successAction = { type: actionTypes.GET_PLP_BANNER_CONFIG_SUCCESS };
  const errorAction = { type: actionTypes.GET_PLP_BANNER_CONFIG_FAIL };
  try {
    const data = yield call(async () => _client.get(`${config.apiPath.plpBanner}`));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runPLPBannerCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.GET_PLP_BANNER_CONFIG, getPlpBannerConfig)]);
}
