import React from 'react';

const Share = ({ isSP }) => {
  const shops = [
    'OWNDAYS Bedok Mall',
    'OWNDAYS nex',
    'OWNDAYS ION Orchard',
    'OWNDAYS IMM Outlet',
    'OWNDAYS Jurong Point',
    'OWNDAYS Vivo City',
    'OWNDAYS Waterway Point',
    'OWNDAYS Compass One',
    'OWNDAYS Westgate',
    'OWNDAYS Suntec City',
    'OWNDAYS Century Square',
    'OWNDAYS Causeway Point',
    'OWNDAYS MBS Premium Concept Store',
    'OWNDAYS Takashimaya S.C Premium Concept Store',
    'OWNDAYS Pasir Ris Mall',
  ];
  const shops1 = shops.slice(0, 8);
  const shops2 = shops.slice(8);
  return (
    <section className="shop">
      <div className="l-hp__container">
        <h2 className="l-hp__title text-effect" data-aos="fade-up">
          <span>
            AVAILABLE <br />
            SHOPS
          </span>
        </h2>

        {isSP ? (
          <ul data-aos="fade-up">
            {shops.map((shop, index) => (
              <li key={index}>{shop}</li>
            ))}
          </ul>
        ) : (
          <div className="shop__col-wrap">
            <div className="shop__col" data-aos="fade-up">
              <ul>
                {shops1.map((shop, index) => (
                  <li key={index}>{shop}</li>
                ))}
              </ul>
              <ul>
                {shops2.map((shop, index) => (
                  <li key={index + 8}>{shop}</li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      {/* move position from merchandise */}
      <div className="merchandise__buttons">
        <a className="l-hp__btn" href="/sg/en/eyeglasses/brands/harry-potter.html">
          <span className="l-hp__btn-inner">ALL ITEMS</span>
        </a>
        <a className="l-hp__btn" href="https://www.owndays.com/stores/sg">
          <span className="l-hp__btn-inner">Search for OWNDAYS stores</span>
        </a>
      </div>
    </section>
  );
};

export default Share;
