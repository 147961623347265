import React from 'react';
import './WishlistPageItem.scss';

import Pricing from '../../../../../CommonComponents/ProductCard/Pricing';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonCallsActionCreators from '../../../../../actionCreators/common';
import { removeDomainName } from '../../../../helper';
import { addToCart } from '../../../../../actionCreators/cart';
import SelectLenses from '../../../../CommonComponents/SelectLensesModal/SelectLenses';
import { toggleLensesSelectionPopup } from '../../../../../actionCreators/product';

const WishlistPageItem = props => {
  const { dataLocale, productData, localeInfo, commonCallsAction, login } = props;
  const { CURRENCY_SYMBOL, ADD_TO_CART, REMOVE } = dataLocale;

  const lenskartPrice = productData && productData?.prices[1].price;
  const firstBuyPrice =
    productData && productData?.prices.length === 3 && productData?.prices[2]?.price;
  const finalprice = productData?.prices.length === 3 ? firstBuyPrice : lenskartPrice;
  const marketPrice = productData?.prices[0].price;
  const productId = productData?.id;

  const dispatch = useDispatch();

  const redirectToProduct = () => {
    const url = productData.productUrl;
    if (url?.length > 0 && url.includes('sg/en/')) {
      window.location.assign(`/${removeDomainName(url)}`);
    } else {
      window.location.assign(`/sg/en/${removeDomainName(url)}`);
    }
  };

  const removeFromWishlist = () => {
    commonCallsAction.shortListType(true);

    if (window.dtm.LenskartRewamp)
      window.dtm.LenskartRewamp.Listing.click.removeProdcutShortlist(login, productData.id);

    commonCallsAction.shortlist(productData.id, 'delete', `/${productData.id}`);
  };

  const handleAddToCart = () => {
    if (productData?.classification === 'sunglasses') {
      dispatch(addToCart({ productId, powerType: 'Frame Only' }));
    } else {
      props.toggleLensesSelectionPopup(true);
    }
  };

  const handleLensesModal = value => {
    props.toggleLensesSelectionPopup(value);
  };

  console.log('Whishlist product data ===>', productData);

  return (
    <>
      {props?.isLensesSelectionPopupOpen && (
        <div style={{ position: 'absolute', zIndex: '15' }}>
          <SelectLenses handleModal={handleLensesModal} productData={productData} />
        </div>
      )}
      <div className="wishlist-page-item">
        <div className="wishlist-page-item__layout">
          <div
            className="wishlist-page-item__image"
            style={{
              backgroundImage: `url(${productData?.productImageUrl})`,
            }}
            onClick={redirectToProduct}
          />

          <div className="wishlist-page-item__details">
            <p className="wishlist-page-item__title od-font-bold" onClick={redirectToProduct}>
              {productData?.brandName} {productData?.modelName}
            </p>

            <Pricing
              isMobile
              currencySymbol={CURRENCY_SYMBOL}
              dataLocale={dataLocale}
              finalprice={finalprice}
              localeInfo={localeInfo}
              marketPrice={marketPrice}
            />

            <hr className="wishlist-page-item__divider" />

            <div className="wishlist-page-item__actions">
              <button
                className="wishlist-page-item__action od-font-bold"
                onClick={removeFromWishlist}
              >
                {REMOVE}
              </button>

              <div className="wishlist-page-item__actions-divider" />

              <button className="wishlist-page-item__action od-font-bold" onClick={handleAddToCart}>
                {ADD_TO_CART}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
    localeInfo: state.common.localeInfo,
    login: state.common.login,
    isLensesSelectionPopupOpen: state.product.isLensesSelectionPopupOpen,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    commonCallsAction: bindActionCreators(commonCallsActionCreators, dispatch),
    toggleLensesSelectionPopup: data => dispatch(toggleLensesSelectionPopup(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPageItem);
