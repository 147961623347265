import React, { Fragment, useEffect } from 'react';
import Slider from 'react-slick';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { CONCEPT_LIST, ABOUT_LIST } from './constants';

import './HeaderOwndaysSunglasses.scss';

export const HeaderOwndaysSunglasses = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news#all', label: 'News' },
    { path: '#', label: 'OWNDAYS SUNGLASSES COLLECTION 2024' },
  ];

  const conceptSettings = {
    dots: false,
    touchMove: false,
    swipe: false,
    arrows: false,
    draggable: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: 'linear',
    spaceBetween: 0,
    variableWidth: true,
  };

  useEffect(() => {
    // Initialize Fancybox
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-owndays-sunglasses__main">
        <div className="base-owndays-sunglasses__container">
          <h1>
            <span>OWNDAYS </span>
            <br />
            SUNGLASSES <br />
            COLLECTION
          </h1>
          <div className="header-owndays-sunglasses__main__uvcut">
            <img
              alt="Add a little sass to change your look from drab to fab!"
              src={`${baseImgUrl}/logo-UVCUT.webp`}
            />
          </div>
        </div>
        <picture className="header-owndays-sunglasses__main__img">
          <source media="(min-width:768px)" srcSet={`${baseImgUrl}/main-pc.webp`} />
          <img alt="OWNDAYS SUNGLASSES COLLECTION" src={`${baseImgUrl}/main-sp.webp`} />
        </picture>
      </section>

      <section className="header-owndays-sunglasses__concept">
        <div className="base-owndays-sunglasses__container">
          <h2 className="header-owndays-sunglasses__concept__title">
            Add a little sass to <br />
            change your look from drab to fab!
          </h2>
          <picture className="header-owndays-sunglasses__concept__pic">
            <source media="(min-width:1024px)" srcSet={`${baseImgUrl}/concept-pc.webp`} />
            <img
              alt="Add a little sass to change your look from drab to fab!"
              className="lazyload"
              src={`${baseImgUrl}/concept-sp.webp`}
            />
          </picture>
          <p className="header-owndays-sunglasses__concept__desc">
            All OWNDAYS sunglasses{' '}
            <strong className="header-owndays-sunglasses__concept__desc__blue-text">
              block over 99% UV!{' '}
            </strong>
            <br />
            From neutrals in earth tones to transparent frames, sunglasses this season are all about
            subtle nuances that rejuvenate your everyday look while protecting your eyes from UV
            rays. Put on a pair that resonates with your style and make it an extension of your
            personality.
          </p>
          <a
            className="base-owndays-sunglasses__btn base-owndays-sunglasses__btn--black"
            href="/sg/en/eyewear/sunglasses.html"
          >
            See All Sunglasses
          </a>
        </div>

        <div className="header-owndays-sunglasses__concept__slide header-owndays-sunglasses__concept__slide__list">
          <Slider
            {...conceptSettings}
            className="header-owndays-sunglasses__concept__slide__list-wrapper"
          >
            {CONCEPT_LIST.map((slide, index) => (
              <li key={index} className="header-owndays-sunglasses__concept__slide__list-item">
                <img alt={slide.alt} className="lazyload" src={slide.src} />
              </li>
            ))}
          </Slider>
        </div>
      </section>

      <section className="header-owndays-sunglasses__about">
        <div className="base-owndays-sunglasses__container">
          <h2 className="base-owndays-sunglasses__title">
            About <small>Features of OWNDAYS sunglasses</small>
          </h2>

          <ul className="header-owndays-sunglasses__about__ul">
            {ABOUT_LIST.map((item, index) => (
              <li key={index}>
                <picture>
                  <source media="(min-width:768px)" srcSet={item.pcSrc} />
                  <img alt={item.alt} className="lazyload" src={item.spSrc} />
                </picture>
                <p dangerouslySetInnerHTML={{ __html: item.text }} />
              </li>
            ))}
          </ul>

          <div className="header-owndays-sunglasses__about__note">
            <span>Top-up fee of $198 applies for adding prescription to colour lenses</span>
            <span>Adding of prescription to sunglasses is not available at the online store</span>
          </div>
        </div>

        <a
          className="header-owndays-sunglasses__concept__video-modal"
          data-fancybox="etc"
          href="https://youtu.be/LaznxDzYGi4"
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            className="lazyload"
            src={`${baseImgUrl}/sunglasses-2024.mp4`}
          ></video>
        </a>
      </section>
    </Fragment>
  );
};
