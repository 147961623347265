import * as actionTypes from './../../actionTypes/search';

const initialState = {
  loaded: false,
  loading: false,
  searchData: null,
  algoliaSearchData: null,
  query: null,
};

export default function search(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH_ALGOLIA_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        algoliaSearchData: action.algoliaSearchData,
      });
    case actionTypes.SEARCH_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        searchData: action.data,
        query: action.query,
      });
    case actionTypes.RESET_SEARCH_DATA:
      return Object.assign({}, prevState, { searchData: null, algoliaSearchData: null });
    default:
      return prevState;
  }
}
