export const GET_MEMBERSHIP_POINTS = 'membership/GET_MEMBERSHIP_POINTS';
export const GET_MEMBERSHIP_POINTS_SUCCESS = 'membership/GET_MEMBERSHIP_POINTS_SUCCESS';
export const GET_MEMBERSHIP_POINTS_FAIL = 'membership/GET_MEMBERSHIP_POINTS_FAIL';

export const APPLY_MEMBERSHIP_POINTS = 'membership/APPLY_MEMBERSHIP_POINTS';
export const APPLY_MEMBERSHIP_POINTS_SUCCESS = 'membership/APPLY_MEMBERSHIP_POINTS_SUCCESS';
export const APPLY_MEMBERSHIP_POINTS_FAIL = 'membership/APPLY_MEMBERSHIP_POINTS_FAIL';

export const REMOVE_MEMBERSHIP_POINTS = 'membership/REMOVE_MEMBERSHIP_POINTS';
export const REMOVE_MEMBERSHIP_POINTS_SUCCESS = 'membership/REMOVE_MEMBERSHIP_POINTS_SUCCESS';
export const REMOVE_MEMBERSHIP_POINTS_FAIL = 'membership/REMOVE_MEMBERSHIP_POINTS_FAIL';

export const GET_MEMBERSHIP_POINTS_HISTORY_LOAD = 'membership/GET_MEMBERSHIP_POINTS_HISTORY_LOAD';
export const GET_MEMBERSHIP_POINTS_HISTORY_SUCCESS =
  'membership/GET_MEMBERSHIP_POINTS_HISTORY_SUCCESS';
export const GET_MEMBERSHIP_POINTS_HISTORY_FAIL = 'membership/GET_MEMBERSHIP_POINTS_HISTORY_FAIL';
