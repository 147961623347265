import { fork, call, put, take, takeLatest, all, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import config from 'config';
// import redisConfigPath from 'redis.config.js';
import * as actionTypes from '../actionTypes/common';
import apiClient from '../helpers/apiClient';
import getSetCookie from '../cookie';
import * as categoryActionTypes from '../actionTypes/category';
import * as productActionTypes from '../actionTypes/product';
import * as cmsActionTypes from '../actionTypes/cmsMapping';
import * as shortlistActionTypes from '../actionTypes/shortlist';
import { COLLECTION_PAGE_DATA_SUCCESS } from '../actionTypes/collectionPage';
import {
  CHECKOUT_STEP_KEY,
  GET_PRESALE_INFO_SUCCESS,
  GET_PRESALE_INFO_FAIL,
} from '../actionTypes/checkout';
import { SET_DATALAYER_EMAIL_MOBILE, RESET_DATALAYER_EMAIL_MOBILE } from '../actionTypes/DataLayer';
import { getLocaleInfo } from '../selectors/localeReducer';
import { SET_DTM_USERINFO, RESET_DTM_USERINFO } from '../actionTypes/DTM';
import { modifyProductData, setProductStaticDTM } from './responseDataManipulator';
import { SET_IS_EXCHANGE } from '../actionTypes/returnExchange';
import { commonState } from './state';
import sessionStorageHelper from '../utils/sessionStorageHelper';

let _client = null;
let _location = null;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

function* getResource(resource, query, pathname, successAction, errorAction, counter, headers) {
  const client = { ..._client };
  client.setHeader();
  try {
    const getOptions = headers ? { headers } : undefined;
    const data = yield call(async () => client.get(resource, getOptions));
    successAction.data = data;
    successAction.data.counter = counter || 0;
    if (query !== '') {
      successAction.data.query = query;
      successAction.data.pathname = pathname;
    }
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

async function wishlist(pid, method, query) {
  const client = { ..._client };
  client.setHeader();
  const url = new URL(config.apiPath.wishlist + query);
  url.searchParams.append('additionalFields', true);
  try {
    const options = {};
    let data;
    if (pid) {
      data = {
        productId: pid,
      };
    }
    let response;
    if (method === 'post') {
      response = await client.post(url.href, data, options);
    } else {
      if (data) {
        options.data = data;
      }
      response = await client[method](url.href, options);
    }
    return response;
  } catch (er) {
    throw er;
  }
}

function* dittoAuth(reqData) {
  const successAction = { type: actionTypes.DITTO_AUTH_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_AUTH_FAILURE };
  const client = Object.assign({}, _client);
  client.defaults.headers = {};
  const { dittoId } = reqData;
  try {
    client.setHeader();
    const data = yield call(async () => client.get(`${config.apiPath.dittoAuth}${dittoId}`));
    const accessCode = data.result.accessCode;
    const signature = data.result.signature;
    successAction.data = data;
    if (__SERVER__) {
      const setCookie = client.getServerResponse();
      setCookie.cookie('dittoAccessID', accessCode, {
        // domain: domain || '.lenskart.com',
        path: '/',
        maxAge: 2700000,
      });
      setCookie.cookie('dittoSignature', signature, {
        // domain: domain || '.lenskart.com',
        path: '/',
        maxAge: 2700000,
      });
    }
    if (__CLIENT__) {
      getSetCookie.setCookie('dittoAccessID', accessCode, null, 45);
      if (dittoId === 'lenskart') {
        window.sessionStorage.setItem('createDittoSignature', signature);
      } else {
        getSetCookie.setCookie('dittoSignature', signature, null, 45);
      }
    }
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* dittoProfileAuth(reqData) {
  const successProfileAction = { type: actionTypes.DITTO_AUTH_PROFILE };
  const errorAction = { type: actionTypes.DITTO_AUTH_FAILURE };
  const client = Object.assign({}, _client);
  client.defaults.headers = {};
  const { dittoIdList } = reqData;
  try {
    if (Array.isArray(dittoIdList)) {
      const signaturePromise = dittoIdList.map(ditto =>
        client.get(`${config.apiPath.dittoAuth}${ditto.id}`, {
          headers: {
            'X-Api-Client': xApiClient,
            'Cache-Control': 'no-cache, no-store',
            'X-Session-Token': getSetCookie.getCookie('clientV1'),
          },
        })
      );
      const getSignatures = yield Promise.all(signaturePromise.map(p => p.catch(() => undefined)));
      const promises = dittoIdList.map((ditto, index) =>
        client.get(`${config.ditto.vtoUrl}${ditto.id}/frontal-frame/?size=300x240`, {
          headers: {
            'X-Ditto-Access-Key-Id': getSignatures[index].result.accessCode,
            'X-Ditto-Signature': getSignatures[index].result.signature,
          },
          responseType: 'blob',
        })
      );
      let getImagesCall = yield Promise.all(promises.map(p => p.catch(() => undefined)));
      getImagesCall.forEach((imageId, index) => {
        if (!imageId) dittoIdList.splice(index, 1);
      });
      getImagesCall = getImagesCall.filter(Boolean);
      const getImages = getImagesCall.map(blob => URL.createObjectURL(blob));
      successProfileAction.data = getImages;
      yield put(successProfileAction);
    }
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getRedisData(path) {
  let redisData = null;
  let redisKey = null;
  let redisKeyArr = [];
  const requestUrl = __SERVER__ ? config.apiPath.albRedis : config.apiPath.redis;
  const options = {
    headers: {
      'Content-Type': 'text/html',
      'X-Client-Org': 'owndays',
    },
  };
  redisData = yield call(async () => _client.get(requestUrl + path, options));
  console.log('Function getRedisData ===> debugging', requestUrl + path, options);
  redisKey = redisData.result[path?.indexOf(',') ? path.split(',')[0] : path];
  redisKeyArr = redisKey ? redisKey.split(':') : [];
  console.log('redisData', redisData);
  return { redisData, redisKey, redisKeyArr };
}

function* getCmsPageData(cmsPageUrl) {
  const pdpcmsSuccessAction = { type: actionTypes.PDP_CMS_POPUP_SUCCESS };
  const pdpcmsErrorAction = { type: actionTypes.PDP_CMS_POPUP_FAIL };
  const client = { ..._client };
  try {
    let cmsData = null;
    let cmsId = null;
    const cmsRedisKeyArr = yield call(getRedisData, cmsPageUrl.cmsPath);
    cmsId = cmsRedisKeyArr.redisKeyArr[1];
    cmsData = yield call(async () => client.getCmsData(cmsId));
    pdpcmsSuccessAction.data = cmsData;
    yield put(pdpcmsSuccessAction);
  } catch (er) {
    pdpcmsErrorAction.error = er;
    yield put(pdpcmsErrorAction);
  }
}

function* getRedisRouter({
  path,
  params,
  productId,
  categoryId,
  location,
  pageCounter = 0,
  isBot = false,
}) {
  const successAction = { type: actionTypes.GET_REDIS_ROUTER_SUCCESS };
  const errorAction = { type: actionTypes.GET_REDIS_ROUTER_FAIL };
  const categorySuccessAction = { type: categoryActionTypes.CATEGORY_DATA_SUCCESS };
  const categoryErrorAction = { type: categoryActionTypes.CATEGORY_DATA_FAIL };
  const saveCategoryToReduxAction = { type: categoryActionTypes.SAVE_CATEGORYDATA_TO_REDUX };
  const productSuccessAction = { type: productActionTypes.PRODUCT_DATA_SUCCESS };
  const cmsSuccessAction = { type: cmsActionTypes.CMS_DATA_SUCCESS };
  const colSuccessAction = { type: COLLECTION_PAGE_DATA_SUCCESS };
  let categoryPageSizeRedisCount = 0;
  const client = { ..._client };
  client.setHeader();
  try {
    let redisData = null;
    let redisKey = null;
    let redisKeyArr = [];
    let categoryData = null;
    let productData = null;
    let section = 'na';
    let cmsData = null;
    let cmsId = null;
    let colData = null;
    const reduxState = yield select(commonState);
    // Fetch category page size count if not there in redis common object
    const pathWithCategoryPageCount = path + ',CATEGORY_PAGE_SIZE_COUNT';
    if (reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT === undefined)
      path = pathWithCategoryPageCount;
    if (location && location.pathname !== '/search') {
      if (categoryId) {
        section = 'cat';
      } else if (productId) {
        section = 'prod';
      } else {
        console.log('debugging redis ==>', path.replace('sg/en', ''));
        const redisInfo = yield call(getRedisData, path.replace('sg/en/', '')) || {};
        redisData = redisInfo.redisData;
        redisKey = redisInfo.redisKey;
        redisKeyArr = redisInfo.redisKeyArr;
        section = redisKeyArr[0] || 'na';
        // Just in case we forget to set redis key
        categoryPageSizeRedisCount =
          typeof redisData.result[path.split(',')[1]] === 'undefined'
            ? config.categoryPageSize
            : redisData.result[path.split(',')[1]];

        if (Object.keys(redisData.result).length > 0) {
          if (redisData.result.hasOwnProperty('redirectUrl')) {
            yield put(push(redisData.result.redirectUrl));
          }
        }
        categoryId = redisKeyArr[1];
        productId = redisKeyArr[1];
      }
    }
    let isExchange = false;
    let orderId;
    let itemId;
    // ditto auth - get access id and signature
    if (__SERVER__) {
      const getCookies = client.getServerRequestHeader().cookies;
      if (getCookies.isDittoID && !getCookies.dittoSignature) {
        const dittoId = getCookies.isDittoID;
        yield call(dittoAuth, { dittoId });
      }
      if (getCookies.exchangeMethod) {
        isExchange = getCookies.exchangeMethod;
        orderId = getCookies.exchangeOrderId;
        itemId = getCookies.exchangeItemId;
      }
    }
    if (__CLIENT__) {
      if (getSetCookie.getCookie('isDittoID') && !getSetCookie.getCookie('dittoSignature')) {
        const dittoId = getSetCookie.getCookie('isDittoID');
        yield call(dittoAuth, { dittoId });
      }
      let isExchangeSessionValid;
      if (xApiClient === 'mobilesite') {
        isExchangeSessionValid =
          sessionStorageHelper.getItem('returnAction') ||
          sessionStorageHelper.getItem('returnProduct')?.returnAction;
      } else {
        isExchangeSessionValid =
          sessionStorageHelper.getItem('exchangeNP') &&
          (sessionStorageHelper.getItem('returnAction') ||
            sessionStorageHelper.getItem('returnProduct')?.returnAction);
      }
      if (getSetCookie.getCookie('exchangeMethod') && isExchangeSessionValid) {
        isExchange = getSetCookie.getCookie('exchangeMethod');
        orderId = getSetCookie.getCookie('exchangeOrderId');
        itemId = getSetCookie.getCookie('exchangeItemId');
      } else {
        isExchange = false;
      }
    }
    if (isExchange && ['cat', 'prod', 'col'].includes(section)) {
      yield put({ type: SET_IS_EXCHANGE, flag: true });
    }
    if (section === 'cat') {
      productId = null;
      cmsId = null;
      const reduxState = yield select(commonState);
      const listPageSize =
        xApiClient === 'mobilesite'
          ? reduxState.redisCommonData.LISTING_PAGE_SIZE
          : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
      categoryPageSizeRedisCount =
        listPageSize === undefined ? config.categoryPageSize : listPageSize;
      if (__CLIENT__) sessionStorage.removeItem('isReOrderProduct');
      // In case redis category page count set is not divisible by 3
      // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
      // ends
      if (isExchange) {
        const exchangeParams = orderId && itemId ? `orderId=${orderId}&itemId=${itemId}` : '';
        params = (params && `${params}&${exchangeParams || ''}`) || exchangeParams || '';
      }
      categoryData = yield call(async () =>
        _client.getCategoryData(
          categoryId,
          params,
          categoryPageSizeRedisCount,
          pageCounter,
          isExchange
        )
      );
      console.log('🚀 ~ file: common.js:340 ~ categoryData:', categoryData);
      categorySuccessAction.data = categoryData;
      categorySuccessAction.data.result.categoryId = categoryId;
      categorySuccessAction.data.location = _location;
      categorySuccessAction.data.counter = pageCounter;
      categorySuccessAction.data.params = params;
      saveCategoryToReduxAction.data = categoryData;
      saveCategoryToReduxAction.data.result.categoryId = categoryId;
      saveCategoryToReduxAction.data.location = _location;
      yield put(categorySuccessAction);
      yield put(saveCategoryToReduxAction);
    }
    if (section === 'prod') {
      categoryId = null;
      cmsId = null;
      if (isExchange) {
        const exchangeParams = orderId && itemId ? `orderId=${orderId}&itemId=${itemId}` : '';
        params = (params && `${params}&${exchangeParams || ''}`) || exchangeParams || '';
      }
      productData = yield call(async () => client.getProductData(productId, params, isExchange));
      const data = productData;
      const modifiedProductData = modifyProductData(data.result, isExchange);
      const staticDTM = setProductStaticDTM(data.result, modifiedProductData, isExchange);
      productSuccessAction.data = data;
      productSuccessAction.modifiedProductData = modifiedProductData;
      productSuccessAction.staticDTM = staticDTM;
      productSuccessAction.data = productData;
      yield put(productSuccessAction);
    }
    if (section === 'cms') {
      categoryId = null;
      productId = null;
      if (__CLIENT__) sessionStorage.removeItem('isReOrderProduct');
      cmsData = yield call(async () => client.getCmsData(cmsId));
      cmsSuccessAction.data = cmsData;
      cmsSuccessAction.isCollectionData = false;
      yield put(cmsSuccessAction);
    }
    if (section === 'col') {
      const parts = redisKeyArr[1].split('/');
      let jsonFile = parts[parts.length - 1];
      jsonFile = jsonFile.split('.');
      const url = `${config.jsonFireBasePath}/${jsonFile[0]}`;
      colData = yield call(async () => client.get(url));
      cmsSuccessAction.data = colData;
      colSuccessAction.data = colData;
      cmsSuccessAction.isBot = isBot;
      colSuccessAction.isBot = isBot;
      colSuccessAction.url = url;
      if (__CLIENT__) sessionStorage.removeItem('isReOrderProduct');
      cmsSuccessAction.isCollectionData = true;
      yield put(cmsSuccessAction);
      yield put(colSuccessAction);
    }
    if (location.pathname === '/search') {
      productId = null;
      categoryId = null;
      cmsId = null;
      let query = '';
      if (__CLIENT__) sessionStorage.removeItem('isReOrderProduct');
      const reduxState = yield select(commonState);
      const listPageSize =
        xApiClient === 'mobilesite'
          ? reduxState.redisCommonData.LISTING_PAGE_SIZE
          : reduxState.redisCommonData.CATEGORY_PAGE_SIZE_COUNT;
      categoryPageSizeRedisCount =
        listPageSize === undefined ? config.categoryPageSize : listPageSize;
      // In case redis category page count set is not divisible by 3
      // categoryPageSizeRedisCount = validateCategoryListingPageSize(categoryPageSizeRedisCount);
      // ends
      if (__SERVER__) {
        query =
          _location?.indexOf('&') > -1
            ? _location.slice(_location?.indexOf('=') + 1, _location?.indexOf('&'))
            : _location.slice(_location?.indexOf('=') + 1);
      } else {
        query =
          window.location.search?.indexOf('&') > -1
            ? window.location.search.slice(
                window.location.search?.indexOf('=') + 1,
                window.location.search?.indexOf('&')
              )
            : window.location.search.slice(window.location.search?.indexOf('=') + 1);
      }
      query = query.replace(/[\])}?[{(]/g, '');
      if (!(location.pathname?.indexOf('/search') > -1)) {
        categoryData = yield call(
          getResource,
          `${config.apiPath.categorySearch}${query}?page-size=${categoryPageSizeRedisCount}&page=${pageCounter}`,
          query,
          location.pathname,
          categorySuccessAction,
          categoryErrorAction,
          pageCounter
        );
      }
      section = xApiClient === 'mobilesite' ? 'search' : 'cat';
    }
    const redisDataObj = { section, categoryId, productId, redisKey };
    successAction.data = redisDataObj;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* getPresaleInfo() {
  const successAction = { type: GET_PRESALE_INFO_SUCCESS };
  const errorAction = { type: GET_PRESALE_INFO_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () => client.get(config.apiPath.userSession));
    successAction.result = result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* validateSession(successAction, errorAction, path) {
  try {
    const data = yield call(async () => _client.validateSession());
    const localeInfo = yield select(getLocaleInfo);
    data.result.countryCode = localeInfo.countryCode;
    successAction.data = data;
    yield put(successAction);
    if (data.result.login) {
      if (path) yield put({ type: CHECKOUT_STEP_KEY, key: 2 });
      // Set DataLayer params
      yield put({ type: SET_DATALAYER_EMAIL_MOBILE, data });
      yield put({ type: SET_DTM_USERINFO, data });
    }
    if (path === '/checkout/onepage/presales' || path === '/checkout/onepage/presales/') {
      yield call(getPresaleInfo);
    }
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* validateSessionV2() {
  const successAction = { type: actionTypes.GET_USER_SUCCESS };
  const errorAction = { type: actionTypes.GET_USER_FAIL };
  try {
    const data = yield call(async () => _client.validateSession());
    const localeInfo = yield select(getLocaleInfo);
    data.result.countryCode = localeInfo.countryCode;
    successAction.data = data;
    yield put(successAction);
    if (data.result.login) {
      // Set DataLayer params
      yield put({ type: SET_DATALAYER_EMAIL_MOBILE, data });
      yield put({ type: SET_DTM_USERINFO, data });
    }
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getRedisCommonData() {
  const action = yield take(actionTypes.GET_REDIS_DATA);
  // OD_NOTE: Checking JSONPath for redisCommonData
  console.log('@Common.js/sagas ===> redisConsole', config?.apiPath?.redisConsole);
  yield call(
    getResource,
    config.apiPath.redisConsole,
    '',
    '',
    { type: actionTypes.GET_REDIS_SUCCESS },
    { type: actionTypes.GET_REDIS_FAIL },
    0,
    { isBot: action.isBot }
  );
}

function* getLensMappingData() {
  const successAction = { type: actionTypes.GET_LENS_MAPPING_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_LENS_MAPPING_DATA_FAIL };
  const client = { ..._client };
  try {
    const data = yield call(async () => client.get(config.apiPath.lensMappingConfigPath));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getUserData() {
  const { path } = yield take(actionTypes.GET_USER);
  yield fork(
    validateSession,
    { type: actionTypes.GET_USER_SUCCESS },
    { type: actionTypes.GET_USER_FAIL },
    path
  );
}

function* setJunoSession() {
  const successAction = { type: actionTypes.LOAD_JUNO_SESSION_API_SUCCESS };
  const errorAction = { type: actionTypes.LOAD_JUNO_SESSION_API_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.customPost(config.apiPath.userSession, null, null, xApiClient, null)
    );
    if (__CLIENT__) {
      getSetCookie.deleteCookie('clientV1');
      getSetCookie.deleteCookie('frontend');
      // getSetCookie.setCookie('clientV1', data.result.id, 30);
    }
    successAction.data = data;
    yield put(successAction);
    yield put({ type: RESET_DATALAYER_EMAIL_MOBILE });
    yield put({ type: RESET_DTM_USERINFO });
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* loadShortList(reqData) {
  const successAction = { type: actionTypes.SHORTLIST_SUCCESS };
  const errorAction = { type: actionTypes.SHORTLIST_FAIL };
  const { pid, method, query } = reqData;
  try {
    const data = yield call(async () => wishlist(pid, method, query));
    successAction.data = data;
    yield put(successAction);
    yield put({ type: shortlistActionTypes.SHORTLIST_PRODUCTS_SUCCESS });
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* deleteShortlist(reqData) {
  const successAction = { type: actionTypes.SHORTLIST_DELETE_SUCCESS };
  const { pid, method, query } = reqData;
  try {
    const data = yield call(async () => wishlist(pid, method, query));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    // console.log('delete shorlist error: ', er);
  }
}

function* addShortlist(reqData) {
  const successAction = { type: actionTypes.SHORTLIST_ADD_SUCCESS };
  const { pid, method, query } = reqData;
  try {
    const data = yield call(async () => wishlist(pid, method, query));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    // console.log('add shorlist error: ', er);
  }
}

function* getUserInfo() {
  const successAction = { type: actionTypes.GET_USER_SUCCESS };
  const errorAction = { type: actionTypes.GET_USER_FAIL };
  const requestUrl = __SERVER__ ? config.apiPath.albGetUserInfo : config.apiPath.getUserInfo;
  const client = Object.assign({}, _client);
  client.setHeader();
  try {
    const data = yield call(async () => client.get(requestUrl));
    const dittoId = data.result.dittos && data.result.dittos[0];
    if (dittoId) {
      yield call(dittoAuth, { dittoId });
    }
    const localeInfo = yield select(getLocaleInfo);
    data.result.countryCode = localeInfo.countryCode;
    successAction.data = data;
    successAction.data.result.login = true;
    if (__CLIENT__) getSetCookie.setCookie('hasPlacedOrder', data.result.hasPlacedOrder, 30);
    yield put(successAction);
    yield put({ type: SET_DATALAYER_EMAIL_MOBILE, data });
    yield put({ type: SET_DTM_USERINFO, data });
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getDittoProfiles() {
  const successAction = { type: actionTypes.DITTO_PROFILE_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_PROFILE_FAILURE };
  const client = Object.assign({}, _client);
  client.setHeader();
  try {
    const data = yield call(async () => client.get(config.apiPath.dittoProfile));
    const dittoIdList = data.result.dittoId;
    const dittoId = data.result.defaultDittoId;
    yield call(dittoProfileAuth, { dittoIdList });
    yield call(dittoAuth, { dittoId });
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* saveDittoName({ dittoNameObj }) {
  const successAction = { type: actionTypes.DITTO_NAME_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_NAME_FAILURE };
  const client = Object.assign({}, { ..._client });
  client.setHeader();
  try {
    const data = yield call(async () => client.post(config.apiPath.dittoProfile, dittoNameObj));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* setDitto(reqData) {
  const successAction = { type: actionTypes.DITTO_DEFAULT_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_DEFAULT_FAILURE };
  const client = Object.assign({}, _client);
  client.setHeader();
  try {
    const { dittoId } = reqData;
    yield call(dittoAuth, { dittoId });
    const data = yield call(async () => client.put(config.apiPath.dittoProfile + dittoId));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* deleteDitto(reqData) {
  const successAction = { type: actionTypes.DITTO_DELETE_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_DELETE_FAILURE };
  const client = Object.assign({}, _client);
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.delete(config.apiPath.dittoProfile + reqData.dittoId)
    );
    const dittoIdList = data.result.dittoId;
    const dittoId = data.result.defaultDittoId;
    yield call(dittoProfileAuth, { dittoIdList });
    yield call(dittoAuth, { dittoId });
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getCountryData({ code }) {
  const successAction = { type: actionTypes.COUNTRY_DATA_SUCCESS };
  const errorAction = { type: actionTypes.COUNTRY_DATA_FAIL };
  const client = Object.assign({}, _client);
  try {
    const response = yield call(async () => client.get(config.apiPath.countryCode + code));
    successAction.data = response.result.data;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error.data;
    yield put(errorAction);
  }
}
function* getAudienceType() {
  if (getSetCookie.getCookie('userip') && !getSetCookie.getCookie('audienceType')) {
    const client = Object.assign({}, _client);
    const ip = getSetCookie.getCookie('userip');
    client.setHeader();
    // let domain = '.lenskart.com';
    // if (window?.location?.hostname) domain = window.location.hostname;
    try {
      const response = yield call(async () => client.get(config.apiPath.userLocation + ip));
      getSetCookie.setCookie('audienceType', response.result.audienceType, {
        path: '/',
        // domain: domain || '.lenskart.com',
      });
    } catch (error) {
      getSetCookie.setCookie('audienceType', 'default', { path: '/' });
    }
  }
}

function* getCustomerInfo({ customerId }) {
  const successAction = { type: actionTypes.GET_CUSTOMER_INFO_SUCCESS };
  const errorAction = { type: actionTypes.GET_CUSTOMER_INFO_FAIL };
  const client = { ..._client };
  client.setHeader({ 'X-Auth-Token': '8e8b0816-4c73-4f08-8f7d-022dcd186a91' });
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.getCustomerInfo}${customerId}`)
    );
    successAction.data = response;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error.data;
    yield put(errorAction);
  }
}

function* passUtmData(utmDataObj) {
  const successAction = { type: actionTypes.PASS_UTM_DATA_SUCCESS };
  const errorAction = { type: actionTypes.PASS_UTM_DATA_FAIL };
  const client = Object.assign({}, { ..._client });
  client.setHeader();
  try {
    const data = yield call(async () => client.post(config.apiPath.passUtmData, utmDataObj?.data));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* saveLeadGenDataSaga(dataObj) {
  const successAction = { type: actionTypes.LEAD_GEN_DATA_SUCCESS };
  const errorAction = { type: actionTypes.LEAD_GEN_DATA_FAIL };
  const client = Object.assign({}, { ..._client });
  client.setHeader();
  try {
    const data = yield call(async () => client.post(config.apiPath.leadGen, dataObj?.data));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* saveMarketingSubscriptionSaga(dataObj) {
  const successAction = { type: actionTypes.MARKETING_SUBSCRIPTION_SUCCESS };
  const errorAction = { type: actionTypes.MARKETING_SUBSCRIPTION_FAIL };
  const client = Object.assign({}, { ..._client });
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(config.apiPath.marketingSubscription, dataObj?.data)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getMarketingSubscriptionDataSaga() {
  const successAction = { type: actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const options = {};
    const response = yield call(async () =>
      client.get(config.apiPath.marketingSubscription, options)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error.data;
    yield put(errorAction);
  }
}

function* getPidFromBarcode(dataObj) {
  const successAction = { type: actionTypes.FETCH_PID_DATA_FROM_BARCODE_SUCCESS };
  const errorAction = { type: actionTypes.FETCH_PID_DATA_FROM_BARCODE_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const { data } = dataObj;
    const response = yield call(async () =>
      client.get(`${config.apiPath.pidFromBarcode}?barCodeId=${data?.barcodeId}`)
    );
    successAction.data = response.result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error.error;
    yield put(errorAction);
  }
}

export function* runCommonCalls(client, location) {
  _client = client ? Object.assign({}, client) : apiClient();
  _location = location;
  yield all([
    takeLatest(actionTypes.GET_REDIS_ROUTER_LOAD, getRedisRouter),
    fork(getRedisCommonData),
    // fork(getSharktankCommonData),
    // takeLatest(actionTypes.GET_SHARKTANK_DATA, getSharktankCommonData),
    fork(getUserData),
    takeLatest(actionTypes.GET_LENS_MAPPING_DATA, getLensMappingData), // for singapore cart!
    takeLatest(actionTypes.LOAD_JUNO_SESSION, setJunoSession),
    takeLatest(actionTypes.SHORTLIST_LOAD, loadShortList),
    takeLatest(actionTypes.SHORTLIST_DELETE, deleteShortlist),
    takeLatest(actionTypes.GET_USER_LOAD, getUserInfo),
    takeLatest(actionTypes.SHORTLIST_ADD, addShortlist),
    takeLatest(actionTypes.DITTO_PROFILE_LOAD, getDittoProfiles),
    takeLatest(actionTypes.DITTO_NAME_SAVE, saveDittoName),
    takeLatest(actionTypes.DITTO_DEFAULT_SET, setDitto),
    takeLatest(actionTypes.DITTO_DELETE, deleteDitto),
    takeLatest(actionTypes.DITTO_AUTH_LOAD, dittoAuth),
    takeLatest(actionTypes.VALIDATE_SESSION, validateSessionV2),
    takeLatest(actionTypes.COUNTRY_DATA_LOAD, getCountryData),
    takeLatest(actionTypes.PDP_CMS_POPUP_LOAD, getCmsPageData),
    takeLatest(actionTypes.AUDIENCE_TYPE_LOAD, getAudienceType),
    takeLatest(actionTypes.GET_CUSTOMER_INFO_LOAD, getCustomerInfo),
    takeLatest(actionTypes.PASS_UTM_DATA_LOAD, passUtmData),
    takeLatest(actionTypes.LEAD_GEN_DATA_LOAD, saveLeadGenDataSaga),
    takeLatest(actionTypes.MARKETING_SUBSCRIPTION_LOAD, saveMarketingSubscriptionSaga),
    takeLatest(actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_LOAD, getMarketingSubscriptionDataSaga),
    takeLatest(actionTypes.FETCH_PID_DATA_FROM_BARCODE_LOAD, getPidFromBarcode),
  ]);
}
