import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/collectionPage';
import apiClient from '../helpers/apiClient';

let _client = null;

function* getCollectionPageData({ url }) {
  const successAction = { type: actionTypes.COLLECTION_PAGE_DATA_SUCCESS };
  const errorAction = { type: actionTypes.COLLECTION_PAGE_DATA_FAIL };
  try {
    const data = yield call(async () => _client.get(url));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runCollectionPageCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.COLLECTION_PAGE_DATA_LOAD, getCollectionPageData)]);
}
