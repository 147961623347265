const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-sunglasses-2024';

export const CONCEPT_LIST = [
  { alt: 'SUN2104B-4S', src: `${baseImgUrl}/concept-slide-1.webp` },
  { alt: 'SUN2104B-4S', src: `${baseImgUrl}/concept-slide-2.webp` },
  { alt: 'SUN1073B-4S', src: `${baseImgUrl}/concept-slide-3.webp` },
  { alt: 'SUN1074B-4S', src: `${baseImgUrl}/concept-slide-4.webp` },
  { alt: 'SUN2100T-2S', src: `${baseImgUrl}/concept-slide-5.webp` },
  { alt: 'SUN2103B-4S', src: `${baseImgUrl}/concept-slide-6.webp` },
];

export const ABOUT_LIST = [
  {
    alt: 'Block over 99% UV',
    pcSrc: `${baseImgUrl}/about-1-pc.webp`,
    spSrc: `${baseImgUrl}/about-1-sp.webp`,
    text: 'Block over 99% UV',
  },
  {
    alt: 'Quality assurance',
    pcSrc: `${baseImgUrl}/about-2-pc.webp`,
    spSrc: `${baseImgUrl}/about-2-sp.webp`,
    text: 'Quality assurance',
  },
  {
    alt: 'Customisable with prescription lenses',
    pcSrc: `${baseImgUrl}/about-3-pc.webp`,
    spSrc: `${baseImgUrl}/about-3-sp.webp`,
    text: 'Customisable with prescription lenses',
  },
];

export const LINEUP_MENU = [
  {
    id: 'essential',
    title: 'Essential',
    imgSrc: `${baseImgUrl}/lineup-nav-1.webp`,
    alt: 'essential',
  },
  { id: 'modern', title: 'Modern', imgSrc: `${baseImgUrl}/lineup-nav-2.webp`, alt: 'Modern' },
  { id: 'classic', title: 'Classic', imgSrc: `${baseImgUrl}/lineup-nav-3.webp`, alt: 'Classic' },
];

export const SKU_SUN2107N_4S = [
  {
    alt: 'SUN2107N-4S C1',
    src: `${baseImgUrl}/SUN2107N-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2107N-4S C2',
    src: `${baseImgUrl}/SUN2107N-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2107N-4S C3',
    src: `${baseImgUrl}/SUN2107N-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2107N-4S C4',
    src: `${baseImgUrl}/SUN2107N-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN2104B_4S = [
  {
    alt: 'SUN2104B-4S C1',
    src: `${baseImgUrl}/SUN2104B-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2104B-4S C2',
    src: `${baseImgUrl}/SUN2104B-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2104B-4S C3',
    src: `${baseImgUrl}/SUN2104B-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2104B-4S C4',
    src: `${baseImgUrl}/SUN2104B-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN2093T_2S = [
  {
    alt: 'SUN2093T-2S C1',
    src: `${baseImgUrl}/SUN2093T-2S-C1.webp`,
    link: '/sg/en/owndays-sun2093t-2s-c1-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN2093T-2S C2',
    src: `${baseImgUrl}/SUN2093T-2S-C2.webp`,
    link: '/sg/en/owndays-sun2093t-2s-c2-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN2093T-2S C3',
    src: `${baseImgUrl}/SUN2093T-2S-C3.webp`,
    link: '/sg/en/owndays-sun2093t-2s-c3-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
];

export const SKU_SUN8007J_2S = [
  {
    alt: 'SUN8007J-2S C1',
    src: `${baseImgUrl}/SUN8007J-2S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: false,
  },
  {
    alt: 'SUN8007J-2S C2',
    src: `${baseImgUrl}/SUN8007J-2S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: false,
  },
  {
    alt: 'SUN8007J-2S C3',
    src: `${baseImgUrl}/SUN8007J-2S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: false,
  },
  {
    alt: 'SUN8007J-2S C4',
    src: `${baseImgUrl}/SUN8007J-2S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: false,
  },
];

export const SKU_SUN7001B_2S = [
  {
    alt: 'SUN7001B-2S C1',
    src: `${baseImgUrl}/SUN7001B-2S-C1.webp`,
    link: '/sg/en/owndays-sun7001b-2s-c1-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN7001B-2S C2',
    src: `${baseImgUrl}/SUN7001B-2S-C2.webp`,
    link: '/sg/en/owndays-sun7001b-2s-c2-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN7001B-2S C3',
    src: `${baseImgUrl}/SUN7001B-2S-C3.webp`,
    link: '/sg/en/owndays-sun7001b-2s-c3-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN7001B-2S C4',
    src: `${baseImgUrl}/SUN7001B-2S-C4.webp`,
    link: '/sg/en/owndays-sun7001b-2s-c4-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
];

export const SKU_SUN7002B_2S = [
  {
    alt: 'SUN7002B-2S C1',
    src: `${baseImgUrl}/SUN7002B-2S-C1.webp`,
    link: '/sg/en/owndays-sun7002b-2s-c1-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN7002B-2S C2',
    src: `${baseImgUrl}/SUN7002B-2S-C2.webp`,
    link: '/sg/en/owndays-sun7002b-2s-c2-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN7002B-2S C3',
    src: `${baseImgUrl}/SUN7002B-2S-C3.webp`,
    link: '/sg/en/owndays-sun7002b-2s-c3-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN7002B-2S C4',
    src: `${baseImgUrl}/SUN7002B-2S-C4.webp`,
    link: '/sg/en/owndays-sun7002b-2s-c4-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
];

export const SKU_SUN2102B_4S = [
  {
    alt: 'SUN2102B-4S C1',
    src: `${baseImgUrl}/SUN2102B-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2102B-4S C2',
    src: `${baseImgUrl}/SUN2102B-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2102B-4S C3',
    src: `${baseImgUrl}/SUN2102B-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2102B-4S C4',
    src: `${baseImgUrl}/SUN2102B-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN1073B_4S = [
  {
    alt: 'SUN1073B-4S C1',
    src: `${baseImgUrl}/SUN1073B-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1073B-4S C2',
    src: `${baseImgUrl}/SUN1073B-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1073B-4S C3',
    src: `${baseImgUrl}/SUN1073B-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1073B-4S C4',
    src: `${baseImgUrl}/SUN1073B-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN2103B_4S = [
  {
    alt: 'SUN2103B-4S C1',
    src: `${baseImgUrl}/SUN2103B-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2103B-4S C2',
    src: `${baseImgUrl}/SUN2103B-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2103B-4S C3',
    src: `${baseImgUrl}/SUN2103B-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2103B-4S C4',
    src: `${baseImgUrl}/SUN2103B-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN1062T_1S = [
  {
    alt: 'SUN1062T-1S C1',
    src: `${baseImgUrl}/SUN1062T-1S-C1.webp`,
    link: '/sg/en/owndays-sun1062t-1s-c1-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN1062T-1S C2',
    src: `${baseImgUrl}/SUN1062T-1S-C2.webp`,
    link: '/sg/en/owndays-sun1062t-1s-c2-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN1062T-1S C3',
    src: `${baseImgUrl}/SUN1062T-1S-C3.webp`,
    link: '/sg/en/owndays-sun1062t-1s-c3-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN1062T-1S C4',
    src: `${baseImgUrl}/SUN1062T-1S-C4.webp`,
    link: '/sg/en/owndays-sun1062t-1s-c4-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
];

export const SKU_SUN1063T_1S = [
  {
    alt: 'SUN1063T-1S C1',
    src: `${baseImgUrl}/SUN1063T-1S-C1.webp`,
    link: '/sg/en/owndays-sun1063t-1s-c1-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN1063T-1S C2',
    src: `${baseImgUrl}/SUN1063T-1S-C2.webp`,
    link: '/sg/en/owndays-sun1063t-1s-c2-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN1063T-1S C3',
    src: `${baseImgUrl}/SUN1063T-1S-C3.webp`,
    link: '/sg/en/owndays-sun1063t-1s-c3-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN1063T-1S C4',
    src: `${baseImgUrl}/SUN1063T-1S-C4.webp`,
    link: '/sg/en/owndays-sun1063t-1s-c4-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
];

export const SKU_SUN2100T_2S = [
  {
    alt: 'SUN2100T-2S C1',
    src: `${baseImgUrl}/SUN2100T-2S-C1.webp`,
    link: '/sg/en/owndays-sun2100t-2s-c1-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN2100T-2S C2',
    src: `${baseImgUrl}/SUN2100T-2S-C2.webp`,
    link: '/sg/en/owndays-sun2100t-2s-c2-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN2100T-2S C3',
    src: `${baseImgUrl}/SUN2100T-2S-C3.webp`,
    link: '/sg/en/owndays-sun2100t-2s-c3-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
  {
    alt: 'SUN2100T-2S C4',
    src: `${baseImgUrl}/SUN2100T-2S-C4.webp`,
    link: '/sg/en/owndays-sun2100t-2s-c4-sunglasses.html',
    buttonName: 'More Details',
    isNew: false,
  },
];

export const SKU_SUN2106J_4S = [
  {
    alt: 'SUN2106J-4S C1',
    src: `${baseImgUrl}/SUN2106J-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2106J-4S C2',
    src: `${baseImgUrl}/SUN2106J-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2106J-4S C3',
    src: `${baseImgUrl}/SUN2106J-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN2106J-4S C4',
    src: `${baseImgUrl}/SUN2106J-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN1074B_4S = [
  {
    alt: 'SUN1074B-4S C1',
    src: `${baseImgUrl}/SUN1074B-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1074B-4S C2',
    src: `${baseImgUrl}/SUN1074B-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1074B-4S C3',
    src: `${baseImgUrl}/SUN1074B-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1074B-4S C4',
    src: `${baseImgUrl}/SUN1074B-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN1075G_4S = [
  {
    alt: 'SUN1075G-4S C1',
    src: `${baseImgUrl}/SUN1075G-4S-C1.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1075G-4S C2',
    src: `${baseImgUrl}/SUN1075G-4S-C2.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1075G-4S C3',
    src: `${baseImgUrl}/SUN1075G-4S-C3.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
  {
    alt: 'SUN1075G-4S C4',
    src: `${baseImgUrl}/SUN1075G-4S-C4.webp`,
    link: '/stores/sg',
    buttonName: 'AVAILABLE IN-STORE ONLY',
    isNew: true,
  },
];

export const SKU_SUN1068T_2S = [
  {
    alt: 'SUN1068T-2S C1',
    src: `${baseImgUrl}/SUN1068T-2S-C1.webp`,
    link: '/sg/en/owndays-sun1068t-2s-c1-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN1068T-2S C2',
    src: `${baseImgUrl}/SUN1068T-2S-C2.webp`,
    link: '/sg/en/owndays-sun1068t-2s-c2-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN1068T-2S C3',
    src: `${baseImgUrl}/SUN1068T-2S-C3.webp`,
    link: '/sg/en/owndays-sun1068t-2s-c3-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN1068T-2S C4',
    src: `${baseImgUrl}/SUN1068T-2S-C4.webp`,
    link: '/sg/en/owndays-sun1068t-2s-c4-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
];

export const SKU_SUN1069T_2S = [
  {
    alt: 'SUN1069T-2S C1',
    src: `${baseImgUrl}/SUN1069T-2S-C1.webp`,
    link: '/sg/en/owndays-sun1069t-2s-c1-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN1069T-2S C2',
    src: `${baseImgUrl}/SUN1069T-2S-C2.webp`,
    link: '/sg/en/owndays-sun1069t-2s-c2-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN1069T-2S C3',
    src: `${baseImgUrl}/SUN1069T-2S-C3.webp`,
    link: '/sg/en/owndays-sun1069t-2s-c3-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN1069T-2S C4',
    src: `${baseImgUrl}/SUN1069T-2S-C4.webp`,
    link: '/sg/en/owndays-sun1069t-2s-c4-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
];

export const SKU_SUN8009B_3S = [
  {
    alt: 'SUN8009B-3S C1',
    src: `${baseImgUrl}/SUN8009B-3S-C1.webp`,
    link: '/sg/en/owndays-sun8009b-3s-c1-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN8009B-3S C2',
    src: `${baseImgUrl}/SUN8009B-3S-C2.webp`,
    link: '/sg/en/owndays-sun8009b-3s-c2-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN8009B-3S C3',
    src: `${baseImgUrl}/SUN8009B-3S-C3.webp`,
    link: '/sg/en/owndays-sun8009b-3s-c3-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
  {
    alt: 'SUN8009B-3S C4',
    src: `${baseImgUrl}/SUN8009B-3S-C4.webp`,
    link: '/sg/en/owndays-sun8009b-3s-c4-sunglasses.html',
    buttonName: 'More Detail',
    isNew: false,
  },
];
