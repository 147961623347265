import { useEffect } from 'react';

const useModalScrollLock = modalOpen => {
  useEffect(() => {
    const body = document.body;

    if (modalOpen) {
      const scrollPosition = window.scrollY;
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.width = '100%';
      body.style.top = `-${scrollPosition}px`;
    } else {
      const scrollPosition = parseInt(body.style.top || '0', 10);
      body.style.overflow = 'auto';
      body.style.position = 'static';
      body.style.width = 'auto';
      body.style.top = 'auto';
      window.scrollTo(0, Math.abs(scrollPosition));
    }

    return () => {
      body.style.overflow = 'auto';
      body.style.position = 'static';
      body.style.width = 'auto';
      body.style.top = 'auto';
    };
  }, [modalOpen]);
};

export default useModalScrollLock;
