import React from 'react';
import MapComponent from '../CommonComponents/MapComponent/MapComponent';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../CommonComponents/Breadcrumbs/Breadcrumbs';
import './css/InformationDetails.scss';

const ToaPayohStore = () => {
  const crumbs = [
    { path: '/information', label: 'See all the Information' },
    { path: '/information/shops/1043', label: 'OWNDAYS Toa Payoh Store Now Open' },
  ];

  const lat = 1.3339248818754506;
  const long = 103.84921216931656;

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <section className="information-details">
        <div className="information-details__container">
          <div className="information-details__title">
            <p>2024.08.14 ｜ Latest information</p>
            <h1>Thu. 15 Aug. 2024 OWNDAYS Toa Payoh Store Now Open!</h1>
          </div>
          <div className="information-details__cover">
            <img
              alt="OWNDAYS Toa Payoh Store"
              src="https://static.lenskart.com/media/owndays/img/information/1043/main.webp"
            />
          </div>
          <div className="information-details__content">
            <h2>Store Details</h2>
            <dl>
              <dt>Address</dt>
              <dd>190 Lorong 6 Toa Payoh, #01-552, Singapore 310190</dd>

              <dt>Operating Hours</dt>
              <dd>10:30-21:00</dd>
            </dl>
            <h2>Access</h2>
            <div className="information-details__map">
              <MapComponent lat={lat} long={long} />
              <a
                href="https://www.google.com/maps/@1.332981,103.8467231,17z?entry=ttu"
                rel="noreferrer"
                target="_blank"
              >
                View larger map
              </a>
            </div>
            <div className="information-details__btn">
              <a href="https://www.owndays.com/stores/sg">More Details</a>
            </div>
            <ul className="information-details__sns">
              <li>
                <a
                  href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/information/287"
                  rel="noreferrer"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/information/287"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="http://line.me/R/msg/text/?https://www.owndays.com/sg/en/information/287"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <i className="fab fa-line"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="information-details__footer">
            <div className="information-details__back">
              <Link to="/information">
                <svg viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z" />
                </svg>
                Back to list
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ToaPayohStore;
