import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

const COUNTRY = 'sg';
const LANG = 'en';

const MetaTagsManager = ({
  description,
  publisher,
  author,
  robots,
  canonical,
  title,
  additionalMeta = [],
}) => {
  const { domain } = useSelector(state => state.common.localeInfo);

  const [computedCanonicalPath, setComputedCanonicalPath] = useState('');
  const [metaDescription, setMetaDescription] = useState('owndays');
  const [metaPublisher, setMetaPublisher] = useState('owndays');
  const [metaAuthor, setMetaAuthor] = useState('owndays');
  const [metaRobots, setMetaRobots] = useState('INDEX,FOLLOW');
  const [metaTitle, setMetaTitle] = useState('owndays.com');

  useEffect(() => {
    const path = canonical || (typeof window !== 'undefined' ? window.location.pathname : '');
    const locale = domain.includes(`/${COUNTRY}/${LANG}`) ? '' : `/${COUNTRY}/${LANG}`;
    const pathWithoutLocale = path && path.replace(/\/sg\/en/g, '');
    const newCanonicalPath = path ? `${domain}${locale}${pathWithoutLocale}` : '';

    setComputedCanonicalPath(newCanonicalPath);
    setMetaDescription(description || 'owndays');
    setMetaPublisher(publisher || 'owndays');
    setMetaAuthor(author || 'owndays');
    setMetaRobots(robots || 'INDEX,FOLLOW');
    setMetaTitle(title || 'owndays');
  }, [domain, description, publisher, author, robots, canonical, title]);

  const { linkArray, metaArray } = useMemo(() => {
    const linkArray = [
      { rel: 'canonical', href: computedCanonicalPath },
      { rel: 'alternate', href: computedCanonicalPath },
    ];

    const baseMetaArray = [
      { name: 'description', content: metaDescription },
      { name: 'robots', content: metaRobots },
      { name: 'author', content: metaAuthor },
      { name: 'publisher', content: metaPublisher },
    ];

    const filteredMetaArray = baseMetaArray.filter(metaItem => {
      return !additionalMeta.some(additionalMetaItem => {
        return (
          (additionalMetaItem.name && additionalMetaItem.name === metaItem.name) ||
          (additionalMetaItem.property && additionalMetaItem.property === metaItem.property)
        );
      });
    });

    return { linkArray, metaArray: [...filteredMetaArray, ...additionalMeta] };
  }, [
    computedCanonicalPath,
    metaAuthor,
    metaDescription,
    metaPublisher,
    metaRobots,
    additionalMeta,
  ]);

  return <Helmet link={linkArray} meta={metaArray} title={metaTitle} />;
};

export default MetaTagsManager;
