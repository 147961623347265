import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

const settings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ItemDetails = ({ isVisible, selectedBrand, brandIndex, setBrandIndex, setOpenCard }) => {
  const [animationKey, setAnimationKey] = useState(0);
  const [showSlider, setShowSlider] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setAnimationKey(prev => prev + 1);
      setTimeout(() => {
        setShowSlider(true);
      }, 100);
    } else {
      setShowSlider(false);
    }
  }, [selectedBrand, isVisible]);

  const handleClose = () => {
    setIsClosing(true);
    setShowSlider(false);
    setTimeout(() => {
      setBrandIndex(null);
      setOpenCard('');
      setIsClosing(false);
    }, 300);
  };

  return (
    <li 
      key={animationKey}
      className={`brands__details ${isVisible ? 'is-visible' : ''} ${isClosing ? 'is-closing' : ''}`}
    >
      <button
        className="brands__details-close"
        onClick={handleClose}
      >
        <img src="https://static.lenskart.com/media/owndays/img/icons/close.svg" alt="Close" />
      </button>
      {selectedBrand.gallery && (
        <div className={`brands__details-slider ${showSlider ? 'is-visible' : ''}`}>
          <Slider {...settings}>
            {selectedBrand.gallery.map((item, index) => (
              <div key={index}>
                <img src={item} alt={selectedBrand.alt} loading="lazy" />
              </div>
            ))}
          </Slider>
        </div>
      )}
      <div className="brands__details-content">
        <h2 className="brands__details-logo">
          {selectedBrand.title}
          <img src={selectedBrand.logo} alt={selectedBrand.title} />
        </h2>
        <p className="brands__details-recommended">
          Recommended for : {selectedBrand.recommended.join('・')}
        </p>
        <div className="brands__details-bottom">
          {(selectedBrand.productList || selectedBrand.productDetails) && (
            <div className="brands__details-actions">
              {selectedBrand.productList && (
                <a href={selectedBrand.productList} className="button button--secondary">
                  See All Products
                </a>
              )}
              {selectedBrand.productDetails && (
                <a href={selectedBrand.productDetails} className="button button--secondary">
                  More Details
                </a>
              )}
            </div>
          )}
          {selectedBrand.description && (
            <p className="brands__details-description">{selectedBrand.description}</p>
          )}
          {selectedBrand.brandTitle1 && selectedBrand.brandTitle2 && (
            <p className="brands__details-title">
              {selectedBrand.brandTitle1}
              <br />
              {selectedBrand.brandTitle2}
            </p>
          )}
        </div>
      </div>
    </li>
  );
};

export default ItemDetails;
