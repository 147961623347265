import React from 'react';
import { ARTICLES } from '../Articles/Articles';
import ArticleDetailsBreadcrumb from './ArticleDetailsBreadcrumb';
import ArticleDetailsHeader from './ArticleDetailsHeader';
import ArticleDetailsFooter from './ArticleDetailsFooter';
import './ArticleDetails.scss';

const SG002 = () => {
  const permalink = 'sg-002';
  const article = ARTICLES?.find(article => article?.permalink === permalink);

  return (
    <>
      <ArticleDetailsBreadcrumb article={article} />
      <div className="article-details">
        <ArticleDetailsHeader article={article} />
        <div className="article-details__content">
          <div className="article-details__container">
            <p>{article.description}</p>
            <div className="button-group">
              <a href="/sg/en/services/lens#myokid" className="button button--primary">
                See Myopia Control Lenses
              </a>
            </div>
            <div>
              <h2>What Are The Signs And Symptoms Of Myopia?</h2>
              <p className="p-img-left p-align-center">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-3.webp"
                  alt="What Are The Signs And Symptoms Of Myopia?"
                  loading="lazy"
                />
                If you suspect your child has myopia, consider consulting an eye care professional
                for an accurate diagnosis. Keep a lookout for the following symptoms:
              </p>
              <ul className="article-details__pins">
                <li>A tendency to sit closer to the computer screen or TV to see clearly</li>
                <li>
                  Complaints of eye fatigue or discomfort after prolonged visual tasks, such as
                  using electronic devices or reading
                </li>
                <li>Difficulty seeing distant objects clearly, often leading to squinting</li>
                <li>Headaches caused by eye strain and squinting</li>
              </ul>
            </div>
            <div>
              <h2>How To Manage Myopia Progression</h2>
              <p className="p-img-single">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-5.webp"
                  alt="How To Manage Myopia Progression"
                  loading="lazy"
                />
              </p>
              <p>
                If your child has been diagnosed with myopia, fret not! Several treatment options
                are available to help manage the condition to prevent it from worsening. The ideal
                choice will ultimately depend on each individual’s unique circumstances, such as
                lifestyle and preferences. Therefore, it is beneficial to understand the options
                available to ensure you make an informed decision for your child.
              </p>
              <ol>
                <li>
                  <h3>
                    <span>Option #1</span>Myopia Control Lenses
                  </h3>
                  <p className="p-img-single">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-2.webp"
                      alt="Myopia Control Lenses"
                      loading="lazy"
                    />
                  </p>
                  <p>
                    The most recommended method for controlling myopia progression is with
                    prescription glasses complemented by myopia control lenses. OWNDAYS offers
                    Myopia Control 2, a type of myopia control lens that is designed with special
                    optical properties effective in reducing the rate at which myopia progresses.
                    The lens is developed using the latest technology to reduce peripheral hyperopic
                    defocus which is a primary cause of worsening myopia.
                  </p>
                  <a
                    href="/sg/en/news/free-myopia-control-2-lenses"
                    className="article-details__card"
                  >
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/free-myopia-control-2-lenses.webp"
                      alt="Free upgrade* to Myopia Control 2 lenses for children aged 14 and below"
                      loading="lazy"
                    />
                    <p>
                      Free upgrade* to Myopia Control 2 lenses for children aged 14 and below
                      <br />
                      <small>*Term & conditions apply</small>
                    </p>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                      <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                    </svg>
                  </a>
                </li>
                <li>
                  <h3>
                    <span>Option #2</span>Ortho-K Lenses
                  </h3>
                  <p>
                    As glasses tend to be more fragile and break more easily, they may not be ideal
                    for those with an active lifestyle or involved in contact sports. If your child
                    is in a similar situation, myopia lenses may be more suitable for their needs.
                  </p>
                  <p className="p-img-single">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-4.webp"
                      alt="Ortho-K Lenses"
                      loading="lazy"
                    />
                  </p>
                  <p>
                    At OWNDAYS, we offer Ortho-K lenses that are customised contact lenses. They are
                    worn at night to reshape the cornea during sleep and then removed during the day
                    for spectacle-free vision. They also slow down myopia progression in children.
                    However, they require more care and upkeep to prevent eye infections. Bring your
                    child to an eye care professional to determine the correct type to address their
                    myopia level and other refractive errors.
                  </p>
                  <div className="article-details__intro">
                    <h4>Introduction</h4>
                    <div className="article-details__intro-grid">
                      <img
                        src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/ortho-k-lens.webp"
                        alt="Ortho-K Lens"
                        loading="lazy"
                      />
                      <div>
                        <p>
                          Ortho-K, also known as orthokeratology, is the use of customised contact
                          lenses that temporarily reshape the cornea to improve vision during
                          treatment.
                        </p>
                        <a className="link-arrow-left" href="/sg/en/contacts/orthok-contactlens">
                          More details
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <h3>
                    <span>Option #3</span>Low-Dose Atropine Eye Drops
                  </h3>
                  <p>
                    Atropine eye drops are usually used to dilate the pupil before an eye exam or
                    surgery. However, low doses of these drops can help slow myopia progression in
                    children.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <h2>Eyecare Tips To Prevent Myopia</h2>
              <p>
                At the end of the day, even with the aid of these myopia control options, it is up
                to you and your child to manage the condition to prevent it from worsening. Let us
                share several helpful myopia management techniques to slow its progression.
              </p>
              <ol>
                <li>
                  <h3>
                    <span>Tip #1</span>Take Frequent Eye Breaks
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-6.webp"
                      alt="Take Frequent Eye Breaks"
                      loading="lazy"
                    />
                    Prolonged periods of close-up work can contribute to the progression of myopia
                    in children. So, encourage your child to take regular breaks to prevent eye
                    fatigue. Keep in mind the 20-20-20 rule – every 20 minutes, look at an object at
                    least 20 feet away for at least 20 seconds.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Tip #2</span>Limit Screen Time
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-7.webp"
                      alt="Limit Screen Time"
                      loading="lazy"
                    />
                    Watching YouTube videos on the iPad or playing video games on the computer is
                    near work too! So, limit your child’s screen time whenever possible. Doing so
                    can help ease eye strain and eye fatigue – common symptoms of myopia.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Tip #3</span>Spend More Time Outdoor
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-8.webp"
                      alt="Spend More Time Outdoor"
                      loading="lazy"
                    />
                    <span>
                      <a
                        href="https://www.nature.com/articles/s41598-021-85825-y"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <u>Research</u>
                      </a>{' '}
                      has demonstrated a link between outdoor time and a reduced risk of myopia in
                      children. Researchers believe that sunlight helps regulate eye growth by
                      triggering the release of retinal dopamine, a neurotransmitter inhibiting eye
                      elongation, a primary cause of myopia.
                    </span>
                  </p>
                  <p>
                    Other theories posit that engaging in outdoor activities requires our eyes to
                    focus on objects at varying distances. This constant adjustment of focus may
                    help prevent or slow myopia progression.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>Tip #4</span>Correct Vision Problem Early
                  </h3>
                  <p className="p-img-left">
                    <img
                      src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/main-9.webp"
                      alt="Correct Vision Problem Early"
                      loading="lazy"
                    />
                    As with any condition, seeking early treatment is essential in controlling
                    myopia progression. If you suspect your child has myopia, consult an eye care
                    professional who can assess their eyes and advise which myopia control method
                    best suits them.
                  </p>
                  <p>
                    Even if your child has been diagnosed with myopia, there are effective ways to
                    safeguard their vision with proper myopia control. By staying informed and
                    taking proactive steps, you can ensure their visual health is well taken care
                    of, setting the stage for a bright, clear future.
                  </p>
                </li>
              </ol>
            </div>
            <div>
              <h2>Browse our range of prescription glasses online</h2>
              <p className="p-img-small">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/sg-002/illust01.svg"
                  alt="Browse our range of prescription glasses online"
                  loading="lazy"
                />
              </p>
              <p>
                Enjoy a free upgrade to Myopia Control 2 lenses when you purchase spectacles for
                your child aged 14 and below*. Alternatively, you can head down to our various
                spectacle shops across Singapore, including Bugis and Orchard, for all your vision
                care needs.
                <br />
                <small>*Terms and conditions apply.</small>
              </p>
            </div>
            <div className="article-details__related">
              <h3>OWNDAYS Customer Guarantees</h3>
              <div className="article-details__related-grid">
                <img
                  src="https://static1.lenskart.com/media/owndays/img/articles/warranty03.svg"
                  alt="OWNDAYS Customer Guarantees"
                  loading="lazy"
                />
                <div>
                  <p>
                    Proper care and regular maintenance can extend the lifespan of your spectacles.
                    OWNDAYS offers you the most comprehensive after-sales service in the optical
                    industry so you can enjoy your OWNDAYS spectacles at all times.
                  </p>
                  <a className="link-arrow-right" href="/sg/en/services/warranty">
                    More Details
                  </a>
                </div>
              </div>
            </div>
            <div className="button-group button-group--2">
              <a href="/sg/en/eyewear/eyeglasses.html" className="button button--primary">
                See All Eyeglasses
              </a>
              <a href="/stores/sg" className="button button--primary">
                Search For Nearest OWNDAYS Stores
              </a>
            </div>
          </div>
        </div>
        <ArticleDetailsFooter permalink={permalink} />
      </div>
    </>
  );
};

export default SG002;
