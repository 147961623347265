import React, { Fragment, useState } from 'react';

import Modal from '../Modal/Modal';

import { CONTACT_LENS_DEALS, TERMS } from './constants';
import './FooterOwndays1For1ContactLensDeal.scss';

export const FooterOwndays1For1ContactLensDeal = ({ baseImgUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <section className="base-owndays-1-for-1-contact-lens-deal__container footer-owndays-1-for-1-contact-lens-deal">
        <h2 className="footer-owndays-1-for-1-contact-lens-deal__highlights">Highlights</h2>

        <div className="footer-owndays-1-for-1-contact-lens-deal__card--sm">
          <div className="footer-owndays-1-for-1-contact-lens-deal__card">
            <img
              alt="OWNDAYS Clear Vision contact lenses"
              className="footer-owndays-1-for-1-contact-lens-deal__item__image"
              src={`${baseImgUrl}/main-event.webp`}
            />

            <div className="footer-owndays-1-for-1-contact-lens-deal__item">
              <p className="footer-owndays-1-for-1-contact-lens-deal__item__title">
                Participating Products:
              </p>

              <ul className="footer-owndays-1-for-1-contact-lens-deal__item__list">
                {CONTACT_LENS_DEALS.map((deal, index) => (
                  <li key={index}>
                    <a href={deal.url}>
                      <strong>{deal.name}</strong>
                    </a>{' '}
                    (U.P. {deal.price})
                  </li>
                ))}
              </ul>

              <a
                className="footer-owndays-1-for-1-contact-lens-deal__item__term-and-conditions"
                onClick={() => setIsModalOpen(true)}
              >
                Terms & Conditions
              </a>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="modal__title">Terms & Conditions</h2>
        <ul className="modal__terms">
          {TERMS.map((term, index) => (
            <li key={index}>{term}</li>
          ))}
        </ul>
      </Modal>
    </Fragment>
  );
};
