import * as actionTypes from './../../actionTypes/qrCode';

const initialState = {
  loading: false,
  qrScan: null,
  qrData: null,
};

export default function qrCode(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_QR_SCAN_STATUS_LOAD:
      return Object.assign({}, prevState, { qrScan: null });
    case actionTypes.GET_QR_SCAN_STATUS_SUCCESS:
      return Object.assign({}, prevState, { qrScan: action.data });
    case actionTypes.GET_QR_SCAN_STATUS_FAIL:
      return Object.assign({}, prevState, { qrScan: action.error });
    case actionTypes.GET_QR_CODE_LOAD:
      return Object.assign({}, prevState, { loading: true, qrData: null });
    case actionTypes.GET_QR_CODE_SUCCESS:
      return Object.assign({}, prevState, { loading: false, qrData: action.data });
    case actionTypes.GET_QR_CODE_FAIL:
      return Object.assign({}, prevState, { loading: false, qrData: action.error });
    default:
      return prevState;
  }
}
