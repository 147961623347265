import React from 'react';
import './MembershipFunction.scss';
import { v4 } from 'uuid';

const MembershipFunction = () => {
  const membershipFunctionData = [
    {
      img: {
        src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-1.webp',
        alt: 'digital card',
      },
      title: 'Digital Membership Card',
      description: 'No need to enter details again at the store',
    },
    {
      img: {
        src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-2.webp',
        alt: 'warranty cards',
      },
      title: 'Warranty Cards Order History',
      description: 'Retrieve your purchase records and warranty cards from Facebook',
    },
    {
      img: {
        src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-3.webp',
        alt: 'prescription',
      },
      title: 'Prescription',
      description:
        'Want to know the lens power of your previous pair of OWNDAYS spectacles?Just look it up from Facebook!',
    },
    {
      img: {
        src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-4.webp',
        alt: 'Members-only coupon',
      },
      title: 'Members-only coupon',
      description: 'Receive members-only coupons for special events and campaigns!',
    },
    {
      img: {
        src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-5.webp',
        alt: 'Enjoy the convenience of online shopping',
      },
      title: 'Enjoy the convenience of online shopping',
      description: 'Sign up as member to breeze through your next purchase!',
    },
  ];
  return (
    <div className="od-membership-function-container">
      <div className="od-membership-function">
        {membershipFunctionData &&
          membershipFunctionData.length > 0 &&
          membershipFunctionData.map(el => {
            return (
              <div key={v4()} className="od-membership-function__item">
                <img alt={el.img.alt} src={el.img.src} />
                <div className="od-membership-function__item__title od-font-bold">{el.title}</div>
                <div className="od-membership-function__item__description od-font-reg">
                  {el.description}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MembershipFunction;
