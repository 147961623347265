import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  applyCode,
  applyCouponClicked,
  resetApplyCoupon,
} from '../../../../../actionCreators/cart';
import config from '../../../../../config';
import './OdCouponItems.scss';
import ButtonOwnDays from '../../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { setToastMessage } from '../../../../../actionCreators/common';

const CouponItems = props => {
  const {
    setShowSideBar,
    gvList,
    applyCoupon,
    cartError,
    coupon,
    handleSetCoupon,
    couponError,
    setCouponError,
    applyCouponClicked,
    dataLocale,
    applyCouponData,
  } = props;

  const {
    APPLY_COUPON,
    APPLY_COUPON_BTN,
    // SORRY_NO_COUPONS_AVAILABLE,
    INVALID_COUPON_CODE_MESSAGE,
  } = dataLocale;

  const dispatch = useDispatch();

  const cartDataErrorMessage = cartError && cartError?.message;

  const [couponErrorMessage, setCouponErrorMessage] = useState('');

  const couponValue = useRef();

  useEffect(() => {
    if (cartError && !couponError) {
      setCouponError(true);
      if (cartDataErrorMessage && cartDataErrorMessage !== '') {
        setCouponErrorMessage(cartDataErrorMessage);
      } else {
        setCouponErrorMessage(INVALID_COUPON_CODE_MESSAGE);
      }
    } else {
      setCouponError(false);
    }
  }, [cartError]);

  const handleApplyCoupon = () => {
    const value = couponValue?.current?.value;
    if (value) {
      handleSetCoupon(value.toLowerCase());
      applyCoupon(value.toLowerCase());
      applyCouponClicked(true);
    }
  };

  useEffect(() => {
    if (coupon && applyCouponData && !couponError) {
      setShowSideBar(false);
      dispatch(resetApplyCoupon());
      dispatch(setToastMessage({ message: 'Voucher applied to the Cart', timeout: 3000 }));
    }
  }, [applyCouponData, coupon, couponError, dispatch, setShowSideBar]);

  return (
    <div className="od-coupon-items-wrapper">
      <div className="od-coupon-items">
        <div className="od-coupon-items-header">
          {/* <div className="od-coupon-items-header__icon" onClick={() => setShowSideBar(false)}>
            <img
              alt="back"
              src="https://static1.lenskart.com/media/desktop/img/DesignStudioIcons/LeftArrowBlue.svg"
            />
          </div> */}
          <div className="od-coupon-items-header__title od-font-bold">{APPLY_COUPON}</div>
          <div className="od-coupon-items-header__icon" onClick={() => setShowSideBar(false)}>
            <img
              alt="close"
              src="https://static1.lenskart.com/media/desktop/img/DesignStudioIcons/CrossBlue.svg"
            />{' '}
          </div>
        </div>

        <div>
          <div className="od-coupon-items-add">
            <input
              ref={couponValue}
              className="od-coupon-items-add__input"
              name="coupon"
              placeholder="Enter Coupon Code"
              type="text"
            />
          </div>

          {couponError && <div className="od-coupon-items-add__error">{couponErrorMessage}</div>}
        </div>

        {gvList &&
          gvList.map((item, idx) => (
            <div key={idx} className="od-coupon-tems-item">
              <div className="od-coupon-tems-item__title">{item.heading}</div>
              <div className="hod-coupon-tems-item__description">{item.description}</div>
              <hr className="od-coupon-tems-item__divider"></hr>
              <div className="od-coupon-tems-item__terms-conditions">{item.termsAndConditions}</div>
              <hr className="od-coupon-tems-item__divider"></hr>
              <button className="od-coupon-tems-item__button" onClick={() => handleApplyCoupon()}>
                {APPLY_COUPON_BTN}
              </button>
            </div>
          ))}

        {gvList && gvList.length === 0 && (
          // <div className="od-coupon-items__empty">{SORRY_NO_COUPONS_AVAILABLE}</div>
          <div className="od-coupon-items-multiple">
            <ul className="od-coupon-items-multiple__description">
              <li>Not valid in conjunction with discount coupons issued in retail stores</li>
              <li>Only 1 coupon code can be used per transaction</li>
              <li>
                Discounts and/or promotions cannot be combined. The highest discount will be
                automatically applied at checkout.
              </li>
            </ul>
          </div>
        )}
      </div>
      <ButtonOwnDays
        mode="grey"
        style={{ width: '100%', flexShrink: 0 }}
        onClick={() => handleApplyCoupon()}
      >
        {APPLY_COUPON}
      </ButtonOwnDays>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  applyCoupon: code =>
    dispatch(applyCode(`${config.apiPath.applyGV}${code}?applyWallet=false`, 'post')),
  deleteCoupon: code =>
    dispatch(applyCode(`${config.apiPath.applyGV}${code}?applyWallet=false`, 'delete')),
  applyCouponClicked: value => dispatch(applyCouponClicked(value)),
});

const mapStateToProps = state => {
  const cartData = state.cart.cartData ? state.cart.cartData.result : null;
  return {
    cartData,
    currencyMark: state.common.currencyMark,
    dataLocale: state.locale,
    isCartUpdating: state.cart.isCartLoader,
    applyCouponData: state.cart.applyCouponData,
    login: state.common.login,
    redisCommonData: state.common.redisCommonData,
    localeInfo: state.common.localeInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponItems);
