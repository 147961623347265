import React from 'react';
import './WishlistPageItems.scss';

import WishlistPageItem from '../WishlistPageItem/WishlistPageItem';

import { connect } from 'react-redux';

const WishlistPageItems = props => {
  const { shortListData } = props;

  return (
    <div className="wishlist-page-items">
      <div className="wishlist-page-items__layout">
        {shortListData.result.productList.map(product => (
          <WishlistPageItem key={product.id} productData={product} />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    shortListData: state.common.shortListData,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(WishlistPageItems);
