import * as actionTypes from './../../actionTypes/product';

const initialState = {
  productData: null,
  coloredLensData: null,
  loadingColoredLens: false,
  loadedColoredLens: true,
  loaded: false,
  loading: false,
  clOpenList: '',
  contactLensData: null,
  loadingContactPackage: false,
  isCLSavedPresLoaded: false,
  savedPrescription: null,
  isContactPrescValid: null,
  frontImgUrl: '',
  frontImgCss: '',
  goToPDPId: '',
  catUrl: '',
  scrollTop: false,
  errorExpress: null,
  expressOptions: null,
  successExpress: false,
  oosSaved: false,
  oosError: false,
  selectedPowerType: '',
  productTabIndex: 1,
  enterLocationAddToCart: null,
  loadingPackage: false,
  getLensPackages: { result: { packages: [] } },
  getLensPackagesFailed: false,
  clsubscriptions: { data: null, error: null, loading: true },
  subscriptionDiscounts: { data: null, error: null, loading: true },
  clsolutions: { data: null, error: null, loading: true },
  modifiedProductData: {},
  staticDTM: {},
  pincodeData: null,
  pincodeError: null,
  appOfferDiscount: 0,
  appOfferPrice: 0,
  appCTAText: null,
  inValidPres: false,
  exchangeBalance: 0,
  isNonJitPowerValid: true,
  packageOrCoatingGroup: null,
  threeSixtyViewStatus: null,
  loadingStores: null,
  storeAvailability: [],
  storeAvailabilityError: null,
  isLensesSelectionPopupOpen: false,
};

export default function product(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_LENSES_SELECTION_POPUP:
      return Object.assign({}, prevState, {
        isLensesSelectionPopupOpen: action.isLensesSelectionPopupOpen,
      });
    case actionTypes.CHECK_STORE_AVAILABILITY_LOAD:
      return Object.assign({}, prevState, {
        loadingStores: true,
        storeAvailability: null,
        storeAvailabilityError: null,
      });
    case actionTypes.CHECK_STORE_AVAILABILITY_SUCCESS:
      return Object.assign({}, prevState, {
        loadingStores: false,
        storeAvailability: action.data,
        storeAvailabilityError: null,
      });
    case actionTypes.CHECK_STORE_AVAILABILITY_FAIL:
      return Object.assign({}, prevState, {
        loadingStores: false,
        storeAvailability: null,
        storeAvailabilityError: action.error,
      });

    case actionTypes.PRODUCT_DATA_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        productData: null,
      });
    case actionTypes.PRODUCT_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        productData: action.data,
        modifiedProductData: action.modifiedProductData,
        staticDTM: action.staticDTM,
        successExpress: false,
      });
    case actionTypes.PRODUCT_DATA_FAIL:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        productData: action.error,
        modifiedProductData: {},
        staticDTM: {},
      });
    case actionTypes.SIMILAR_PRODUCTS_LOAD:
      return Object.assign({}, prevState, {
        similarProducts: {
          ...(prevState.similarProducts || {}),
          [action.paramName]: [],
        },
      });
    case actionTypes.SIMILAR_PRODUCTS_SUCCESS:
      return Object.assign({}, prevState, {
        similarProducts: {
          ...(prevState.similarProducts || {}),
          [action.paramName]: action.data,
        },
      });
    case actionTypes.SIMILAR_PRODUCTS_FAIL:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        similarProducts: {
          ...(prevState.similarProducts || {}),
          [action.paramName]: action.error,
        },
      });
    case actionTypes.COLORED_LENS_DATA_LOAD:
      return Object.assign({}, prevState, {
        loadingColoredLens: true,
        loadedColoredLens: false,
        coloredLensData: null,
      });
    case actionTypes.COLORED_LENS_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        loadedColoredLens: true,
        loadingColoredLens: false,
        coloredLensData: action.data,
      });
    case actionTypes.COLORED_LENS_DATA_FAIL:
      return Object.assign({}, prevState, {
        loadedColoredLens: true,
        loadingColoredLens: false,
        coloredLensData: action.error,
      });
    case actionTypes.DITTO_IMG_EMAIL_SUCCESS:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        dittoEmailSuccess: true,
      });

    case actionTypes.DITTO_IMG_EMAIL_FAIL:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        dittoEmailSuccess: false,
      });
    case actionTypes.DITTO_IMG_EMAIL_RESET:
      return Object.assign({}, prevState, {
        loaded: false,
        loading: false,
        dittoEmailSuccess: undefined,
      });
    case actionTypes.CONTACT_LENS_LOAD:
      return Object.assign({}, prevState, {
        clOpenList: '',
        contactLensData: null,
        loadingContactPackage: true,
      });
    case actionTypes.CONTACT_LENS_SUCCESS:
      return Object.assign({}, prevState, {
        loadingContactPackage: false,
        contactLensData: action.data.result,
        isNonJitPowerValid: true,
      });
    case actionTypes.CONTACT_LENS_FAIL:
      return Object.assign({}, prevState, {
        loadingContactPackage: false,
        contactLensData: action.error,
      });
    case actionTypes.CL_SAVED_PRESCRIPTION_LOAD:
      return Object.assign({}, prevState, {
        isCLSavedPresLoaded: false,
        savedPrescription: null,
      });
    case actionTypes.CL_SAVED_PRESCRIPTION_SUCCESS:
      return Object.assign({}, prevState, {
        isContactPrescValid: null,
        isCLSavedPresLoaded: true,
        savedPrescription: action.data,
      });
    case actionTypes.CL_SAVED_PRESCRIPTION_FAIL:
      return Object.assign({}, prevState, {
        isCLSavedPresLoaded: true,
        savedPrescription: null,
        error: action.error,
      });
    case actionTypes.CL_DROPDOWN_OPEN:
      return Object.assign({}, prevState, {
        clOpenList: action.data,
      });
    case actionTypes.CL_PRESCRIPTION_VALID_TEST:
      return Object.assign({}, prevState, {
        loading: true,
        isContactPrescValid: null,
        inValidPres: false,
        isNonJitPowerValid: true,
      });
    case actionTypes.CL_PRESCRIPTION_VALID_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        isContactPrescValid: action.data,
        inValidPres: false,
        isNonJitPowerValid: !prevState.productData.result.jit
          ? action.data.result[0].isSuccess
          : true,
      });
    case actionTypes.CL_PRESCRIPTION_VALID_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        isContactPrescValid: action.error,
        inValidPres: true,
        isNonJitPowerValid: true,
      });
    case actionTypes.FRONT_IMG:
      return Object.assign({}, prevState, {
        frontImgUrl: action.imgUrl,
        frontImgCss: action.imgUrl
          ? {
              backgroundImage: `url(${action.imgUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }
          : {},
      });
    case actionTypes.GOTO_PDP_ID:
      return Object.assign({}, prevState, {
        goToPDPId: action.data,
        catUrl: action.path,
        scrollTop: true,
      });
    case actionTypes.EXPRESS_TIME_SUCCESS:
      return Object.assign({}, prevState, {
        errorExpress: null,
        successExpress: true,
        expressOptions: action.data,
      });
    case actionTypes.EXPRESS_TIME_FAIL:
      return Object.assign({}, prevState, {
        errorExpress: action.error,
        successExpress: false,
      });
    case actionTypes.RESET_PINCODE_FORM:
      return Object.assign({}, prevState, {
        errorExpress: null,
        successExpress: false,
        expressOptions: null,
      });
    case actionTypes.OOS_SUCCESS:
      return Object.assign({}, prevState, {
        oosSaved: true,
        oosError: false,
      });
    case actionTypes.OOS_FAIL:
      return Object.assign({}, prevState, {
        oosSaved: false,
        oosError: true,
      });
    case actionTypes.LENS_LOCATION_AVAILABILITY_SUCCESS:
      return Object.assign({}, prevState, {
        enterLocationAddToCart: action.data,
      });
    case actionTypes.LENS_LOCATION_AVAILABILITY_FAIL:
      return Object.assign({}, prevState, {
        enterLocationAddToCart: action.error,
      });
    case actionTypes.LENS_PACKAGES_LOAD:
      return Object.assign({}, prevState, {
        loadingPackage: true,
        getLensPackages: { result: { packages: [] } },
        getLensPackagesFailed: null,
      });
    case actionTypes.LENS_PACKAGES_SUCCESS:
      return Object.assign({}, prevState, {
        loadingPackage: false,
        getLensPackages: action.data,
        getLensPackagesFailed: null,
      });
    case actionTypes.LENS_PACKAGES_FAIL:
      return Object.assign({}, prevState, {
        loadingPackage: false,
        getLensPackages: { result: { packages: [] } },
        getLensPackagesFailed: action.error,
      });
    case actionTypes.FLUSH_PRODUCT_DATA:
      return Object.assign({}, prevState, {
        productData: null,
        modifiedProductData: {},
        productStaticDTM: {},
      });
    case actionTypes.FLUSH_COLORED_LENS_DATA:
      return Object.assign({}, prevState, {
        coloredLensData: null,
      });
    case actionTypes.CHANGE_PRODUCTTABINDEX:
      return Object.assign({}, prevState, {
        productTabIndex: action.value,
      });

    case actionTypes.RESET_PRODUCT_VALUE:
      return Object.assign({}, prevState, {
        ...action.args,
      });
    case actionTypes.SET_POWER_TYPE:
      return Object.assign({}, prevState, {
        selectedPowerType: action.value,
      });
    case actionTypes.GET_CLSUBSCRIPTION:
      return Object.assign({}, prevState, {
        clsubscriptions: { data: null, error: null, loading: true },
      });
    case actionTypes.GET_CLSUBSCRIPTION_SUCCESS:
      return Object.assign({}, prevState, {
        clsubscriptions: { data: action.data, error: action.error, loading: false },
      });
    case actionTypes.GET_CLSUBSCRIPTION_FAIL:
      return Object.assign({}, prevState, {
        clsubscriptions: { data: null, error: action.error, loading: false },
      });
    case actionTypes.GET_SUBSCRIPTION_DISCOUNT:
      return Object.assign({}, prevState, {
        subscriptionDiscounts: { data: null, error: null, loading: true },
      });
    case actionTypes.GET_SUBSCRIPTION_DISCOUNT_SUCCESS:
      return Object.assign({}, prevState, {
        subscriptionDiscounts: { data: action.data, error: null, loading: false },
      });
    case actionTypes.GET_SUBSCRIPTION_DISCOUNT_FAIL:
      return Object.assign({}, prevState, {
        subscriptionDiscounts: { data: null, error: action.error, loading: false },
      });
    case actionTypes.GET_CLSOLUTIONS:
      return Object.assign({}, prevState, {
        clsolutions: { data: null, error: null, loading: true, isClSolutionsResponseCame: false },
      });
    case actionTypes.GET_CLSOLUTIONS_SUCCESS:
      return Object.assign({}, prevState, {
        clsolutions: {
          data: action.data,
          error: null,
          loading: false,
          isClSolutionsResponseCame: true,
        },
      });
    case actionTypes.GET_CLSOLUTIONS_FAIL:
      return Object.assign({}, prevState, {
        clsolutions: {
          data: null,
          error: action.error,
          loading: false,
          isClSolutionsResponseCame: true,
        },
      });
    case actionTypes.GET_DELIVERY_DETAILS_LOAD:
      return Object.assign({}, prevState, {
        pincodeData: null,
        pincodeError: null,
      });
    case actionTypes.GET_DELIVERY_DETAILS_SUCCESS:
      return Object.assign({}, prevState, {
        pincodeData: action.data,
        pincodeError: null,
      });
    case actionTypes.GET_DELIVERY_DETAILS_FAILED:
      return Object.assign({}, prevState, {
        pincodeData: null,
        pincodeError: action.error,
      });
    case actionTypes.SET_APP_PRICE:
      return Object.assign({}, prevState, {
        appOfferPrice: action.price,
        appOfferDiscount: action.discount,
        appCTAOffer: action.appCTAText,
      });
    case actionTypes.REVIEW_PRODUCT:
      return Object.assign({}, prevState, { postingReview: true, review: null });
    case actionTypes.REVIEW_PRODUCT_SUCCESS:
      return Object.assign({}, prevState, {
        postingReview: false,
        review: { message: action.message, error: null },
      });
    case actionTypes.REVIEW_PRODUCT_FAIL:
      return Object.assign({}, prevState, {
        postingReview: false,
        review: { message: null, error: action.error },
      });
    case actionTypes.SET_EXCHANGE_BALANCE:
      return Object.assign({}, prevState, { exchangeBalance: action.amount });
    case actionTypes.GET_PACKAGE_OR_COATING_GROUP_LOAD:
      return Object.assign({}, prevState, { packageOrCoatingGroup: null });
    case actionTypes.GET_PACKAGE_OR_COATING_GROUP_SUCCESS:
      return Object.assign({}, prevState, { packageOrCoatingGroup: action.data });
    case actionTypes.GET_PACKAGE_OR_COATING_GROUP_FAIL:
      return Object.assign({}, prevState, { packageOrCoatingGroup: null });
    case actionTypes.GET_THREE_SIXTY_VIEW_LOAD:
      return Object.assign({}, prevState, { threeSixtyViewStatus: null });
    case actionTypes.GET_THREE_SIXTY_VIEW_SUCCESS:
      return Object.assign({}, prevState, { threeSixtyViewStatus: action.data });
    case actionTypes.GET_THREE_SIXTY_VIEW_FAIL:
      return Object.assign({}, prevState, { threeSixtyViewStatus: null });
    default:
      return prevState;
  }
}
