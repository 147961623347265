import React from 'react';
import './TermsConditionsPage.scss';
import { useSelector } from 'react-redux';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const TermsConditionsPage = () => {
  const dataLocale = useSelector(state => state.locale);
  const { TERMS_AND_CONDITION_PAGE } = dataLocale;
  const { title, html } = TERMS_AND_CONDITION_PAGE;
  return (
    <main>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              'Discover OWNDAYS Singapore and understand our terms & conditions for a smooth shopping journey. Ensuring transparency and trust in your eyewear purchases.',
          },
          {
            name: 'twitter:description',
            content:
              'Discover OWNDAYS Singapore and understand our terms & conditions for a smooth shopping journey. Ensuring transparency and trust in your eyewear purchases.',
          },
          {
            name: 'og:title',
            content: 'Terms & Conditions | OWNDAYS Singapore',
          },
          {
            name: 'twitter:title',
            content: 'Terms & Conditions | OWNDAYS Singapore',
          },
        ]}
        description="Discover OWNDAYS Singapore and understand our terms & conditions for a smooth shopping journey. Ensuring transparency and trust in your eyewear purchases."
        title="Terms & Conditions | OWNDAYS Singapore"
      />
      <section className="od-terms">
        <div className="od-terms__title od-font-bold">{title}</div>
        <div dangerouslySetInnerHTML={{ __html: html }} className="od-terms__content" />
      </section>
    </main>
  );
};

export default TermsConditionsPage;
