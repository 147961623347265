let countryData = null;

export const setCountryData = data => {
  if (!countryData) {
    countryData = data;
  }
  return countryData;
};

export const phoneCodeToShow = obj => {
  const countryToShow =
    obj.availableCountries && obj.availableCountries.filter(item => item.isEnable === true);
  const newObj = { availableCountries: countryToShow };
  return newObj;
};
