export const SKU_PC2007N2S = [
  {
    alt: 'PC2007N-2S C1',
    src: 'PC2007N-2S_C1_02_r2.webp',
    link: '#PC2007N-2S',
    buttonName: 'PC2007N-2S',
  },
  {
    alt: 'PC2007N-2S C2',
    src: 'PC2007N-2S_C2_02_r2.webp',
    link: '#PC2007N-2S',
    buttonName: 'PC2007N-2S',
  },
  {
    alt: 'PC2007N-2S C3',
    src: 'PC2007N-2S_C3_02_r2.webp',
    link: '#PC2007N-2S',
    buttonName: 'PC2007N-2S',
  },
  {
    alt: 'PC2007N-2S C4',
    src: 'PC2007N-2S_C4_02_r2.webp',
    link: '#PC2007N-2S',
    buttonName: 'PC2007N-2S',
  },
];

export const SKU_PC2008N2S = [
  {
    alt: 'PC2008N-2S C1',
    src: 'PC2008N-2S_C1_02_r2.webp',
    link: '#PC2008N-2S',
    buttonName: 'PC2008N-2S',
  },
  {
    alt: 'PC2008N-2S C2',
    src: 'PC2008N-2S_C2_02_r2.webp',
    link: '#PC2008N-2S',
    buttonName: 'PC2008N-2S',
  },
  {
    alt: 'PC2008N-2S C3',
    src: 'PC2008N-2S_C3_02_r2.webp',
    link: '#PC2008N-2S',
    buttonName: 'PC2008N-2S',
  },
  {
    alt: 'PC2008N-2S C4',
    src: 'PC2008N-2S_C4_02_r2.webp',
    link: '#PC2008N-2S',
    buttonName: 'PC2008N-2S',
  },
];

export const ABOUT_IMAGES = Array(5).fill(['about1.webp', 'about2.webp', 'about3.webp']).flat();
export const POINT_IMAGES = Array(5).fill(['point1.webp', 'point2.webp', 'point3.webp']).flat();

export const POINTS = [
  {
    image: `pointA.webp`,
    title: 'Ultem Frame',
    description:
      'Made of Ultem, a lightweight material that has superb elasticity and is also used for aircraft parts and medical devices',
  },
  {
    image: `pointB.webp`,
    title: 'Adjustable Tips',
    description:
      'Rubber tips that can be adjusted for better fitting to enhance wearing comfort and prevent the frame from slipping down the face',
  },
  {
    image: `pointC.webp`,
    title: 'Nose Pad-Less Design',
    description:
      'A saddle bridge design with no nose pads to offer better comfort and fit and does not leave marks on the nose',
  },
];

export const LINE_UP_PC2007N2S = [
  {
    color: 'matte black',
    sku: 'PC2007N-2S C1',
    image: 'PC2007N-2S_C1_04_r2.webp',
    delay: '0',
  },
  {
    color: 'light brown',
    sku: 'PC2007N-2S C2',
    image: 'PC2007N-2S_C2_04_r2.webp',
    delay: '50',
  },
  {
    color: 'navy',
    sku: 'PC2007N-2S C3',
    image: 'PC2007N-2S_C3_04_r2.webp',
    delay: '100',
  },
  {
    color: 'khaki',
    sku: 'PC2007N-2S C4',
    image: 'PC2007N-2S_C4_04_r2.webp',
    delay: '150',
  },
];

export const LINE_UP_PC2008N2S = [
  {
    color: 'matte black',
    sku: 'PC2008N-2S C1',
    image: 'PC2008N-2S_C1_04_r2.webp',
    delay: '0',
  },
  {
    color: 'matte brown demi',
    sku: 'PC2008N-2S C2',
    image: 'PC2008N-2S_C2_04_r2.webp',
    delay: '50',
  },
  {
    color: 'light brown',
    sku: 'PC2008N-2S C3',
    image: 'PC2008N-2S_C3_04_r2.webp',
    delay: '100',
  },
  {
    color: 'green',
    sku: 'PC2008N-2S C4',
    image: 'PC2008N-2S_C4_04_r2.webp',
    delay: '150',
  },
];
