export const RESET_USER_SAVED_CARDS = 'savedCards/RESET_USER_SAVED_CARDS';
export const GET_USER_SAVED_CARDS_LOAD = 'savedCards/GET_USER_SAVED_CARDS_LOAD';
export const GET_USER_SAVED_CARDS_SUCCESS = 'savedCards/GET_USER_SAVED_CARDS_SUCCESS';
export const GET_USER_SAVED_CARDS_FAIL = 'savedCards/GET_USER_SAVED_CARDS_FAIL';

export const GET_USER_SAVED_CARDS_OFFER_SUCCESS = 'savedCards/GET_USER_SAVED_CARDS_OFFER_SUCCESS';
export const GET_USER_SAVED_CARDS_OFFER_FAIL = 'savedCards/GET_USER_SAVED_CARDS_OFFER_FAIL';

export const DELETE_USER_SAVED_CARD_LOAD = 'savedCards/DELETE_USER_SAVED_CARD_LOAD';
export const DELETE_USER_SAVED_CARD_SUCCESS = 'savedCards/DELETE_USER_SAVED_CARD_SUCCESS';
export const DELETE_USER_SAVED_CARD_FAIL = 'savedCards/DELETE_USER_SAVED_CARD_FAIL';
