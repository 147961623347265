import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/dittoShare';
import apiClient from '../helpers/apiClient';
import config from 'config';

let _client = null;
function* saveDitto({ dittoId, formData }) {
  const successAction = { type: actionTypes.DITTO_SHARE_DATA_SUCCESS };
  const errorAction = { type: actionTypes.DITTO_SHARE_DATA_FAIL };
  const client = { ..._client };
  client.setHeader({ 'Content-Type': 'multipart/form-data' });
  try {
    const data = yield call(async () =>
      client.post(
        `${config.apiPath.storeApi}/ditto/${dittoId.substring(0, 14)}/uploadImage`,
        formData
      )
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runDittoShareCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.DITTO_SHARE_DATA_LOAD, saveDitto)]);
}
