export const SET_ORDER = 'order/SET_ORDER';
export const GET_ORDER = 'order/GET_ORDER';
export const GET_ORDER_SUCCESS = 'order/GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'order/GET_ORDER_FAIL';
export const POWER_SUMBMIT_LOAD = 'order/POWER_SUMBMIT_LOAD';
export const POWER_SUMBMIT_FAIL = 'order/POWER_SUMBMIT_FAIL';

export const ORDER_CANCEL_REFUND_DETAILS = 'order/ORDER_CANCEL_REFUND_DETAILS';
export const ORDER_CANCEL_REFUND_DETAILS_SUCCESS = 'order/ORDER_CANCEL_REFUND_DETAILS_SUCCESS';
export const ORDER_CANCEL_REFUND_DETAILS_FAIL = 'order/ORDER_CANCEL_REFUND_DETAILS_FAIL';

export const PREV_ORDER_LOAD = 'order/PREV_ORDER_LOAD';
export const PREV_ORDER_SUCCESS = 'order/PREV_ORDER_SUCCESS';
export const PREV_ORDER_FAIL = 'order/PREV_ORDER_FAIL';

export const RENEW_ORDER_DETAILS = 'RENEW_ORDER_DETAILS';

export const CLEAR_STATE = 'order/CLEAR_STATE';

export const RESET_ORDERDETAILS = 'order/RESET_ORDERDETAILS';
