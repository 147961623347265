import * as actionTypes from './../../actionTypes/editPower';

export default function editPower(prevState = {}, action) {
  let hash;
  switch (action.type) {
    case actionTypes.GET_LENS_PACKAGES_SUCCESS:
      hash = action.pid + (action.powerType ? action.powerType : '');
      const pkgs = {
        [hash]: action.data && action.data.result ? action.data.result.packages : undefined,
      };
      prevState.pidToPkgs = { ...prevState.pidToPkgs, ...pkgs };
      return { ...prevState };
    case actionTypes.GET_LENS_PACKAGES_FAIL:
      return prevState;
    case actionTypes.GET_POWERS_SUCCESS:
      hash = action.pid + (action.powerType ? action.powerType : '');
      const powers = {
        [hash]: action.data && action.data.result ? action.data.result.powerTypeList : undefined,
      };
      prevState.pidToPowers = { ...prevState.pidToPowers, ...powers };
      return { ...prevState };
    case actionTypes.GET_POWERS_FAIL:
      return prevState;
    default:
      return prevState;
  }
}
