import React, { useEffect, useState } from 'react';
import Main from './Main';
import Lineup from './Lineup';
import Concept from './Concept';
import Merchandise from './Merchandise';
import Shop from './Shop';
import Share from './Share';
const HarryPotterBase = ({ className }) => {
  const baseUrl = 'https://www.owndays.com/sg/en/';
  const baseImgUrl = 'https://www.owndays.com/images/specials/products/harry-potter/';
  const currentUrl = window.location.href;
  const pageName = currentUrl.split('/').pop();
  const [isSP, setIsSP] = useState(window.innerWidth < 768);

  useEffect(() => {
    const addScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = onLoad;
      document.head.appendChild(script);
      return script;
    };

    const addStylesheet = href => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      document.head.appendChild(link);
      return link;
    };

    const scripts = [
      { src: 'https://cdn-script.com/ajax/libs/jquery/3.7.1/jquery.js', onLoad: null },
      { src: 'https://unpkg.com/aos@3.0.0-beta.6/dist/aos.js', onLoad: null },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.7/swiper-bundle.min.js',
        onLoad: null,
      },
    ];

    const stylesheets = [
      'https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.7/swiper-bundle.css',
      'https://unpkg.com/aos@3.0.0-beta.6/dist/aos.css',
    ];

    const scriptElements = scripts.map(({ src, onLoad }) => addScript(src, onLoad));
    const styleElements = stylesheets.map(href => addStylesheet(href));

    window.onload = () => {
      AOS.init({
        duration: 1000,
        once: true,
      });
      $('body').append(`
        <script>
          // Font Adobe - Source Han Serif JP
          (function (d) {
            var config = {
              kitId: 'rep8jse',
              scriptTimeout: 3000,
              async: true
            },
              h = d.documentElement,
              t = setTimeout(function () {
                h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
              }, config.scriptTimeout),
              tk = d.createElement("script"),
              f = false,
              s = d.getElementsByTagName("script")[0],
              a;
            h.className += " wf-loading";
            tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
            tk.async = true;
            tk.onload = tk.onreadystatechange = function () {
              a = this.readyState;
              if (f || a && a != "complete" && a != "loaded") return;
              f = true;
              clearTimeout(t);
              try {
                Typekit.load(config)
              } catch (e) { }
            };
            s.parentNode.insertBefore(tk, s)
          })(document);

          // Font Adobe - Pollen
          (function (d) {
            var config = {
              kitId: 'hdk2aki',
              scriptTimeout: 3000,
              async: true
            },
            h = d.documentElement,
            t = setTimeout(function () {
              h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
            }, config.scriptTimeout),
            tk = d.createElement("script"),
            f = false,
            s = d.getElementsByTagName("script")[0],
            a;
            
            h.className += " wf-loading";
            tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
            tk.async = true;
            tk.onload = tk.onreadystatechange = function () {
              a = this.readyState;
              if (f || (a && a != "complete" && a != "loaded")) return;
              f = true;
              clearTimeout(t);
              try {
                Typekit.load(config);
              } catch (e) {}
            };
            
            s.parentNode.insertBefore(tk, s);
          })(document);  
          // end font adobe
          
          new Swiper('.lineup__products-top-swiper', {
            slidesPerView: 1,
            spaceBetween: 5,
            loop: true,
            pagination: {
              el: '.lineup__products-top-swiper-pagination',
              clickable: true,
            },
            autoplay: {
              delay: 6000,
              disableOnInteraction: false,
            },
            speed: 600,
            effect: 'fade',
            fadeEffect: {
              crossFade: true,
            },
          });

          new Swiper('.lineup__products-middle-swiper', {
            slidesPerView: 'auto',
            spaceBetween: 6,
            centeredSlides: true,
            loop: true,
            pagination: {
              el: '.lineup__products-middle-swiper-pagination',
              clickable: true,
            },
            breakpoints: {
              768: {
                slidesPerView: 3,
                centeredSlides: false,
                simulateTouch: false,
                touchStartPreventDefault: false,
                allowTouchMove: false,
              },
            },
          });

          new Swiper('.lineup__products-bottom-swiper', {
            centeredSlides: true,
            slidesPerView: 'auto',
            loop: true,
            speed: 300,
            effect: 'coverflow',
            coverflowEffect: {
              rotate: 0,
              stretch: 220,
              depth: 400,
              modifier: 1,
              slideShadows: false,
            },
            pagination: {
              el: '.lineup__products-bottom-swiper-pagination',
              clickable: true,
            },
            navigation: {
              nextEl: '.button-next',
              prevEl: '.button-prev',
            },
            breakpoints: {
              ...genBreakpoints(390, 418, 2, 230),
              ...genBreakpoints(420, 458, 2, 256),
              ...genBreakpoints(460, 480, 2, 290),
              ...genBreakpoints(482, 518, 2, 308),
              ...genBreakpoints(520, 538, 2, 340),
              ...genBreakpoints(540, 568, 2, 356),
              ...genBreakpoints(570, 598, 2, 380),
              ...genBreakpoints(600, 628, 2, 406),
              ...genBreakpoints(630, 650, 2, 430),
              ...genBreakpoints(652, 700, 2, 450),
              ...genBreakpoints(710, 760, 2, 498),
              ...genBreakpoints(760, 767, 2, 540),
              768: {
                coverflowEffect: {
                  stretch: 455,
                },
              },
              1024: {
                coverflowEffect: {
                  stretch: 650,
                },
              },
            },
          });

          function genBreakpoints(start, max, step, initialStretch) {
            const breakpoints = {};
            let stretch = initialStretch;

            for (let i = start; i <= max; i += step) {
              breakpoints[i] = { coverflowEffect: { stretch: stretch } };
              stretch += step;
            }
            return breakpoints;
          }

          new Swiper('.merchandise__main-slider', {
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            pagination: {
              el: '.merchandise__main-slider-pagination',
              clickable: true,
            },
            autoplay: {
              delay: 6000,
              disableOnInteraction: false,
            },
            speed: 600,
            effect: 'fade',
            fadeEffect: {
              crossFade: true,
            },
          });

          new Swiper('.merchandise__other-slider', {
            slidesPerView: 1.5,
            spaceBetween: 6,
            loop: true,
            centeredSlides: true,
            pagination: {
              el: '.merchandise__other-slider-pagination',
              clickable: true,
            },
            breakpoints: {
              768: {
                slidesPerView: 3.5,
              },
              1024: {
                initialSlide: 2,
                slidesPerView: 5,
                spaceBetween: 9.5,
                allowTouchMove: false,
              },
            },
          });

          const $lHeader = $('.header-placeholder').length > 0 ? $('.header-placeholder') : $('.od-header__panel');
          const $lHeaderHeight = $lHeader.outerHeight();

          $.fn.hasAttr = function (name) {
            return this.attr(name) !== undefined;
          };

          $(".l-hp a[href^='#']").on('click', function (e) {
            const $this = $(this);
            const $targetId = $this.attr('href');
            const $target = $($targetId);
            const $duration = $this.hasAttr('data-scroll-duration') ? $this.attr('data-scroll-duration') : 1000;
            const $offset = $this.hasAttr('data-scroll-offset') ? $this.attr('data-scroll-offset') : 25;
            const $scrollPosition = $target.offset().top - $lHeaderHeight - $offset;

            if ($target.length === 0) {
              return;
            }

            e.preventDefault();

            $('html, body').animate(
              {
                scrollTop: $scrollPosition,
              },
              {
                duration: parseInt($duration),
                step: (now, fx) => {
                  const $realPos = $target.offset().top - $lHeaderHeight - $offset;
                  if (fx.end !== $realPos) {
                    fx.end = $realPos;
                  }
                },
              }
            );
          });
        </script>
      `);

      const space = () => {
        const space = (1200 - document.body.clientWidth) / 2 + 'px';
        if ($(window).width() >= 1240) {
          $('.l-hp .lineup__products-top-swiper').css('margin-left', space);
          $('.l-hp .lineup__products-middle').css('margin', '0 ' + space);
        } else {
          $('.l-hp .lineup__products-top-swiper').css('margin-left', '');
          $('.l-hp .lineup__products-middle').css('margin', '');
        }
      };
      space();

      function splitBtnText() {
        if ($(window).width() >= 1024) {
          const maintxtElements = document.querySelectorAll('.l-hp__btn-inner');

          maintxtElements.forEach(function (maintxt) {
            const letters = maintxt.textContent.split('');
            let totalLetters = 0;

            maintxt.innerHTML = '';

            const fragment = document.createDocumentFragment();

            letters.forEach(function (letter) {
              const span = document.createElement('span');
              span.textContent = letter;

              if (letter === ' ') {
                span.classList.add('space');
              }

              span.style.animationDelay = totalLetters * 0.05 + 's';
              fragment.appendChild(span);
              totalLetters++;
            });

            maintxt.appendChild(fragment);
          });
        }
      }

      splitBtnText();

      window.addEventListener('resize', function () {
        splitBtnText();
        space();
      });

      document.addEventListener('mousemove', createParticle);

      function createParticle(e) {
        const particle = document.createElement('div');
        particle.classList.add('hp-particle');
        particle.style.left = `${e.clientX + 5}px`;
        particle.style.top = `${e.clientY + 5}px`;
        document.body.appendChild(particle);

        setTimeout(() => {
          particle.remove();
        }, 1000);
      }
    };
    const handleResize = () => {
      setIsSP(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      scriptElements.forEach(script => document.head.removeChild(script));
      styleElements.forEach(link => document.head.removeChild(link));
    };
  }, []);
  return (
    <>
      <div className={className} data-aos="fade" data-aos-duration="300">
        <Main baseImgUrl={baseImgUrl} isSP={isSP} />
        <Concept baseImgUrl={baseImgUrl} />

        <div className="bg-lg">
          <Lineup baseImgUrl={baseImgUrl} isSP={isSP} />
          <Merchandise baseImgUrl={baseImgUrl} isSP={isSP} />
          <Shop isSP={isSP} />
        </div>
        <Share baseImgUrl={baseImgUrl} baseUrl={baseUrl} pageName={pageName} />
      </div>
    </>
  );
};

export default HarryPotterBase;
