export const BUNDLE_SALE_CONTENT = {
  title: 'OWNDAYS Contact Lens Promotion',
  thumbSrc: 'https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/thumbnail.webp',
  thumbAlt: 'OWNDAYS Contact Lens Promotion',
  duration: '<strong>Duration : </strong>From 1 Sep 2024, while stocks last',
  description: 'Experience all-day comfort with our exclusive contact lens bundles—grab your clear and colour contact lenses today and enjoy unbeatable savings at selected OWNDAYS stores!',
};

export const BUNDLE_SALE_HIGHLIGHTS = [
  {
    imgSrc: 'https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/event-2.webp',
    imgAlt: 'Image Clear Contact Lenses',
    title: 'Enjoy $10 off for every 4 boxes purchased or $30 off for 8 boxes of Clear Contact Lenses from the same series.',
    products: [
      {
        title: 'Clear Vision Daily',
        path: '/contacts/clear-contactlens/daily',
        price: '(U.P. $30/box)'
      },
      {
        title: 'Clear Vision Monthly',
        path: '/contacts/clear-contactlens/monthly',
        price: '(U.P. $30/box)'
      },
      {
        title: 'Clear Vision Blue Daily',
        path: '/contacts/clear-contactlens/daily-blue',
        price: '(U.P. $40/box)'
      }
    ],
    terms: {
      title: 'Clear Contact Lens Bundle',
      list: [
        'Offer is valid for the purchase of all daily and monthly clear contact lenses only, while stocks last',
        'Offer includes $10 off 4 boxes or $30 off 8 boxes of clear contact lenses from the same series',
        'Offer is valid for lenses used by the same wearer only and there shall be no combining of receipts',
        'Offer is valid at selected OWNDAYS stores in Singapore that retail contact lenses',
        'Offer cannot be used in conjunction with other discounts, vouchers and/or promotions',
        'The management reserves the right to amend the offer and the terms and conditions at any time without prior notice',
      ],
    }
  },
  {
    imgSrc: 'https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/event-1.webp',
    imgAlt: 'Image Colour Contact Lenses',
    title: 'Receive 1 Box FREE for every 7 boxes of Colour Contact Lenses purchased!',
    products: [
      {
        title: 'Natural Series Daily',
        path: '/contacts/color-contactlens/natural',
        price: '(U.P. $18/box)'
      },
      {
        title: 'Horoscope Series Daily',
        path: '/contacts/color-contactlens/horoscope',
        price: '(U.P. $18/box)'
      },
      {
        title: 'Petal Series Monthly',
        path: '/contacts/color-contactlens/petal',
        price: '(U.P. $15/box)'
      },
      {
        title: 'Velvet Series Monthly',
        path: '/contacts/color-contactlens/velvet',
        price: '(U.P. $15/box)'
      }
    ],
    terms: {
      title: 'Colour Contact Lens Bundle',
      list: [
        'Offer is valid for the purchase of all daily and monthly colour contact lenses only, while stocks last',
        'Offer includes 1 free box of colour contact lenses with the purchase of 7 boxes; the free box will be the lowest priced item',
        'Offer is valid for lenses used by the same wearer only and there shall be no combining of receipts',
        'Offer is valid at selected OWNDAYS stores in Singapore that retail contact lenses',
        'Offer cannot be used in conjunction with other discounts, vouchers and/or promotions',
        'The management reserves the right to amend the offer and the terms and conditions at any time without prior notice',
      ],
    }
  }
];
