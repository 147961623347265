import * as actionTypes from '../../actionTypes/order';

const initialState = {
  orderDetails: null,
  loading: false,
  loaded: false,
  prevOrders: null,
  prevOrdersLoading: false,
};

export default function reducer(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_ORDER:
      return { orderDetails: action.orderDetails, error: null, isSubmitting: false };
    case actionTypes.RESET_ORDERDETAILS:
      return initialState;
    case actionTypes.GET_ORDER:
      return { ...prevState, loading: true, loaded: false, orderDetails: null };
    case actionTypes.GET_ORDER_SUCCESS:
      return { ...prevState, loading: false, loaded: true, orderDetails: action.data };
    case actionTypes.GET_ORDER_FAIL:
      return { ...prevState, loading: false, loaded: true, orderDetails: action.error };
    case actionTypes.ORDER_CANCEL_REFUND_DETAILS:
      return {
        ...prevState,
        orderCancelRefundDetailsLoading: true,
        orderCancelRefundDetails: null,
      };
    case actionTypes.ORDER_CANCEL_REFUND_DETAILS_SUCCESS:
      return {
        ...prevState,
        orderCancelRefundDetailsLoading: false,
        orderCancelRefundDetails: action.data,
      };
    case actionTypes.ORDER_CANCEL_REFUND_DETAILS_FAIL:
      return {
        ...prevState,
        orderCancelRefundDetailsLoading: false,
        orderCancelRefundDetails: action.error,
      };
    case actionTypes.POWER_SUMBMIT_LOAD:
      return { ...prevState, error: null, isSubmitting: true };
    case actionTypes.POWER_SUMBMIT_FAIL:
      return { ...prevState, isSubmitting: false, error: action.error };
    case actionTypes.PREV_ORDER_LOAD:
      return { ...prevState, prevOrdersLoading: true };
    case actionTypes.PREV_ORDER_SUCCESS:
      return { ...prevState, prevOrdersLoading: false, prevOrders: action.data };
    case actionTypes.PREV_ORDER_FAIL:
      return { ...prevState, prevOrdersLoading: false, prevOrders: action.error };
    case actionTypes.CLEAR_STATE:
      return { ...prevState, ...action.data };
    default:
      return prevState;
  }
}
