import React, { PureComponent } from 'react';
import Modal from '../../../CommonComponents/BootstrapComponents/Modal/Modal';
import PropTypes from 'prop-types';
import './Modal.scss';

class LKModal extends PureComponent {
  static propTypes = {
    size: PropTypes.string,
    backdrop: PropTypes.any,
    hideHandler: PropTypes.func,
  };

  render() {
    const {
      size,
      backdrop,
      hideHandler,
      showModal,
      headerTitle,
      keyboard,
      sgBookAppointment,
      outSideContentClick,
    } = this.props;
    const { dialogClassName = 'lkModal', bodyClassName } = this.props;
    const keyboardVal = keyboard || false;
    return (
      <div className="static-modal">
        <Modal
          backdrop={backdrop}
          bsSize={size}
          dialogClassName={dialogClassName}
          keyboard={keyboardVal}
          outSideContentClick={outSideContentClick || null}
          sgBookAppointment={sgBookAppointment}
          show={showModal}
          onHide={() => hideHandler()}
        >
          {headerTitle && (
            <Modal.Header closeButton>
              <Modal.Title>{headerTitle}</Modal.Title>
            </Modal.Header>
          )}
          <Modal.Body className={bodyClassName}>{this.props.children}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default LKModal;
