export const PRODUCT_DATA_LOAD = 'product/PRODUCT_DATA_LOAD';
export const PRODUCT_DATA_SUCCESS = 'product/PRODUCT_DATA_SUCCESS';
export const PRODUCT_DATA_FAIL = 'product/PRODUCT_DATA_FAIL';

export const SIMILAR_PRODUCTS_LOAD = 'product/SIMILAR_PRODUCTS_LOAD';
export const SIMILAR_PRODUCTS_SUCCESS = 'product/SIMILAR_PRODUCTS_SUCCESS';
export const SIMILAR_PRODUCTS_FAIL = 'product/SIMILAR_PRODUCTS_FAIL';

export const CHECK_STORE_AVAILABILITY_LOAD = 'product/CHECK_STORE_AVAILABILITY_LOAD';
export const CHECK_STORE_AVAILABILITY_SUCCESS = 'product/CHECK_STORE_AVAILABILITY_SUCCESS';
export const CHECK_STORE_AVAILABILITY_FAIL = 'product/CHECK_STORE_AVAILABILITY_FAIL';

export const COLORED_LENS_DATA_LOAD = 'product/COLORED_LENS_DATA_LOAD';
export const COLORED_LENS_DATA_SUCCESS = 'product/COLORED_LENS_DATA_SUCCESS';
export const COLORED_LENS_DATA_FAIL = 'product/COLORED_LENS_DATA_FAIL';
// Send Ditto Image Email
export const DITTO_IMG_EMAIL_SEND = 'product/DITTO_EMAIL_SEND';
export const DITTO_IMG_EMAIL_SUCCESS = 'product/DITTO_IMG_EMAIL_SUCCESS';
export const DITTO_IMG_EMAIL_FAIL = 'product/DITTO_IMG_EMAIL_FAIL';
export const DITTO_IMG_EMAIL_RESET = 'product/DITTO_IMG_EMAIL_RESET';

// CL Prescriptions
export const CONTACT_LENS_LOAD = 'produc/CONTACT_LENS_LOAD';
export const CONTACT_LENS_SUCCESS = 'product/CONTACT_LENS_SUCCESS';
export const CONTACT_LENS_FAIL = 'product/CONTACT_LENS_FAIL';
export const CL_DROPDOWN_OPEN = 'product/CL_DROPDOWN_OPEN';
export const CL_SAVED_PRESCRIPTION_LOAD = 'product/CL_SAVED_PRESCRIPTION_LOAD';
export const CL_SAVED_PRESCRIPTION_SUCCESS = 'product/CL_SAVED_PRESCRIPTION_SUCCESS';
export const CL_SAVED_PRESCRIPTION_FAIL = 'product/CL_SAVED_PRESCRIPTION_FAIL';
export const CL_PRESCRIPTION_VALID_TEST = 'product/CL_PRESCRIPTION_VALID_TEST';
export const CL_PRESCRIPTION_VALID_SUCCESS = 'product/CL_PRESCRIPTION_VALID_SUCCESS';
export const CL_PRESCRIPTION_VALID_FAIL = 'product/CL_PRESCRIPTION_VALID_FAIL';
export const FRONT_IMG = 'product/FRONT_IMG';
export const GOTO_PDP_ID = 'product/GOTO_PDP_ID';

export const EXPRESS_TIME_LOAD = 'product/EXPRESS_TIME_LOAD';
export const EXPRESS_TIME_SUCCESS = 'product/EXPRESS_TIME_SUCCESS';
export const EXPRESS_TIME_FAIL = 'product/EXPRESS_TIME_FAIL';

export const RESET_PINCODE_FORM = 'product/RESET_PINCODE_FORM';

export const OOS_SUBSCRIPTION = 'product/OOS_SUBSCRIPTION';
export const OOS_SUCCESS = 'product/OOS_SUCCESS';
export const OOS_FAIL = 'product/OOS_FAIL';

export const LENS_LOCATION_AVAILABILITY_CHECK = 'product/LENS_LOCATION_AVAILABILITY_CHECK';
export const LENS_LOCATION_AVAILABILITY_SUCCESS = 'product/LENS_LOCATION_AVAILABILITY_SUCCESS';
export const LENS_LOCATION_AVAILABILITY_FAIL = 'product/LENS_LOCATION_AVAILABILITY_FAIL';

export const LENS_PACKAGES_LOAD = 'product/LENS_PACKAGES_LOAD';
export const LENS_PACKAGES_SUCCESS = 'product/LENS_PACKAGES_SUCCESS';
export const LENS_PACKAGES_FAIL = 'product/LENS_PACKAGES_FAIL';

export const FLUSH_PRODUCT_DATA = 'product/FLUSH_PRODUCT_DATA';
export const CHANGE_PRODUCTTABINDEX = 'product/CHANGE_PRODUCTTABINDEX';
export const RESET_PRODUCT_VALUE = 'product/RESET_PRODUCT_VALUE';

export const SET_POWER_TYPE = 'product/SET_POWER_TYPE';

// CL Subscription & discounts
export const GET_CLSUBSCRIPTION = 'product/GET_CLSUBSCRIPTION';
export const GET_CLSUBSCRIPTION_SUCCESS = 'product/GET_CLSUBSCRIPTION_SUCCESS';
export const GET_CLSUBSCRIPTION_FAIL = 'product/GET_CLSUBSCRIPTION_FAIL';

export const GET_SUBSCRIPTION_DISCOUNT = 'product/GET_SUBSCRIPTION_DISCOUNT';
export const GET_SUBSCRIPTION_DISCOUNT_SUCCESS = 'product/GET_SUBSCRIPTION_DISCOUNT_SUCCESS';
export const GET_SUBSCRIPTION_DISCOUNT_FAIL = 'product/GET_SUBSCRIPTION_DISCOUNT_FAIL';

export const GET_CLSOLUTIONS = 'product/GET_CLSOLUTIONS';
export const GET_CLSOLUTIONS_SUCCESS = 'product/GET_CLSOLUTIONS_SUCCESS';
export const GET_CLSOLUTIONS_FAIL = 'product/GET_CLSOLUTIONS_FAIL';

// Delivery
export const GET_DELIVERY_DETAILS_LOAD = 'GET_DELIVERY_DETAILS_LOAD';
export const GET_DELIVERY_DETAILS_SUCCESS = 'GET_DELIVERY_DETAILS_SUCCESS';
export const GET_DELIVERY_DETAILS_FAILED = 'GET_DELIVERY_DETAILS_FAILED';

export const SET_APP_PRICE = 'SET_APP_PRICE';

export const REVIEW_PRODUCT = 'product/REVIEW_PRODUCT';
export const REVIEW_PRODUCT_SUCCESS = 'product/REVIEW_PRODUCT_SUCCESS';
export const REVIEW_PRODUCT_FAIL = 'product/REVIEW_PRODUCT_FAIL';

// exchange
export const SET_EXCHANGE_BALANCE = 'product/SET_EXCHANGE_BALANCE';

// package or coating group (for now used for exchange flow)
export const GET_PACKAGE_OR_COATING_GROUP_LOAD = 'product/GET_PACKAGE_OR_COATING_GROUP_LOAD';
export const GET_PACKAGE_OR_COATING_GROUP_SUCCESS = 'product/GET_PACKAGE_OR_COATING_GROUP_SUCCESS';
export const GET_PACKAGE_OR_COATING_GROUP_FAIL = 'product/GET_PACKAGE_OR_COATING_GROUP_FAIL';

// Get 360 view Status
export const GET_THREE_SIXTY_VIEW_LOAD = 'product/GET_THREE_SIXTY_VIEW_LOAD';
export const GET_THREE_SIXTY_VIEW_SUCCESS = 'product/GET_THREE_SIXTY_VIEW_SUCCESS';
export const GET_THREE_SIXTY_VIEW_FAIL = 'product/GET_THREE_SIXTY_VIEW_FAIL';

export const TOGGLE_LENSES_SELECTION_POPUP = 'product/TOGGLE_LENSES_SELECTION_POPUP';
