import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
                                                    <meta property="og:title" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                                                                        <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/membership-terms">
                <meta property="og:image" content="https://www.owndays.com/images/common/ogp_1200x630.jpg?1690859228">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link href="https://www.owndays.com/web/css/privacy.css" rel="stylesheet">
<link href="https://www.owndays.com/web/css/terms-page.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">    OWNDAYS ONLINE STORE - OPTICAL SHOP
</title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
<div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/terms">OWNDAYS Membership Programme Terms and Conditions</a>
                                                                                </li>
        
            </ul>
</div>


<header class="l-terms__header l-privacy__header l-container">
    <h1>OWNDAYS Membership Programme Terms and Conditions</h1>
</header>

<section class="l-terms l-terms__content l-privacy__content l-container">
    <ul class="l-parent-ul">
        <li class="l-paragraph">
            <h3>Membership Account</h3>
            <p>You are required to register a membership account with us to enjoy the exclusive benefits as an OWNDAYS member. During registration, you will need to provide personal information, including your full name, contact number and email address, that is true, accurate and current. By registering an account, you confirm that you have read, understood and agree to be bound by the Terms and Conditions of the OWNDAYS Membership Programme, <a href="/sg/en/terms" class="link-color-blue u-dib">the use of our website</a> and our <a href="/sg/en/privacy" class="link-color-blue u-dib">Privacy Policy</a>.</p>
            <p>You may only register one membership account with us and the account cannot be shared nor transferred to others. If you are below the age of 13 years old, we require that you inform a parent or guardian of our Terms and Conditions and our Privacy Policy and seek their consent before registering an account. The management reserves the right to only accounts registered by customers who are 13 years and above.</p>
            <p>Should there be any change to your personal information, please sign in to your account immediately and update the relevant section. For assistance and enquiries regarding the membership programme, please email us at <a href="mailto:inquirysg@owndays.com" class="link-color-blue u-dib">inquirysg@owndays.com</a>. Our Customer Service Team will respond to you within 3 to 5 working days.</p>
        </li>
        <li class="l-paragraph">
            <h3>Membership Points</h3>
            <p>As a member, you will earn rebates in Membership Points for valid purchases made in-store and online which may then be used to offset your next purchase at OWNDAYS.</p>
            <h4>Earning Points</h4>
            <p>You will earn Membership Points for each qualifying purchase made at our physical store or the online store. Unless otherwise stated, all products and services available for purchase, including physical gift vouchers and eGift, will qualify for points issuance after less of all discounts which include Membership Points redeemed for the purchase.</p>
            <p class="u-mb__0">You will earn different amount of Membership Points at different Membership Tiers:</p>
            <table class="l-terms__table u-ml__20">
                <tbody>
                    <tr class="l-terms__table-list">
                        <td>Membership Tier</td>
                        <td>Points Rebate</td>
                        <td>Example of Points Earned </td>
                    </tr>
                    <tr>
                        <th>Bronze</th>
                        <td>4%</td>
                        <td>
                            Purchase Amount = $148<br>
                            Points Earned = 148*4% = 6 Points (rounded up to the nearest 1 Point)
                        </td>
                    </tr>
                    <tr>
                        <th>Silver</th>
                        <td>6%</td>
                        <td>
                            Purchase Amount = $148<br>
                            Points Earned = 148*6% = 9 Points (rounded up to the nearest 1 Point)
                        </td>
                    </tr>
                    <tr>
                        <th>Gold</th>
                        <td>8%</td>
                        <td>
                            Purchase Amount = $148<br>
                            Points Earned = 148*8% = 12 Points (rounded up to the nearest 1 Point)
                        </td>
                    </tr>
                </tbody>
            </table>
            <p class="u-mt__20">Membership Points will be issued for each purchase and there is strictly no combining of receipts or online orders to earn points. Membership Points are non-transferrable and shall be credited to each member’s individual membership account. You will not be able to earn points for purchases made by another customer nor to transfer the points you have earned from your purchase to someone else.</p>
            <p>Membership Points are issued at the point of purchase. There will be no Membership Points issued for past purchases or purchases made when you are not a registered member. For purchases made at physical stores, you must present your Membership ID or QR Code to the staff prior to payment. Membership Points will be credited to your Membership Account immediately upon payment. Please note that we are unable to re-issue Membership Points if you fail to present your Membership ID or QR Code to the staff prior to payment and the purchase has been settled. For purchases made online, you must log in to your Membership Account to place the order. Membership Points will be credited within 7 working days upon order delivery.</p>
            <h4>Redeeming Points</h4>
            <p>You may use the Membership Points to offset your next in-store or online purchase. Regardless of your Membership Tier, 1 Membership Point is equivalent to S$1 discount. Membership Points are non-transferrable. You may redeem points for your own purchases only.</p>
            <p>To redeem Membership Points when making a purchase in-store, you must present your Membership ID or QR Code to the staff prior to payment. To redeem Membership Points when making an online purchase, you must log in to your Membership Account and complete the “Use points” section accordingly during checkout.</p>
            <p>There is strictly no refund of Membership Points once they are redeemed. Conversely, once a purchase has been settled and paid for, we are unable to deduct any Membership Point available in your Membership Account and refund the payment to you.</p>
            <h4>Points Expiry</h4>
            <p>All Membership Points are valid for 12 months from the month of issue. There is strictly no extension of expiry date. Membership Points are not exchangeable for cash and will be forfeited upon expiry. </p>
        </li>
        <li class="l-paragraph">
            <h3>Membership Tiers</h3>
            <p>The total number of Membership Points you have earned during your membership lifetime will determine the Membership Tier you will be at. Upon registering and activating your Membership Account, you will be at the Bronze Tier. When you have earned a total of 200 points, you will automatically level up to the Silver Tier. When you have earned a total of 600 points, you will subsequently level up to the highest Gold Tier.</p>
            <p>There is no expiry of Membership Tier. Once you have reached a certain tier, you will be able to enjoy the exclusive benefits available to the particular Membership Tier.</p>
        </li>
        <li class="l-paragraph">
            <h3>Referral Feature</h3>
            <p>To use the referral feature, you must make a one-time purchase of minimum $78 in-store or online. You may then log in to your account to issue a unique Referral Code which you can share with your friends and family who are new to OWNDAYS. There is no limit to the number of people whom you may share the Referral Code with. However, do note that each Referral Code is valid for 1 month only and you will need to re-issue a new code from your account when the initial code expires.</p>
            <h4>Referral Coupon</h4>
            <p>Your friend will need to sign up a Membership Account and enter a valid Referral Code during registration. Upon successful registration, he will then receive a Referral Coupon that entitles him to a one-time $20 discount off his first purchase at OWNDAYS subject to the following coupon terms and conditions:</p>
            <ul class="c-list-decoration c-list-decoration--dot">
                <li>Referral Coupon is valid for one-month from the date of account registration</li>
                <li>Referral Coupon is non-transferrable and is valid for first-time eyewear purchase at OWNDAYS only</li>
                <li>Referral coupon is only valid with minimum spend of $98 on a single pair of eyewear and excludes collaboration merchandise</li>
                <li>Referral Coupon is not valid in conjunction with other discounts, vouchers and/or promotions</li>
            </ul>
            <h4 class="u-mt__20">Referral Points Rebate</h4>
            <p>Regardless of your Membership Tier, you will earn 4% points rebate from your friend’s first purchase when he redeems the Referral Coupon. The number of Membership Points earned will be calculated based on the nett amount spent in your friend’s first purchase which shall be inclusive of the prevailing Goods and Services Tax (GST) and less of all discounts.</p>
        </li>
        <li class="l-paragraph">
            <h3>Scenarios to illustrate the points you will earn</h3>
            <p>Scenario 1:<br>
                Your friend bought these items in his first purchase: a pair of spectacles worth $198, a pair of sunglasses worth $78 and a spectacle chain worth $20<br>
                Total amount before discount: $198 + $78 + $20 = $296<br>
                Referral Coupon: $20 discount<br>
                Nett amount spent: $276<br>
                Membership Points you will earn: $276 x 4% = 11 Points (rounded down to the nearest 1 Point)
            </p>
            <p>Scenario 2:<br>
            Your friend bought these items in his first purchase: a pair of sunglasses worth $78 and a pair of OWNDAYS PC glasses worth $60<br>
                Total amount before discount: $138<br>
                Referral Coupon: $20 discount - not valid for this purchase<br>
                Nett amount spent: $138<br>
                You will not earn any Membership Point as the Referral Coupon is not valid for redemption in your friend’s first purchase
            </p>
            <p>Membership Points you earn from a successful referral will be credited to your Membership Account within 5 to 7 working days. There is no limit to the number of Membership Points you may earn from successful referrals.</p>
        </li>
        <li class="l-paragraph">
            <h3>Account Cancellation</h3>
            <p>You may cancel your Membership Account at any time by logging in to your account and selecting the “Cancel Account” option. When you cancel your account, all your account information, including order history and prescription records, will be deleted. You will not be able to retrieve any information after your Membership Account has been deleted. Any Membership Point, discount coupon, eGift available in your account will be forfeited immediately upon account cancellation and will not be reinstated when you re-register a Membership Account.</p>
        </li>
        <li class="l-paragraph">
            <h3>Account Termination</h3>
            <p>We reserve the right to terminate your Membership Account without prior notice if you do not comply with or violate these Terms and Conditions. All membership benefits, including Membership Points and discount coupons, will be forfeited without compensation of any kind.</p>
        </li>
        <li class="l-paragraph">
            <h3>Amendments</h3>
            <p>The management reserves the right to suspend, cancel or amend the membership programme and these terms and conditions at any time without prior notice.</p>
        </li>
    </ul>
    <div class="u-text-right">
        <p>Last updated: November 2021</p>
    </div>
</section>
        </main>
            </div>

    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://www.owndays.com/web/js/jp/common.js" defer></script>
<script>
    var isWebView = '';
    if (isWebView) {
        $('header').hide();
        $('footer').hide();
        $('.od-c-breadcrumb').hide();
        $('main').removeClass('l-content');
        $('#latestpost').hide();
        $('.hachidori_chat_open').hide();
        $('.l-privacy__content').attr('style', 'padding-top: 20px !important');
        $('.l-privacy__content h2').attr('style', 'padding-bottom: 20px !important');
    }
</script>
            
</body>

</html>`;

const MembershipTermsHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Review the Terms and Conditions of the OWNDAYS Membership Programme. Understand the benefits and guidelines of our membership for a better eyewear experience."
        title="Membership Terms & Conditions | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default MembershipTermsHTML;
