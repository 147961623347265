import React from 'react';
import './ButtonOwnDays.scss';

const defaultMode = 'white';

const ButtonOwnDays = props => {
  const {
    onClick,
    text,
    width,
    px,
    py,
    fontSize,
    mode,
    type = 'button',
    borderless = false,
    disabled = false,
    style = {},
    fontClass,
    classes = '',
    childrenPosition = 'right',
    noHover = false,
    id = '',
  } = props;

  return (
    <button
      className={`owndays-btn owndays-btn--${mode || defaultMode} ${
        fontClass || 'owndays-btn-font--mobile'
      } ${disabled && 'owndays-btn--disabled'} ${borderless && 'owndays-btn--borderless'} ${
        noHover && `owndays-btn owndays-btn--${mode || defaultMode}-no-hover`
      } ${classes}`}
      disabled={disabled}
      id={id}
      style={{
        width,
        padding: `${py} ${px}`,
        fontSize,
        ...style,
      }}
      type={type}
      onClick={disabled ? () => {} : onClick || null}
    >
      {props.children && childrenPosition === 'left' && props.children}
      {text || null}
      {props.children && childrenPosition === 'right' && props.children}
    </button>
  );
};

export default ButtonOwnDays;
