import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/footer';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;
function* getResource(resource, successAction, errorAction, options) {
  try {
    const data = yield call(async () => _client.get(resource, options));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getPowerUpdate({ value }) {
  const successAction = {
    type: actionTypes.ENTER_POWER_SUCCESS,
  };
  const errorAction = {
    type: actionTypes.ENTER_POWER_FAIL,
  };
  const options = {
    params: {
      phone: value,
    },
  };
  yield call(getResource, config.apiPath.enterMyPower, successAction, errorAction, options);
}

export function* runFooterCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.ENTER_POWER_LOAD, getPowerUpdate)]);
}
