import React, { useState } from 'react';

const Attention = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const attentionData = {
    toggleQuestion: 'Before making a purchase',
    toggleAnswer: {
      title: 'Please read the safety precautions if you have purchased the product.',
      items: [
        {
          key: '①',
          text: 'This product is not waterproof. Do not use spray, solvents, chemicals, or cleaning solutions containing alcohol and ammonia. Do not wash the product in water.',
        },
        {
          key: '②',
          text: 'Only the nose pad arms may be adjusted for better fitting. Do not adjust any other parts of the smart audio glasses.',
        },
      ],
      warranty: `1 year from the date of purchase\nValid for: Non-user-related faulty performance and manufacturing defects\nCoverage: Temples (faulty sound quality, bad connection, unable to power on, etc.); Front Attachment (stains, defects, etc.)\nWarranty Exclusions: Faulty performance or damage caused by improper usage, mishandling, accidents, or operating the product outside stated guidelines; loss of product`,
      note: `We do not accept returns if prescription lenses cannot be added due to lens power constraints. Please consult our staff in-store before making a purchase if you require prescription lenses.`,
      precautions: [
        {
          key: '①',
          text: `Listening to loud music for prolonged periods may harm your hearing. Avoid loud volumes, especially when using the smart audio glasses continuously for long periods. Adjust the volume to avoid ear strain, and turn down the playback device volume before wearing the glasses. Then, gradually increase the volume to a comfortable level.`,
        },
        {
          key: '②',
          text: 'Please comply with the laws and regulations regarding mobile phone use while driving in your country or local region.',
        },
        {
          key: '③',
          text: 'Exercise caution when near roads, construction sites, railway tracks, etc. Remove the smart audio glasses or lower the volume to hear alarms or warning sounds from the surroundings.',
        },
        {
          key: '④',
          text: 'If you experience abnormal sound quality, stop using the glasses immediately, power them off, and contact Customer Service.',
        },
        {
          key: '⑤',
          text: 'Do not immerse the smart audio glasses in water or use them during swimming, water skiing, surfing, or other water sports.',
        },
        {
          key: '⑥',
          text: 'Remove the smart audio glasses immediately if they become hot or produce no sound.',
        },
      ],
    },
    notes: [
      'Not valid with any discount, promotion, and/or privilege.',
      'No cancellation, refund, exchange, or change of order details once an order is placed.',
      'Resale or transfer is strictly prohibited.',
      'Delivery within Singapore only.',
      'Certain prescriptions cannot be added to the lenses due to constraints in lens power.',
      'Upgrade to optional lenses is available at additional charges.',
      'For manufacturing defects, please contact our Customer Service Team at +65 6222 0588.',
    ],
  };

  return (
    <section className="l-huawei2-attention" data-aos="fade-up" data-aos-offset="300">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2__heading l-huawei2__heading--center">ATTENTION</h2>
        <div className="l-huawei2-attention__toggle">
          <div className={`l-huawei2-attention__item ${isOpen ? 'open' : ''}`}>
            <div className="l-huawei2-attention__item-q" onClick={toggleOpen}>
              {attentionData.toggleQuestion}
            </div>
            {isOpen && (
              <div className="l-huawei2-attention__item-a">
                <h3>{attentionData.toggleAnswer.title}</h3>
                <ul>
                  {attentionData.toggleAnswer.items.map((item, index) => (
                    <li key={index}>
                      <span>{item.key}</span>
                      <span>{item.text}</span>
                    </li>
                  ))}
                </ul>
                <p>
                  {attentionData.toggleAnswer.warranty.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
                <p className="note note05">{attentionData.toggleAnswer.note}</p>
                <h4>■ Safety Precautions</h4>
                <ul>
                  {attentionData.toggleAnswer.precautions.map((precaution, index) => (
                    <li key={index}>
                      <span>{precaution.key}</span>
                      <span>{precaution.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
        <ul className="l-huawei2-attention__note note06">
          {attentionData.notes.map((note, index) => (
            <li key={index}>{note}</li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Attention;
