import React from 'react';
import Slider from 'react-slick';

export const LineupProducts = ({ productSettings }) => {
  const renderProducts = products => {
    return products?.map(item => (
      <div key={item.alt} className="body-owndays-sunglasses__lineup__product-list">
        {item.isNew && <span className="body-owndays-sunglasses__lineup__product-new">New</span>}
        <img
          alt={item.alt}
          className="body-owndays-sunglasses__lineup__product-img lazyload"
          data-sizes="auto"
          src={item.src}
        />
        <h4 className="body-owndays-sunglasses__lineup__product-code">{item.alt}</h4>

        <a
          className="base-owndays-sunglasses__btn base-owndays-sunglasses__btn--blue"
          href={item.link}
        >
          {item.buttonName}
        </a>
      </div>
    ));
  };

  return (
    <div className="body-owndays-sunglasses__lineup__product-list-container">
      {productSettings.map(({ settings, sku }, index) => (
        <Slider key={index} {...settings}>
          {renderProducts(sku)}
        </Slider>
      ))}
    </div>
  );
};
