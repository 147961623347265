import React from 'react';
import { Breadcrumbs } from './Breadcrumbs';
import './ProductLineChange.scss';
import backIcon from '../../../../static/icons/back.svg';
import { onImageLoadError } from '../../../utils/helper';
import LinkProvider from '../../../CommonComponents/LinkProvider/LinkProvider';
import FBIcon from '../../../../static/icons/facebook-square.svg';
import xTwitterIcon from '../../../../static/icons/x-twitter.svg';
import lineIcon from '../../../../static/icons/line.svg';

const ProductLineChange = () => {
  return (
    <>
      <Breadcrumbs />
      <div className="product-line-change">
        {/* Header Section */}
        <div className="product-line-change__header">
          <span className="product-line-change__day">2024.10.31 ｜ Press Release</span>
          <h1 className="product-line-change__title">
            Announcement on Product Line Changes
          </h1>
        </div>

        {/* Content Section */}
        <div className="product-line-change__content">
          <p className="product-line-change__content__header">
            Through the rebranding, we have restructured our product range to make our
            eyewear even more attractive and easier to understand for our customers.
          </p>

          <h2>In-House Brands</h2>
          <p className="product-line-change__content__desc">
            This product range is designed for those seeking the best items for everyday use,
            with an emphasis on functionality and cost-effectiveness. They are designed for
            simplicity, high quality, and convenience.
          </p>

          <h2>Sub-Brands</h2>
          <p className="product-line-change__content__desc">
            This product group stands out for its design and unique concept. A wide variety of
            designs are available, perfect for those who want to focus on eyewear as a fashion
            statement.
          </p>

          <h2>Collaborations</h2>
          <p className="product-line-change__content__desc product-line-change__mb-0">
            OWNDAYS collaborates with brands from various industries to create unique items that
            blend the character of both companies. By collaborating with brands that have distinct
            backgrounds and values, OWNDAYS offers collections that appeal to an even broader range
            of styles.
          </p>
          {/* Image */}
        <img
          alt="OWNDAYS Announcement on Product Line Changes"
          className="product-line-change__image"
          src="https://static1.lenskart.com/media/owndays/img/information/5002/image.webp"
        />
        </div>
        <div className="product-line-change__footer">
      {/* Social Links */}
      <ul className="product-line-change__sns">
        <li>
          <LinkProvider to="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/information/287">
            <img
              alt=""
              className="product-line-change__icon"
              src={FBIcon}
              onError={e => onImageLoadError(e)}
            />
          </LinkProvider>
        </li>
        <li>
          <LinkProvider to="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/information/287">
            <img
              alt=""
              className="product-line-change__icon"
              src={xTwitterIcon}
              onError={e => onImageLoadError(e)}
            />
          </LinkProvider>
        </li>
        <li>
          <LinkProvider to="https://line.me/R/msg/text/?https://www.owndays.com/sg/en/information/287">
            <img
              alt=""
              className="product-line-change__icon"
              src={lineIcon}
              onError={e => onImageLoadError(e)}
            />
          </LinkProvider>
        </li>
      </ul>

      {/* Footer Section */}
      <LinkProvider
        className="product-line-change__back-nav"
        target="_self"
        to="/information"
      >
        <img
          alt=""
          className="product-line-change__icon"
          src={backIcon}
          onError={e => onImageLoadError(e)}
        />
        <span>Back</span>
          </LinkProvider>
        </div>
      </div>
    </>
  );
};

export default ProductLineChange;
