import * as actionTypes from './../../actionTypes/home';

const initialState = {
  loaded: false,
  loading: false,
  homepageData: {},
  renderData: [],
  scrollY: null,
  loadMoreCarousals: true,
  homePageProducts: {
    data: null,
    loading: false,
    error: null,
  },
  clickCategory: {
    data: null,
    loading: false,
    error: null,
  },
};

export default function home(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.HOMEPAGE_DATA_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
        homepageData: {},
        renderData: [],
      });
    case actionTypes.MOBILE_HOMEPAGE_DATA_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.HOMEPAGE_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        homepageData: action.data.homeData,
        renderData: action.data.homeData && action.data.homeData.sections.slice(0, 7),
        rawHomeData: action.rawData,
      });
    case actionTypes.MOBILE_HOMEPAGE_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        homepageData: action.data,
        rawHomeData: Object.assign({}, action.data),
        renderData: action.isBot
          ? action.data.result
          : action.data.result && action.data.result.slice(0, 7),
      });
    case actionTypes.HOMEPAGE_DATA_FAIL:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        homepageData: action.error,
      });
    case actionTypes.RENDER_DATA_UPDATE:
      return Object.assign({}, prevState, {
        renderData: prevState.renderData
          ? prevState.renderData.concat(action.data)
          : [].concat(action.data),
      });
    case actionTypes.HOMEPAGE_MERGED_DATA_UPDATE:
      const homeData = prevState.homepageData;
      homeData.result = action.data;
      return Object.assign({}, prevState, {
        homepageData: homeData,
        renderData: homeData.result && homeData.result.slice(0, 7),
      });
    case actionTypes.HOMEPAGE_CAROUSALS_DATA_LOAD:
      return Object.assign({}, prevState, {
        loadMoreCarousals: null,
      });
    case actionTypes.HOMEPAGE_CAROUSALS_DATA_SUCCESS:
      // to update the products in render data when carousel data is fetched successfully
      const rawHomeData = action.data;
      const homepageData = action.mergedHomeData;
      return Object.assign({}, prevState, {
        homepageData,
        rawHomeData: Object.assign({}, rawHomeData),
        renderData: homepageData.sections.slice(0, 10),
        loadMoreCarousals: true,
      });
    case actionTypes.HOMEPAGE_CAROUSALS_DATA_FAIL:
      return Object.assign({}, prevState, {
        rawHomeData: action.error,
        loadMoreCarousals: null,
      });
    case actionTypes.HOME_SCROLL_SET:
      return Object.assign({}, prevState, {
        scrollY: action.scrollY,
      });

    case actionTypes.HOME_PAGE_PRODUCTS_LOAD:
      return Object.assign({}, prevState, {
        homePageProducts: {
          ...prevState.homePageProducts,
          loading: true,
          error: null,
        },
      });

    case actionTypes.HOME_PAGE_PRODUCTS_SUCCESS:
      return Object.assign({}, prevState, {
        homePageProducts: {
          data: action.data,
          loading: false,
          error: null,
        },
      });

    case actionTypes.HOME_PAGE_PRODUCTS_FAILURE:
      return Object.assign({}, prevState, {
        homePageProducts: {
          data: null,
          loading: false,
          error: action.error,
        },
      });

    case actionTypes.GET_CLICK_CATEGORY_LOAD:
      return Object.assign({}, prevState, {
        clickCategory: {
          data: null,
          loading: false,
          error: action.error,
        },
      });

    case actionTypes.GET_CLICK_CATEGORY_SUCCESS:
      return Object.assign({}, prevState, {
        clickCategory: {
          data: action.data,
          loading: false,
          error: null,
        },
      });

    case actionTypes.GET_CLICK_CATEGORY_FAILURE:
      return Object.assign({}, prevState, {
        clickCategory: {
          data: null,
          loading: false,
          error: action.error,
        },
      });
    default:
      return prevState;
  }
}
