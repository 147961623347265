import React, { Fragment } from 'react';

import { LINE_UP_PC2007N2S, LINE_UP_PC2008N2S } from './constants';
import './FooterOwndaysPc.scss';

export const FooterOwndaysPc = ({ baseImgUrl }) => {
  const renderProductItem = products => {
    return products.map(product => (
      <li key={product.sku} data-aos="fade-up" data-aos-delay={product.delay}>
        <a
          href={`/sg/en/owndays-pc-${product.sku.toLowerCase().replace(' ', '-')}-eyeglasses.html`}
        >
          <figure>
            <p className="footer-owndays-pc__lineup__product-color">{product.color}</p>
            <img
              alt={product.sku}
              className="footer-owndays-pc__lineup__product-img"
              src={`${baseImgUrl}/${product.image}`}
            />
          </figure>
          <p className="footer-owndays-pc__lineup__product-sku">{product.sku}</p>
        </a>
      </li>
    ));
  };

  const renderMoreDetailButton = link => {
    return (
      <div className="base-owndays-pc__container-bg-grid">
        <div className="footer-owndays-pc__lineup__more">
          <a className="base-owndays-pc__btn base-owndays-pc__btn--right" href={link}>
            More Details
          </a>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <section className="footer-owndays-pc__lineup">
        <div className="base-owndays-pc__container-bg-grid">
          <div className="base-owndays-pc__title-grid">
            <div className="base-owndays-pc__title-heading">
              <h2 className="base-owndays-pc__title" data-aos="fade-down">
                <span className="base-owndays-pc__title-no">04</span>
                <span className="base-owndays-pc__title-name">LINEUP</span>
              </h2>
            </div>

            <div className="base-owndays-pc__title-content">
              <p className="footer-owndays-pc__lineup__mb-0" data-aos="fade-left">
                Made of Ultem, a lightweight and highly elastic material, OWNDAYS PC is durable and
                can be worn comfortably even over a long period of time.
              </p>
            </div>
          </div>
        </div>

        <div className="base-owndays-pc__container">
          <ul className="footer-owndays-pc__lineup__product lineup__product--first" id="PC2007N-2S">
            {renderProductItem(LINE_UP_PC2007N2S)}
          </ul>

          {renderMoreDetailButton('/sg/en/owndays-pc-pc2007n-2s-c1-eyeglasses.html')}

          <ul className="footer-owndays-pc__lineup__product lineup__product--first" id="PC2008N-2S">
            {renderProductItem(LINE_UP_PC2008N2S)}
          </ul>

          {renderMoreDetailButton('/sg/en/owndays-pc-pc2008n-2s-c1-eyeglasses.html')}

          <div className="footer-owndays-pc__lineup__footer">
            <a
              className="base-owndays-pc__btn base-owndays-pc__btn--right base-owndays-pc__btn--black"
              href="/sg/en/search?q=PC"
            >
              See All OWNDAYS PC Products
            </a>
            <a
              className="base-owndays-pc__btn base-owndays-pc__btn--right base-owndays-pc__btn--black"
              href="/stores/sg"
            >
              Search For Nearest OWNDAYS Stores
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
