import * as actionTypes from './../../actionTypes/PD';

const initialState = {
  loading: false,
  pdData: null,
  pdError: null,
};

export default function PDReducer(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PD:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_PD_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        pdData: action.data,
        pdError: null,
      });
    case actionTypes.GET_PD_FAILURE:
      return Object.assign({}, prevState, {
        loading: false,
        pdData: null,
        pdError: action.error,
      });
    case actionTypes.SET_PD:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.SET_PD_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        pdData: action.data,
        pdError: null,
      });
    case actionTypes.CLEAR_ERROR:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.CLEAR_ERROR_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        pdError: action.error,
        pdData: null,
      });
    default:
      return prevState;
  }
}
