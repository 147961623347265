import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="keywords" content="biomass mark,certified product,derived from plants,castor plant,environment,eco,eco-friendly,metal allergies,SDGs,glasses,online,online store" />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/eco2xy">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/eco2xy/sg/ogp_1200x630.jpg?1645601330">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.css">
<link rel="stylesheet" href="https://www.owndays.com/web/css/specials-eco2xy.css?20220120">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Kantumruy:wght@400;700&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">eco²xy | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            eco²xy                    </li>
        
            </ul>
</div>


<div class="l-eco2xy">
    <section class="main">
        <div class="l-eco2xy__container">
            <h1 class="main__logo"><img src="https://www.owndays.com/images/specials/products/eco2xy/eco2xy.svg" alt="eco²xy"></h1>
            <p class="main__heading">
                <span class="main__heading-bg" data-aos="text-slide">
                    <span class="main__heading-text" data-aos="text-slide"><mark>Environment-friendly</mark> spectacle frames</span>
                </span>
            </p>
            <div class="main__img">
                <picture>
                    <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/eco2xy/main.webp">
                    <img src="https://www.owndays.com/images/specials/products/eco2xy/main_sp.webp" alt="eco²xy main visual">
                </picture>
            </div>
            <p class="main__text">
                <span class="main__text-bg" data-aos="text-slide" data-aos-offset="0">
                    <span class="main__text-text" data-aos="text-slide" data-aos-offset="5">eyewear option</span>
                </span>
                <span class="main__text-bg" data-aos="text-slide" data-aos-offset="0">
                    <span class="main__text-text" data-aos="text-slide" data-aos-offset="6">A new <mark>eco-friendly</mark></span>
                </span>
            </p>
            <ol class="main__features" data-aos="fade-up" data-aos-duration="1100" data-aos-easing="ease-out">
                <li>
                    <div class="main__features-img"><img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-042.webp" alt="New Eco-Friendly Material"></div>
                    <h5 class="main__features-title">New Eco-Friendly<br>Material</h5>
                    <p class="main__features-text">Made of a resin material derived from castor plant; with lower carbon emission compared to conventional resin materials</p>
                </li>
                <li>
                    <div class="main__features-img"><img src="https://www.owndays.com/images/specials/products/eco2xy/ECO2022Q-1A_C3_01.webp" alt="Colourful Transparent Frames"></div>
                    <h5 class="main__features-title">Colourful Transparent<br> Frames</h5>
                    <p class="main__features-text">Made of Rilsan® Clear G850, a sustainable material that is transparent yet rich in colour</p>
                </li>
                <li>
                    <div class="main__features-img"><img src="https://www.owndays.com/images/specials/products/eco2xy/ECO2021Q-1A_C2_02.webp" alt="Lightweight and Gentle on the Skin"></div>
                    <h5 class="main__features-title">Lightweight and Gentle <br>on the Skin</h5>
                    <p class="main__features-text">Lightweight with no exposed metal components; safe for people with metal allergies</p>
                </li>
            </ol>
            <div class="main__btn">
                <a href="#lineup" data-scroll class="l-eco2xy__btn l-eco2xy__btn--secondary l-eco2xy__btn--icon l-eco2xy__btn--icon-down"><span>View Collection</span></a>
            </div>
            <div class="main__details" data-aos="fade-up" 　data-aos-offset="10" data-aos-duration="1100" data-aos-easing="ease-out">
                <h5 class="main__details-title"><small>What is</small><span>Biomass Mark</span></h5>
                <div class="main__details-img"><img src="https://www.owndays.com/images/specials/products/eco2xy/logo45.svg" alt=""></div>
                <p class="main__details-text">The Biomass Mark is awarded to eco-friendly products that use biomass resources and are in compliant with key regulations and standards for product quality and safety. Products must be of at least 10% biomass composition on a dry weight basis to qualify.</p>
            </div>
            <div class="main__logo-45"><img src="https://www.owndays.com/images/specials/products/eco2xy/sg/logo45-text.svg" alt="Biomass Mark"></div>
        </div>
    </section>
    <section class="concept">
        <div class="l-eco2xy__container">
            <div class="concept__inner" data-aos="fade-up" data-aos-duration="1100" data-aos-easing="ease-out">
                <h2 class="concept__title"><span>Eco-Friendly Spectacle Frames</span><br>Made using biomass resources derived from castor plant                </h2>
                <div class="concept__img concept__img--blob"><img src="https://www.owndays.com/images/specials/products/eco2xy/concept-blob.webp" alt="glasses"></div>
                <div class="concept__img concept__img--circle"><img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-056.webp" alt="castor plant"></div>
                <p class="concept__text">Castor plant is a renewable raw material that grows in wastelands. It is a perennial plant that does not cause deforestation. The use of fossil fuel is thus reduced during production, resulting in lower carbon emission.<br>At OWNDAYS, we are committed to making our products as environmentally friendly as possible.</p>
            </div>
        </div>
    </section>
    <section class="howitwork">
        <div class="l-eco2xy__container">
            <h2 class="l-eco2xy__title">HOW IT WORKS<small><span>The Making of eco²xy</span></small></h2>
            <div class="howitwork__grid">
                <ul class="howitwork__nav" id="howitwork-nav">
                    <li>
                        <a href="#step01" class="active current">01</a>
                    </li>
                    <li>
                        <a href="#step02" class="">02</a>
                    </li>
                    <li>
                        <a href="#step03" class="">03</a>
                    </li>
                    <li>
                        <a href="#step04" class="">04</a>
                    </li>
                    <li>
                        <a href="#step05" class="">05</a>
                    </li>
                </ul>
                <div class="howitwork__content">
                    <div class="howitwork__card" id="step01">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-119_pc.webp">
                            <img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-119.webp" alt="Castor Plant" class="howitwork__card-img">
                        </picture>
                        <div class="howitwork__card-overlay">
                            <h4 class="howitwork__card-title u-mb__10">
                                <span class="howitwork__card-title-number">STEP<b>01</b></span>
                                <span class="howitwork__card-title-text"><strong>Castor Plant</strong>A renewable plant-based resource</span>
                            </h4>
                            <h5 class="howitwork__card-subtitle"><span>Features of Castor Plant</span></h5>
                            <ul class="howitwork__card-list">
                                <li>・Does not cause deforestation</li>
                                <li>・Does not affect food supply</li>
                                <li>・A perennial plant</li>
                            </ul>
                        </div>
                    </div>
                    <div class="howitwork__card" id="step02">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-077_pc.webp">
                            <img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-077.webp" alt="Oil" class="howitwork__card-img">
                        </picture>
                        <div class="howitwork__card-overlay">
                            <h4 class="howitwork__card-title">
                                <span class="howitwork__card-title-number">STEP<b>02</b></span>
                                <span class="howitwork__card-title-text"><strong>Oil</strong>Extracted from castor seeds</span>
                            </h4>
                        </div>
                    </div>
                    <div class="howitwork__card" id="step03">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-103_pc.webp">
                            <img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-103.webp" alt="Powder" class="howitwork__card-img">
                        </picture>
                        <div class="howitwork__card-overlay">
                            <h4 class="howitwork__card-title">
                                <span class="howitwork__card-title-number">STEP<b>03</b></span>
                                <span class="howitwork__card-title-text"><strong>Powder</strong>Transformed from oil to resin raw material</span>
                            </h4>
                        </div>
                    </div>
                    <div class="howitwork__card" id="step04">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-013_pc.webp">
                            <img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-013.webp" alt="Resin" class="howitwork__card-img">
                        </picture>
                        <div class="howitwork__card-overlay">
                            <h4 class="howitwork__card-title">
                                <span class="howitwork__card-title-number">STEP<b>04</b></span>
                                <span class="howitwork__card-title-text"><strong>Resin</strong>Processed into spectacle frames</span>
                            </h4>
                        </div>
                    </div>
                    <div class="howitwork__card" id="step05">
                        <picture>
                            <source media="(min-width: 768px)" srcset="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-007_pc.webp">
                            <img src="https://www.owndays.com/images/specials/products/eco2xy/ecoxy2021-007.webp" alt="End Product" class="howitwork__card-img">
                        </picture>
                        <div class="howitwork__card-overlay">
                            <h4 class="howitwork__card-title">
                                <span class="howitwork__card-title-number">STEP<b>05</b></span>
                                <span class="howitwork__card-title-text"><strong>End Product</strong>Eco-friendly spectacle frames <br class="u-pc-only">that meet <b>SDGs</b><br class="u-pc-only">(Sustainable Development Goals)</span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="lineup" id="lineup">
        <div class="l-eco2xy__container">
            <h2 class="l-eco2xy__title">LINE UP<small>All Products</small></h2>
            <ul class="lineup__grid">
                
                <!-- ECO2021Q-1A -->
                <li class="lineup__new">
                    <h5 class="lineup__title">Oval</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C1_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C1_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C1_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2021Q-1A C1</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2021Q-1A?sku=5978" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C2_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C2_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C2_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2021Q-1A C2</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2021Q-1A?sku=5979" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C3_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C3_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C3_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2021Q-1A C3</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2021Q-1A?sku=5980" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C4_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C4_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2021Q-1A_C4_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2021Q-1A C4</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2021Q-1A?sku=5981" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #999999"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #F0C2A1"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #465B7A"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #F79278"></span></div>
                    </div>
                </li>

                <!-- ECO2022Q-1A -->
                <li class="lineup__new">
                    <h5 class="lineup__title">Boston</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C1_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C1_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C1_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2022Q-1A C1</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2022Q-1A?sku=5982" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C2_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C2_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C2_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2022Q-1A C2</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2022Q-1A?sku=5983" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C3_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C3_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C3_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2022Q-1A C3</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2022Q-1A?sku=5984" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C4_01.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C4_02.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2022Q-1A_C4_03.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku"><span>Kids Model</span>ECO2022Q-1A C4</p>
                                <p class="lineup__details-price"><span>S$138.00</span></p>
                                <a href="/sg/en/products/ECO2022Q-1A?sku=5985" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #000000"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-1.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #A3A597"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #C88A99"></span></div>
                    </div>
                </li>

                <!-- ECO2020K-1A -->
                <li>
                    <h5 class="lineup__title">Polygon</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2020K-1A C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2020K-1A?sku=5473" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2020K-1A C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2020K-1A?sku=5474" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2020K-1A C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2020K-1A?sku=5475" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2020K-1A_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2020K-1A C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2020K-1A?sku=5476" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #999999"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-1.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-2.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-3.jpg)"></span></div>
                    </div>
                </li>

                <!-- ECO2011-K -->
                <li class="jp-none">
                    <h5 class="lineup__title">Boston</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2011-K C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2011-K?sku=3405" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <!-- ECO2011-K C2 -->
                                                <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2011-K C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2011-K?sku=3406" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                                                <!-- ECO2011-K C2 -->
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2011-K C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2011-K?sku=3407" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2011-K C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2011-K?sku=3408" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C5_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C5_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2011-K_C5_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2011-K C5</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2011-K?sku=3409" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #999999"></span></div>
                        <!-- ECO2011-K C2 -->
                                                <div><span class="lineup__slider-nav-color" style="--lineup-color: #06348B"></span></div>
                                                <!-- ECO2011-K C2 -->
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #434021"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #F7F5F4"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-3.jpg)"></span></div>
                    </div>
                </li>

                <!-- ECO2012-K -->
                <li>
                    <h5 class="lineup__title">Boston</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2012-K C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2012-K?sku=3410" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2012-K C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2012-K?sku=3411" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2012-K C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2012-K?sku=3412" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <!-- ECO2012-K C4 -->
                                                <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2012-K C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2012-K?sku=3413" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                                                <!-- ECO2012-K C4 -->
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C5_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C5_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2012-K_C5_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2012-K C5</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2012-K?sku=3414" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #000000"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-1.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #d2af97"></span></div>
                        <!-- ECO2012-K C4 -->
                                                <div><span class="lineup__slider-nav-color" style="--lineup-color: #583877"></span></div>
                                                <!-- ECO2012-K C4 -->
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #FC9AC0"></span></div>
                    </div>
                </li>

                <!-- ECO2013-K -->
                <li>
                    <h5 class="lineup__title">Rectangle</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2013-K C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2013-K?sku=3415" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2013-K C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2013-K?sku=3416" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2013-K C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2013-K?sku=3417" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <!-- ECO2013-K C4 -->
                                                <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2013-K C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2013-K?sku=3418" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                                                <!-- ECO2013-K C4 -->
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C5_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C5_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2013-K_C5_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2013-K C5</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2013-K?sku=3419" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #000000"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-1.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #999999"></span></div>
                        <!-- ECO2013-K C4 -->
                                                <div><span class="lineup__slider-nav-color" style="--lineup-color: #865928"></span></div>
                                                <!-- ECO2013-K C4 -->
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #e2d8fb"></span></div>
                    </div>
                </li>

                <!-- ECO2015K-0S -->
                <li class="jp-none">
                    <h5 class="lineup__title">Wellington</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2015K-0S C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2015K-0S?sku=4926" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2015K-0S C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2015K-0S?sku=4927" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2015K-0S C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2015K-0S?sku=4928" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2015K-0S_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2015K-0S C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2015K-0S?sku=4929" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #000000"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-4.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #434021"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-3.jpg)"></span></div>
                    </div>
                </li>

                <!-- ECO2016K-0S -->
                <li>
                    <h5 class="lineup__title">Rectangle</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2016K-0S C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2016K-0S?sku=4930" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2016K-0S C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2016K-0S?sku=4931" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2016K-0S C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2016K-0S?sku=4932" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2016K-0S_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2016K-0S C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2016K-0S?sku=4933" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #000000"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #865928"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #999999"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-3.jpg)"></span></div>
                    </div>
                </li>

                <!-- ECO2019K-1A -->
                <li>
                    <h5 class="lineup__title">Wellington</h5>
                    <div class="lineup__slider-for">
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C1_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C1_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C1_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2019K-1A C1</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2019K-1A?sku=5469" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C2_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C2_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C2_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2019K-1A C2</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2019K-1A?sku=5470" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C3_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C3_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C3_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2019K-1A C3</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2019K-1A?sku=5471" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                        <div>
                            <div class="lineup__slider-inner">
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C4_1.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C4_2.jpg" alt=""></div>
                                <div><img src="https://www.owndays.com/images/specials/products/eco2xy/lineup/ECO2019K-1A_C4_3.jpg" alt=""></div>
                            </div>
                            <div class="lineup__details">
                                <p class="lineup__details-sku">ECO2019K-1A C4</p>
                                <p class="lineup__details-price"><span>S$158.00</span></p>
                                <a href="/sg/en/products/ECO2019K-1A?sku=5472" class="l-eco2xy__btn l-eco2xy__btn--primary l-eco2xy__btn--icon">More details</a>
                            </div>
                        </div>
                    </div>
                    <div class="lineup__slider-nav">
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #999999"></span></div>
                        <div><span class="lineup__slider-nav-color" style="--lineup-color: #865928"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-5.jpg)"></span></div>
                        <div><span class="lineup__slider-nav-color" style="background-image: url(https://www.owndays.com/images/specials/products/eco2xy/color-3.jpg)"></span></div>
                    </div>
                </li>
            </ul>
            <div class="lineup__btn">
                <a href="/sg/en/products?productLines%5B0%5D=21" class="l-eco2xy__btn l-eco2xy__btn--secondary l-eco2xy__btn--icon"><span>See all eco²xy frames</span></a>
                <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-eco2xy__btn l-eco2xy__btn--secondary l-eco2xy__btn--icon"><span>Search for nearest OWNDAYS stores</span></a>
            </div>
        </div>
    </section>
    <section class="sdg">
        <div class="l-eco2xy__container">
            <div class="sdg__grid">
                <div class="sdg__img"><img src="https://www.owndays.com/images/specials/products/eco2xy/sdg.svg" alt="SASTAINABLE DEVELOPMENT GOALS"></div>
                <div class="sdg__details">
                    <h5 class="sdg__details-title">Our consumption habits will impact<br>the environment and our future</h5>
                    <p class="sdg__details-text">OWNDAYS is committed to protecting the environment and the well-being of our future generations. We strive to achieve the Sustainable Development Goals towards building a more sustainable society and to achieve greater social good through our initiatives.</p>
                                    </div>
            </div>
        </div>
    </section>
</div>

        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/ScrollMagic.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.8/plugins/debug.addIndicators.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"></script>
<script src="https://www.owndays.com/web/js/specials-eco2xy.js"></script>
<script>
    (function(d) {
        var config = {
                kitId: 'hdk2aki',
                scriptTimeout: 3000,
                async: true
            },
            h = d.documentElement,
            t = setTimeout(function() {
                h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
            }, config.scriptTimeout),
            tk = d.createElement("script"),
            f = false,
            s = d.getElementsByTagName("script")[0],
            a;
        h.className += " wf-loading";
        tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
        tk.async = true;
        tk.onload = tk.onreadystatechange = function() {
            a = this.readyState;
            if (f || a && a != "complete" && a != "loaded") return;
            f = true;
            clearTimeout(t);
            try {
                Typekit.load(config)
            } catch (e) {}
        };
        s.parentNode.insertBefore(tk, s)
    })(document);
</script>
 
</body>

</html>`;

const EcoFriendlySpectacleFramesHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Eco-Friendly Spectacle Frames made using renewable raw materials.  At OWNDAYS, we are committed to making our products as environmentally friendly as possible."
        title="eco²xy: Eco-Friendly Spectacle Frames | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default EcoFriendlySpectacleFramesHTML;
