import React, { memo } from 'react';
import './PowerStatusMessage.scss';

function PowerStatusMessage(props) {
  const {
    message,
    imgIcon,
    view,
    showUploadPrescModal,
    dataLocale,
    powerValue,
    isBorderallowed,
    newStyle,
    cartView = false,
  } = props;

  if (cartView) {
    return (
      <>
        <div className="cartView-msg-container">
          <div className="cartView-primary-msg">
            {!powerValue ? (
              <>
                <span className="cartView-basicMsg">{message}</span>
                {typeof showUploadPrescModal === 'function' && view !== 'desktop' && (
                  <>
                    {' '}
                    &#x2022;
                    <span className="cartView-moreInfo" onClick={showUploadPrescModal}>
                      {' '}
                      {dataLocale?.VIEW_PRESCRIPTION}{' '}
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                <span>
                  {view === 'desktop'
                    ? dataLocale?.POWER_PRESCRIPTION_UPLOADED
                    : dataLocale?.PRESCRIPTION_UPLOADED}
                </span>
                {typeof showUploadPrescModal === 'function' && view !== 'desktop' && (
                  <>
                    {' '}
                    &#x2022;
                    <span className="cartView-moreInfo" onClick={showUploadPrescModal}>
                      {dataLocale?.VIEW_PRESCRIPTION}
                    </span>
                  </>
                )}

                {typeof showUploadPrescModal === 'function' && view === 'desktop' && (
                  <>
                    {' '}
                    &#x2022;
                    <span className="cartView-moreInfo" onClick={showUploadPrescModal}>
                      {dataLocale?.VIEW_DETAILS}
                    </span>
                  </>
                )}
              </>
            )}
          </div>

          <div className="cartView-secondary-msg">
            {dataLocale?.NO_EXTRA_CHARGES_FOR_HIGH_POWER || 'No extra charge for high power'}
          </div>
        </div>
      </>
    );
  }
  return (
    <div className={view !== 'desktop' ? 'padding_0_7' : ''}>
      {view !== 'desktop' && isBorderallowed && (
        <div className="border-bottom_grey mr-l8 mr-r8"></div>
      )}
      <div
        className={`${
          !newStyle ? 'power-status-message display-flex align-items-center' : 'new-style-details'
        } ${
          view === 'desktop'
            ? !newStyle
              ? 'desktop padding-12 margin-l15 margin-b10 margin-t10 bora-8 mr-t5'
              : 'new-style-fonts'
            : !newStyle
            ? 'pd-b10 bora-4 mr-auto pd-t10 width_100 pd-l8 pd-r8'
            : 'new-style-fonts'
        }`}
        data-testid="powerStatusMessage"
      >
        {view === 'desktop' && (
          <div className={`${!newStyle ? 'status-icon mr-r12 margin-r12' : ''}`} id={imgIcon}>
            <span
              className={`${
                !newStyle
                  ? 'icon display-flex align-items-center test-color_white justify-content-center fsp18 fs18'
                  : ''
              }`}
            >
              {!newStyle && (
                <img alt="Power status icon" aria-label="Power Status Icon" src={imgIcon} />
              )}
            </span>
          </div>
        )}
        {!powerValue ? (
          <div
            className={`${!newStyle ? 'status-message fsp12 lh-16' : ''} ${
              view === 'desktop' ? (!newStyle ? 'display-flex align-items-center flex-1' : '') : ''
            }`}
          >
            {message}{' '}
            {typeof showUploadPrescModal === 'function' && view !== 'desktop' && (
              <span
                className={`${
                  !newStyle
                    ? 'mr-l8 fsp12 text-color_link_blue text-uppercase fw400 align-self-center'
                    : ''
                }`}
                onClick={showUploadPrescModal}
              >
                {dataLocale?.VIEW_PRESCRIPTION}
              </span>
            )}
          </div>
        ) : (
          <div
            className={`${
              !newStyle ? 'status-message fsp12 lh-16 display-flex align-items-center flex-1' : ''
            }`}
          >
            {view === 'desktop'
              ? dataLocale?.POWER_PRESCRIPTION_UPLOADED
              : dataLocale?.PRESCRIPTION_UPLOADED}{' '}
            {typeof showUploadPrescModal === 'function' && view !== 'desktop' && (
              <span
                className={`${
                  !newStyle
                    ? 'mr-l8 fsp12 text-color_link_blue text-uppercase fw400 align-self-center'
                    : ''
                }`}
                onClick={showUploadPrescModal}
              >
                {dataLocale?.VIEW_PRESCRIPTION}
              </span>
            )}
          </div>
        )}
        {typeof showUploadPrescModal === 'function' && view === 'desktop' && (
          <div
            className={`${
              !newStyle
                ? 'power-status-message__view-details cursor-pointer margin-r20 uppercase fw700 align-self-center'
                : ''
            }`}
            onClick={showUploadPrescModal}
          >
            {dataLocale?.VIEW_DETAILS}
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(PowerStatusMessage);
