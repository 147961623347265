import React from 'react';
import './Collapse.scss';

const Collapse = ({ title, description, titleIcon, onSelect, isSelected }) => {
  return (
    <div
      className={`odslv-modal-pt__item ${isSelected ? 'odslv-modal-pt__item--active' : ''}`}
      onClick={() => onSelect && onSelect()}
    >
      {titleIcon && (
        <div className="odslv-modal-pt__item__img">
          <img alt="prescription" src={titleIcon} />
        </div>
      )}
      <div className="odslv-modal-pt__item__info">
        <div className="odslv-modal-pt__item__title">
          {title && (
            <div className="odslv-modal-pt__item__name">
              <span>{title}</span>
              {isSelected ? (
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0003 18.3332C14.6027 18.3332 18.3337 14.6022 18.3337 9.99984C18.3337 5.39746 14.6027 1.6665 10.0003 1.6665C5.39795 1.6665 1.66699 5.39746 1.66699 9.99984C1.66699 14.6022 5.39795 18.3332 10.0003 18.3332Z"
                    fill="#546073"
                    stroke="#CDCDCD"
                    strokeLinecap="square"
                  />
                  <path d="M6.25 10L8.75 12.5L13.75 7.5" stroke="white" strokeLinecap="square" />
                </svg>
              ) : (
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0003 18.3334C14.6027 18.3334 18.3337 14.6025 18.3337 10.0001C18.3337 5.39771 14.6027 1.66675 10.0003 1.66675C5.39795 1.66675 1.66699 5.39771 1.66699 10.0001C1.66699 14.6025 5.39795 18.3334 10.0003 18.3334Z"
                    fill="white"
                    stroke="#CDCDCD"
                    strokeLinecap="square"
                  />
                  <path d="M6.25 10L8.75 12.5L13.75 7.5" stroke="white" strokeLinecap="square" />
                </svg>
              )}
            </div>
          )}
          {description && <div className="odslv-modal-pt__item__description">{description}</div>}
        </div>
      </div>
    </div>
  );
};

export default Collapse;
