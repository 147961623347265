import React from 'react';

const defaultSetting = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  fade: true,
  draggable: false,
  infinite: false,
  swipe: false,
  touchMove: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        draggable: true,
        infinite: true,
        swipe: true,
        touchMove: true,
      },
    },
  ],
};

export const settingsForMM1013B3S = {
  ...defaultSetting,
  customPaging: i => {
    switch (i) {
      case 0:
        return <span className="product__color" style={{ backgroundColor: 'black' }}></span>;
      case 1:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C2.png)',
            }}
          ></span>
        );
      case 2:
        return <span className="product__color" style={{ backgroundColor: '#754C27' }}></span>;
      case 3:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C4.png)',
            }}
          ></span>
        );
      default:
        return null;
    }
  },
};

export const settingsForMM1014B3S = {
  ...defaultSetting,
  customPaging: i => {
    switch (i) {
      case 0:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C1.png)',
            }}
          ></span>
        );
      case 1:
        return <span className="product__color" style={{ backgroundColor: '#754C27' }}></span>;

      case 2:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C3.png)',
            }}
          ></span>
        );
      case 3:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C4.png)',
            }}
          ></span>
        );
      default:
        return null;
    }
  },
};

export const settingsForMM1015B3S = {
  ...defaultSetting,
  customPaging: i => {
    switch (i) {
      case 0:
        return <span className="product__color" style={{ backgroundColor: '#754C27' }}></span>;
      case 1:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C2-2.png)',
            }}
          ></span>
        );

      case 2:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C3-2.png)',
            }}
          ></span>
        );
      case 3:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C4-2.png)',
            }}
          ></span>
        );

      case 4:
        return (
          <span
            className="product__color"
            style={{
              backgroundImage:
                'url(https://static.lenskart.com/media/owndays/img/memory-metal/C4.png)',
            }}
          ></span>
        );
      default:
        return null;
    }
  },
};
