import React from 'react';
import Shimmer from '../Shimmer';

const OdCartShimmer = () => {
  return (
    <div style={{ width: '100%' }}>
      <Shimmer line height={30} style={{ marginBottom: 30, maxWidth: 150 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={3} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 25 }} />
      <Shimmer line height={3} style={{ marginBottom: 25 }} />
      <Shimmer line height={15} style={{ marginBottom: 35 }} />
      <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto', gridGap: 30 }}>
        <Shimmer height={100} style={{ borderRadius: 10 }} />
        <Shimmer height={100} style={{ borderRadius: 10 }} />
        <Shimmer height={100} style={{ borderRadius: 10 }} />
      </div>
    </div>
  );
};

export default OdCartShimmer;
