import './carousel.scss';
import React, { PureComponent } from 'react';
import Swipe from './Swipe';

import { getIntialStateOfCarousel, getTrackStyle } from './utils';
import { v4 } from 'uuid';

class Carousel extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    const noOfSlides = React.Children.count(props.children);
    if (noOfSlides !== state.noOfSlides) {
      return getIntialStateOfCarousel({ ...props });
    }
    return null;
  }
  constructor(props) {
    super(props);
    this.state = getIntialStateOfCarousel({ ...props });
    this.prevSlideHandler = this.prevSlideHandler.bind(this);
    this.nextSlideHandler = this.nextSlideHandler.bind(this);
    this.autoPlayHandler = null;
    this.animateToView = null;
  }
  componentDidMount() {
    if (this.props.autoPlay > 0) {
      this.autoPlayHandler = window.setInterval(() => {
        this.getNextSlide();
      }, this.props.autoPlay);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentSlide === this.state.currentSlide) {
      return;
    }
    if (this.props.currentSlide !== prevProps.currentSlide) {
      this.getSlideIntoView(this.props.currentSlide, null, 0);
    }
    if (prevState === this.state || this.state.disabledInfinite) {
      return;
    }
    // For infine carousel, using mirrored slides on both ends. creating loop
    const { currentSlide, noOfSlides } = this.state;
    if (currentSlide === noOfSlides) {
      this.animating = true;
      this.animateToView = window.setTimeout(() => {
        this.getSlideIntoView(-1, true, 1);
        this.animating = false;
      }, 500);
    } else if (currentSlide === -1) {
      this.animating = true;
      this.animateToView = window.setTimeout(() => {
        this.getSlideIntoView(noOfSlides, true, -1);
        this.animating = false;
      }, 500);
    }
  }
  componentWillUnmount() {
    if (this.autoPlayHandler) {
      window.clearInterval(this.autoPlayHandler);
    }
    window.clearTimeout(this.animateToView);
  }
  getPrevSlide() {
    const { currentSlide, disabledInfinite } = this.state;
    if (disabledInfinite && currentSlide === 0) return;
    this.getSlideIntoView(currentSlide, null, -1);
  }
  getNextSlide() {
    const { currentSlide, disabledInfinite } = this.state;
    if (disabledInfinite && currentSlide === this.state.noOfSlides - 1) return;
    this.getSlideIntoView(currentSlide, null, 1);
  }
  getSlideIntoView(current, transitionOFF, prevOrNext = 1) {
    // if prevOrNext = -1 * n :: previous else next(1 * n)
    const trackStyle = getTrackStyle({
      current,
      prevOrNext,
      transitionOFF,
      ...this.state,
      ...this.props,
    });
    this.setState({ currentSlide: current + prevOrNext, trackStyle }, () => {
      if (typeof this.props.onSlideChange === 'function') {
        this.props.onSlideChange({ currentSlide: this.state.currentSlide });
      }
    });
  }
  stopAutoPlay() {
    if (this.autoPlayHandler) {
      window.clearInterval(this.autoPlayHandler);
    }
  }
  prevSlideHandler() {
    if (this.animating || this.state.isFitToView) return;
    this.getPrevSlide();
    this.stopAutoPlay();
  }
  nextSlideHandler() {
    if (this.animating || this.state.isFitToView) return;
    this.getNextSlide();
    this.stopAutoPlay();
  }
  render() {
    const {
      currentSlide,
      trackStyle,
      slideStyle,
      isFitToView,
      leftMirroredSlides,
      rightMirroedSlides,
      disabledInfinite,
    } = this.state;
    const { children, dots, arrows, dataLocale, isPfu, onClickDots } = this.props;
    const { NEXT, PREV } = dataLocale;
    const pfuCarouselTrack = {
      ...trackStyle,
      height: '96vh',
    };
    return (
      <section className="carousel">
        {this?.props?.newStyle && (
          <div className="dot-container">
            {this.props?.children?.map((item, index) => {
              return (
                <div
                  key={v4()}
                  className="dot-item"
                  style={currentSlide === index ? { backgroundColor: '#A2A2B8' } : {}}
                  onClick={() => this.getSlideIntoView(index - 1)}
                ></div>
              );
            })}
          </div>
        )}
        {arrows && !isFitToView && !this?.props?.newStyle && (
          <button
            aria-label="carousel-prev"
            className={`carousel-prev ${isPfu ? 'pfu-carousel-prev' : ''}`}
            type="button"
            onClick={this.prevSlideHandler}
          >
            {PREV}
          </button>
        )}

        <Swipe next={this.nextSlideHandler} previous={this.prevSlideHandler}>
          <div className={`carousel-slide-container ${isPfu ? 'isPfu-container' : ''}`}>
            <div
              className="carousel-track"
              data-testid="carouselTrack"
              style={isPfu ? pfuCarouselTrack : trackStyle}
            >
              {!disabledInfinite && leftMirroredSlides}
              {React.Children.map(children, (childNode, index) => {
                return (
                  <div
                    key={v4()}
                    className={`${isPfu ? '' : 'carousel-slide'} ${
                      currentSlide === index ? 'active-slide' : ''
                    }`}
                    data-testid="carouselSlide"
                    style={slideStyle}
                  >
                    {childNode}
                  </div>
                );
              })}
              {!disabledInfinite && rightMirroedSlides}
            </div>
          </div>
        </Swipe>

        {arrows && !isFitToView && !this?.props?.newStyle && (
          <button
            aria-label="carousel-next"
            className="carousel-next"
            type="button"
            onClick={this.nextSlideHandler}
          >
            {NEXT}
          </button>
        )}

        {dots && !isFitToView && (
          <div className="nav-dots-container">
            {React.Children.map(children, (childNode, index) => {
              return (
                <span
                  key={v4()}
                  className={`nav-dot ${currentSlide === index ? 'active' : ''}`}
                  data-testid="navDot"
                  onClick={() => onClickDots && onClickDots(index)}
                ></span>
              );
            })}
          </div>
        )}
      </section>
    );
  }
}

Carousel.defaultProps = {
  currentSlide: 0,
  slidesPerView: 1,
  aspectRatio: 1,
  dots: true,
  arrows: true,
  infinite: true,
  autoPlay: 0,
};

export default Carousel;
