import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import apiClient from '../helpers/apiClient';
import * as actionTypes from '../actionTypes/membershipPoints';

let _client = null;

function* getMembershipPoints() {
  const successAction = { type: actionTypes.GET_MEMBERSHIP_POINTS_SUCCESS };
  const errorAction = { type: actionTypes.GET_MEMBERSHIP_POINTS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const responseData = {};
    const data = yield call(async () => _client.get(`${config.apiPath.membershipPoints}`));
    responseData.result = data.result;
    successAction.data = responseData.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* applyMembershipPoints() {
  const successAction = { type: actionTypes.APPLY_MEMBERSHIP_POINTS_SUCCESS };
  const errorAction = { type: actionTypes.APPLY_MEMBERSHIP_POINTS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const responseData = {};
    const data = yield call(async () =>
      _client.post(`${config.apiPath.membershipPoints}/apply`, {})
    );
    responseData.result = data;
    successAction.data = responseData.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* removeMembershipPoints() {
  const successAction = { type: actionTypes.REMOVE_MEMBERSHIP_POINTS_SUCCESS };
  const errorAction = { type: actionTypes.REMOVE_MEMBERSHIP_POINTS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const responseData = {};
    const data = yield call(async () => _client.delete(`${config.apiPath.membershipPoints}`));
    responseData.result = data.result;
    successAction.data = responseData.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getMembershipPointsHistory({ customerId }) {
  const successAction = { type: actionTypes.GET_MEMBERSHIP_POINTS_HISTORY_SUCCESS };
  const errorAction = { type: actionTypes.GET_MEMBERSHIP_POINTS_HISTORY_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.membershipPointsHistory}?customerId=${customerId}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runMembershipPointsCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_MEMBERSHIP_POINTS, getMembershipPoints),
    takeLatest(actionTypes.APPLY_MEMBERSHIP_POINTS, applyMembershipPoints),
    takeLatest(actionTypes.REMOVE_MEMBERSHIP_POINTS, removeMembershipPoints),
    takeLatest(actionTypes.GET_MEMBERSHIP_POINTS_HISTORY_LOAD, getMembershipPointsHistory),
  ]);
}
