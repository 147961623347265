import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../../src/desktop/components/Common/BlankLoader.js';

const ForgotPasswordLoadable = Loadable({
  loader: () =>
    import('./ForgotPassword.js' /* webpackChunkName: 'forgotPassword' */).then(
      module => module.default
    ),
  loading: () => <Loader />,
});

export default ForgotPasswordLoadable;
