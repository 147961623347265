import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="og:title" content="#09 HITOSHI UEDA - OWNDAYS MEETS | OWNDAYS ONLINE STORE - OPTICAL SHOP)" />
    <meta name="description" content="#09 HITOSHI UEDA -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path." />
    <meta name="keywords" content="HITOSHI UEDA,OWNDAYS MEETS,glasses,spectacles,optical shop,eyeglasses,OWNDAYS,frame,sunglasses,eyewear Singapore" />
    <meta name="og:description" content="#09 HITOSHI UEDA -OWNDAYS MEETS- Own Days… Each day builds on the last, and eventually they from one path." />
    <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/owndays-meets-201408">
          <meta property="og:image" content="https://www.owndays.com/images/specials/owndays-meets/201408/ogp_1200x630.jpg?1553872784">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
        <link href="https://www.owndays.com/web/css/owndays-meets.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title"> #09 HITOSHI UEDA - OWNDAYS MEETS | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
        <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            #09 HITOSHI UEDA - OWNDAYS MEETS                    </li>
        
            </ul>
</div>

    <div class="l-owndays-meets">
            <section class="p-main">
        <div class="p-main__bg">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/owndays-meets/201408/01_imgcnv-2880x1620.webp">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201408/01_imgcnv-768x615.webp" alt="">
            </picture>
        </div>
        <div class="p-main__title">
            <h1 class="p-main__title-default meets-title">
                <img src="https://www.owndays.com/images/specials/owndays-meets/owndays-meets.png" alt="OWNDAYS MEETS">
                <span class="meets-title__vol">vol. 09</span>
                <span class="meets-title__name"><span><span>HITOSHI UEDA<span>Architect</span></span></span></span>
            </h1>
        </div>
    </section>
        <div class="l-content--inner">
            <p class="l-owndays-meets__information">    OWNDAYS MEETS’s ninth guest has been involved in creating plans for numerous cities and regions as well as the design and construction of a wide variety of buildings around the world. An architect who aims to create architecture that gives a sense of fulfillment, Hitoshi Ueda tells us here about the path he has taken on his highly varied creative journey so far, and what the road ahead has in store for him.</p>
        </div>
        <section class="l-owndays-meets__profile">
                <div>
        <figure><img src="https://www.owndays.com/images/specials/owndays-meets/201408/profile_imgcnv.webp" alt="HITOSHI UEDA"></figure>
        <div>
            <div>
                <h2 class="profile__name">HITOSHI UEDA<span></span></h2>
                <p class="profile__job">Architect</p>
            </div>
        </div>
    </div>
    <p class="profile__text">    Born in Shibuya, Tokyo in 1963, Hitoshi Ueda graduated with a degree in architecture from Tama Art University in 1987.<br>In the same year he joined the design department of a general contractor, and was assigned to work in the design studio. In 1998 he became the head of design at Kisho Kurokawa Architect & Associates, and later became a director at a related company called Kurokawa CAD Center as well as at a company called Urban Research.<br>He has been involved in many design competitions and run large-scale projects in fields ranging from urban planning to architecture both in Japan an overseas, including Singapore, Malaysia, China, and Taiwan.<br>In 2003, Ueda became director of Kisho Kurokawa Architect & Associates, before resigning in 2005.<br>2006 saw him establish and become director of an architecture company called SO Architects & Associates (certified 1st class architects), and in 2007 he established offices in Los Angeles and Taiwan.<br>In 2008, he put together a jazz album with a comprehensive, integrated approach to express with music the concepts of “memories of the past” and “creation of the future” which can be found in his architecture.<br>In 2011 he became a consultant for the NPO “Forum for Family Office”, and a consultant for NACRE Global Asset Protection (Switzerland) AG in 2013, and produced his second jazz album “Love Score”.</p>
    <p class="profile__link"></p>
        </section>
        <section class="l-owndays-meets__interview">
            <div class="l-content--inner">
                <div class="interview__movie">
                    <a href="" data-video="https://www.youtube.com/embed/7LrRdWGvgis?loop=1&playlist=7LrRdWGvgis" data-toggle="modal" data-target="#videoModal">
                        <img src="https://www.owndays.com/images/specials/owndays-meets/201408/movie_imgcnv.webp" alt="OWNDAYS MEETS HITOSHI UEDA()">
                    </a>
                    <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModal" area-hidden="true">
                        <div class="modal-dialog modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe width="560" height="315" src="" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                    </div>
                    <h3 class="interview__question"><span><span>How did you end up becoming an architect?</span></span></h3>
    <p class="interview__answer">I’ve really liked drawing and music since I was in kindergarten, you see, and my parents were involved in architecture and construction so there were always books and technical drawings related to this scattered around the house. I used to draw lines on these with an architect’s scale and play with it as if it were a toy. I also used to go onsite with my parents to observe the process of a building being constructed, so rather than making the decision to become an architect I just naturally became interested in it.</p>
    <h3 class="interview__question"><span><span>What kind of student were you?</span></span></h3>
    <p class="interview__answer">I attended an art college, and whenever I had the slightest chance I would visit a country or go to see a piece of architecture that I was interested in. I always thought it was important to see it with my own eyes, you see; to smell the smells and hear the sounds, and just feel everything with my own senses.<br>It doesn’t really matter how much you look at a picture or a postcard, nothing compares to experiencing something for yourself.<br>But at the time I was on a very tight budget!<br>When I went to France I felt that I should see as much as possible so I stretched my resources as far as they would go by using buses and walking, and I went to Paris, Nice, Monte Carlo, and as far as Rome. Everything I saw provided me with so much simulation and made an overwhelming impression on me. I still have the memories of that time today, and they continue to provide me with pointers during the creative process.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201408/02_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>The Santa Chiara Church in Tokyo is fairly typical of the things you’ve designed recently…</span></span></h3>
    <p class="interview__answer">Yeah, I designed it based on the concept of a church in the middle of a forest and made it so that you can catch glimpses of the church not because it’s right on the street but because it’s set back.<br>The greenery of the church changes with the seasons, and its character is going to keep changing into next year, the year after that, and the future.<br>That’s why it will never be complete. If I used normal construction materials to create it, it would have been permanent and unchanging, but I think that greenery gives you the opportunity to enjoy the changes that occur.<br>I don’t mind if kids want to play with the leaves or even pick them off; if that place stimulates their interest in architecture then I will be really happy.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201408/03_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>How do you approach your design work?</span></span></h3>
    <p class="interview__answer">Well, in the case of designing that church, for example I more often than not approached it from a woman’s perspective than a man’s. I tried to see things through the eyes of a woman who was excited about the prospect of wearing a wedding dress and having her ceremony in this place.<br>If I don’t take this kind of approach then I don’t really think I have the ability to create anything at all.<br>I also designed a bar in the Ginza area of Tokyo not so long ago, and I came up with some ideas so that the owner and the drinks would take centre stage because the owner was beloved by the customers.<br>I don’t approach design by trying to impose my idea of “cool” on others or from the perspective of making something for myself; I want to create something that makes the people who come there and use the place feel that it is a wonderful, comfortable place to be.<br>If the people in my buildings smile and feel a sense of fulfillment, I feel my work has received praise.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201408/03b_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>What is essential in the process of creating something?</span></span></h3>
    <p class="interview__answer">Creating something as a group is very important to me.<br>Not only in architecture but in other fields as well, like when I made my jazz CD or when I was trying to help out after the Great East Japan Earthquake in 2011 and made the t-shirt I am wearing today, if I was to compare my approach to making music I would say I don’t approach things as if I were a solo singer and try to express everything myself.<br>If I were to compare my approach to a band, I would be the vocalist standing at the front, but around me there would be guitarists, a drummer, a bass player, backing singers, and we would all be working together to produce a single piece of music.<br>Each person has limits to their capacities, and I don’t have the ability to do everything myself, so I like taking the approach of creating something as a team, regardless of the age and gender of its members.</p>
    <div class="interview__photos">
        <img src="https://www.owndays.com/images/specials/owndays-meets/201408/04_imgcnv.webp" alt="">
    </div>
    <h3 class="interview__question"><span><span>What are your future goals?</span></span></h3>
    <p class="interview__answer">I want to be a cool old guy, haha.<br>I don’t mean getting rich and cruising around drinking champagne all day, I mean the type of guy that students and youngsters come to for a range of advice because he still has “it”!<br>This means giving them advice about where they should go when they say they want to go travelling, and what kind of food they should try when they are there.<br>They will hopefully consider me to be wise, entertaining and fun.<br>After I stop working I don’t want to stick with the experience I’ve already accumulated, I want to keep discovering and experiencing new things all the way until my time is up.<br>I just want to keep having a range of different experiences until the end of my life; I can’t get enough of it.<br>As well as this, I want to do things that move people.<br>Buildings can’t speak so there is a limit to what they can convey, so I want to make things like movies and music that people can feel close to and that can have an impact on them no matter how small.<br>This might be in the form of a jazz CD, or a short film. I really haven’t decided how I am going to do it, but I think if I could do something like this it would be a really fulfilling experience.<small class="u-db u-text-right u-mt__10">Interview : August 2014</small></p>
            </div>
        </section>
        <section class="l-owndays-meets__guestselect">
                <div class="l-content--inner">
        <h2>
            Guest Select
            <a href="/sg/en/products?productLines[]=1">John Dillinger</a>
        </h2>
        <ul class="guestselect__photos">
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201408/glasses_imgcnv.webp" alt="John Dillinger/OE2571"></div></li>
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201408/05_imgcnv.webp"></div></li>
            <li><div><img src="https://www.owndays.com/images/specials/owndays-meets/201408/06_imgcnv.webp"></div></li>
        </ul>
        <p>    I have weak eyesight so glasses are part of my everyday life. Glasses are the first thing I select when I am choosing what to wear; I select them depending on my mood on the day, and I match my clothes to my glasses. I have loads of glasses and sunglass made from different materials and in different colours and shapes, but I’ve never had any like this before. They’re really comfortable to wear, and while they look dynamic they have a slight retro feel to them and the frames are flexible, and combined with the colour which compliments my clothes I really like them.</p>
        <div class="guestselect__product">
            <dl>
                <dt>P/No.</dt><dd>OE2571</dd>
                <dt>Colour</dt><dd>C2 Havana</dd>
            </dl>
        </div>
        
        
        
    </div>
        </section>
        <section id="latestpost" class="l-owndays-meets__latestpost">
    <div class="l-content--inner">
        <h2>Latest post</h2>
        <!-- 最新の記事2つ -->
                <div class="latestpost__list">
            <a href="/sg/en/news/owndays-meets-201905">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201905/list-main.webp" alt="HIROTADA OTOTAKE / Writer">
                <div>
                    <p class="latestpost__list-vol">vol. 19</p>
                    <p class="latestpost__list-name">HIROTADA OTOTAKE / Writer</p>
                </div>
            </a>
            <a href="/sg/en/news/owndays-meets-201808">
                <img src="https://www.owndays.com/images/specials/owndays-meets/201901/list-main.webp" alt="KOMATSU MIWA / Contemporary Artist">
                <div>
                    <p class="latestpost__list-vol">vol. 18</p>
                    <p class="latestpost__list-name">KOMATSU MIWA / Contemporary Artist</p>
                </div>
            </a>
        </div>
                <div class="c-btn c-btn--primary c-btn--primary-center u-mt__30">
            <a href="/sg/en/news#owndays-meets">Back to list</a>
        </div>
    </div>
</section>
    </div>
        </main>
            </div>

  
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
    <script src="https://www.owndays.com/web/js/movie-modal.js" defer></script>
    <script>
        $(document).ready(function () {
            autoPlayYouTubeModal();
        });
    </script>
 
</body>

</html>`;

const OwndaysMeetsHitoshiUedaHTML = () => {
  return (
    <>
      <MetaTagsManager
        additionalMeta={[
          {
            name: 'og:description',
            content:
              "Discover the story of Hitoshi Ueda, his inspirations, and his eyewear choices in this edition of 'OWNDAYS MEETS'.",
          },
          {
            name: 'twitter:description',
            content:
              "Discover the story of Hitoshi Ueda, his inspirations, and his eyewear choices in this edition of 'OWNDAYS MEETS'.",
          },
          {
            name: 'og:title',
            content: 'OWNDAYS MEETS: #09 HITOSHI UEDA | OWNDAYS Singapore',
          },
          {
            name: 'twitter:title',
            content: 'OWNDAYS MEETS: #09 HITOSHI UEDA | OWNDAYS Singapore',
          },
        ]}
        canonical="/news/owndays-meets/201402"
        description="Discover the story of Hitoshi Ueda, his inspirations, and his eyewear choices in this edition of 'OWNDAYS MEETS'."
        title="OWNDAYS MEETS: #09 HITOSHI UEDA | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default OwndaysMeetsHitoshiUedaHTML;
