export default class Customevent {
  constructor(name, options) {
    this.name = name;
    this.event = new CustomEvent(name, options);
  }
  addListner(listener) {
    window.addEventListener(this.name, listener);
  }
  removeListner(listener) {
    window.removeEventListener(this.name, listener);
  }
  dispatch() {
    window.dispatchEvent(this.event);
  }
}
