// import React from 'react';
import Loadable from 'react-loadable';
// import Loader from 'desktop/components/BlankLoader/BlankLoader';

const RedisMappingLoadable = Loadable({
  loader: () =>
    import('./RedisMapping.js' /* webpackChunkName: 'desktopRedisMapping' */).then(
      module => module.default
    ),
  loading: () => null,
});

export default RedisMappingLoadable;
