import * as actionTypes from '../actionTypes/home';

export function getMobileHomepageData(isBot = false) {
  return { type: actionTypes.MOBILE_HOMEPAGE_DATA_LOAD, isBot };
}

export function getHome({ pageContext }) {
  return { type: actionTypes.HOMEPAGE_DATA_LOAD, pageContext };
}

export function updateRenderData(data) {
  return { type: actionTypes.RENDER_DATA_UPDATE, data };
}

export function setScroll(scrollY) {
  return { type: actionTypes.HOME_SCROLL_SET, scrollY };
}

export function updateMergedHomepageData(data) {
  return { type: actionTypes.HOMEPAGE_MERGED_DATA_UPDATE, data };
}
export function loadCarousalsData() {
  return { type: actionTypes.HOMEPAGE_CAROUSALS_DATA_LOAD };
}

export function getHomePageProductsLoad(catId) {
  return { type: actionTypes.HOME_PAGE_PRODUCTS_LOAD, catId };
}

export function getClickCategory(catId) {
  return { type: actionTypes.GET_CLICK_CATEGORY_LOAD, catId };
}
