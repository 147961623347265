import * as actionTypes from './../../actionTypes/cmsMapping';

const initialState = {
  cmsData: null,
  jsonData: null,
  loaded: false,
  isCollectionData: false,
  loading: false,
};

export default function CmsMapping(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.CMS_DATA_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.CMS_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        isCollectionData: action.isCollectionData,
        cmsData: action.data,
      });
    case actionTypes.CMS_DATA_FAIL:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        cmsData: action.error,
        isCollectionData: false,
      });
    case actionTypes.CLEAR_CMS_DATA:
      return Object.assign({}, prevState, {
        cmsData: null,
      });
    default:
      return prevState;
  }
}
