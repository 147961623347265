import React, { useEffect, useState } from 'react';
import './PaymentSuccess.scss';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../../../actionCreators/myAccount';
import SubmitPrescription from '../../../CommonComponents/SubmitPrescription/SubmitPrescription';
import useGetOrderById from '../../../CommonComponents/SubmitPrescription/useGetOrderById';
import { v4 } from 'uuid';
import { getQueryParam } from '../../../../utils/helper';
import { showGlobalHeaderFooter } from '../../../../actionCreators/header';
import { odPushGA4Event } from '../../../../utils/gaFour';

// MAKE_CHANGE --> POWER_NO_REQUIRED to POWER_REQUIRED
// const getPrescriptionItems = items => {
//   if (Array.isArray(items) && items.length > 0) {
//     const result = items.filter(
//       // CHANGE POWER_NOT_REQUIRED ---> POWER_REQUIRED
//       item => item?.productType === 'Eyeglasses' && item?.powerRequired === 'POWER_NOT_REQUIRED'
//     );

//     return result;
//   }
//   return [];
// };

const PaymentSuccess = props => {
  const grabPayOrderId = getQueryParam(props.location.search.substr(1), 'order_id');
  const grabPayOrderStatus = getQueryParam(props.location.search.substr(1), 'status');
  const grabPayStatusId = getQueryParam(props.location.search.substr(1), 'status_id');

  const history = props?.history;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const [uuid, setUuid] = useState(v4());
  const orderId = props?.location?.state?.orderId || grabPayOrderId;
  const userInfo = useSelector(state => state.common.userInfo);
  const { orderDetail, fetchingOrderDetails } = useGetOrderById(orderId, uuid, grabPayOrderId);

  const ordersList = useSelector(
    state => (state.myAccount.ordersList && state.myAccount.ordersList.orders) || null
  );

  const isPaymentSuccess = props?.location?.state?.paymentSuccess;

  useEffect(() => {
    if (isPaymentSuccess && ordersList && ordersList?.length > 0) {
      const gaPLPSelectItemData =
        JSON.parse(sessionStorage.getItem('ga-plp-select-item-data')) || {};

      const gaAddToCartData = JSON.parse(sessionStorage.getItem('ga-add-to-cart-data')) || {};

      odPushGA4Event('purchase', {
        userInfo: userInfo?.result,
        order: ordersList[0],
        gaPLPSelectItemData,
        gaAddToCartData,
      });
    }
  }, [isPaymentSuccess && ordersList]);

  useEffect(() => {
    dispatch(showGlobalHeaderFooter({ header: true, footer: false, secondaryHeader: false }));

    // // OD__CLEVERTAP__EVENT (Payment Made)
    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('Payment Made', {
    //     paymentMade: true,
    //     orderId,
    //     userInfo: userInfo?.result,
    //   });

    //   // OD__CLEVERTAP__EVENT (Payment Success)
    //   window.clevertap.event.push('Payment Success', {
    //     paymentMade: true,
    //     orderId,
    //     userInfo: userInfo?.result,
    //   });
    // }

    // odPushClevertapEvent('Order Completed', {
    //   orderDetail,
    // });

    if (grabPayOrderStatus === 'AUTHENTICATION_FAILED') {
      history.push('/customer/account');
    }
  }, []);

  const {
    ORDER_CONFIRMATION_SUBTITLE,
    YOUR_ORDER_CONTAINS_ITEMS_REQUIRE_PRESCRIPTION,
    VIEW_ORDER,
    CONTINUE_SHOPPING,
    ORDER_NO = 'Order No',
    SUBMIT_PRESCRIPTION = 'Submit prescription',
  } = useSelector(state => state?.locale);

  useEffect(() => {
    dispatch(getOrders());
  }, [dispatch]);

  const mainClass = 'od-payment-success-d';

  const handleContinueShopping = () => {
    window.location.href = '/';
  };

  const onClosePopup = () => {
    setIsOpen(false);
  };

  const handleSelectedItem = item => {
    setSelectedItem(item);
    setIsOpen(true);
  };

  if (fetchingOrderDetails && !orderDetail) {
    return null;
  }

  const showPrescriptionStatus = item => {
    if (item?.powerRequired === 'POWER_REQUIRED') {
      return (
        <ButtonOwnDays
          fontClass="od-font-reg"
          fontSize="14px"
          mode="oval-grey"
          px="40px"
          py="16px"
          text={SUBMIT_PRESCRIPTION}
          width="100%"
          onClick={() => handleSelectedItem(item)}
        />
      );
    } else if (item?.powerRequired === 'POWER_SUBMITTED') {
      return (
        <div className="od-payment-success-d-prescription-card__status od-font-bold">
          file uploaded
        </div>
      );
    }
    return (
      <div className="od-payment-success-d-prescription-card__status od-font-bold">
        No Prescription required
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <SubmitPrescription
          isOpen={isOpen}
          orderId={orderId}
          orderItem={selectedItem}
          setUuid={setUuid}
          onClosePopup={onClosePopup}
        />
      )}
      <div className={`${mainClass}`}>
        <div className={`${mainClass}-left`}>
          <div className={`${mainClass}-left__title od-font-bold`}>Order summary</div>
          <div className={`${mainClass}-banner`}>
            <div className={`${mainClass}-banner__title od-font-bold`}>
              Order placed{' '}
              <span aria-label="success" role="img">
                🎉
              </span>{' '}
              Just one more step
            </div>
            <div className={`${mainClass}-banner__description od-font-reg`}>
              {ORDER_CONFIRMATION_SUBTITLE}
            </div>
            <div
              className={`${mainClass}-banner__btn`}
              onClick={() => {
                window.location.href = '/sg/en/customer/account';
              }}
            >
              <svg
                fill="none"
                height="16"
                viewBox="0 0 16 16"
                width="16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6676 5.99967V3.99967C10.6676 2.52692 9.47366 1.33301 8.0009 1.33301C6.52814 1.33301 5.33423 2.52692 5.33423 3.99967V5.99967M2.39557 6.90098L1.99557 11.1676C1.88183 12.3808 1.82497 12.9874 2.02626 13.4559C2.2031 13.8674 2.51298 14.2077 2.90624 14.4222C3.35391 14.6663 3.96314 14.6663 5.1816 14.6663H10.8202C12.0387 14.6663 12.6479 14.6663 13.0956 14.4222C13.4888 14.2077 13.7987 13.8674 13.9755 13.4559C14.1768 12.9874 14.12 12.3808 14.0062 11.1677L13.6062 6.90099C13.5102 5.87657 13.4622 5.36437 13.2318 4.97712C13.0289 4.63606 12.7291 4.36308 12.3706 4.1929C11.9636 3.99968 11.4491 3.99968 10.4202 3.99968L5.5816 3.99967C4.55269 3.99967 4.03824 3.99967 3.63118 4.1929C3.27267 4.36308 2.9729 4.63606 2.77 4.97712C2.53962 5.36436 2.4916 5.87657 2.39557 6.90098Z"
                  stroke="#546073"
                  strokeLinecap="square"
                />
              </svg>
              <span className="od-font-reg">{VIEW_ORDER}</span>
            </div>
          </div>

          <div className={`${mainClass}-next od-font-bold`}>
            <div className={`${mainClass}-next__title od-font-bold`}>
              {isPaymentSuccess
                ? 'What’s the next step?'
                : '⚠️ Action needed: Complete your payment '}
            </div>
            <div className={`${mainClass}-next__description od-font-reg`}>
              {isPaymentSuccess ? (
                YOUR_ORDER_CONTAINS_ITEMS_REQUIRE_PRESCRIPTION
              ) : (
                <>
                  <span className="od-font-reg">
                    Oops! it looks like your payment didn’t go through. but don’t worry your order
                    is placed successfully and you can
                  </span>
                  <span className="od-font-bold"> complete your payment within 48 hours</span>
                </>
              )}
            </div>
            <div className={`${mainClass}-next__btns`}>
              <ButtonOwnDays
                fontClass="od-font-reg"
                fontSize="16px"
                mode="oval-grey"
                px="40px"
                py="18px"
                style={{ gap: '16px' }}
                onClick={handleContinueShopping}
              >
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.334 7.50033V5.00033C13.334 3.15938 11.8416 1.66699 10.0006 1.66699C8.15968 1.66699 6.6673 3.15938 6.6673 5.00033V7.50032M2.99397 8.62696L2.49397 13.9603C2.35181 15.4767 2.28072 16.2349 2.53234 16.8206C2.75338 17.335 3.14073 17.7604 3.63232 18.0285C4.1919 18.3337 4.95344 18.3337 6.47651 18.3337H13.5248C15.0478 18.3337 15.8094 18.3337 16.3689 18.0285C16.8605 17.7604 17.2479 17.335 17.4689 16.8206C17.7205 16.2349 17.6495 15.4767 17.5073 13.9603L17.0073 8.62696C16.8872 7.34645 16.8272 6.70619 16.5392 6.22213C16.2856 5.79581 15.9109 5.45458 15.4628 5.24186C14.954 5.00033 14.3109 5.00033 13.0248 5.00033L6.97651 5.00033C5.69037 5.00033 5.04731 5.00033 4.53848 5.24186C4.09035 5.45458 3.71564 5.79581 3.46202 6.22213C3.17404 6.70619 3.11402 7.34645 2.99397 8.62696Z"
                    stroke="white"
                    strokeLinecap="square"
                  />
                </svg>
                <span>{CONTINUE_SHOPPING}</span>
              </ButtonOwnDays>
              <div className={`${mainClass}-next__btns__divider od-font-reg`}>or</div>
              <button className={`${mainClass}-next__btn od-font-reg`}>
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 20 20"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.33268 12.1126C9.36063 11.5185 10.638 11.5185 11.6659 12.1126M1.66602 12.5003L2.25059 6.65459C2.27275 6.43295 2.28383 6.32213 2.30083 6.22706C2.5012 5.10617 3.43368 4.26229 4.56895 4.17444C4.66524 4.16699 4.77661 4.16699 4.99935 4.16699M18.3327 12.5003L17.7481 6.65459C17.7259 6.43296 17.7149 6.32213 17.6979 6.22706C17.4975 5.10617 16.565 4.26229 15.4298 4.17444C15.3335 4.16699 15.2221 4.16699 14.9993 4.16699M7.35637 10.1433C8.65812 11.445 8.65812 13.5556 7.35637 14.8574C6.05463 16.1591 3.94408 16.1591 2.64232 14.8574C1.34058 13.5556 1.34058 11.4451 2.64232 10.1433C3.94407 8.84156 6.05462 8.84156 7.35637 10.1433ZM17.3564 10.1433C18.6581 11.445 18.6581 13.5556 17.3564 14.8574C16.0546 16.1591 13.9441 16.1591 12.6423 14.8574C11.3406 13.5556 11.3406 11.4451 12.6423 10.1433C13.9441 8.84156 16.0546 8.84156 17.3564 10.1433Z"
                    stroke="#546073"
                    strokeLinecap="square"
                  />
                </svg>
                <span>Submit prescriptions on the right</span>
              </button>
            </div>
          </div>
        </div>

        <div className={`${mainClass}-prescriptions`}>
          <div className={`${mainClass}-prescriptions__title od-font-bold`}>
            {SUBMIT_PRESCRIPTION}
          </div>
          {/* {orderDetail?.items?.length > 0 &&
            orderDetail?.items.map((item, index) => ( */}
          {ordersList &&
            ordersList.length > 0 &&
            ordersList[0]?.items.length > 0 &&
            ordersList[0]?.items?.map((item, index) => (
              <div key={index} className="od-payment-success-d-prescription-card">
                <div className="od-payment-success-d-prescription-card__thumbnail-wrapper">
                  <img
                    alt={item?.sku}
                    className="od-payment-success-d-prescription-card__thumbnail"
                    src={item?.image}
                  />
                </div>
                <div className="od-payment-success-d-prescription-card__details">
                  <div className="od-payment-success-d-prescription-card__order-no od-font-reg">
                    {ORDER_NO}: {ordersList[0].id}
                  </div>
                  <div className="od-payment-success-d-prescription-card__name od-font-reg">
                    {item?.name}
                  </div>
                  {item.options?.[1]?.type === 'COATING' && (
                    <div className="od-payment-success-d-prescription-card__coating od-font-reg">
                      {item.options?.[1].label}
                    </div>
                  )}
                  <div className="od-payment-success-d-prescription-card__divider" />
                  {showPrescriptionStatus(item)}
                  {/* {item?.powerRequired === 'POWER_REQUIRED' ? (
                    <ButtonOwnDays
                      classes="od-payment-success-d-prescription-card__button"
                      fontClass="od-font-bold"
                      onClick={() => handleSelectedItem(item)}
                    >
                      {SUBMIT_PRESCRIPTION}
                    </ButtonOwnDays>
                  ) : item?.powerRequired === 'POWER_SUBMITTED' ? (
                    <div className="od-payment-success-d-prescription-card__status od-font-bold">
                      file uploaded
                    </div>
                  ) : (
                    <div className="od-payment-success-d-prescription-card__status od-font-bold">
                      No Prescription required
                    </div>
                  )} */}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
