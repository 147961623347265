import React, { Fragment, useEffect } from 'react';

import { MainButtons } from './MainButtons';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import './HeaderOwndaysSnap.scss';

export const HeaderOwndaysSnap = ({ baseImgUrl }) => {
  const mainAlt =
    'Be it indoors or outdoors Transform in a heartbeat with just a simple snap.OWNDAYS SNAP';

  const conceptTitle = (
    <Fragment>
      Spectacles to{' '}
      <span className="concept-owndays-snap__messages-layout__text-box__title--blue">
        sunglasses
      </span>
      <br />
      in a{' '}
      <span className="concept-owndays-snap__messages-layout__text-box__title--green">
        split second
      </span>
    </Fragment>
  );

  const conceptItems = [
    {
      pcImage: `${baseImgUrl}/concept-item-pc-01.webp`,
      spImage: `${baseImgUrl}/concept-item-sp-01.webp`,
      alt: 'Convenient 2-in-1 design',
      title: (
        <Fragment>
          Convenient <span className="concept-owndays-snap__item__title--blue">2-in-1 </span>design
        </Fragment>
      ),
      description: 'With built-in magnets to transform the spectacles into sunglasses effortlessly',
    },
    {
      pcImage: `${baseImgUrl}/concept-item-pc-02.webp`,
      spImage: `${baseImgUrl}/concept-item-sp-02.webp`,
      alt: 'Fitted with polarised lenses',
      title: (
        <Fragment>
          Fitted with{' '}
          <span className="concept-owndays-snap__item__title--green">polarised lenses </span>
        </Fragment>
      ),
      description:
        'SNAP LENS is fitted with polarised lenses to give you the most comfortable visual experience outdoors',
    },
    {
      pcImage: `${baseImgUrl}/concept-item-pc-03.webp`,
      spImage: `${baseImgUrl}/concept-item-sp-03.webp`,
      alt: 'Over 99% UV protection',
      title: (
        <Fragment>
          Over <span className="concept-owndays-snap__item__title--blue">99% UV protection</span>
        </Fragment>
      ),
      description: 'Stylish and functional, SNAP LENS blocks both UV rays and glare',
    },
  ];

  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news#all', label: 'News' },
    { path: '#', label: 'OWNDAYS SNAP' },
  ];

  useEffect(() => {
    // Initialize Fancybox
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-owndays-snap">
        <div className="header-owndays-snap__layout">
          <div
            className="base-owndays-snap__action-left"
            data-aos="no-animation"
            data-aos-delay="1000"
          >
            <picture className="header-owndays-snap__image">
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/main-pc.webp`}
                type="image/webp"
              />
              <img
                alt={mainAlt}
                className="lazyload header-owndays-snap__image"
                height="610"
                src={`${baseImgUrl}/main-sp.webp`}
                width="1320"
              />
            </picture>
          </div>

          <img
            alt="OWNDAYS SNAP"
            className="header-owndays-snap__ob-y lazyload base-owndays-snap__action-left"
            data-aos="no-animation"
            data-aos-delay="1600"
            height="500"
            src={`${baseImgUrl}/main-ob-y.webp`}
            width="253"
          />
          <img
            alt="OWNDAYS SNAP"
            className="header-owndays-snap__ob-x lazyload base-owndays-snap__action-right"
            data-aos="no-animation"
            data-aos-delay="1800"
            height="420"
            src={`${baseImgUrl}/main-ob-x.webp`}
            width="370"
          />

          <div
            className="header-owndays-snap__messages base-owndays-snap__action-left"
            data-aos="no-animation"
            data-aos-delay="1500"
          >
            <picture className="header-owndays-snap__messages__logo">
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/main-logo-pc.webp`}
                type="image/webp"
              />
              <img
                alt={mainAlt}
                className="lazyload"
                height="70"
                src={`${baseImgUrl}/main-logo-sp.webp`}
                width="180"
              />
            </picture>

            <div className="header-owndays-snap__messages__tag">
              <h3 className="header-owndays-snap__messages__tag--black">
                Be it indoors or outdoors
              </h3>
              <h4 className="header-owndays-snap__messages__tag--white">
                Transform in a heartbeat with just a simple snap
              </h4>
            </div>
          </div>
        </div>
      </section>

      <section className="concept-owndays-snap">
        <div className="concept-owndays-snap__bg">
          <video autoPlay loop muted playsInline>
            <source src={`${baseImgUrl}/OWNDAYSSNAP-2024.mp4`} type="video/mp4" />
          </video>
        </div>

        <div className="concept-owndays-snap__layout">
          <div className="concept-owndays-snap__messages-layout">
            <div className="concept-owndays-snap__messages-layout__text-box">
              <h1 className="concept-owndays-snap__messages-layout__text-box__title concept-owndays-snap__messages-layout__text-box__title--pc">
                {conceptTitle}
              </h1>
              <p className="concept-owndays-snap__messages-layout__text-box__description">
                Transform the spectacles into sunglasses instantly by snapping on the SNAP LENS to
                the base frame. Besides, SNAP LENS is fitted with polarised lenses that can reduce
                glare from shiny surfaces such as the road and water surface to give you a more
                comfortable vision.
              </p>

              <ul>
                <li>Comes in a bundled set consisting of a base frame and a SNAP LENS</li>
                <li>Changing of SNAP LENS colour is not available for bundled set</li>
              </ul>
            </div>

            <a data-fancybox="etc" href="https://youtu.be/8AzPC4c2Cus">
              <div className="concept-owndays-snap__messages-layout__vdo">
                <picture>
                  <source
                    media="(min-width:768px)"
                    srcSet={`${baseImgUrl}/concept-vdo-pc.webp`}
                    type="image/webp"
                  />
                  <img
                    alt={mainAlt}
                    className="lazyload concept-owndays-snap__messages-layout__preview-vdo"
                    height="70"
                    src={`${baseImgUrl}/concept-vdo-pc.webp`}
                    width="180"
                  ></img>
                </picture>

                <img
                  alt="SNAP concept video"
                  className="lazyload concept-owndays-snap__messages-layout__play-icon"
                  height="80"
                  src={`${baseImgUrl}/play-icon.svg`}
                  width="80"
                />

                <div className="concept-owndays-snap__messages-layout__bg-vdo"></div>
              </div>
            </a>

            <h1 className="concept-owndays-snap__messages-layout__text-box__title concept-owndays-snap__messages-layout__text-box__title--sp">
              {conceptTitle}
            </h1>
          </div>
          <div className="concept-owndays-snap__card">
            <ul className="concept-owndays-snap__list">
              {conceptItems.map((item, index) => {
                return (
                  <li key={index} className="concept-owndays-snap__item">
                    <picture
                      className="concept-owndays-snap__item__image base-owndays-snap__action-left"
                      data-aos="no-animation"
                    >
                      <source media="(min-width:768px)" srcSet={item.pcImage} type="image/webp" />
                      <img
                        alt={item.alt}
                        className="lazyload"
                        height="70"
                        src={item.spImage}
                        width="180"
                      />
                    </picture>

                    <div>
                      <h4 className="concept-owndays-snap__item__title">{item.title}</h4>
                      <p className="concept-owndays-snap__item__description">{item.description}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="concept-owndays-snap__buttons--top">
            <MainButtons />
          </div>
        </div>
      </section>
    </Fragment>
  );
};
