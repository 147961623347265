import React, { useEffect } from 'react';
import './SearchBarNascent.scss';

import SearchBarResults from '../SearchBarResults/SearchBarResults';

import { useSelector } from 'react-redux';

const AutoComplete = ({
  placeholder,
  handleBlur,
  handleFocus,
  onKeyDown,
  handleKeyUp,
  inputRef,
}) => (
  <div>
    <input
      ref={inputRef}
      className="search-bar-nascent__input od-font-reg"
      placeholder={placeholder}
      type="text"
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={onKeyDown}
      onKeyUp={event => handleKeyUp(event)}
    />
  </div>
);

const SearchBarNascent = props => {
  const {
    showInput,
    classes,
    placeholder,
    displayInput,
    inputRef,
    handleBlur,
    handleFocus,
    handleKeyUp,
    handleKeyDown,
    getSearchResult,
    showAllResults,
    showSearchResult,
    showTrending,
    secondaryNav,
  } = props;

  const searchData = useSelector(state => state.search.searchData);
  const searchDataQuery = useSelector(state => state.search.query);

  useEffect(() => {
    if (searchData) {
      if (searchData.webUrl) {
        window.location.href = `/sg/en/${searchData.webUrl}`;
      } else if (searchDataQuery) {
        window.location.href = `/sg/en/search?q=${searchDataQuery}`;
      }
    }
  }, [inputRef, searchData, searchDataQuery]);

  return (
    <div
      className={`search-bar-nascent ${showInput ? 'search-bar-nascent--active' : ''} ${classes}`}
    >
      <div className="search-bar-nascent__layout">
        {showInput && (
          <AutoComplete
            handleBlur={handleBlur}
            handleFocus={handleFocus}
            handleKeyUp={handleKeyUp}
            inputRef={inputRef}
            placeholder={placeholder}
            onKeyDown={event => handleKeyDown(event)}
          />
        )}
        {showInput ? (
          <img
            alt="true"
            className="search-bar-nascent__icon"
            src="https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg"
            onClick={() => getSearchResult(inputRef?.current?.value)}
          />
        ) : (
          <img
            alt="true"
            className={`search-bar-nascent__icon ${
              secondaryNav || !showInput ? 'search-bar-nascent__icon--secondary' : ''
            }`}
            src={
              secondaryNav
                ? 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-search-white-icon.svg'
                : 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/search-md.svg'
            }
            onClick={displayInput}
          />
        )}
      </div>

      <SearchBarResults
        getSearchResult={getSearchResult}
        showAllResults={showAllResults}
        showSearchResult={showSearchResult}
        showTrending={showTrending}
      />
    </div>
  );
};

export default SearchBarNascent;
