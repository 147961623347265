import React, { useEffect, useState } from 'react';
import SideBarPopup from '../../../desktop/components/SideBarPopup/SideBarPopup';
import './SubmitPrescription.scss';
import KnowMyPower from '../../../../static/assets/svg/know-my-power.svg';
import ChevronRight from '../../../../static/assets/img/chevron-right.png';
import ChevronDown from '../../../../static/assets/img/chevron-down.png';
import ChevronUp from '../../../../static/assets/img/chevron-up.png';
import LeftIcon from '../../../../static/assets/svg/od-arrow-left-grey-icon.svg';
import CrossIcon from '../../../../static/assets/svg/Cross.svg';
import CheckedIcon from '../../../../static/assets/svg/od-checked.svg';
import OdCircleIcon from '../../../../static/assets/svg/od-circle-icon.svg';
import OdCheckCircleFilledIcon from '../../../../static/assets/svg/od-check-circle-filled-icon.svg';
import UnCheckedIcon from '../../../../static/assets/svg/od-unchecked.svg';
import ButtonOwnDays from '../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { setToastMessage } from '../../../actionCreators/common';
import { useDispatch, useSelector } from 'react-redux';
import apiClient from '../../../helpers/apiClient';
import { apiPath } from '../../../config';
import ProductImage from '../../ProductCard/ProductImage/ProductImage';
import { v4 } from 'uuid';
import FadeOD from '../../views/desktop/AuthModalOD/FadeOD/FadeOD';
import UserInputErrorOD from '../../views/desktop/AuthModalOD/UserInputErrorOD/UserInputErrorOD';
import { odPushClevertapEvent } from '../../../utils/clevertap';

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

const screensKey = {
  submitPrescription: 'submit-prescription',
  savedPrescription: 'saved-prescription',
  uploadPrescription: 'upload-prescription',
};

const submitPrescriptionActions = {
  usePower: 'use-power',
  uploadPrescription: 'upload-prescription',
};

const screens = {
  submitPrescription: {
    key: screensKey.submitPrescription,
    title: 'Submit Prescription',
  },
  savedPrescription: {
    key: screensKey.savedPrescription,
    title: 'Saved power',
  },
  uploadPrescription: {
    key: screensKey.uploadPrescription,
    title: 'Upload your prescription details',
  },
};

const SubmitPrescription = ({
  isOpen,
  onClosePopup,
  orderItem,
  orderId,
  addNewPrescription = false,
  hideSavedPowerOption = false,
  setUuid,
}) => {
  const [openIKnowPower, setOpenIKnowPower] = useState(true);
  // const [dontKnowPower, setDontKnowPower] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(screens.submitPrescription);
  const [savedPowers, setSavedPowers] = useState([]);
  const [selectedSavedPowers, setSelectedSavedPowers] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [selectedSubmitPrescriptionAction, setSelectedSubmitPrescriptionAction] = useState(null);
  const [uploadfileError, setUploadfileError] = useState(null);
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.common.userInfo);
  const userEmail = userInfo?.result?.email;
  const userName = userInfo?.result?.firstName;
  const userPhone = userInfo?.result?.telephone;
  const userPhoneCode = userInfo?.result?.phoneCode;

  const { CONTINUE, CURRENCY_SYMBOL, CURRENCY_CODE } = useSelector(state => state?.locale);

  useEffect(() => {
    console.log('this is common state ===>', userEmail, userPhone, userName, userInfo);
  }, [userPhone, userName, userEmail]);

  useEffect(() => {
    if (currentScreen.key === screensKey.savedPrescription) {
      const client = apiClient();
      client.setHeader();
      client
        .get(`${apiPath.getSavedPrescription}`)
        .then(res => {
          if (Number(res?.status) === 200) {
            console.log('this is saved prescriptions....', res.result);
            if (res?.result?.length > 0) {
              setSavedPowers(res?.result);
            }
          }
        })
        .catch(() => {
          setSavedPowers([]);
        });
    } else {
      setSavedPowers([]);
      setSelectedSavedPowers(null);
    }
  }, [currentScreen]);

  const handleAddNewPower = () => {
    setCurrentScreen(screens.uploadPrescription);
    setSelectedFile(null);
    setFileUploading(false);
  };

  const uploadPrescriptionForItem = uploadFileRes => {
    if (addNewPrescription) {
      const imageFileName = uploadFileRes?.result?.filename || uploadFileRes?.imageFileName;
      if (imageFileName) {
        const client = apiClient();
        client.setHeader();
        client
          .post(`${apiPath.savePrescription}`, {
            powerType: 'SINGLE_VISION',
            showPd: true,
            userName,
            imageFileName,
            source: 'WEB',
            power_updated_by: userEmail,
            customerEmail: userEmail,
            customerPhone: userPhone,
            phoneCode: userPhoneCode,
          })
          .then(res => {
            if (Number(res?.status) === 200) {
              odPushClevertapEvent('Power Submitted', {
                orderId,
              });

              setFileUploading(false);
              onClosePopup();
              if (setUuid) {
                setUuid();
              }
              console.log('this is after uploading file for order ===>>>> new', res.result);
            }
          })
          .catch(() => {
            setFileUploading(false);
          });
      }
    } else {
      const imageFileName = uploadFileRes?.result?.filename || uploadFileRes?.imageFileName;
      if (imageFileName) {
        const client = apiClient();
        client.setHeader();
        client
          .put(
            `${apiPath.getOrders}/${orderId}/items/${orderItem?.id}/prescription?email=${userEmail}`,
            {
              powerType: 'SINGLE_VISION',
              showPd: true,
              userName,
              imageFileName,
              source: 'WEB',
              power_updated_by: userEmail,
            }
          )
          .then(res => {
            if (Number(res?.status) === 200) {
              setFileUploading(false);
              onClosePopup();
              if (setUuid) {
                setUuid();
              }
              console.log('this is after uploading file for order ===>>>>', res.result);
            }
          })
          .catch(() => {
            setFileUploading(false);
          });
      }
    }
  };

  const handleUseThisPower = () => {
    if (selectedSavedPowers) {
      //  do something
      console.log('this is selected saved powers ===>', selectedSavedPowers);
      uploadPrescriptionForItem(selectedSavedPowers);
    }
  };

  useEffect(() => {
    console.log('this is selected fileeeee ===>', selectedFile);
  }, [selectedFile]);

  const handlePrescriptionUpload = e => {
    if (e.target.files) {
      const prescriptionUpload = e.target.files[0];
      const allowedFormats = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!allowedFormats.includes(prescriptionUpload.type)) {
        setUploadfileError('Invalid file format. Please upload a JPEG, PNG, or PDF file.');
        setSelectedFile(null);
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024;
      if (prescriptionUpload.size > maxSizeInBytes) {
        setUploadfileError('File size exceeds the maximum limit of 5 MB.');
        setSelectedFile(null);
        return;
      }

      setUploadfileError(null);
      setSelectedFile(e.target.files[0]);
    }
  };

  const submitUploadFile = () => {
    if (selectedFile) {
      setFileUploading(true);
      const client = apiClient();
      client.setHeader();
      const formData = new FormData();
      formData.append('myfile', selectedFile);
      client
        .post(`${apiPath.uploadprescfile}`, formData)
        .then(res => {
          if (Number(res?.result?.code) === 200) {
            // save file in state...
            dispatch(
              setToastMessage({
                message: res?.result?.message,
                timeout: 3000,
              })
            );

            // upload prescription for item
            uploadPrescriptionForItem(res);
          }
        })
        .catch(err => {
          setFileUploading(false);
          dispatch(
            setToastMessage({
              warning: true,
              message: err?.message || 'Something went wrong',
              timeout: 3000,
            })
          );
          // setSavedPowers([]);
        });
    }
  };

  const handleKnowMyPowerAction = () => {
    if (selectedSubmitPrescriptionAction === submitPrescriptionActions.uploadPrescription) {
      setCurrentScreen(screens.uploadPrescription);
      setSelectedFile(null);
      setFileUploading(false);
      setSelectedSubmitPrescriptionAction(null);
    } else if (selectedSubmitPrescriptionAction === submitPrescriptionActions.usePower) {
      setCurrentScreen(screens.savedPrescription);
      setSelectedFile(null);
      setFileUploading(false);
      setSelectedSubmitPrescriptionAction(null);
    }
  };

  const renderSubmitPrescription = () => {
    return (
      <>
        <div className="upload-prescription-sidebar__collapaseMenu">
          <div className="upload-prescription-sidebar__collapaseMenu-title">
            <div>
              <img alt="know my power" src={KnowMyPower} /> I know my power
            </div>
            <img
              alt="chevron"
              src={openIKnowPower ? ChevronUp : ChevronDown}
              width="20px"
              onClick={() => setOpenIKnowPower(!openIKnowPower)}
            />
          </div>
          <div className="upload-prescription-sidebar__collapaseMenu-body">
            {openIKnowPower && (
              <>
                {!hideSavedPowerOption && (
                  <div
                    className="upload-prescription-sidebar__collapaseMenu-body-item"
                    onClick={() => {
                      setSelectedSubmitPrescriptionAction(submitPrescriptionActions.usePower);
                    }}
                  >
                    <div>Use saved power</div>
                    <img
                      alt="unchecked Icon"
                      src={
                        selectedSubmitPrescriptionAction === submitPrescriptionActions.usePower
                          ? CheckedIcon
                          : UnCheckedIcon
                      }
                      width="24px"
                    />
                  </div>
                )}
                <div
                  className="upload-prescription-sidebar__collapaseMenu-body-item"
                  onClick={() => {
                    setSelectedSubmitPrescriptionAction(
                      submitPrescriptionActions.uploadPrescription
                    );
                  }}
                >
                  <div>Upload a prescription</div>
                  <img
                    alt="unchecked Icon"
                    src={
                      selectedSubmitPrescriptionAction ===
                      submitPrescriptionActions.uploadPrescription
                        ? CheckedIcon
                        : UnCheckedIcon
                    }
                    width="24px"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="upload-prescription-sidebar__footer">
          {orderItem && orderItem.price.value && (
            <div className="upload-prescription-sidebar__footer__price">
              Total: <span>{`${CURRENCY_CODE}${CURRENCY_SYMBOL}${orderItem.price.value}`}</span>
            </div>
          )}
          <ButtonOwnDays
            noHover
            disabled={!selectedSubmitPrescriptionAction}
            fontClass="od-font-bold"
            fontSize="16px"
            mode="grey"
            px="20px"
            py="12px"
            style={!orderItem && { margin: '0 auto' }}
            width="320px"
            onClick={handleKnowMyPowerAction}
          >
            {CONTINUE}
          </ButtonOwnDays>
        </div>
      </>
    );
  };

  const renderSavedPower = () => {
    return (
      <div className="saved-prescription-sidebar-panel">
        {savedPowers?.length > 0 &&
          savedPowers.map(power => (
            <div
              key={v4()}
              className="saved-prescription-radio"
              onClick={() => setSelectedSavedPowers(power)}
            >
              <div className="saved-prescription-radio__left">
                <div className="saved-prescription-radio__left_name">
                  {power?.imageFileName || power?.userName}
                </div>
                <div className="saved-prescription-radio__left_date">
                  Created on {formatUnixTimestamp(power?.createdAt)}
                </div>
              </div>
              <div className="saved-prescription-radio__right">
                <img
                  alt="radio button"
                  className="saved-prescription-radio__right_check_uncheck"
                  src={
                    selectedSavedPowers?.id === power?.id ? OdCheckCircleFilledIcon : OdCircleIcon
                  }
                />
              </div>
            </div>
          ))}
        <div className="saved-prescription-sidebar-panel__footer">
          <ButtonOwnDays
            noHover
            disabled={fileUploading}
            fontClass="od-font-bold"
            fontSize="16px"
            px="20px"
            py="12px"
            style={{
              fontWeight: 400,
              border: 'none',
              backgroundColor: '#fafafa',
              color: '#546073',
            }}
            width="320px"
            onClick={handleAddNewPower}
          >
            Add new power
          </ButtonOwnDays>
          <ButtonOwnDays
            noHover
            disabled={!selectedSavedPowers || fileUploading}
            fontClass="od-font-bold"
            fontSize="16px"
            mode="grey"
            px="20px"
            py="12px"
            width="320px"
            onClick={handleUseThisPower}
          >
            Use this power
          </ButtonOwnDays>
        </div>
      </div>
    );
  };

  const renderUploadPrescription = () => {
    return (
      <div className="upload-prescription-sidebar-panel">
        <ul className="upload-prescription-sidebar-panel__list">
          <li className="upload-prescription-sidebar-panel__list_item">
            PDF, JPEG, PNG formats accepted
          </li>
          <li className="upload-prescription-sidebar-panel__list_item">
            Make sure your file size under 5 MB
          </li>
        </ul>
        {selectedFile && (
          <div className="upload-prescription-sidebar-panel__img">
            <img alt="Selected File" src={URL.createObjectURL(selectedFile)} />
          </div>
        )}
        {uploadfileError && (
          <FadeOD show={uploadfileError}>
            <UserInputErrorOD message={uploadfileError} />
          </FadeOD>
        )}
        <div className="upload-prescription-sidebar-panel__uploadBtn">
          <ButtonOwnDays
            noHover
            classes="hto-hero__button"
            fontClass="od-font-bold"
            fontSize="16px"
            px="24px"
            py="16px"
            onClick={() => document.getElementById('fileInput').click()}
          >
            <input
              id="fileInput"
              style={{ display: 'none' }}
              type="file"
              onChange={handlePrescriptionUpload}
            />
            {selectedFile ? 'Change file' : 'Upload'}
            <img
              alt="upload file"
              src={ChevronRight}
              style={{ marginBottom: 2, marginLeft: 10 }}
              width={18}
            />
          </ButtonOwnDays>
        </div>
        <div className="upload-prescription-sidebar-panel__footer">
          <ButtonOwnDays
            noHover
            disabled={!selectedFile || fileUploading}
            fontClass="od-font-bold"
            fontSize="16px"
            mode="grey"
            px="20px"
            py="12px"
            width="320px"
            onClick={submitUploadFile}
          >
            {CONTINUE}
          </ButtonOwnDays>
        </div>
      </div>
    );
  };

  const renderHeaderTitle = () => {
    if (currentScreen.key === screensKey.submitPrescription) {
      return 'Back';
    }

    if (currentScreen.key !== screensKey.submitPrescription) {
      if (orderId) {
        return (
          <div className="upload-prescription-sidebar__heading__title">{currentScreen.title}</div>
        );
      }
    }

    return <div className="upload-prescription-sidebar__heading__title">{currentScreen.title}</div>;
  };

  return (
    <SideBarPopup
      crossIconStyle="upload-prescription-sidebar__cross"
      dialogClassName="upload-prescription-sidebar"
      open={isOpen}
      width="768px"
      onClose={onClosePopup}
    >
      <div className="upload-prescription-sidebar__heading">
        <div className="upload-prescription-sidebar__heading__back-btn">
          <img
            alt="back"
            src={LeftIcon}
            onClick={() => {
              if (currentScreen.key !== screensKey.submitPrescription) {
                setCurrentScreen(screens.submitPrescription);
                setSelectedFile(null);
                setFileUploading(false);
              } else {
                onClosePopup();
              }
            }}
          />
          <div className="upload-prescription-sidebar__heading__back-btn__text">
            {renderHeaderTitle()}
          </div>
        </div>
        <div
          className="upload-prescription-sidebar__heading__close"
          onClick={() => {
            onClosePopup();
          }}
        >
          <img alt="back" src={CrossIcon} />
        </div>
      </div>
      {currentScreen.key === screensKey.submitPrescription && (
        <div className="upload-prescription-sidebar__title">{currentScreen.title}</div>
      )}
      {currentScreen.key !== screensKey.submitPrescription && orderId && (
        <div className="upload-prescription-sidebar__order-id">Order No : {orderId}</div>
      )}
      {currentScreen.key === screensKey.submitPrescription && orderItem && (
        <div className="upload-prescription-sidebar__product">
          <ProductImage alt={orderItem?.description} src={orderItem?.image} />
          <div className="upload-prescription-sidebar__product__info">
            <div className="upload-prescription-sidebar__product__info__order-id">
              Order No : {orderId}
            </div>
            <div className="upload-prescription-sidebar__product__info__title">
              {orderItem?.brandName}
            </div>
            <div className="upload-prescription-sidebar__product__info__description">
              {orderItem?.name}
            </div>
          </div>
        </div>
      )}
      <div className="upload-prescription-sidebar__content">
        {currentScreen.key === screensKey.submitPrescription && renderSubmitPrescription()}
        {currentScreen.key === screensKey.savedPrescription && renderSavedPower()}
        {currentScreen.key === screensKey.uploadPrescription && renderUploadPrescription()}
        {/* <div className="upload-prescription-sidebar__collapaseMenu">
          <div className="upload-prescription-sidebar__collapaseMenu-title">
            <div>
              {' '}
              <img alt="dont know my power" src={DontKnowMyPower} />I don't know my power
            </div>
            <img
              alt="chevron"
              src={dontKnowPower ? ChevronUp : ChevronDown}
              onClick={() => setDontKnowPower(!dontKnowPower)}
            />
          </div>
          <div className="upload-prescription-sidebar__collapaseMenu-body">
            {dontKnowPower && (
              <>
                <div className="upload-prescription-sidebar__collapaseMenu-body-item">
                  <div>Use saved power</div>
                  <img alt="chevron" src={ChevronRight} />
                </div>
                <div className="upload-prescription-sidebar__collapaseMenu-body-item">
                  <div>Upload a prescription</div>
                  <img alt="chevron" src={ChevronRight} />
                </div>
              </>
            )}
          </div>
        </div> */}
      </div>
    </SideBarPopup>
  );
};

export default SubmitPrescription;
