// redis

export const GET_REDIS_ROUTER_LOAD = 'common/GET_REDIS_ROUTER_LOAD';
export const GET_REDIS_ROUTER_SUCCESS = 'common/GET_REDIS_ROUTER_SUCCESS';
export const GET_REDIS_ROUTER_FAIL = 'common/GET_REDIS_ROUTER_FAIL';
export const SET_COUNTRY_INFO = 'common/SET_COUNTRY_INFO';
// redis common data
export const GET_REDIS_DATA = 'common/GET_REDIS_DATA';
export const GET_REDIS_SUCCESS = 'common/GET_REDIS_SUCCESS';
export const GET_REDIS_FAIL = 'common/GET_REDIS_FAIL';

// sharktank common data
export const GET_SHARKTANK_DATA = 'common/GET_SHARKTANK_DATA';
export const GET_SHARKTANK_SUCCESS = 'common/GET_SHARKTANK_SUCCESS';
export const GET_SHARKTANK_FAIL = 'common/GET_SHARKTANK_FAIL';

// User actions
export const VALIDATE_SESSION = 'common/VALIDATE_SESSION';
export const GET_USER = 'common/GET_USER';
export const GET_USER_LOAD = 'common/GET_USER_LOAD';
export const GET_USER_SUCCESS = 'common/GET_USER_SUCCESS';
export const GET_USER_FAIL = 'common/GET_USER_FAIL';
export const LOGIN_SUCCESS = 'common/LOGIN_SUCCESS';

// juno actions
export const LOAD_JUNO_SESSION = 'common/LOAD_JUNO_SESSION';
export const LOAD_JUNO_SESSION_API_SUCCESS = 'common/LOAD_JUNO_SESSION_API_SUCCESS';
export const LOAD_JUNO_SESSION_API_FAIL = 'common/LOAD_JUNO_SESSION_API_FAIL';

// short list
export const SHORTLIST_LOAD = 'common/SHORTLIST_LOAD';
export const SHORTLIST_SUCCESS = 'common/SHORTLIST_SUCCESS';
export const SHORTLIST_FAIL = 'common/SHORTLIST_FAIL';
export const WISHLIST_TRUE = 'common/WISHLIST_TRUE';
export const SHORTLIST_TYPE = 'common/SHORTLIST_TYPE';
export const SHORTLIST_DELETE = 'common/SHORTLIST_DELETE';
export const SHORTLIST_DELETE_SUCCESS = 'common/SHORTLIST_DELETE_SUCCESS';
export const SHORTLIST_ADD = 'common/SHORTLIST_ADD';
export const SHORTLIST_ADD_SUCCESS = 'commom/SHORTLIST_ADD_SUCCESS';

// common cart action
export const CART_POPUP = 'CART_POPUP';
export const UPDATE_CART_COUNT = 'UPDATE_CART_COUNT';

export const CHANGE_SECTION = 'commom/CHANGE_SECTION';
export const MODIFY_DATA = 'commom/MODIFY_DATA';

// ditto profile
export const DITTO_PROFILE_LOAD = 'common/DITTO_PROFILE_LOAD';
export const DITTO_PROFILE_SUCCESS = 'common/DITTO_PROFILE_SUCCESS';
export const DITTO_PROFILE_FAILURE = 'common/DITTO_PROFILE_FAILURE';
export const DITTO_DEFAULT_SET = 'common/DITTO_DEFAULT_SET';
export const DITTO_DEFAULT_SUCCESS = 'common/DITTO_DEFAULT_SUCCESS';
export const DITTO_DEFAULT_FAILURE = 'common/DITTO_DEFAULT_FAILURE';
export const DITTO_NAME_SAVE = 'common/DITTO_NAME_SAVE';
export const DITTO_NAME_SUCCESS = 'common/DITTO_NAME_SUCCESS';
export const DITTO_NAME_FAILURE = 'common/DITTO_NAME_FAILURE';
export const DITTO_CHANGED_STATUS_UPDATE = 'common/DITTO_CHANGED_STATUS_UPDATE';
export const DITTO_DELETE = 'common/DITTO_DELETE';
export const DITTO_DELETE_SUCCESS = 'common/DITTO_DELETE_SUCCESS';
export const DITTO_DELETE_FAILURE = 'common/DITTO_DELETE_FAILURE';
export const CHANGE_LOGO = 'common/CHANGE_LOGO';

// ditto auth
export const DITTO_AUTH_LOAD = 'common/DITTO_AUTH_LOAD';
export const DITTO_AUTH_SUCCESS = 'common/DITTO_AUTH_SUCCESS';
export const DITTO_AUTH_FAILURE = 'common/DITTO_AUTH_FAILURE';
export const DITTO_AUTH_PROFILE = 'common/DITTO_AUTH_PROFILE';

// logout user
export const LOGOUT_USER = 'common/LOGOUT_USER';

// ask user to login
export const ASK_USER_LOGIN = 'common/ASK_USER_LOGIN';

// toast message
export const SET_TOAST_MESSAGE = 'common/SET_TOAST_MESSAGE';

export const SET_SIZE_DETAIL = 'common/SET_SIZE_DETAIL';

export const COUNTRY_DATA_LOAD = 'common/COUNTRY_DATA_LOAD';
export const COUNTRY_DATA_SUCCESS = 'common/COUNTRY_DATA_SUCCESS';
export const COUNTRY_DATA_FAIL = 'common/COUNTRY_DATA_FAIL';

export const PDP_CMS_POPUP_LOAD = 'common/PDP_CMS_POPUP_LOAD';
export const PDP_CMS_POPUP_SUCCESS = 'common/PDP_CMS_POPUP_SUCCESS';
export const PDP_CMS_POPUP_FAIL = 'common/PDP_CMS_POPUP_FAIL';
// show hide YOTP WIDGETS
export const YOTPO_WIDGETS_SHOW = 'common/YOTPO_WIDGETS_SHOW';

// get audienceType
export const AUDIENCE_TYPE_LOAD = 'common/AUDIENCE_TYPE_LOAD';

// update tryonswtich cta pdp/cat
export const TRY_ON_SWITCH = 'common/TRY_ON_SWITCH';
// get customer info
export const GET_CUSTOMER_INFO_LOAD = 'common/GET_CUSTOMER_INFO_LOAD';
export const GET_CUSTOMER_INFO_SUCCESS = 'common/GET_CUSTOMER_INFO_SUCCESS';
export const GET_CUSTOMER_INFO_FAIL = 'common/GET_CUSTOMER_INFO_FAIL';
// embed video
export const EMBED_VIDEO = 'common/EMBED_VIDEO';

// set webview
export const SET_IS_WEBVIEW = 'common/SET_IS_WEBVIEW';

// set phonepeview
export const IS_PHONEPEVIEW = 'common/IS_PHONEPEVIEW';

// passing utm data
export const PASS_UTM_DATA_LOAD = 'common/PASS_UTM_DATA_LOAD';
export const PASS_UTM_DATA_SUCCESS = 'common/PASS_UTM_DATA_SUCCESS';
export const PASS_UTM_DATA_FAIL = 'PASS_UTM_DATA_FAIL';

export const LEAD_GEN_DATA_LOAD = 'common/LEAD_GEN_DATA_LOAD';
export const LEAD_GEN_DATA_SUCCESS = 'common/LEAD_GEN_DATA_SUCCESS';
export const LEAD_GEN_DATA_FAIL = 'LEAD_GEN_DATA_FAIL';

export const MARKETING_SUBSCRIPTION_LOAD = 'common/MARKETING_SUBSCRIPTION_LOAD';
export const MARKETING_SUBSCRIPTION_SUCCESS = 'common/MARKETING_SUBSCRIPTION_SUCCESS';
export const MARKETING_SUBSCRIPTION_FAIL = 'common/MARKETING_SUBSCRIPTION_FAIL';

export const GET_MARKETING_SUBSCRIPTION_DATA_LOAD = 'common/GET_MARKETING_SUBSCRIPTION_DATA_LOAD';
export const GET_MARKETING_SUBSCRIPTION_DATA_SUCCESS =
  'common/GET_MARKETING_SUBSCRIPTION_DATA_SUCCESS';
export const GET_MARKETING_SUBSCRIPTION_DATA_FAIL = 'common/GET_MARKETING_SUBSCRIPTION_DATA_FAIL';

// lens mapping data
export const GET_LENS_MAPPING_DATA = 'common/GET_LENS_MAPPING_DATA';
export const GET_LENS_MAPPING_DATA_SUCCESS = 'common/GET_LENS_MAPPING_DATA_SUCCESS';
export const GET_LENS_MAPPING_DATA_FAIL = 'common/GET_LENS_MAPPING_DATA_FAIL';

export const PLP_MOBILE_VIEW_LAYOUT_CHANGE = 'common/PLP_MOBILE_VIEW_LAYOUT_CHANGE';

// PID_BARCODE
export const FETCH_PID_DATA_FROM_BARCODE_LOAD = 'common/FETCH_PID_DATA_FROM_BARCODE_LOAD';
export const FETCH_PID_DATA_FROM_BARCODE_SUCCESS = 'common/FETCH_PID_DATA_FROM_BARCODE_SUCCESS';
export const FETCH_PID_DATA_FROM_BARCODE_FAIL = 'common/FETCH_PID_DATA_FROM_BARCODE_FAIL';

// BARCODE_MODAL
export const BARCODE_MODAL_STATE = 'common/BARCODE_MODAL_STATE';
