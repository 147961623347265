import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/hto';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;

function* getHTOConfig() {
  const successAction = { type: actionTypes.GET_HTO_CONFIG_SUCCESS };
  const errorAction = { type: actionTypes.GET_HTO_CONFIG_FAIL };
  try {
    const data = yield call(async () =>
      _client.get(`${config.apiPath.htoPageJson}?v=${new Date().getTime()}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getLocationServiceDetails({ lat, lng }) {
  const successAction = { type: actionTypes.GET_LOCATION_SERVICE_DETAILS_SUCCESS };
  const errorAction = { type: actionTypes.GET_LOCATION_SERVICE_DETAILS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () =>
      _client.get(
        `${config.apiPath.validateUserLocation}?location=${encodeURIComponent(`${lat},${lng}`)}`
      )
    );
    successAction.data = result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getSlots({ pincode }) {
  const successAction = { type: actionTypes.GET_SLOTS_SUCCESS };
  const errorAction = { type: actionTypes.GET_SLOTS_FAIL };
  try {
    const response = yield call(async () =>
      _client.get(`${config.apiPath.validateUserLocation}/slot/?pinCode=${pincode}`)
    );
    successAction.result = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getHTOAppointmentCancelReasons() {
  const successAction = { type: actionTypes.GET_CANCEL_REASONS_SUCCESS };
  const errorAction = { type: actionTypes.GET_CANCEL_REASONS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const response = yield call(async () =>
      client.get(`${config.apiPath.validateUserLocation}/order/cancel`)
    );
    successAction.data = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* cancelHTOOrder({ orderId, reason }) {
  const successAction = { type: actionTypes.CANCEL_HTO_ORDER_SUCCESS };
  const errorAction = { type: actionTypes.CANCEL_HTO_ORDER_FAIL };
  const client = { ..._client };
  client.setHeader({ brand: 'abc' });
  try {
    const response = yield call(async () =>
      client.post(
        `${config.apiPath.validateUserLocation}/order/${orderId}/cancel?reason=${reason}`,
        { reason }
      )
    );
    successAction.result = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getAgentInfo({ phone }) {
  const successAction = { type: actionTypes.GET_AGENT_INFO_SUCCESS };
  const errorAction = { type: actionTypes.GET_AGENT_INFO_FAIL };
  try {
    const response = yield call(async () =>
      _client.get(`${config.apiPath.validateUserLocation}/lastorder?telephone=${phone}`)
    );
    successAction.result = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* bookHTOAppointment({ data }) {
  const successAction = { type: actionTypes.BOOK_APPOINTMENT_SUCCESS };
  const errorAction = { type: actionTypes.BOOK_APPOINTMENT_FAIL };
  const client = { ..._client };
  client.setHeader({ brand: 'abc' });
  try {
    const response = yield call(async () =>
      client.post(`${config.apiPath.htoBookNowOrder}/order${encodeURI(data.utm)}`, data.userInfo)
    );
    successAction.result = response && response.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runHtoCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_HTO_CONFIG, getHTOConfig),
    takeLatest(actionTypes.GET_LOCATION_SERVICE_DETAILS, getLocationServiceDetails),
    takeLatest(actionTypes.GET_SLOTS, getSlots),
    takeLatest(actionTypes.GET_CANCEL_REASONS, getHTOAppointmentCancelReasons),
    takeLatest(actionTypes.CANCEL_HTO_ORDER_LOAD, cancelHTOOrder),
    takeLatest(actionTypes.GET_AGENT_INFO, getAgentInfo),
    takeLatest(actionTypes.BOOK_APPOINTMENT, bookHTOAppointment),
  ]);
}
