import * as actionTypes from './../../actionTypes/auth';
import localStorageHelper from '../../utils/localStorageHelper';

const initialState = {
  register: false,
  authenticateLoader: false,
  reguser: {},
  user: null,
  loginSuccess: false,
  username: '',
  social: null,
  oTPSent: false,
  socialError: null,
  userInputErr: null,
  forgotpassError: null,
  emailStatus: '',
  registerError: null,
  previousUrl: '',
  hash: '',
  emailList: [],
  otpType: '',
  emailLinkSuccessfully: false,
  accessCount: 0,
  isCaptchaRequired: false,
  updateCustomerLoaded: false,
};

export default function auth(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.REGISTER_LOAD:
      return Object.assign({}, prevState, {
        register: false,
        registerError: null,
        loading: true,
        reguser: null,
      });
    case actionTypes.REGISTER_SUCCESS:
      return Object.assign({}, prevState, {
        register: true,
        reguser: action.data,
        loading: false,
        registerError: null,
      });
    case actionTypes.REGISTER_FAIL:
      return Object.assign({}, prevState, {
        register: false,
        reguser: null,
        loading: false,
        registerError: action.error,
      });
    case actionTypes.UPDATE_CUSTOMER_SUCCESS:
      return Object.assign({}, prevState, {
        updateCustomerLoaded: true,
      });
    case actionTypes.UPDATE_CUSTOMER_FAIL:
      return Object.assign({}, prevState, {
        updateCustomerLoaded: true,
      });
    case actionTypes.LOGIN_LOAD:
      return Object.assign({}, prevState, {
        authenticateLoader: true,
        user: null,
        loading: true,
        loginError: null,
      });
    case actionTypes.LOGIN_SUCCESS:
      const data = {
        isTelephoneVerified: action.data.result.isTelephoneVerified,
      };
      const userDetails = localStorageHelper.getItem('userDetails') || {};
      localStorageHelper.setItem('userDetails', Object.assign({}, userDetails, data));
      return Object.assign({}, prevState, {
        authenticateLoader: false,
        user: action.data,
        loginError: null,
        loading: false,
        loginSuccess: true,
      });
    case actionTypes.LOGIN_FAIL:
      return Object.assign({}, prevState, {
        authenticateLoader: false,
        user: null,
        loading: false,
        loginError: action.error,
      });
    case actionTypes.USER_INPUT_LOAD:
      return Object.assign({}, prevState, {
        userInputValidate: null,
        userInputErr: null,
        loading: true,
        userInputValidationStatus: 'load',
      });
    case actionTypes.USER_INPUT_SUCCESS:
      return Object.assign({}, prevState, {
        userInputValidate: action.data,
        username: action.data.result.data.username,
        userInputErr: null,
        loading: false,
        userInputValidationStatus: 'success',
      });
    case actionTypes.USER_INPUT_FAIL:
      return Object.assign({}, prevState, {
        userInputValidate: null,
        userInputErr: action.error,
        loading: false,
        userInputValidationStatus: 'fail',
      });
    case actionTypes.USER_OTP_LOAD:
      return Object.assign({}, prevState, {
        oTPSent: false,
        loading: true,
        otpType: action.data.type,
        oTPLoginSuccess: false,
      });
    case actionTypes.USER_OTP_SUCCESS:
      if (action.data.result.data.type === 'VALIDATE_OTP') {
        const userDetails = localStorageHelper.getItem('userDetails') || {};
        const { emailVerificationStatus, isTelephoneVerified, telephone } = action.data.result;
        localStorageHelper.setItem(
          'userDetails',
          Object.assign({}, userDetails, {
            emailVerificationStatus,
            isTelephoneVerified,
            telephone,
          })
        );
      }
      return Object.assign({}, prevState, {
        oTPSent: action.data.result.data.type === 'SEND_OTP' ? true : action.data,
        oTPLoginSuccess: action.data.result.data.type !== 'SEND_OTP',
        loading: false,
        accessCount: prevState.accessCount + 1,
        isCaptchaRequired: action.data.result.isCaptchaRequired,
      });
    case actionTypes.USER_OTP_FAIL:
      return Object.assign({}, prevState, {
        oTPSent: action.error.message ? action : { error: { message: 'Something went wrong' } },
        loading: false,
        oTPLoginSuccess: false,
      });
    case actionTypes.MERGE_MOBILE_EMAIL_LOAD:
      return Object.assign({}, prevState, {
        oTPSent: false,
        loading: true,
        otpType: 'MERGE_MOBILE',
        oTPLoginSuccess: false,
      });
    case actionTypes.MERGE_MOBILE_EMAIL_SUCCESS:
      const user = localStorageHelper.getItem('userDetails') || {};
      user.loggedIn = 1;
      localStorageHelper.setItem('userDetails', Object.assign({}, user));
      return Object.assign({}, prevState, {
        oTPSent: action.data,
        oTPLoginSuccess: true,
        loading: false,
      });
    case actionTypes.MERGE_MOBILE_EMAIL_FAIL:
      return Object.assign({}, prevState, {
        oTPSent: action.error.message ? action : { error: { message: 'Something went wrong' } },
        loading: false,
        oTPLoginSuccess: false,
      });
    case actionTypes.RESET_MOBILE_LOGIN_STATE:
      return Object.assign({}, prevState, {
        oTPSent: false,
        userInputValidate: null,
      });
    case actionTypes.FORGET_PASSWORD_LOAD:
      return Object.assign({}, prevState, {
        forgotpassError: null,
        loading: true,
      });
    case actionTypes.FORGET_PASSWORD_SUCCESS:
      return Object.assign({}, prevState, {
        forgotpassError: null,
        emailStatus: 'sent',
        loading: false,
      });
    case actionTypes.FORGET_PASSWORD_FAIL:
      return Object.assign({}, prevState, {
        forgotpassError: action.error,
        emailStatus: 'fail',
        loading: false,
      });

    case actionTypes.RESET_AUTH_STATE:
      return Object.assign({}, prevState, {
        forgotpassError: null,
        userInputValidate: null,
        userInputErr: null,
        registerError: null,
        emailStatus: '',
        oTPSent: false,
        register: false,
      });
    case actionTypes.SOCIAL_LOAD:
      return Object.assign({}, prevState, {
        social: null,
        socialError: null,
        loading: true,
      });
    case actionTypes.SOCIAL_SUCCESS:
      return Object.assign({}, prevState, {
        social: action.data,
        socialError: null,
        authenticateLoader: false,
        loginError: null,
        loading: false,
      });
    case actionTypes.SOCIAL_FAIL:
      return Object.assign({}, prevState, {
        social: null,
        loading: false,
        socialError: action.error,
      });
    case actionTypes.FETCH_EMAILS_LOAD:
      return Object.assign({}, prevState, {
        emailList: [],
        loading: true,
      });
    case actionTypes.FETCH_EMAILS_SUCCESS:
      return Object.assign({}, prevState, {
        emailList: action.data,
        loading: false,
      });
    case actionTypes.FETCH_EMAILS_FAIL:
      return Object.assign({}, prevState, {
        emailList: action.error,
        loading: false,
      });
    case actionTypes.LINK_EMAIL_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        emailLinkSuccessfully: false,
      });
    case actionTypes.LINK_EMAIL_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        emailLinkSuccessfully: true,
      });
    case actionTypes.LINK_EMAIL_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        emailLinkSuccessfully: false,
      });
    case actionTypes.LOGOUT_USER:
      if (__CLIENT__) {
        localStorageHelper.setItem('whatsAppOptInId', '');
      }
      return Object.assign({}, prevState, {
        social: null,
        socialError: null,
        username: '',
        authenticateLoader: false,
        user: null,
      });
    case actionTypes.SET_PREVIOUS_URL:
      return Object.assign({}, prevState, {
        previousUrl: action.location.url,
        hash: action.location.hash || '',
      });
    default:
      return prevState;
  }
}
