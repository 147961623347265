import React from 'react';
import HeaderBlackCollection from './HeaderBlackCollection';
import BodyBlackCollection from './BodyBlackCollection';
import FooterBlackCollection from './FooterBlackCollection';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import useAdobeFont from '../../../hooks/useAdobeFont';
import './BlackCollection.scss';

const imgUrl = 'https://static1.lenskart.com/media/owndays/img/news/black-collection';
const iconUrl = 'https://static1.lenskart.com/media/owndays/img/icons';

const BlackCollection = () => {
  const crumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'BLACK COLLECTION' },
  ];

  useAdobeFont('wxp5dpu');

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <main className="black-collection">
        <HeaderBlackCollection imgUrl={imgUrl} />
        <BodyBlackCollection imgUrl={imgUrl} />
        <FooterBlackCollection iconUrl={iconUrl} />
      </main>
    </>
  );
};

export default BlackCollection;
