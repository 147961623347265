import React from 'react';
import { MAIN_LIST } from './constants.js';

const HeaderFreeMyopiaControl2Lenses = ({ baseImgUrl }) => {
  return (
    <section className="fmc2l__main">
      <img
        className="fmc2l__cloud fmc2l__cloud--1"
        src={`${baseImgUrl}/cloud.svg`}
        alt="cloud"
      />
      <img
        className="fmc2l__cloud fmc2l__cloud--2"
        src={`${baseImgUrl}/cloud.svg`}
        alt="cloud"
      />
      <div className="fmc2l__main-img">
        <picture>
          <source media="(min-width: 768px)" srcSet={`${baseImgUrl}/main-pc.webp?v=123455`} />
          <img src={`${baseImgUrl}/main-sp.webp`} alt="FREE Myopia Control 2 Lenses" />
        </picture>
      </div>
      <div className="fmc2l__main-content">
        <div className="fmc2l__container">
          <h1 className="fmc2l__main-heading">
            <img src={`${baseImgUrl}/free.svg`} alt="FREE" />
            <span>Myopia Control 2 Lenses</span>
          </h1>
          {MAIN_LIST && (
            <ul className="fmc2l__main-list">
              {MAIN_LIST.map(item => (
                <li key={item.text}>
                  <img src={item.iconPath} alt={item.text} />
                  <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default HeaderFreeMyopiaControl2Lenses;
