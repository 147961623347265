import React from 'react';

const HeaderZakuhead = ({ baseImgUrl, setIsModalOpen }) => {
  const handleClickSmoothScroll = e => {
    const target = e.target;
    const targetId = target.getAttribute('href');

    if (targetId && targetId.startsWith('#')) {
      e.preventDefault();
      const targetElement = document.querySelector(targetId);
      const headerHeight = document.querySelector('.header-placeholder')?.offsetHeight || 0;
      const offset = parseInt(target.getAttribute('data-scroll-offset'), 10) || 25;
      const scrollPosition = targetElement.offsetTop - headerHeight - offset;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const links = document.querySelectorAll('.l-gundam5 a[href^="#"]');
  links.forEach(link => link.addEventListener('click', handleClickSmoothScroll));

  window.addEventListener('scroll', function () {
    handleScroll('.c-modal-video', function (target) {
      const iframe = target.querySelector('iframe');
      if (iframe) {
        const src = iframe.getAttribute('src');
        const updatedSrc = src.replace('autoplay=0', 'autoplay=1');
        iframe.setAttribute('src', updatedSrc);
      }
    });
  });

  return (
    <>
      <section className="main">
        <div className="l-gundam5__container">
          <div className="main__content">
            <h1>ZAKU HEAD CASE</h1>
            <small>MS-06S CHAR’S ZAKUII</small>
            <img alt="ZEON × OWNDAYS" className="main__logo" src={`${baseImgUrl}logo.svg`} />
            <p>
              Limited to <strong>2,500</strong> units worldwide
            </p>
          </div>
          <img alt="ZEON" className="main__zeon-logo" src={`${baseImgUrl}zeon-logo.svg`} />
          <a
            aria-label="Scroll down"
            className="main__scroll-down"
            data-scroll-offset="-50"
            href="#concept"
          >
            <span>Scroll down</span>
          </a>
        </div>
        <picture className="main__img">
          <source media="(min-width:768px)" srcSet={`${baseImgUrl}main-pc.webp`} />
          <img alt="ZEON × OWNDAYS ZAKU HEAD CASE" src={`${baseImgUrl}main-sp.webp`} />
        </picture>
      </section>
      <section className="concept" id="concept">
        <div className="l-gundam5__container">
          <h2 className="l-gundam5__title l-gundam5__title--right-sp">CONCEPT</h2>
          <div className="concept__top">
            <picture className="concept__img">
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}concept-img-pc.webp`} />
              <img alt="ZAKU" src={`${baseImgUrl}concept-img-sp.webp`} />
            </picture>
            <p className="concept__desc">
              From Mobile Suit Gundam, one of the most iconic Japanese anime series that has
              transcended generations, comes the Zaku Head Case, an eyewear storage case modelled
              after Char Aznable’s custom Zaku in the largest-ever 1:7 scale. Besides being able to
              house 4 pairs of eyewear, the head case showcases a lighted monoeye and Zaku’s
              characteristic activation sound effect upon opening and closing. The monoeye, an
              iconic feature of Zeon mobile suits, can be moved left and right.
            </p>
          </div>
          <div className="c-modal-video c-modal-video--iframe" onClick={() => setIsModalOpen(true)}>
            <iframe
              allow="autoplay"
              src="https://www.youtube.com/embed/__kz_GvsgzQ?autoplay=0&mute=1&loop=1&controls=0&showinfo=0&playlist=__kz_GvsgzQ"
              title="ZAKU Head Case Video"
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderZakuhead;
