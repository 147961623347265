import React from 'react';

import { DotPalette } from '../DotPalette/DotPalette';

const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-sunglasses-2024';

const defaultSetting = {
  slidesToShow: 1,
  slidesToScroll: 2,
  arrows: false,
  dots: true,
  fade: true,
  draggable: false,
  infinite: true,
  swipe: false,
  touchMove: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        draggable: true,
        infinite: true,
        swipe: true,
        touchMove: true,
      },
    },
  ],
};

const CustomDots = props => {
  const backgroundColors = {
    'SUN2107N-4S': ['#000', '#575757', '#939393', '#3F6677'],
    'SUN2104B-4S': ['#000', `${baseImgUrl}/product-color-1.webp`, '#B08563', '#DBDCDF'],
    'SUN2093T-2S': [`${baseImgUrl}/product-color-1.webp`, '#593776', '#E1D4B3'],
    'SUN8007J-2S': [
      '#000',
      `${baseImgUrl}/product-color-1.webp`,
      `${baseImgUrl}/product-color-7.webp`,
      '#06348B',
    ],
    'SUN7001B-2S': [
      `${baseImgUrl}/product-color-4.webp`,
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-8.webp`,
      `${baseImgUrl}/product-color-9.webp`,
    ],
    'SUN7002B-2S': [
      `${baseImgUrl}/product-color-5.webp`,
      `${baseImgUrl}/product-color-4.webp`,
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-8.webp`,
    ],
    'SUN2102B-4S': ['#000', `${baseImgUrl}/product-color-1.webp`, '#AB8E7A', '#D7BDBF'],
    'SUN1073B-4S': [
      '#4B4B4B',
      `${baseImgUrl}/product-color-2.webp`,
      '#E2C7B6',
      `${baseImgUrl}/product-color-3.webp`,
    ],
    'SUN2103B-4S': ['#855928', `${baseImgUrl}/product-color-1.webp`, '#5F3D17', '#E0DEDE'],
    'SUN1062T-1S': [
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-4.webp`,
      `${baseImgUrl}/product-color-4.webp`,
    ],
    'SUN1063T-1S': [
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-3.webp`,
      `${baseImgUrl}/product-color-5.webp`,
    ],
    'SUN2100T-2S': ['#855928', '#999', '#E0DEDE', '#DACFC7'],
    'SUN2106J-4S': ['#000', '#D7BDBF', '#E0DEDE', '#8C9564'],
    'SUN1074B-4S': ['#000', `${baseImgUrl}/product-color-1.webp`, '#C1937C', '#D7BDBF'],
    'SUN1075G-4S': [
      '#000',
      '#A9A9A9',
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-6.webp`,
    ],
    'SUN1068T-2S': [
      '#000',
      '#855928',
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-2.webp`,
    ],
    'SUN1069T-2S': [
      '#000',
      `${baseImgUrl}/product-color-2.webp`,
      `${baseImgUrl}/product-color-4.webp`,
      `${baseImgUrl}/product-color-2.webp`,
    ],
    'SUN8009B-3S': [
      '#000',
      `${baseImgUrl}/product-color-10.webp`,
      `${baseImgUrl}/product-color-11.webp`,
      `${baseImgUrl}/product-color-1.webp`,
    ],
  };

  return (
    <DotPalette
      {...props}
      backgroundColors={backgroundColors}
      containerClassName="product-od-sunglasses"
    />
  );
};

const initialSlides = {
  'SUN2107N-4S': 0,
  'SUN2104B-4S': 3,
  'SUN2093T-2S': 1,
  'SUN8007J-2S': 1,
  'SUN7001B-2S': 3,
  'SUN7002B-2S': 3,
  'SUN2102B-4S': 2,
  'SUN1073B-4S': 3,
  'SUN2103B-4S': 3,
  'SUN1062T-1S': 2,
  'SUN1063T-1S': 1,
  'SUN2100T-2S': 3,
  'SUN2106J-4S': 3,
  'SUN1074B-4S': 0,
  'SUN1075G-4S': 2,
  'SUN1068T-2S': 3,
  'SUN1069T-2S': 3,
  'SUN8009B-3S': 0,
};

const createSettings = sku => {
  const initialSlide = initialSlides[sku];

  return {
    ...defaultSetting,
    initialSlide,
    beforeChange: (oldIndex, newIndex) => {
      initialSlides[sku] = newIndex;
    },
    appendDots: dots => <CustomDots dots={dots} initialSlide={initialSlides[sku]} sku={sku} />,
  };
};

// Generate settings for each SKU
export const settingsForSUN2107N4S = createSettings('SUN2107N-4S');
export const settingsForSUN2104B4S = createSettings('SUN2104B-4S');
export const settingsForSUN2093T2S = createSettings('SUN2093T-2S');
export const settingsForSUN8007J2S = createSettings('SUN8007J-2S');
export const settingsForSUN7001B2S = createSettings('SUN7001B-2S');
export const settingsForSUN7002B2S = createSettings('SUN7002B-2S');

export const settingsForSUN2102B4S = createSettings('SUN2102B-4S');
export const settingsForSUN1073B4S = createSettings('SUN1073B-4S');
export const settingsForSUN2103B4S = createSettings('SUN2103B-4S');
export const settingsForSUN1062T1S = createSettings('SUN1062T-1S');
export const settingsForSUN1063T1S = createSettings('SUN1063T-1S');
export const settingsForSUN2100T2S = createSettings('SUN2100T-2S');

export const settingsForSUN2106J4S = createSettings('SUN2106J-4S');
export const settingsForSUN1074B4S = createSettings('SUN1074B-4S');
export const settingsForSUN1075G4S = createSettings('SUN1075G-4S');
export const settingsForSUN1068T2S = createSettings('SUN1068T-2S');
export const settingsForSUN1069T2S = createSettings('SUN1069T-2S');
export const settingsForSUN8009B3S = createSettings('SUN8009B-3S');
