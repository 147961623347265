import React from 'react';
import './PrescriptionPage.scss';
import { useSelector } from 'react-redux';
import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import { v4 } from 'uuid';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const tableDataPrescriptionPage = [
  {
    title: '',
    right: 'Right',
    left: 'Left',
  },
  {
    title: 'Pupillary Distance',
    right: '31',
    left: '31',
  },
  {
    title: 'Sphere',
    right: '-6.50',
    left: '-8.00',
  },
  {
    title: 'Cylinder',
    right: '±0.00',
    left: '±0.00',
  },
  {
    title: 'Axis (Degree)',
    right: '--',
    left: '--',
  },
];

const PrescriptionPage = () => {
  const dataLocale = useSelector(state => state.locale);
  const { PRESCRIPTION, PRESCRIPTION_FROM_CENTURY_SQUARE } = dataLocale;

  return (
    <section className="prescription-page">
      <MetaTagsManager
        description="prescription page | OWNDAYS Singapore"
        title="prescription page | OWNDAYS Singapore"
      />
      <div className="prescription-page-wrapper">
        <div className="prescription-page-title od-font-bold">{PRESCRIPTION}</div>
        <ButtonOwnDays
          fontClass="od-font-reg"
          fontSize="20px"
          mode="black"
          px="50px"
          py="15px"
          text="Add New Prescription"
          width="auto"
          onClick={() => {}}
        />
      </div>
      <div className="prescription-page-content">
        <div className="prescription-page-date od-font-reg">Added on 28/06/2023</div>
        <div className="prescription-page-text od-font-bold">
          {PRESCRIPTION_FROM_CENTURY_SQUARE}
        </div>
        <div className="prescription-page-table">
          {tableDataPrescriptionPage.map(row => (
            <div key={v4()} className="prescription-page-table-row od-font-bold">
              <div className="prescription-page-table-title">{row.title}</div>
              <div className="prescription-page-table-cell">{row.right}</div>
              <div className="prescription-page-table-cell">{row.left}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PrescriptionPage;
