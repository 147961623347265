import React, { Fragment } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import './HeaderGiftTicket.scss';

export const HeaderGiftTicket = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '#', label: `It's a great way to send a gift to your loved ones!` },
  ];

  return (
    <Fragment>
      <div className="header-gift-ticket__breadcrumbs">
        <Breadcrumbs crumbs={breadcrumbs} />
      </div>

      <section className="header-gift-ticket__banner">
        <picture>
          <source media="(min-width:768px)" srcSet={`${baseImgUrl}/banner-pc.webp`} />
          <img
            alt="OWNDAYS eGift Ticket"
            className="about-top__bg"
            src={`${baseImgUrl}/banner-sp.webp`}
          />
        </picture>

        <div className="header-gift-ticket__banner__message">
          <div className="header-gift-ticket__banner__message__container">
            <img
              alt="OWNDAYS eGift Ticket"
              className="header-gift-ticket__banner__message__logo"
              src={`${baseImgUrl}/logo.svg`}
            />
            <p className="header-gift-ticket__banner__message__desc">
              A digital gift card that can be used <br />
              for the purchase of eyewear at OWNDAYS
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
