const getSetCookie = {
  getCookie: cname => {
    const name = `${cname}=`;
    if (typeof window !== 'undefined') {
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieSplit = decodedCookie.split(';');
      for (let index = 0; index < cookieSplit.length; index += 1) {
        let cookie = cookieSplit[index];
        while (cookie.charAt(0) === ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie?.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
    }
    return '';
  },
  setCookie: (cname, cvalue, days, minutes) => {
    const date = new Date();
    date.setTime(date.getTime() + (days ? days * 24 * 60 : 1 + minutes) * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    if (typeof window !== 'undefined') {
      if (window.location.hostname.includes('www.lenskart.com'))
        document.cookie = `${cname}=${cvalue};${expires};path=/;domain=www.lenskart.com;`;
      else if (window.location.hostname.includes('preprod-owndays.lenskart.com'))
        document.cookie = `${cname}=${cvalue};${expires};path=/;domain=preprod-owndays.lenskart.com;`;
      else if (window.location.hostname.includes('www.owndays.com'))
        document.cookie = `${cname}=${cvalue};${expires};path=/;domain=www.owndays.com;`;
      else document.cookie = `${cname}=${cvalue};${expires};path=/`;
    }
  },
  deleteCookie: cname => {
    // let domain = 'domain=';
    // domain += window.location.hostname;
    const domains = [];
    domains.push(window.location.hostname);
    if (window.location.hostname?.indexOf('lenskart.ae') > -1) {
      domains.push('.lenskart.ae');
    } else if (window.location.hostname?.indexOf('lenskart.sg') > -1) {
      domains.push('.lenskart.sg');
    } else if (
      window.location.hostname?.indexOf('lenskart.com') > -1 &&
      window.location.hostname?.indexOf('sa.lenskart.com') === -1
    ) {
      domains.push('.lenskart.com');
    } else if (window.location.hostname?.indexOf('sa.lenskart.com') === -1) {
      domains.push('.lenskart.com');
    }
    domains.forEach(d => {
      document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${d};`;
    });
    document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
  },
  setCookieWithDomain: (cName, value, extime) => {
    const dt = new Date();
    dt.setTime(dt.getTime() + extime);
    const cValue = `${
      escape(value) + (extime === null ? '' : `;expires=${dt.toGMTString()}`)
    }; path=/`;
    if (window.location.hostname.includes('www.lenskart.com'))
      document.cookie = `${cName}=${cValue};domain=www.lenskart.com;`;
    else if (window.location.hostname.includes('preprod-owndays.lenskart.com'))
      document.cookie = `${cName}=${cValue};domain=preprod-owndays.lenskart.com;`;
    else if (window.location.hostname.includes('www.owndays.com'))
      document.cookie = `${cName}=${cValue};domain=www.owndays.com;`;
    else document.cookie = `${cName}=${cValue}`;
  },
  setCookieWithOptions: (cName, value, options) => {
    const cValue = `${escape(value)};expires=${options.expires};path=${options.path};secure=${
      options.secure
    };`;
    if (window.location.hostname.includes('www.lenskart.com'))
      document.cookie = `${cName}=${cValue};domain=www.lenskart.com;`;
    else if (window.location.hostname.includes('preprod-owndays.lenskart.com'))
      document.cookie = `${cName}=${cValue};domain=preprod-owndays.lenskart.com;`;
    else if (window.location.hostname.includes('www.owndays.com'))
      document.cookie = `${cName}=${cValue};domain=www.owndays.com;`;
    else document.cookie = `${cName}=${cValue}`;
  },
};
export default getSetCookie;
