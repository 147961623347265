import CryptoJs from 'crypto-js';
import getSetCookie from '../cookie';
import { pushDataLayerEvent } from '../utils/DataLayer';
import sessionStorageHelper from '../utils/sessionStorageHelper';
import localStorageHelper from './localStorageHelper';

export const noop = () => {};

export const removeDomainName = (url, alsoRemove = '', countryCode = '') => {
  if (!url) return '';
  let domain = url.replace('http://', '').replace('https://', '').replace('www.lenskart.com', '');
  if (alsoRemove) domain = domain.replace(alsoRemove, '');
  if (countryCode) {
    domain = domain
      .replace('frontend-preprod.lenskart.com', '')
      .replace(`www.lenskart.${countryCode}`, '')
      .replace(`www.owndays.com`, '')
      .replace(`frontend-preprod-${countryCode}.lenskart.com`, '')
      .replace(`frontend-preprod-${countryCode}.lenskart.${countryCode}`, '')
      .replace(`frontend-preprod-${countryCode}`, '')
      .replace(`frontend-preprod.lenskart.${countryCode}`, '')
      .replace(`preprod.lenskart.${countryCode}`, '')
      .replace(`preprod-${countryCode}.lenskart.com`, '')
      .replace(`${countryCode}.lenskart.com`, '')
      .replace(`localhost:8081`, '');
  }
  return domain;
};

export const UtmObjectCreation = data => {
  const UtmObj = {};
  let tempUtmObj;
  data.forEach((item, index) => {
    if (index === 0) {
      tempUtmObj = item.split('.')[4].split('=');
    } else {
      tempUtmObj = item.split('=');
    }
    UtmObj[tempUtmObj[0]] = tempUtmObj[1];
  });
  return UtmObj;
};

export const getQueryParam = (query, param) => {
  const result = {};
  if (query) {
    query.split('&').forEach(param => {
      const item = param.split(/=(.+)/);
      result[item[0]] = decodeURIComponent(item[1]);
    });
  }
  return param ? result[param] : result;
};

export const updateSize = (value, setfn) => {
  setfn(() => {
    return window.innerWidth >= value;
  });
};

export const tConv24 = time24 => {
  let ts = time24;
  const H = +ts.substr(0, 2);
  let h = H % 12 || 12;
  h = h < 10 ? '0' + h : h;
  const ampm = H < 12 ? ' AM' : ' PM';
  ts = h + ts.substr(2, 3) + ampm;
  return ts;
};

export const currentMonth = () => {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const d = new Date();
  return month[d.getMonth()].substring(0, 3);
};

export const hasFrameOnlyProduct = (cartItems = []) => {
  return cartItems.some(
    eyeFrame =>
      (eyeFrame.classification === 'eyeframe' || eyeFrame.classification === 'sunglasses') &&
      eyeFrame.powerRequired === 'POWER_REQUIRED'
  );
};

export const getHelplineNo = (config, categoryType) => {
  let buyoncallConfig;
  if (config) {
    buyoncallConfig = JSON.parse(config);
  }
  const catConfig = buyoncallConfig ? buyoncallConfig[categoryType] : {};
  if (catConfig && catConfig.isShown !== 'ON') {
    return null;
  }
  if (catConfig && catConfig.contactNumberDisplay) {
    return buyoncallConfig[categoryType].contactNumberDisplay;
  }
  return '';
};

export const getNumericChars = str => {
  if (str) {
    return str.replace(/\D/g, '');
  }
  return str;
};

export const getOnlyCharswSpace = str => {
  if (str) {
    return str.replace(/[^a-z\s]+/gi, '');
  }
  return str;
};

export const roundUptoDigits = (number, digits) => {
  if (number) {
    const factor = 10 ** digits;
    return Math.round(Number(number) * factor) / factor;
  }
  return number;
};

export const onImageLoadError = evt => {
  evt.target.src = 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/no-image.jpg';
};

export const isEmptyObject = obj => {
  return typeof obj === 'object' && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export function isObject(obj) {
  return obj === Object(obj);
}

export const extractUtmSourceFromUrl = utmSourceStr => {
  const utmObj = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
  };
  if (utmSourceStr && utmSourceStr.split('?') && utmSourceStr.split('?')[1]) {
    const utmArr = utmSourceStr.split('?')[1].split('&');
    const utmTempObj = utmArr.reduce((filtered, str) => {
      if (str?.indexOf('utm_source') > -1) {
        filtered.utm_source = str.split('=')[1];
      } else if (str?.indexOf('utm_medium') > -1) {
        filtered.utm_medium = str.split('=')[1];
      } else if (str?.indexOf('utm_campaign') > -1) {
        filtered.utm_campaign = str.split('=')[1];
      } else if (str?.indexOf('utm_content') > -1) {
        filtered.utm_content = str.split('=')[1];
      } else if (str?.indexOf('utm_term') > -1) {
        filtered.utm_term = str.split('=')[1];
      }
      return filtered;
    }, {});

    return Object.assign({}, utmObj, utmTempObj);
  }
  return utmObj;
};

export function loadFile(file, ext, id, place, async, defer) {
  let child;
  if (ext === 'css') {
    child = document.createElement('link');
    child.rel = 'stylesheet';
    child.href = file;
    child.type = 'text/css';
  } else {
    child = document.createElement('script');
    child.type = 'text/javascript';
    child.src = file;
  }
  if (async === 'async') {
    child.async = 'async';
  }
  if (defer === 'defer') {
    child.defer = 'defer';
  }
  if (id) {
    document.getElementById(id).appendChild(child);
  }
  if (place) {
    document[place].appendChild(child);
  }
}

export function loadScriptWithCallback(url, callback, attrs = {}) {
  if (!document) return;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  Object.keys(attrs).forEach(attr => {
    script[attr] = attrs[attr];
  });
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => {
      callback();
    };
  }
  script.src = url;
  document.body.appendChild(script);
}

export function convertHttps(value) {
  return value && value.replace('http:', 'https:');
}

export function isNavigationRequired(pathname) {
  if (pathname === 'checkout/success') return true;
  const noNavigationRequiredUrls = ['checkout', 'rx/buy'];
  return !noNavigationRequiredUrls.some(path => pathname?.indexOf(path) > 0);
}

export function isRelativeUrl(url) {
  return (
    url?.indexOf('http') === -1 &&
    url?.indexOf('//www.lenskart.') === -1 &&
    url?.indexOf('//live.lenskart.') === -1
  );
}

export function disableBackgroundScroll() {
  document.body.classList.add('overflow-hidden');
  document.body.classList.add('h-100cent');
}

export function enableBackgroundScroll() {
  document.body.classList.remove('overflow-hidden');
  document.body.classList.remove('h-100cent');
}

export function determineCategory(pathname, cmsCategoryArray) {
  let category = 'accessories';
  category = category.replace('_', '-');
  if (cmsCategoryArray?.indexOf(category) < 0) {
    if (pathname?.indexOf('eyeglasses') > -1) {
      category = 'eyeglasses';
    }
    if (pathname?.indexOf('sunglasses') > -1) {
      category = 'sunglasses';
    }
    if (pathname?.indexOf('power-sunglasses') > -1) {
      category = 'power sunglasses';
      document.cookie = 'product_category=power_sunglasses';
    }
    if (pathname?.indexOf('premium-eyeglasses') > -1) {
      category = 'premium eyeglasses';
    }
    if (pathname?.indexOf('contact-lenses') > -1) {
      category = 'contact-lenses';
    }
  }
  if (category !== 'contact-lenses') {
    category = category.replace('-', ' ');
  }
  return category;
}

export function showElement(query, show) {
  // used in case of lazy load
  const $element = document.querySelector(query);
  if ($element) {
    const display = show ? 'block' : 'none';
    $element.style.display = display;
  }
}

export function checkElementInViewport($element) {
  const rect = $element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const extractAndAppendScriptSourceForCMS = domString => {
  const scriptTagString = domString;
  scriptTagString.split('</script>').forEach((domString, index) => {
    // delete node first if they are exist
    const scriptEl = document.getElementById(`cms${index}`);
    if (scriptEl) document.body.removeChild(scriptEl);
    if (domString?.indexOf('<script') > -1) {
      const script = document.createElement('script');
      script.id = `cms${index}`;
      if (domString?.indexOf('src=') > -1) {
        // in script tag of cms pages src must be last in the tag otherwise condition will break.
        const srcPath = domString.substring(domString?.indexOf('src=') + 5, domString.length - 2);
        script.src = srcPath;
      } else {
        const scriptToAppend = domString.substr(domString?.indexOf('<script>') + 8);
        script.type = 'text/javascript';
        // script.append(scriptToAppend);
        script.innerHTML = scriptToAppend;
      }
      document.body.appendChild(script);
    }
  });
};

export function updateImageResolution(url, resolution = null) {
  if (url) {
    const urlArray = url.split('/');
    const indexMinusTwo = urlArray?.indexOf('cache');
    if (urlArray[indexMinusTwo + 1] === '1' && resolution) {
      const index = indexMinusTwo + 2;
      urlArray[index] = 'thumbnail';
      urlArray[index + 1] = resolution;
      const newUrlString = urlArray.join('/');
      return convertHttps(newUrlString);
    }
    return convertHttps(url);
  }
  return '';
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function calcPriceWithGST(price, gstPercentage) {
  const gst = (price * gstPercentage) / 100;
  return price + gst;
}

export function getLocationApiErrorMessage(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return 'Error! Location access has earlier been denied, kindly enable it from the browser setting to access the auto detect location.';
    case error.POSITION_UNAVAILABLE:
      return 'Error! Please enable your location services or enter your address or pin-code in search bar.';
    case error.TIMEOUT:
      return 'Error! The request to get user location timed out.';
    case error.UNKNOWN_ERROR:
      return 'Error! An unknown error occurred.';
    default:
      return 'Could not determine your location, please try again';
  }
}

export function removeDuplicatesInArray(arr, match = '') {
  const dups = [];
  const retArray = arr.filter(el => {
    // const matchKey = match ? el[match] : el;
    const matchKey = match && typeof el === 'object' && el[match] ? el[match] : el; // change in code
    // If it is not a duplicate, return true
    if (dups?.indexOf(matchKey) === -1) {
      dups.push(matchKey);
      return true;
    }
    return false;
  });
  return retArray;
}

export const readQueryParam = (name, url, isServer) => {
  if (!url && !isServer) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const removeParameterFromUrl = (url, parameter) => {
  return url
    .replace(new RegExp('^([^#]*?)(([^#]*)&)?' + parameter + '(=[^&#]*)?(&|#|$)'), '$1$3$5')
    .replace(/^([^#]*)((\?)&|\?(#|$))/, '$1$3$4');
};

export const appendScriptToDOM = (scriptSrc, id, isAsync, callback) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = scriptSrc;
  if (id) {
    script.id = id;
  }
  if (isAsync) {
    script.async = true;
  }
  if (callback) {
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => {
        callback();
      };
    }
  }
  document.head.appendChild(script);
};

export const triggerFBqEvent = (
  fbPixelForCartItems,
  totalCartPrice,
  fireAddPaymentInfo,
  InitiateCheckout,
  currencyCode,
  facebookPixel
) => {
  if (!window.ReactPixel) {
    window.ReactPixel = require('react-facebook-pixel');
    const { init } = window.ReactPixel.default;
    init(facebookPixel);
  }
  const cartLenskartPrice = [];
  const cartProductId = [];
  const cartName = [];
  let cartSku = '';
  if (fbPixelForCartItems) {
    fbPixelForCartItems.forEach(item => {
      cartProductId.push(item.productId);
      cartName.push(item.name);
      item.catalogPrices.forEach(prices => {
        if (prices.name === 'Lenskart Price') {
          cartLenskartPrice.push(prices.value.toFixed(2));
        }
      });
      const skuItem = item.sku.toLowerCase().split(':')[0];
      if (skuItem === 'eye') {
        cartSku = 'eye';
      } else if (skuItem === 'sun' && cartSku !== 'eye') {
        cartSku = 'sun';
      } else if (skuItem === 'contact' && cartSku !== 'eye' && cartSku !== 'sun') {
        cartSku = 'contact';
      } else if (cartSku !== 'eye' && cartSku !== 'sun' && cartSku !== 'contact') {
        cartSku = 'watch';
      }
    });
  }
  const code = currencyCode || '';
  if (InitiateCheckout) {
    window.ReactPixel.default.track('InitiateCheckout', {
      content_ids: cartProductId.toString().split(','),
      value: totalCartPrice.toString(),
      content_name: cartName.toString(),
      currency: code,
      content_category: cartSku,
      content_type: 'product',
    });
  }

  if (fireAddPaymentInfo) {
    window.ReactPixel.default.track('AddPaymentInfo', {
      content_ids: cartProductId.toString().split(','),
      value: totalCartPrice.toString(),
      content_name: cartName.toString(),
      currency: code,
      content_category: cartSku,
      content_type: 'product',
    });
  }
};

export function setParam(uri, key, val) {
  return uri
    .replace(new RegExp('([?&]' + key + '(?=[=&#]|$)[^#&]*|(?=#|$))'), '&' + key + '=' + val)
    .replace(/^([^?&]+)&/, '$1?');
}

export function capitalize(str = '') {
  return str.replace(/(?:^|\s)\S/g, a => {
    return a.toUpperCase();
  });
}

// detect search crawler
export function isSearchCrawler(serverCallUserAgent = null) {
  const botPattern =
    '(googlebot/|Googlebot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)';
  const re = new RegExp(botPattern, 'i');
  if (serverCallUserAgent) {
    return re.test(serverCallUserAgent);
  }
  return typeof window !== 'undefined' && re.test(window.navigator.userAgent);
}

export const composeValidators =
  (...validators) =>
  (value, formValues) =>
    validators.reduce((error, validator) => error || validator(value, formValues), undefined);

export const sleep = milliseconds => {
  return new Promise(resolve => window.setTimeout(resolve, milliseconds));
};

export const formateTimeTo12Hour = input => {
  const time = new Date(input);
  return time.toLocaleString('en-US', { hour: 'numeric', hour12: true });
};

export function formatDate(time, pattern, year = '') {
  let formattedString = '';
  if (isNaN(time)) return '';
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = new Date(time).getDate();
  const day = new Date(time).getDay();
  const month = new Date(time).getMonth();
  formattedString = pattern.replace('EEE', days[day].substr(0, 3));
  formattedString = formattedString.replace('MMM', months[month].substr(0, 3));
  // In case of December, Date will replace on second D other than Dec
  if (month === 11 && pattern.includes('D')) {
    const firstPart = formattedString.substr(0, pattern?.indexOf('D'));
    const lastPart = formattedString.substr(pattern?.indexOf('D') + 1);
    formattedString = firstPart + date + lastPart;
  } else {
    formattedString = formattedString.replace('D', date);
  }
  if (year) {
    formattedString = formattedString.concat(' ' + new Date(time).getFullYear());
  }
  return formattedString;
}

export function datesDifference(timeStamp) {
  const date = new Date(timeStamp);
  const todayDate = new Date();
  const difference = todayDate.getTime() - date.getTime();
  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  return daysDifference;
}

// eslint-disable-next-line no-unused-vars
export function canApplyWallet(redisCommonData) {
  // let applyWallet = !(redisCommonData.LKCashEnabled === 'OFF');
  // const is = localStorageHelper.getItem('applyWalletOnCart');
  // if (is) applyWallet = is === 'ON';
  // return applyWallet;
  const applyWallet = localStorageHelper.getItem('applyWalletOnCart');
  if (applyWallet === 'ON') {
    return true;
  }
  return false;
}

export function getLogo(cartData, redisCommonData = {}) {
  let dealskartLogo;
  const { RBI_POLICY_MARKETPLACE_CONFIG } = redisCommonData;
  try {
    const rbiMarketPolicy = RBI_POLICY_MARKETPLACE_CONFIG
      ? JSON.parse(RBI_POLICY_MARKETPLACE_CONFIG)
      : {};
    dealskartLogo = rbiMarketPolicy.checkout === 'ON' ? rbiMarketPolicy.msitedkLogo : null;
  } catch (error) {
    console.log(error);
  }
  const merchantId = cartData.merchantId ? cartData.merchantId.toLowerCase() : 'lenskart';
  if (merchantId === 'dealskart' && cartData.items && cartData.items.length) {
    return dealskartLogo;
  }
  return null;
}

export function getCardType(cardNo) {
  const cards = {
    visa: '^4[0-9]{6,}$',
    maestro: '^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$',
    diners: '^3(?:0[0-5]|[68][0-9])[0-9]{4,}$',
    jcb: '^(?:2131|1800|35[0-9]{3})[0-9]{3,}$',
    amex: '^3[47][0-9]{5,}$',
    discover: '^6(?:011|5[0-9]{2})[0-9]{3,}$',
  };
  const type = Object.keys(cards).find(key => {
    const regx = new RegExp(cards[key]);
    return regx.test(cardNo);
  });
  return type || '';
}

export function getClassification(type) {
  let classification;
  switch (type) {
    case 'eyeframe':
      classification = 'Eyeglasses';
      break;
    case 'sunglasses':
      classification = 'Sunglasses';
      break;
    case 'contact_lens':
      classification = 'Contact Lens';
      break;
    default:
      classification = 'Accessories';
  }
  return classification;
}

export function getAppUrl(adSet, afUrl) {
  const location = window.location;
  let utmS = getSetCookie.getCookie('utm_source');
  utmS = utmS && utmS !== 'null' ? utmS : '';
  let utmCH = getSetCookie.getCookie('utm_campaign') || '';
  utmCH = utmCH && utmCH !== 'null' ? utmCH : '';
  let utmM = getSetCookie.getCookie('utm_medium') || '';
  utmM = utmM && utmM !== 'null' ? utmM : '';
  let utmC = getSetCookie.getCookie('utm_content') || '';
  utmC = utmC && utmC !== 'null' ? utmC : '';
  let partnerId = utmS;
  if (partnerId.toLowerCase() === 'facebook') {
    partnerId = 'FB';
  } else if (partnerId.toLowerCase() === 'google') {
    partnerId = 'GAds';
  }
  const pid = partnerId || 'msite';
  let pageUrl = location.href;
  pageUrl = pageUrl
    .replace('.sg', '.com')
    .replace('.ae', '.com')
    .replace('sa.', '')
    .replace('live.', '')
    .replace('preprod.', '');
  const index = pageUrl?.indexOf('lenskart.');
  const wwwIndex = pageUrl?.indexOf('www');

  if (wwwIndex === -1 && index !== -1)
    pageUrl = pageUrl.slice(0, index) + 'www.' + pageUrl.slice(index);

  const url =
    'https://lenskart.onelink.me/747387224?pid=' +
    pid +
    '&af_dp=' +
    (afUrl || pageUrl) +
    '&af_web_dp=' +
    (afUrl || pageUrl) +
    '&utm_source=' +
    utmS +
    '&utm_medium=' +
    utmM +
    '&utm_campaign=' +
    utmCH +
    '&utm_content=' +
    utmC +
    '&af_siteid=' +
    utmC +
    '&af_adset=' +
    adSet +
    '&is_retargeting=true&c=' +
    utmCH +
    '&af_channel=' +
    utmM +
    '&deep_link_value=' +
    (afUrl || pageUrl);
  return url;
}

export function getAppUrlSingapore(adSet, productId, isProgressive) {
  const { getCookie } = getSetCookie;
  // let utmSource = getCookie('utm_source') || '';
  // utmSource = utmSource !== 'null' ? utmSource : '';
  let utmCampaign = isProgressive
    ? 'msite_button_web_pdp_progressivelense_15_Jun_2022_NA'
    : getCookie('utm_campaign') || '';
  utmCampaign = utmCampaign !== 'null' ? utmCampaign : '';
  // let utmMedium = getCookie('utm_medium') || '';
  // utmMedium = utmMedium !== 'null' ? utmMedium : '';
  let utmContent = getCookie('utm_content') || '';
  utmContent = utmContent !== 'null' ? utmContent : '';

  // TODO: make this configurable
  const productLink = productId ? `product/${productId}` : '';
  const mobileUrl = `https://www.lenskart.com/${productLink}`;
  const url = 'https://ej3gt.app.goo.gl/?link='
    .concat(mobileUrl)
    .concat('&apn=com.lenskart.app&ibi=com.valyoo.lenskart&isi=970343205')
    .concat(`&utm_source=msite`)
    .concat(`&utm_medium=buyonapppdp`)
    .concat(`&utm_campaign=${utmCampaign?.length > 0 ? utmCampaign : 'msite_buyonapppdp'}`)
    .concat(`&utm_content=${utmContent}`)
    .concat(`&af_adset=${adSet}`);
  return url;
}

export function getOSType() {
  const ua = window.navigator.userAgent;
  let osType = '';
  if (/Android/i.test(ua)) {
    osType = 'android';
  } else if (/iPhone/i.test(ua)) {
    osType = 'ios';
  }
  return osType;
}

export function replaceUnderscore(text) {
  return text.replace('_', ' ');
}

export function checkElementExist(defaultTimeout, query, cb) {
  let _counter = 100;
  const checkExist = setInterval(() => {
    if (document.querySelector(query) || _counter > defaultTimeout) {
      if (cb) cb(document.querySelector(query));
      clearInterval(checkExist);
    }
    _counter += 100;
  }, 100);
}

export function checkPriceRange(keyprices, lenskartPrice) {
  return keyprices.find(sp => {
    const pricerange = sp.split('_');
    return (
      (lenskartPrice >= pricerange[0] && lenskartPrice <= pricerange[1]) ||
      (lenskartPrice === pricerange[0] && pricerange[1] === null)
    ); // eslint-disable-line
  });
}
export function calcGSTPrice(classification, brandName, frameType, lenskartPrice, PRODUCTS_GST) {
  let gsttotal;
  let finalprice = lenskartPrice;
  const ftype = frameType ? frameType.toLowerCase() : '_';
  if (PRODUCTS_GST) {
    gsttotal =
      PRODUCTS_GST[`non_fff_${classification}_${brandName}_${ftype}`] ||
      PRODUCTS_GST[`${classification}_${brandName}_${ftype}`] ||
      PRODUCTS_GST[`${classification}_${brandName}`];
    // set default GST based on Product Type
    if (gsttotal == null && PRODUCTS_GST[classification]) {
      gsttotal = PRODUCTS_GST[classification] || 0;
    }
    finalprice = calcPriceWithGST(lenskartPrice, Number(gsttotal));
  }
  return finalprice;
}

export function resetOrientation(srcBase64, srcOrientation, callback) {
  const img = new Image();

  img.onload = function () {
    const width = img.width;
    const height = img.height;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set proper canvas dimensions before transform & export
    if (srcOrientation > 4 && srcOrientation < 9) {
      canvas.width = height;
      canvas.height = width;
    } else {
      canvas.width = width;
      canvas.height = height;
    }

    // transform context before drawing image
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, width, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, width, height);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, height);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, height, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, height, width);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, width);
        break;
      default:
        break;
    }

    // draw image
    ctx.drawImage(img, 0, 0);

    // export base64
    callback(canvas.toDataURL('image/jpeg'));
  };

  img.src = srcBase64;
}

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}
// export const preConnectOrigins = [
//     '//www.google-analytics.com',
//     '//www.googletagmanager.com',
// ];
export const preFetchOrigins = [
  '//www.google-analytics.com',
  '//www.googletagmanager.com',
  '//static.lenskart.com',
];

export function getGeocodeDetails(data) {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(data, (results, status) => {
      if (status === 'OK') {
        resolve(results[0]);
      } else {
        reject(new Error('No Result Found..'));
      }
    });
  });
}

export function getAddressFromPlaces(place) {
  const location = (place.geometry && place.geometry.location) || {};
  const lat = typeof location.lat === 'function' ? location.lat() : undefined;
  const lng = typeof location.lng === 'function' ? location.lng() : undefined;
  if (place && place.address_components && place.address_components.length) {
    const a = place.address_components.reduce((memo, c) => {
      memo[c.types[0]] = c.long_name;
      return memo;
    }, {});
    return {
      lat,
      lng,
      postcode: a.postal_code,
      city: a.locality || a.administrative_area_level_2 || a.administrative_area_level_1,
      state: a.administrative_area_level_1,
      country: a.country,
      formattedAddress: place.formatted_address,
    };
  }
  return {
    formattedAddress: place ? place.name : '',
    lat,
    lng,
  };
}

export function getUtmParameters() {
  const utmSource = getSetCookie.getCookie('__utmz');
  let utmSourceValue = null;
  let utmCamValue = null;
  let utmMediumValue = null;
  let utmTermValue = null;
  let utmContentValue = null;

  if (utmSource) {
    const utmSourceStr = utmSource.split('|');
    utmSourceValue = utmSourceStr[0]?.split('=')[1];
    utmCamValue = utmSourceStr[1]?.split('=')[1];
    utmMediumValue = utmSourceStr[2]?.split('=')[1];
    utmTermValue = utmSourceStr[3]?.split('=')[1];
    utmContentValue = utmSourceStr[4]?.split('=')[1];
  }
  return {
    utmSourceValue,
    utmCamValue,
    utmMediumValue,
    utmTermValue,
    utmContentValue,
  };
}

export function setDate() {
  const today = new Date();
  if (today.getHours() > 21) {
    today.setDate(today.getDate() + 1);
    today.setHours(0, 0, 0, 0);
  }
  return today;
}

export function getMinDate(day) {
  let date = String(day.getDate());
  let month = String(day.getMonth() + 1);
  const year = day.getFullYear();
  if (date.length < 2) {
    date = `0${date}`;
  }
  if (month.length < 2) {
    month = `0${month}`;
  }
  return `${String(year)}-${month}-${date}`;
}

export function getNearByStoreTimeSlots() {
  return [
    { startHour: 9, endHour: 12, value: '9 AM - 12 PM', label: '09:00 AM - 12:00 PM' },
    { startHour: 12, endHour: 15, value: '12 PM - 3 PM', label: '12:00 PM - 03:00 PM' },
    { startHour: 15, endHour: 18, value: '3 PM - 6 PM', label: '03:00 PM - 06:00 PM' },
    { startHour: 18, endHour: 21, value: '6 PM - 9 PM', label: '06:00 PM - 09:00 PM' },
  ];
}

/* eslint-disable */
export function generateHash(userInfo){
  if(Object.keys(userInfo).length === 0) return '';
  const { firstName, lastName, email, telephone, emailId, phoneNumber /*, id */ } = userInfo;
  var key = 'e3647512-219c-46ab-8f2b-3a1068a15c14'
  var userDetails = `${telephone || phoneNumber || ''}_${firstName || ''}_${lastName || ''}__${telephone || phoneNumber || ''}_${email || emailId || ''}`;
  var hash = CryptoJs.HmacSHA256(userDetails, key).toString(CryptoJs.enc.Hex);

  return hash;
}
export function updateChatBotParams( userInfo, platform ){
    const { firstName, lastName, telephone, email, countryCode, id, orderNo, phoneNumber, emailId } = userInfo;
  window && window.sprChat && window.sprChat('updateUserSettings', {
    "user": {
        "id": telephone || phoneNumber || '', //mandatory
        "firstName": firstName || '', //optional, can send empty
        "lastName": lastName || '', //optional, can send empty
        "profileImageUrl": "",//optional, can send empty
        "phoneNo": telephone || phoneNumber || '', //optional
        "email": email || emailId || '', //optional
        "hash": generateHash(userInfo) //mandatory → you need to generate your own
    },
   "userContext": {
      "_c_628fa2a6e10d7d26ffae932e": orderNo || "", // order_no
      "_c_628fa2e3e10d7d26ffae99c9": "", // unique_id
      "_c_628fa30ee10d7d26ffae9d66": "", // action
      "_c_629059cde7872557aeec559f": "", // customer_id
      "_c_628fa33ae10d7d26ffaea0b0": process.env.BUILD_ENV === 'development' ? 'preprod' : 'prod', // env
      "_c_628fa36be10d7d26ffaea88b": "", // username
      "_c_628fa39ae10d7d26ffaead3d": "", // External_Conversation_ID
      "_c_628fa3c5e10d7d26ffaeb1a3": "", // External_Customer_ID
      "_c_628fa3f7e10d7d26ffaeb7a9": "", // auth_id
      "_c_628fa430e10d7d26ffaebb90": "",  // pincode
      "_c_628fa46ae10d7d26ffaec0e6": "",  // auth_code
      "_c_5cc9a7d0e4b01904c8dfc965": "",  // language
      "_c_62a1a40fd7c1ad35cd4a6323": platform || "",  // device
      "_c_628fa497e10d7d26ffaec4c9": countryCode || ""  // country
   }
});
}


export function initSprinklrChatBot(userInfo = {}, device = false, loginStatus = false){
  const {  telephone, firstName, lastName, email, orderNo, countryCode, platform, phoneNumber, emailId } = userInfo;
  window.sprChatSettings = window.sprChatSettings || {};
window.sprChatSettings = {
"appId": "626fcf8563ac4521314470a6_app_600019149",
"skin": "MODERN",
"landingScreen": "LAST_CONVERSATION",
...(device && {device: "MOBILE"}),
...(loginStatus && {"user": {
        "id": telephone || phoneNumber || '', //mandatory - can send phone number
        "firstName": firstName || '', //optional, can send empty
        "lastName": lastName || '', //optional, can send empty
        "profileImageUrl": "",//optional, can send empty
        "phoneNo": telephone || phoneNumber || '', //optional
        "email": email || emailId || '', //optional
        "hash": generateHash(userInfo) //mandatory - you need to generate your own
    }}),
...(loginStatus && {"userContext": {
      "_c_628fa2a6e10d7d26ffae932e": orderNo || "", // order_no
      "_c_628fa2e3e10d7d26ffae99c9": "", // unique_id
      "_c_628fa30ee10d7d26ffae9d66": "", // action
      "_c_629059cde7872557aeec559f": "", // customer_id
      "_c_628fa33ae10d7d26ffaea0b0": process.env.BUILD_ENV === 'development' ? 'preprod' : 'prod', // env
      // "_c_628fa33ae10d7d26ffaea0b0": "", // env
      "_c_628fa36be10d7d26ffaea88b": "", // username
      "_c_628fa39ae10d7d26ffaead3d": "", // External_Conversation_ID
      "_c_628fa3c5e10d7d26ffaeb1a3": "", // External_Customer_ID
      "_c_628fa3f7e10d7d26ffaeb7a9": "", // auth_id
      "_c_628fa430e10d7d26ffaebb90": "",  // pincode
      "_c_628fa46ae10d7d26ffaec0e6": "",  // auth_code
      "_c_5cc9a7d0e4b01904c8dfc965": "",  // language
      "_c_62a1a40fd7c1ad35cd4a6323": platform || "",  // device
      "_c_628fa497e10d7d26ffaec4c9": countryCode || ""  // country
      // "_c_628fa497e10d7d26ffaec4c9": ""  // country
   }})
};
(function(){var t=window,e=t.sprChat,a=e&&!!e.loaded,n=document,r=function(){r.m(arguments)};r.q=[],r.m=function(t){r.q.push(t)},t.sprChat=a?e:r;var o=function(){var e=n.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://prod4-live-chat.sprinklr.com/api/livechat/handshake/widget/"+t.sprChatSettings.appId,e.onerror=function(){t.sprChat.loaded=!1},e.onload=function(){t.sprChat.loaded=!0};var a=n.getElementsByTagName("script")[0];a.parentNode.insertBefore(e,a)};"function"==typeof e?a?e("update",t.sprChatSettings):o():"loading"!==n.readyState?o():n.addEventListener("DOMContentLoaded",o)})()
}

export function initChatBot() {
  //  Script for ChatBot intitation
  (function (d, w, c) {
    if (!d.getElementById('spd-busns-spt')) {
      var n = d.getElementsByTagName('script')[0],
        s = d.createElement('script');
      var loaded = false;
      s.id = 'spd-busns-spt';
      s.defer = 'defer';
      s.setAttribute('data-self-init', 'false');
      s.setAttribute('data-init-type', 'delayed');
      s.src = 'https://cdn.freshbots.ai/assets/share/js/freshbots.min.js';
      s.setAttribute('data-client', '2c1b964cbb34f33e12b48a7d141ee51929d60aea');
      s.setAttribute('data-bot-hash', '98296e8067b0c18916c200631613d3c5963c8591');
      s.setAttribute('data-spa-app', 'true');
      s.setAttribute('data-env', 'prod');
      s.setAttribute('data-region', 'us');
      if (c) {
        s.onreadystatechange = s.onload = function () {
          if (!loaded) {
            c();
          }
          loaded = true;
        };
      }
      n.parentNode.insertBefore(s, n);
    }
  })(document, window, function () {
    Freshbots.initiateWidget(
      {
        autoInitChat: false,
        getClientParams: function () {
          const {
            chatBotParams: {
              orderNo,
              emailId,
              phoneNumber,
              sessionID,
              action,
              uniqueId,
              apiClient,
              env,
            } = {},
          } = window;
          return {
            order_no: orderNo,
            'sn::cstmr::id': uniqueId || phoneNumber,
            'cstmr::eml': emailId,
            unique_id: uniqueId,
            xtrnlTcktId: orderNo || uniqueId || phoneNumber,
            'cstmr::phn': phoneNumber,
            action: action,
            auth_token: sessionID,
            env: env,
            device: apiClient,
          };
        },
      },
      function (successResponse) {},
      function (errorResponse) {}
    );
  });
  // Script ends here
}
/* eslint-enable */

export function getUserEventData(eventName) {
  const utmSource = localStorageHelper.getItem('utmSource');
  const whatsAppOptInId = localStorageHelper.getItem('whatsAppOptInId');
  const gaClientId = getSetCookie.getCookie('_ga');
  const data = {
    event: eventName,
    gaClientId: gaClientId || '',
    whatsappOptInId: whatsAppOptInId || '',
    details: {
      utm: {
        utm_campaign: utmSource?.utm_campaign || '(direct)',
        utm_medium: utmSource?.utm_medium || '(none)',
        utm_source: utmSource?.utm_source || 'null',
      },
    },
  };
  return data;
}

export function getRefundExchangeEventData({
  event,
  eventAct,
  eventActSuffix,
  eventLev,
  pnameSuffix,
}) {
  const eventData = getSetCookie.getCookie('evtD'); // localStorageHelper.getItem('eventData') || {};
  const {
    eA: eAct = '',
    rT: returnType = '',
    pId: productId = 0,
    cl: classification = '',
  } = eventData ? JSON.parse(eventData) : {};
  const data = {
    event,
    eventAct: eventActSuffix ? `${eAct} ${eventActSuffix}` : eventAct,
    eventLev,
    pname: `${returnType}|${pnameSuffix}`,
    pcategory: classification,
    pid: Number(productId),
    returnType,
  };
  return data;
}

export function getViewPolicyEventData(ctanamePrefix, channel) {
  const data = {
    event: 'cta_click',
    ctaname: `${ctanamePrefix}view policy`,
    channel,
  };
  return data;
}

export function triggerV2Event(orderDetails) {
  if (window.dtm.LenskartRewamp) {
    window.dtm.LenskartRewamp.success.load.ecommOrderSuccessV2(orderDetails);
  }
  return true;
}
export function getProductReturnStatus() {
  const returnProductDetails = sessionStorageHelper.getItem('returnProduct') || '';
  const dispatchPoint = sessionStorageHelper.getItem('returnOrderDispatchMapping') || '';
  // const dispatchPoint = returnEligibiliyDetails?.exchangeDispatchPointMapping || {};
  let returnStatus = '';
  if (returnProductDetails && returnProductDetails.returnMode) {
    if (returnProductDetails.returnMode === 'store_return') {
      returnStatus = dispatchPoint?.return_at_store;
    } else if (returnProductDetails.returnMode === 'schedule_pickup') {
      returnStatus = dispatchPoint?.courier_pickup;
    } else {
      returnStatus = dispatchPoint?.return_at_wh;
    }
  }
  return returnStatus;
}

// To Scroll to top on given ID
export function scrollToTopById(id = '') {
  const ele = document.getElementById(id);
  if (ele) ele.scrollIntoView();
}

export function setDataAttr(gaObj) {
  const finalAttr = {};
  Object.entries(gaObj).forEach(([key, value]) => {
    const newKey = `data-${key}`;
    return Object.assign(finalAttr, { [newKey]: value });
  });
  return finalAttr;
}

function getEventFromElem(e) {
  const elemObj = e.target.attributes;
  const gaObj = {};
  Object.entries(elemObj).forEach(([key, entry]) => {
    if (entry.name?.indexOf('data-') > -1 && entry.name !== 'data-src' && key >= 0) {
      const entryKey = entry.name.replace('data-', '');
      Object.assign(gaObj, { [entryKey]: entry.value });
    }
  });
  const shouldTriggerGA = Object.values(gaObj).some(value => value.length);
  if (shouldTriggerGA) {
    pushDataLayerEvent(gaObj);
  }
}

export function setGAEventListener() {
  document.querySelector('body').addEventListener(
    'click',
    evt => {
      if (evt.target.classList.contains('getGaData') && evt.target.attributes) {
        try {
          getEventFromElem(evt);
        } catch (err) {
          console.error(err.message);
        }
        // IPL offer modal
        // Add classname "ipl-unlock-offer" to the img by which have to open offer modal
        // and in anchor tag href = 'javascript:void(0)'
        if (evt.target.classList.contains('ipl-unlock-offer')) {
          const iplUnloadOfferEvent = new Event('iplUnloadOfferEvent');
          document.dispatchEvent(iplUnloadOfferEvent); // To trigger the Event
        }
      }
    },
    true
  );
}

export function hideRepeatUserElem(isRepeatUser, hiddenElem) {
  const len = (hiddenElem && hiddenElem.length) || null;
  if (!len) return;
  for (let i = 0; i < len; i++) {
    const element = hiddenElem[i];
    const hasClass = element.classList.contains('display-none');
    if (isRepeatUser && !hasClass) {
      element.classList.add('display-none');
    } else {
      element.classList.remove('display-none');
    }
  }
}

export function deleteExchangeStorageData() {
  // mobile
  getSetCookie.deleteCookie('exchangeMethod');
  getSetCookie.deleteCookie('exchangeOrderId');
  getSetCookie.deleteCookie('exchangeItemId');
  sessionStorageHelper.removeItem('returnAction');
  // desktop
  sessionStorageHelper.removeItem('primaryReason');
  sessionStorageHelper.removeItem('exchangeOrderId');
  sessionStorageHelper.removeItem('returnUserAddress');
  sessionStorageHelper.removeItem('returnStep');
  sessionStorageHelper.removeItem('returnProduct');
  sessionStorageHelper.removeItem('exchangeNP');
  sessionStorageHelper.removeItem('returnStore');
  sessionStorageHelper.removeItem('exchangePowerDetails');
  sessionStorageHelper.removeItem('isExchangeOnlyCase');
  sessionStorageHelper.removeItem('selectedPowerType');
  sessionStorageHelper.removeItem('selectedPower');
  sessionStorageHelper.removeItem('powerMethod');
  sessionStorageHelper.removeItem('showPowerFailModal');
  getSetCookie.deleteCookie('exchangeMethod');
}

export function onlyNumbersEntered(value) {
  return /^[0-9]*$/.test(value);
}

export function emailCriteriaFulfilled(value) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export const discountCalculator = prices => {
  if (prices.length === 0 || prices.length === 1) {
    return 0;
  }
  let marketPrice = prices.filter(price => price.name === 'Market Price')[0];
  marketPrice = marketPrice.price || marketPrice.value;
  let lenskartPrice = prices.filter(price => price.name === 'Lenskart Price')[0];
  lenskartPrice = lenskartPrice.price || lenskartPrice.value;
  return marketPrice - lenskartPrice;
};

// TODO:
export const getProductType = sku => {
  if (sku && typeof sku === 'string') {
    let prodType = '';
    if (sku && sku?.indexOf('eye:') > -1) prodType = 'eyeglass';
    else if (sku && sku?.indexOf('sun:') > -1) prodType = 'sunglass';
    else if (sku && sku?.indexOf('contact:') > -1) prodType = 'contact lens';
    else if (sku && sku?.indexOf('watch:') > -1) prodType = 'accessories';
    else if (sku && sku?.indexOf('service:') > -1) prodType = 'membership';
    else prodType = '';
    return prodType;
  }
  return '';
};
export const getCategoryType = categoryName => {
  let prodType = '';

  if (typeof categoryName === 'string') {
    if (categoryName.toLowerCase()?.indexOf('eye') > -1) prodType = 'eyeglass';
    if (categoryName.toLowerCase()?.indexOf('gift') > -1) prodType = 'giftcard';
    else if (categoryName.toLowerCase()?.indexOf('sun') > -1) prodType = 'sunglass';
    else if (categoryName.toLowerCase()?.indexOf('contact') > -1) prodType = 'contact lens';
    else if (categoryName.toLowerCase()?.indexOf('watch') > -1) prodType = 'accessories';
    else if (categoryName.toLowerCase()?.indexOf('service') > -1) prodType = 'membership';
  }

  return prodType;
};

export const getOrderContent = order => {
  let cartHasMembership = false;
  let cartHasProducts = false;
  if (order?.items?.length) {
    cartHasMembership =
      order?.items?.filter(item => getProductType(item?.sku) === 'membership').length > 0;
    cartHasProducts =
      order?.items?.filter(item => getProductType(item?.sku) !== 'membership').length > 0;
  }
  if (cartHasMembership && cartHasProducts) return 'membership-and-product';
  else if (cartHasMembership) return 'membership-only';
  else if (cartHasProducts) return 'product-only';
  return '';
};

export const getOdProductCategories = product => {
  const result = {
    item_category2: '',
    item_category3: '',
    item_category4: '',
  };

  if (
    product?.lensType?.toLowerCase() === 'services' ||
    product?.lensType === 'CONTACT_LENS' ||
    !product?.lensType
  ) {
    return result;
  }

  result.item_category2 = product.lensType;

  if (product?.prescription?.powerType) {
    result.item_category3 = product?.options[0]?.name;
    result.item_category4 = product?.options?.filter(option => option.type === 'COATING')?.length
      ? 'yes'
      : 'no';
  } else {
    result.item_category3 = '';
    result.item_category4 = '';
  }

  return result;
};

export const getProductCategories = (product, ga4Product) => {
  if (
    product?.lensType?.toLowerCase() === 'services' ||
    product?.lensType === 'CONTACT_LENS' ||
    !product?.lensType
  ) {
    ga4Product.item_category2 = '';
    ga4Product.item_category3 = '';
    ga4Product.item_category4 = '';
  } else {
    ga4Product.item_category2 = product.lensType;
    if (product?.prescription?.powerType) {
      ga4Product.item_category3 = product?.options[0]?.name;
      ga4Product.item_category4 = product?.options?.filter(option => option.type === 'COATING')
        ?.length
        ? 'yes'
        : 'no';
    } else {
      ga4Product.item_category3 = '';
      ga4Product.item_category4 = '';
    }
  }
};

function addDays(date, days) {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return copy;
}

export const addLoyaltyData = (order, userInfo, eventData) => {
  const cartHasMembership =
    order?.items?.filter(item => getProductType(item?.sku) === 'membership').length > 0;
  if (cartHasMembership) {
    eventData.loyalty_start_date = new Date().toLocaleString('sv').split(' ')[0];

    eventData.loyalty_end_date = addDays(
      new Date(),
      order?.items?.filter(item => getProductType(item?.sku) === 'membership')[0]
        ?.tierMembershipDuration
    )
      ?.toLocaleString('sv')
      ?.split(' ')[0];
  }
  eventData.user_loyalty = userInfo?.tierLabel
    ? (userInfo?.tierLabel + ' membership')?.toLowerCase()
    : 'na';
};

export const getMembershipDiscount = cartData => {
  const goldDiscount = cartData?.finalTotal?.filter(cartValue => cartValue?.id === 'goldDiscount');
  if (goldDiscount?.length) {
    return goldDiscount[0]?.value;
  }
  return 0;
};

export const hasContactLensItems = (cartItems = []) => {
  return cartItems.some(cartItem => cartItem?.classification === 'contact_lens');
};

export const lazyLoadImgs = rootMargin => {
  if ('IntersectionObserver' in window) {
    const lazyImageObserver = new IntersectionObserver(
      function (entries, observer) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            if (lazyImage.dataset.src || lazyImage.dataset.bgimage) {
              const original = lazyImage.dataset.src || lazyImage.dataset.bgimage;
              lazyImage.src = original;
            }
            lazyImage.classList.remove('lazy-load-image');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      },
      {
        rootMargin: rootMargin || '0px',
      }
    );

    document
      .querySelectorAll('img.lazy-load-image, .bg-lazy, video.lazy-video')
      .forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
  } else {
    // Possibly fall back to event handlers here
    console.log('Error: lazyloadimgs: IntersectionObserver not present in Window.');
  }
};

export const getProductPrice = product => {
  const lenskartPrice = product?.prices[1]?.price;
  const firstBuyPrice = product?.prices?.length === 3 && product?.prices[2]?.price;
  const finalprice = product?.prices?.length === 3 ? firstBuyPrice : lenskartPrice;
  return finalprice;
};

export const capitalizeFirstLetter = string =>
  `${string?.charAt(0)?.toUpperCase()}${string?.slice(1)}`;

export const replaceLenskartBackendError = errorText =>
  errorText?.toLowerCase()?.includes('lenskart') ? 'Something went wrong' : errorText;

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
};

// export const sha256 = async text => {
//   // encode as UTF-8
//   const msgBuffer = new TextEncoder().encode(text);

//   // hash the text
//   const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

//   // convert ArrayBuffer to Array
//   const hashArray = Array.from(new Uint8Array(hashBuffer));

//   // convert bytes to hex string
//   const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

//   return hashHex;
// };

export const sha256 = async data => {
  const textAsBuffer = new TextEncoder().encode(data);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const digest = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return digest;
};

export const validateUserEmail = email => {
  const emails = [
    'diyanah@nesobrands.com',
    'paulo@nesobrands.com',
    'riyaz.khan@nesobrands.com',
    'akhil.kulkarni@nesobrands.com',
    'sandeep@nesobrands.com',
    'bjorn@nesobrands.com',
  ];

  if (emails.includes(email)) {
    return true;
  }
  return true;
};

export const showZendeskWidget = (url, isLensesSelectionPopupOpen) => {
  if (
    url === 'https://www.owndays.com/sg/en' ||
    url === 'https://preproduction.owndays.com/' ||
    isLensesSelectionPopupOpen
  ) {
    return false;
  }

  const pages = [
    'checkout',
    'payment-page',
    'login',
    'signup',
    'payment-success',
    'select-lenses',
    'cart',
  ];

  return !pages.some(page => url?.includes(page));
};
