import * as actionTypes from '../actionTypes/ditto';

export function addNewDitto(dittoId) {
  return { type: actionTypes.ADD_DITTO_LOAD, dittoId };
}

export function saveUpdateDitto(dittoId) {
  return { type: actionTypes.SAVE_UPDATE_DITTO_LOAD, dittoId };
}

export function uploadPhotoToCygnus(image, phNumber, phoneCode, clientName) {
  return { type: actionTypes.UPLOAD_IMAGE_TO_CYGNUS_LOAD, image, phNumber, phoneCode, clientName };
}

export function updateCustomerCygnus(cygnusId, clientName) {
  return { type: actionTypes.UPDATE_CUSTOMER_CYGNUS_LOAD, cygnusId, clientName };
}

export function getOverlayedDitto(productId, phNumber, phoneCode, dittoGuestId, clientName) {
  return {
    type: actionTypes.GET_IMAGE_FROM_CYGNUS_LOAD,
    productId,
    phNumber,
    phoneCode,
    dittoGuestId,
    clientName,
  };
}
