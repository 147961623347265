import React from 'react';
import './CarePage.scss';
import BannerOwndays from '../../../CommonComponents/BannerOwndays/BannerOwndays';
import MembershipSection from '../Membership/MembershipPoints/MembershipSection';
import MetaTagsManager from '../../../../CommonComponents/Helmet/MetaTagsManager';

const bannerData = {
  title: 'Enhance Your Spectacle Frame Warranty for 1 Year',
  img: {
    src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/care/od-care-banner.webp',
  },
  contentImg: {
    src: 'https://static.lenskart.com/media/owndays/desktop/img/owndays/care/od-care-plus.webp',
    alt: 'care plus',
    width: '299px',
    height: '122px',
  },
};

const CarePage = () => {
  return (
    <main className="od-care">
      <MetaTagsManager
        description="Care page | OWNDAYS Singapore"
        title="Care page | OWNDAYS Singapore"
      />
      <section className="od-care-banner">
        <BannerOwndays data={bannerData} />
      </section>
      <section className="od-care-pay-description">
        <div className="od-care-pay-description__text od-font-bold">
          Pay 10% of price indicated on the frame to enjoy!
        </div>
      </section>
      <MembershipSection
        isCare
        dataHeading={{
          title: 'Features',
        }}
      />
    </main>
  );
};

export default CarePage;
