import * as actionTypes from './../../actionTypes/common';
import config from 'config';
import localStorageHelper from '../../utils/localStorageHelper';
import getSetCookie from '../../cookie';
const initialState = {
  login: false,
  scrollingLock: false,
  isCartCount: 0,
  isWishlistCount: 0,
  isAlreadyLoyal: false,
  userInfo: null,
  loginError: null,
  commonError: null,
  redisCommonData: {},
  sharktankData: {},
  loginLoader: false,
  showCart: false,
  addToCartFailed: null,
  junoV2Session: null,
  dittoSaved: false,
  saveWishlist: false,
  currencyMark: {
    INR: '₹',
    SGD: '$',
    AED: 'AED',
    SAR: 'SAR',
    USD: '$',
  },
  utmMapping: {
    utmcsr: 'utm_source',
    utmcmd: 'utm_medium',
    utmccn: 'utm_campaign',
    utmctr: 'utm_term',
    utmcct: 'utm_content',
  },
  wishlistTrue: false,
  shortListBool: false,
  wishListDeleteSuccess: false,
  wishListAddSuccess: false,
  shortListData: {},
  toastMessage: null,
  productID: null,
  categoryID: null,
  redisKey: null,
  section: '',
  quickViewPid: null,
  dittoProfile: null,
  refreshDittoProfile: false,
  saveDittoName: null,
  dittoChanged: false,
  deleteDittoProfile: null,
  deleteDittoProccessing: false,
  redisLoading: true,
  redisLoaded: false,
  appLogo:
    'https://static1.lenskart.com/media/owndays/desktop/img/od-logos-new/logo-type-black.png',
  flowType: true,
  sizeQuiz: { sizeString: '', prefSize: '' },
  dittoAuth: null,
  dittoProfileAuthImages: null,
  askUserLogin: false,
  countryData: null,
  cmsPopUpData: '',
  isYotpoWidgets: false,
  isTryOnSwitch: false,
  customerInfo: null,
  embedVideo: null,
  localeInfo: null,
  leadGenDataLoading: false,
  leadGenData: null,
  leadGenDataSuccess: false,
  marketingSubscriptionLoading: false,
  marketingSubscriptionData: null,
  marketingSubscriptionSuccess: false,
  marketingSubscriptionError: false,
  getMarketingSubscriptionDataLoading: false,
  getMarketingSubscriptionData: null,
  getMarketingSubscriptionDataSuccess: false,
  getMarketingSubscriptionDataFail: false,
  getMarketingSubscriptionDataError: null,
  lensMappingData: [],
  plpMobileListView: true,
  pidFromBarcodeData: null,
  pidFromBarcodeDataLoading: null,
  pidFromBarcodeError: null,
  isBarcodeModalActive: false,
};

export default function common(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_REDIS_ROUTER_LOAD:
      return Object.assign({}, prevState, {
        redisLoading: true,
        redisLoaded: false,
        productID: action.categoryId ? prevState.productID : action.productId,
        categoryID: action.productId ? prevState.categoryID : action.categoryId,
        section: action.section || 'BlankLoader',
        isSupported: __CLIENT__ && typeof ditto !== 'undefined' ? window?.Ditto?.flowType : true,
        checkBrowser: __CLIENT__ && typeof ditto !== 'undefined' ? window?.Ditto?.flowType : true,
      });
    case actionTypes.SET_COUNTRY_INFO:
      return Object.assign({}, prevState, {
        localeInfo: action.data,
      });
    case actionTypes.GET_REDIS_ROUTER_SUCCESS:
      return Object.assign({}, prevState, {
        redisLoading: false,
        redisLoaded: true,
        section: action.data.section,
        productID: action.data.productId ? action.data.productId : prevState.productID,
        categoryID: action.data.categoryId ? action.data.categoryId : prevState.categoryID,
        redisKey: action.data.redisKey,
        serversideLoaded: __SERVER__,
      });
    case actionTypes.GET_REDIS_ROUTER_FAIL:
      return Object.assign({}, prevState, {
        section: 'na',
        productID: null,
        appLogo:
          'https://static1.lenskart.com/media/owndays/desktop/img/od-logos-new/logo-type-black.png',
        categoryID: null,
        redisLoading: false,
        redisLoaded: true,
        redisKey: null,
      });
    case actionTypes.GET_SHARKTANK_DATA:
      return Object.assign({}, prevState);
    case actionTypes.GET_SHARKTANK_SUCCESS:
      return Object.assign({}, prevState, {
        isDoctorSession: action.data.isDoctorSession ? action.data.isDoctorSession : false,
        sharktankCommonData: action.data.result,
      });
    case actionTypes.GET_SHARKTANK_FAIL:
      return Object.assign({}, prevState, { sharktankCommonData: action.error });
    case actionTypes.GET_REDIS_DATA:
      return Object.assign({}, prevState);
    case actionTypes.GET_REDIS_SUCCESS:
      return Object.assign({}, prevState, {
        isDoctorSession: action.data.isDoctorSession ? action.data.isDoctorSession : false,
        redisCommonData: action.data.result,
      });
    case actionTypes.GET_REDIS_FAIL:
      return Object.assign({}, prevState, { redisCommonData: action.error });
    case actionTypes.GET_USER:
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem(
          'userEmail',
          window.sessionStorage.getItem('userEmail')
            ? window.sessionStorage.getItem('userEmail')
            : ''
        );
        window.sessionStorage.setItem(
          'userPh',
          window.sessionStorage.getItem('userPh') ? window.sessionStorage.getItem('userPh') : ''
        );
      }
      return Object.assign({}, prevState, {
        userInfo: null,
        loginLoader: true,
        isAlreadyLoyal: false,
        isSupported: __CLIENT__ && typeof ditto !== 'undefined' ? window?.Ditto?.flowType : true,
        checkBrowser: __CLIENT__ && typeof ditto !== 'undefined' ? window?.Ditto?.flowType : true,
      });
    case actionTypes.GET_USER_SUCCESS:
      if (typeof window !== 'undefined') {
        const userInfo = action.data.result;
        window.sessionStorage.setItem('userEmail', userInfo.email ? userInfo.email : '');
        window.sessionStorage.setItem('userPh', userInfo.telephone ? userInfo.telephone : '');
      }
      return Object.assign({}, prevState, {
        login: action.data.result.login,
        loginLoader: false,
        isAlreadyLoyal: action.data && action.data.result && action.data.result.isLoyalty,
        isWishlistCount: action.data.result.isWishlistCount,
        isCartCount: action.data.result.isCartCount,
        userInfo: action.data,
        redisLoaded: true,
        redisLoading: false,
        askUserLogin: false,
      });
    case actionTypes.GET_USER_FAIL:
      return Object.assign({}, prevState, {
        login: false,
        userInfo: null,
        loginLoader: false,
        isAlreadyLoyal: false,
        errorCode: action.error.status,
        isWishlistCount: action.error.result ? action.error.result.isWishlistCount : 0,
        isCartCount: action.error.result ? action.error.result.isCartCount : 0,
        commonError: action.error,
      });
    case actionTypes.LOAD_JUNO_SESSION:
      return Object.assign({}, prevState, {
        saveWishlist: false,
        dittoSaved: false,
        junoV2Session: null,
        errorCode: null,
      });
    case actionTypes.LOAD_JUNO_SESSION_API_SUCCESS:
      return Object.assign({}, prevState, {
        sessionToken: action.data.result.id,
        junoV2Session: true,
        isSupported: __CLIENT__ && typeof ditto !== 'undefined' ? window?.Ditto?.flowType : true,
        checkBrowser: __CLIENT__ && typeof ditto !== 'undefined' ? window?.Ditto?.flowType : true,
      });
    case actionTypes.LOAD_JUNO_SESSION_API_FAIL:
      return Object.assign({}, prevState, { junoV2Session: action.error });
    case actionTypes.SHORTLIST_LOAD:
      console.log('actionTypes.SHORTLIST_LOAD');
      return Object.assign({}, prevState, {
        shortListLoading: true,
      });
    case actionTypes.SHORTLIST_SUCCESS:
      console.log('actionTypes.SHORTLIST_SUCCESS');
      return Object.assign({}, prevState, {
        shortListLoading: false,
        wishListDeleteSuccess: false,
        wishListAddSuccess: false,
        saveWishlist: true,
        shortListData: action.data
          ? { result: action.data.result }
          : { result: { productIds: [], productList: [], numberOfProducts: 0 } },
        error: null,
      });
    case actionTypes.SHORTLIST_FAIL:
      return Object.assign({}, prevState, {
        shortListLoading: false,
        wishListDeleteSuccess: false,
        wishListAddSuccess: false,
        saveWishlist: true,
        error: action.error,
        commonError: action.error,
      });
    case actionTypes.SHORTLIST_DELETE:
      return Object.assign({}, prevState, {
        shortListLoading: true,
        wishListDeleteSuccess: false,
      });
    case actionTypes.SHORTLIST_DELETE_SUCCESS:
      const prevProductList = {
        result: {
          productList: prevState.shortListData.result.productList,
          numberOfProducts: prevState.shortListData.result?.numberOfProducts,
        },
      };
      const newData = { result: Object.assign({}, action.data.result, prevProductList.result) };
      return Object.assign({}, prevState, {
        shortListLoading: false,
        wishListDeleteSuccess: true,
        isWishlistCount: action.data.result.numberOfProducts,
        shortListData: action.data.result.numberOfProducts
          ? newData
          : { result: { productIds: [], productList: [], numberOfProducts: 0 } },
      });
    case actionTypes.SHORTLIST_ADD:
      return Object.assign({}, prevState, {
        shortListLoading: true,
      });
    case actionTypes.SHORTLIST_ADD_SUCCESS:
      return Object.assign({}, prevState, {
        wishListDeleteSuccess: false,
        wishListAddSuccess: true,
        isWishlistCount: action.data.result.numberOfProducts,
        shortListData: action.data
          ? { result: action.data.result }
          : { result: { productIds: [], productList: [], numberOfProducts: 0 } },
      });
    case actionTypes.WISHLIST_TRUE:
      return Object.assign({}, prevState, {
        wishlistTrue: action.value,
      });
    case actionTypes.SHORTLIST_TYPE:
      return Object.assign({}, prevState, {
        shortListBool: action.shortListValue,
      });
    case actionTypes.CART_POPUP:
      return Object.assign({}, prevState, {
        showCart: action.value,
        addToCartFailed: action.value === false ? null : prevState.addToCartFailed,
      });
    case actionTypes.LOGIN_SUCCESS:
      if (__CLIENT__) {
        localStorageHelper.setItem('ng2-webstorage|userdetails', { loggedIn: 1 });
      }
      return Object.assign({}, prevState, {
        login: true,
      });
    case actionTypes.CHANGE_SECTION:
      return Object.assign({}, prevState, {
        section: action.value,
        redisLoaded: action.isDataLoaded || false,
        redisLoading: !action.isDataLoaded,
      });
    case actionTypes.DITTO_PROFILE_SUCCESS:
      return Object.assign({}, prevState, {
        dittoProfile: action.data,
        refreshDittoProfile: false,
        saveDittoName: null,
        dittoChanged: false,
        login: prevState.login ? prevState.login : false,
      });
    case actionTypes.DITTO_PROFILE_FAILURE:
      return Object.assign({}, prevState, {
        dittoProfile: action.error,
        saveDittoName: null,
      });
    case actionTypes.DITTO_NAME_SUCCESS:
      return Object.assign({}, prevState, {
        saveDittoName: action.data,
      });
    case actionTypes.DITTO_NAME_FAILURE:
      return Object.assign({}, prevState, {
        saveDittoName: action.error,
      });
    case actionTypes.DITTO_DEFAULT_SUCCESS:
      return Object.assign({}, prevState, {
        refreshDittoProfile: true,
      });
    case actionTypes.DITTO_DEFAULT_FAILURE:
      return Object.assign({}, prevState, {
        refreshDittoProfile: false,
      });
    case actionTypes.DITTO_CHANGED_STATUS_UPDATE:
      return Object.assign({}, prevState, {
        dittoChanged: action.changedState,
      });
    case actionTypes.DITTO_DELETE:
      return Object.assign({}, prevState, {
        deleteDittoProccessing: true,
      });
    case actionTypes.DITTO_DELETE_SUCCESS:
      return Object.assign({}, prevState, {
        deleteDittoProfile: action.data,
        dittoProfile: action.data,
        refreshDittoProfile: false,
        deleteDittoProccessing: false,
      });
    case actionTypes.DITTO_DELETE_FAILURE:
      return Object.assign({}, prevState, {
        deleteDittoProfile: action.error,
        refreshDittoProfile: false,
        dittoProfileAuthImages: null,
        dittoProfile: null,
        deleteDittoProccessing: false,
      });
    case actionTypes.DITTO_AUTH_SUCCESS:
      return Object.assign({}, prevState, {
        dittoAuth: action.data,
      });
    case actionTypes.DITTO_AUTH_FAILURE:
      return Object.assign({}, prevState, {
        dittoAuth: null,
      });
    case actionTypes.DITTO_AUTH_PROFILE:
      return Object.assign({}, prevState, {
        dittoProfileAuthImages: action.data,
      });
    case actionTypes.LOGOUT_USER:
      if (__CLIENT__) {
        localStorageHelper.setItem('ng2-webstorage|userdetails', { loggedIn: 0 });
      }
      return Object.assign({}, prevState, {
        login: false,
        isCartCount: 0,
        isWishlistCount: 0,
        dittoSaved: false,
        dittoProfile: null,
        refreshDittoProfile: false,
        saveDittoName: null,
        dittoChanged: false,
        deleteDittoProfile: null,
        shortListData: {
          result: { productIds: [], productList: [], numberOfProducts: 0 },
        },
        userInfo: null,
      });
    case actionTypes.UPDATE_CART_COUNT:
      return Object.assign({}, prevState, {
        isCartCount: action.data,
      });
    case actionTypes.MODIFY_DATA:
      return Object.assign({}, prevState, {
        ...action.data,
      });
    case actionTypes.SET_TOAST_MESSAGE:
      return Object.assign({}, prevState, {
        toastMessage: action.data,
      });
    case actionTypes.SET_SIZE_DETAIL:
      return Object.assign({}, prevState, {
        sizeQuiz: Object.assign({}, prevState.sizeQuiz, action.data),
      });
    case actionTypes.CHANGE_LOGO:
      const appLogo = config.rbiMarketPlaceConfig.defaultLogo;
      // const RBI_POLICY_MARKETPLACE_CONFIG =
      //   prevState.redisCommonData && prevState.redisCommonData.RBI_POLICY_MARKETPLACE_CONFIG
      //     ? JSON.parse(prevState.redisCommonData.RBI_POLICY_MARKETPLACE_CONFIG)
      //     : {};
      // if (config.rbiMarketPlaceConfig.type === 'mobile') {
      //   if (action.logoType === 'dealskart') {
      //     appLogo = RBI_POLICY_MARKETPLACE_CONFIG.msitedkLogo || '';
      //   }
      // } else if (action.logoType === 'dealskart') {
      //   appLogo = RBI_POLICY_MARKETPLACE_CONFIG.desktopdkLogo || '';
      //   if (action.pageType === 'checkout') {
      //     appLogo = RBI_POLICY_MARKETPLACE_CONFIG.desktopcheckoutdkLogo || '';
      //   }
      // } else if (action.pageType === 'checkout') {
      //   appLogo = 'https://static.lenskart.com/media/desktop/img/checkout_Logo.png';
      // }
      return Object.assign({}, prevState, {
        appLogo,
      });
      '#if CLIENT_TYPE !== "mobile"'; // eslint-disable-line
    case '@@router/LOCATION_CHANGE':
      if (prevState.serversideLoaded) {
        return Object.assign({}, prevState, {
          serversideLoaded: false,
        });
      }
      return Object.assign({}, prevState, {
        section: prevState.section === 'na' ? 'na' : '',
        redisLoaded: false,
        redisLoading: true,
      });
      '#endif'; // eslint-disable-line
    case actionTypes.ASK_USER_LOGIN:
      return Object.assign({}, prevState, {
        askUserLogin: action.data,
      });
    case actionTypes.COUNTRY_DATA_LOAD:
      return Object.assign({}, prevState, {
        countryData: null,
      });
    case actionTypes.COUNTRY_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        countryData: action.data,
      });
    case actionTypes.COUNTRY_DATA_FAIL:
      return Object.assign({}, prevState, {
        countryData: action.error,
      });
    case actionTypes.PDP_CMS_POPUP_LOAD:
      return Object.assign({}, prevState, {
        cmsPopUpData: '',
        cmsdataloading: true,
        cmsdatafetcherror: false,
        cmsdataError: null,
      });
    case actionTypes.PDP_CMS_POPUP_SUCCESS:
      return Object.assign({}, prevState, {
        cmsPopUpData: action.data,
        cmsdataloading: false,
        cmsdatafetcherror: false,
        cmsdataError: null,
      });
    case actionTypes.PDP_CMS_POPUP_FAIL:
      return Object.assign({}, prevState, {
        cmsPopUpData: '',
        cmsdataloading: false,
        cmsdatafetcherror: true,
        cmsdataError: action.error,
      });
    case actionTypes.YOTPO_WIDGETS_SHOW:
      return Object.assign({}, prevState, action.data);
    case actionTypes.TRY_ON_SWITCH:
      getSetCookie.setCookie('showMeDitto', JSON.stringify(action.data.isTryOnSwitch));
      return Object.assign({}, prevState, action.data);
    case actionTypes.GET_CUSTOMER_INFO_LOAD:
      return Object.assign({}, prevState, {
        customerInfo: null,
      });
    case actionTypes.GET_CUSTOMER_INFO_SUCCESS:
      return Object.assign({}, prevState, {
        customerInfo: action.data && action.data.result,
      });
    case actionTypes.GET_CUSTOMER_INFO_FAIL:
      return Object.assign({}, prevState, {
        customerInfo: null,
      });
    case actionTypes.EMBED_VIDEO:
      return Object.assign({}, prevState, action.data);
    case actionTypes.SET_IS_WEBVIEW:
      return Object.assign({}, prevState, {
        isWebView: true,
      });
    case actionTypes.IS_PHONEPEVIEW:
      return Object.assign({}, prevState, {
        isPhonepeView: action.flag,
      });
    case actionTypes.PASS_UTM_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        passUtmData: action.data,
      });
    case actionTypes.PASS_UTM_DATA_FAIL:
      return Object.assign({}, prevState, {
        passUtmData: action.error,
      });
    case actionTypes.LEAD_GEN_DATA_LOAD:
      return Object.assign({}, prevState, {
        leadGenDataLoading: true,
        leadGenDataSuccess: false,
      });
    case actionTypes.LEAD_GEN_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        leadGenData: action.data.result,
        leadGenDataLoading: false,
        leadGenDataSuccess: true,
      });
    case actionTypes.LEAD_GEN_DATA_FAIL:
      return Object.assign({}, prevState, {
        leadGenDataError: action.error.message,
        leadGenDataSuccess: false,
        leadGenDataLoading: false,
      });
    case actionTypes.MARKETING_SUBSCRIPTION_LOAD:
      return Object.assign({}, prevState, {
        marketingSubscriptionLoading: true,
        marketingSubscriptionSuccess: false,
      });
    case actionTypes.MARKETING_SUBSCRIPTION_SUCCESS:
      return Object.assign({}, prevState, {
        marketingSubscriptionLoading: false,
        marketingSubscriptionData: action.data.result,
        marketingSubscriptionSuccess: true,
      });
    case actionTypes.MARKETING_SUBSCRIPTION_FAIL:
      return Object.assign({}, prevState, {
        marketingSubscriptionLoading: false,
        marketingSubscriptionError: action.error.message,
        marketingSubscriptionSuccess: false,
      });
    case actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_LOAD:
      return Object.assign({}, prevState, {
        getMarketingSubscriptionDataLoading: true,
        getMarketingSubscriptionDataSuccess: false,
      });
    case actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        getMarketingSubscriptionDataLoading: false,
        getMarketingSubscriptionDataSuccess: true,
        getMarketingSubscriptionData: action.data,
      });
    case actionTypes.GET_MARKETING_SUBSCRIPTION_DATA_FAIL:
      return Object.assign({}, prevState, {
        getMarketingSubscriptionDataLoading: false,
        getMarketingSubscriptionDataFail: true,
        getMarketingSubscriptionDataSuccess: false,
        getMarketingSubscriptionDataError: action.error.message,
      });
    case actionTypes.GET_LENS_MAPPING_DATA:
      return Object.assign({}, prevState);
    case actionTypes.GET_LENS_MAPPING_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        lensMappingData: action.data.result,
      });
    case actionTypes.GET_LENS_MAPPING_DATA_FAIL:
      return Object.assign({}, prevState, { lensMappingData: action.error });
    case actionTypes.PLP_MOBILE_VIEW_LAYOUT_CHANGE:
      return Object.assign({}, prevState, { plpMobileListView: !prevState.plpMobileListView });

    case actionTypes.FETCH_PID_DATA_FROM_BARCODE_LOAD:
      return Object.assign({}, prevState, {
        pidFromBarcodeDataLoading: true,
        pidFromBarcodeData: null,
        pidFromBarcodeError: null,
      });
    case actionTypes.FETCH_PID_DATA_FROM_BARCODE_SUCCESS:
      return Object.assign({}, prevState, {
        pidFromBarcodeDataLoading: false,
        pidFromBarcodeData: action.data,
        pidFromBarcodeError: null,
      });
    case actionTypes.FETCH_PID_DATA_FROM_BARCODE_FAIL:
      return Object.assign({}, prevState, {
        pidFromBarcodeDataLoading: false,
        pidFromBarcodeData: null,
        pidFromBarcodeError: action.error,
      });
    case actionTypes.BARCODE_MODAL_STATE:
      return Object.assign({}, prevState, {
        isBarcodeModalActive: action.data,
      });
    default:
      return prevState;
  }
}
