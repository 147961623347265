import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const { errorMessage } = this.props;
    if (this.state.hasError) {
      if (errorMessage) {
        return <div className="errorBoundary alert alert-danger">{errorMessage}</div>;
      }
      return <div className="error-noMessage"></div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
