import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { settingsForMM1013B3S, settingsForMM1014B3S, settingsForMM1015B3S } from './configSliders';

import './BodyMemoryMetal.scss';

export const BodyMemoryMetal = ({ baseImgUrl }) => {
  const [currentOrderMM1013B3S, setCurrentOrderMM1013B3S] = useState(1);
  const [currentOrderMM1014B3S, setCurrentOrderMM1014B3S] = useState(1);
  const [currentOrderMM1015B3S, setCurrentOrderMM1015B3S] = useState(1);

  useEffect(() => {
    // Initialize Fancybox
    if (window.Fancybox) {
      window.Fancybox.bind("[data-fancybox='etc']", {});
    }
  }, []);

  const renderProducts = (sku, order) => {
    const products = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

    return products.map(product => (
      <div key={product.id}>
        <div className="product__img">
          <img alt={`${sku} ${product.code}`} src={`${baseImgUrl}/${sku}_C${order}_01.webp`} />
        </div>
        <div className="product__details">
          <p className="product__sku">
            {sku} C{order}
          </p>
          <a
            className="base-memory-metal__btn base-memory-metal__btn--brown-light"
            href={`/sg/en/memory-metal-${sku.toLowerCase()}-c${order}-eyeglasses.html`}
          >
            <span data-hover="More Details">More Details</span>
          </a>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="body-memory-metal">
        <section className="concept">
          <div className="base-memory-metal__container base-memory-metal__container--sm">
            <h2 data-aos className="base-memory-metal__title">
              <span>NiTi Shape Memory Alloy</span>
            </h2>
            <div className="concept__content">
              <div className="concept__movie">
                <a
                  className="concept__movie-link"
                  data-caption="Memory Metal PR"
                  data-fancybox="etc"
                  href="https://www.youtube.com/watch?v=mJWPHuYB324"
                >
                  <div className="concept__movie-icon">
                    <img
                      alt="Memory Metal NiTi Shape Memory Alloy"
                      src={`${baseImgUrl}/play.svg`}
                    />
                    <svg viewBox="0 0 198 198" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="98" cy="98" r="97" stroke="none"></circle>
                    </svg>
                  </div>
                  <img
                    alt="Memory Metal"
                    className="concept__movie-thumb"
                    src={`${baseImgUrl}/thumbnail-1.webp`}
                  />
                </a>
              </div>
              <p className="concept__text" data-aos="fade-up">
                A collection featuring Nitinol (NiTi) frames that are made using the latest ion
                plating (IP) process.
                <br />
                Nitinol is a shape memory alloy consisting of nickel and titanium. The material
                exhibits great elasticity and durability. It is able to retain its shape despite
                being bent by force.
                <br />
                Ion plating is applied on the frame surface to enhance durability and resistance to
                rust. This also prevents the frame coating from peeling or scratching while acting
                as a protective layer for people with metal allergies.
              </p>
            </div>
          </div>
          <p className="base-memory-metal__heading" data-aos="fade-right">
            NITINOL FRAMES
          </p>
        </section>

        <section className="testing">
          <div className="base-memory-metal__container base-memory-metal__container--sm">
            <h2 data-aos className="base-memory-metal__title">
              <span>
                Japanese Industrial Standards (JIS) <br className="u-sp-only" />
                Temple Performance Test
              </span>
            </h2>
            <div className="testing__content">
              <p className="testing__text" data-aos="fade-up">
                The elasticity and durability of Memory Metal are validated by bridge distortion and
                durability tests carried out based on Japanese Industrial Standards (JIS B
                7285:2016). In addition, a test conducted by the Japan Spectacles and Popular Optics
                Inspection Institute has shown that the temples are able to retain their shape
                without distortion despite being twisted over 10,000 times.
              </p>
            </div>
          </div>
          <p className="base-memory-metal__heading" data-aos="fade-left">
            GREAT ELASTICITY
          </p>
          <div className="testing__img" data-aos="fade-down">
            <img
              alt="Japanese Industrial Standards (JIS) Temple Performance Test"
              src={`${baseImgUrl}/main-2.webp`}
            />
          </div>
        </section>

        <section className="lineup">
          <div className="base-memory-metal__container base-memory-metal__container--sm">
            <h2 data-aos className="base-memory-metal__title">
              <span>Memory Metal Frames</span>
            </h2>
          </div>
          <div className="lineup__content">
            <p className="base-memory-metal__heading">
              <span data-aos="fade-right">LINEUP</span>
            </p>
            <div className="base-memory-metal__container">
              <p className="lineup__text" data-aos="fade-up">
                Introducing 3 new models! From the currently-trending polygonal styles to the
                always-chic rectangle styles, <br className="u-pc-only" />
                discover an array of designs to suit any occasion.
              </p>
              <ul className="lineup__list">
                <li className="lineup__list-item lineup__list-item--new" id="MM1013B-3S">
                  <Slider
                    {...settingsForMM1013B3S}
                    afterChange={index => setCurrentOrderMM1013B3S(index + 1)}
                    className="product product--slider"
                  >
                    {renderProducts('MM1013B-3S', currentOrderMM1013B3S)}
                  </Slider>
                </li>

                <li className="lineup__list-item lineup__list-item--new">
                  <Slider
                    {...settingsForMM1014B3S}
                    afterChange={index => setCurrentOrderMM1014B3S(index + 1)}
                    className="product product--slider product--for"
                  >
                    {renderProducts('MM1014B-3S', currentOrderMM1014B3S)}
                  </Slider>
                </li>

                <li className="lineup__list-item lineup__list-item--new">
                  <Slider
                    {...settingsForMM1015B3S}
                    afterChange={index => setCurrentOrderMM1015B3S(index + 1)}
                    className="product product--slider product--for"
                  >
                    {renderProducts('MM1015B-3S', currentOrderMM1015B3S)}
                  </Slider>
                </li>

                <li className="lineup__list-item">
                  <div className="product">
                    <div className="product__img">
                      <img alt="MM1001B-0S C3" src={`${baseImgUrl}/MM1001B-0S_C3.webp`} />
                    </div>
                    <div className="product__details">
                      <p className="product__sku">MM1001B-0S C3</p>
                      <a
                        className="base-memory-metal__btn base-memory-metal__btn--brown-light"
                        href="/sg/en/memory-metal-mm1001b-0s-c3-eyeglasses.html"
                      >
                        <span data-hover="More Details">More Details</span>
                      </a>
                    </div>
                  </div>
                </li>

                <li className="lineup__list-item id-none">
                  <div className="product">
                    <div className="product__img">
                      <img alt="MM1002B-0S C4" src={`${baseImgUrl}/MM1002B-0S_C4.webp`} />
                    </div>
                    <div className="product__details">
                      <p className="product__sku">MM1002B-0S C4</p>
                      <a
                        className="base-memory-metal__btn base-memory-metal__btn--brown-light"
                        href="/sg/en/memory-metal-mm1002b-0s-c4-eyeglasses.html"
                      >
                        <span data-hover="More Details">More Details</span>
                      </a>
                    </div>
                  </div>
                </li>

                <li className="lineup__list-item">
                  <div className="product">
                    <div className="product__img">
                      <img alt="MM1005B-0S C2" src={`${baseImgUrl}/MM1005B-0S_C2.webp`} />
                    </div>
                    <div className="product__details">
                      <p className="product__sku">MM1005B-0S C2</p>
                      <a
                        className="base-memory-metal__btn base-memory-metal__btn--brown-light"
                        href="/sg/en/memory-metal-mm1005b-0s-c2-eyeglasses.html"
                      >
                        <span data-hover="More Details">More Details</span>
                      </a>
                    </div>
                  </div>
                </li>

                <li className="lineup__list-item">
                  <div className="product">
                    <div className="product__img">
                      <img alt="MM1006B-0S C1" src={`${baseImgUrl}/MM1006B-0S_C1.webp`} />
                    </div>
                    <div className="product__details">
                      <p className="product__sku">MM1006B-0S C1</p>
                      <a
                        className="base-memory-metal__btn base-memory-metal__btn--brown-light"
                        href="/sg/en/memory-metal-mm1006b-0s-c1-eyeglasses.html"
                      >
                        <span data-hover="More Details">More Details</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="base-memory-metal__btn-group">
                <a
                  className="base-memory-metal__btn base-memory-metal__btn--brown"
                  href="/sg/en/eyeglasses/brands/MemoryMetal.html"
                >
                  <span data-hover="See All Memory Metal Products">
                    See All Memory Metal Products
                  </span>
                </a>
                <a
                  className="base-memory-metal__btn base-memory-metal__btn--brown"
                  href="/stores/sg"
                >
                  <span data-hover="Search For Nearest OWNDAYS Stores">
                    Search For Nearest OWNDAYS Stores
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
