import React from 'react';

export const LineupBanner = ({ id, title, subtitle, desc, imgPc, imgSp, isRight, baseImgUrl }) => (
  <div
    className={`body-owndays-sunglasses__lineup__type-container ${
      isRight
        ? 'body-owndays-sunglasses__lineup__type-container--right'
        : 'body-owndays-sunglasses__lineup__type-container--left'
    }`}
    id={id}
  >
    <div className="body-owndays-sunglasses__lineup__banner">
      <div className="base-owndays-sunglasses__container">
        <div
          className={`body-owndays-sunglasses__lineup__banner-text-container ${
            id === 'classic'
              ? 'body-owndays-sunglasses__lineup__banner-text-container--black-max-md'
              : ''
          }`}
        >
          <h2 className="body-owndays-sunglasses__lineup__banner-title">
            {title}
            <small>{subtitle}</small>
          </h2>
          <p className="body-owndays-sunglasses__lineup__banner-desc">{desc}</p>
        </div>
      </div>
      <picture>
        <source media="(min-width:768px)" srcSet={`${baseImgUrl}/${imgPc}.webp`} />
        <img
          alt={`${title} ${subtitle}`}
          className="lazyload"
          src={`${baseImgUrl}/${imgSp}.webp`}
        />
      </picture>
    </div>
  </div>
);
