import * as actionTypes from '../../actionTypes/dittoShare';

const initalState = {
  dittoShareData: null,
  dittoShareLoading: true,
};

export default function dittoShare(prevState = initalState, action) {
  switch (action.type) {
    case actionTypes.DITTO_SHARE_DATA_LOAD:
      return Object.assign({}, prevState, {
        dittoShareLoading: true,
        dittoShareData: null,
      });
    case actionTypes.DITTO_SHARE_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        dittoShareLoading: false,
        dittoShareData: action.data.result,
      });
    case actionTypes.DITTO_SHARE_DATA_FAIL:
      return Object.assign({}, prevState, {
        dittoShareLoading: false,
        dittoShareData: action.error,
      });
    case actionTypes.SET_DITTO_SHARE_FROM_STORAGE:
      return Object.assign({}, prevState, {
        dittoShareLoading: false,
        dittoShareData: { fileLocation: action.fileLocation, setLocalStorage: false },
      });
    default:
      return prevState;
  }
}
