import React from 'react';

const Share = ({ baseImgUrl, baseUrl, pageName }) => {
  const socialMediaLinks = [
    {
      title: 'x',
      href: `https://twitter.com/intent/ttweet?url=${baseUrl}${pageName}`,
    },
    {
      title: 'facebook',
      href: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}${pageName}`,
    },
    {
      title: 'line',
      href: `https://social-plugins.line.me/lineit/share?url=${baseUrl}${pageName}`,
    },
  ];
  return (
    <section className="share">
      <div className="l-hp__container">
        <h2 className="l-hp__title text-effect" data-aos="fade-up">
          <span>SHARE</span>
        </h2>
        <ul className="share__list">
          {socialMediaLinks.map((link, index) => (
            <li key={index} data-aos="fade-up">
              <a
                className={`share__list-link share__list-link--${link.title}`}
                href={link.href}
                rel="noopener noreferrer"
                target="_blank"
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
        <p className="share__copyright" data-aos="fade-up">
          <img alt="Harry Potter" src={`${baseImgUrl}copyright.svg`} />
          <span>
            All characters and elements © & ™ Warner Bros. <br />
            Entertainment Inc. WB SHIELD: © & ™ WBEI. Publishing Rights <br />© JKR. (s24)
          </span>
        </p>
      </div>
    </section>
  );
};

export default Share;
