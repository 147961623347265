export const ARTICLES = [
  {
    permalink: 'sg-003',
    date: '2024.11.14',
    img: 'https://static1.lenskart.com/media/owndays/img/articles/sg-003/main.webp',
    title: 'Top 4 Signs That Indicate You Should Get Your Eyes Checked',
    description: `Many vision problems are best addressed early. However, the symptoms of these issues are often not prominent, especially in the early stages. As such, it can be challenging for individuals to recognise that something might be wrong with their eyes. That is why regular eye examinations are essential for maintaining eye health.`
  },
  {
    permalink: 'sg-002',
    date: '2024.11.14',
    img: 'https://static1.lenskart.com/media/owndays/img/articles/sg-002/main.webp',
    title: 'How to Manage Myopia and Prevent It from Worsening',
    description: `As a parent, noticing your child's vision changing can be concerning. Myopia, or nearsightedness, is increasingly common among children today. While myopia has no known cure, you can successfully manage this vision-related condition with prescription glasses, myopia control lenses, or contact lenses. For children, early interventions, such as limiting screen time, can also help prevent myopia progression.`
  },
  {
    permalink: 'sg-001',
    date: '2024.11.14',
    img: 'https://static1.lenskart.com/media/owndays/img/articles/sg-001/main.webp',
    title: 'Your Guide To Adjusting To Progressive Lenses Effortlessly',
    description: `Are you still getting used to your new pair of progressive glasses? Fret not! As you adjust to your progressive lenses, it is normal to experience a difference compared to wearing your usual single vision lenses. We recommend wearing them at home for a few days to adjust before venturing out or driving. Beyond that, we also have several simple progressive lens adjustment tips to help you adapt to your new eyewear effortlessly.`
  }
];