import React, { Fragment } from 'react';

import './FooterOrthoKWebinar.scss';

export const FooterOrthoKWebinar = ({ baseImgUrl }) => {
  return (
    <Fragment>
      <section className="base-ortho-k-webinar__bg">
        <div className="footer-ortho-k-webinar__share__container">
          <h5 className="footer-ortho-k-webinar__share__title">Share</h5>

          <div className="footer-ortho-k-webinar__share__icons">
            <a
              href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/ortho-k-webinar"
              rel="noreferrer"
              target="_blank"
            >
              <img alt="x" src={`${baseImgUrl}/x.svg`} />
            </a>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/ortho-k-webinar"
              rel="noreferrer"
              target="_blank"
            >
              <img alt="FaceBook" src={`${baseImgUrl}/fb.svg`} />
            </a>
            <a
              href="https://wa.me/?text=https://www.owndays.com/sg/en/news/ortho-k-webinar"
              rel="noreferrer"
              target="_blank"
            >
              <img alt="WhatsApp" src={`${baseImgUrl}/whatsapp.svg`} />
            </a>
          </div>
        </div>
      </section>

      <section className="footer-ortho-k-webinar__sign-up">
        <div className="base-ortho-k-webinar__container">
          <a
            className="base-ortho-k-webinar__btn base-ortho-k-webinar__btn--green"
            href="https://forms.gle/PNT48cs9R2TQ5eYb7"
            rel="noreferrer"
            target="_blank"
          >
            Sign Up Now
          </a>
        </div>
      </section>
    </Fragment>
  );
};
