import React, { useState, useEffect } from 'react';

import { checkCustomersByToken, updateCustomerByCustomerNo } from '../../../servicesOwndaysNet';

import './FormPolicy.scss';

const OPTIONS = [
  { name: 'Email', isChecked: true, key: 'email' },
  { name: 'Text Message', isChecked: true, key: 'sms' },
];

export const FormPolicy = () => {
  const [listReceiveNews, setListReceiveNews] = useState(OPTIONS);
  const [isAgree, setIsAgree] = useState(false);
  const [customerData, setCustomerData] = useState(null);

  const toggleCheckboxChange = selectedItem => {
    setListReceiveNews(
      listReceiveNews.map(item =>
        item.name === selectedItem.name ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  const handleRadioChange = () => {
    setIsAgree(prevState => !prevState);
  };

  const handleSubmit = () => {
    if (!customerData) {
      alert('Unexpected error');
      return;
    }

    const {
      customerNo,
      storeCode,
      name,
      firstName,
      lastName,
      kana,
      firstKana,
      lastKana,
      telNo,
      birthDay,
      gender,
      isAgreedToSendWhatsApp,
      isAgreedToCall,
      hasInternalMemo,
      karteEmail,
    } = customerData;

    const updateData = {
      storeCode,
      name,
      firstName,
      lastName,
      kana,
      firstKana,
      lastKana,
      telNo,
      birthDay,
      gender,
      karteEmail,
      isAgreedToSendWhatsApp,
      isAgreedToCall,
      hasInternalMemo,
      isAgreedToSendMail: listReceiveNews.find(item => item.key === 'email').isChecked,
      isAgreedToSendSMS: listReceiveNews.find(item => item.key === 'sms').isChecked,
      isAgreedToPrivacyPolicy: isAgree,
    };

    updateCustomerByCustomerNo(customerNo, updateData);
  };

  useEffect(() => {
    checkCustomersByToken(setCustomerData);
  }, []);

  return (
    <div className="form-policy__container">
      <div className="form-policy__box">
        <p className="form-policy__question">
          I consent that you use my personal information for the purposes mentioned above.
        </p>

        <div className="form-policy__agree-layout">
          <input
            checked={isAgree}
            className="form-policy__radio"
            type="radio"
            value="Agree"
            onClick={handleRadioChange}
          />
          <label className="form-policy__name" htmlFor="Agree">
            Agree
          </label>
        </div>
      </div>

      <div className="form-policy__box">
        <p className="form-policy__question">
          How would you like to receive news and special offers from us ?
        </p>

        <ul className="form-policy__list">
          {listReceiveNews.map(item => (
            <li key={`form-policy-option-${item.name.toLowerCase()}`} className="form-policy__item">
              <input
                checked={item.isChecked}
                className="form-policy__checkbox"
                name={item.name}
                type="checkbox"
                value={item.name}
                onChange={() => toggleCheckboxChange(item)}
              />
              <label className="form-policy__name" htmlFor={item.name}>
                {item.name}
              </label>
            </li>
          ))}
        </ul>
      </div>

      <button
        className={`form-policy__confirm-button ${
          isAgree ? 'form-policy__confirm-button--active' : 'form-policy__confirm-button--inactive'
        }`}
        disabled={!isAgree}
        onClick={handleSubmit}
      >
        Update My Preference
      </button>
    </div>
  );
};
