import React from 'react';

import { onImageLoadError } from '../../../utils/helper';

import './BodyOwndaysRevampsBrand.scss';

export const BodyOwndaysRevampsBrand = () => {
  return (
    <div className="od-body-owndays-revamps-brand">
      <h2>Brand Logo</h2>
      <img
        alt=""
        src="https://www.owndays.com/images/information/20240711/rebrand-logo.webp"
        onError={e => onImageLoadError(e)}
      />

      <div className="od-body-owndays-revamps-brand__content">
        <p className="od-body-owndays-revamps-brand__content__desc">
          The new brand logo symbolises OWNDAYS’ evolution as a global brand while staying true to
          its mission and values. It incorporates a motif that denotes a switch with the concept of
          “switching on/off”, signifying OWNDAYS’ role in empowering customers worldwide to unlock
          their best selves through OWNDAYS eyewear. Designed in the corporate colours of black and
          white, the logo conveys the notion that colours in the space created by OWNDAYS are
          brought to life by its eyewear and the people wearing them.
        </p>
      </div>

      <h2>Tagline: OWN ‘your’ DAYS</h2>

      <img
        alt=""
        src="https://www.owndays.com/images/information/20240711/OWNyourDAYS-logo_only.webp"
        onError={e => onImageLoadError(e)}
      />

      <div className="od-body-owndays-revamps-brand__content">
        <p className="od-body-owndays-revamps-brand__content__desc">
          The new tagline “OWN ‘your’ DAYS” stems from OWNDAYS’ corporate philosophy of enriching
          the lives of people associated with it. By inspiring confidence and positivity through
          innovative eyewear solutions, OWNDAYS is empowering customers to shape their own
          narratives and embrace their unique stories.
        </p>
      </div>

      <h2 className="od-body-owndays-revamps-brand__mb-0">Revamped Retail Design</h2>

      <div className="od-body-owndays-revamps-brand__content">
        <h3 className="od-header-owndays-revamps-brand__content__header">
          Location: LaLaport Tachikawa Tachihi (Japan)
        </h3>
        <p className="od-body-owndays-revamps-brand__content__desc">
          Opening Date: 11 July 2024 (Thu)
        </p>
      </div>

      <img
        alt=""
        src="https://www.owndays.com/images/information/20240711/676.webp"
        onError={e => onImageLoadError(e)}
      />

      <div className="od-body-owndays-revamps-brand__content">
        <h3 className="od-header-owndays-revamps-brand__content__header">
          Location: Northpoint City (Singapore)
        </h3>
        <p className="od-body-owndays-revamps-brand__content__desc">
          Opening Date: 19 July 2024 (Fri)
        </p>
      </div>

      <img
        alt=""
        src="https://www.owndays.com/images/information/20240711/968.webp"
        onError={e => onImageLoadError(e)}
      />

      <h2>Statement from the CEO, Umiyama Takeshi</h2>

      <div className="od-body-owndays-revamps-brand__content">
        <p className="od-body-owndays-revamps-brand__content__desc">
          “At OWNDAYS, customers are at the heart of everything we do. Our minimalist yet
          sophisticated store design creates an inviting space where customers can explore our
          latest eyewear collection at ease. The essence of OWN ‘your’ DAYS is embodied in our
          retail space, greeting customers with a welcoming ambience that is bright, comfortable,
          and open.
        </p>
        <p className="od-body-owndays-revamps-brand__content__desc od-body-owndays-revamps-brand__mb-0">
          As we expand globally, we are excited to introduce this refreshed retail design to new
          stores across various markets while also updating the logo in existing stores. This brand
          refresh underscores our unwavering commitment to evolving and becoming a brand loved by
          customers around the world. We look forward to sharing these exciting changes with our
          customers.”
        </p>
      </div>
    </div>
  );
};
