import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './GiftCardsHeader.scss';

const GiftCardsHeader = ({ device }) => {
  const dataLocale = useSelector(state => state.locale);
  const { GIFT_CARDS_TITLE, GIFT_CARDS_DESCRIPTION, GIFT_CARDS_LINK_TEXT } = dataLocale;

  const mainClass = `od-gift-cards-header--${device}`;

  return (
    <section className={`main-content-wrapper od-gift-cards-header ${mainClass}`}>
      <h1 className={`${mainClass}__title od-font-bold`}>{GIFT_CARDS_TITLE}</h1>
      <p
        className={`${mainClass}__description ${
          device === 'desktop' ? 'od-font-bold' : 'od-font-reg'
        }`}
      >
        {GIFT_CARDS_DESCRIPTION}
      </p>
      <div className={`${mainClass}__btn od-font-bold`}>
        <Link to="/gift-ticket">{GIFT_CARDS_LINK_TEXT}</Link>
      </div>
    </section>
  );
};

export default GiftCardsHeader;
