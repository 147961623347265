import PolicyHTML from '../own-days/html/PolicyHTML.jsx';
import PolicyWithEmailHTML from '../own-days/html/PolicyWithEmailHTML.jsx';
import ResultSubmitHTML from '../own-days/html/ResultSubmitHTML.jsx';

import Articles from '../own-days/views/Articles';
import SG001 from '../own-days/views/ArticleDetails/SG001.jsx';
import SG002 from '../own-days/views/ArticleDetails/SG002.jsx';
import SG003 from '../own-days/views/ArticleDetails/SG003.jsx';

const pdpaRoutes = [
  { path: '/(sg/en)?/pdpa/completed', component: ResultSubmitHTML },
  { path: '/(sg/en)?/pdpa', component: PolicyHTML },
  { path: '/(sg/en)?/pdpa2', component: PolicyWithEmailHTML },
];

const articleRoutes = [
  { path: '/(sg/en)?/articles', exact: true, component: Articles },
  { path: '/(sg/en)?/articles/sg-001', component: SG001 },
  { path: '/(sg/en)?/articles/sg-002', component: SG002 },
  { path: '/(sg/en)?/articles/sg-003', component: SG003 },
];

export const commonRoutes = [...pdpaRoutes, ...articleRoutes];
