import * as actionTypes from './../../actionTypes/productCarousel';

const initialState = {
  loaded: false,
  loading: false,
  productCarouselData: {},
};

export default function productCarousel(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.PRODUCTCAROUSEL_DATA_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
        loaded: false,
      });
    case actionTypes.PRODUCTCAROUSEL_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        productCarouselData: action.data.result,
      });
    case actionTypes.PRODUCTCAROUSEL_DATA_FAIL:
      return Object.assign({}, prevState, {
        loaded: true,
        loading: false,
        productCarouselData: action.data,
      });
    default:
      return prevState;
  }
}
