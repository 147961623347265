import React, { useState, useRef } from 'react';
import './AddPrescription.scss';

import ButtonOwnDays from '../../../../CommonComponents/buttons/ButtonOwnDays/ButtonOwnDays';
import OdCheckedSharpCircleIcon from '../../../../../static/assets/svg/od-checked-sharp-circle-icon.svg';
import OdUploadIcon from '../../../../../static/assets/svg/od-upload-icon.svg';

import { useSelector } from 'react-redux';

const AddPrescription = props => {
  const { history } = props;

  const {
    ADD_NEW_PRESCRIPTION,
    FILL_PRESCRIPTION_FORM,
    PRESCRIPTION_NAME,
    INTENDED_USE_PLACEHOLDER,
    USAGE,
    UPLOAD_PRESCRIPTION,
    DROP_OR_SELECT_FILE_TO_UPLOAD,
    UPLOAD_PRESCRIPTION_ACCEPTABLE_FILES,
    USAGE_DISTANCE_VISION,
    USAGE_NEAR_VISION,
    PRESCRIPTION,
    RIGHT,
    LEFT,
    PUPILLARY_DISTANCE,
    SPHERE,
    CYLINDER,
    AXIS,
    DEGREE,
    UPLOAD_PRESCRIPTION_TABLE_HINT,
    SAVE_PRESCRIPTION,
    CANCEL,
  } = useSelector(state => state.locale);

  const [usage, setUsage] = useState('distance');
  const [prescriptionFile, setPrescriptionFile] = useState(null);

  const prescriptionUploadRef = useRef(null);

  const handlePrescriptionUpload = event => {
    setPrescriptionFile(event?.target?.files?.[0]);
  };

  const handleDragOver = event => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handlePrescriptionDrop = event => {
    event.stopPropagation();
    event.preventDefault();
    setPrescriptionFile(event?.dataTransfer?.items?.[0]?.getAsFile());
  };

  return (
    <section className="od-add-prescription">
      <div className="od-add-prescription__title od-font-bold">{ADD_NEW_PRESCRIPTION}</div>
      <div className="od-add-prescription__subtitle od-font-reg">{FILL_PRESCRIPTION_FORM}</div>

      <div className="od-add-prescription__label od-font-bold">
        {PRESCRIPTION_NAME}{' '}
        <span className="od-add-prescription__required-mark od-font-bold">*</span>
      </div>
      <input
        className="od-add-prescription__input od-font-reg"
        placeholder={INTENDED_USE_PLACEHOLDER}
      />

      <div className="od-add-prescription__label od-font-bold">{USAGE}</div>
      <div className="od-add-prescription__usage-options od-font-bold">
        <div
          className={`od-add-prescription__usage-option ${
            usage === 'distance'
              ? 'od-add-prescription__usage-option--active od-font-bold'
              : 'od-font-reg'
          }`}
          onClick={() => setUsage('distance')}
        >
          {USAGE_DISTANCE_VISION}
          {usage === 'distance' && <img alt="selected" src={OdCheckedSharpCircleIcon} />}
        </div>
        <div
          className={`od-add-prescription__usage-option ${
            usage === 'near'
              ? 'od-add-prescription__usage-option--active od-font-bold'
              : 'od-font-reg'
          }`}
          onClick={() => setUsage('near')}
        >
          {USAGE_NEAR_VISION}
          {usage === 'near' && <img alt="selected" src={OdCheckedSharpCircleIcon} />}
        </div>
      </div>

      <div className="od-add-prescription__label od-font-bold">
        {UPLOAD_PRESCRIPTION}{' '}
        <span className="od-add-prescription__required-mark od-font-bold">*</span>
      </div>
      <div
        className="od-add-prescription__upload od-font-bold"
        onClick={() => prescriptionUploadRef.current.click()}
        onDragOver={handleDragOver}
        onDrop={handlePrescriptionDrop}
      >
        <div className="od-add-prescription__upload-title od-font-bold">
          <div>{DROP_OR_SELECT_FILE_TO_UPLOAD}</div>
          <img alt="selected" src={OdUploadIcon} />
        </div>
        {prescriptionFile?.name && <div className="od-font-reg">({prescriptionFile.name})</div>}
      </div>
      <ul className="od-add-prescription__hints">
        <li className="od-add-prescription__hint od-font-light">
          {UPLOAD_PRESCRIPTION_ACCEPTABLE_FILES}
        </li>
      </ul>

      <div className="od-add-prescription__label od-font-bold">{PRESCRIPTION}</div>
      <div className="od-add-prescription__table">
        <div className="od-add-prescription__cell" />
        <div className="od-add-prescription__cell od-add-prescription__cell--title od-font-bold">
          {PUPILLARY_DISTANCE}
        </div>
        <div className="od-add-prescription__cell od-add-prescription__cell--title od-font-bold">
          {SPHERE}
        </div>
        <div className="od-add-prescription__cell od-add-prescription__cell--title od-font-bold">
          {CYLINDER}
        </div>
        <div className="od-add-prescription__cell od-add-prescription__cell--title od-font-bold">
          {AXIS} ({DEGREE})
        </div>

        <div className="od-add-prescription__cell od-add-prescription__cell--title od-font-bold">
          {RIGHT.toLowerCase()}
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>

        <div className="od-add-prescription__cell od-add-prescription__cell--title od-font-bold">
          {LEFT.toLowerCase()}
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
        <div className="od-add-prescription__cell od-font-reg">
          <input className="od-add-prescription__cell-input" />
        </div>
      </div>

      <ul className="od-add-prescription__hints">
        <li className="od-add-prescription__hint od-font-light">
          {UPLOAD_PRESCRIPTION_TABLE_HINT}
        </li>
      </ul>

      <div className="od-add-prescription__actions">
        <ButtonOwnDays
          classes="od-add-prescription__submit"
          fontClass="od-font-bold"
          onClick={() => {}}
        >
          {SAVE_PRESCRIPTION}
        </ButtonOwnDays>
        <ButtonOwnDays
          classes="od-add-prescription__cancel"
          fontClass="od-font-bold"
          onClick={() => history.push(`/customer/prescriptions/`)}
        >
          {CANCEL.toLowerCase()}
        </ButtonOwnDays>
      </div>

      <input
        ref={prescriptionUploadRef}
        accept=".jpg, .png, .gif, .pdf"
        name="file"
        style={{ display: 'none' }}
        type="file"
        onChange={handlePrescriptionUpload}
      />
    </section>
  );
};

export default AddPrescription;
