import React from 'react';
import Slider from 'react-slick';
import 'theme/vendor/slick.min.scss';

const BodyZakuhead = ({ baseImgUrl }) => {
  const products = [
    {
      src: `${baseImgUrl}gallery-1.webp`,
      desc: 'Monoeye lights up on opening/closing, along with an activation sound effect',
    },
    {
      src: `${baseImgUrl}gallery-2.webp`,
      desc: 'Space to hold 4 pairs of eyewear',
    },
    {
      src: `${baseImgUrl}gallery-3.webp`,
      desc: '2-step opening/closing mechanism reveals an exquisitely engineered internal structure',
    },
    {
      src: `${baseImgUrl}gallery-4.webp`,
      desc: 'Detachable antenna, a symbol of the commander rank',
    },
    {
      src: `${baseImgUrl}gallery-5.webp`,
      desc: 'Movable monoeye that makes it more realistic',
    },
  ];

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: '0',
    centerMode: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '25px',
          adaptiveHeight: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="function">
        <div className="l-gundam5__container">
          <h2 className="l-gundam5__title">FUNCTION</h2>
        </div>
        <div className="function__slider swiper">
          <Slider {...settings}>
            {products.map((product, index) => (
              <>
                <div key={index} className="function__slider-img">
                  <img alt={product.desc} src={product.src} />
                </div>
                <p className="function__slider-desc">{product.desc}</p>
              </>
            ))}
          </Slider>
        </div>

        {/* FUNCTION - TRY AR */}
        <div className="l-gundam5__container">
          <div className="function__ar">
            <h3 className="function__ar-title">TRY AR</h3>
            <div className="function__ar-img">
              <img
                alt="Get a ZAKU HEAD CASE in your room!"
                className="function__ar-img-demo"
                src={`${baseImgUrl}ARzaku.webp`}
              />
              <img
                alt="QR Code"
                className="function__ar-img-qr"
                src={`${baseImgUrl}ar-qrcode-prod.webp`}
              />
            </div>
            <p className="function__ar-desc">
              Use AR (augmented reality) to see how the Zaku Head Case would fit in your room.
              <div className="function__ar-note">* Discrepancy in size perception may occur</div>
            </p>
          </div>
        </div>
        {/* FUNCTION - PRICE */}
        <div className="function__price">
          <div className="l-gundam5__container">
            <picture className="function__price-img">
              <source media="(min-width:768px)" srcSet={`${baseImgUrl}zaku-head-pc.webp`} />
              <img alt="ZAKU HEAD CASE" src={`${baseImgUrl}zaku-head-sp.webp`} />
            </picture>
            <h4 className="function__price-title">
              <span>ZAKU HEAD CASE</span>
              <small>Limited to 2,500 units worldwide</small>
            </h4>
            <p className="function__price-value">S$498</p>
            <a className="l-gundam5__btn" href="/sg/en/gundam-zaku-case3a.html">
              Purchase Online
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default BodyZakuhead;
