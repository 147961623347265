import React from 'react';
const Lineup = ({ baseImgUrl }) => {
  const products = [
    {
      id: 'glasses',
      charImagePc: `${baseImgUrl}lineup_char-1-pc.webp`,
      charImageSp: `${baseImgUrl}lineup_char-1-sp.webp`,
      title: "ZEON EXCLUSIVE <br class='u-sp-only'/>SPECTACLES",
      titleSmall: 'ZEON EXCLUSIVE SPECTACLES',
      price: 'S$198',
      code: 'GDM2003B-3A',
      desc: 'An exquisite spectacle frame in dual materials, created with meticulous attention to detail. Its metal parts feature engraved motifs inspired by the lapel pins of the Principality of Zeon Armed Forces while the temple tips are designed in the colours symbolic of the Principality of Zeon. C1 is presented in Char Aznable’s distinctive colour palette that features a red gradient reminiscent of a red comet, coupled with metal parts in an elegant gold-colour treatment. C2 adopts a hardcore military-style colourway as a reference to the soldiers of the Principality of Zeon Armed Forces.',
      colors: [
        {
          target: 'glasses-red',
          color: 'Red',
          sku: '7927',
          image: `${baseImgUrl}color-red.webp`,
          url: '/sg/en/gundam-gdm2003b-3a-c1-c1-eyeglasses.html',
          isActive: true,
        },
        {
          target: 'glasses-khaki',
          color: 'Khaki',
          sku: '7928',
          url: '/sg/en/gundam-gdm2003b-3a-c2-c2-eyeglasses.html',
          image: `${baseImgUrl}color-khaki.webp`,
        },
      ],
      sliders: {
        'glasses-red': [
          `${baseImgUrl}products/GDM2003B-3A/C1/GDM2003B-3A_C1_01.webp`,
          `${baseImgUrl}products/GDM2003B-3A/C1/GDM2003B-3A_C1_02.webp`,
          `${baseImgUrl}products/GDM2003B-3A/C1/GDM2003B-3A_C1_03.webp`,
          `${baseImgUrl}products/GDM2003B-3A/C1/GDM2003B-3A_C1_04.webp`,
        ],
        'glasses-khaki': [
          `${baseImgUrl}products/GDM2003B-3A/C2/GDM2003B-3A_C2_01.webp`,
          `${baseImgUrl}products/GDM2003B-3A/C2/GDM2003B-3A_C2_02.webp`,
          `${baseImgUrl}products/GDM2003B-3A/C2/GDM2003B-3A_C2_03.webp`,
          `${baseImgUrl}products/GDM2003B-3A/C2/GDM2003B-3A_C2_04.webp`,
        ],
      },
    },
    {
      id: 'sunglasses',
      charImagePc: `${baseImgUrl}lineup_char-2-pc.webp`,
      charImageSp: `${baseImgUrl}lineup_char-2-sp.webp`,
      title: "ZEON EXCLUSIVE <br class='u-sp-only'/>SUNGLASSES",
      titleSmall: 'ZEON EXCLUSIVE SUNGLASSES',
      price: 'S$78',
      code: 'GDM1004B-3A',
      desc: 'Envisioned for use by mobile suit pilots, these sunglasses are made of lightweight stainless steel with flexible rubber temple tips. Several elements reminiscent of the Zaku are added to complement the metallic texture. C1 comes in red, a colour based on the custom Zaku piloted by Char Aznable who holds the honorary title of The Red Comet. C2 features a khaki colourway that draws reference from the Mass Production Type Zaku.',
      colors: [
        {
          target: 'sunglasses-red',
          color: 'Red',
          sku: '7925',
          image: `${baseImgUrl}color-red.webp`,
          url: '/sg/en/gundam-gdm1004b-3a-c1-c1-sunglasses.html',
        },
        {
          target: 'sunglasses-khaki',
          color: 'Khaki',
          sku: '7926',
          image: `${baseImgUrl}color-khaki.webp`,
          url: '/sg/en/gundam-gdm1004b-3a-c2-c2-sunglasses.html',
          isActive: true,
        },
      ],
      sliders: {
        'sunglasses-red': [
          `${baseImgUrl}products/GDM1004B-3A/C1/GDM1004B-3A_C1_01.webp`,
          `${baseImgUrl}products/GDM1004B-3A/C1/GDM1004B-3A_C1_02.webp`,
          `${baseImgUrl}products/GDM1004B-3A/C1/GDM1004B-3A_C1_03.webp`,
          `${baseImgUrl}products/GDM1004B-3A/C1/GDM1004B-3A_C1_04.webp`,
        ],
        'sunglasses-khaki': [
          `${baseImgUrl}products/GDM1004B-3A/C2/GDM1004B-3A_C2_01.webp`,
          `${baseImgUrl}products/GDM1004B-3A/C2/GDM1004B-3A_C2_02.webp`,
          `${baseImgUrl}products/GDM1004B-3A/C2/GDM1004B-3A_C2_03.webp`,
          `${baseImgUrl}products/GDM1004B-3A/C2/GDM1004B-3A_C2_04.webp`,
        ],
      },
    },
  ];
  return (
    <section className="lineup">
      {products.map((product, index) => (
        <div key={index} className={`lineup__item lineup__item--${product.id}`}>
          <div className="lineup__char rellax" data-rellax-percentage="0.5" data-rellax-speed="-1">
            <picture>
              <source media="(min-width: 1024px)" srcSet={product.charImagePc} />
              <img alt="" src={product.charImageSp} />
            </picture>
          </div>
          <div className="lineup__content">
            <div className="lineup__heading">
              <div className="lineup__heading-logo">
                <img alt="" src={`${baseImgUrl}zeon-logo.svg`} />
              </div>
              <div className="lineup__heading-text">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: `${product.title}<br class="u-sp-only"/><small>${product.titleSmall}</small>`,
                  }}
                />
                <p>
                  {product.code} <strong>{product.price}</strong>
                </p>
              </div>
            </div>
            <div className="lineup__product">
              <div className="lineup__product-slider">
                {Object.keys(product.sliders).map((key, sliderIndex) => (
                  <div
                    key={sliderIndex}
                    className={`slick slick--${product.id} ${
                      product.colors.find(color => color.target === key).isActive ? 'active' : ''
                    }`}
                    id={key}
                  >
                    {product.sliders[key].map((image, imgIndex) => (
                      <div key={imgIndex}>
                        <img alt={`${product.code} ${key}`} src={image} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="lineup__product-details">
                <div className="lineup__product-actions">
                  <ul className="lineup__product-colors" id={`${product.id}-colors`}>
                    {product.colors.map((color, colorIndex) => (
                      <li key={colorIndex}>
                        <button
                          className={color.isActive ? 'active' : ''}
                          data-color={color.color}
                          data-target={color.target}
                          data-url={color.url}
                          style={{ backgroundImage: `url(${color.image})` }}
                          type="button"
                        >
                          {color.color}
                        </button>
                      </li>
                    ))}
                  </ul>
                  <span className="lineup__product-color" id={`${product.id}-color`}>
                    {product.colors.map(color => color.isActive && color.color)}
                  </span>
                  <nav className="lineup__product-nav" id={`${product.id}-nav`}>
                    <button
                      className="lineup__product-nav-btn lineup__product-nav-btn--prev"
                      type="button"
                    >
                      Previous
                    </button>
                    <button
                      className="lineup__product-nav-btn lineup__product-nav-btn--next"
                      type="button"
                    >
                      Next
                    </button>
                  </nav>
                </div>
                <p
                  dangerouslySetInnerHTML={{ __html: `${product.desc}` }}
                  className="lineup__product-des"
                />
                <div className="lineup__product-link">
                  {product.colors.map(
                    (color, colorIndex) =>
                      color.isActive && (
                        <a
                          key={colorIndex}
                          className="l-zeon__btn l-zeon__btn--online-store"
                          href={`${color.url}`}
                          id={`${product.id}-link`}
                        >
                          View Product
                        </a>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Lineup;
