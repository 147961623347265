export const CHECK_PHONE_NUMBER_EXPIRED = 'CHECK_PHONE_NUMBER_EXPIRED';
export const CLOSE_PHONE_NUMBER_CAPTURE = 'CLOSE_PHONE_NUMBER_CAPTURE';

export const PHONE_NUMBER_CAPTURE_SUBMIT = 'PHONE_NUMBER_CAPTURE_SUBMIT';
export const PHONE_NUMBER_CAPTURE_SUCCESS = 'PHONE_NUMBER_CAPTURE_SUCCESS';
export const PHONE_NUMBER_CAPTURE_FAILED = 'PHONE_NUMBER_CAPTURE_FAILED';

export const ONLY_PHONE_CAPTURE_SUBMIT = 'ONLY_PHONE_CAPTURE_SUBMIT';
export const ONLY_PHONE_CAPTURE_SUCCESS = 'ONLY_PHONE_CAPTURE_SUCCESS';
export const ONLY_PHONE_CAPTURE_FAILED = 'ONLY_PHONE_CAPTURE_FAILED';
