import React from 'react';

const ModalHeader = props => {
  const {
    /* backdrop, backdropClassName, bsSize, */ children,
    closeButton,
    /* dialogClassName, keyboard, */ onHide /* show */,
  } = props;
  return (
    <>
      <div className="modal-header">
        {closeButton && (
          <button className="close" onClick={onHide}>
            <span>x</span>
          </button>
        )}
        {children || null}
      </div>
    </>
  );
};

export default ModalHeader;
