import * as actionTypes from '../actionTypes/auth';

export function registerUser(data) {
  return { type: actionTypes.REGISTER_LOAD, data };
}

export const updateCustomer = data => ({
  type: actionTypes.UPDATE_CUSTOMER_LOAD,
  data,
});

export function authenticateUser(data) {
  return { type: actionTypes.LOGIN_LOAD, data };
}

export function validateUserInput(data) {
  return { type: actionTypes.USER_INPUT_LOAD, data };
}

export function sendValidateOtp(data) {
  return { type: actionTypes.USER_OTP_LOAD, data };
}

export function resetMobileLoginProps() {
  return { type: actionTypes.RESET_MOBILE_LOGIN_STATE };
}

export function forgotPasswordAction({ email }) {
  return { type: actionTypes.FORGET_PASSWORD_LOAD, data: { email } };
}

export function socialAuth({ data, account }) {
  return { type: actionTypes.SOCIAL_LOAD, data, account };
}

export function resetAuthData() {
  return { type: actionTypes.RESET_AUTH_STATE };
}

export function logoutUser() {
  return { type: actionTypes.LOGOUT_USER };
}

export function setPreviousUrl({ url, hash }) {
  return { type: actionTypes.SET_PREVIOUS_URL, location: { url, hash } };
}

export function fetchEmailIdsForMobile(number) {
  return { type: actionTypes.FETCH_EMAILS_LOAD, number };
}

export function linkEmailToMobile(email) {
  return { type: actionTypes.LINK_EMAIL_LOAD, email };
}

export function mergeMobileWithEmail(data) {
  return { type: actionTypes.MERGE_MOBILE_EMAIL_LOAD, data };
}
