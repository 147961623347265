import React from 'react';

const Feature = ({ baseImgUrl, isSP }) => {
  return (
    <section className="l-huawei2-feature">
      <div className="l-huawei2-feature__sec l-huawei2-feature__sec--one">
        <div className="l-huawei2__container">
          <div className="l-huawei2-feature__img l-huawei2-feature__img--one">
            <picture>
              {!isSP && (
                <>
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${baseImgUrl}Huawei_gen2_Landing_1_pc.webp`}
                    type="image/webp"
                  />
                </>
              )}
              <img
                alt="Slimmer and Lighter / Enhanced Performance for Sound Leakage Prevention"
                src={`${baseImgUrl}Huawei_gen2_Landing_1.webp`}
              />
            </picture>
          </div>
          <dl className="l-huawei2-feature__list" data-aos="fade-up">
            <dt>
              Slimmer and Lighter
              <br />
              For All-Day Comfortable Wear{' '}
            </dt>
            <dd>
              Temples are approximately 20% slimmer than earlier models, making it more comfortable
              and sleeker to wear.
            </dd>

            <dt>Enhanced Performance for Sound Leakage Prevention</dt>
            <dd>
              New reverse sound field technology ensures minimal sound leakage so you can listen in
              complete privacy.
            </dd>
          </dl>
        </div>
      </div>
      <div className="l-huawei2-feature__sec l-huawei2-feature__sec--two">
        <div className="l-huawei2__container">
          <div className="l-huawei2-feature__img l-huawei2-feature__img--two">
            <picture>
              {!isSP && (
                <>
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${baseImgUrl}Huawei_gen2_Landing_2_pc.webp`}
                    type="image/webp"
                  />
                </>
              )}
              <img
                alt="Larger Battery Capacity / High Quality Stereo Sound"
                src={`${baseImgUrl}Huawei_gen2_Landing_2.webp`}
              />
            </picture>
          </div>
          <dl className="l-huawei2-feature__list" data-aos="fade-up">
            <dt>Larger Battery Capacity</dt>
            <dd>
              A full charge provides up to 96 hours on standby; 9 hours of voice call; 11 hours of
              audio playback. Fast charging feature is also available.
            </dd>

            <dt>High Quality Stereo Sound</dt>
            <dd>
              Equipped with dynamic driver; co-directional dual diaphragm large amplitude units
              provide strong bass.
            </dd>
          </dl>
        </div>
      </div>
      <div className="l-huawei2-feature__bottom">
        <div className="l-huawei2-feature__bottom-list">
          <div className="l-huawei2-feature__bottom-group">
            <div
              className="l-huawei2-feature__bottom-item l-huawei2-feature__bottom-item--1"
              data-aos="fade-up"
            >
              <img
                alt="Touch Controls"
                className="l-huawei2-feature__bottom-icon"
                src={`${baseImgUrl}icon-hand.svg`}
              />
              <h3 className="l-huawei2-feature__bottom-title">Touch Controls</h3>
              <p className="l-huawei2-feature__bottom-des">
                Intuitive touch controls allow you to skip tracks, control volume and take calls
                simply by tapping and swiping on the temples.
              </p>
            </div>
            <div
              className="l-huawei2-feature__bottom-item l-huawei2-feature__bottom-item--2"
              data-aos="fade-up"
              data-aos-offset="100"
            >
              <img
                alt="Dust and Splash Resistant"
                className="l-huawei2-feature__bottom-icon"
                src={`${baseImgUrl}icon-water.svg`}
              />
              <h3 className="l-huawei2-feature__bottom-title">Dust and Splash Resistant</h3>
              <p className="l-huawei2-feature__bottom-des">
                The smart audio glasses are rated IP54 – protected against ingress of dust and water
                spray from any direction.
              </p>
            </div>
          </div>
          <div
            className="l-huawei2-feature__bottom-item l-huawei2-feature__bottom-item--3"
            data-aos="fade-up"
            data-aos-offset="200"
          >
            <img
              alt="Convert into Sunglasses Effortlessly"
              className="l-huawei2-feature__bottom-icon"
              src={`${baseImgUrl}icon-glasses.svg`}
            />
            <h3 className="l-huawei2-feature__bottom-title">
              Convert into Sunglasses Effortlessly
            </h3>
            <p className="l-huawei2-feature__bottom-des">
              Attach the designated SNAP LENS (sold separately) to the smart audio glasses to
              convert them into sunglasses. With the use of built-in magnets, all it takes is a
              simple snap.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
