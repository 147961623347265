import React from 'react';

const FooterZakuhead = ({ baseImgUrl, baseUrl, pageName }) => {
  const socialMediaLinks = [
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}news/${pageName}`,
      title: 'Facebook',
      imgSrc: 'https://www.owndays.com/images/specials/products/huawei2/fb-icon.svg',
    },
    {
      href: `https://twitter.com/intent/tweet?url=${baseUrl}news/${pageName}`,
      title: 'X',
      imgSrc: 'https://www.owndays.com/images/specials/products/huawei2/tw-icon.svg',
    },
    {
      href: `http://line.me/R/msg/text/?${baseUrl}news/${pageName}`,
      title: 'LINE',
      imgSrc: 'https://www.owndays.com/images/specials/products/huawei2/line-icon.svg',
    },
  ];
  const specs = [
    { title: 'Product Size', detail: 'Approximately W403 x D357 x H443 mm' },
    { title: 'Packing Size', detail: 'Approximately W415 x D395 x H325 mm' },
    { title: 'Weight', detail: 'Approximately 3.7 kg (Including outer box and packaging)' },
    { title: 'Material', detail: 'ABS Resin' },
    { title: 'Battery', detail: '3 x AA alkaline batteries (1 set included for trial)' },
    {
      title: 'Storage Capacity',
      detail: '4 pairs of eyewear',
      note: '*Subject to the size of eyewear',
    },
    { title: 'Intended Users', detail: 'Aged 15 years and above' },
  ];
  return (
    <>
      <section className="spec">
        <div className="l-gundam5__container">
          <h2 className="l-gundam5__title l-gundam5__title--center">SPECIFICATIONS</h2>
          <div className="spec__list">
            <ul>
              {specs.map((spec, index) => (
                <li key={index}>
                  <div className="spec__list--title">
                    <p>{spec.title}</p>
                  </div>
                  <div className="spec__list--detail">
                    <p>{spec.detail}</p>
                    {spec.note && <p className="note">{spec.note}</p>}
                  </div>
                </li>
              ))}
            </ul>
            <div className="spec-download">
              <a
                href="https://www.owndays.com/images/specials/products/gundam5-zakuhead/head_torisetu_web.pdf"
                rel="noreferrer"
                target="_blank"
              >
                <span>Download Instruction Manual (PDF)</span>
              </a>
            </div>
          </div>
          <div className="spec__note spec__note-section">
            <h2>Precautions</h2>
            <p>
              • This is a delicate product that should be handled with care. To reduce the risk of
              damage or accident, do not drop, hit or subject product to strong impact.
              <br />
              • Do not place product on slanted surfaces
              <br />• Product is not waterproof/splash-proof. Keep away from all sources of liquid.
            </p>
          </div>
        </div>
      </section>
      <section className="banner">
        <div className="banner__container">
          <picture>
            <source media="(min-width:768px)" srcSet={`${baseImgUrl}banner-bottom-pc.webp`} />
            <img
              alt="Zeon exclusive spectacles & sunglasses"
              src={`${baseImgUrl}banner-bottom-sp.webp`}
            />
          </picture>
          <div className="banner__detail">
            <div className="banner-category">
              <p>Simultaneous Release</p>
            </div>
            <div className="logo-zeon-owndays">
              <img alt="ZEON × OWNDAYS" src={`${baseImgUrl}logo.svg`} />
            </div>
            <div className="sub-zeon-owndays">
              <p>ZEON EXCLUSIVE SPECTACLES & SUNGLASSES</p>
            </div>
            <div className="btn-banner">
              <a className="btn-gold" href="/sg/en/news/gundam5-zeon">
                View More
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="share">
        <div className="share__container">
          <h2>SHARE</h2>
          <ul className="list-sns">
            {socialMediaLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} rel="noopener noreferrer" target="_blank" title={link.title}>
                  <img alt={link.title} src={link.imgSrc} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default FooterZakuhead;
