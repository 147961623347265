import React, { Fragment } from 'react';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';

import { HOW_WORKS } from './constants';
import './HeaderOrthoKLens.scss';

export const HeaderOrthoKLens = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news', label: 'News' },
    { path: '#', label: 'Ortho-K Lens' },
  ];

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <div className="base-ortho-k-lens__container">
        <section className="header-ortho-k-lens__top">
          <div className="header-ortho-k-lens__top__img">
            <picture>
              <source
                media="(min-width:768px)"
                srcSet={`${baseImgUrl}/main-banner-pc.webp`}
                type="image/webp"
              />
              <source
                media="(max-width:767px)"
                srcSet={`${baseImgUrl}/main-banner-sp.webp`}
                type="image/webp"
              />
              <img alt="MYOPIA CONTROL FOR KIDS" src={`${baseImgUrl}/main-banner-sp.png`} />
            </picture>

            <img
              alt="Ortho-K"
              className="header-ortho-k-lens__top__title"
              src={`${baseImgUrl}/main-banner-text.svg`}
            />
          </div>
        </section>

        <section className="header-ortho-k-lens__intro">
          <h1 className="base-ortho-k-lens__title">What is Ortho-k?</h1>
          <p>
            Ortho-K, also known as orthokeratology, is the use of customised contact lenses that
            temporarily reshape the cornea to improve vision during treatment. It is a safe and
            reversible process that allows you to enjoy spectacle-free vision and is effective in
            slowing down myopia progression for children.
          </p>
          <img
            alt="KIDS"
            className="header-ortho-k-lens__intro__img"
            src={`${baseImgUrl}/children.svg`}
          />
        </section>

        <section className="header-ortho-k-lens__video">
          <div className="base-ortho-k-lens__container--sub">
            <div className="header-ortho-k-lens__video__iframe-container">
              <iframe
                allowFullScreen
                frameBorder={0}
                height="100%"
                src="https://www.youtube.com/embed/CH8DcBcvgNw?rel=0?rel=0"
                title="Ortho-K Lenses Video"
                width="100%"
              />
            </div>
          </div>
        </section>

        <section className="header-ortho-k-lens__how-works">
          <h2 className="base-ortho-k-lens__title base-ortho-k-lens__title--center">
            How It Works
          </h2>

          <div className="base-ortho-k-lens__container--sub">
            <ul className="header-ortho-k-lens__how-works__list">
              {HOW_WORKS.map((item, index) => (
                <li key={index}>
                  <p className="header-ortho-k-lens__how-works__list-number"></p>
                  <img
                    alt={item.alt}
                    className="header-ortho-k-lens__how-works__list-img lazyload"
                    src={item.imgSrc}
                    style={item.imgStyle}
                  />
                  <h3 className="header-ortho-k-lens__how-works__list-title">
                    {item.title}
                    <br />
                    <small>{item.subtitle}</small>
                  </h3>
                </li>
              ))}
            </ul>
            <p className="header-ortho-k-lens__how-works__des">
              Ortho-K lenses are gas permeable contact lenses that are specially shaped and fitted
              accordingly to each individual’s eyes. They are worn at night to reshape the cornea
              during sleep and then removed during the day.
              <br />
              When worn overnight, the Ortho-K lens gently presses the cornea, causing the centre of
              the cornea to gradually flatten and moulding the cornea to the correct shape that
              focuses light onto the retina. When removed, the cornea remains flattened and vision
              is corrected without the need for spectacles during the day.
            </p>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
