import { call, put, all, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/locale';
import config from 'config';
import apiClient from '../helpers/apiClient';

let _client = null;
function* getResource(resource, successAction, errorAction) {
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(resource));
    console.log('🚀 ~ function*getResource ~ data:', resource, data);
    successAction.data = data?.result ? data.result : data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* getLocaleData() {
  yield call(
    getResource,
    `${config.apiPath.jsonPath}`,
    { type: actionTypes.LOAD_LOCALE_CONFIG_SUCCESS },
    { type: actionTypes.LOAD_LOCALE_CONFIG_FAIL }
  );
}

export function* runGetLocaleCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(actionTypes.LOAD_LOCALE_CONFIG, getLocaleData)]);
}
