import React, { useState, useEffect } from 'react';

const HeaderBlackCollection = ({ imgUrl }) => {
  const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    if (isMobile) {
      setVideoSrc(`${imgUrl}/JP_black_2024_sp.mp4`);
    } else {
      setVideoSrc(`${imgUrl}/JP_black_2024_pc.mp4`);
    }
  }, [imgUrl]);

  return (
    <section className="black-collection__main">
      <div className="black-collection__main-video">
        <video autoPlay loop muted>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="black-collection__main-content">
        <div className="black-collection__container black-collection__container--fluid">
          <h1 className="black-collection__main-heading">
            <span className="black-collection__main-heading-black">BLACK</span>
            <span className="black-collection__main-heading-collection">COLLECTION</span>
          </h1>
        </div>
      </div>
      <p className="black-collection__main-scroll">
        Scroll down <img src={`${imgUrl}/icon-scroll-down.svg`} alt='Icon scroll down'/>
      </p>
    </section>
  );
};

export default HeaderBlackCollection;
