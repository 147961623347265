import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/cart';
import { UPDATE_CART_COUNT, SET_TOAST_MESSAGE } from '../actionTypes/common';
import apiClient from '../helpers/apiClient';
import getSetCookie from '../cookie';
import sessionStorageHelper from '../utils/sessionStorageHelper';

import config from 'config';

let _client = null;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line
function addDiscounts(data) {
  if (
    data.result &&
    data.result.totals &&
    data.result.totals.discounts &&
    data.result.totals.discounts.length
  ) {
    data.result.totals.discounts.forEach(discount => {
      if (discount.type === 'gv') {
        data.gvAmt = discount.amount;
      }
      if (discount.type === 'implicit') {
        data.implicitAmt = discount.amount;
      }
      if (discount.type === 'sc') {
        data.scAmt = data.scAmt ? data.scAmt + discount.amount : discount.amount;
      }
      if (discount.type === 'lenskart') {
        data.gvAmt = discount.amount + data.gvAmt;
      }
    });
  }
}

function* addToCart({ data, showCartPopup }) {
  const successAction = { type: actionTypes.GET_CART_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CART_DATA_FAIL };
  const client = Object.assign({}, _client);
  client.defaults.headers = {};
  let isExchange = false;
  if (__SERVER__) {
    const getCookies = client.getServerRequestHeader().cookies;
    if (getCookies.exchangeMethod) {
      isExchange = getCookies.exchangeMethod;
    }
  }
  if (__CLIENT__) {
    const isExchangeSessionValid =
      sessionStorageHelper.getItem('returnAction') ||
      sessionStorageHelper.getItem('returnProduct')?.returnAction;
    if (getSetCookie.getCookie('exchangeMethod') && isExchangeSessionValid) {
      isExchange = getSetCookie.getCookie('exchangeMethod');
    } else {
      isExchange = false;
    }
  }
  try {
    const response = yield call(async () =>
      client.customPost(
        config.apiPath.cartApi,
        data,
        'X-Session-Token',
        xApiClient,
        null,
        isExchange
      )
    );
    response.isAddToCartCalled = true;
    response.showCartPopup = showCartPopup;
    successAction.data = response;
    yield put(successAction);
    yield put({ type: UPDATE_CART_COUNT, data: response.result.itemsCount });
    if (isExchange && xApiClient === 'desktop') {
      const orderId = getSetCookie.getCookie('exchangeOrderId');
      let returnStep = sessionStorageHelper.getItem('returnStep') || {};
      const returnProductDetails = sessionStorageHelper.getItem('returnProduct') || {};
      const itemId = returnProductDetails && returnProductDetails.returnProductId;
      if (returnProductDetails.returnMethod === 'NEWPRODUCT' && returnStep.finalStep === '') {
        returnStep = { ...returnStep, fourthStep: true };
        sessionStorageHelper.setItem('returnStep', returnStep);
        window.open(`/customer/my/order/return/${orderId}/${itemId}`, '_self');
      }
    }
  } catch (er) {
    errorAction.error = er || {};
    errorAction.error.isAddToCartCalled = true;
    if (isExchange && xApiClient === 'desktop') {
      yield put({
        type: SET_TOAST_MESSAGE,
        data: { message: er?.error || 'System error occurred', timeout: 5000, error: true },
      });
    }
    yield put(errorAction);
  }
}

function* getCartData({ reorderId, applyWallet, emailId, isExchange }) {
  const successAction = { type: actionTypes.GET_CART_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CART_DATA_FAIL };
  let responseData = {};
  const client = { ..._client };
  const header = isExchange ? { 'X-Service-Type': 'exchange' } : null;
  client.setHeader(header);
  try {
    if (reorderId) {
      if (emailId) {
        responseData = yield call(async () =>
          client.post(config.apiPath.reOrderCartApi, { emailId, orderId: reorderId })
        );
      } else {
        responseData = yield call(async () =>
          client.post(config.apiPath.reOrderCartApi + reorderId)
        );
      }
    } else {
      let url = config.apiPath.cartApi;
      if (applyWallet !== null) {
        url += `?applyWallet=${applyWallet}`;
      }
      responseData = yield call(async () => client.get(url));
    }
    if (applyWallet !== null) {
      responseData.result.walletApplied = applyWallet;
    }
    addDiscounts(responseData);
    successAction.data = responseData;
    yield put(successAction);
    yield put({ type: UPDATE_CART_COUNT, data: responseData.result.itemsCount });
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* updateCartItem({ itemid, count, flag }) {
  const successAction = { type: actionTypes.GET_CART_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CART_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    let data = {};
    if (flag === 'increase') {
      data = yield call(async () =>
        client.put(`${config.apiPath.updateCartItem}${itemid}?count=${count}`)
      );
    } else if (flag === 'decrease') {
      data = yield call(async () =>
        client.delete(`${config.apiPath.updateCartItem}${itemid}?count=${count}`)
      );
    } else {
      data = yield call(async () => client.delete(config.apiPath.updateCartItem + itemid));
    }
    addDiscounts(data);
    data.isUpdateCartCalled = true;
    successAction.data = data;
    yield put(successAction);
    yield put({ type: UPDATE_CART_COUNT, data: data.result.itemsCount });
  } catch (er) {
    errorAction.error = er;
    errorAction.error.isUpdateCartCalled = true;
    yield put(errorAction);
  }
}

function* updateCart({ itemid, params }) {
  const successAction = { type: actionTypes.GET_CART_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CART_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(config.apiPath.updateCartItem + itemid, params)
    );
    addDiscounts(data);
    data.isUpdateCartCalled = true;
    successAction.data = data;
    yield put(successAction);
    yield put({ type: UPDATE_CART_COUNT, data: data.result.itemsCount });
  } catch (er) {
    errorAction.error = er;
    errorAction.error.isUpdateCartCalled = true;
    yield put(errorAction);
  }
}

function* applyCode({ url, reqType }) {
  const successAction = { type: actionTypes.GET_CART_DATA_SUCCESS };
  const errorAction = { type: actionTypes.GET_CART_DATA_FAIL };
  const applyCouponErrorAction = { type: actionTypes.APPLY_COUPON_ERROR };
  const applyCouponSuccessAction = { type: actionTypes.APPLY_COUPON_SUCCESS };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client[reqType](url));
    data.result.walletApplied = false;
    if (url?.indexOf('?applyWallet') !== -1) {
      data.result.walletApplied = url.split('?applyWallet=')[1] === 'true';
    }
    addDiscounts(data);
    if (url?.indexOf('giftVoucher') > -1) {
      data.isGvApplied = true;
      data.isScApplied = false;
    } else {
      data.isGvApplied = false;
      data.isScApplied = true;
    }
    successAction.data = data;
    applyCouponSuccessAction.data = data;
    yield put(successAction);
    if (url?.indexOf('giftVoucher') > -1) {
      yield put(applyCouponSuccessAction);
    }
  } catch (er) {
    errorAction.error = er;
    if (url?.indexOf('giftVoucher') > -1) {
      errorAction.error.isGvApplied = true;
      errorAction.error.isScApplied = false;
      applyCouponErrorAction.error = er;
    } else {
      errorAction.error.isGvApplied = false;
      errorAction.error.isScApplied = true;
    }
    yield put(errorAction);
    if (url?.indexOf('giftVoucher') > -1) {
      yield put(applyCouponErrorAction);
    }
  }
}

function* countryShippingCheck({ country }) {
  const successAction = { type: actionTypes.SHIPPING_CHECK_SUCCESS };
  const errorAction = { type: actionTypes.SHIPPING_CHECK_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () =>
      client.post(`${config.apiPath.cartApi}/shippingCheck`, { country })
    );
    successAction.result = result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

// Cart update Bin Series
function* applyOffer({ data }) {
  const successAction = { type: actionTypes.APPLY_OFFER_SUCCESS };
  const errorAction = { type: actionTypes.APPLY_OFFER_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () => client.post(`${config.apiPath.applyOffer}`, data)); // client.post(`${config.apiPath.applyOfferMock}`, data));
    successAction.result = result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* removeOffer({ offerId }) {
  const successAction = { type: actionTypes.REMOVE_OFFER_SUCCESS };
  const errorAction = { type: actionTypes.REMOVE_OFFER_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () => client.delete(`${config.apiPath.removeOffer}${offerId}`)); // client.get(`${config.apiPath.deleteCardsOffer}`, { offerId }));
    successAction.result = result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}
function* deleteCart() {
  const client = { ..._client };
  client.setHeader();
  try {
    yield call(async () => client.delete(`${config.apiPath.cartApi}`));
  } catch (error) {
    console.log('clear cart', error);
  }
}

function* updatePower({ itemId, prescription }) {
  const successAction = { type: actionTypes.UPDATE_POWER_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_POWER_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(config.apiPath.updateCartItem + itemId, prescription)
    );
    successAction.result = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* applyGiftCard({ giftCode }) {
  const successAction = { type: actionTypes.APPLY_GIFT_SUCCESS };
  const errorAction = { type: actionTypes.APPLY_GIFT_FAIL };
  const client = { ..._client };
  const state = yield select();
  const prevGiftCards =
    state.cart.cartData.result.giftCards.length > 0 &&
    state.cart.cartData.result.giftCards.map(card => {
      return card.code;
    });
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(
        config.apiPath.giftCards,
        prevGiftCards ? [...prevGiftCards, giftCode] : [giftCode]
      )
    );
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* removeGiftCard({ giftCode }) {
  const successAction = { type: actionTypes.REMOVE_GIFT_SUCCESS };
  const errorAction = { type: actionTypes.REMOVE_GIFT_FAIL };
  const client = { ..._client };
  const state = yield select();
  const giftCards = state.cart.cartData.result.giftCards;

  const remainingGiftCards =
    giftCards.length > 1 ? giftCards.map(card => card.code).filter(code => code !== giftCode) : [];

  client.setHeader();

  try {
    const data = yield call(async () => client.post(config.apiPath.giftCards, remainingGiftCards));
    successAction.data = data.result;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runCartCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.GET_CART_DATA_LOAD, getCartData),
    takeLatest(actionTypes.APPLY_GIFT_LOAD, applyGiftCard),
    takeLatest(actionTypes.REMOVE_GIFT_LOAD, removeGiftCard),
    takeLatest(actionTypes.UPDATE_CART_LOAD, updateCartItem),
    takeLatest(actionTypes.UPDATE_CART, updateCart),
    takeLatest(actionTypes.DISCOUNT_CODE_LOAD, applyCode),
    takeLatest(actionTypes.ADD_TO_CART_LOAD, addToCart),
    takeLatest(actionTypes.SHIPPING_CHECK_LOAD, countryShippingCheck),
    takeLatest(actionTypes.APPLY_OFFER, applyOffer),
    takeLatest(actionTypes.REMOVE_OFFER, removeOffer),
    takeLatest(actionTypes.DELETE_CART_LOAD, deleteCart),
    takeLatest(actionTypes.UPDATE_POWER_LOAD, updatePower),
  ]);
}
