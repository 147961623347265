// User Campaign User Data
export const SAVE_CAMPAIGN_USER_DATA_LOAD = 'offerCampaign/SAVE_CAMPAIGN_USER_DATA_LOAD';
export const SAVE_CAMPAIGN_USER_DATA_SUCCESS = 'offerCampaign/SAVE_CAMPAIGN_USER_DATA_SUCCESS';
export const SAVE_CAMPAIGN_USER_DATA_FAIL = 'offerCampaign/SAVE_CAMPAIGN_USER_DATA_FAIL';
export const RESET_CAMPAIGN_USER_DATA = 'offerCampaign/RESET_CAMPAIGN_USER_DATA';

// Check User Exists or not
export const CHECK_USER_EXISTS_LOAD = 'offerCampaign/CHECK_USER_EXISTS_LOAD';
export const CHECK_USER_EXISTS_SUCCESS = 'offerCampaign/CHECK_USER_EXISTS_SUCCESS';
export const CHECK_USER_EXISTS_FAIL = 'offerCampaign/CHECK_USER_EXISTS_FAIL';
export const CHANGE_USER_EXISTS_VALUE = 'offerCampaign/CHANGE_USER_EXISTS_VALUE';

// Check First Deal User Exists or not
export const FIRST_DEAL_USER_EXISTS_LOAD = 'offerCampaign/FIRST_DEAL_USER_EXISTS_LOAD';
export const FIRST_DEAL_USER_EXISTS_SUCCESS = 'offerCampaign/FIRST_DEAL_USER_EXISTS_SUCCESS';
export const FIRST_DEAL_USER_EXISTS_FAIL = 'offerCampaign/FIRST_DEAL_USER_EXISTS_FAIL';
