import React from 'react';
import './MembershipHeading.scss';

const MembershipHeading = ({ title, subTitle, description }) => {
  return (
    <>
      {subTitle && <div className="od-membership-heading__subtitle od-font-reg">{subTitle}</div>}
      <div className="od-membership-heading__title od-font-bold">{title}</div>
      {description && (
        <div className="od-membership-heading__description od-font-reg">{description}</div>
      )}
    </>
  );
};

export default MembershipHeading;
