export const REGISTER_LOAD = 'auth/REGISTER_LOAD';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAIL = 'auth/REGISTER_FAIL';

export const LOGIN_LOAD = 'auth/LOGIN_LOAD';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAIL = 'auth/LOGIN_FAIL';

export const UPDATE_CUSTOMER_LOAD = 'auth/UPDATE_CUSTOMER_LOAD';
export const UPDATE_CUSTOMER_SUCCESS = 'auth/UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAIL = 'auth/UPDATE_CUSTOMER_FAIL';

export const USER_INPUT_LOAD = 'auth/USER_INPUT_LOAD';
export const USER_INPUT_SUCCESS = 'auth/USER_INPUT_SUCCESS';
export const USER_INPUT_FAIL = 'auth/USER_INPUT_FAIL';

export const USER_OTP_LOAD = 'auth/USER_OTP_LOAD';
export const USER_OTP_SUCCESS = 'auth/USER_OTP_SUCCESS';
export const USER_OTP_FAIL = 'auth/USER_OTP_FAIL';

export const FORGET_PASSWORD_LOAD = 'auth/FORGET_PASSWORD_LOAD';
export const FORGET_PASSWORD_SUCCESS = 'auth/FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAIL = 'auth/FORGET_PASSWORD_FAIL';

export const SOCIAL_LOAD = 'auth/SOCIAL_LOAD';
export const SOCIAL_SUCCESS = 'auth/SOCIAL_SUCCESS';
export const SOCIAL_FAIL = 'auth/SOCIAL_FAIL';

export const RESET_MOBILE_LOGIN_STATE = 'auth/RESET_MOBILE_LOGIN_STATE';

export const RESET_AUTH_STATE = 'auth/RESET_AUTH_STATE';
export const LOGOUT_USER = 'auth/LOGOUT_USER';

export const SET_PREVIOUS_URL = 'auth/SET_PREVIOUS_URL';

export const FETCH_EMAILS_LOAD = 'auth/FETCH_EMAILS_LOAD';
export const FETCH_EMAILS_SUCCESS = 'auth/FETCH_EMAILS_SUCCESS';
export const FETCH_EMAILS_FAIL = 'auth/FETCH_EMAILS_FAIL';

export const LINK_EMAIL_LOAD = 'auth/LINK_EMAIL_LOAD';
export const LINK_EMAIL_SUCCESS = 'auth/LINK_EMAIL_SUCCESS';
export const LINK_EMAIL_FAIL = 'auth/LINK_EMAIL_FAIL';

export const MERGE_MOBILE_EMAIL_LOAD = 'auth/MERGE_MOBILE_EMAIL_LOAD';
export const MERGE_MOBILE_EMAIL_SUCCESS = 'auth/MERGE_MOBILE_EMAIL_SUCCESS';
export const MERGE_MOBILE_EMAIL_FAIL = 'auth/MERGE_MOBILE_EMAIL_FAIL';
