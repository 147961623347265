import { call, put, takeLatest, all } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/gupshupWhatsapp';
import apiClient from '../helpers/apiClient';
import config from 'config';
import localStorageHelper from '../utils/localStorageHelper';

let _client = null;

function* checkCurrentWhatsappStatus() {
  const successAction = { type: actionTypes.CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.CHECK_CURRENT_WHATSAPP_OPTIN_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const result = yield call(async () => client.get(`${config.apiPath.checkWhatsappConsent}`));
    successAction.result = result;
    yield put(successAction);
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

function* setCurrentWhatsappStatus({ status }) {
  const successAction = { type: actionTypes.SET_WHATSAPP_OPTIN_STATUS_SUCCESS };
  const errorAction = { type: actionTypes.SET_WHATSAPP_OPTIN_STATUS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.put(`${config.apiPath.setWhatsappConsent}?optingValue=${status}`)
    );
    const response = data?.result?.response;
    const id = response?.details === 'OPT_IN' ? response.id : '';
    if (__CLIENT__) {
      localStorageHelper.setItem('whatsAppOptInId', id);
    }
    if (response.status === 'success') {
      successAction.data = { optin: response?.details === 'OPT_IN' || false };
      yield put(successAction);
    }
  } catch (error) {
    errorAction.error = error;
    yield put(errorAction);
  }
}

export function* runWhatsappStatusCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.CHECK_CURRENT_WHATSAPP_OPTIN_STATUS, checkCurrentWhatsappStatus),
    takeLatest(actionTypes.SET_WHATSAPP_OPTIN_STATUS, setCurrentWhatsappStatus),
  ]);
}
