import * as actionTypes from './../../actionTypes/offerCampaign';
import { LOGOUT_USER } from './../../actionTypes/common';

const initialState = {
  localeInfo: null,
  saveUserDataLoading: null,
  isUserExists: null,
  loading: false,
  isFirstDealUser: null,
};

export default function offerCampaign(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.CHECK_USER_EXISTS_LOAD:
      return Object.assign({}, prevState, {
        isUserExists: null,
        loading: true,
      });
    case actionTypes.CHECK_USER_EXISTS_SUCCESS:
      return Object.assign({}, prevState, {
        loading: false,
        isUserExists: action.data,
      });
    case actionTypes.CHECK_USER_EXISTS_FAIL:
      return Object.assign({}, prevState, {
        loading: false,
        isUserExists: false,
      });
    case actionTypes.CHANGE_USER_EXISTS_VALUE:
      return Object.assign({}, prevState, {
        isUserExists: false,
      });
    case actionTypes.FIRST_DEAL_USER_EXISTS_LOAD:
      return Object.assign({}, prevState, {
        isFirstDealUser: null,
      });
    case actionTypes.FIRST_DEAL_USER_EXISTS_SUCCESS:
      return Object.assign({}, prevState, {
        isFirstDealUser: action.data,
      });
    case actionTypes.FIRST_DEAL_USER_EXISTS_FAIL:
      return Object.assign({}, prevState, {
        isFirstDealUser: null,
      });
    case LOGOUT_USER:
      return Object.assign({}, prevState, {
        isFirstDealUser: false,
      });
    case actionTypes.SAVE_CAMPAIGN_USER_DATA_LOAD:
      return Object.assign({}, prevState, {
        campaignUserData: null,
        saveUserDataLoading: true,
      });
    case actionTypes.SAVE_CAMPAIGN_USER_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        campaignUserData: action.data,
        saveUserDataLoading: false,
      });
    case actionTypes.SAVE_CAMPAIGN_USER_DATA_FAIL:
      return Object.assign({}, prevState, {
        campaignUserData: action.error,
        saveUserDataLoading: false,
      });
    case actionTypes.RESET_CAMPAIGN_USER_DATA:
      return Object.assign({}, prevState, {
        campaignUserData: null,
      });
    default:
      return prevState;
  }
}
