import React, { Fragment } from 'react';

import { TERM_AND_CONDITIONS } from './constants';
import './FooterGiftTicket.scss';

export const FooterGiftTicket = () => {
  return (
    <Fragment>
      <section className="footer-gift-ticket__condition">
        <div className="footer-gift-ticket__condition__container">
          <h2 className="footer-gift-ticket__condition__title">Terms and Conditions</h2>
          <ul className="footer-gift-ticket__condition__list">
            {TERM_AND_CONDITIONS.map((condition, index) => (
              <li
                key={`gift-ticket-condition-${index}`}
                className="footer-gift-ticket__condition__item"
              >
                {condition}
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Fragment>
  );
};
