import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
        <meta name="format-detection" content="telephone=no">
<meta name="keywords" content="Demon Slayer,Kimetsu no Yaiba,CHARACTER FRAME COLLECTION,ORIGINAL,Tanjiro,Nezuko,Zenitsu,Inosuke,Giyu,Shinobu,Nichirin Sword,iconic patterns,outfit,spectacles,glasses,eye glasses" />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/kimetsu">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/kimetsu/sg/ogp_1200x630.jpg?1665053197">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700;900&family=Noto+Sans+TC:wght@300;400;500;700;900&family=Noto+Serif+JP:wght@300;400;500;600;700;900&family=Noto+Serif+TC:wght@300;400;500;600;700;900&Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Noto+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet">
<link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css" />
<link rel="stylesheet" type="text/css" href="https://www.owndays.com/web/css/slick-theme.css" />
<link href="https://www.owndays.com/web/css/specials-kimetsu.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
  


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            [Demon Slayer: Kimetsu no Yaiba] Character Frame Collection                    </li>
        
            </ul>
</div>


<section class="l-kimetsu">
    <section class="l-kimetsu__main">
        <div class="l-kimetsu--container">
            <picture>
                <source media="(min-width:800px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/main@2x.webp">
                <img data-src="https://www.owndays.com/images/specials/products/kimetsu/main.webp" alt="[Demon Slayer] Key Visual" class="l-kimetsu__main--img lazyload">
            </picture>
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/bandai.svg" alt="BANDAI" class="l-kimetsu__main--ban-dai lazyload">
                        <div class="l-kimetsu__main-inner">
                <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/kimetsu.svg" alt="Demon Slayer: Kimetsu no Yaiba" class="l-kimetsu__main--kimetsu lazyload">
                <div class="l-kimetsu__main__heading">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/main-heading-bg.svg" alt="" class="l-kimetsu__main__heading-bg lazyload">
                    <h1><span>KIMETSU NO YAIBA</span>CHARACTER<br>FRAME COLLECTION</h1>
                </div>
                <div class="l-kimetsu__main__reservation jp-none">
                    <div class="l-kimetsu__main__reservation--tag">
                        <span class="l-kimetsu__main__reservation--tag-left">Now available at selected stores and online</span>
                        <div class="l-kimetsu__main__reservation--tag-right"></div>
                    </div>
                    <p class="l-kimetsu__main__reservation--note"></p>
                </div>
            </div>
        </div>
    </section>
    <section class="l-kimetsu__sub">
        <h2 class="l-kimetsu__sub-title">
            <span class="l-kimetsu__sub-title-line l-kimetsu__sub-title-line--left"></span>
            <span class="l-kimetsu__sub-title-text">Concept</span>
            <span class="l-kimetsu__sub-title-line l-kimetsu__sub-title-line--right"></span>
        </h2>
        <div class="l-kimetsu--container">
            <p class="l-kimetsu__sub-text">A collection featuring six spectacle frames inspired by the six characters, namely Tanjiro, Nezuko, Zenitsu,<br class="d-none d-lg-block"> Inosuke, Giyu and Shinobu, from the anime television series Demon Slayer: Kimetsu no Yaiba.<br class="d-none d-lg-block"> Each frame is a distinct representation of each of the six characters, with frame temples decorated in motifs<br class="d-none d-lg-block"> depicting iconic patterns found on both the Nichirin Sword and the outfit of the respective character.</p>
            <ul class="l-kimetsu__sub__nav">
                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec1" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect1_frame9.webp" alt="Tanjiro Kamado Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Tanjiro<br class="d-block d-xl-none"> Kamado</h5>
                    </a>
                </li>
                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec2" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect2_frame9.webp" alt="Nezuko Kamado Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Nezuko<br class="d-block d-xl-none"> Kamado</h5>
                    </a>
                </li>
                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec3" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect3_frame9.webp" alt="Zenitsu Agatsuma Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Zenitsu<br class="d-block d-xl-none"> Agatsuma</h5>
                    </a>
                </li>
                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec4" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect4_frame9.webp" alt="Inosuke Hashibira Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Inosuke<br class="d-block d-xl-none"> Hashibira</h5>
                    </a>
                </li>
                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec5" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect5_frame9.webp" alt="Giyu Tomioka Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Giyu<br class="d-block d-xl-none"> Tomioka</h5>
                    </a>
                </li>
                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec6" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect6_frame9.webp" alt="Shinobu Kocho Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Shinobu<br class="d-block d-xl-none"> Kocho</h5>
                    </a>
                </li>
                                <li class="l-kimetsu__sub__nav-item">
                    <a href="#sec7" class="l-kimetsu__sub__nav-link" data-scroll>
                        <div class="l-kimetsu__sub__nav-img">
                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sect7_frame9.webp" alt="Kyojuro Rengoku Model">
                        </div>
                        <h5 class="l-kimetsu__sub__nav-title">Kyojuro<br class="d-block d-xl-none"> Rengoku</h5>
                    </a>
                </li>
                            </ul>
        </div>
    </section>
    <section class="l-kimetsu__sec l-kimetsu__sec1" id="sec1">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect1_chara.webp" alt="Tanjiro Kamado" class="l-kimetsu__sec-img l-kimetsu__sec1-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec1-title">
                <span class="l-kimetsu__sec-number l-kimetsu__sec1-number">1</span>
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect1_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect1_name_m.png" alt="Tanjiro Kamado Model">
                    </picture>
                </span>
            </h2>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec1-subtitle">TANJIRO KAMADO</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec1-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect1_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>
            <p class="l-kimetsu__sec-des l-kimetsu__sec1-des">A versatile design in the Boston style that is made of acetate and metal. The frame features a glossy black colour that resembles Tanjiro’s Nichirin Sword. The end pieces (the part that extends from the front of the frame to the temple) come in a red pattern mirroring that on his sword handle, offering an extra accent to the eye area. A metal emblem in the design of Tanjiro’s Nichirin Sword handguard is set on the tip of the temples while the reverse side features the iconic checked pattern from his haori jacket.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec1-details-price">
                        <h5>S$258</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                    </div>
                                        <a href="/sg/en/products/KMTY2001Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec1-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Acrylic (demo lens) / <br class="d-block d-md-none d-lg-block d-xl-none">Frame: Acetate, Alloy</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 48mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 20mm / <br class="d-none d-md-block">Temple Length: Approx. 140mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
    <section class="l-kimetsu__sec l-kimetsu__sec2" id="sec2">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect2_chara.webp" alt="Nezuko Kamado" class="l-kimetsu__sec-img l-kimetsu__sec2-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec2-title">
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect2_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect2_name_m.png" alt="Nezuko Kamado Model">
                    </picture>
                </span>
                <span class="l-kimetsu__sec-number l-kimetsu__sec2-number">2</span>
            </h2>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec2-subtitle">NEZUKO KAMADO</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec2-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect2_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>

            <p class="l-kimetsu__sec-des l-kimetsu__sec2-des">Boston-style frame in a soft pink tone much like the colour of Nezuko’s kimono. The temples feature a metallic accent inspired by bamboo with the reverse side in a checked pattern taken from Nezuko’s obi sash. This is a cute pair that exudes a gentle but delightful charm.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec2-details-price">
                        <h5>S$258</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                    </div>
                                        <a href="/sg/en/products/KMTY2002Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec2-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Acrylic (demo lens) / <br class="d-block d-md-none d-lg-block d-xl-none">Frame: Acetate, Alloy</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 50mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 19mm / <br class="d-none d-md-block">Temple Length: Approx. 140mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
    <section class="l-kimetsu__sec l-kimetsu__sec3" id="sec3">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect3_chara.webp" alt="Zenitsu Agatsuma" class="l-kimetsu__sec-img l-kimetsu__sec3-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec3-title">
                <span class="l-kimetsu__sec-number l-kimetsu__sec3-number">3</span>
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect3_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect3_name_m.png" alt="Zenitsu Agatsuma Model">
                    </picture>
                </span>
            </h2>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec3-subtitle">ZENITSU AGATSUMA</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec3-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect3_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>

            <p class="l-kimetsu__sec-des l-kimetsu__sec3-des">A chic design in the trending Crown Panto style. The front features a gradient tone while the end pieces come in a yellow pattern mirroring that on Zenitsu’s Nichirin Sword handle, offering an extra accent to the eye area. A metal emblem in the design of his sword handguard is set on the tip of the temples while the reverse side features a fish scale motif from the haori jacket that Zenitsu wears.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec3-details-price">
                        <h5>S$258</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                    </div>
                                        <a href="/sg/en/products/KMTY2003Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec3-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Acrylic (demo lens) / <br class="d-block d-md-none d-lg-block d-xl-none">Frame: Acetate, Alloy</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 48mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 20mm / <br class="d-none d-md-block">Temple Length: Approx. 140mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
    <section class="l-kimetsu__sec l-kimetsu__sec4" id="sec4">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect4_chara.webp" alt="Inosuke Hashibira" class="l-kimetsu__sec-img l-kimetsu__sec4-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec4-title">
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect4_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect4_name_m.png" alt="Inosuke Hashibira Model">
                    </picture>
                </span>
                <span class="l-kimetsu__sec-number l-kimetsu__sec4-number">4</span>
            </h2>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec4-subtitle">INOSUKE HASHIBIRA</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec4-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect4_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>

            <p class="l-kimetsu__sec-des l-kimetsu__sec4-des">A sharp design in the rectangle style created based on Inosuke’s fierce, impulsive personality. The metal temples are designed to resemble the jagged blade of Inosuke’s Nichirin Swords and are encased in clear acetate. With a minimalist colourway, it is a versatile pair suitable for all occasions.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec4-details-price">
                        <h5>S$258</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                    </div>
                                        <a href="/sg/en/products/KMTY2004Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec4-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Acrylic (demo lens) / <br class="d-block d-md-none d-lg-block d-xl-none">Frame: Acetate</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 55mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 16mm / <br class="d-none d-md-block">Temple Length: Approx. 145mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
    <section class="l-kimetsu__sec l-kimetsu__sec5" id="sec5">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect5_chara.webp" alt="Giyu Tomioka" class="l-kimetsu__sec-img l-kimetsu__sec5-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec5-title">
                <span class="l-kimetsu__sec-number l-kimetsu__sec5-number">5</span>
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect5_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect5_name_m.png" alt="Giyu Tomioka Model">
                    </picture>
                </span>
            </h2>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec5-subtitle">GIYU TOMIOKA</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec5-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect5_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>

            <p class="l-kimetsu__sec-des l-kimetsu__sec5-des">Wellington-style frame in a deep sapphire colour that denotes the blue, melancholic eyes of Giyu, a.k.a. the Water Hashira. The end pieces of the frame come in a pattern mirroring that on Giyu’s Nichirin Sword handle, offering an extra accent to the eye area. The temples follow the split design of Giyu’s haori jacket, featuring a burgundy shade on the external and a rhombille tiling tessellation on the reverse side. With a metal emblem in the kanji character for “metsu”, meaning destroy, set on the tip of the temples, this is a frame that conveys the comportment of the Demon Slayer Corps.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec5-details-price">
                        <h5>S$258</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                    </div>
                                        <a href="/sg/en/products/KMTY2005Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec5-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Acrylic (demo lens) / <br class="d-block d-md-none d-lg-block d-xl-none">Frame: Acetate, Alloy</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 53mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 17mm / <br class="d-none d-md-block">Temple Length: Approx. 140mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
    <section class="l-kimetsu__sec l-kimetsu__sec6" id="sec6">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect6_chara.webp" alt="Shinobu Kocho" class="l-kimetsu__sec-img l-kimetsu__sec6-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec6-title">
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect6_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect6_name_m.png" alt="Shinobu Kocho Model">
                    </picture>
                </span>
                <span class="l-kimetsu__sec-number l-kimetsu__sec6-number">6</span>
            </h2>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec6-subtitle">SHINOBU KOCHO</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec6-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect6_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>

            <p class="l-kimetsu__sec-des l-kimetsu__sec6-des">A feminine Boston-styled frame in a bright, alluring shade of purple that denotes Shinobu Kocho, a.k.a. the Insect Hashira. The reverse side of the metal temples features an exquisite butterfly wing motif from the haori jacket that Shinobu wears. The end pieces of the frame come in a pattern mirroring that on Shinobu’s Nichirin Sword handle with a floral embellishment in the design of her sword handguard. This is a frame that is both elegant and cute.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec6-details-price">
                        <h5>S$258</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                    </div>
                                        <a href="/sg/en/products/KMTY2006Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec6-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Acrylic (demo lens) / <br class="d-block d-md-none d-lg-block d-xl-none">Frame: Acetate, Alloy</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 49mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 20mm / <br class="d-none d-md-block">Temple Length: Approx. 140mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
        <section class="l-kimetsu__sec l-kimetsu__sec7" id="sec7">
        <div class="l-kimetsu--container">
            <img data-src="https://www.owndays.com/images/specials/products/kimetsu/sect7_chara.webp" alt="Kyojuro Rengoku" class="l-kimetsu__sec-img l-kimetsu__sec7-img lazyload">
            <h2 class="l-kimetsu__sec-title l-kimetsu__sec7-title">
                <span class="l-kimetsu__sec-number l-kimetsu__sec7-number">7</span>
                <span>
                    <picture>
                        <source media="(min-width:768px)" srcset="https://www.owndays.com/images/specials/products/kimetsu/sg/sect7_name.png">
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kimetsu/sg/sect7_name_m.png" alt="Kyojuro Rengoku Model">
                    </picture>
                </span>
            </h2>
            <div class="l-kimetsu__sec-badge l-kimetsu__sec7-badge">
                <p class="l-kimetsu__sec-badge-header">Computer<br>Glasses</p>
                <p class="l-kimetsu__sec-badge-body">Reduce Blue Light<span>Up to<strong>25%</strong><br><small>based on<br>International<br>Standard</small></span></p>
                <p class="l-kimetsu__sec-badge-footer"></p>
            </div>
            <h3 class="l-kimetsu__sec-subtitle l-kimetsu__sec7-subtitle">KYOJURO RENGOKU</h3>
            <div class="l-kimetsu__sec-slider l-kimetsu__sec7-slider">
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame1.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame2.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame3.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame4.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame5.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame6.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame7.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame8.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
                <div class="l-kimetsu__sec-slider-item">
                    <img data-src="https://www.owndays.com/images/specials/products/kimetsu/slider/sect7_frame9.webp" alt="" class="l-kimetsu__sec-slider-img lazyload">
                </div>
            </div>

            <p class="l-kimetsu__sec-des l-kimetsu__sec7-des">A bold design in the browline style that resembles a flame with a metal emblem in the shape of Rengoku’s signature eyebrows added as a decorative element on each side of the frame front. To symbolise the righteousness of Rengoku’s character, a luxurious champagne-gold tone is used for all metal components of the frame. Temple tips come in a flame-like colourway and are adorned with the kanji character for “metsu” which means destroy. This is a frame that conveys the vigour and burning passion of a chivalrous swordsman.</p>
            <div class="l-kimetsu__sec-details">
                <div class="l-kimetsu__sec-details-left">
                    <div class="l-kimetsu__sec-details-price l-kimetsu__sec7-details-price">
                        <h5>S$298</h5>
                        <p>Comes with exclusive spectacle case and cleaning cloth</p>
                                                <p>*Top up fee of S$98 applies to replace lenses to standard prescription lenses</p>
                                            </div>
                                        <a href="/sg/en/products/KMTY1001Y-1S" class="l-kimetsu__sec-details-btn l-kimetsu__sec7-details-btn">Order Online</a>
                                    </div>
                <div class="l-kimetsu__sec-details-right">
                    <dl>
                        <dt>Materials</dt>
                        <dd>Lens: Plastic (99% UV reduction; <br class="d-none d-lg-none d-xl-block">Up to 25% (based on International Standard); <br class="d-none d-lg-none d-xl-block">with super water-repellent coating) / Frame: Titanium; Acetate</dd>
                    </dl>
                    <dl>
                        <dt>Size</dt>
                        <dd>Lens Width: Approx. 49mm / <br class="d-block d-md-none d-lg-block d-xl-none">Nose Bridge Length: Approx. 20mm / <br class="d-none d-md-block">Temple Length: Approx. 140mm</dd>
                    </dl>
                </div>
            </div>
        </div>
    </section>
    
        <section class="l-kimetsu__lens">
        <h2 class="l-kimetsu__sub-title">
            <span class="l-kimetsu__sub-title-line l-kimetsu__sub-title-line--left l-kimetsu__sub-title-line--dark"></span>
            <span class="l-kimetsu__sub-title-text">Available shops</span>
            <span class="l-kimetsu__sub-title-line l-kimetsu__sub-title-line--right l-kimetsu__sub-title-line--dark"></span>
        </h2>
        <ul class="l-kimetsu__shops l-content--inner c-list-decoration c-list-decoration--dot">
            <li><a href="/sg/en/shops/907">OWNDAYS 313@somerset</a></li>
            <li><a href="/sg/en/shops/908">OWNDAYS Bedok Mall</a></li>
            <li><a href="/sg/en/shops/909">OWNDAYS nex</a></li>
            <li><a href="/sg/en/shops/922">OWNDAYS IMM Outlet</a></li>
            <li><a href="/sg/en/shops/928">OWNDAYS Jurong Point</a></li>
            <li><a href="/sg/en/shops/931">OWNDAYS Vivo City</a></li>
            <li><a href="/sg/en/shops/933">OWNDAYS Waterway Point</a></li>
            <li><a href="/sg/en/shops/968">OWNDAYS Northpoint City</a></li>
            <li><a href="/sg/en/shops/1005">OWNDAYS Causeway Point</a></li>
            <li><a href="/sg/en/shops/976">OWNDAYS AMK Hub</a></li>
            <li><a href="/sg/en/shops/989">OWNDAYS Jewel Changi</a></li>
            <li><a href="/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a></li>
        </ul>
    </section>
        <section class="l-kimetsu__footer">
        <div class="l-kimetsu--container">
            <ul class="l-kimetsu__footer-social">
                <li>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/kimetsu" target="_blank">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                </li>
                                <li>
                    <a href="https://www.instagram.com/owndays_sg/" target="_blank" rel="noopener noreferrer">
                        <i class="fab fa-instagram"></i>
                    </a>
                </li>
                            </ul>
            <p class="l-kimetsu__footer-text">©Koyoharu Gotoge / SHUEISHA, Aniplex, ufotable</p>
        </div>
    </section>
</section>

        </main>
            </div>

   
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://code.jquery.com/jquery-migrate-3.0.1.js"></script>
<script src="https://www.owndays.com/web/js/slick.js" defer></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://www.owndays.com/web/js/specials-kimetsu.js" defer></script>
<script>
    (function(d) {
        var config = {
                kitId: 'eej5ely',
                scriptTimeout: 3000,
                async: true
            },
            h = d.documentElement,
            t = setTimeout(function() {
                h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
            }, config.scriptTimeout),
            tk = d.createElement("script"),
            f = false,
            s = d.getElementsByTagName("script")[0],
            a;
        h.className += " wf-loading";
        tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
        tk.async = true;
        tk.onload = tk.onreadystatechange = function() {
            a = this.readyState;
            if (f || a && a != "complete" && a != "loaded") return;
            f = true;
            clearTimeout(t);
            try {
                Typekit.load(config)
            } catch (e) {}
        };
        s.parentNode.insertBefore(tk, s)
    })(document);
</script>
 
</body>

</html>`;

const KimetsuCollectionHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Discover six unique spectacle frames inspired by the characters from Demon Slayer: Kimetsu no Yaiba. Each frame embodies the essence of a character's iconic patterns."
        title="Demon Slayer: Kimetsu no Yaiba x OWNDAYS | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default KimetsuCollectionHTML;
