export const CARDS = [
  { alt: 'OWNDAYS', src: 'card-1.webp' },
  { alt: 'GIFT FOR YOU', src: 'card-2.webp' },
  { alt: 'Happy Birthday', src: 'card-3.webp' },
  { alt: 'Thank you', src: 'card-4.webp' },
  { alt: 'Thank you', src: 'card-5.webp' },
  { alt: 'Happy Valentine Day', src: 'card-6.webp' },
];

export const HOW_TO_SEND = [
  {
    imgAlt: 'Creating an eGift',
    imgSrc: 'how-to-send-1.webp',
    heading: 'Creating an eGift',
    description:
      'Select the card design and the card value, then add a personalised message to the recipient.',
  },
  {
    imgAlt: 'Purchasing an eGift',
    imgSrc: 'how-to-send-2.webp',
    heading: 'Purchasing an eGift',
    description: 'Add to cart and proceed to checkout.',
  },
  {
    imgAlt: 'Sending an eGift',
    imgSrc: 'how-to-send-3.webp',
    heading: 'Sending an eGift',
    description: 'Share the URL to retrieve the eGift with the recipient via Facebook or email.',
  },
];

export const HOW_TO_USE = [
  {
    imgAlt: 'Receiving an eGift',
    imgSrc: 'how-to-use-1.webp',
    heading: 'Receiving an eGift',
    description: 'eGift will be sent via Facebook or email.',
  },
  {
    imgAlt: 'Shopping♪',
    imgSrc: 'how-to-use-2.webp',
    heading: 'Shopping♪',
    description: 'Shop OWNDAYS eyewear in-store or online.',
  },
  {
    imgAlt: 'Using eGift',
    imgSrc: 'how-to-use-3.webp',
    heading: 'Using eGift',
    description:
      'Flash eGift on your mobile device to the staff in-store prior to payment; enter gift code during checkout for online orders.',
  },
];

export const TERM_AND_CONDITIONS = [
  'Each eGift is valid within one year of purchase.',
  'Each eGift is valid for one time use only.',
  'Multiple eGifts may be used in one transaction.',
  'eGift is non-refundable nor exchangeable for cash or other reward forms and cannot be re-issued.',
  'Purchase exceeding the eGift value shall be topped up with other accepted payment methods.',
  'The full value must be utilised in a single purchase. There is strictly no refund of any kind for any unused value.',
  'eGift must be used before it expires. There is strictly no extension of expiry date.',
  'For in-store purchase, eGift must be presented prior to payment; for online purchase, gift code must be entered during checkout.',
  'The management reserves the right to amend the terms and conditions at any time without prior notice.',
];
