import * as actionTypes from './../../actionTypes/ui';

const initialState = {
  showOdLoader: false,
};

export default function ui(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.SHOW_OD_LOADER:
      return Object.assign({}, prevState, {
        showOdLoader: true,
      });
    case actionTypes.HIDE_OD_LOADER:
      return Object.assign({}, prevState, {
        showOdLoader: false,
      });
    default:
      return prevState;
  }
}
