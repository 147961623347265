import React from 'react';
import './ExpiryInfo.scss';
import { useSelector } from 'react-redux';
import { formatDateToDDMMYYYY } from '../../../../helper';

const ExpiryInfo = () => {
  const dataLocale = useSelector(state => state.locale);
  const { EXPIRY_BY } = dataLocale;

  const expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  return (
    <div className="od-expiry-info od-font-bold">
      <span className="od-expiry-info__text">{EXPIRY_BY} </span>
      <span className="od-expiry-info__date">{formatDateToDDMMYYYY(expiryDate)}</span>
    </div>
  );
};

export default ExpiryInfo;
