export const ORDER_LIST_LOAD = 'myAccount/ORDER_LIST_LOAD';
export const ORDER_LIST_SUCCESS = 'myAccount/ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAIL = 'myAccount/ORDER_LIST_FAIL';
export const ORDER_LIST_LOAD_MORE = 'myAccount/ORDER_LIST_LOAD_MORE';

export const RESET_PASSWORD_LOAD = 'myAccount/RESET_PASSWORD_LOAD';
export const RESET_PASSWORD_SUCCESS = 'myAccount/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'myAccount/RESET_PASSWORD_FAIL';

export const UPDATE_ADDRESS_LOAD = 'myAccount/UPDATE_ADDRESS_LOAD';
export const UPDATE_ADDRESS_SUCCESS = 'myAccount/UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAIL = 'myAccount/UPDATE_ADDRESS_FAIL';

export const UPDATE_USER_INFO_LOAD = 'myAccount/UPDATE_USER_INFO_LOAD';
export const UPDATE_USER_INFO_SUCCESS = 'myAccount/UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'myAccount/UPDATE_USER_INFO_FAIL';

export const CHECK_VOUCHER_LOAD = 'myAccount/CHECK_VOUCHER_LOAD';
export const CHECK_VOUCHER_SUCCESS = 'myAccount/CHECK_VOUCHER_SUCCESS';
export const CHECK_VOUCHER_FAIL = 'myAccount/CHECK_VOUCHER_FAIL';

export const STORE_CREDIT_BAL_LOAD = 'myAccount/STORE_CREDIT_BAL_LOAD';
export const STORE_CREDIT_BAL_SUCCESS = 'myAccount/STORE_CREDIT_BAL_SUCCESS';
export const STORE_CREDIT_BAL_FAIL = 'myAccount/STORE_CREDIT_BAL_FAIL';

export const STORE_CREDIT_ORDERS_LOAD = 'myAccount/STORE_CREDIT_ORDERS_LOAD';
export const STORE_CREDIT_ORDERS_SUCCESS = 'myAccount/STORE_CREDIT_ORDERS_SUCCESS';
export const STORE_CREDIT_ORDERS_FAIL = 'myAccount/STORE_CREDIT_ORDERS_FAIL';

export const STORE_CREDIT_ORDER_HISTORY_LOAD = 'myAccount/STORE_CREDIT_ORDER_HISTORY_LOAD';
export const STORE_CREDIT_ORDER_HISTORY_SUCCESS = 'myAccount/STORE_CREDIT_ORDER_HISTORY_SUCCESS';
export const STORE_CREDIT_ORDER_HISTORY_FAIL = 'myAccount/STORE_CREDIT_ORDER_HISTORY_FAIL';

export const STORE_CREDIT_ORDER_SMS_LOAD = 'myAccount/STORE_CREDIT_ORDER_SMS_LOAD';
export const STORE_CREDIT_ORDER_SMS_SUCCESS = 'myAccount/STORE_CREDIT_ORDER_SMS_SUCCESS';
export const STORE_CREDIT_ORDER_SMS_FAIL = 'myAccount/STORE_CREDIT_ORDER_SMS_FAIL';

export const EDIT_NEFT_LOAD = 'myAccount/EDIT_NEFT_LOAD';
export const EDIT_NEFT_SUCCESS = 'myAccount/EDIT_NEFT_SUCCESS';
export const EDIT_NEFT_FAIL = 'myAccount/EDIT_NEFT_FAIL';

export const CLEAR_STATE = 'myAccount/CLEAR_STATE';

export const RETURN_ORDER_CONFIG_LOAD = 'myAccount/RETURN_ORDER_CONFIG_LOAD';
export const RETURN_ORDER_CONFIG_SUCCESS = 'myAccount/RETURN_ORDER_CONFIG_SUCCESS';
export const RETURN_ORDER_CONFIG_FAIL = 'myAccount/RETURN_ORDER_CONFIG_FAIL';

export const PFU_PD_RULES_CONFIG_LOAD = 'myAccount/PFU_PD_RULES_CONFIG_LOAD';
export const PFU_PD_RULES_CONFIG_SUCCESS = 'myAccount/PFU_PD_RULES_CONFIG_SUCCESS';
export const PFU_PD_RULES_CONFIG_FAIL = 'myAccount/PFU_PD_RULES_CONFIG_FAIL';

export const NEW_ORDER_CHECK = 'myAccount/NEW_ORDER_CHECK';

export const COD_CONFIRMATION_LOAD = 'order/COD_CONFIRMATION_LOAD';
export const COD_CONFIRMATION_SUCCESS = 'order/COD_CONFIRMATION_SUCCESS';
export const COD_CONFIRMATION_FAIL = 'order/COD_CONFIRMATION_FAIL';

export const CLEAR_COD_CONFIRM_STATE = 'myAccount/CLEAR_COD_CONFIRM_STATE';

export const EXCHANGE_FLOW_SET = 'myAccount/RETURN_REASON';

export const GET_ORDER_ITEMS_RETURN_ELIGIBILITY_ARR =
  'myAccount/GET_ORDER_ITEMS_RETURN_ELIGIBILITY_ARR';
export const GET_ORDER_ITEMS_RETURN_ELIGIBILITY_ARR_SUCCESS =
  'myAccount/GET_ORDER_ITEMS_RETURN_ELIGIBILITY_ARR_SUCCESS';
export const GET_ORDER_ITEMS_RETURN_ELIGIBILITY_ARR_FAIL =
  'myAccount/GET_ORDER_ITEMS_RETURN_ELIGIBILITY_ARR_FAIL';

export const REFRESH_ORDER = 'REFRESH_ORDER';
