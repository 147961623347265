import * as actionTypes from './../../actionTypes/shortlist';
import * as commonActionTypes from './../../actionTypes/common';

const initialState = {
  shortlistedProducts: null,
  wishListProductsLoading: true,
};

export default function shortlist(prevState = initialState, action) {
  switch (action.type) {
    case commonActionTypes.SHORTLIST_DELETE:
      return Object.assign({}, prevState, {
        wishListProductsLoading: true,
      });
    case commonActionTypes.SHORTLIST_DELETE_SUCCESS:
      return Object.assign({}, prevState, {
        wishListProductsLoading: false,
      });
    case actionTypes.SHORTLIST_PRODUCTS_LOAD:
      return Object.assign({}, prevState, {
        wishListProductsLoading: true,
      });
    case actionTypes.SHORTLIST_PRODUCTS_SUCCESS:
      return Object.assign({}, prevState, {
        shortlistedProducts: action.data || [],
        wishListProductsLoading: false,
      });
    case actionTypes.SHORTLIST_PRODUCTS_FAIL:
      return Object.assign({}, prevState, {
        wishListProductsLoading: false,
      });
    case actionTypes.SHORTLIST_SUCCESS:
      return Object.assign({}, prevState, {
        wishListProductsLoading: false,
      });
    case actionTypes.SHORTLIST_FAIL:
      return Object.assign({}, prevState, {
        wishListProductsLoading: false,
      });
    default:
      return prevState;
  }
}
