import * as actionTypes from '../../actionTypes/newMSiteOfflineSalesFlow';

const initialState = {
  salesmanData: null,
};

export default function newMSiteOfflineSalesFlow(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.SALESMAN_ID_VERIFY:
      return Object.assign({}, prevState, {
        salesmanData: null,
        salesmanVerifyError: null,
        isSalesmanVerificationLoading: true,
      });
    case actionTypes.SALESMAN_ID_VERIFY_SUCCESS:
      return Object.assign({}, prevState, {
        salesmanData: action.data,
        salesmanVerifyError: null,
        isSalesmanVerificationLoading: false,
      });
    case actionTypes.SALESMAN_ID_VERIFY_FAIL:
      return Object.assign({}, prevState, {
        salesmanVerifyError: action.error,
        salesmanData: null,
        isSalesmanVerificationLoading: false,
      });
    default:
      return prevState;
  }
}
