import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="description" content="OWNDAYS PETAL MONTHLY - Discover the new you with colour contact lens by OWNDAYS! Comes in 4 series with a variety of colour options to suit your preference and style. Available in daily and monthly lenses at selected stores." />
<meta name="keywords" content="OWNDAYS PETAL MONTHLY,Monthly,10 lenses,colour contact lens,soft contact lens,original" />
<meta name="og:title" content="OWNDAYS PETAL MONTHLY | OWNDAYS ONLINE STORE - OPTICAL SHOP" />
<meta name="og:description" content="OWNDAYS PETAL MONTHLY - Discover the new you with colour contact lens by OWNDAYS! Comes in 4 series with a variety of colour options to suit your preference and style. Available in daily and monthly lenses at selected stores." />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
<meta name="twitter:card" content="summary_large_image" />
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/contacts/color-contactlens/petal">
          <meta
    property="og:image"
    content="https://www.owndays.com/images/specials/products/color-contactlens/ogp_1200x630.jpg?1606914699"
  >
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="stylesheet" href="https://use.typekit.net/wib1bvw.css">
<link rel="stylesheet" href="https://use.typekit.net/euq4tnf.css">
<link href="https://fonts.googleapis.com/css?family=Noto+Sans" rel="stylesheet">
<link href="https://www.owndays.com/web/css/specials-color-contactlens-petal.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">OWNDAYS PETAL MONTHLY | OWNDAYS ONLINE STORE - OPTICAL SHOP </title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>



<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            OWNDAYS PETAL MONTHLY                    </li>
        
            </ul>
</div>


<!-- contents_area -->
<section class="wp" id="wrapper">
    <section class="main">
        <ul class="main_tit">
            <li>
                <h1>
                    <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/logo.svg" alt="OWNDAYS PETAL MONTHLY" class="title-main">
                    <p>Monthly <br class="u-sp-only">Contact Lens</p>
                </h1>
                <p class="color-variation">6 COLOURS</p>
            </li>
            <li>
                <div class="pk_visual">
                    <img src="https://www.owndays.com/images/specials/products/color-contactlens/package-petal.png" alt="OWNDAYS PETAL package" class="all_p">
                </div>
            </li>
        </ul>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1500 50" preserveAspectRatio="none" id="svg-bg">
            <path d="M0,0 v25 q10,10 20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 v-25 Z" fill="#fff"></path>
        </svg>
    </section>
    <!--#movie -->
    <!-- <section class="movie" id="movie">
        <h3>MOVIE</h3>
        <div id="modal-content">
            <div class="inner">
                <div id="player02"></div>
            </div>
        </div>
        <a id="modal-open" class="movie-thumbnail" href="#movie"><img src="https://www.owndays.com/images/specials/products/color-contactlens/petal.jpeg" alt="OWNDAYS VELVET MOVIE">
            <svg class="play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="-264 388.1 66.8 66.8" style="enable-background:new -264 388.1 66.8 66.8;" xml:space="preserve">
                <path d="M-230.6,450.1c-15.8,0-28.6-12.9-28.6-28.6c0-15.8,12.9-28.6,28.6-28.6s28.6,12.9,28.6,28.6C-202,437.3-214.8,450.1-230.6,450.1z M-230.6,395.3c-14.5,0-26.2,11.7-26.2,26.2s11.7,26.2,26.2,26.2s26.2-11.7,26.2-26.2C-204.4,407-216.1,395.3-230.6,395.3z"></path>
                <path d="M-239.7,434.2v-25.4l22,12.7L-239.7,434.2z M-236.8,413.7v15.6l13.5-7.8L-236.8,413.7z"></path>
            </svg>
        </a>
    </section> -->
    <!--/#movie -->

    <section class="features b_fade_out">
        <h3 class="title-features">FEATURES</h3>
        <div>
            <div class="pt_txt">
                <h5><strong>01</strong>Embedded Colour Pigment</h5>
                <p>Colour pigment is embedded within the lens material to avoid direct contact with the eye, thereby ensuring safer and more comfortable wear</p>
            </div>
            <div class="pt_txt">
                <h5><strong>02</strong>Tri-Curve Edge</h5>
                <p>Lens edge features a tri-curve design to enhance fit and comfort while allowing easier handling</p>
            </div>
        </div>
    </section>
    <section class="about">
        <h3 class="title-features">LINE UP</h3>
        <ul>
            <li>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/lens-petal-violet.png" alt="" class="lens-image">
                <h4>Petal Violet</h4>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/image-petal-violet.png" alt="" class="eye-image">
            </li>
            <li>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/lens-petal-blue.png" alt="" class="lens-image">
                <h4>Petal Blue</h4>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/image-petal-blue.png" alt="" class="eye-image">
            </li>
            <li>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/lens-petal-choco.png" alt="" class="lens-image">
                <h4>Petal Choco</h4>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/image-petal-choco.png" alt="" class="eye-image">
            </li>
            <li>
                <p>NEW</p>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/lens-petal-grey.png" alt="" class="lens-image">
                <h4>Petal Grey</h4>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/image-petal-grey.png" alt="" class="eye-image">
            </li>
            <li>
                <p>NEW</p>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/lens-petal-green.png" alt="" class="lens-image">
                <h4>Petal Green</h4>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/image-petal-green.png" alt="" class="eye-image">
            </li>
            <li>
                <p>NEW</p>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/lens-petal-brown.png" alt="" class="lens-image">
                <h4>Petal Brown</h4>
                <img src="https://www.owndays.com/images/specials/products/color-contactlens/petal/image-petal-brown.png" alt="" class="eye-image">
            </li>
        </ul>
    </section>
    <section class="spec b_fade_out">
        <h3>SPEC</h3>
        <ul>

            <li>
                <h6>Product Name</h6>
                <p>OWNDAYS PETAL MONTHLY</p>
            </li>
            <li>
                <h6>Water Content</h6>
                <p>40%</p>
            </li>
            <li>
                <h6>Diameter (DIA)</h6>
                <p>14.5mm</p>
            </li>
            <li>
                <h6>Base Curve (BC)</h6>
                <p>8.6mm</p>
            </li>
            <li>
                <h6>Oxygen Permeability (DK)</h6>
                <p>11.3</p>
            </li>
            <li>
                <h6>Centre Thickness</h6>
                <p>0.08mm <small>*1</small></p>
            </li>
            <li>
                <h6>Material</h6>
                <p>HEMA</p>
            </li>
            <li>
                <h6>UV Inhibitor</h6>
                <p>No</p>
            </li>
            <li>
                <h6>Pack Size</h6>
                <p>2 pieces</p>
            </li>
            <li>
                <h6>Power (PWR)</h6>
                <p>Plano, -0.75 to -8.00 <small>*2</small></p>
            </li>
            <li>
                <h6>Price</h6>
                <p>S$15</p>
            </li>
        </ul>
        <ul class="caution">
            <li>*1　For -3.00D</li>
            <li>*2　Below -5.00 in 0.25 steps / Above -5.00 in 0.50 steps</li>
        </ul>
    </section>
    <section class="shop b_fade_out">
        <h3>AVAILABLE SHOPS</h3>
        <ul>
            <li><a href="/sg/en/shops/907">OWNDAYS 313@somerset</a></li>
            <li><a href="/sg/en/shops/908">OWNDAYS Bedok Mall</a></li>
            <li><a href="/sg/en/shops/909">OWNDAYS nex</a></li>
            <li><a href="/sg/en/shops/928">OWNDAYS Jurong Point</a></li>
            <li><a href="/sg/en/shops/931">OWNDAYS Vivo City</a></li>
            <li><a href="/sg/en/shops/933">OWNDAYS Waterway Point</a></li>
            <li><a href="/sg/en/shops/948">OWNDAYS Compass One</a></li>
            <li><a href="/sg/en/shops/962">OWNDAYS Westgate</a></li>
            <li><a href="/sg/en/shops/968">OWNDAYS Northpoint City</a></li>
            <li><a href="/sg/en/shops/983">OWNDAYS Century Square</a></li>
            <li><a href="/sg/en/shops/1004">OWNDAYS Raffles City</a></li>
            <li><a href="/sg/en/shops/1005">OWNDAYS Causeway Point</a></li>
            <li><a href="/sg/en/shops/920">OWNDAYS ION Orchard</a></li>
            <li><a href="/sg/en/shops/1007">OWNDAYS MBS Premium Concept Store</a></li>
            <li><a href="/sg/en/shops/1011">OWNDAYS BUGIS JUNCTION</a></li>
            <li><a href="/sg/en/shops/1020">OWNDAYS Takashimaya S.C. Premium Concept Store</a></li>
        </ul>
    </section>
</section>
<!-- /contents_area -->
        </main>
            </div>

    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://code.jquery.com/jquery-migrate-3.0.1.js"></script>
<script src="https://www.owndays.com/web/js/sg/specials-color-contactlens_petal.js" defer></script>
 
</body>

</html>`;

const ContactLensPetalHTML = () => {
  return (
    <>
      <MetaTagsManager
        canonical="/contacts/color-contactlens/petal"
        description="Experience enhanced comfort with OWNDAYS Petal Monthly contact lenses, available in six different colors, featuring embedded color pigment and a tri-curve edge."
        title="OWNDAYS Petal Montly Contact Lens | OWNDAYS Singapore"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default ContactLensPetalHTML;
