import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
                                                    <meta property="og:title" content="EYE CAMP｜OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                                                    <meta name="description" content="OWNDAYS Eye Camp is a CRS initiative where OWNDAYS provides relief support to the needy communities around the world.">
                                            <meta name="keywords" content="CSR,EYE CAMP,Giving back to society,Regional activity,glasses,spectacles,optical shop,online store,eyeglasses,OWNDAYS,frame,sunglasses,pc lenses">
                                                                                                        <meta property="og:description" content="OWNDAYS Eye Camp is a CRS initiative where OWNDAYS provides relief support to the needy communities around the world.">
                                            <meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
                                                        <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/company/eyecamp">
                <meta property="og:image" content="https://www.owndays.com/images/company/csr/ogp_1200x630.jpg?1553871931">
                <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="preload" as="style" href="https://www.owndays.com/web/css/owl.carousel.min.css">
<link href="https://www.owndays.com/web/css/owl.carousel.min.css" rel="stylesheet">
<link rel="preload" as="style" href="https://www.owndays.com/web/css/owl.theme.default.css">
<link href="https://www.owndays.com/web/css/owl.theme.default.css" rel="stylesheet">
<link rel="preload" as="style" href="https://www.owndays.com/web/css/csr.css">
<link href="https://www.owndays.com/web/css/csr.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
    <title id="title">    EYE CAMP｜OWNDAYS ONLINE STORE - OPTICAL SHOP 
</title>
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>
 


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            
<div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                            Eye Camp                    </li>
        
            </ul>
</div>


<div class="l-csr">
    <section class="p-main">
        <div class="p-main__bg">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/company/csr/csr_main-2990x1120.webp">
                <img src="https://www.owndays.com/images/company/csr/csr_main-768x1031.webp" alt="">
            </picture>
        </div>
        <div class="p-main__title">
            <h1 class="p-main__title-default">Eye Camp<span>Seeing the unknown future with OWNDAYS</span>
            </h1>
        </div>
    </section>
    <section class="l-csr__message l-content--inner">
        <div class="u-row">
            <div class="u-col l-csr__message--logo">
                <div>
                    <img src="https://www.owndays.com/images/company/csr/logo_owndays.svg" alt="OWNDAYSロゴ">
                    <h2>
                        For all we will encounter <br class="u-pc-only">in the future<span>Sharing the vision with the world.</span>                    </h2>
                </div>
            </div>
            <div class="u-col l-csr__message--text">
                <h3>We at OWNDAYS have a dream – <br class="u-pc-only">“To enrich the life of everyone who has a relationship with OWNDAYS.”</h3>
                <p>It is believed that about four billion people in the world need visual correction. However, half of them are unable to get their vision corrected due to the lack of eye care facilities as well as financial difficulties.<br><br>It saddens us to learn that so many people have to endure the inconveniences and agonies caused by poor vision so we begin pondering what we can do for them.<br><br>It is, of course, impossible to change the world or help every single person in need.<br><br>However, we know that there would be no improvement to the situation if we do nothing.<br><br>Through OWNDAYS glasses, we hope to bring people a clearer and a more beautiful vision before their eyes.</p>
            </div>
        </div>
    </section>
        <section class="l-csr__eyecamp p-main" id="eye-camp">
        <div class="p-main__bg">
            <picture>
                <source media="(min-width:768px)" srcset="https://www.owndays.com/images/company/csr/csr_sub-2990x1120.webp">
                <img src="https://www.owndays.com/images/company/csr/csr_sub-768x1031.webp" alt="">
            </picture>
        </div>
        <div class="p-main__title">
            <h3 class="p-main__title-default">
                OWNDAYS<br>Eye Camp                <span>Sharing the vision with the world.</span>
            </h3>
        </div>
    </section>
    <section class="l-csr__message l-content--inner">
        <div class="u-row">
            <div class="u-col l-csr__message--text">
                <h3>In OWNDAYS, part of our profits goes towards giving<br class="u-pc-only">out free glasses to people in developing countries.</h3>
                <p>The OWNDAYS Eye Camp is conducted every 3 months in partnership with local non-governmental organizations in developing countries. Volunteer doctors conduct eye checks and carry out eye health evaluations to screen for signs of eye diseases.<br><br>OWNDAYS then makes the glasses using the prescriptions obtained from the eye checks. Not only that, OWNDAYS staff even help the recipients with the adjustment of the glasses to ensure the glasses sit well on their faces.</p>
            </div>
            <div class="u-col l-csr__message--image">
                <img src="https://www.owndays.com/images/company/csr/photo_campflag.webp" alt="OWNDAYS EYE CAMP">
            </div>
        </div>
    </section>
    <section class="l-csr__history">
        <div class="l-content--inner">
            <h2 class="c-title c-title--small-sp">HISTORY</h2>
            <div class="l-csr__list archive-list clearfix">
                            <div class="column" id="202110">
                    <a href="#202110" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/202110/thumb.jpg">
                            <div>
                                <span>IRAQ</span>2021.10
                                <p class="-city">Kaiyara</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>IRAQ</span>Kaiyara
                                                <span class="-date">2021.10.29</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_07.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202110/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The latest OWNDAYS Eye Camp was held in the town of Kaiyara in Iraq, with cooperation from the NPO IVY. This is the first OWNDAYS Eye Camp ever hosted in the Middle East<br>As was the case last year, the impact from the spread of the new coronavirus infectious disease prevented OWNDAYS personnel from making the trip overseas. Instead, the eyeglasses prepared for distribution were consigned to IVY staff members.</p>
                                                <p class="-space">Kaiyara Town in Mosul City was occupied by the Islamic State in Iraq (ISIL) through 2016. In the subsequent operation to recapture the region, a large number of local public facilities were destroyed.<br>The school building of Mashriq Elementary School, utilized as the site for this Eye Camp, was destroyed by ISIL during the occupation. After this area was liberated, the school was rebuilt with solicited donations.</p>
                                                <p class="-space">Our program here was organized to conduct eyesight tests and distribute eyeglasses to local children and their guardians, elderly persons and others living in the neighboring area.<br>For example, children forced to wear broken eyeglasses because they are unable to purchase new ones. Children unable to obtain any eyeglasses up to now. Senior citizens continuing to use reading glasses not fitting their own visual needs.<br>Every recipient of new eyeglasses could be seen smiling at the joy of seeing clearly again.<br>This was the first time that overseas products had been delivered to this town following its liberation. That further increased the joy of the participants.</p>
                                                <p class="-space">Going forward, OWNDAYS has determined to advance it business to Middle Eastern countries.<br>That will be accompanied with ongoing expansion of new activities, with OWNDAYS Eye Camp programs in the Middle East continued to be organized.</p>
                                            </div>
                                                                                    </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="202011">
                    <a href="#202011" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/202011/thumb.jpg">
                            <div>
                                <span>MYANMAR</span>2020.11
                                <p class="-city">Pin Laung,Pin Pgyit,Yauk Sauk</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>MYANMAR</span>Pin Laung, Pin Pgyit, Yauk Sauk
                                                <span class="-date">2020.10.29-11.18</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/202011/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 19th OWNDAYS Eye Camp was held in two regions of Shan State in Myanmar – Pin Laung and Yauk Sauk.</p>
                                                <p class="-space">In organizing these activities, the impact of the spread of the new coronavirus infectious disease was a major factor. The situation made it extremely difficult to conduct the traditional approach of OWNDAYS staff members from various countries gathering at local sites to distribute the eyeglasses. Instead, the 448 eyeglasses produced for this occasion were presented by local doctors and charity group workers, following thorough infection control measures.</p>
                                                <p class="-space">In Pin Laung, about four and a half hours by car from Taunggyi City (the base of our operation), eyeglasses were distributed in the three villages of Yimi, Pinglun and Tigyit. Under administrative instructions, the program was spread over five days, with no more than 30 persons allowed to gather at once. In Yauk Sauk, about a two-hour drive from Taunggyi, distribution took place in three locations –Pin Pgyit and Yadanar Pone villages, and Magin Monastery.</p>
                                                <p class="-space">Though the impact of the pandemic has created unprecedented conditions over the past year, it is exactly at such times that cooperation takes on greater importance. Our aim is to bring smiles to the faces of as many people in Myanmar as possible, while conveying our appreciation to the entire local population. We likewise look forward to the recovery of security and a democratic political system for all Myanmar citizens at the earliest possible time.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/_ef9e71NrqI?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                                <div class="column" id="201912">
                    <a href="#201912" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201912/thumb.jpg">
                            <div>
                                <span>PHILIPPINES</span>2019.12
                                <p class="-city">Sapang Bato, Pampanga</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>PHILIPPINES</span>Sapang Bato, Pampanga
                                                <span class="-date">2019.12.25</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201912/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">OWNDAYS Eye Camp is a way of giving back to our fellow Filipinos. Last December 25, 2019, we had our 5th Eye Camp in the Philippines – aiming to help our less fortunate kababayans from Pampanga and shared with them the gift of sight with the help of Apl.de.ap’s team.</p>
                                                <p class="-space">OWNDAYS Volunteers, including OWNDAYS CEO, Shuji Tanaka, celebrated their Christmas with the families from Sapang Bato, Pampanga, Philippines. There was a total of 863 participants of the Christmas Party and a total of 150 pairs of glasses were given to those who needed them. The children who attended were also given a total of 1,000 school supplies to support their education. Participants and the volunteers enjoyed the Christmas Party that was held at the residence of Mr. Apl.de.ap.</p>
                                                <p class="-space">As we conclude the year 2019, OWNDAYS assures everyone that we will continue to dedicate ourselves to sharing the gift of good vision to those in need through Eye Camp.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/iMtj8K82OLo?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                                                <div class="column" id="201911">
                    <a href="#201911" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201911/thumb.jpg">
                            <div>
                                <span>MYANMAR</span>2019.11
                                <p class="-city">Pekon, Shan</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>MYANMAR</span>Pekon, Shan
                                                <span class="-date">2019.11.09 & 2019.11.11</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201911/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 18th OWNDAYS Eye Camp was held in Pekon, a town in Shan State, Myanmar.</p>
                                                <p class="-space">People in Pekon have limited access to healthcare facilities as it is difficult getting to the cities where the main medical institutions are situated. Despite this, Pekon rarely receives relief from charities so the people there were delighted when we visited them for the OWNDAYS Eye Camp.</p>
                                                <p class="-space">This eye camp, we distributed approximately 400 pairs of glasses to people of all ages, from children to the elderly. The glasses were fitted with prescription lenses catered to each recipient’s needs and there were both reading glasses and those for viewing far distances. One by one, the eye camp crew carefully adjusted the frame fitting for the recipients and checked to ensure their vision was clear and comfortable.</p>
                                                <p class="-space">Similar to previous ones, this OWNDAYS Eye Camp was made possible as a result of the combined effort from various parties. This included our customers around the world whose purchase constituted a portion of the funding for the project, the committee members who liaised with local doctors and schools, the local doctors who conducted eye examination and provided us with the prescription details and finally the OWNDAYS staff in Japan who had taken great care in putting the glasses together. As to the eye camp crew members, we completed the final step of handing over the glasses to the beneficiaries. Beyond being visual aids, these glasses embodied the thoughts of everyone involved in the project, their well-wishes to the needy people of Pekon and our aspiration of making the world look more beautiful through improved vision.</p>
                                                <p class="-space">This OWNDAYS Eye Camp gave us an opportunity to establish a closer bond with the people in Myanmar. Moving forward, we will continue to bring our glasses to those in need so they can experience the joy of having brighter, clearer vision.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/th5yDomvP3Y?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                                                <div class="column" id="201908">
                    <a href="#201908" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201908/thumb.jpg">
                            <div>
                                <span>PHILIPPINES</span>2019.08
                                <p class="-city">Tondo, Manila</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>PHILIPPINES</span>Tondo, Manila
                                                <span class="-date">2019.08.07</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201908/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 2019 OWNDAYS Eye Camp in the Philippines was organised in collaboration with Lions Clubs International, a non-profit service organization. This time, the eye camp was held in Tondo, Manila, one of the most densely populated places in the Philippines where people often face difficulties in accessing adequate supply of water, housing and proper sanitation.<br>Besides, the provision of education and health services as well as employment opportunities are also lacking in the area.</p>
                                                <p class="-space">This eye camp, we gave out over 200 pairs of prescription glasses to individuals who are troubled by poor vision but are unable to afford glasses. In addition, we donated school supplies, schoolbags, shoes, toys and snacks to the local children.</p>
                                                <p class="-space">We hope our effort through the OWNDAYS Eye Camp could help to brighten up people’s lives and create a positive impact in the society. In line with our philosophy of “enriching the lives of people associated with OWNDAYS”, we will continue to dedicate ourselves to helping the less fortunate and to sharing the vision with the world through OWNDAYS Eye Camp.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/HFo_QOe9Dd8?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                                                <div class="column" id="201907">
                    <a href="#201907" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201907/thumb.jpg">
                            <div>
                                <span>THAILAND</span>2019.07
                                <p class="-city">Huai Sai, Chianmai</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>THAILAND</span>Huai Sai, Chianmai
                                                <span class="-date">2019.07.03</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201907/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 17th OWNDAYS Eye Camp took place in Thailand. It was the first eye camp in the country.</p>
                                                <p class="-space">The venue, Kalyanamitsuk Centre, is a rehabilitation centre located in a sub-district of Huai Sai, Chiang Mai. It provides physical therapy for the elderlies and people with disabilities from all over Chiang Mai.<br>We distributed reading glasses and helped the centre residents with cleaning, maintenance and proper fitting of their glasses. As the Buddhist Lent Day was approaching, we also brought along Buddhists scriptures for them.</p>
                                                <p class="-space">The joy of having a good, clear vision is beyond words can describe. Through the OWNDAYS Eye Camp, we have come to recognise the importance of helping people around the world improve their vision so as to enrich their lives.</p>
                                                <p class="-space">To this end, we have dedicated a portion of our profits towards helping the less privileged through the OWNDAYS Eye Camp. We will continue our efforts in sharing vision with the world with OWNDAYS glasses.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/kx_KAs6X4pQ?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201902">
                    <a href="#201902" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201902/thumb.jpg">
                            <div>
                                <span>MYANMAR</span>2019.02
                                <p class="-city">Inle, Taunggyi</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>MYANMAR</span>Inle, Taunggyi
                                                <span class="-date">2019.02.28 & 2019.03.01</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201902/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 16th OWNDAYS Eye Camp was held in Myanmar, a first in the country.</p>
                                                <p class="-space">We distributed prescription glasses to people from around Taunggyi and those living in Inle Lake at the two designated distribution venues. We also gave out stationery and sports equipment to orphans living in a temple at Inle Lake.</p>
                                                <p class="-space">We have distributed prescription glasses around the world, starting from India to Nepal, the Philippines and Myanmar this time. Many people in Myanmar, be it children or adults, are deprived of the chance to enjoy a clear vision.</p>
                                                <p class="-space">“Sharing the vision with the world.”<br>This is the motto of the OWNDAYS Eye Camp. To this end, we will continue travelling across borders to bring prescription glasses to those in need. Instead of simply sending prescription glasses over, we choose to hand the glasses out to the recipients in person in order to witness and share their joy of finally having crystal clear vision.</p>
                                                <p>We will continue to give out prescription glasses to the less privileged individuals in Myanmar so as to enrich their lives and help them see a clearer future.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/81PhesMrPnU?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201810">
                    <a href="#201810" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201810/thumb.jpg">
                            <div>
                                <span>NEPAL</span>2018.10
                                <p class="-city">Jogimara, Dhadin</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>NEPAL</span>Jogimara, Dhadin
                                                <span class="-date">2018.10.25</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201810/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 15th OWNDAYS Eye Camp was held in Dhading District of Nepal.</p>
                                                <p class="-space">From the capital city, Kathmandu, we drove 3 hours through winding, rocky roads to get to Jogimara where we distributed prescription glasses to the local people. We also gave out stationery and toys to children studying at a primary school situated within steep mountainous terrain.<br>Being our 4th eye camp there and having interacted with the local people several times, we have come to forged a stronger bond with both the adults and the children in Nepal. It was a great experience which we could not have acquired in our day-to-day lives at home and it had definitely contributed to our personal growth.</p>
                                                <p>People living in mountainous regions often face issues such as poor accessibility and the lack of daily necessities. Besides, there are still many underprivileged people around the world who are unable to afford glasses or any form of visual corrective aid.<br>We have dedicated a portion of our profits to help these underprivileged people through the OWNDAYS Eye Camp. Guided by our philosophy of enriching the lives of people associated with OWNDAYS, we will continue to travel across borders to distribute prescription glasses and to share vision with the world.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/JcAIDMKY64g?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                                <div class="column" id="201803">
                    <a href="#201803" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201803/thumb.jpg">
                            <div>
                                <span>NEPAL</span>2018.03
                                <p class="-city">Malekhu, Beni Ghat</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>NEPAL</span>Malekhu, Beni Ghat
                                                <span class="-date">2018.03.18</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201803/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 12th OWNDAYS Eye Camp was held in Nepal.</p>
                                                <p class="-space">There were 2 distribution venues this time. We first distributed prescription glasses at Malekhu, a small town that is an hour’s drive away from the capital city of Kathmandu. After which, we headed to Town Beni Ghat where we gave out toys and stationery to the children living there.</p>
                                                <p class="-space">Being situated in the mountainous inland areas, economic growth in Malekhu and Beni Ghat has been slow. Public facilities are inadequate and supplies are usually limited due to transportation constraints. Many living in both towns face difficulties in obtaining daily necessities.<br>Through OWNDAYS Eye Camp, we were able to provide support, albeit just a small amount, to the needy people.</p>
                                                <p class="-space">We have dedicated a portion of our global profits to help people who are less privileged. By doing so, we hope to enrich their lives. To us, each OWNDAYS Eye Camp is a reinforcement of the importance of our work.<br>By visiting local communities and giving out relief supplies to the needy people in person, we hope we would be able to enrich the lives of these people.</p>
                                                <p>To this end, we will continue to travel across borders to help people in need through the OWNDAYS Eye Camp.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/yZEgdk1N_vE?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201710">
                    <a href="#201710" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201710/thumb.jpg">
                            <div>
                                <span>NEPAL</span>2017.10
                                <p class="-city">Tandi, Chitwan</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>NEPAL</span>Tandi, Chitwan
                                                <span class="-date">2017.10.06</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201710/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 11th OWNDAYS Eye Camp was held in Tandi, a city in the Chitwan District of Nepal.<br>From the capital city, Kathmandu, we travelled across steep mountains with prescription glasses prepared for needy local people who could not afford glasses. We also brought along books and toys that would be given out in a local school.</p>
                                                <p class="-space">There are many ways to contribute to the society. For instance, one could simply send financial or material aids from home. However, in OWNDAYS, we choose to visit local communities in perso　n because we would like to provide the same level of service and quality to the recipients as we would to our customers back home. As we hand over our glasses, we are able to help the recipients adjust the glasses at the same time.</p>
                                                <p class="-space">This time, we also raised donations in the form of an in-house virtual currency called OWNDAYS Miles. The donations were exchanged for toys and given out to children from poor families.<br>The OWNDAYS Eye Camp allows us to meet the local people face to face and to pass them our glasses personally. It is a reminder to us on how we could do our part for people in the world as a company.</p>
                                                <p>In line with our corporate philosophy of enriching the lives of people related to OWNDAYS, we have pledged a portion of our profits to help needy people who are burdened by poor eyesight.<br>We will continue to provide prescription glasses to those in need through OWNDAYS Eye Camp.</p>>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/8vtbc7w9WnQ?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201706">
                    <a href="#201706" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201706/thumb.jpg">
                            <div>
                                <span>TAIWAN</span>2017.06
                                <p class="-city">Datong Dist., Taipei City</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>TAIWAN</span>Datong Dist., Taipei City
                                                <span class="-date">2017.06.10</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201706/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 10th OWNDAYS Eye Camp was held in Taiwan. This was the first time the project was conducted there. We collaborated with Breeze Charity Foundation and distributed prescription glasses to the children in need.</p>
                                                <p class="-space">We met many children from low income families who were unable to afford eyeglasses. This encounter has reminded us yet again that OWNDAYS is more than just an eyewear retailer. We are also helping to enrich the lives of others by bringing them a clearer, brighter future.</p>
                                                <p>This OWNDAYS Eye Camp in Taiwan has brought us new encounters and reminded us the importance of having good vision. Let’s continue making a difference by sharing the vision with the world.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/U-Uz9q0sFHY?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201705">
                    <a href="#201705" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201705/thumb.jpg">
                            <div>
                                <span>PHILIPPINES</span>2017.05
                                <p class="-city">Cabalawan, Tacloban City</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>PHILIPPINES</span>Cabalawan, Tacloban City
                                                <span class="-date">2017.03.27 & 05.23</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201705/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 9th OWNDAYS Eye Camp was held in Cabalawan, Tacloban City, the Philippines in collaboration with Lions Club International and The Gift of Sight by Robinsons Malls.</p>
                                                <p class="-space">In November 2013, the once beautiful city, formerly known as “The Beautiful City By The Bay”, was wrecked by the deadly Typhoon Haiyan. 4 years on, many people are still living in makeshift homes. The road to the city’s revival seems slow and difficult.</p>
                                                <p class="-space">This time, we gave out eyeglasses to the needy people living in Cabalawan district of Tacloban. We also brought pictures books and toys for the children. We hope our OWNDAYS glasses could bring a little sunshine and joy to their lives.</p>
                                                <p>In line with our philosophy of “enriching the lives of people associated with OWNDAYS”, we will continue to dedicate ourselves to helping the less fortunate and to sharing the vision with the world through OWNDAYS Eye Camp.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/Uyv2gmE1mIU?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201612/thumb.jpg">
                            <div>
                                <span>INDIA</span>2016.12
                                <p class="-city">Ayanar nagar village</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column" id="201611">
                    <a href="#201611" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201611/thumb.jpg">
                            <div>
                                <span>PHILIPPINES</span>2016.11
                                <p class="-city">Pandacan, Manila</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>PHILIPPINES</span>Pandacan, Manila
                                                <span class="-date">2016.11.08 & 23</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201611/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">The 7th OWNDAYS Eye Camp was held in the Philippines. In partnership with ABS-CBN Lingkod Kapamilya Foundation Inc., the Eye Camp Team identified towns with the greatest need for vision correction before embarking on the mission. This was the first Eye Camp in the country and would definitely not be the last.<br>We distributed prescription eyeglasses to 200 children and adults. The recipients shared stories about the difficulties they experienced every day and how OWNDAYS Eye Camp could change their lives. We were very excited that we could help give them a clearer and more beautiful vision.</p>
                                                <p>Through the OWNDAYS Eye Camp, we have met many people who require eyeglasses but are unable to obtain a pair due to financial difficulties. From children to adults, we hope to continue these efforts in enriching the lives of people through our eyeglasses. What we do day by day is more than merely selling eyeglasses, it is to give others the ability to see clearly. We are grateful to have another heart-warming experience in the OWNDAYS Eye Camp. In line with our mission of sharing vision with the world, we strive to continue making a difference to the world.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/eDG7RHFein0?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201608">
                    <a href="#201608" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201608/thumb.jpg">
                            <div>
                                <span>INDIA</span>2016.08
                                <p class="-city">Madurai, Tamil Nadu</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>INDIA</span>Madurai, Tamil Nadu
                                                <span class="-date">2016.08.24 - 25</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201608/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">One year since the first OWNDAYS Eye Camp. This was our 6th Eye Camp and we returned to Madurai, India, the place where it was first held.</p>
                                                <p class="-space">There were 4 distribution venues this time. We distributed glasses to the residents in Andar Kottaram Village, Ambethkar Nagar Village, Kathakinaru Village as well as students in a school in Kathakinaru Village.<br>At present, there are many people who require glasses but are unable to obtain a pair. We wish to do our part to help improve this situation no matter how small our effort may be. One person at a time, we hope from the bottom of our hearts that our glasses would put a smile on the Indian people.</p>
                                                <p>In line with our mission to enrich the lives of everyone related to OWNDAYS, we will continue to do our part in order to share the wonders and joy of “being able to see clearly” with the world.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/0baXlYCP_zc?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201604/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2016.04
                                <p class="-city">Kumamoto</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column" id="201603">
                    <a href="#201603" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201603/thumb.jpg">
                            <div>
                                <span>NEPAL</span>2016.03
                                <p class="-city">Tokha, Kathmandu</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>NEPAL</span>Tokha, Kathmandu
                                                <span class="-date">2016.03.11</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201603/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">In 2015, Nepal was hit by a deadly earthquake of an estimated 7.8 magnitude. It was a dreadful disaster that brought many buildings down in shatters, led to landslides and even triggered an avalanche.<br>With the thought of helping people in affected areas acquire good vision while they rebuild their homeland, we headed to Kathmandu, the capital city of Nepal for the 4th installment of the OWNDAYS Eye Camp.</p>
                                                <p class="-space">This time, we brought along approximately 150 pairs of glasses with customised prescriptions for the recipients and we called upon OWNDAYS staff from around the world to go on this expedition in Nepal.<br>As we handed out the glasses, the Nepali people also gave us their widest smiles in return. Some put their palms together in a prayer like gesture while others held and shook our hands. Despite differences in language, we could feel their immense happiness and gratitude upon receiving the glasses.</p>
                                                <p>We hope our efforts of giving out glasses to those who cannot afford it could contribute to the rebuilding of Nepal.<br>Everyone should have a chance to enjoy a clear vision and we aim to make this happen. We hope to enrich the lives of people around us through the gift of sight, step by step, one person after another.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/vBt08cpSXQE?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column" id="201509">
                    <a href="#201509" class="column-inner">
                        <div class="filter">
                            <img src="https://www.owndays.com/images/company/csr/201509/thumb.jpg">
                            <div>
                                <span>INDIA</span>2015.09
                                <p class="-city">Madurai, Tamil Nadu</p>
                            </div>
                            <span class="plus"></span>
                        </div>
                        <span class="icon-search"></span>
                    </a>
                    <div class="column-content">
                        <div class="column-detail">
                            <div class="archive-drawarea">
                                <div class="archive-body">
                                    <div class="column-description">
                                        <div class="l-csr__detail">
                                            <h3>
                                                <span>INDIA</span>Madurai, Tamil Nadu
                                                <span class="-date">2015.09.04</span>
                                            </h3>
                                            <div class="l-csr__slider">
                                                <div class="owl-carousel owl-theme">
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_01.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_02.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_03.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_04.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_05.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_06.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_07webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_08.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_09.webp" alt=""></div>
                                                    <div><img src="https://www.owndays.com/images/company/csr/201509/slide_10.webp" alt=""></div>
                                                </div>
                                            </div>
                                            <div class="l-csr__text">
                                                <p class="-space">6,800 km from Japan.<br>In the rural parts of India, facilities available for eye examinations are often inadequate. As a result, many people living in these areas are unable to correct their vision.<br>To help these people overcome the problems and inconveniences caused by poor vision, OWNDAYS organized a volunteer expedition to Sakkimangalam Village, located in Madurai, a major city in the state of Tamil Nadu in southern India, where staff from the different countries came together to deliver glasses to the local communities there. Approximately 100 pairs of prescription glasses were given out to people from 6 to 80 years old.</p>
                                                <p>We cannot help but to reminisce in the joy we saw on these people’s faces when they could finally see clearly with their new glasses. Indeed, the joy of having good vision transcends borders and cultural differences.<br>From children to adults, we hope to continue our efforts in enriching the lives of people around us through OWNDAYS glasses. Whether it is one person or one thousand people, we hope we can make a difference to their lives.</p>
                                            </div>
                                            <div class="l-csr__video">
                                                <iframe width="1120" height="630" src="https://www.youtube.com/embed/Sici0phrpjk?mute=1&loop=1" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="close-archive" href="#">
                                <span class="close-archive-inner">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                        <polygon points="16,15.3 10.7,10 16,4.7 15.3,4 10,9.3 4.7,4 4,4.7 9.3,10 4,15.3 4.7,16 10,10.7 15.3,16 "></polygon>
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201508/thumb.jpg">
                            <div>
                                <span>INDIA</span>2015.08
                                <p class="-city">India</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201507/thumb.jpg">
                            <div>
                                <span>INDIA</span>2015.07
                                <p class="-city">Town Panjayat</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201104/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.04
                                <p class="-city">Tsuyama General Gymnasium</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201104/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.04
                                <p class="-city">Ishinomaki Junior High School </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201104/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.04
                                <p class="-city">HigashiMatsushima Municipal Daiichi Junior High School</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201104/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.04
                                <p class="-city">Yamoto Daini Junior High School</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201104/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.04
                                <p class="-city">Omagari Elementary School</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201104/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.04
                                <p class="-city">Tagajo City Cultural Centre</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div class="column-inner">
                        <div class="filter -no-filter">
                            <img src="https://www.owndays.com/images/company/csr/201103/thumb.jpg">
                            <div>
                                <span>JAPAN</span>2011.03
                                <p class="-city">Tagajo City Cultural Centre</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
        </main>
            </div>

 
    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>



<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js" defer></script>
<script src="https://www.owndays.com/web/js/owl.carousel.min.js"></script>
<script src="https://www.owndays.com/web/js/view-box_csr.js"></script>
 
</body>

</html>`;

const EyeCampHTML = () => {
  return (
    <>
      <MetaTagsManager
        description="Join our Eye Camp at OWNDAYS Singapore Online Store. Get your eyes checked by our experts and find the perfect eyewear. Don't miss this opportunity for clearer vision!"
        title="Eye Camp｜OWNDAYS Singapore Online Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default EyeCampHTML;
