import React from 'react';

const Main = ({ baseImgUrl, isSP }) => {
  return (
    <section className="main">
      <div className="main__bg">
        <picture data-aos="main-bg" data-aos-duration="3000">
          {!isSP && (
            <source
              media="(min-width:768px)"
              srcSet={`${baseImgUrl}main-pc.webp`}
              type="image/webp"
            />
          )}
          <img
            alt="Harry Potter × OWNDAYS"
            className="main__img"
            src={`${baseImgUrl}main-sp.webp`}
          />
        </picture>
      </div>
      <div className="main__detail">
        <picture className="main__logo" data-aos="fade-up" data-aos-delay="1000">
          {!isSP && (
            <source
              media="(min-width:768px)"
              srcSet={`${baseImgUrl}logo-pc.webp`}
              type="image/webp"
            />
          )}
          <img alt="Harry Potter × OWNDAYS" src={`${baseImgUrl}logo-sp.webp`} />
        </picture>
      </div>
    </section>
  );
};

export default Main;
