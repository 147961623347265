import React, { useRef } from 'react';
import './SelectCardDesign.scss';
import Slider from 'react-slick';

const SLIDES_TO_SHOW = 4;

const config = {
  arrows: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: SLIDES_TO_SHOW,
  slidesToScroll: 1,
  swipeToSlide: true,
  centerPadding: '40px',
};

const SelectCardDesign = ({
  handleSelectCardType,
  giftCardImages,
  selectCardType,
  setPreviewType,
}) => {
  const sliderRef = useRef(null);

  const handleSelectCardDesign = (url, index) => {
    handleSelectCardType(url);
    setPreviewType(index);
  };

  return (
    <div className="select-card-design">
      <div className="slider-container">
        <Slider ref={sliderRef} {...config}>
          {giftCardImages.map((url, index) => (
            <div
              key={url}
              className={`${selectCardType === url ? 'select-card-design-bordered' : ''}`}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img alt="gift card" src={url} onClick={() => handleSelectCardDesign(url, index)} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SelectCardDesign;
