import React from 'react';
import Loadable from 'react-loadable';
import Loader from '../../../components/Common/BlankLoader.js';

const CartLoadable = Loadable({
  loader: () =>
    import('./OdCartWrapper' /* webpackChunkName: 'cart' */).then(module => module.default),
  loading: () => <Loader />,
});

export default CartLoadable;
