import React from 'react';
import { setDataAttr } from './../../../../../utils/helper';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';

function SubMenuList({ data, updateNavValueDtm, section, subSection, categoryMenu, parentKey }) {
  const submenu = data.submenu;

  const isKidsGlassesSection = section === 'Kids Glasses';
  const isImageType = data.type === 'image';

  let layoutClass = '';
  if (isImageType) {
    layoutClass = isKidsGlassesSection
      ? 'justify-space-evenly justify-content-end align-items-center'
      : 'flex-column align-items-start';
  }

  const handleLinkClick = (e, key) => {
    e.preventDefault();
    updateNavValueDtm(
      section,
      subSection,
      categoryMenu,
      parentKey,
      e,
      `${section} ${subSection} ${categoryMenu} ${key}`
    );
  };

  return (
    <div className={data.type === 'image' ? `layout ${layoutClass}` : ''}>
      {submenu &&
        submenu.map(dataItem =>
          Object.keys(dataItem).map(key => {
            const dataAttr =
              dataItem[key].gaObj && Object.keys(dataItem[key].gaObj).length > 0
                ? setDataAttr(dataItem[key].gaObj)
                : null;

            const image = dataItem[key].image;
            const isExternalLink =
              dataItem[key].url && dataItem[key].url.indexOf('lenskart.com') === -1;

            return (
              <div
                key={v4()}
                className={`nav-level-4 ${dataItem[key].image ? 'image' : ''} ${
                  isKidsGlassesSection ? 'level-5' : ''
                }`}
              >
                {/* <a
                    {...dataAttr}
                    className="nav-level-4-item od-font-reg"
                    href={dataItem[key].url}
                    onClick={e => handleLinkClick(e, key)}
                  >
                    {isKidsGlassesSection && (
                      <img
                        alt={image.imgAlt}
                        className="img lazy-load-image"
                        data-src={image.src}
                        src="https://static1.lenskart.com/media/desktop/img/GreyBackground.png"
                      />
                    )}
                    {isKidsGlassesSection ? (
                      <div className="margin-t10">{key}</div>
                    ) : (
                      <span>{key}</span>
                    )}
                  </a> */}
                {/* <Link
                  {...dataAttr}
                  className="nav-level-4-item od-font-reg"
                  to={{
                    pathname: dataItem[key].url,
                    state: { categoryId: dataItem[key].categoryId, section: 'cat' },
                  }}
                  onClick={e => handleLinkClick(e, key)}
                >
                  {isKidsGlassesSection && (
                      <img
                        alt={image.imgAlt}
                        className="img lazy-load-image"
                        data-src={image.src}
                        src="https://static1.lenskart.com/media/desktop/img/GreyBackground.png"
                      />
                    )}
                  <span>{key}</span>
                  <i className="fa fa-angle-right hide"></i>
                </Link> */}
                <Link
                  {...dataAttr}
                  className="nav-level-4-item od-font-reg"
                  to={{
                    pathname: dataItem[key].url,
                    state: { categoryId: dataItem[key].categoryId, section: 'cat' },
                  }}
                  // onClick={e => handleLinkClick(e, key)}
                >
                  {/* {isKidsGlassesSection && (
                      <img
                        alt={image.imgAlt}
                        className="img lazy-load-image"
                        data-src={image.src}
                        src="https://static1.lenskart.com/media/desktop/img/GreyBackground.png"
                      />
                    )} */}
                  <span>{key}</span>
                  <i className="fa fa-angle-right hide"></i>
                </Link>
              </div>
            );
          })
        )}
    </div>
  );
}

export default SubMenuList;
