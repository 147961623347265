import React from 'react';
import './ContactLensCard.scss';

const ContactLensCard = ({ cardData, triggerDataLayerOnCtaClick }) => {
  const handleClickCard = link => {
    window.location.href = link;
    const data = {
      event: 'cta_click',
      cta_name: cardData?.title?.trim().toLowerCase().replace(/[ ]+/gi, '-') || '',
      cta_flow_and_page: 'navigation-bar',
    };
    triggerDataLayerOnCtaClick(data);
  };

  return (
    <div className="contact-lens-card" onClick={() => handleClickCard(cardData.link)}>
      <div className="contact-lens-card__img">
        <img alt={cardData.img.src} src={cardData.img.src} />
      </div>
      <div className="contact-lens-card__title od-font-reg">{cardData.title}</div>
    </div>
  );
};

export default ContactLensCard;
