const { langCodeAllowed } = require('./locales');

const isDevelopment = process.env.BUILD_ENV === 'development';
const isPreProd = process.env.ENVIRONMENT === 'preprod';
const COUNTRY_CODE = process.env.COUNTRY?.toLowerCase();
const LANGUAGE_DIR = langCodeAllowed[COUNTRY_CODE]?.defaultLocale;
const __CLIENT_TYPE__ = process.env.CLIENT_TYPE;
const __API_JUNO__ = process.env.API_JUNO;
const __API_ALB_V2__ = process.env.API_ALB_V2;
const __API_LK__ = process.env.API_LK;
const __IS_LOCAL__ = process.env.IS_LOCAL_DEV;
const __YOTPO_API_KEY__ = isDevelopment
  ? 'H8YJ4tBeZCQjT0UJVvyfiCm7Dyl6v0UNCKwZXywm'
  : 'yRLOxOqElIsw88TIlZ1GZE9GxjFBP5oNzH49HEnC';
const __API_STORE__ = isDevelopment
  ? 'https://webapi-beta-preprod.lenskart.com'
  : 'https://webapi-beta.lenskart.com'; // process.env.API_STORE;
const __API_POS__ = isDevelopment
  ? 'https://apipos-preprod.lenskart.com'
  : 'https://apipos.lenskart.com';
const __API_IFRAME__ = isDevelopment
  ? 'https://preprodbridge.lenskart.com'
  : 'https://bridge.lenskart.com';
const __AUTO_SUGGEST__ = isDevelopment
  ? 'https://api-gateway.juno.preprod.lenskart.com'
  : 'https://api.lenskart.com';
const __API_JUNO_IN__ = isDevelopment
  ? 'https://api-gateway.juno.preprod.lenskart.com'
  : 'https://api-gateway-in.juno.lenskart.com';

const jsonPath =
  __IS_LOCAL__ === 'ON'
    ? `/locale/${__CLIENT_TYPE__}/:locale`
    : `https://static.lenskart.com/media/owndays/locale/${
        isDevelopment ? 'stage' : 'prod'
      }/${__CLIENT_TYPE__}/en_sg`;
const jsonFireBasePath = `https://${isDevelopment ? 'area51' : 'area51'}.lenskart.io/api/v1/static`;
console.log('@config.js JSONPath ===>', jsonFireBasePath);
const environment = {
  development: {
    isProduction: false,
    assetsPath: '/',
  },
  production: {
    isProduction: true,
    assetsPath: `${
      __CLIENT_TYPE__ === 'mobile'
        ? 'https://static.lenskart.com/media/mobile/react/'
        : 'https://static.lenskart.com/media/desktop/js/dist-prod/'
    }`,
  },
}[process.env.BUILD_ENV || 'development'];

const frameSizeId = {
  medium: isDevelopment ? '11341' : '11341',
  extraNarrow: isDevelopment ? '23559' : '24072',
  narrow: isDevelopment ? '23557' : '24070',
  wide: isDevelopment ? '23555' : '24069',
  extraWide: isDevelopment ? '23553' : '24071',
};
// added comment
const storeUrl =
  process.env.ENVIRONMENT === 'preprod'
    ? 'https://preproduction.owndays.com/stores/sg'
    : 'https://www.owndays.com/stores/sg';

const algoliaIndex =
  process.env.ENVIRONMENT === 'preprod'
    ? 'owndays_preprod_product_index_SG_query_suggestions_external'
    : 'owndays_prod_product_index_SG_query_suggestions_external';

const htoLink = isDevelopment ? 'https://hto-preprod.lenskart.com' : 'https://hto-new.lenskart.com';
// Msite specific globals will go here.
const msiteGlobals = {
  maxShortList: { count: 50 },
  appXApiClients: ['android', 'ios'],
  recViewList: { noOfDisplayProducts: 11 },
  viewImagesList: ['frame_shape_id', 'frametype_id', 'frame_size_id'],
  filterCdnPath: 'https://static.lenskart.com/images/cust_mailer/',
  recCatList: { noOfCatIds: 5, noOfDisplayProducts: 10 },
  cmsCategoryArray: [
    'eyeglasses',
    'sunglasses',
    'power-sunglasses',
    'premium-eyeglasses',
    'contact-lenses',
  ],
};
//  ditto auth api
// const dittoSecretKey = '985b799a13ad811468ede9f20fc8bb2e802ce2a48712639ecccb7f2a39fb23f1abb027db2ac171823c77b0843877b2acad4c2e16ae0f3cfaf6b8a68881bcbeed';
// const dittoAccessKey = '864c62d7894d1f12';
// site key for captcha
const siteKey = '6LcssXobAAAAABuHKTm_Fk6nevRwZUTGtHij1wS2';
// Msite specific api path will goes here.
const msiteApiPath = {
  homePageJson: `${jsonPath}/home.json`,
  // homePageJson: 'http://lenskart.com:8081/locale/mobile/en_IN/home.json',
  verifyOtp: `${__API_JUNO__}/v2/customers/verifyOtp`,
  subCatApiUrl: `${__API_JUNO__}/v2/products/subcategory`,
  validateServiceableAddress: `${__API_JUNO__}/v2/orders/return/serviceability`,
  returnLabelStatus: `${__API_JUNO__}/v2/orders/return/return-label/`,
  paymentMethod: `${__API_JUNO__}/v3/payment-methods`,
  paymentOffers: `${__API_JUNO__}/v2/payments/offers/enabled`,
  posSeamlessLink: `${__API_POS__}/v1/order/seamless/sendLink`,
  getSalesmanDetails: `${__API_JUNO__}/v2/utility/salesman`,
  validateVpa: `${__API_JUNO__}/v2/payments/validateVpa`,
  upiTransactionStatus: `${__API_JUNO__}/v2/payments/`,
  cameraPdUpload: `${__API_JUNO__}/v2/utility/customer/prescriptions/upload`,
  pfuStoreSlots: `${__API_JUNO__}/v2/orders/store/appointment/slots`,
  pfuStoreAppointment: `${__API_JUNO__}/v2/orders/store/appointment/book`,
  getPfuAppointmentDetail: `${__API_JUNO__}/v2/orders/store/appointment`,
  cancelPfuAppointment: `${__API_JUNO__}/v2/orders/store/appointment/cancel`,
  getHecDetail: `${__API_JUNO__}/hto/api/HTOService/v2/order`,
  cancelHecAppointmnet: `${__API_JUNO__}/v2/orders/hec/appointment/cancel`,
  hecDetailJunoUpdate: `${__API_JUNO__}/v2/orders/hec/appointment`,
  updateShippingAddress: `${__API_JUNO__}/v2/orders`,
  pd: `${__API_JUNO_IN__}/v1/orion/customers/pd`,
};
// Common api paths will goes here for msite/desktop
let apiPath = {
  userSession: `${__API_JUNO__}/v2/sessions`,
  logOut: `${__API_JUNO__}/v2/sessions/logout`,
  returnConfig: `${jsonPath}/return-config.json`,
  pfuPdRules: `${jsonPath}/pfu-config.json`,
  returnReasons: `${__API_JUNO__}/v2/orders/return/reasons`,
  cancelReasons: `${__API_JUNO__}/v2/orders/`,
  getOrders: `${__API_JUNO__}/v2/orders`,
  getOfflineOrders: `${__API_JUNO__}/v2/orders/offline`,
  membershipPointsHistory: `${__API_JUNO__}/v2/money/points/history`,
  returnModes: `${__API_JUNO__}/v2/orders/return/methods`,
  nearbyStore: `${__API_JUNO__}/v2/utility/admin/store/nearbyAll`,
  albUserSession: `${__API_ALB_V2__}/v2/sessions`,
  redisConsole: `${jsonFireBasePath}/od-web-redis-config`,
  sharktankConsole: `${jsonFireBasePath}/sharktank-html`,
  redis: `${__API_JUNO__}/v2/utility/redis?keys=`,
  albRedis: `${__API_JUNO__}/v2/utility/redis?keys=`,
  redisConfig: `${jsonPath}/redis.json`,
  dataProduct: `${__API_JUNO__}/v3/products/product/`,
  checkStoreAvailability: `${__API_JUNO__}/v2/products/product/check-store-availability`,
  albDataProduct: `${__API_ALB_V2__}/v3/products/product/`,
  dataProductUnSecure: `${__API_JUNO__}/v2/products/product/`,
  lensPackages: `${__API_JUNO__}/v2/products/product/`,
  lensPackageAndCoating: `${__API_JUNO__}/v2/products/product/buyoptions/group`,
  subscriptionDiscounts: `${__API_JUNO__}/v2/products/subscriptions/discount`,
  estimateDelivery: `${__API_JUNO__}/v2/products/product/`,
  categorySearch: `${__API_JUNO__}/v2/products/category/search/query/`,
  categorySearchFilter: `${__API_JUNO__}/v2/products/filters/query/`, // ?query=vincent
  // categorySearchFilter: `${__API_LK__}/v2/products/category/search/query/`, // ?query=vincent
  dataCategory: `${__API_JUNO__}/v2/products/category/`,
  albDataCategory: `${__API_JUNO__}/v2/products/category/`,
  categoryCarouselUrl: `${__API_JUNO__}/v2/products/carouselbanner?category=`,
  filterDataApi: `${__API_JUNO__}/v2/products/filters/`,
  membershipPoints: `${__API_JUNO__}/v2/carts/points/membership`,
  homePageJson: `${jsonPath}/revamp_home.json`,
  // TODO: need fix dynamic url
  // plpBanner: `${jsonPath}/plp-banner.json`,
  plpBanner: `https://static.lenskart.com/media/owndays/locale/stage/${__CLIENT_TYPE__}/en_sg/plp-banner.json`,
  mainNavigation: `${jsonPath}/navigation.json`,
  mobileNavigationJsonUrl: `${jsonPath}/navigation_menu.json`,
  userAuthenticate: `${__API_JUNO__}/v2/customers/authenticate`,
  userAddress: `${__API_JUNO__}/v2/customers/me/addresses/`,
  saveUpdateDitto: `${__API_JUNO__}/v2/utility/ditto/customer/`,
  updateCustomer: `${__API_JUNO__}/v2/customers/owndays/update`,
  updateCustomerCygnus: `${__API_JUNO__}/v2/customers/me/cygnus`,
  uploadImageToCygnus: `${__API_JUNO_IN__}/v1/cygnus/customers/images`,
  getOverlayedImageFromCygnus: `${__API_JUNO_IN__}/v1/cygnus/customers/images/products/images/overlays`,
  lensOnlyShippinglocation: `${__API_LK__}/api/v1/shipping/location/`,
  deleteUserAddress: `${__API_JUNO__}/v2/customers/me/`,
  registerCustomer: `${__API_JUNO__}/v2/customers/register`,
  forgotPasswordToken: `${__API_JUNO__}/v2/customers/account/tokens`,
  campaignData: `${__API_JUNO__}/v2/utility/campaign`,
  socialAuth: `${__API_JUNO__}/v2/customers/socialauthenticate/`,
  updateCartItem: `${__API_JUNO__}/v2/carts/items/`,
  locationData: `${__API_JUNO__}/v2/utility/countrystate`,
  pincodeCheck: `${__API_JUNO__}/v2/utility/checkpincode/`,
  orderPayment: `${__API_JUNO__}/v2/orderpayment/`,
  getOfflineOrders: `${__API_JUNO__}/v2/orders/offline`,
  // requestPaymentUrl: __API_LK___SECURE + '/magento/me/index/requestPayment',
  applyGV: `${__API_JUNO__}/v2/carts/giftVoucher/`,
  giftCards: `${__API_JUNO__}/v2/carts/gift-cards`,
  storeCredit: `${__API_JUNO__}/v2/money/store-credits/`,
  applySC: `${__API_JUNO__}/v2/carts/`,
  cartApi: `${__API_JUNO__}/v2/carts`,
  albCartApi: `${__API_ALB_V2__}/v2/carts`,
  reOrderCartApi: `${__API_JUNO__}/v2/carts/recart/`,
  breadcrumb: `${__API_LK__}/magento/me/index/breadcrumb?id=`,
  getUserInfo: `${__API_JUNO__}/v2/customers/me`,
  albGetUserInfo: `${__API_ALB_V2__}/v2/customers/me`,
  deleteCustomer: `${__API_JUNO__}/v2/customers/deleteCustomer`,
  wishlist: `${__API_JUNO__}/v2/products/wishlist`,
  // usersSavedCards: `${__API_JUNO__}/v2/saved-cards`,
  checkDeliveryOption: `${__API_JUNO__}/v2/carts/expressdelivery/deliveryOptions?pincode=`,
  updateDeliveryOption: `${__API_JUNO__}/v2/carts/expressdelivery/updateDeliveryOption/`,
  oosSubscription: `${__API_JUNO__}/v2/products/product/oos-subscription`,
  saveShippingAddress: `${__API_JUNO__}/v2/carts/shippingAddress`,
  homeEyeCheckup: 'https://hto.lenskart.com/HTO/api/HTOService/slot',
  emailDitto: `${__API_JUNO__}/v2/utility/ditto/customer/mail`,
  enterMyPower: 'https://www.lenskart.com/instantpfulink.php',
  v2Orders: `${__API_JUNO__}/v2/orders`,
  getOrdersListInv: `${__API_JUNO__}/v3/orders/aggregator/inventory`,
  getOrderDetailsInv: `${__API_JUNO__}/v3/orders/aggregator/inventory?orderId=`,
  getSavedPrescription: `${__API_JUNO__}/v2/prescriptions`,
  recentOrders: `${__API_JUNO__}/v2/orders/recentOrders`,
  latestOrder: `${__API_JUNO__}/v2/orders/latest/order`,
  uploadprescfile: `${__API_JUNO__}/magento/me/index/uploadprescfile`,
  validateSession: `${__API_JUNO__}/v2/sessions/validate`,
  albValidateSession: `${__API_ALB_V2__}/v2/sessions/validate`,
  userLocation: `${__API_JUNO__}/v2/utility/configurations/location?ip=`,
  albUserLocation: `${__API_JUNO__}/v2/utility/configurations/location?ip=`,
  varnishApi: `${__API_LK__}/ajax/index/dummyvar`,
  dittoProfile: `${__API_JUNO__}/v2/utility/ditto/customer/`,
  changePassword: `${__API_JUNO__}/v2/customers/account/resetpassword`,
  giftVoucherBalance: `${__API_JUNO__}/v2/money/gift-vouchers/code?giftVoucherCode=`,
  mobileLoginAccountInfo: `${__API_JUNO__}/v2/customers/accountsInfo`,
  editNeft: `${__API_JUNO__}/v2/orders/neft/savedetails`,
  resetPassword: `${__API_JUNO__}/v2/customers/account/forgotpassword`,
  mobileLoginSendOTP: `${__API_JUNO__}/v3/customers/sendOtp`,
  mobileLoginAuthenticateMobileUser: `${__API_JUNO__}/v2/customers/authenticate/mobile`,
  magentoCategory: `${__API_JUNO__}/magento/me/index/carouselbanner?category=`,
  mobileEmailFetch: `${__API_JUNO__}/v2/customers/accounts?telephone=`,
  mobileEmailLink: `${__API_JUNO__}/v2/customers/accountVerification/sendEmail`,
  cmsPages: '/me/catalog/cmsPage/id/',
  captchaUrl: `${__API_LK__}/magento/me/index/getcaptcha/`,
  countryCode: `${__API_STORE__}/country/`,
  dittoAuth: `${__API_JUNO__}/v2/utility/ditto/customer/authentication/token/`,
  reviewProduct: `${__API_JUNO__}/v2/products/product/`,
  generateOtp: `${__API_JUNO__}/v2/utility/otp`,
  yotpoSiteReview: `https://api.yotpo.com/products/${__YOTPO_API_KEY__}/yotpo_site_reviews/bottomline`,
  checkWhatsappConsent: `${__API_JUNO__}/v2/customers/checkWhatsAppOpingStatus`,
  setWhatsappConsent: `${__API_JUNO__}/v2/customers/whatsAppOpting`,
  // frameSize: `https://lenskart-${isDevelopment ? 'prod' : 'prod'}.firebaseapp.com/api/v1/frame-size/calculate`,
  frameSize: `https://lenskart-${
    isDevelopment ? 'dev-b9d14' : 'prod'
  }.firebaseapp.com/api/v1/frame-size/calculate`,
  storeFrameSize: `https://lenskart-${
    isDevelopment ? 'stage-e9bdd' : 'prod'
  }.firebaseapp.com/api/v1/frame-size/upload_image`,
  categoryConfigJson: `https://lenskart-${
    isDevelopment ? 'stage-e9bdd' : 'prod'
  }.firebaseapp.com/api/v1/static/category-config`,
  getCustomerInfo: `${__API_JUNO__}/v2/customers/info/`,
  storeApi: `${__API_STORE__}`,
  getCardOffers: `${__API_JUNO__}/v2/payments/offers/enabled`, // use it for getCardOffers local env
  applyOfferMock: '/mock-api/applyOffer', // for mock
  applyOffer: `${__API_JUNO__}/v2/carts/paymentOffer`, // use it for getCardOffers local env
  // usersSavedCards: '/mock-api/savedCards',
  usersSavedCards: `${__API_JUNO__}/v2/saved-cards/`,
  removeOfferMock: '/mock-api/removeOffer', // for mock
  removeOffer: `${__API_JUNO__}/v2/carts/paymentOffer/`,
  // paymentMethod: '/mock-api/paymentMethods',
  paymentMethod: `${__API_JUNO__}/v4/payment-methods`,
  htoPageJson: `${jsonPath}/hto.json`,
  validateUserLocation: `${__API_JUNO__}/hto/api/HTOService`,
  htoBookNowOrder: `${__API_JUNO__}/hto/api/HTOService`,
  qrPaymentsApi: `${__API_JUNO__}/v2/payments/`,
  preSalesDualLogin: `${__API_JUNO__}/v2/customers/dualLogin`,
  // localeConfigPath: `${jsonFireBasePath}/od-web-locale-config`,
  localeConfigPath: `${
    __IS_LOCAL__ === 'ON'
      ? '/locale/localeConfig.:locale.json'
      : `https://static.lenskart.com/media/owndays/locale/${
          isDevelopment ? 'stage' : 'prod'
        }/${__CLIENT_TYPE__}/${LANGUAGE_DIR}/localeConfig.json`
  }`,
  // localeConfigPath: `${__IS_LOCAL__ === 'ON' ? `/locale/${__CLIENT_TYPE__}/:locale/localeConfig.json` : `https://static.lenskart.com/media/locale/${isDevelopment ? 'stage' : 'prod'}/${__CLIENT_TYPE__}/:locale/localeConfig.json`}`,
  downloadUploadedPrescription: `${__API_JUNO__}/v2/utility/customer/prescriptions/download/`,
  savePrescription: `${__API_JUNO__}/v2/utility/customer/prescriptions`,
  productReviewList: `${__API_JUNO__}/v2/products/product/`,
  passUtmData: `${__API_JUNO__}/v2/utility/user/passutmdata`,
  downloadPrescription: `${__API_JUNO__}/customers/prescriptions/download`,
  juspaySdkInitiate: `${__API_JUNO__}/v2/payments/sdk/initiate`,
  checkUserExists: `${__API_JUNO__}/v2/orders/exists`,
  campaignFirstDeal: `${__API_JUNO__}/v2/utility/survey/first-deal`,
  checkFirstDealUserExists: `${__API_JUNO__}/v2/utility/survey/first-deal/exists`,
  threeSixtyViewStatus: 'https://arsdk.lookr.in/api/v4/get-assets-details',
  leadGen: `${__API_JUNO__}/v2/utility/lead-capture`,
  marketingSubscription: `${__API_JUNO__}/v2/customers/me/marketing-subscription`,
  lensMappingConfigPath: `${jsonFireBasePath}/lens-mapping`,
  bookTestYourVisionAppointment: `${__API_JUNO__}/v2/orders/store/appointment/book`,
  pidFromBarcode: `${__API_JUNO__}/v2/products/product/barcode`,
  getClientTokenPrimer: `${__API_JUNO__}/v2/payments/primer/sdk/initiate`,
  primerPayment: `${__API_JUNO__}/v2/payments/primer`,
  primerSavedCards: `${__API_JUNO__}/v2/payments/primer/saved-cards`,
  pd: `${__API_JUNO_IN__}/v1/orion/customers/pd`,
  storeUrl,
  algoliaIndex,
  giftCardCatId: process.env.ENVIRONMENT === 'preprod' ? 91351 : 17131,
  getCustomerMembership: `${__API_JUNO__}/v2/money/points/membership/me`,
  getStoreAppointments: `${__API_JUNO__}/v2/orders/store/owndays/appointments`,
};

apiPath = __CLIENT_TYPE__ === 'mobile' ? Object.assign(apiPath, msiteApiPath) : apiPath;

let config = Object.assign(
  {
    host: process.env.HOST || 'localhost',
    port: process.env.PORT || 3000,
    apiHost: process.env.APIHOST || 'localhost',
    apiPort: process.env.APIPORT,
    app: {
      title: 'Owndays.com',
      description:
        'Shop online for Sunglasses, Eyeglasses and Contact Lenses for men &amp; women and Get upto 50% Flat Discount at lenskart.com.  ✓FREE shipping ✓Cash on Delivery ✓14 Days Return.​',
      head: {
        titleTemplate: '%s',
      },
    },
    categoryPageSize: 15,
    apiPath,
    siteKey,
    rbiMarketPlaceConfig: {
      type: __CLIENT_TYPE__,
      defaultLogo:
        'https://static1.lenskart.com/media/owndays/desktop/img/od-logos-new/logo-type-black.png',
    },
    ditto: {
      serverUrl: 'https://vto.lenskart.api.ditto.com',
      accessKeyId: '',
      signature: '',
      modelDittoId: 'ditto_model_female_04',
      modelDittoSignature: '',
      sku: '122548',
      disableScale: true,
      vtoUrl: 'https://vto.lenskart.api.ditto.com/api/1.3/dittos/',
      faServerUrl: 'https://face.lenskart.api.ditto.com',
      replaceShareDittoUrlPrefix: !isDevelopment,
    },
    cygnus: {
      // getOverlayedImageFromCygnus: `https://api-gateway-in.juno.lenskart.com/v1/cygnus/customers/images/products/images/overlays`,
      getOverlayedImageFromCygnus: `${__API_JUNO_IN__}/v1/cygnus/customers/images/products/images/overlays`,
    },
    themeColor: '#fff',
    sixOversix: {
      options: {
        fullPage: true,
        // width: '100vw',
        themeColor: '#fff',
        profileName: 'web',
        flow: 'pd',
      },
      clientId: '6b8c7fe4-dc57-4160-8c31-326041a50b59',
    },
    defaultFrameCategory: {
      eyeglasses: '/eyeglasses/brands/vincent-chase-eyeglasses.html#filter_frame_size_id%3D',
      sunglasses: '/sunglasses/promotions/vc-new-arrivals.html#filter_frame_size_id%3D',
    },
    searchBarConfig: {
      url: `${__API_JUNO__}/v2/products/search/autosuggest/v2/query/`,
    },
    searchBar: {
      url: `${__API_JUNO__}/v2/products/search`,
    },
    iframe: {
      iframeUrl: __API_IFRAME__,
    },
    yotpoConfig: {
      apiKey: __YOTPO_API_KEY__,
      instaGalleries: {
        pdp: isDevelopment ? '5cd2e4b093bf900cc16e06c3' : '5cd56a15a230aa3b9199f193',
        home: isDevelopment ? '5cc82cae6830580008ec0594' : '5cc6ae7593bf9010d734bc9d',
        shop: isDevelopment ? '5cd3edb858bdd30009dca8dc' : '5cd56a30f1b75c389da04c81',
      },
    },
    jsonPath,
    jsonFireBasePath,
    frameSizeId,
    htoLink,
    directoryPath: '/sg/en/',
  },
  environment
);
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
config = Object.assign(config, { msiteGlobals });
('#endif'); // eslint-disable-line

module.exports = config;
