import React from 'react';
import './CorporateInformationPage.scss';

import CorporateInformationTable from './CorporateInformationTable/CorporateInformationTable';

import { connect } from 'react-redux';

const CorporateInformationPage = props => {
  const { dataLocale } = props;

  const {
    CORPORATE_INFORMATION = 'Corporate Information',
    CORPORATE_INFORMATION_EXECUTIVES_LIST = [
      {
        executive: 'Shuji Tanaka - President & CEO',
        twitter: 'https://twitter.com/shuji7771/',
        facebook: 'https://www.facebook.com/shuji.tanaka.3',
        blog: 'https://ameblo.jp/shuji7777/',
      },
      {
        executive: 'Takeshi Umiyama - Director',
      },
      {
        executive:
          'Norbert Leuret - Non-Executive Director (LVMH Moët Hennessy Louis Vuitton Japan)',
      },
      {
        executive:
          'Peyush Bansal - Non-Executive Director (Co-Founder, CEO of Lenskart Solution Private Limited)',
      },
      {
        executive: 'Amit Chaudhary - Non-Executive Director',
      },
      {
        executive: 'Neha Bansal - Non-Executive Director',
      },
      {
        executive: 'Anant Gupta - Non-Executive Director',
      },
      {
        executive: 'Ashwani Agarwal - Non-Executive Director',
      },
      {
        executive: 'Aya Haruyama - Non-Executive Director',
      },
      {
        executive: 'Sumeet Kumar - Auditor',
      },
    ],
    SINGAPORE = 'Singapore',
    CORPORATE_INFORMATION_SINGAPORE_EXECUTIVES_LIST = [
      {
        executive: 'Shuji Tanaka - President & CEO',
        twitter: 'https://twitter.com/shuji7771/',
        facebook: 'https://www.facebook.com/shuji.tanaka.3',
        blog: 'https://ameblo.jp/shuji7777/',
      },
      {
        executive: 'Takeshi Umiyama - Director',
      },
      {
        executive:
          'Norbert Leuret - Non-Executive Director (LVMH Moët Hennessy Louis Vuitton Japan)',
      },
      {
        executive:
          'Peyush Bansal - Non-Executive Director (Co-Founder, CEO of Lenskart Solution Private Limited)',
      },
      {
        executive: 'Amit Chaudhary - Non-Executive Director',
      },
      {
        executive: 'Neha Bansal - Non-Executive Director',
      },
      {
        executive: 'Anant Gupta - Non-Executive Director',
      },
      {
        executive: 'Ashwani Agarwal - Non-Executive Director',
      },
      {
        executive: 'Aya Haruyama - Non-Executive Director',
      },
      {
        executive: 'Sumeet Kumar - Auditor',
      },
    ],
  } = dataLocale;

  return (
    <main className="corporate-information">
      <div className="corporate-information__layout">
        <h1 className="corporate-information__title od-font-bold">{CORPORATE_INFORMATION}</h1>
        <CorporateInformationTable executivesList={CORPORATE_INFORMATION_EXECUTIVES_LIST} />
        <hr className="corporate-information__divider" />
        <h3 className="corporate-information__subtitle od-font-bold">{SINGAPORE}</h3>
        <CorporateInformationTable
          executivesList={CORPORATE_INFORMATION_SINGAPORE_EXECUTIVES_LIST}
        />
      </div>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateInformationPage);
