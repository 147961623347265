import * as actionTypes from '../actionTypes/membershipPoints';

export function getMembershipPoints() {
  return { type: actionTypes.GET_MEMBERSHIP_POINTS };
}

export function applyMembershipPoints() {
  return { type: actionTypes.APPLY_MEMBERSHIP_POINTS };
}

export function removeMembershipPoints() {
  return { type: actionTypes.REMOVE_MEMBERSHIP_POINTS };
}

export function getMembershipPointsHistory(customerId) {
  return { type: actionTypes.GET_MEMBERSHIP_POINTS_HISTORY_LOAD, customerId };
}
