export const GET_CART_DATA_LOAD = 'cart/GET_CART_DATA_LOAD';
export const GET_CART_DATA_SUCCESS = 'cart/GET_CART_DATA_SUCCESS';
export const GET_CART_DATA_FAIL = 'cart/GET_CART_DATA_FAIL';

export const UPDATE_CART_LOAD = 'cart/UPDATE_CART_LOAD';
export const UPDATE_CART = 'cart/UPDATE_CART';

export const ADD_TO_CART_LOAD = 'cart/ADD_TO_CART_LOAD';

export const DISCOUNT_CODE_LOAD = 'cart/DISCOUNT_CODE_LOAD';

export const GET_CART_NET_AMOUNT = 'cart/GET_CART_NET_AMOUNT';

export const SHIPPING_CHECK_LOAD = 'cart/SHIPPING_CHECK_LOAD';
export const SHIPPING_CHECK_SUCCESS = 'cart/SHIPPING_CHECK_SUCCESS';
export const SHIPPING_CHECK_FAIL = 'cart/SHIPPING_CHECK_FAIL';

export const FRAME_ONLY_PRODUCT = 'cart/FRAME_ONLY_PRODUCT';
export const LOGOUT_USER = 'cart/LOGOUT_USER';
export const RESET_CART = 'cart/RESET_CART';

/* Bin Series Card */
export const APPLY_OFFER = 'checkout/APPLY_OFFER';
export const APPLY_OFFER_SUCCESS = 'checkout/APPLY_OFFER_SUCCESS';
export const APPLY_OFFER_FAIL = 'checkout/APPLY_OFFER_FAIL';

export const REMOVE_OFFER = 'checkout/REMOVE_OFFER';
export const REMOVE_OFFER_SUCCESS = 'checkout/REMOVE_OFFER_SUCCESS';
export const REMOVE_OFFER_FAIL = 'checkout/REMOVE_OFFER_FAIL';
export const DELETE_CART_LOAD = 'cart/DELETE_CART';

export const UPDATE_POWER_LOAD = 'cart/UPDATE_POWER_LOAD';
export const UPDATE_POWER_SUCCESS = 'cart/UPDATE_POWER_SUCCESS';
export const UPDATE_POWER_FAIL = 'cart/UPDATE_POWER_FAIL';

export const APPLY_COUPON_CLICKED = 'cart/APPLY_COUPON_CLICKED';
export const APPLY_COUPON_ERROR = 'cart/APPLY_COUPON_ERROR';
export const APPLY_COUPON_SUCCESS = 'cart/APPLY_COUPON_SUCCESS';
export const RESET_APPLY_COUPON = 'cart/RESET_APPLY_COUPON';
export const RESET_CART_ERROR = 'cart/RESET_CART_ERROR';

export const APPLY_GIFT_LOAD = 'cart/APPLY_GIFT_LOAD';
export const APPLY_GIFT_SUCCESS = 'cart/APPLY_GIFT_SUCCESS';
export const APPLY_GIFT_FAIL = 'cart/APPLY_GIFT_FAIL';

export const REMOVE_GIFT_LOAD = 'cart/REMOVE_GIFT_LOAD';
export const REMOVE_GIFT_SUCCESS = 'cart/REMOVE_GIFT_SUCCESS';
export const REMOVE_GIFT_FAIL = 'cart/REMOVE_GIFT_FAIL';
