const langCodeAllowed = {
  in: {
    defaultLocale: 'en_in',
    defaultLanguage: 'en',
    countryCode: 'in',
    maxMobileNumberLength: 10,
    minMobileNumberLength: 10,
    fbVerificationTag: { enabled: false, key: '' },
    regex: /[6-9][0-9]{9}/,
    isPinCodeHide: false,
    countryName: 'India',
    storeHomeTrial: true,
    cookieDomain: 'www.lenskart.com',
    domain:
      process.env.BUILD_ENV === 'development'
        ? 'https://preprod-owndays.lenskart.com'
        : 'https://www.lenskart.com',
    domainUrl:
      process.env.BUILD_ENV === 'development' ? 'preprod-owndays.lenskart.com' : 'www.lenskart.com',
    hrefLang: 'en-in',
    gaId: 'UA-130468609-1',
    facebookPixel: '790230201013876',
    GvLkEnabled: true,
    phoneCode: '+91',
    gtmId_mob_prod: 'GTM-5GSLLFKQ',
    gtmId_mob_dev: 'GTM-5GSLLFKQ',
    gtmId_desktop_prod: 'GTM-5GSLLFKQ',
    gtmId_desktop_dev: 'GTM-5GSLLFKQ',
    localeList: [
      {
        title: 'English',
        code: 'en_in',
      },
    ],
    trendingSearch_desktop: [
      {
        name: 'Ray ban',
        url: 'https://www.lenskart.com/sunglasses/brands/ray-ban-sunglasses.html',
      },
      { name: 'Eyeglasses', url: 'https://www.lenskart.com/eyeglasses.html' },
      { name: 'Sunglasses', url: 'https://www.lenskart.com/sunglasses.html' },
      {
        name: 'Contact Lenses',
        url: 'https://www.lenskart.com/contact-lenses.html',
      },
      {
        name: 'Acuvue',
        url: 'https://www.lenskart.com/contact-lenses/most-popular-contact-lenses/acuvue-contact-lenses.html',
      },
      {
        name: 'Eyewear Accessories',
        url: 'https://www.lenskart.com/eyewear-accessories.html',
      },
      {
        name: 'Oakley',
        url: 'https://www.lenskart.com/sunglasses/brands/oakley-sunglasses.html',
      },
      {
        name: 'Mens Sunglasses',
        url: 'https://www.lenskart.com/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url: 'https://www.lenskart.com/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url: 'https://www.lenskart.com/sunglasses/frame-shape/aviator-sunglasses.html',
      },
      {
        name: 'Purevision',
        url: 'https://www.lenskart.com/contact-lenses/most-popular-contact-lenses/purevision-contact-lenses.html',
      },
      {
        name: 'Sunpocket - Folding Sunglasses',
        url: 'https://www.lenskart.com/sunglasses/brands/sunpocket.html',
      },
      { name: 'Eye Checkup', url: 'https://www.lenskart.com/HTO/' },
    ],
    trendingSearch_mobile: [
      {
        name: 'Ray ban',
        url: '//www.lenskart.com/sunglasses/brands/ray-ban-sunglasses.html',
      },
      { name: 'Eyeglasses', url: '/eyeglasses.html' },
      { name: 'Sunglasses', url: '/sunglasses.html' },
      { name: 'Contact Lenses', url: '/contact-lenses.html' },
      {
        name: 'Acuvue',
        url: '//www.lenskart.com/contact-lenses/most-popular-contact-lenses/acuvue-contact-lenses.html',
      },
      {
        name: 'Eyewear Accessories',
        url: '//www.lenskart.com/eyewear-accessories.html',
      },
      {
        name: 'Oakley',
        url: '//www.lenskart.com/sunglasses/brands/oakley-sunglasses.html',
      },
      {
        name: 'Mens Sunglasses',
        url: '//www.lenskart.com/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url: '//www.lenskart.com/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url: '///www.lenskart.com/sunglasses/frame-shape/aviator-sunglasses.html',
      },
      {
        name: 'Purevision',
        url: '//www.lenskart.com/contact-lenses/most-popular-contact-lenses/purevision-contact-lenses.html',
      },
      {
        name: 'Sunpocket - Folding Sunglasses',
        url: '//www.lenskart.com/sunglasses/brands/sunpocket.html',
      },
      { name: 'Eye Checkup', url: '//www.lenskart.com/HTO/' },
    ],
    allowedTrafficSourceCountries: ['DE'],
    showSentry: false,
  },
  ae: {
    defaultLocale: 'en_ae',
    defaultLanguage: 'en',
    fbVerificationTag: { enabled: true, key: '9jb6va8jnp2a7gstkihyr2rlfjcaz1' },
    countryCode: 'ae',
    storeHomeTrial: false,
    cookieDomain: '.lenskart.ae',
    gaId: 'UA-136497493-3',
    facebookPixel: '592809931699777',
    domain:
      process.env.BUILD_ENV === 'development'
        ? 'https://frontend-preprod-ae.lenskart.com'
        : 'https://www.lenskart.ae',
    domainUrl: process.env.BUILD_ENV === 'development' ? 'preprod.lenskart.ae' : 'www.lenskart.ae',
    maxMobileNumberLength: 9,
    minMobileNumberLength: 9,
    regex: /^\d+$/,
    hrefLang: 'en-ae',
    countryName: 'United Arab Emirates',
    pathPrefix: '',
    isPinCodeHide: true,
    phoneCode: '+971',
    gtmId_mob_prod: 'GTM-5GSLLFKQ',
    gtmId_mob_dev: 'GTM-5GSLLFKQ',
    gtmId_desktop_prod: 'GTM-5GSLLFKQ',
    gtmId_desktop_dev: 'GTM-5GSLLFKQ',
    GvLkEnabled: true,
    localeList: [
      {
        title: 'English',
        code: 'en_AE',
      },
    ],
    trendingSearch_desktop: [
      {
        name: 'Eyeglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/eyeglasses.html'
            : 'https://www.lenskart.ae/eyeglasses.html',
      },
      {
        name: 'Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses.html'
            : 'https://www.lenskart.ae/sunglasses.html',
      },
      {
        name: 'Mens Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/mens-sunglasses.html'
            : 'https://www.lenskart.ae/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/womens-sunglasses.html'
            : 'https://www.lenskart.ae/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/frame-shape/aviator-sunglasses.html'
            : 'https://www.lenskart.ae/sunglasses/frame-shape/aviator-sunglasses.html',
      },
    ],
    trendingSearch_mobile: [
      { name: 'Eyeglasses', url: '/eyeglasses.html' },
      { name: 'Sunglasses', url: '/sunglasses.html' },
      {
        name: 'Eyewear Accessories',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/eyewear-accessories.html'
            : '//www.lenskart.ae/eyewear-accessories.html',
      },
      {
        name: 'Mens Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/mens-sunglasses.html'
            : '//www.lenskart.ae/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/womens-sunglasses.html'
            : '//www.lenskart.ae/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/frame-shape/aviator-sunglasses.html'
            : '///www.lenskart.ae/sunglasses/frame-shape/aviator-sunglasses.html',
      },
    ],
    allowedTrafficSourceCountries: [
      'AE',
      'QA',
      'KW',
      'BH',
      'OM',
      'EG',
      'TR',
      'JO',
      'LB',
      'YE',
      'IQ',
      'SY',
      'IR',
    ],
    showSentry: false,
  },
  sg: {
    defaultLocale: 'en_sg',
    defaultLanguage: 'en',
    fbVerificationTag: { enabled: true, key: 'pfd8x7ajms35j8pe7h6nitj5b95nqw' },
    countryCode: 'sg',
    storeHomeTrial: false,
    cookieDomain: '.owndays.com',
    gaId: 'UA-131075285-1',
    facebookPixel: '297844910867676',
    tiktokPixel: 'C3QE7P84C3SCJI4REVE0',
    domain:
      process.env.LINK_ENV === 'development'
        ? 'https://preproduction.owndays.com'
        : 'https://www.owndays.com/sg/en',
    domainUrl:
      process.env.BUILD_ENV === 'development' ? 'preproduction.owndays.com' : 'www.owndays.com',
    maxMobileNumberLength: 8,
    minMobileNumberLength: 8,
    regex: /^\d+$/,
    hrefLang: 'en-sg',
    countryName: 'Singapore',
    pathPrefix: '',
    isPinCodeHide: true,
    phoneCode: '+65',
    gtmId_mob_prod: 'GTM-5GSLLFKQ',
    gtmId_mob_dev: 'GTM-5GSLLFKQ',
    gtmId_desktop_prod: 'GTM-5GSLLFKQ',
    gtmId_desktop_dev: 'GTM-5GSLLFKQ',
    GvLkEnabled: true,
    localeList: [
      {
        title: 'English',
        code: 'en_SG',
      },
    ],
    trendingSearch_desktop: [
      {
        name: 'Eyeglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sg/en/eyewear/eyeglasses.html'
            : 'https://www.owndays.con/sg/en/eyewear/eyeglasses.html',
      },
      {
        name: 'Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sg/en/sunglasses.html'
            : 'https://www.owndays.com/sg/en/sunglasses.html',
      },
      {
        name: 'Best Seller',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sg/en/bestseller.html'
            : 'https://www.owndays.com/sg/en/bestseller.html',
      },
      {
        name: 'Browline',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sg/en/eyeglasses/shape/browline.html'
            : 'https://www.owndays.com/sg/en/eyeglasses/shape/browline.html',
      },
    ],
    trendingSearch_mobile: [
      { name: 'Eyeglasses', url: '/sg/en/eyewear/eyeglasses.html' },
      { name: 'Sunglasses', url: '/sg/en/sunglasses.html' },
      {
        name: 'Best Seller',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sg/en/bestseller.html'
            : 'https://www.owndays.com/sg/en/bestseller.html',
      },
      {
        name: 'Browline',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sg/en/eyeglasses/shape/browline.html'
            : 'https://www.owndays.com/sg/en/eyeglasses/shape/browline.html',
      },
    ],
    allowedTrafficSourceCountries: [
      'BN',
      'KH',
      'TL',
      'ID',
      'LA',
      'MY',
      'MM',
      'PH',
      'SG',
      'TH',
      'VN',
      'CN',
      'HK',
      'MO',
      'JP',
      'MN',
      'KR',
      'TW',
      'AU',
      'NZ',
      'KP',
    ],
    showSentry: false,
  },
  sa: {
    defaultLocale: 'en_sa',
    defaultLanguage: 'en',
    fbVerificationTag: { enabled: true, key: '903kf3m83v88kwqatqoj4723m1dmeg' },
    countryCode: 'sa',
    storeHomeTrial: false,
    cookieDomain: 'sa.lenskart.com',
    gaId: 'UA-152862508-14', // temporary test account gaId(only for ga360)
    facebookPixel: '756183872056470',
    domain:
      process.env.BUILD_ENV === 'development'
        ? 'https://preprod-sa.lenskart.com'
        : 'https://sa.lenskart.com',
    domainUrl:
      process.env.BUILD_ENV === 'development' ? 'preprod-sa.lenskart.sa' : 'sa.lenskart.com',
    maxMobileNumberLength: 9,
    minMobileNumberLength: 9,
    regex: /^\d+$/,
    hrefLang: 'en-sa',
    countryName: 'Saudi Arabia',
    pathPrefix: '',
    isPinCodeHide: true,
    phoneCode: '+966',
    gtmId_mob_prod: 'GTM-5GSLLFKQ',
    gtmId_mob_dev: 'GTM-5GSLLFKQ',
    gtmId_desktop_prod: 'GTM-5GSLLFKQ',
    gtmId_desktop_dev: 'GTM-5GSLLFKQ',
    GvLkEnabled: true,
    localeList: [
      {
        title: 'English',
        code: 'en_SA',
      },
    ],
    trendingSearch_desktop: [
      {
        name: 'Eyeglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/eyeglasses.html'
            : 'https://sa.lenskart.com/eyeglasses.html',
      },
      {
        name: 'Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses.html'
            : 'https://sa.lenskart.com/sunglasses.html',
      },
      {
        name: 'Mens Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/mens-sunglasses.html'
            : 'https://sa.lenskart.com/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/womens-sunglasses.html'
            : 'https://sa.lenskart.com/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/frame-shape/aviator-sunglasses.html'
            : 'https://sa.lenskart.com/sunglasses/frame-shape/aviator-sunglasses.html',
      },
    ],
    trendingSearch_mobile: [
      { name: 'Eyeglasses', url: '/eyeglasses.html' },
      { name: 'Sunglasses', url: '/sunglasses.html' },
      {
        name: 'Eyewear Accessories',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/eyewear-accessories.html'
            : '//sa.lenskart.com/eyewear-accessories.html',
      },
      {
        name: 'Mens Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/mens-sunglasses.html'
            : '//sa.lenskart.com/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/womens-sunglasses.html'
            : '//sa.lenskart.com/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/frame-shape/aviator-sunglasses.html'
            : '///sa.lenskart.com/sunglasses/frame-shape/aviator-sunglasses.html',
      },
    ],
    allowedTrafficSourceCountries: [],
    showSentry: false,
  },
  us: {
    defaultLocale: 'en_us',
    defaultLanguage: 'en',
    fbVerificationTag: { enabled: true, key: '9jb6va8jnp2a7gstkihyr2rlfjcaz1' }, // pending
    countryCode: 'us',
    storeHomeTrial: false, // pending
    cookieDomain: '.lenskart.us',
    gaId: 'UA-136497493-1',
    facebookPixel: '674180879654129',
    domain:
      process.env.BUILD_ENV === 'development'
        ? 'https://preprod.lenskart.us'
        : 'https://live.lenskart.us',
    domainUrl: process.env.BUILD_ENV === 'development' ? 'preprod.lenskart.us' : 'live.lenskart.us',
    maxMobileNumberLength: 10, // pending
    minMobileNumberLength: 10, // pending
    regex: /^\d+$/,
    hrefLang: 'en-us',
    countryName: 'United States of America',
    pathPrefix: '',
    isPinCodeHide: false,
    phoneCode: '+1',
    gtmId_mob_prod: 'GTM-5GSLLFKQ',
    gtmId_mob_dev: 'GTM-5GSLLFKQ',
    gtmId_desktop_prod: 'GTM-5GSLLFKQ',
    gtmId_desktop_dev: 'GTM-5GSLLFKQ',
    GvLkEnabled: true,
    localeList: [
      {
        title: 'English',
        code: 'en_US',
      },
    ],
    trendingSearch_desktop: [
      {
        name: 'Eyeglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/eyeglasses.html'
            : 'https://live.lenskart.us/eyeglasses.html',
      },
      {
        name: 'Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses.html'
            : 'https://www.lenskart.us/sunglasses.html',
      },
      {
        name: 'Mens Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/mens-sunglasses.html'
            : 'https://www.lenskart.us/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/womens-sunglasses.html'
            : 'https://www.lenskart.us/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/frame-shape/aviator-sunglasses.html'
            : 'https://www.lenskart.us/sunglasses/frame-shape/aviator-sunglasses.html',
      },
    ],
    trendingSearch_mobile: [
      { name: 'Eyeglasses', url: '/eyeglasses.html' },
      { name: 'Sunglasses', url: '/sunglasses.html' },
      {
        name: 'Eyewear Accessories',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/eyewear-accessories.html'
            : '//www.lenskart.us/eyewear-accessories.html',
      },
      {
        name: 'Mens Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/mens-sunglasses.html'
            : '//www.lenskart.us/sunglasses/find-eyewear/mens-sunglasses.html',
      },
      {
        name: 'Women Sunglasses',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/find-eyewear/womens-sunglasses.html'
            : '//www.lenskart.us/sunglasses/find-eyewear/womens-sunglasses.html',
      },
      {
        name: 'Aviator',
        url:
          process.env.BUILD_ENV === 'development'
            ? '/sunglasses/frame-shape/aviator-sunglasses.html'
            : '///www.lenskart.us/sunglasses/frame-shape/aviator-sunglasses.html',
      },
    ],
    allowedTrafficSourceCountries: [],
  },
};

module.exports = { langCodeAllowed };
