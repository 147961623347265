import React, { useMemo, useRef, useState } from 'react';
import './DenominationSection.scss';

import { useSelector } from 'react-redux';

import OdCheckBlackIcon from '../../../../../../static/assets/svg/od-check-icon.svg';

const DenominationSection = ({
  handleDenominationSelection,
  denominations,
  selectedDenomination,
}) => {
  const dataLocale = useSelector(state => state.locale);
  const { VIEW_MORE } = dataLocale;
  const currencyCode = dataLocale?.CURRENCY_CODE || '';
  const currencySymbol = dataLocale?.CURRENCY_SYMBOL || '';

  const [showOptions, setShowOptions] = useState(false);

  const togglerInputRef = useRef(null);

  const { separateDenominations, groupedDenominations } = useMemo(() => {
    return denominations.reduce(
      (acc, denomination, index) => {
        acc[index < 4 ? 'separateDenominations' : 'groupedDenominations'].push(denomination);

        return acc;
      },
      { separateDenominations: [], groupedDenominations: [] }
    );
  }, [denominations]);

  const checkIsGroupedDenomination = price => {
    const prices = groupedDenominations.map(item => item?.price);
    if (prices.includes(price)) {
      return `${currencyCode}${currencySymbol} ${price}`;
    }

    return false;
  };

  const isSelectedFromDropdown = useMemo(() => {
    const selectedDenominationIndex = denominations.findIndex(
      denomination => selectedDenomination?.price === denomination?.price
    );

    return selectedDenominationIndex > 3;
  }, [denominations, selectedDenomination]);

  const handleToggle = e => {
    e.target.focus();
    setShowOptions(!showOptions);
  };

  const handleBlur = e => {
    // firefox onBlur issue workaround
    if (
      e.nativeEvent.explicitOriginalTarget &&
      e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
    ) {
      return;
    }

    if (showOptions) {
      setTimeout(() => {
        setShowOptions(false);
      }, 200);
    }
  };

  if (Array.isArray(denominations) && denominations.length > 0) {
    return (
      <div className="od-denominations">
        {separateDenominations.map(denomination => (
          <div
            key={denomination.id}
            className={`od-denominations__item od-font-reg ${
              selectedDenomination?.price === denomination?.price &&
              'od-denominations__item--selected'
            }`}
            onClick={() => handleDenominationSelection(denomination)}
          >
            {`${currencyCode}${currencySymbol} ${denomination.price}`}
            {/* <ButtonOwnDays
              borderless
              noHover
              classes="od-denomination__btn"
              fontClass="od-font-reg"
              fontSize="16px"
              mode="grey-secondary"
              px="50px"
              py="16px"
              text={`${currencyCode}${currencySymbol} ${denomination.price}`}
              onClick={() => handleDenominationSelection(denomination)}
            /> */}
          </div>
        ))}

        <div className="od-denominations-dropdown-wrapper">
          <label
            className={`od-denominations-dropdown-toggler ${
              isSelectedFromDropdown && 'od-denominations-dropdown-toggler--selected'
            }`}
          >
            <input
              ref={togglerInputRef}
              className="od-denominations-dropdown-toggler__input od-font-reg"
              type="button"
              value={checkIsGroupedDenomination(selectedDenomination?.price) || VIEW_MORE}
              onBlur={handleBlur}
              onClick={handleToggle}
            />
            <i
              className={`fa fa-angle-down od-denominations-dropdown-toggler__icon${
                showOptions ? ' od-denominations-dropdown-toggler__icon--active' : ''
              }`}
            />
          </label>
          {showOptions && (
            <div className="od-denominations-dropdown-options">
              {groupedDenominations.map(denomination => (
                <div
                  key={denomination.id}
                  className={`od-denominations-dropdown-options__option ${
                    selectedDenomination?.price === denomination?.price
                      ? 'od-denominations-dropdown-options__option--selected od-font-bold'
                      : 'od-font-reg'
                  }`}
                  onClick={() => handleDenominationSelection(denomination)}
                >
                  {`${currencyCode}${currencySymbol} ${denomination.price}`}

                  {selectedDenomination?.price === denomination?.price && (
                    <img alt="selected" src={OdCheckBlackIcon} />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default DenominationSection;
