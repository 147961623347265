import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import {
  HeaderOwndaysSnap,
  BodyOwndaysSnap,
  FooterOwndaysSnap,
} from '../CommonComponents/OwndaysSnap';

import '../CommonComponents/OwndaysSnap/BaseOwndaysSnap.scss';

const OwndaysSnapHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-snap-2024';

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <div className="base-owndays-snap">
      <HeaderOwndaysSnap baseImgUrl={baseImgUrl} />
      <BodyOwndaysSnap baseImgUrl={baseImgUrl} />
      <FooterOwndaysSnap baseImgUrl={baseImgUrl} />
    </div>
  );
};

export default OwndaysSnapHTML;
