import React from 'react';
import CartFooter from './CartFooter';

const RenderCartFooter = props => {
  const { checkoutCTA, cartObj, dataLocale, isframeProduct, redisCommonData, checkoutLoader } =
    props;
  return (
    <div className={`finl-cart-conti span6 ${checkoutCTA ? 'custom-checkoutBtn' : ''}`}>
      <CartFooter
        cartObj={cartObj}
        checkOutHandler={() => {
          props?.checkoutLoaderFunc(true);
          _gaq.push(
            ['_trackEvent', 'Place Order', 'Cod'],
            ['ninja._trackEvent', 'Place Order', 'Cod']
          );
        }}
        checkoutCTA={checkoutCTA}
        checkoutLoader={checkoutLoader}
        dataLocale={dataLocale}
        isframeProduct={isframeProduct}
        redisCommonData={redisCommonData}
      />
    </div>
  );
};

export default RenderCartFooter;
