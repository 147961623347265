import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import * as actionTypes from '../actionTypes/myAccount';
import { GET_USER_SUCCESS } from '../actionTypes/common';
import apiClient from '../helpers/apiClient';

let _client = null;
let xApiClient = 'desktop';
// conditional imports on compilation
('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
xApiClient = 'mobilesite';
('#endif'); // eslint-disable-line

function makeCall(pageName, size) {
  const client = { ..._client };
  client.setHeader();
  let url;
  if (pageName && size) {
    url = `${config.apiPath.getOrdersListInv}?page=${pageName}&page-size=${size}`;
  } else {
    url = `${config.apiPath.getOrdersListInv}`;
  }

  return client.get(url);
}

function* getOrders({ pageName, size, shouldReset }) {
  const successAction = { type: actionTypes.ORDER_LIST_SUCCESS };
  const errorAction = { type: actionTypes.ORDER_LIST_FAIL };
  try {
    const data = yield call(async () => makeCall(pageName, size));
    successAction.data = data;
    if (shouldReset) successAction.shouldReset = shouldReset;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* updateInfo({ firstName, lastName, gender, email }) {
  const successAction = { type: actionTypes.UPDATE_USER_INFO_SUCCESS };
  const userSuccessAction = { type: GET_USER_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_USER_INFO_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.post(`${config.apiPath.getUserInfo}`, { firstName, lastName, gender, email })
    );
    successAction.data = data;
    userSuccessAction.data = data;
    userSuccessAction.data.result.login = true;
    yield put(userSuccessAction);
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* resetPassword({ oldPassword, newPassword, email, token }) {
  const successAction = { type: actionTypes.RESET_PASSWORD_SUCCESS };
  const errorAction = { type: actionTypes.RESET_PASSWORD_FAIL };
  const client = { ..._client };
  client.setHeader();
  let url = `${config.apiPath.changePassword}`;
  let apiData = {};
  if (token) {
    url = `${config.apiPath.resetPassword}/${token}`;
    apiData = { newPassword, confirmNewPassword: newPassword };
  } else {
    apiData = { oldPassword, newPassword, email };
  }
  try {
    const data = yield call(async () => client.post(url, apiData));
    successAction.email = email;
    successAction.password = newPassword;
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* checkVoucher({ voucherCode }) {
  const successAction = { type: actionTypes.CHECK_VOUCHER_SUCCESS };
  const errorAction = { type: actionTypes.CHECK_VOUCHER_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.giftVoucherBalance}${voucherCode}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

function* storeCredit({ voucherCode }) {
  const successAction = { type: actionTypes.STORE_CREDIT_BAL_SUCCESS };
  const errorAction = { type: actionTypes.STORE_CREDIT_BAL_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(`${config.apiPath.storeCredit}${voucherCode}`));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* getStoreCreditOrders({ pageName, size }) {
  const successAction = { type: actionTypes.STORE_CREDIT_ORDERS_SUCCESS };
  const errorAction = { type: actionTypes.STORE_CREDIT_ORDERS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.storeCredit}orders?page=${pageName}&pagesize${size}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* getStoreCreditOrderHistory({ orderId }) {
  const successAction = { type: actionTypes.STORE_CREDIT_ORDER_HISTORY_SUCCESS };
  const errorAction = { type: actionTypes.STORE_CREDIT_ORDER_HISTORY_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.storeCredit}history?orderId=${orderId}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* sendStoreCreditSMS({ orderId }) {
  const successAction = { type: actionTypes.STORE_CREDIT_ORDER_SMS_SUCCESS };
  const errorAction = { type: actionTypes.STORE_CREDIT_ORDER_SMS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.get(`${config.apiPath.storeCredit}sms?orderId=${orderId}`)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* saveNeftValues({ values }) {
  const successAction = { type: actionTypes.EDIT_NEFT_SUCCESS };
  const errorAction = { type: actionTypes.EDIT_NEFT_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.post(`${config.apiPath.editNeft}`, values));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* getReturnConfig() {
  const successAction = { type: actionTypes.RETURN_ORDER_CONFIG_SUCCESS };
  const errorAction = { type: actionTypes.RETURN_ORDER_CONFIG_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(`${config.apiPath.returnConfig}`));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* getPfuPdRulesConfig() {
  const successAction = { type: actionTypes.PFU_PD_RULES_CONFIG_SUCCESS };
  const errorAction = { type: actionTypes.PFU_PD_RULES_CONFIG_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () => client.get(`${config.apiPath.pfuPdRules}`));
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}
function* updateAddress({ orderId, address }) {
  const successAction = { type: actionTypes.UPDATE_ADDRESS_SUCCESS };
  const errorAction = { type: actionTypes.UPDATE_ADDRESS_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      client.put(`${config.apiPath.updateShippingAddress}/${orderId}/shippingAddress`, address)
    );
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    errorAction.error = er;
    yield put(errorAction);
  }
}

export function* runMyAccountCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([
    takeLatest(actionTypes.ORDER_LIST_LOAD, getOrders),
    takeLatest(actionTypes.UPDATE_USER_INFO_LOAD, updateInfo),
    takeLatest(actionTypes.RESET_PASSWORD_LOAD, resetPassword),
    takeLatest(actionTypes.CHECK_VOUCHER_LOAD, checkVoucher),
    takeLatest(actionTypes.UPDATE_ADDRESS_LOAD, updateAddress),
    takeLatest(actionTypes.STORE_CREDIT_BAL_LOAD, storeCredit),
    takeLatest(actionTypes.STORE_CREDIT_ORDERS_LOAD, getStoreCreditOrders),
    takeLatest(actionTypes.STORE_CREDIT_ORDER_HISTORY_LOAD, getStoreCreditOrderHistory),
    takeLatest(actionTypes.STORE_CREDIT_ORDER_SMS_LOAD, sendStoreCreditSMS),
    takeLatest(actionTypes.EDIT_NEFT_LOAD, saveNeftValues),
    takeLatest(actionTypes.ORDER_LIST_LOAD_MORE, getOrders),
    // takeLatest(actionTypes.RETURN_ORDER_CONFIG_LOAD, getReturnConfig),
    takeLatest(actionTypes.PFU_PD_RULES_CONFIG_LOAD, getPfuPdRulesConfig),
  ]);
}
