import React from 'react';
import './SocialMediaLinks.scss';
import { v4 } from 'uuid';

const SocialMediaLinks = ({ device, socialLinksdata }) => {
  const socialImgSize = device === 'mobile' ? 24 : 18;
  return socialLinksdata && socialLinksdata.length > 0 ? (
    <ul className={`social-media-links ${device === 'mobile' && 'social-media-links-mweb'}`}>
      {socialLinksdata.map(social => {
        return (
          <li key={v4()}>
            <a href={social.link} rel="nofollow noreferrer" target="_blank" title={social.title}>
              <img
                alt={social.img.alt}
                src={social.img.src}
                style={{ height: socialImgSize, width: socialImgSize }}
              />
            </a>
          </li>
        );
      })}
    </ul>
  ) : null;
};

export default SocialMediaLinks;
