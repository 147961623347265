import React from 'react';
import './ServicesPage.scss';

import LOGO_HUAWEI from '../../../../../static/assets/logo/logo-huawei.svg';

import { connect } from 'react-redux';

const ServicesPage = props => {
  const { dataLocale } = props;

  const {
    FROM,
    SERVICES = 'Services',
    SERVICES_DESCRIPTION = 'At OWNDAYS, we are dedicated to providing the best service to our customers. Using the concept of OWNDAYS Standard, we strive to offer a consistent level of excellent customer service across all our retail outlets.',
    SERVICES_PRICE_TITLE = 'Simple Price',
    SERVICES_PRICE_SUBTITLE = 'No additional charge for standard lenses of any power',
    SERVICES_PRICE_DESCRIPTION = 'In OWNDAYS, we pride ourselves in our simple price system. All you need to pay for a pair of spectacles is the price indicated on the frame. There is no additional charge for standard high index aspheric lenses of any power.',
    SERVICES_LENS_TITLE = 'Lens',
    SERVICES_LENS_SUBTITLE = 'Our standard lenses are high quality high index aspheric lenses that cause less visual distortion',
    SERVICES_LENS_DESCRIPTION = 'The lenses we use in OWNDAYS are high index aspheric lenses manufactured by top international lens makers.',
    SERVICES_PROCESSING_TITLE = '20-Minutes Quick Processing',
    SERVICES_PROCESSING_SUBTITLE = 'Glasses ready in only 20 minutes after purchase',
    SERVICES_PROCESSING_DESCRIPTION = 'We know your time is valuable and that’s why we aim to deliver your glasses to you in as little time as possible. Backed by solid knowledge and skills, we are able to process your glasses within 20 minutes upon payment.',
    SERVICES_REPLACEMENT_TITLE = 'Lens Replacement',
    SERVICES_REPLACEMENT_SUBTITLE = 'Replace lenses of spectacles bought from other shops at simple price!',
    SERVICES_REPLACEMENT_DESCRIPTION = 'Let us help you breathe new life into the old spectacles that you cherish by replacing the lenses. We are happy to do so even for spectacles purchased from other shops.',
    SERVICES_FRAME_TITLE = 'Frame',
    SERVICES_FRAME_SUBTITLE = 'Designed and manufactured in-house',
    SERVICES_FRAME_DESCRIPTION = 'Every pair of OWNDAYS spectacles is carefully assembled by hand.',
  } = dataLocale;

  const LENS_REPLACEMENT_PRICE = 'S$98';

  return (
    <main className="services-page">
      <section className="services-page-hero">
        <img
          alt="Services"
          className="services-page-hero__image"
          src="https://static.lenskart.com/media/owndays/desktop/img/owndays/services/services-banner.webp"
        />
        <div className="services-page-hero__layout">
          <img alt="Logo" className="services-page-hero__logo" src={LOGO_HUAWEI} />
          <h1 className="services-page-hero__title od-font-bold">{SERVICES}</h1>
        </div>
      </section>

      <section className="services-page-list">
        <div className="services-page-list__layout">
          <p className="services-page-list__description od-font-reg">{SERVICES_DESCRIPTION}</p>
          <div className="services-page-list__grid">
            <div className="services-page-list__item-info">
              <h2 className="services-page-list__item-title od-font-reg">{SERVICES_PRICE_TITLE}</h2>
              <p className="services-page-list__item-subtitle od-font-bold">
                {SERVICES_PRICE_SUBTITLE}
              </p>
              <p className="services-page-list__item-description od-font-reg">
                {SERVICES_PRICE_DESCRIPTION}
              </p>
            </div>
            <div
              className="services-page-list__item-image"
              style={{
                backgroundImage: `url(https://static.lenskart.com/media/owndays/desktop/img/owndays/services/services-price.webp)`,
              }}
            />
            <div
              className="services-page-list__item-image"
              style={{
                backgroundImage: `url(https://static.lenskart.com/media/owndays/desktop/img/owndays/services/services-lens.webp)`,
              }}
            />
            <div className="services-page-list__item-info">
              <h2 className="services-page-list__item-title od-font-reg">{SERVICES_LENS_TITLE}</h2>
              <p className="services-page-list__item-subtitle od-font-bold">
                {SERVICES_LENS_SUBTITLE}
              </p>
              <p className="services-page-list__item-description od-font-reg">
                {SERVICES_LENS_DESCRIPTION}
              </p>
            </div>
            <div className="services-page-list__item-info">
              <h2 className="services-page-list__item-title od-font-reg">
                {SERVICES_PROCESSING_TITLE}
              </h2>
              <p className="services-page-list__item-subtitle od-font-bold">
                {SERVICES_PROCESSING_SUBTITLE}
              </p>
              <p className="services-page-list__item-description od-font-reg">
                {SERVICES_PROCESSING_DESCRIPTION}
              </p>
            </div>
            <div
              className="services-page-list__item-image"
              style={{
                backgroundImage: `url(https://static.lenskart.com/media/owndays/desktop/img/owndays/services/services-processing.webp)`,
              }}
            />
            <div
              className="services-page-list__item-image"
              style={{
                backgroundImage: `url(https://static.lenskart.com/media/owndays/desktop/img/owndays/services/services-replacement.webp)`,
              }}
            />
            <div className="services-page-list__item-info">
              <h2 className="services-page-list__item-title od-font-reg">
                {SERVICES_REPLACEMENT_TITLE}
              </h2>
              <p className="services-page-list__item-subtitle od-font-bold">
                {SERVICES_REPLACEMENT_SUBTITLE}
              </p>
              <p className="services-page-list__item-price">{`${FROM} ${LENS_REPLACEMENT_PRICE}`}</p>
              <p className="services-page-list__item-description od-font-reg">
                {SERVICES_REPLACEMENT_DESCRIPTION}
              </p>
            </div>
            <div className="services-page-list__item-info">
              <h2 className="services-page-list__item-title od-font-reg">{SERVICES_FRAME_TITLE}</h2>
              <p className="services-page-list__item-subtitle od-font-bold">
                {SERVICES_FRAME_SUBTITLE}
              </p>
              <p className="services-page-list__item-description od-font-reg">
                {SERVICES_FRAME_DESCRIPTION}
              </p>
            </div>
            <div
              className="services-page-list__item-image"
              style={{
                backgroundImage: `url(https://static.lenskart.com/media/owndays/desktop/img/owndays/services/services-frame.webp)`,
              }}
            />
          </div>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesPage);
