import React, { Fragment, useEffect } from 'react';
import AOS from 'aos';

import {
  HeaderOwndaysSunglasses,
  BodyOwndaysSunglasses,
  FooterOwndaysSunglasses,
} from '../CommonComponents/OwndaysSunglasses';

import '../CommonComponents/OwndaysSunglasses/BaseOwndaysSunglasses.scss';

const OwndaysSunglassesHTML = () => {
  const baseImgUrl = 'https://static.lenskart.com/media/owndays/img/owndays-sunglasses-2024';

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <Fragment>
      <HeaderOwndaysSunglasses baseImgUrl={baseImgUrl} />
      <BodyOwndaysSunglasses baseImgUrl={baseImgUrl} />
      <FooterOwndaysSunglasses baseImgUrl={baseImgUrl} />
    </Fragment>
  );
};

export default OwndaysSunglassesHTML;
