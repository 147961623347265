// OWNDAYS_COMPONENT ===> Main Nav desktop
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './MainNav.scss';
import PropTypes from 'prop-types';

import AuthModalNewLoadable from '../AuthModalNew/AuthModalNewLoadable';
import Logo from './Logo';
import Cart from './Cart/Cart';
import ErrorBoundaries from './../ErrorBoundaries/ErrorBoundary';
import SearchBar from '../SearchBar/SearchBar';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonCallsActionCreators from '../../../actionCreators/common';
import * as registerActions from '../../../actionCreators/auth';
import * as cartActions from '../../../actionCreators/cart';
import * as dittoCallsActionCreators from '../../../actionCreators/ditto';
import { hideRepeatUserElem } from '../../../utils/helper';
import sessionStorageHelper from '../../../utils/sessionStorageHelper';
import getSetCookie from '../../../cookie';
import GenderMenuOD from '../../../own-days/views/desktop/GenderMenuOD/GenderMenuOD';
import { v4 } from 'uuid';
import ContactLensCard from '../../../own-days/Desktop/ContactLensCard/ContactLensCard';
import { pushDataLayerEvent } from '../../../utils/DataLayer';

const MainNav = props => {
  const {
    redisCommonData = {},
    dataLocale,
    login,
    askUserLogin,
    shortListData,
    section,
    loginStatus,
    commonCallsAction,
    registerActions,
    history,
    isCartCount,
    userInfo,
    dittoCallsAction,
    cartActions,
    location,
    localeInfo,
    appLogo,
    cartData,
    showCart,
    mainNavigationData,
    secondaryNav,
    register,
    loginSuccess,
  } = props;

  const userInformation = localStorage?.getItem('user-info');
  const parsedUserInformation = JSON.parse(userInformation);

  const {
    NEW_ARRIVALS,
    EYEGLASSES,
    SUNGLASSES,
    STORES = 'Stores',
    LOGIN,
    SEARCH,
    GIFT_CARDS = 'Gift Cards',
    CONTACT_LENS,
    COLLECTIONS,
  } = dataLocale;

  const path = window.location.pathname;

  const isPaymentPage = path.includes('/payment-page');
  const isShippingPage = path.includes('/checkout/onepage');

  const mainClass = secondaryNav ? 'secondary' : 'main';
  const headerLogo = secondaryNav
    ? 'https://static1.lenskart.com/media/owndays/desktop/img/od-logos-new/logo-type-white.png'
    : appLogo;

  const [showAuthPopUp, setShowAuthPopUp] = useState(false);
  const [newCartFlowEnable, setNewCartFlowEnable] = useState(false);
  const [registeredSuccessfully, setRegisteredSuccessfully] = useState(false);
  const [showBookEyeTest, setShowBookEyeTest] = useState(false);
  const [openTab, setOpenTab] = useState(false);

  const isNewAuthPopUpAvailable = !!(
    redisCommonData?.NEW_AUTH_POPUP_DESKTOP &&
    JSON.parse(redisCommonData?.NEW_AUTH_POPUP_DESKTOP)?.enable === 'ON'
  );

  const triggerDataLayerOnCtaClick = name => {
    name = name?.trim().toLowerCase() || '';
    const data = {
      event: 'cta_click',
      cta_name: name.replace(/[ ]+/gi, '-'),
      cta_flow_and_page: 'navigation-bar',
    };
    pushDataLayerEvent(data);
  };

  const showWishList = () => {
    window.location.assign('/sg/en/wishlist');

    triggerDataLayerOnCtaClick('wishlist');

    // uncomment if need handling shortlisted products and wishlists.

    // This section appears to handle clicking on the header
    // if (window.dtm.LenskartRewamp)
    //   window.dtm.LenskartRewamp.header.click.headerClick(section, 'shortlist', loginStatus);

    // declaring and initializing an array called 'productId'
    // const productId = [];

    // populating the 'productId' array with ids of shortlisted products
    // if (shortListData.result?.numberOfProducts > 0 && shortListData.result.productLis) {
    //   shortListData.result.productList.forEach(data => productId.push(data.id));
    // }

    // If 'wishlistTrue' is true, it hides the wishlist using the 'commonCallsAction.showWishlist(false)' function.
    // if (wishlistTrue === false) {
    //   if (window.dtm.LenskartRewamp) {
    //     window.dtm.LenskartRewamp.Listing.load.headerShortlist(productId.toString());
    //   }

    //   commonCallsAction.showWishlist(true);
    // } else {
    //   commonCallsAction.showWishlist(false);
    // }
  };

  const displayAuthPopUp = useCallback(() => {
    setShowAuthPopUp(true);
    setOpenTab('signin');

    registerActions.resetMobileLoginProps();
  }, [registerActions]);

  const hideAuthPopUp = useCallback(() => {
    clearInterval(window.secondsTimer);
    if (register) {
      setRegisteredSuccessfully(true);
    } else {
      setShowAuthPopUp(false);
    }
  }, [register]);

  useEffect(() => {
    if (registeredSuccessfully) {
      const timer = setTimeout(() => {
        setShowAuthPopUp(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [registeredSuccessfully]);

  const cartClickHandler = () => {
    // OD__CLEVERTAP__EVENT (cta_click)
    // if (typeof window.clevertap !== 'undefined') {
    //   window.clevertap.event.push('cta_click', {
    //     ctaName: 'Cart-logo',
    //   });
    //   console.log('🚀 ~ Cart-logo:', window.clevertap.event);
    // }

    // window.dtm.actiontype = 'view';
    window.location.href = '/sg/en/cart';

    // if (window.dtm.LenskartRewamp)
    //   window.dtm.LenskartRewamp.header.click.headerClick(section, 'CART', loginStatus);

    // // commonCallsAction.toggleCart(true);

    // if (newCartFlowEnable) {
    // }
  };

  const checkRepeatUser = useCallback(() => {
    const isRepeatUser = userInfo?.result?.hasPlacedOrder;

    if (isRepeatUser) {
      sessionStorage.setItem('isRepeatUser', 'true');
    } else {
      sessionStorage.removeItem('isRepeatUser');
    }

    const hideElem = document.getElementsByClassName('hide-repeat');

    if (hideElem) {
      hideRepeatUserElem(isRepeatUser, hideElem);
    }
  }, [userInfo?.result?.hasPlacedOrder]);

  /**
   * The cartOmniture function processes cart data and sends information to LenskartRewamp for analytics.
   * @param {Array} data - An array of cart data, containing information for each item.
   */
  const cartOmniture = data => {
    // Check if LenskartRewamp object is available in the global window.
    if (!window.dtm.LenskartRewamp) return;

    // Initialize variables and objects to store data.
    const cxid = userInfo ? userInfo.result.id : '';
    let subCategoryStr = '';
    const cartData = {
      cartProductId: [],
      lenskartPrice: [],
      quanTity: [],
      productType: [],
      productBrand: [],
      productFrameSize: [],
      productName: [],
      frametype: [],
      productOption: [],
      productPackageName: [],
      productCoating: [],
      addOn: [],
    };

    // Process each cart item in the provided 'data' array.
    data.forEach(cartItem => {
      // Collect category information to form string data.
      cartData.subCategory.push(cartItem.subcategory);
      subCategoryStr = cartData.subCategory.join('|').toLowerCase();

      // Collect product information to form string data.
      cartData.cartProductId.push(cartItem.productId);
      cartData.quanTity.push(cartItem.quantity);
      cartData.productType.push(cartItem.productTypeValue);
      cartData.productFrameSize.push(cartItem.frameSize);
      cartData.productBrand.push(cartItem.brandName);
      cartData.productName.push(cartItem.name);
      cartData.frametype.push(cartItem.frameType);

      // Process price data for the product.
      if (cartItem.catalogPrices.length > 2) {
        cartData.lenskartPrice.push(cartItem.catalogPrices[2].value);
      } else {
        const lenskartPriceObj = cartItem.catalogPrices.find(
          price => price.name === 'Lenskart Price'
        );
        cartData.lenskartPrice.push(lenskartPriceObj ? lenskartPriceObj.value : '');
      }

      // Process information about selected product options.
      if (cartItem.options.length >= 1) {
        cartData.productOption.push(cartItem.options[0].type);
        cartData.productPackageName.push(cartItem.options[0].name);
        cartData.productCoating.push(
          cartItem.options.length > 1 && cartItem.options[1].type === 'COATING' ? 'Yes' : 'No'
        );
      }

      // Process information about additional product options (addOn).
      cartItem.options.forEach(productOptionsType => {
        const optionsPrice = productOptionsType.catalogPrices[0].value;
        cartData.addOn.push({
          addonid: productOptionsType.oid,
          addontype: productOptionsType.type,
          addonprice: optionsPrice,
          addonbaseproduct: cartItem.productId,
        });
      });
    });

    // Send data to LenskartRewamp for analytics using the cartSummary method.
    window.dtm.LenskartRewamp.slideCart.click.cartSummary(
      cxid,
      cartData.cartProductId.join('|').toLowerCase(),
      cartData.lenskartPrice.join('|').toLowerCase(),
      cartData.quanTity.join('|').toLowerCase(),
      cartData.productBrand.join('|').toLowerCase(),
      cartData.productType.join('|').toLowerCase(),
      cartData.productOption.join('|').toLowerCase(),
      cartData.productPackageName.join('|').toLowerCase(),
      cartData.productCoating.join('|').toLowerCase(),
      cartData.addOn,
      login,
      cartData.frametype.join('|').toLowerCase(),
      subCategoryStr,
      cartData.productFrameSize.join('|').toLowerCase(),
      cartData.productName.join('|').toLowerCase()
    );

    // Save 'cartSummary' value in sessionStorage for further analytics usage.
    sessionStorage.setItem('dtmOnLoadData', 'cartSummary');
  };

  const hideCart = () => {
    commonCallsAction.toggleCart(false);
  };

  useEffect(() => {
    setNewCartFlowEnable(
      !!(
        redisCommonData?.NEW_CART_FLOW_DESKTOP &&
        JSON.parse(redisCommonData?.NEW_CART_FLOW_DESKTOP)?.enable === 'ON'
      )
    );

    const typeLogin = location.pathname === '/customer/account/login';
    const typeRegister = location.pathname === '/customer/account/signup';

    setTimeout(() => {
      if (typeLogin || typeRegister) {
        if (typeRegister) setOpenTab('signup');
        if (typeLogin) setOpenTab('signin');
        setShowAuthPopUp(Boolean(typeLogin || typeRegister));
      }
    }, 100);
  }, [location, redisCommonData?.NEW_CART_FLOW_DESKTOP]);

  useEffect(() => {
    if (
      // getSetCookie.getCookie('isDittoID') === '' &&
      userInfo &&
      userInfo.result.dittos &&
      userInfo.result.dittos.length &&
      Boolean(parseInt(getSetCookie.getCookie('isLogined'), 10))
    ) {
      const dittoId = redisCommonData?.ENABLE_CYGNUS
        ? userInfo?.result?.cygnus?.cygnusId
        : userInfo.result.dittos[0];

      if (dittoId) {
        getSetCookie.setCookie('isDittoID', dittoId);
        localStorage.setItem(
          'ditto-platform',
          userInfo.result.ditto && userInfo.result.ditto.platform
        );
      }
    }

    window.onpopstate = () => {
      if (location.pathname === '/') {
        sessionStorage.setItem('backTrigger', 'true');
      }
    };
  });

  // Desktop exchange flow
  useEffect(() => {
    if (isCartCount > 0) {
      const exchangeFlow = sessionStorageHelper.getItem('exchangeFlow');
      const { returnAction } = sessionStorageHelper.getItem('returnProduct') || {};

      if (exchangeFlow) {
        if (returnAction === 'exchange') {
          cartActions.getCart(null, false, false, false, true);
        }
      } else {
        cartActions.getCart();
      }
    }
  }, [cartActions, isCartCount]);
  // End

  useEffect(() => {
    if (
      getSetCookie.getCookie('guest_dittoid') !== '' &&
      getSetCookie.getCookie('isLogined') === '1'
    ) {
      dittoCallsAction.saveUpdateDitto(getSetCookie.getCookie('isDittoID'));
      dittoCallsAction.addNewDitto(getSetCookie.getCookie('isDittoID'));
      commonCallsAction.dittoAuth(getSetCookie.getCookie('isDittoID'));
      localStorage.setItem('ditto-platform', 'desktop');
      document.cookie = `guest_dittoid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=${
        localeInfo.cookieDomain || '.lenskart.com'
      };path=/`;
    }
  }, [commonCallsAction, dittoCallsAction, localeInfo.cookieDomain, userInfo]);

  // useEffect(() => {
  //   if (loginSuccess && userInfo) {
  //     // OD__CLEVERTAP__EVENT (Login)
  //     if (typeof window.clevertap !== 'undefined') {
  //       window.clevertap.event.push('Login', {
  //         name: userInfo.firstName,
  //         email: userInfo.email,
  //         dob: userInfo.dob,
  //         phoneNumber: userInfo.telephone,
  //       });
  //       console.log('🚀 ~ Login event ~ userInfo:', userInfo);
  //       console.log('🚀 ~ Login event :', window.clevertap.event);
  //     }

  //     odPushClevertapEvent('LogIn', {
  //       loginSuccess,
  //     });
  //   }
  // }, [loginSuccess, userInfo]);

  useEffect(() => {
    if (!loginStatus && askUserLogin) {
      displayAuthPopUp();
    }

    if (
      !registeredSuccessfully &&
      loginStatus &&
      window.location.pathname?.indexOf('/customer/') !== -1 &&
      !askUserLogin
    ) {
      hideAuthPopUp();
    }

    // This condition is case when user comes from retrypayment page.
    if (loginStatus && location.state && location.state.redirectUrl) {
      window.location.href = `${localeInfo.domain}/customer/account/#/`;
    }

    if (location.pathname === '/') {
      checkRepeatUser();
    }
  }, [
    askUserLogin,
    checkRepeatUser,
    displayAuthPopUp,
    localeInfo.domain,
    location.pathname,
    location.state,
    loginStatus,
  ]);

  const [hoveredItem, setHoveredItem] = useState(null);

  const handleMouseEnter = index => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  // uncomment "data key" for show submenu
  const navLinks = useMemo(() => {
    return [
      {
        title: EYEGLASSES?.toUpperCase(),
        link: mainNavigationData?.header?.mainNav?.EYEGLASSES?.link,
        // data: mainNavigationData.header.mainNav.EYEGLASSES.data,
      },
      {
        title: SUNGLASSES?.toUpperCase(),
        link: mainNavigationData?.header?.mainNav?.SUNGLASSES?.link,
      },
      {
        title: NEW_ARRIVALS?.toUpperCase(),
        link: mainNavigationData?.header?.mainNav?.NEW_ARRIVALS.link,
      },
      {
        title: CONTACT_LENS?.toUpperCase(),
        // link: mainNavigationData?.header?.mainNav?.CONTACT_LENS.link,
        data: mainNavigationData?.header?.mainNav?.CONTACT_LENS?.data,
      },
      {
        title: 'COLLECTIONS',
        link: mainNavigationData?.header?.mainNav?.COLLECTIONS?.link,
      },
      {
        title: STORES?.toUpperCase(),
        // link: apiPath?.storeUrl,
        link: mainNavigationData?.header?.mainNav?.STORES?.link,
      },
      {
        title: GIFT_CARDS?.toUpperCase(),
        link: mainNavigationData?.header?.mainNav?.GIFT_CARDS?.link,
      },
    ];
  }, [
    CONTACT_LENS,
    EYEGLASSES,
    GIFT_CARDS,
    NEW_ARRIVALS,
    STORES,
    SUNGLASSES,
    COLLECTIONS,
    mainNavigationData,
  ]);

  const fetchMenu = data => {
    if (data.type === 'EYEGLASSES') {
      return data.map(data => {
        const subParentKey = data;
        return Object.keys(data).map(key => {
          return (
            <div key={v4()} className={`od-nav-level-2 nav-${section}`}>
              {data[key] && (
                <GenderMenuOD
                  dataLocale={dataLocale}
                  genderMenuData={data[key]}
                  menuData={subParentKey}
                  parentKey={key}
                  section={section}
                />
              )}
            </div>
          );
        });
      });
    }

    if (data.type === 'CONTACT_LENS') {
      return (
        <div className="contact-lens-cards">
          {data.cards.map(card => {
            return (
              <ContactLensCard
                key={v4()}
                cardData={card}
                triggerDataLayerOnCtaClick={triggerDataLayerOnCtaClick}
              />
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      <section className={`${mainClass}-nav`}>
        <div className={`${mainClass}-nav__top`}>
          <div className={`${mainClass}-nav__top--left`}>
            <Logo appLogo={headerLogo} dataLocale={dataLocale} pathPrefix={localeInfo.pathPrefix} />
            {!(isPaymentPage || isShippingPage) && (
              <nav className={`${mainClass}-nav__nav`}>
                <ul className={`${mainClass}-nav__nav-links`}>
                  {navLinks.map(({ title, link, data }, index) => {
                    return link ? (
                      <>
                        <li key={v4()} onMouseEnter={() => handleMouseEnter(index)}>
                          <a
                            className={`${mainClass}-nav__nav-link od-font-reg`}
                            onClick={() => {
                              triggerDataLayerOnCtaClick(title);
                              if (link?.includes('/stores/sg')) {
                                window.location.href = link;
                              } else {
                                window.location.assign(link);
                              }
                            }}
                          >
                            {title}
                          </a>
                          {data && hoveredItem === index && (
                            <div className="od-submenu-d-wrapper" onMouseLeave={handleMouseLeave}>
                              <ul className="od-submenu-d">{fetchMenu(data)}</ul>
                            </div>
                          )}
                        </li>
                      </>
                    ) : (
                      <>
                        <li
                          key={v4()}
                          onMouseEnter={() => {
                            handleMouseEnter(index);
                          }}
                        >
                          <div
                            className={`${mainClass}-nav__nav-link od-font-reg`}
                            onClick={() => triggerDataLayerOnCtaClick(title)}
                          >
                            {title}
                          </div>
                          {data && hoveredItem === index && (
                            <div className="od-submenu-d-wrapper" onMouseLeave={handleMouseLeave}>
                              <ul className="od-submenu-d">{fetchMenu(data)}</ul>
                            </div>
                          )}
                        </li>
                      </>
                    );
                  })}
                </ul>
              </nav>
            )}
          </div>
          <div className={`${mainClass}-nav__top--right`}>
            {!(isPaymentPage || isShippingPage) && (
              <>
                {!showBookEyeTest && (
                  <div
                    className={`${mainClass}-nav__book-eye-test`}
                    onClick={() => {
                      if (window?.location?.href?.includes('preproduction')) {
                        window.location.href = 'https://preproduction.owndays.com/stores/sg';
                      } else {
                        window.location.href = 'https://www.owndays.com/stores/sg';
                      }
                    }}
                  >
                    <img
                      alt="Book Free Eye Test"
                      src="https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-book-eye-test.svg"
                    />
                    <div className={`${mainClass}-nav__book-eye-test__text od-font-reg`}>
                      Book Free Eye Test
                    </div>
                  </div>
                )}
                <SearchBar
                  {...props}
                  placeholder={SEARCH}
                  setShowBookEyeTest={setShowBookEyeTest}
                  triggerDataLayerOnCtaClick={triggerDataLayerOnCtaClick}
                />
              </>
            )}
            <div className={`${mainClass}-nav__top--right--info`}>
              {!(isPaymentPage || isShippingPage) && (
                <>
                  <div className={`${mainClass}-nav__countable`}>
                    <img
                      alt="true"
                      height="24px"
                      src={
                        secondaryNav
                          ? 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-heart-white-icon.svg'
                          : 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-heart-icon.svg'
                      }
                      width="24px"
                      onClick={showWishList}
                    />
                    {shortListData.result?.numberOfProducts > 0 && (
                      <div className="main-nav__counter">
                        {shortListData.result?.numberOfProducts}
                      </div>
                    )}
                  </div>
                  <div className={`${mainClass}-nav__countable`}>
                    <img
                      alt="true"
                      height="24px"
                      src={
                        secondaryNav
                          ? 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-cart-white-icon.svg'
                          : 'https://static.lenskart.com/media/owndays/desktop/img/od-misc/od-cart-icon.svg'
                      }
                      width="24px"
                      onClick={cartClickHandler}
                    />
                    {cartData.result.itemsQty > 0 && (
                      <div className={`${mainClass}-nav__counter`}>{cartData.result.itemsQty}</div>
                    )}
                  </div>
                </>
              )}
              {parsedUserInformation?.name ? (
                <div className="auth-panel">
                  <div className="secondary-nav__login od-font-bold">
                    {' '}
                    {parsedUserInformation?.name}{' '}
                    <i className="fa fa-caret-down auth-panel__dropdown-toggler"></i>
                  </div>
                  <div className="od-font-bold">
                    <div className="navbar-dropdown-menu auth-panel__dropdown-menu auth-panel__dropdown-menu--bottom auth-panel__dropdown-menu--left">
                      <div className="auth-panel__dropdown-content">
                        <div>
                          <a
                            className="dropdown-list auth-panel__dropdown-list"
                            onClick={() => (window.location.href = '/sg/en/customer/account')}
                          >
                            Account Information
                          </a>
                          <a
                            className="dropdown-list auth-panel__dropdown-list"
                            onClick={() => {
                              commonCallsAction.logoutUser();
                              registerActions.logoutUser();
                              cartActions.logoutUser();
                              window.location.href = '/';
                            }}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="secondary-nav__login od-font-bold"
                  onClick={() => {
                    history.push({
                      pathname: '/customer/account/login',
                      state: { prevUrl: window.location.pathname },
                    });
                  }}
                >
                  Login
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {showAuthPopUp && isNewAuthPopUpAvailable && (
        <AuthModalNewLoadable
          allowSignUp
          hideLogin={hideAuthPopUp}
          hideSuccessModal={() => setShowAuthPopUp(false)}
          login={login}
          openTab={registeredSuccessfully ? 'successSignUp' : openTab}
        />
      )}

      {!newCartFlowEnable && showCart && (
        <ErrorBoundaries>
          <Cart cartOmniture={data => cartOmniture(data)} hideHandler={hideCart} />
        </ErrorBoundaries>
      )}
    </>
  );
};

MainNav.propTypes = {
  redisCommonData: PropTypes.any,
  login: PropTypes.bool,
  loginStatus: PropTypes.bool,
  shortListData: PropTypes.object,
  userInfo: PropTypes.any,
  toggleCart: PropTypes.func,
};

const mapStateToProps = state => ({
  redisCommonData: state.common.redisCommonData,
  dataLocale: state.locale,
  login: state.common.login,
  loginStatus: PropTypes.bool,
  askUserLogin: state.common.askUserLogin,
  toggleCart: state.common.toggleCart,
  isCartCount: state.common.isCartCount,
  userInfo: state.common.userInfo,
  localeInfo: state.common.localeInfo,
  appLogo: state.common.appLogo,
  cartData: state.cart.cartData,
  showCart: state.common.showCart,
  register: state.auth.register,
  loginSuccess: state.auth.loginSuccess,
});

function mapDispatchToProps(dispatch) {
  return {
    commonCallsAction: bindActionCreators(commonCallsActionCreators, dispatch),
    registerActions: bindActionCreators(registerActions, dispatch),
    cartActions: bindActionCreators(cartActions, dispatch),
    dittoCallsAction: bindActionCreators(dittoCallsActionCreators, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNav);
