import * as actionTypes from './../../actionTypes/hto';

const initialState = {
  locationServiceableDetails: null,
};

export default function hto(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_HTO_CONFIG:
      return Object.assign({}, prevState, {
        loadingHTOConfig: true,
        htoConfig: undefined,
      });
    case actionTypes.GET_HTO_CONFIG_SUCCESS:
      return Object.assign({}, prevState, {
        loadingHTOConfig: false,
        htoConfig: action.data,
      });
    case actionTypes.GET_HTO_CONFIG_FAIL:
      return Object.assign({}, prevState, {
        loadingHTOConfig: false,
        htoConfig: undefined,
      });
    case actionTypes.RESET_ERROR_LOCATION_SERVICE_DETAILS:
      return Object.assign({}, prevState, {
        loadingLocationServiceableDetails: false,
        locationServiceableDetails: null,
        googleZeroResult: action.flag,
      });
    case actionTypes.GET_LOCATION_SERVICE_DETAILS:
      return Object.assign({}, prevState, {
        loadingLocationServiceableDetails: true,
        locationServiceableDetails: null,
        googleZeroResult: action.flag,
        cancelledOrder: null,
      });
    case actionTypes.GET_LOCATION_SERVICE_DETAILS_SUCCESS:
      return Object.assign({}, prevState, {
        loadingLocationServiceableDetails: false,
        locationServiceableDetails: action.data.result,
      });
    case actionTypes.GET_LOCATION_SERVICE_DETAILS_FAIL:
      return Object.assign({}, prevState, {
        loadingLocationServiceableDetails: false,
        locationServiceableDetails: undefined,
      });
    case actionTypes.GET_SLOTS:
      return Object.assign({}, prevState, {
        loadingSlotDetails: true,
        slotDetails: null,
        cancelledOrder: null,
      });
    case actionTypes.GET_SLOTS_SUCCESS:
      return Object.assign({}, prevState, {
        loadingSlotDetails: false,
        slotDetails: action.result,
      });
    case actionTypes.GET_SLOTS_FAIL:
      return Object.assign({}, prevState, {
        loadingSlotDetails: false,
        slotDetails: null,
      });
    case actionTypes.GET_CANCEL_REASONS:
      return Object.assign({}, prevState, {
        cancelledOrder: null,
        loadingAppointmentCancelReasons: true,
        appointmentCancelReasons: null,
      });
    case actionTypes.GET_CANCEL_REASONS_SUCCESS:
      return Object.assign({}, prevState, {
        loadingAppointmentCancelReasons: false,
        appointmentCancelReasons: action.data && action.data.reasons,
      });
    case actionTypes.GET_CANCEL_REASONS_FAIL:
      return Object.assign({}, prevState, {
        loadingAppointmentCancelReasons: false,
        appointmentCancelReasons: null,
      });
    case actionTypes.CANCEL_HTO_ORDER_LOAD:
      return Object.assign({}, prevState, {
        processingCancelOrder: true,
        cancelledOrder: null,
      });
    case actionTypes.CANCEL_HTO_ORDER_SUCCESS:
      return Object.assign({}, prevState, {
        processingCancelOrder: false,
        cancelledOrder: action.result,
        appointmentInfo: null,
      });
    case actionTypes.CANCEL_HTO_ORDER_FAIL:
      return Object.assign({}, prevState, {
        processingCancelOrder: false,
        cancelledOrder: action.error,
      });
    case actionTypes.GET_AGENT_INFO:
      return Object.assign({}, prevState, {
        loadingAgentInfo: true,
        agentInfo: null,
      });
    case actionTypes.GET_AGENT_INFO_SUCCESS:
      return Object.assign({}, prevState, {
        loadingAgentInfo: false,
        agentInfo: action.result,
      });
    case actionTypes.GET_AGENT_INFO_FAIL:
      return Object.assign({}, prevState, {
        loadingAgentInfo: false,
        agentInfo: null,
      });
    case actionTypes.BOOK_APPOINTMENT:
      return Object.assign({}, prevState, {
        isBookingProcessing: true,
        appointmentInfo: null,
      });
    case actionTypes.BOOK_APPOINTMENT_SUCCESS:
      return Object.assign({}, prevState, {
        isBookingProcessing: false,
        appointmentInfo: action.result,
      });
    case actionTypes.BOOK_APPOINTMENT_FAIL:
      return Object.assign({}, prevState, {
        isBookingProcessing: false,
        appointmentInfo: action.error,
      });
    default:
      return prevState;
  }
}
