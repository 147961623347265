import React from 'react';
import CrossIcon from './../../../../static/assets/svg/Cross.svg';
import './SelectLensesHeader.scss';

const SelectLensesHeader = ({ onBack, onCross, steps, currentStep }) => {
  return (
    <div className="odslv-modal-h">
      <div
        className="odslv-modal-h__left"
        onClick={() => {
          if (onBack && currentStep !== 'select_prescription') {
            onBack();
          }
        }}
      >
        {currentStep !== 'select_prescription' && (
          <svg
            fill="none"
            height="20"
            viewBox="0 0 20 20"
            width="20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12.5 15L7.5 10L12.5 5" stroke="#546073" strokeLinecap="square" />
          </svg>
        )}{' '}
        <span style={{ cursor: currentStep !== 'select_prescription' ? 'pointer' : 'default' }}>
          {steps?.[currentStep]?.label}
        </span>
      </div>
      <div className="odslv-modal-h__right" onClick={() => onCross && onCross()}>
        <img alt="back" src={CrossIcon} />
      </div>
    </div>
  );
};

export default SelectLensesHeader;
