import React, { useEffect, useState } from 'react';
import './OdApplyCoupon.scss';
import OdCouponItems from './OdCouponItems';
import { connect } from 'react-redux';
import { sendValidateOtp } from '../../../../../actionCreators/auth';
import { applyCode } from '../../../../../actionCreators/cart';
import { setCurrentWhatsappStatus } from '../../../../../actionCreators/gupshupWhatsapp';
import config from '../../../../../config';
import { odPushGA4Event } from '../../../../../utils/gaFour';
import AuthModalNewLoadable from '../../../AuthModalNew/AuthModalNewLoadable';
import OdTicketIcon from '../../../../../../static/assets/svg/od-ticket-icon.svg';

const OdApplyCoupon = props => {
  const {
    cartObj,
    dataLocale,
    cartError,
    login,
    userInfo,
    platform,
    isMobile,
    setCouponDetails,
    style = {},
  } = props;

  const { ADD_COUPON } = dataLocale;

  const [coupon, setCoupon] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [showSideBar, setShowSideBar] = useState(
    !!(cartError && cartError?.status !== 500 && cartError?.isGvApplied && login && couponError)
  );
  const [gvList, setGvList] = useState([]);

  useEffect(() => {
    if (coupon && setCouponDetails) {
      setCouponDetails({ coupon });
    }
  }, [coupon, setCouponDetails]);

  const buttonStylesMobile = {
    backgroundColor: '#FAFAFA',
    padding: '10px 20px',
    justifyContent: 'space-between',
    gap: 'unset',
    color: '#546073',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.28px',
  };

  const handleShowCoupons = value => {
    if (!login) {
      if (isMobile) {
        window.location.href = `/sg/en/customer/account/login`;
      } else {
        window.location.href = `/sg/en/customer/account/login`;
      }
    } else {
      if (value) {
        odPushGA4Event('page_view', {
          screenName: 'apply-coupon-page',
          userInfo,
          platform,
        });
      }

      setShowSideBar(value);
    }
  };

  const handleSetCoupon = value => {
    odPushGA4Event('cta_click', {
      userInfo,
      platform,
      ctaName: 'coupon-applied-error',
      ctaFlowAndPage: 'apply-coupon-page',
    });

    setCoupon(value);
  };

  // for persist on reload...
  useEffect(() => {
    if (cartObj && cartObj?.totals && cartObj?.totals?.discounts) {
      let gvCode;

      cartObj?.totals?.discounts.forEach(item => {
        if (item.type === 'gv') {
          gvCode = item.code;

          setCoupon(gvCode);
        }
      });
    }
  }, [cartObj]);

  useEffect(() => {
    if (cartObj) {
      setGvList(cartObj && cartObj?.applicableGvs);
    }
  }, [cartObj]);

  return (
    <>
      {showLogin && (
        <AuthModalNewLoadable
          allowSignUp
          handleModalClose={() => setShowLogin(false)}
          hideLogin={() => setShowLogin(false)}
          login={login}
          openTab="signin"
        />
      )}
      <div className="od-apply-coupon" style={style}>
        <div
          className={`od-apply-coupon__button od-font-reg ${
            !login && 'od-apply-coupon__button--unauthorized'
          }`}
          onClick={() => handleShowCoupons(true)}
        >
          <img alt="od-ticket-icon" className="od-apply-coupon__icon" src={OdTicketIcon} />
          {ADD_COUPON}
        </div>

        {showSideBar && (
          <div className="apply-coupon-cart">
            <div className="od-apply-coupon__backdrop" onClick={() => handleShowCoupons(false)} />
            <div className={`od-apply-coupon__sidebar--${isMobile ? 'm' : 'd'}`}>
              <OdCouponItems
                cartError={cartError}
                coupon={coupon}
                couponError={couponError}
                gvList={gvList}
                handleSetCoupon={handleSetCoupon}
                setCouponError={setCouponError}
                setShowSideBar={setShowSideBar}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  applyCoupon: code =>
    dispatch(applyCode(`${config.apiPath.applyGV}${code}?applyWallet=false`, 'post')),
  deleteCoupon: code =>
    dispatch(applyCode(`${config.apiPath.applyGV}${code}?applyWallet=false`, 'delete')),
  sendValidateOtp: data => dispatch(sendValidateOtp(data)),
  setCurrentWhatsappStatus: status => dispatch(setCurrentWhatsappStatus(status)),
});

const mapStateToProps = state => {
  const cartData = state.cart.cartData ? state.cart.cartData.result : null;

  return {
    cartData,
    cartError: state.cart.cartError,
    currencyMark: state.common.currencyMark,
    dataLocale: state.locale,
    isCartUpdating: state.cart.isCartLoader,
    login: state.common.login,
    localeInfo: state.common.localeInfo,
    userInfo: state.common.userInfo,
    platform: state.DTM.platform,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OdApplyCoupon);
