import * as actionTypes from './../../actionTypes/returnExchange';

const initialState = {
  headerTitle: null,
  hideNav: false,
  loading: false,
  userAddress: '',
  exchangeAddress: '',
  // return
  orderReturnableStatus: null,
  returnProducts: [],
  nearbyStore: [],
  returnModes: {},
  returnDetails: null,
  isAddressServiceable: null,
  returnLabelGenerated: null,
  // exchange
  isExchange: false,
  paymentData: null,
  paymentMethods: null,
  // post return
  itemReturnDetails: null,
  returnActionsDetail: null,
  exchangeOrderId: null,
  exchangeItemId: null,
  // refund
  refundDetails: null,
  refundEstimateData: null,
  returnEligibiliyDetails: null,
  returnProductItemDetails: null,
  currentReturnItem: null,
  exchangeNewFlow: false,
  returnCancelLoading: false,
  returnCancelFail: false,
  returnCancelStatus: null,
};

// orderReturnableStatus: {
//   "id": 0,
//   "isFraud": false,
//   "items": [
//     {
//       "id": 231661,
//       "returnable": true
//     }
//   ],
//   "returnable": true
// }

// returnDetails: {
//   "result": {
//     "success": true,
//     "groupId": 1623958,
//     "returnLabelCreated": false,
//     "returns": [{ "id": 231661, "returnId": 7136665 }]
//   },
//   "status": 200,
//   "traceId": "0991606387577446"
// }

export default function returnExchange(prevState = initialState, action) {
  let xApiClient = 'desktop';
  // conditional imports on compilation
  ('#if CLIENT_TYPE === "mobile"'); // eslint-disable-line
  xApiClient = 'mobilesite';
  ('#endif'); // eslint-disable-line
  switch (action.type) {
    case actionTypes.HEADER_UPDATE:
      return Object.assign({}, prevState, {
        headerTitle: action.title,
      });
    case actionTypes.HIDE_NAV:
      return Object.assign({}, prevState, {
        hideNav: action.flag !== false,
      });
    case actionTypes.SET_RETURN_PRODUCTS:
      return Object.assign({}, prevState, {
        returnProducts: action.products,
      });
    case actionTypes.MERGE_RETURN_DETAILS:
      return Object.assign({}, prevState, {
        returnDetails: action.data,
      });
    case actionTypes.STORE_LOCATION_LOAD:
      return Object.assign({}, prevState, {
        loadingNearByStore: true,
      });
    case actionTypes.STORE_LOCATION_SUCCESS:
      return Object.assign({}, prevState, {
        nearbyStore: action.data,
        loadingNearByStore: false,
      });
    case actionTypes.STORE_LOCATION_FAIL:
      return Object.assign({}, prevState, {
        nearbyStore: action.error,
        loadingNearByStore: false,
      });
    case actionTypes.USER_ADDRESS_UPDATE:
      return Object.assign({}, prevState, {
        userAddress: action.address,
      });
    case actionTypes.EXCHANGE_ADDRESS_UPDATE:
      return Object.assign({}, prevState, {
        exchangeAddress: action.address,
      });
    case actionTypes.STORE_ADDRESS_UPDATE:
      return Object.assign({}, prevState, {
        storeAddress: action.address,
      });
    case actionTypes.CREATE_RETURN_LOAD:
      return Object.assign({}, prevState, {
        createReturnLoading: true,
        loading: true,
      });
    case actionTypes.CREATE_RETURN_SUCCESS:
      return Object.assign({}, prevState, {
        returnDetails: action.data,
        createReturnLoading: false,
        loading: false,
      });
    case actionTypes.CREATE_RETURN_FAIL:
      return Object.assign({}, prevState, {
        returnDetails: action.error,
        createReturnLoading: false,
        loading: false,
      });
    case actionTypes.GET_RETURN_REASONS:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_RETURN_REASONS_SUCCESS:
      return Object.assign({}, prevState, {
        returnReasons: action.data,
        loading: false,
      });
    case actionTypes.GET_RETURN_REASONS_ARR_SUCCESS:
      return Object.assign({}, prevState, {
        returnReasonsArr: action.data,
        loading: false,
      });
    case actionTypes.GET_RETURN_REASONS_FAIL:
      return Object.assign({}, prevState, {
        returnReasonsErr: action.error,
        loading: false,
      });
    case actionTypes.GET_RETURN_MODES_LOAD:
      return Object.assign({}, prevState, {
        returnModes: {},
        loading: true,
      });
    case actionTypes.GET_RETURN_MODES_SUCCESS:
      return Object.assign({}, prevState, {
        returnModes: action.data.result,
        loading: false,
      });
    case actionTypes.GET_RETURN_MODES_FAIL:
      return Object.assign({}, prevState, {
        returnModes: action.error,
        loading: false,
      });
    case actionTypes.GET_SERVICEABLE_ADDRESS_LOAD:
      return Object.assign({}, prevState, {
        isAddressServiceable: null,
        loading: true,
      });
    case actionTypes.GET_SERVICEABLE_ADDRESS_SUCCESS:
      return Object.assign({}, prevState, {
        isAddressServiceable: action.data.result.serviceable,
        loading: false,
      });
    case actionTypes.GET_SERVICEABLE_ADDRESS_FAIL:
      return Object.assign({}, prevState, {
        isAddressServiceable: false,
        loading: false,
      });
    case actionTypes.GET_RETURN_DETAILS_LOAD:
      return Object.assign({}, prevState, {
        itemReturnDetails: null,
        loading: true,
      });
    case actionTypes.GET_RETURN_DETAILS_SUCCESS:
      return Object.assign({}, prevState, {
        itemReturnDetails: action.data,
        loading: false,
      });
    case actionTypes.GET_RETURN_DETAILS_FAIL:
      return Object.assign({}, prevState, {
        itemReturnDetails: action.error,
        loading: false,
      });
    case actionTypes.GET_RETURN_ACTIONS_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_RETURN_ACTIONS_SUCCESS:
      return Object.assign({}, prevState, {
        returnActionsDetail: action.data,
        loading: false,
      });
    case actionTypes.GET_RETURN_ACTIONS_FAIL:
      return Object.assign({}, prevState, {
        returnActionsDetail: action.error,
        loading: false,
      });
    case actionTypes.GET_PAYMENT_DATA_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_PAYMENT_DATA_SUCCESS:
      return Object.assign({}, prevState, {
        paymentData: action.data.paymentData,
        loading: false,
      });
    case actionTypes.GET_PAYMENT_DATA_FAIL:
      return Object.assign({}, prevState, {
        paymentData: action.error,
        loading: false,
      });
    case actionTypes.GET_PAYMENT_METHODS_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_PAYMENT_METHODS_SUCCESS:
      return Object.assign({}, prevState, {
        paymentMethods: action.data,
        loading: false,
      });
    case actionTypes.GET_PAYMENT_METHODS_FAIL:
      return Object.assign({}, prevState, {
        paymentMethods: action.error,
        loading: false,
      });
    case actionTypes.CREATE_REFUND_LOAD:
      return Object.assign({}, prevState, {
        createRefundLoading: true,
        loading: true,
      });
    case actionTypes.CREATE_REFUND_SUCCESS:
      return Object.assign({}, prevState, {
        refundDetails: action.data,
        createRefundLoading: false,
        loading: false,
      });
    case actionTypes.CREATE_REFUND_FAIL:
      return Object.assign({}, prevState, {
        refundDetails: action.error,
        createRefundLoading: false,
        loading: false,
      });
    case actionTypes.SET_IS_EXCHANGE:
      return Object.assign({}, prevState, {
        isExchange: (xApiClient === 'mobilesite' && action.flag) || false,
      });
    case actionTypes.SET_EXCHANGE_ORDER_AND_ITEM:
      return Object.assign({}, prevState, {
        exchangeOrderId: action.orderId,
        exchangeItemId: action.itemId,
      });
    case actionTypes.SET_EXCHANGE_RETURN_CLEAN:
      return Object.assign({}, initialState);
    case actionTypes.GET_REFUND_ESTIMATE_LOAD:
      return Object.assign({}, prevState, {
        loading: true,
      });
    case actionTypes.GET_REFUND_ESTIMATE_SUCCESS:
      return Object.assign({}, prevState, {
        refundEstimateData: action.data,
        loading: false,
      });
    case actionTypes.GET_REFUND_ESTIMATE_FAIL:
      return Object.assign({}, prevState, {
        refundEstimateData: action.error,
        loading: false,
      });
    case actionTypes.GET_ELIGIBILITY_RETURN_STATUS:
      return Object.assign({}, prevState, { loading: true, orderReturnableStatus: null });
    case actionTypes.GET_ELIGIBILITY_RETURN_STATUS_SUCCESS:
      return Object.assign({}, prevState, { loading: false, orderReturnableStatus: action.data });
    case actionTypes.GET_ELIGIBILITY_RETURN_STATUS_FAIL:
      return Object.assign({}, prevState, { loading: false, orderReturnableStatus: action.error });
    case actionTypes.GET_RETURN_LABEL_STATUS_LOAD:
      return Object.assign({}, prevState, {
        returnLabelGenerated: null,
      });
    case actionTypes.GET_RETURN_LABEL_STATUS_SUCCESS:
      return Object.assign({}, prevState, {
        returnLabelGenerated: action.data && action.data.success,
      });
    case actionTypes.GET_RETURN_LABEL_STATUS_FAIL:
      return Object.assign({}, prevState, {
        returnLabelGenerated: false,
      });
    case actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY:
      return Object.assign({}, prevState, {
        returnEligibiliyDetailsLoading: true,
        returnEligibiliyDetails: null,
      });
    case actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY_SUCCESS:
      return Object.assign({}, prevState, {
        returnEligibiliyDetailsLoading: false,
        returnEligibiliyDetails: action.data,
      });
    case actionTypes.GET_ORDERITEMS_RETURNELIGIBILITY_FAIL:
      return Object.assign({}, prevState, {
        returnEligibiliyDetailsLoading: false,
        returnEligibiliyDetails: action.error,
      });
    case actionTypes.SET_RETURN_ITEM_DETAILS:
      return Object.assign({}, prevState, {
        returnProductItemDetails: action.data,
      });
    case actionTypes.SET_CURRENT_RETURN_ITEM:
      return Object.assign({}, prevState, {
        currentReturnItem: action.data,
      });
    case actionTypes.SET_NEW_EXCHANGE_FLOW:
      return Object.assign({}, prevState, {
        exchangeNewFlow: action.flag || false,
      });
    case actionTypes.RETURN_CANCEL_LOAD:
      return Object.assign({}, prevState, {
        returnCancelLoading: true,
        returnCancelStatus: null,
      });
    case actionTypes.RETURN_CANCEL_SUCCESS:
      return Object.assign({}, prevState, {
        returnCancelLoading: false,
        returnCancelStatus: action.data,
      });
    case actionTypes.RETURN_CANCEL_FAIL:
      return Object.assign({}, prevState, {
        returnCancelLoading: false,
        returnCancelStatus: action.error,
      });
    case actionTypes.SHOW_LOADER:
      return Object.assign({}, prevState, {
        isLoading: true,
      });
    case actionTypes.HIDE_LOADER:
      return Object.assign({}, prevState, {
        isLoading: false,
      });
    default:
      return prevState;
  }
}
