import { call, put, all, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes/productCarousel';
import config from 'config';
import apiClient from '../helpers/apiClient';
import { getProductCarouselState } from '../selectors/productCarousel';

let _client = null;

function updateCarouselDataState(productCarousel, categoryId, productList) {
  if (!productCarousel) productCarousel = {};
  productCarousel = Object.assign({}, productCarousel);
  productCarousel[categoryId] = productList || [];
  return productCarousel;
}

function* getProductCarouselData({ categoryId, count }) {
  const successAction = { type: actionTypes.PRODUCTCAROUSEL_DATA_SUCCESS };
  const errorAction = { type: actionTypes.PRODUCTCAROUSEL_DATA_FAIL };
  const client = { ..._client };
  client.setHeader();
  try {
    const data = yield call(async () =>
      _client.get(`${config.apiPath.dataCategory}${categoryId}?page-size=${count}&page=0`)
    );
    const productCarousel = yield select(getProductCarouselState);
    if (data.result) {
      data.result = updateCarouselDataState(productCarousel, categoryId, data.result.product_list);
    }
    successAction.data = data;
    yield put(successAction);
  } catch (er) {
    const productCarousel = yield select(getProductCarouselState);
    errorAction.data = updateCarouselDataState(productCarousel, categoryId);
    yield put(errorAction);
  }
}

export function* runProductCarouselCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeEvery(actionTypes.PRODUCTCAROUSEL_DATA_LOAD, getProductCarouselData)]);
}
