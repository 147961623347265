import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './BundleSale.scss';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="bundle-sale__modal-overlay" onClick={onClose}>
      <div className="bundle-sale__modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="bundle-sale__modal-close" onClick={onClose}>
          <img src='https://static.lenskart.com/media/img/news/owndays-contact-lens-promotion/icon-close.svg' alt='Icon close'/>
        </button>
        {children}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;