import React from 'react';
const Sns = ({ baseImgUrl, baseUrl, pageName }) => {
  const socialMediaLinks = [
    {
      platform: 'Facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${baseUrl}news/${pageName}`,
      imgSrc: 'fb-icon.svg',
      alt: 'Facebook',
    },
    {
      platform: 'Twitter',
      url: `https://twitter.com/intent/tweet?url=${baseUrl}news/${pageName}`,
      imgSrc: 'tw-icon.svg',
      alt: 'X',
    },
  ];
  return (
    <section className="l-huawei2-sns">
      <div className="l-huawei2__container">
        <h2 className="l-huawei2__heading l-huawei2__heading--sm l-huawei2__heading--center">
          SHARE
        </h2>
        <ul className="list-sns">
          {socialMediaLinks.map((link, index) => (
            <li key={index}>
              <a href={link.url} rel="noopener noreferrer" target="_blank" title={link.platform}>
                <img alt={link.alt} src={`${baseImgUrl}${link.imgSrc}`} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Sns;
