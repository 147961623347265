import PropTypes from 'prop-types';
import React from 'react';
import CommonLoader from '../../Common/Loader';
import CartItem from './CartItem';
import Customevent from '../../../../utils/Customevent';
import { AddToCartTikTokEvent } from '../../../../utils/tiktokPixel';
import './Cart.scss';
import './CartItems.scss';
import { v4 } from 'uuid';
import RenderCartFooter from './RenderCartFooter';
import CartSummary from './CartSummary';

class CartItems extends React.PureComponent {
  static propTypes = {
    isCartLoader: PropTypes.any,
    section: PropTypes.string,
    isAlreadyLoyal: PropTypes.bool,
    cartObj: PropTypes.object,
    dtmStateLoad: PropTypes.func,
    redisCommonData: PropTypes.object,
    cartActions: PropTypes.object,
    isframeProduct: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    if (this.props.redisCommonData) {
      const checkout = this.props.redisCommonData.CONFIG_CTA_DESKTOP
        ? JSON.parse(this.props.redisCommonData.CONFIG_CTA_DESKTOP)
        : '';
      this.checkoutCTA = checkout.CHECKOUT;
    }
  }

  componentDidMount() {
    const { cartObj, redisCommonData, isAlreadyLoyal } = this.props;
    const pageInfo = `${document.location.pathname}${document.location.search} |time:  Date()`;
    const loyaltyConfig =
      redisCommonData &&
      redisCommonData.LOYALTY_CONFIG &&
      JSON.parse(redisCommonData.LOYALTY_CONFIG);
    _gaq.push(['_trackEvent', 'A2C Popup', 'Render Main Cart', `page: ${pageInfo}`]);
    _gaq.push(['ninja._trackEvent', 'A2C Popup', 'Render Main Cart', `page: ${pageInfo}`]);
    // Removing GV in checkout step 3 componentwillunmount
    // cartObj.totals.discounts.forEach(data => {
    //     if (data.type === 'gv') {
    //         cartActions.applyCode(config.apiPath.applyGV + data.code, 'delete');
    //     }
    // });
    /* Remove This ASAP */
    const isLoyaltyBrand =
      (loyaltyConfig && loyaltyConfig.brandsAllowed) || 'Vincent Chase,John Jacobs';
    let isLoyaltyProductAdded = false;
    let isLoyaltySunglass = false;
    if (cartObj && cartObj.items.length) {
      cartObj.items.forEach(item => {
        if (item.classification === 'loyalty_services') {
          isLoyaltyProductAdded = true;
        }
        if (
          'sunglasses'?.indexOf(item.classification) > -1 &&
          isLoyaltyBrand?.indexOf(item.brandName) > -1
        ) {
          isLoyaltySunglass = true;
        }
      });
    }

    const event = new Customevent('cartPopupOpened', {
      detail: {
        isAlreadyLoyal,
        isLoyaltyProductAdded,
        loyaltyPid: (loyaltyConfig && loyaltyConfig.goldPid) || '128269',
        isLoyaltySunglass,
      },
    });
    event.dispatch();
  }

  deleteCartItem = data => {
    const { cartActions, dtmStateLoad, section } = this.props;
    cartActions.updateCartItem(data.id, 0, 'delete');
    sessionStorage.setItem('cartItem', 'delete');
    sessionStorage.setItem('dtmOnLoadData', 'cartSummary');
    dtmStateLoad(false);
  };

  decrementItem = data => {
    const { cartActions, dtmStateLoad } = this.props;
    cartActions.updateCartItem(data.id, 1, 'decrease');
    sessionStorage.setItem('cartItem', 'decrease');
    sessionStorage.setItem('dtmOnLoadData', 'cartSummary');
    dtmStateLoad(false);
  };

  triggerTiktokEvent = data => {
    const { price } = data;
    AddToCartTikTokEvent({
      currency: price?.currencyCode,
      productId: data?.productId,
      productName: data?.classification,
      productPrice: price?.value,
    });
  };

  incrementItem = data => {
    const { cartActions, dtmStateLoad } = this.props;
    const { price } = data;
    cartActions.updateCartItem(data.id, 1, 'increase');
    sessionStorage.setItem('cartItem', 'increase');
    dtmStateLoad(true);
    window.ReactPixel.default.track('AddToCart', {
      content_type: 'product',
      content_ids: data?.productId,
      value: price?.value,
      content_name: data?.classification,
      currency: price?.currencyCode,
    });
    this.triggerTiktokEvent(data);
    if (window.dtm.LenskartRewamp) {
      window.dtm.LenskartRewamp.slideCart.click.incrementItemProduct(data);
    }
  };

  render() {
    const {
      redisCommonData,
      cartObj,
      isframeProduct,
      dataLocale,
      currencyMark,
      localeInfo,
      login,
    } = this.props;
    const currencyCode =
      cartObj && cartObj.currencyCode ? cartObj.currencyCode : dataLocale.CURRENCY_CODE;
    const currencySymbol = currencyMark[currencyCode];
    const { ORDER_TOTAL } = dataLocale || {};
    const loyaltyConfig =
      redisCommonData &&
      redisCommonData.LOYALTY_CONFIG &&
      JSON.parse(redisCommonData.LOYALTY_CONFIG);
    let isLoyaltyProductAdded = false;
    const {
      classificationsAllowed: isLoyaltyClsfn = 'eyeframe',
      brandsAllowed: isLoyaltyBrand = 'Vincent Chase,John Jacobs',
    } = loyaltyConfig || {};
    let isProductLoyaltyEnabled = false;
    return (
      <div className="cart-content crt-prd-block">
        <div style={{ maxHeight: '272px', overflow: 'auto' }}>
          {cartObj.items.map((data, key) => {
            if (!isLoyaltyProductAdded && data.classification === 'loyalty_services') {
              isLoyaltyProductAdded = true;
            }
            if (
              isLoyaltyClsfn?.indexOf(data.classification) > -1 &&
              isLoyaltyBrand?.indexOf(data.brandName) > -1
            ) {
              if (!isProductLoyaltyEnabled) {
                isProductLoyaltyEnabled = true;
              }
            }
            return (
              <div key={v4()} className="chkout-cart-region row-fluid">
                {this.props.isCartLoader && <CommonLoader />}
                <CartItem
                  currencyMark={currencyMark}
                  data={data}
                  dataLocale={dataLocale}
                  decrementItem={() => {
                    this.decrementItem(data);
                  }}
                  deleteCartItem={() => {
                    this.deleteCartItem(data);
                  }}
                  incrementItem={() => {
                    this.incrementItem(data);
                  }}
                  index={key}
                  localeInfo={localeInfo}
                  login={login}
                />
              </div>
            );
          })}
        </div>
        <CartSummary cartObj={cartObj} currencySymbol={currencySymbol} dataLocale={dataLocale} />
        <div className="row-fluid f23">
          <div className="span6 upy-block">
            <span className="span6">{ORDER_TOTAL} :</span>
            <span className="span6 color-yellow text-left">
              {currencySymbol}
              {cartObj.totals.total}
            </span>
          </div>
          <RenderCartFooter
            cartObj={cartObj}
            checkoutCTA={this.checkoutCTA}
            dataLocale={dataLocale}
            isframeProduct={isframeProduct}
            redisCommonData={redisCommonData}
          />
        </div>
      </div>
    );
  }
}

CartItems.defaultProps = {
  redisCommonData: {},
  cartObj: {},
};

export default CartItems;
