// Helper function to generate SKUs for products
const generateSkus = (productNo, colors) => {
  return colors.map((color, index) => ({
    productSrc: `products/${productNo}_C${index + 1}.webp`,
    colorSrc: `colors/${color.src}.webp`,
    colorAlt: color.alt,
    href: `/sg/en/black-collection-product_${productNo}_C${index + 1}`,
  }));
};

// Helper function to generate image data
const generateImages = (baseName) => [
  {
    imgSrcPc: `${baseName}-1_pc.webp`,
    imgSrcSp: `${baseName}-1_sp.webp`,
    imgAlt: '',
  },
  {
    imgSrcPc: `${baseName}-2_pc.webp`,
    imgSrcSp: `${baseName}-2_sp.webp`,
    imgAlt: '',
  },
];

export const LINEUP = [
  {
    title: 'METAL',
    des: 'Simple yet effortlessly elegant, these metal frames add a touch of sophistication to your ensemble while complementing any style seamlessly. Designed for the discerning adults, they are the perfect combination of refined aesthetics and exceptional functionality.',
    images: generateImages('item-1'),
    products: [
      {
        no: 'OB1001G-4A',
        price: '198',
        defaultSku: 0,
        skus: generateSkus('OB1001G-4A', [
          { src: 'color-1', alt: 'C1' },
          { src: 'color-2', alt: 'C2' },
          { src: 'color-3', alt: 'C3' },
        ]),
      },
      {
        no: 'OB1002B-4A',
        price: '198',
        defaultSku: 0,
        skus: generateSkus('OB1002B-4A', [
          { src: 'color-4', alt: 'C1' },
          { src: 'color-5', alt: 'C2' },
          { src: 'color-6', alt: 'C3' },
        ]),
      },
      {
        no: 'OB1003X-4A',
        price: '138',
        defaultSku: 0,
        skus: generateSkus('OB1003X-4A', [
          { src: 'color-1', alt: 'C1' },
          { src: 'color-5', alt: 'C2' },
          { src: 'color-18', alt: 'C3' },
        ]),
      },
      {
        no: 'OB1004X-4A',
        price: '138',
        defaultSku: 0,
        skus: generateSkus('OB1004X-4A', [
          { src: 'color-1', alt: 'C1' },
          { src: 'color-2', alt: 'C2' },
          { src: 'color-20', alt: 'C3' },
        ]),
      },
    ],
  },
  {
    title: 'PLASTIC',
    des: 'Each plastic frame exudes its own distinct charm, transforming into a statement piece for any outfit. With an emphasis on silhouette and a material that is crafted for comfort, these frame selections are designed to showcase your unique flair in every setting.',
    images: generateImages('item-2'),
    products: [
      {
        no: 'OB2001B-4A',
        price: '178',
        defaultSku: 0,
        skus: generateSkus('OB2001B-4A', [
          { src: 'color-9', alt: 'C1' },
          { src: 'color-1', alt: 'C2' },
          { src: 'color-10', alt: 'C3' },
        ]),
      },
      {
        no: 'OB2002B-4A',
        price: '198',
        defaultSku: 0,
        skus: generateSkus('OB2002B-4A', [
          { src: 'color-4', alt: 'C1' },
          { src: 'color-6', alt: 'C2' },
          { src: 'color-5', alt: 'C3' },
        ]),
      },
      {
        no: 'OB2003N-4A',
        price: '138',
        defaultSku: 0,
        skus: generateSkus('OB2003N-4A', [
          { src: 'color-9', alt: 'C1' },
          { src: 'color-21', alt: 'C2' },
          { src: 'color-11', alt: 'C3' },
        ]),
      },
      {
        no: 'OB2004N-4A',
        price: '138',
        defaultSku: 0,
        skus: generateSkus('OB2004N-4A', [
          { src: 'color-9', alt: 'C1' },
          { src: 'color-12', alt: 'C2' },
          { src: 'color-22', alt: 'C3' },
        ]),
      },
    ],
  },
  {
    title: 'SUNGLASSES',
    des: 'Featuring popular designs such as photochromic lenses (also known as transition lenses) and OWNDAYS SNAP which effortlessly transforms from spectacles to sunglasses with a simple snap, sunglasses in this collection are defined by a sleek, black-colour theme. Equipped with UV protection, these eyewear pieces not only shield your eyes from intense sunlight but also promise a stylish edge.',
    images: generateImages('item-3'),
    products: [
      {
        no: 'OB1005X-4A',
        price: '198',
        defaultSku: 0,
        skus: generateSkus('OB1005X-4A', [
          { src: 'color-1', alt: 'C1' },
          { src: 'color-9', alt: 'C2' },
          { src: 'color-10', alt: 'C3' },
        ]),
      },
      {
        no: 'OB2005N-4A',
        price: '198',
        defaultSku: 0,
        skus: generateSkus('OB2005N-4A', [
          { src: 'color-1', alt: 'C1' },
          { src: 'color-15', alt: 'C2' },
          { src: 'color-16', alt: 'C3' },
        ]),
      },
      {
        no: 'OB1006G-4A',
        price: '78',
        defaultSku: 0,
        skus: generateSkus('OB1006G-4A', [
          { src: 'color-9', alt: 'C1' },
          { src: 'color-2', alt: 'C2' },
          { src: 'color-23', alt: 'C3' },
        ]),
      },
      {
        no: 'OB2006B-4A',
        price: '78',
        defaultSku: 0,
        skus: generateSkus('OB2006B-4A', [
          { src: 'color-9', alt: 'C1' },
          { src: 'color-24', alt: 'C2' },
          { src: 'color-10', alt: 'C3' },
        ]),
      },
    ],
  },
];

export const ACCESSORIES = [
  {
    imgSrc: ['accessories-1-1.webp', 'accessories-1-2.webp'],
    title: 'QUILTED CASE',
    price: 'S$15',
    description:
      'An original quilted multi-purpose case with a detachable shoulder strap and a spring closure for easy access. The compact size makes it perfect for carrying around.',
    href: '/sg/en/black-collection-OB-CASE001-4A',
  },
  {
    imgSrc: ['accessories-2-1.webp', 'accessories-2-2.webp'],
    title: 'GADGET CASE',
    price: 'S$20',
    description:
      'A semi-hard multi-purpose case designed to withstand external impacts. It features a convenient 2-way strap and an adjustable divider to keep your items organised and secured.',
    href: '/sg/en/black-collection-OB-CASE002-4A',
  },
  {
    imgSrc: ['accessories-3-1.webp', 'accessories-3-2.webp'],
    title: 'FRAGRANCE TAG',
    price: 'S$10',
    description: 'Black tea scented fragrance tag with a ribbon for you to hang it up.',
    href: '/sg/en/black-collection-OB-TAG001-4A',
  },
];

export const SHOPS = [
  'OWNDAYS Plaza Singapura',
  'OWNDAYS 313@somerset',
  'OWNDAYS Bedok Mall',
  'OWNDAYS nex',
  'OWNDAYS ION Orchard',
  'OWNDAYS Suntec City',
  'OWNDAYS White Sands',
  'OWNDAYS Jurong Point',
  'OWNDAYS Waterway Point',
  'OWNDAYS Compass One',
  'OWNDAYS Clementi Mall',
  'OWNDAYS Seletar Mall',
  'OWNDAYS Bukit Panjang Plaza',
  'OWNDAYS Causeway Point',
  'OWNDAYS Bugis Junction',
  'OWNDAYS The Woodleigh Mall',
  'OWNDAYS Northpoint City',
];

export const SNS = [
  {
    imgSrc: 'facebook-brands-solid-white.svg',
    imgAlt: 'Facebook',
    href: 'https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/black-collection',
  },
  {
    imgSrc: 'x-twitter-brands-solid-white.svg',
    imgAlt: 'X',
    href: 'https://twitter.com/intent/ttweet?url=https://www.owndays.com/sg/en/news/black-collection',
  },
  {
    imgSrc: 'line-brands-solid-white.svg',
    imgAlt: 'LINE',
    href: 'https://social-plugins.line.me/lineit/share?url=https://www.owndays.com/sg/en/news/black-collection',
  },
];
