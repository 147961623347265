import React from 'react';
import { SNS } from './constants';

const FooterBlackCollection = ({ iconUrl }) => {
  return (
    <section className="black-collection__sns">
      <div className="black-collection__container">
        <h3 className="black-collection__sns-title">
          <span>Share with</span>
          <strong>#OWNDAYS</strong>
          <strong>#BLACKCOLLECTION</strong>
        </h3>
        {SNS && (
          <ul className="black-collection__sns-ul">
            {SNS.map(item => (
              <li key={item.imgAlt}>
                <a href={item.href}>
                  <img src={`${iconUrl}/${item.imgSrc}`} alt={item.imgAlt} />
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default FooterBlackCollection;
