import React, { useEffect, useState } from 'react';
import './MembershipSection.scss';
import MembershipHeading from '../MembershipHeading/MembershipHeading';
import { v4 } from 'uuid';

const dataRefFriendMenu = [
  {
    text: 'Referral coupon is only valid for first-time eyewear purchase at OWNDAYS',
  },
  {
    text: 'Referral coupon is only valid with minimum spend of $98 on a single pair of eyewear and excludes collaboration merchandise',
  },
  {
    text: 'Referral Coupon is not valid in conjunction with other discounts, vouchers and/or promotions',
  },
  {
    text: 'You will only receive points rebate when your friend redeems the referral coupon for their first-time purchase',
  },
  {
    text: 'In the event the discount for same day purchase or any other existing promotion is higher than that of the referral coupon, the highest discount will be automatically applied at checkout and no referral points rebate will be awarded to the referrer.',
  },
];

const dataPointsMenu = [
  {
    text: 'Points will be issued immediately upon purchase at physical stores. For online purchase, points will be issued 7 days upon delivery.',
  },
  {
    text: 'Points are valid for 12 months from the month of issue.',
  },
];

const MembershipSection = ({ dataHeading, isPoints, isRefFriend, isCare }) => {
  const [listOfSection, setListOfSection] = useState(null);

  const isRefFriendOrCareSection = isRefFriend || isCare;

  useEffect(() => {
    if (isPoints) setListOfSection(dataPointsMenu);
    if (isRefFriend) setListOfSection(dataRefFriendMenu);
  }, [isPoints, isRefFriend]);

  return (
    <section className="od-membership-block">
      <MembershipHeading {...dataHeading} />
      <div className="od-membership-block__table">
        {isPoints && (
          <>
            <div className="od-membership-block__table-item">
              <div className="od-membership-block__table-item__content--points">
                <div className="od-membership-block__table-item__content__title od-font-bold">
                  Earn Points
                </div>
                <div className="od-membership-block__table-item__content__rebate">
                  <span className="text-bold od-font-bold">4% – 8% </span>
                  <span className="text-reg od-font-reg">Points Rebate</span>
                </div>
                <div className="od-membership-block__table-item__content__description od-font-reg">
                  for valid purchases made in-store and online
                </div>
                <div className="od-membership-block__table-item__content__rebate-description od-font-light">
                  Percentage of Points Rebate will vary depending on the Membership Tier
                </div>
              </div>
              <div className="od-membership-block__table-item__img">
                <img
                  alt="earn points"
                  height="100%"
                  src="https://static.lenskart.com/media/owndays/desktop/img/membership-earned-points.webp"
                  width="100%"
                />
              </div>
            </div>
            <div className="od-membership-block__table-item">
              <div className="od-membership-block__table-item__img">
                <img
                  alt="earn points"
                  height="100%"
                  src="https://static.lenskart.com/media/owndays/desktop/img/owndays/membership/membership-use-points.webp"
                  width="100%"
                />
              </div>
              <div className="od-membership-block__table-item__content--points">
                <div className="od-membership-block__table-item__content__title od-font-bold">
                  Use Points
                </div>
                <div className="od-membership-block__table-item__content__rebate">
                  <span className="text-bold od-font-bold">1 Point = S$ 1</span>
                </div>
                <div className="od-membership-block__table-item__content__description od-font-reg">
                  Offset your purchasewith as little as 1 point
                </div>
              </div>
            </div>
          </>
        )}
        {isRefFriendOrCareSection && (
          <>
            <div
              className={`od-membership-block__table-item--${isRefFriend ? 'ref-friend' : 'care'}`}
            >
              <div
                className={`od-membership-block__table-item__content--${
                  isRefFriend ? 'ref-friend' : 'care'
                }`}
              >
                <div className="od-membership-block__table-item__content__title od-font-bold">
                  {isRefFriend && 'Your Friend'}
                  {isCare && 'Free Replacement'}
                </div>
                <div className="od-membership-block__table-item__content__rebate">
                  <span className={`${isRefFriend ? '' : 'care-'}text-bold od-font-bold`}>
                    {isRefFriend && 'S$20 '}
                    {isCare && '1 time '}
                  </span>
                  <span className={`${isRefFriend ? '' : 'care-'}text-reg od-font-reg`}>
                    {isRefFriend && 'OFF'}
                    {isCare && 'free replacement'}
                  </span>
                </div>
                <div className="od-membership-block__table-item__content__description od-font-reg">
                  {isRefFriend && 'Referral coupon'}
                  {isCare && 'of damaged frame'}
                </div>
              </div>
              <div
                className={`od-membership-block__table-item__content--${
                  isRefFriend ? 'ref-friend' : 'care'
                }`}
              >
                <div className="od-membership-block__table-item__content__title od-font-bold">
                  {isRefFriend && 'You'}
                  {isCare && 'Discount'}
                </div>
                <div className="od-membership-block__table-item__content__rebate">
                  <span
                    className={`${isRefFriend ? '' : 'care-'}text-bold${
                      isRefFriend ? '' : '--secondary'
                    } od-font-bold`}
                  >
                    {isRefFriend && '4% '}
                    {isCare && '20% '}
                  </span>
                  <span className={`${isRefFriend ? '' : 'care-'}text-reg od-font-reg`}>
                    {isRefFriend && 'Points rebate'}
                    {isCare && 'Unlimited OFF'}
                  </span>
                </div>
                <div className="od-membership-block__table-item__content__description od-font-reg">
                  {isRefFriend && 'Your friend’s first purchase'}
                  {isCare && 'on your new frame purchase'}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isRefFriend && listOfSection && listOfSection.length > 0 && (
        <ul className="od-membership-block__description-list od-font-reg">
          {listOfSection.map(item => {
            return <li key={v4()}>{item.text}</li>;
          })}
        </ul>
      )}
      {isCare && (
        <div className="od-membership-block__terms-conditions">
          <div className="od-membership-block__terms-conditions__title od-font-bold">
            Terms & Conditions
          </div>
          <div className="od-membership-block__terms-conditions__description od-font-reg">
            OWNDAYS Care+ is available for purchase with sunglasses, OWNDAYS PC glasses and all
            spectacle frames, including frames from collaboration collections, Limited Edition
            collection and frames purchased with the same-day-2nd-pair-50%-off promotion. OWNDAYS
            Care+ expires one year from the date of collection. There is strictly no extension of
            expiry date and/or renewal of OWNDAYS Care+. 10% of the original retail price indicated
            on the product, rounded down to the nearest dollar, must be paid at the time of
            purchase. There is strictly no refund upon purchase of OWNDAYS Care+. OWNDAYS Care+ is
            non-transferrable and is valid for eyewear used by the same wearer only.^OWNDAYS Care+
            covers damages to the frame only. Damaged frame must be returned to OWNDAYS and will be
            replaced with a new frame of the same model. In the event the same model is no longer in
            stock, a replacement product of the same or lower price will be provided. There will be
            no refund of the price difference if the replacement product is of a lower price.
            Replacement of damaged frame is accepted at OWNDAYS retail stores in Singapore only.*
            20%-off-new-frame-purchase is valid for the purchase of spectacle frames with standard
            high index aspheric lenses only and excludes collaboration merchandise, limited-edition
            merchandise, sale items, sunglasses, OWNDAYS PC glasses, other ready-to-wear
            merchandise, accessories and all lens upgrades. The discount can be redeemed unlimited
            number of times within the OWNDAYS Care+ validity period. The discount cannot be used in
            conjunction with the same-day-2nd-pair-50%-off promotion and/or other offers and
            privileges.OWNDAYS Care+ is valid in Singapore only. Please retain receipt as proof of
            purchase. The management reserves the right to amend the terms & conditions without
            prior notice.
          </div>
        </div>
      )}
    </section>
  );
};

export default MembershipSection;
