import React, { Fragment } from 'react';
import Slider from 'react-slick';

import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { HEADERS } from './constants';

import './HeaderOwndaysPlus.scss';

export const HeaderOwndaysPlus = ({ baseImgUrl }) => {
  const breadcrumbs = [
    { path: '/sg/en', label: 'Homepage' },
    { path: '/sg/en/news#all', label: 'News' },
    { path: '#', label: 'OWNDAYS+' },
  ];

  const settings = {
    fade: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    draggable: false,
    arrows: false,
    swipe: false,
  };

  return (
    <Fragment>
      <Breadcrumbs crumbs={breadcrumbs} />

      <section className="header-owndays-plus">
        <Slider {...settings} className="header-owndays-plus__slider">
          {HEADERS.map((item, index) => {
            return (
              <picture key={`header-owndays-plus-${index}`}>
                <source media="(min-width: 1296px)" srcSet={item.pc} />
                <source media="(min-width: 768px)" srcSet={item.tb} />
                <img alt="OWNDAYS+" src={item.sp} />
              </picture>
            );
          })}
        </Slider>

        <div className="header-owndays-plus__overlay">
          <div
            className="base-owndays-plus__container"
            data-aos="blur-in-od-plus"
            data-aos-delay="300"
          >
            <h1 className="header-owndays-plus__heading">
              <img alt="OWNDAYS+" src={`${baseImgUrl}/owndays-plus.svg`} />
            </h1>
            <p className="header-owndays-plus__subheading">
              inclusive of high index aspheric lenses S$98
            </p>
            <div className="header-owndays-plus__scroll">scroll</div>
          </div>
        </div>
      </section>

      <section className="header-concept-owndays-plus">
        <div className="header-concept-owndays-plus__detail" data-aos="blur-in">
          <div className="header-concept-owndays-plus__detail__bullet"></div>
          <div className="header-concept-owndays-plus__detail__logo-img-concept">
            <img alt="MADE IN JAPAN" src={`${baseImgUrl}/logo-concept.svg`} />
          </div>

          <div className="header-concept-owndays-plus__detail__text-vertical-concept-os">
            <p>
              Modesty
              <br /> – a classic Japanese essence – <br />
              exemplified in its price and design
            </p>
          </div>

          <div className="header-concept-owndays-plus__detail__text-horizontal-concept">
            <p>
              Subtle and modest, yet infused with the true spirit of Japanese craftsmanship.
              <br />
              Embracing techniques from Japan to create beautiful eyewear with simple design and
              price point.
              <br />
              Where minimalist aesthetic and sublime quality meet efficient pricing, <br />
              that’s OWNDAYS+.
            </p>
          </div>

          <div className="header-concept-owndays-plus__detail__btn-concept">
            <a className="base-owndays-plus__btn" href="/sg/en/eyeglasses/brands/Owndaysplus.html">
              ALL ITEMS
            </a>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
