import React from 'react';
import Modal from './BundleSaleModal';

const TermsModal = ({ isOpen, onClose, terms }) => {
  if (!terms) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className='bundle-sale__modal-title'>Terms & Conditions</h2>
      <p className='bundle-sale__modal-subtitle'>{terms.title}</p>
      <ul className='bundle-sale__modal-terms'>
        {terms.list.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </Modal>
  );
};

export default TermsModal;