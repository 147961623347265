import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PFUViewDetail from '../../mobile/components/PDValue/PFUViewDetail';
import PowerGrid from './PowerGrid';
import PowerStatusMessage from './PowerStatusMessage';
import { pdIconStateHandler, setPdStateHandler } from './PowerDetails.helper';

function PowerDetails(props) {
  const {
    view,
    redisCommonData,
    prescription,
    prescription: { imageFileName, left, right, pdImageFileName },
    showPowerDetailsFn,
    showUploadPrescModal,
    dataLocale,
    fromOrderDetails,
    preCheckout,
    orderDetails,
    newStyle = false,
    cartView = false,
  } = props;
  const userNameList = (redisCommonData && redisCommonData?.userNamesList) || [];
  const [powerState, setPowerState] = useState({});
  const [pdState, setPdState] = useState(null);

  useEffect(() => {
    let section = 'CONTACT_FOR_POWER';
    if (
      left &&
      Object.keys(left).length > 0 &&
      Object.keys(left).some(
        itm => itm !== 'boxes' && left[itm] !== '' && left[itm] !== null && !isNaN(left[itm])
      )
    ) {
      section = 'POWER_GRID';
    } else if (
      right &&
      Object.keys(right).length > 0 &&
      Object.keys(right).some(
        itm => itm !== 'boxes' && right[itm] !== '' && right[itm] !== null && !isNaN(right[itm])
      )
    ) {
      section = 'POWER_GRID';
    } else if (imageFileName) {
      section = 'POWER_UPLOAD';
    } else if (
      left &&
      Object.keys(left).length > 0 &&
      'ap' in left &&
      left.ap !== 'Call Me/Email Me for Power'
    ) {
      section = 'POWER_GRID';
    } else if (
      right &&
      Object.keys(right).length > 0 &&
      'ap' in right &&
      right.ap !== 'Call Me/Email Me for Power'
    ) {
      section = 'POWER_GRID';
    }
    if (typeof showPowerDetailsFn === 'function') showPowerDetailsFn(section);

    const { icon, message } = pdIconStateHandler(
      section,
      imageFileName,
      redisCommonData,
      dataLocale
    );

    setPowerState({ section, icon, message });
    setPdStateHandler(imageFileName, pdImageFileName, setPdState);
  }, [orderDetails, prescription]);

  const { section, icon, message } = powerState;
  let isBorderallowed = !!(section === 'POWER_GRID' && pdState);
  if (
    window?.location?.pathname?.includes('checkout') ||
    window?.location?.pathname?.includes('cart')
  ) {
    isBorderallowed = false;
  }
  if (!section) {
    return null;
  }
  const pdValue =
    prescription?.left?.pd && !isNaN(prescription.left.pd) && prescription.left.pd.length !== 0;
  return (
    <React.Fragment>
      {
        {
          POWER_GRID: (
            <PowerGrid
              cartView={cartView}
              dataLocale={dataLocale}
              fromOrderDetails={fromOrderDetails}
              newStyle={newStyle}
              preCheckout={preCheckout}
              prescription={prescription}
              userNameList={userNameList}
              view={view}
            />
          ),
          POWER_UPLOAD: view !== 'mobile' && (
            <PowerStatusMessage
              cartView={cartView}
              dataLocale={dataLocale}
              imgIcon={icon}
              message={message}
              newStyle={newStyle}
              showUploadPrescModal={showUploadPrescModal}
              view={view}
            />
          ),
          CONTACT_FOR_POWER: (
            <PowerStatusMessage
              cartView={cartView}
              imgIcon={icon}
              message={message}
              newStyle={newStyle}
              view={view}
            />
          ),
        }[section]
      }
      {
        {
          SHOW_POWER_PD: view === 'mobile' && (
            <PFUViewDetail
              dataLocale={dataLocale}
              imageFileName={imageFileName}
              imgIcon={icon}
              isBorderallowed={isBorderallowed}
              newStyle={newStyle}
              pdImageFileName={pdImageFileName}
              pdValue={pdValue}
              prescription={prescription}
              view={view}
            />
          ),
          SHOW_POWER: view === 'mobile' && (
            <PowerStatusMessage
              cartView={cartView}
              dataLocale={dataLocale}
              imgIcon={icon}
              isBorderallowed={isBorderallowed}
              message={message}
              newStyle={newStyle}
              powerValue={prescription?.left?.sph}
              showUploadPrescModal={showUploadPrescModal}
              view={view}
            />
          ),
        }[pdState]
      }
      {view !== 'mobile' &&
        prescription?.left &&
        section !== 'POWER_UPLOAD' &&
        Object.keys(prescription.left).length === 1 &&
        prescription.left.pd &&
        imageFileName && (
          <PowerStatusMessage
            cartView={cartView}
            dataLocale={dataLocale}
            imgIcon={icon}
            message={message}
            newStyle={newStyle}
            showUploadPrescModal={showUploadPrescModal}
            view={view}
          />
        )}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    redisCommonData: state.common.redisCommonData,
    orderDetails: state.order.orderDetails,
  };
}

export default connect(mapStateToProps, null)(PowerDetails);
