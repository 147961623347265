import * as actionTypes from '../../actionTypes/lensPackageUpgrade';

const initialState = {
  orderDataDetails: undefined,
  isLoading: false,
  lensPackageDetails: undefined,
  isLensPackageLoading: false,
  updatedPowerType: undefined,
  isUpdatedPowerTypeLoading: false,
  packageUpdate: undefined,
  isPackageUpdateLoading: false,
  updatePrescription: undefined,
  isUpdatePrescriptionLoading: false,
};

export default function lensPackage(prevState = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_LENS_PACKAGE_DETAILS:
      return { ...prevState, loading: true, lensPackageDetails: undefined };
    case actionTypes.GET_LENS_PACKAGE_DETAILS_SUCCESS:
      return { ...prevState, loading: false, lensPackageDetails: action.data };
    case actionTypes.GET_LENS_PACKAGE_DETAILS_FAIL:
      return { ...prevState, loading: false, lensPackageDetails: action.error };
    case actionTypes.UPDATE_POWER_TYPE_LOAD:
      return {
        ...prevState,
        loading: true,
        isUpdatePowerTypeCallSuccess: false,
        updatedPowerType: undefined,
      };
    case actionTypes.UPDATE_POWER_TYPE_SUCCESS:
      return {
        ...prevState,
        loading: false,
        isUpdatePowerTypeCallSuccess: true,
        updatedPowerType: action.data,
      };
    case actionTypes.UPDATE_POWER_TYPE_FAIL:
      return {
        ...prevState,
        loading: false,
        isUpdatePowerTypeCallSuccess: false,
        updatedPowerType: action.error,
      };
    case actionTypes.UPDATE_PACKAGE_LOAD:
      return {
        ...prevState,
        loading: true,
        isUpdatePackageCallSuccess: false,
        packageUpdate: undefined,
      };
    case actionTypes.UPDATE_PACKAGE_SUCCESS:
      return {
        ...prevState,
        loading: false,
        isUpdatePackageCallSuccess: true,
        packageUpdate: action.data,
      };
    case actionTypes.UPDATE_PACKAGE_FAIL:
      return {
        ...prevState,
        loading: false,
        isUpdatePackageCallSuccess: false,
        packageUpdate: action.error,
      };
    case actionTypes.UPDATE_PRESCRIPTION_LOAD:
      return {
        ...prevState,
        loading: true,
        isUpdatePrescriptionCallSuccess: false,
        updatePrescription: undefined,
      };
    case actionTypes.UPDATE_PRESCRIPTION_SUCCESS:
      return {
        ...prevState,
        loading: false,
        isUpdatePrescriptionCallSuccess: true,
        updatePrescription: action.data,
      };
    case actionTypes.UPDATE_PRESCRIPTION_FAIL:
      return {
        ...prevState,
        loading: false,
        isUpdatePrescriptionCallSuccess: false,
        updatePrescription: action.error,
      };
    case actionTypes.RESET_UPDATE_FIELDS:
      return {
        ...prevState,
        loading: false,
        updatedPowerType: undefined,
        isUpdatedPowerTypeLoading: false,
        packageUpdate: undefined,
        isPackageUpdateLoading: false,
        updatePrescription: undefined,
        isUpdatePrescriptionLoading: false,
      };
    default:
      return prevState;
  }
}
