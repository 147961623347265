export const HOMEPAGE_DATA_LOAD = 'HOMEPAGE_DATA_LOAD';
export const MOBILE_HOMEPAGE_DATA_LOAD = 'MOBILE_HOMEPAGE_DATA_LOAD';
export const HOMEPAGE_DATA_SUCCESS = 'HOMEPAGE_DATA_SUCCESS';
export const MOBILE_HOMEPAGE_DATA_SUCCESS = 'MOBILE_HOMEPAGE_DATA_SUCCESS';
export const HOMEPAGE_DATA_FAIL = 'HOMEPAGE_DATA_FAIL';
export const RENDER_DATA_UPDATE = 'RENDER_DATA_UPDATE';
export const HOME_SCROLL_SET = 'HOME_SCROLL_SET';
export const HOMEPAGE_MERGED_DATA_UPDATE = 'HOMEPAGE_MERGED_DATA_UPDATE';
export const HOMEPAGE_CAROUSALS_DATA_LOAD = 'HOMEPAGE_CAROUSALS_DATA_LOAD';
export const HOMEPAGE_CAROUSALS_DATA_SUCCESS = 'HOMEPAGE_CAROUSALS_DATA_SUCCESS';
export const HOMEPAGE_CAROUSALS_DATA_FAIL = 'HOMEPAGE_CAROUSALS_DATA_FAIL';
export const HOME_PAGE_PRODUCTS_LOAD = 'HOME_PAGE_PRODUCTS_LOAD';
export const HOME_PAGE_PRODUCTS_SUCCESS = 'HOME_PAGE_PRODUCTS_SUCCESS';
export const HOME_PAGE_PRODUCTS_FAILURE = 'HOME_PAGE_PRODUCTS_FAILURE';

export const GET_CLICK_CATEGORY_LOAD = 'GET_CLICK_CATEGORY_LOAD';
export const GET_CLICK_CATEGORY_SUCCESS = 'GET_CLICK_CATEGORY_SUCCESS';
export const GET_CLICK_CATEGORY_FAILURE = 'GET_CLICK_CATEGORY_FAILURE';
