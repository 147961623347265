import React from 'react';

const HeaderContacts = ({ bgImg, headingImg, headingText, subHeading }) => {
  return (
    <section className="contacts-page__main">
      <img
        className="contacts-page__main-bg"
        loading="lazy"
        src={bgImg}
        alt="Main background"
      />
      <div className="contacts-page__container">
        <h1 className="contacts-page__main-heading">
          {headingText}{` `}
          <img loading="lazy" src={headingImg} alt={headingText} />
        </h1>
        {subHeading && <p className="contacts-page__main-subheading">{subHeading}</p>}
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1500 50"
        preserveAspectRatio="none"
        id="svg-bg"
      >
        <path
          d="M0,0 v25 q10,10 20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 t20,0 v-25 Z"
          fill="#fff"
        ></path>
      </svg>
    </section>
  );
};

export default HeaderContacts;
