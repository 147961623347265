import React, { PureComponent, Fragment } from 'react';
// components
import Panel from '../Panel/Panel';
import { noop } from '../../../utils/helper';

class AccordionGroup extends PureComponent {
  constructor(props) {
    super(props);
    const { activeKey } = props;
    const activeKeyState =
      (Array.isArray(activeKey) && activeKey) || (activeKey >= 0 ? [activeKey] : []);
    this.state = { activeKey: activeKeyState };
  }
  componentDidUpdate(prevProps) {
    const { activeKey } = this.props;
    if (prevProps.activeKey !== activeKey) {
      this.selectPanel(activeKey);
    }
  }
  selectPanel(key) {
    const { activeKey } = this.state;
    const { multiOpen } = this.props;
    const keyIndex = activeKey?.indexOf(key);
    if (multiOpen) {
      if (keyIndex > -1) {
        activeKey.splice(keyIndex, 1);
        this.setState({ activeKey: [...activeKey] });
        return;
      }
      this.setState({ activeKey: [...activeKey, key] });
      return;
    }
    if (keyIndex > -1) {
      this.setState({ activeKey: [] });
      return;
    }
    this.setState({ activeKey: [key] });
  }
  render() {
    const { content, className } = this.props;
    const { activeKey } = this.state;
    return (
      <Fragment>
        {content.map((panel, index) => {
          const expanded = activeKey.includes(index);
          const { header, body, extraSection } = panel;
          return (
            <div
              key={index}
              aria-multiselectable="true"
              className={`accordion-group ${className}`}
              role="tablist"
            >
              {extraSection}
              {header && (
                <div
                  aria-label="accordion header"
                  className={`accordion-group-header ${expanded && body ? 'expanded' : ''}`}
                  onClick={body ? () => this.selectPanel(index) : noop}
                >
                  {header}
                </div>
              )}
              {body && (
                <Panel expanded={expanded}>
                  <div className="accordion-group-body" role="tabpanel">
                    {body}
                  </div>
                </Panel>
              )}
            </div>
          );
        })}
      </Fragment>
    );
  }
}

export default AccordionGroup;
