import React from 'react';

const FooterFreeMyopiaControl2Lenses = () => {
  return (
    <section className="fmc2l__fixed-bottom">
      <div className="fmc2l__container">
        <div className="fmc2l__fixed-bottom-grid">
          <a className="fmc2l__btn fmc2l__btn--yellow" href="https://www.owndays.com/stores/sg">
            Book an eye test
          </a>
          <p><a href='/sg/en/terms'>T&Cs</a></p>
        </div>
      </div>
    </section>
  );
};

export default FooterFreeMyopiaControl2Lenses;
