import { call, put, takeLatest, all } from 'redux-saga/effects';
import config from 'config';
import * as shortlistActionTypes from '../actionTypes/shortlist';
import apiClient from '../helpers/apiClient';
import { modifyProductData } from './responseDataManipulator';

let _client = null;
function* getShortlistedProductData({ pIds }) {
  const successAction = { type: shortlistActionTypes.SHORTLIST_PRODUCTS_SUCCESS };
  const errorAction = { type: shortlistActionTypes.SHORTLIST_PRODUCTS_FAIL };
  const client = { ..._client };
  const promisesArray = [];
  const url = __SERVER__ ? config.apiPath.albDataProduct : config.apiPath.dataProduct;
  try {
    client.setHeader();
    pIds.forEach(id => {
      promisesArray.push(client.get(url + id));
    });
    const response = yield call(async () => Promise.all(promisesArray));
    successAction.data = response;
    response.forEach(product => {
      const prodData = Object.assign({}, product.result, modifyProductData(product.result));
      let general = prodData.specifications.find(obj => obj.name === 'general');
      general = general ? general.items : [];
      let collection = '';
      let fColor = '';
      let fSize = '';
      let sizeD = '';
      general.forEach(obj => {
        if (obj.name === 'Collection') {
          collection = obj.value;
        }
        if (obj.name === 'Frame colour') {
          fColor = obj.value;
        }
        if (obj.name === 'Frame Size') {
          fSize = obj.value;
        }
        if (obj.name === 'Size') {
          sizeD = obj.value;
        }
      });
      product.result = prodData;
      product.result.colorAndCollection = collection + ' ' + fColor;
      product.result.sizeInfo = fSize[0] + ' (' + sizeD + ' mm)';
      product.result.sizeDetails = sizeD;
    });
    yield put(successAction);
  } catch (err) {
    errorAction.data = err;
    yield put(errorAction);
  }
}

export function* runShortlistCalls(client) {
  _client = client ? Object.assign({}, client) : apiClient();
  yield all([takeLatest(shortlistActionTypes.SHORTLIST_PRODUCTS_LOAD, getShortlistedProductData)]);
}
