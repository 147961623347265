import { v4 } from 'uuid';
import { sha256 } from './helper';

const transformUserInfo = data => {
  const userInfo = data.result ?? data;

  if (!userInfo) return userInfo;

  return {
    email: userInfo.email,
    // id: userInfo.id,
    phoneCode: userInfo.phoneCode,
    telephone: userInfo.telephone,
  };
};

const transformCartData = data => {
  const cartData = data.result ?? data;

  if (!cartData) return cartData;

  return {
    totals: cartData.totals && {
      total: cartData.totals.total,
      // discounts:
      //   cartData.totals.discounts &&
      //   cartData.totals.discounts.map(discount => ({
      //     type: discount.type,
      //     code: discount.code,
      //   })),
    },
    currencyCode: cartData.currencyCode ?? cartData.currency_code,
    items:
      cartData.items &&
      cartData.items.map(item => ({
        id: item.id,
        modelName: item.modelName ?? item.model_name,
        brandName: item.brandName ?? item.brand_name,
        // sku: item.sku,
        // frameColour: item.frameColour ?? item.frame_colour,
        price: item.price && {
          // currencyCode: item.price.currencyCode ?? item.price.currency_code,
          value: item.price.value,
        },
        // quantity: item.quantity,
      })),
  };
};

const transformProductData = data => {
  const productData = data.result ?? data;

  if (!productData) return productData;

  return {
    id: productData.id,
    modelName: productData.modelName ?? productData.model_name,
    brandName: productData.brandName ?? productData.brand_name,
    // sku: productData.sku,
    // frameColour: productData.frameColour ?? productData.frame_colour,
    prices:
      productData.prices &&
      productData.prices.map(price => ({
        name: price.name,
        currencyCode: price.currencyCode ?? price.currency_code,
        price: price.price,
      })),
    // qty: productData.qty,
  };
};

const transformCategoryData = data => {
  const categoryData = data.result ?? data;

  if (!categoryData) return categoryData;

  return {
    // categoryId: categoryData.categoryId ?? categoryData.category_id,
    // categoryName: categoryData.categoryName ?? categoryData.category_name,
    productList:
      (categoryData.productList ?? categoryData.product_list) &&
      (categoryData.productList ?? categoryData.product_list).map(product => ({
        id: product?.id,
        modelName: product?.modelName ?? product?.model_name,
        brandName: product?.brandName ?? product?.brand_name,
        // color: product?.color,
        prices:
          product?.prices &&
          product.prices.map(price => ({
            name: price.name,
            currencyCode: price.currencyCode ?? price.currency_code,
            price: price.price,
          })),
        // qty: product?.qty,
      })),
  };
};

const transformOrder = data => {
  const order = data.result ?? data;

  if (!order) return order;

  return {
    id: order.id,
    amount: order.amount && {
      currencyCode: order.amount.currencyCode ?? order.amount.currency_code,
      total: order.amount.total ?? order.amount.total,
      // totalTax: order.amount.totalTax ?? order.amount.total_tax,
      // shipping: order.amount.shipping ?? order.amount.shipping,
      // discounts:
      //   order.discounts &&
      //   order.discounts.map(discount => ({
      //     type: discount.type,
      //     amount: discount.amount,
      //   })),
    },
    // deliveryOption: order.deliveryOption ?? order.delivery_option,
    // gokwikRtoDetails: (order.gokwikRtoDetails ?? order.gokwik_rto_details) && {
    //   riskFlag:
    //     (order.gokwikRtoDetails ?? order.gokwik_rto_details).riskFlag ??
    //     (order.gokwikRtoDetails ?? order.gokwik_rto_details).risk_flag,
    // },
    // payments:
    //   order.payments &&
    //   order.payments.map(payment => ({
    //     paymentList:
    //       (payment.paymentList ?? payment.payment_list) &&
    //       (payment.paymentList ?? payment.payment_list).map(paymentItem => ({
    //         method: paymentItem.method,
    //       })),
    //   })),
    // shippingAddress: (order.shippingAddress ?? order.shipping_address) && {
    //   city: (order.shippingAddress ?? order.shipping_address).city,
    // },
    // createdAt: order.createdAt ?? order.created_at,
    items:
      order.items &&
      order.items.map(item => ({
        id: item.id,
        modelName: item.modelName ?? item.model_name,
        brandName: item.brandName ?? item.brand_name,
        // sku: item.sku,
        // frameColour: item.frameColour ?? item.frame_colour,
        price: item.price && {
          // currencyCode: item.price.currencyCode ?? item.price.currency_code,
          value: item.price.value,
        },
        // quantity: item.quantity,
      })),
  };
};

const transformTTQEventData = params => ({
  ...params,
  ...(params.userInfo ? { userInfo: transformUserInfo(params.userInfo) } : {}),
  ...(params.cartData ? { cartData: transformCartData(params.cartData) } : {}),
  ...(params.productData ? { productData: transformProductData(params.productData) } : {}),
  ...(params.categoryData ? { categoryData: transformCategoryData(params.categoryData) } : {}),
  ...(params.order ? { order: transformOrder(params.order) } : {}),
});

const getPayloadIdentify = async userInfo => ({
  email: await sha256(userInfo.email),
  phone_number: await sha256(`${userInfo.phoneCode}${userInfo.telephone}`),
  external_id: await sha256(v4()),
});

const getPayloadCompleteRegistration = () => ({});

const getPayloadSearch = () => ({});

const getPayloadViewContent = ({ productData }) => ({
  contents: [
    {
      content_id: productData?.id,
      content_type: 'product',
      content_name: productData?.modelName,
      brand: productData?.brandName,
    },
  ],
  currency: productData?.prices?.[0]?.currencyCode,
  value: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
});

const getPayloadAddToWishlist = ({ productData, categoryData, index }) => ({
  ...(productData
    ? {
        contents: [
          {
            content_id: productData?.id,
            content_type: 'product',
            content_name: productData?.modelName,
            brand: productData?.brandName,
          },
        ],
        currency: productData?.prices?.[0]?.currencyCode,
        value: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
      }
    : {}),
  ...(categoryData?.productList?.[index]
    ? {
        contents: [
          {
            content_id: categoryData?.productList[index].id,
            content_type: 'product',
            content_name: categoryData?.productList[index].modelName,
            brand: categoryData?.productList[index].brandName,
          },
        ],
        currency: categoryData?.productList[index].prices?.[0]?.currencyCode,
        value: categoryData?.productList[index].prices?.find(
          price => price.name === 'Lenskart Price'
        )?.price,
      }
    : {}),
});

const getPayloadAddToCart = ({ productData }) => ({
  contents: [
    {
      content_id: productData?.id,
      content_type: 'product',
      content_name: productData?.modelName,
      brand: productData?.brandName,
    },
  ],
  currency: productData?.prices?.[0]?.currencyCode,
  value: productData?.prices?.find(price => price.name === 'Lenskart Price')?.price,
});

const getPayloadInitiateCheckout = ({ cartData }) => ({
  contents: cartData?.items?.map(item => ({
    content_id: item?.id,
    content_type: 'product',
    content_name: item?.modelName,
    brand: item?.brandName,
    price: item.price?.value,
  })),
  currency: cartData?.currencyCode,
  value: cartData?.totals?.total,
});

const getPayloadPlaceAnOrder = ({ cartData }) => ({
  contents: cartData?.items?.map(item => ({
    content_id: item?.id,
    content_type: 'product',
    content_name: item?.modelName,
    brand: item?.brandName,
    price: item.price?.value,
  })),
  currency: cartData?.currencyCode,
  value: cartData?.totals?.total,
});

const getPayloadAddPaymentInfo = ({ cartData }) => ({
  contents: cartData?.items?.map(item => ({
    content_id: item?.id,
    content_type: 'product',
    content_name: item?.modelName,
    brand: item?.brandName,
    price: item.price?.value,
  })),
  currency: cartData?.currencyCode,
  value: cartData?.totals?.total,
});

const getPayloadCompletePayment = ({ order }) => ({
  contents: order?.items?.map(item => ({
    content_id: item?.id,
    content_type: 'product',
    content_name: item?.modelName,
    brand: item?.brandName,
    price: item.price?.value,
  })),
  currency: order?.amount?.currencyCode,
  value: order?.amount?.total,
});

const payloadGetters = {
  CompleteRegistration: getPayloadCompleteRegistration,
  Search: getPayloadSearch,
  ViewContent: getPayloadViewContent,
  AddToWishlist: getPayloadAddToWishlist,
  AddToCart: getPayloadAddToCart,
  InitiateCheckout: getPayloadInitiateCheckout,
  PlaceAnOrder: getPayloadPlaceAnOrder,
  AddPaymentInfo: getPayloadAddPaymentInfo,
  CompletePayment: getPayloadCompletePayment,
};

let PENDING_EVENTS = [];
let TTQInterval;

export const odPushTTQEvent = async (event, data = {}, overrides = {}) => {
  console.log('OdPushTTQEvent event data overrides', event, data, overrides);

  const transformedData = transformTTQEventData(data);

  const identifyPayload = await getPayloadIdentify(transformedData);

  console.log('OdPushTTQEvent transformedData', transformedData);

  console.log('OdPushTTQEvent payload', payloadGetters[event]?.(transformedData) || {});

  const payload = {
    event,
    ...(payloadGetters[event]?.(transformedData) || {}),
    ...overrides,
  };

  PENDING_EVENTS.push({ identifyPayload, payload });
  console.log('odPushTTQEvent ~ PENDING_EVENTS:', PENDING_EVENTS);

  if (window.ttq) {
    if (TTQInterval) {
      clearInterval(TTQInterval);

      TTQInterval = null;
    }

    PENDING_EVENTS.forEach(({ identifyPayload, payload }) => {
      window.ttq.identify(identifyPayload);

      window.ttq.track(payload);
    });

    PENDING_EVENTS = [];
  } else if (!TTQInterval) {
    TTQInterval = setInterval(() => {
      if (window.ttq) {
        clearInterval(TTQInterval);

        TTQInterval = null;

        PENDING_EVENTS.forEach(({ identifyPayload, payload }) => {
          window.ttq.identify(identifyPayload);

          window.ttq.track(payload);
        });

        PENDING_EVENTS = [];
      }
    }, 10);
  } else {
    console.log('NO_TTQ_LAYER, sent to interval');
  }
};

// ====================================================================================================
// Lenskart legacy
// ====================================================================================================

export const ViewContentTiktokPixel = ({
  category,
  currency,
  productId,
  productName,
  productPrice,
}) => {
  if (window?.ttq !== undefined) {
    window.ttq.track('ViewContent', {
      content_type: 'product',
      content_id: productId,
      content_name: productName,
      content_category: category,
      currency,
      value: productPrice,
    });
  }
};

// AddToWishlist

// Search

export const AddToCartTikTokEvent = ({ currency, productId, productName, productPrice }) => {
  if (window?.ttq !== undefined) {
    window.ttq.track('AddToCart', {
      content_type: 'product',
      content_id: productId,
      content_name: productName,
      value: productPrice,
      currency,
    });
  }
};

export const AddPaymentInfoTiktokEvent = ({ productIds, totalAmount, totalItems, currency }) => {
  if (window?.ttq !== undefined) {
    window.ttq.track('AddPaymentInfo', {
      content_type: 'product',
      content_id: productIds.toString(),
      value: totalAmount,
      quantity: totalItems,
      currency,
    });
  }
};

export const InitiateCheckoutTiktokEvent = ({ productIds, totalAmount, totalItems, currency }) => {
  if (window?.ttq !== undefined) {
    window.ttq.track('InitiateCheckout', {
      content_type: 'product',
      content_id: productIds.toString(),
      currency,
      value: totalAmount,
      quantity: totalItems,
    });
  }
};

export const TiktokPixelEvent = (event = '', cartData) => {
  const cartItems = cartData?.result?.items || [];
  const productIds = cartItems.map(item => item.productId);
  const productNames = cartItems.map(item => item.name);
  const totalAmount = cartData?.result?.totals?.total || 0;
  const currency = cartData?.result?.totals?.currencyCode || '';

  if (window?.ttq !== undefined) {
    window.ttq.track(event, {
      content_type: 'product',
      content_id: productIds.toString(),
      content_name: productNames.toString(),
      currency,
      value: totalAmount,
    });
  }
};

export const PlaceAnOrderTiktokEvent = ({
  currency,
  productIds,
  productCategories,
  totalItems,
  totalAmount,
}) => {
  if (window?.ttq !== undefined) {
    window.ttq.track('PlaceAnOrder', {
      content_type: 'product',
      content_id: productIds.toString(),
      content_category: productCategories.toString(),
      currency,
      quantity: totalItems,
      value: totalAmount,
    });
  }
};

// CompleteRegistration

// CompletePayment
