import React, { useEffect, useState } from 'react';
import './AddGiftCard.scss';
import ApplyGiftCard from './ApplyGiftCard/ApplyGiftCard';
import { connect } from 'react-redux';
import OdGiftIcon from '../../../../static/assets/svg/od-gift-icon.svg';

const AddGiftCard = props => {
  const {
    cartObj,
    dataLocale,
    cartError,
    login,
    // askUserLogin,
    // redisCommonData,
    isMobile,
    style = {},
  } = props;

  const { ADD_GIFT_CARD } = dataLocale;

  const [gift, setGift] = useState(null);
  const [giftError, setGiftError] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [gvList, setGvList] = useState([]);

  const buttonStylesMobile = {
    backgroundColor: '#FAFAFA',
    padding: '10px 20px',
    justifyContent: 'space-between',
    gap: 'unset',
    color: '#546073',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.28px',
  };

  const handleShowCoupons = value => {
    setShowSideBar(value);
  };

  const handleSetGift = value => {
    setGift(value);
  };

  // for persist on reload...
  useEffect(() => {
    if (cartObj && cartObj?.totals && cartObj?.totals?.discounts) {
      let gvCode;

      cartObj?.totals?.discounts.forEach(item => {
        if (item.type === 'gv') {
          gvCode = item.code;

          setGift(gvCode);
        }
      });
    }
  }, [cartObj]);

  useEffect(() => {
    if (cartObj) {
      setGvList(cartObj && cartObj?.applicableGvs);
    }
  }, [cartObj]);

  return (
    <>
      <div className="od-add-gift" style={style}>
        <div
          className={`od-add-gift__button od-font-reg ${
            !login && 'od-add-gift__button--unauthorized'
          }`}
          onClick={() => handleShowCoupons(true)}
        >
          <img alt="od-ticket-icon" className="od-add-gift__icon" src={OdGiftIcon} />
          {ADD_GIFT_CARD}
        </div>

        {showSideBar && (
          <div className="apply-gift-cart">
            <div className="od-add-gift__backdrop" onClick={() => handleShowCoupons(false)} />
            <div className={`od-add-gift__sidebar--${isMobile ? 'm' : 'd'}`}>
              <ApplyGiftCard
                cartError={cartError}
                coupon={gift}
                giftError={giftError}
                gvList={gvList}
                handleSetGift={handleSetGift}
                setGiftError={setGiftError}
                setShowSideBar={setShowSideBar}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const cartData = state.cart.cartData ? state.cart.cartData.result : null;

  return {
    cartData,
    cartError: state.cart.cartError,
    dataLocale: state.locale,
    login: state.common.login,
    redisCommonData: state.common.redisCommonData,
    askUserLogin: state.common.askUserLogin,
  };
};

export default connect(mapStateToProps, null)(AddGiftCard);
