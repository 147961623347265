import React from 'react';

export const Success = ({ baseImgUrl }) => {
  return (
    <section className="base-result-submit__result__container">
      <img alt="success" src={`${baseImgUrl}/success.svg`} />
      <h1>Thanks!</h1>
      <p>Your preference has been successfully updated.</p>
    </section>
  );
};
