import React from 'react';
import './ProductImage.scss';
import { replaceImageURL } from '../../helper';
import { useSelector } from 'react-redux';

const ProductImage = ({ src, alt }) => {
  const localeInfo = useSelector(state => state.common.localeInfo);
  const pid = useSelector(state => state.common.productID);
  return (
    <div className="od-pc-image-container">
      <img
        alt={alt}
        className="od-pc-image"
        src={
          replaceImageURL(src, localeInfo, pid) ??
          'https://static.lenskart.com/media/owndays/desktop/img/od-misc/no-image.jpg'
        }
        onError={event => {
          event.target.src =
            'https://static.lenskart.com/media/owndays/desktop/img/od-misc/no-image.jpg';
        }}
      />
    </div>
  );
};

export default ProductImage;
