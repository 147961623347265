import React, { useRef, useState } from 'react';

import { onImageLoadError } from '../../../utils/helper';
import { COMPARISONS } from './constants';

import './BodyOwndaysProgressiveCP.scss';

export const BodyOwndaysProgressiveCP = ({ baseImgUrl }) => {
  const refVideoEl = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedComparison, setSelectedComparison] = useState(COMPARISONS[0]);

  const handleContainerClick = () => {
    if (isPlaying) {
      setIsPlaying(false);
      refVideoEl.current.pause();
    } else {
      setIsPlaying(true);
      refVideoEl.current.play();
    }
  };

  const renderMeaning = () => {
    const vdoUrl = 'https://static.lenskart.com/media/owndays/img/progressive-cp/video.mp4';

    return (
      <div className="od-body-owndays-progressive-cp__container__meaning-box">
        <div className="bg-light-blue" />
        <div className="od-body-owndays-progressive-cp__container__meaning od-body-owndays-progressive-cp__container__meaning--text">
          <div className="meaning">
            <h2 className="meaning__title">
              {`WHAT ARE `} <br />
              PROGRESSIVE LENSES
            </h2>
            <p className="meaning__desc">
              Progressive lenses are multifocal lenses designed to correct your vision at all
              viewing distances—far, intermediate and near. The lenses are characterised by a
              gradual change of lens power from top to bottom, without the presence of a boundary
              line between viewing zones. They are commonly prescribed for myopic individuals who
              develop presbyopia around the age of 40.
            </p>
          </div>
        </div>

        <div className="od-body-owndays-progressive-cp__container__meaning od-body-owndays-progressive-cp__container__meaning--vdo">
          <div
            className={`${isPlaying ? 'meaning__playing' : 'meaning__vdo-container'}`}
            onClick={handleContainerClick}
          >
            {!isPlaying && <div className="meaning__vdo-cover"></div>}
            <video
              ref={refVideoEl}
              loop
              playsInline
              className="meaning__vdo"
              muted={false}
              poster={`${baseImgUrl}/thumbnail.webp`}
            >
              <source src={vdoUrl} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="od-body-owndays-progressive-cp__container__meaning-presbyopia">
          <div className="meaning-presbyopia__desc-box">
            <h2 className="meaning-presbyopia__desc-title">
              {`UNDERSTANDING `} <br />
              PRESBYOPIA
            </h2>
            <span className="meaning-presbyopia__desc-detail">
              As we age, our eyes gradually lose their ability to see things clearly up close. This
              is due to the reduced elasticity in the eye muscles and the hardening of the
              crystalline lens that diminishes its ability to change shape. As a result, your vision
              becomes blurry at the regular reading distance, often requiring you to hold reading
              materials further away to focus. This natural ageing process, known as presbyopia,
              typically begins at the age of 40. Although presbyopia cannot be reversed, it can be
              effectively corrected using spectacles, contact lenses or through refractive surgery.
            </span>
          </div>

          <img
            alt="presbyopia"
            className="meaning-presbyopia__image"
            src={`${baseImgUrl}/mean-glasses.webp`}
            onError={e => onImageLoadError(e)}
          />
        </div>

        <div className="od-body-owndays-progressive-cp__container__book-online-btn">
          <a className="book-online-btn" href="/stores/sg" target="_self">
            <span>BOOK AN EYE TEST ONLINE</span>

            <img
              alt="book-an-eye-test-online"
              className=""
              src={`${baseImgUrl}/arrow-right-circle-icon.svg`}
              onError={e => onImageLoadError(e)}
            />
          </a>
        </div>
      </div>
    );
  };

  const renderBenefits = () => {
    const baseClass = 'od-body-owndays-progressive-cp__container__benefits';

    const BenefitItem = ({ alt, src, title, description }) => (
      <div className="benefits__item">
        <img alt={alt} src={src} onError={e => onImageLoadError(e)} />
        <h5>{title}</h5>
        <span>{description}</span>
      </div>
    );

    const benefits = [
      {
        alt: 'CONVENIENCE',
        src: `${baseImgUrl}/icon-benefits-1.svg`,
        title: 'CONVENIENCE',
        description:
          'You only need 1 pair of spectacles to see far, intermediate and reading zones',
      },
      {
        alt: 'COMFORT',
        src: `${baseImgUrl}/icon-benefits-2.svg`,
        title: 'COMFORT',
        description: 'There is no image-jump that may be experienced with bifocal lenses',
      },
      {
        alt: 'AESTHETICS',
        src: `${baseImgUrl}/icon-benefits-3.svg`,
        title: 'AESTHETICS',
        description:
          'Progressive lenses look just like single vision lenses without the presence of a visible boundary line',
      },
      {
        alt: 'EASY ADAPTATION',
        src: `${baseImgUrl}/icon-benefits-4.svg`,
        title: 'EASY ADAPTATION',
        description:
          'Modern lens technology has made it possible for you to get used to wearing progressive lenses quickly',
      },
    ];

    return (
      <div className={`${baseClass}-box`}>
        <div className="bg-dark-blue"></div>
        <div className={baseClass}>
          <h2 className="benefits__title">
            {`BENEFITS OF `}
            <br />
            PROGRESSIVE LENSES
          </h2>
          <div className={`${baseClass}-list`}>
            {benefits.map((benefit, index) => (
              <BenefitItem
                key={index}
                alt={benefit.alt}
                description={benefit.description}
                src={benefit.src}
                title={benefit.title}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPersons = () => {
    return (
      <div className="od-body-owndays-progressive-cp__container__persons">
        <h2 className="persons__title">
          {`WHO SHOULD WEAR `}
          <br />
          PROGRESSIVE LENSES
        </h2>
        <div className="od-body-owndays-progressive-cp__container__persons-list">
          <div className="persons__item">
            <img
              alt="40 years old"
              src={`${baseImgUrl}/icon-who-1.webp`}
              onError={e => onImageLoadError(e)}
            />
            <span>
              Individuals who are 40 years old and above and have difficulty seeing both far and
              near
            </span>
          </div>

          <div className="persons__item">
            <img
              alt="looking afar with their reading glasses being worn"
              src={`${baseImgUrl}/icon-who-2.webp`}
              onError={e => onImageLoadError(e)}
            />
            <span>
              Individuals who experience discomfort due to blurry vision when looking afar with
              their reading glasses being worn
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderTypes = () => {
    return (
      <div className="od-body-owndays-progressive-cp__container__types types">
        <h2>
          {`TYPES OF PROGRESSIVE LENSES `}
          <br />
          AVAILABLE AT OWNDAYS
        </h2>
        <span>
          Unlike conventional progressive lenses that utilise either a front surface design or a
          back surface design, all OWNDAYS progressive lenses feature a double-sided design that
          offers seamless transitions between the different viewing zones. This provides a more
          natural and comfortable visual experience. Besides, it offers a wider viewing field and
          reduces visual distortion when looking through the edges of the lens, making it easier for
          the wearer to get used to the lenses.
        </span>
      </div>
    );
  };

  const renderComparison = () => {
    const renderRankCircle = value => {
      const circles = [1, 2, 3, 4, 5];

      return (
        <div className="circle-box">
          {circles.map(circleValue => (
            <div
              key={circleValue}
              className={`circle ${value >= circleValue ? 'circle--selected' : ''}`}
            />
          ))}
        </div>
      );
    };

    return (
      <div className="od-body-owndays-progressive-cp__container__comparison-box">
        <div className="bg-dark-blue-comparison"></div>
        <div className="od-body-owndays-progressive-cp__container__comparison">
          <h5 className="comparison__title">
            {`COMPARISON BETWEEN THE TYPES OF `}
            <br />
            PROGRESSIVE LENSES AT OWNDAYS
          </h5>

          <nav className="comparison__nav">
            {COMPARISONS.map(comparison => (
              <div
                key={comparison.name}
                className={`comparison__nav-item ${
                  selectedComparison.name === comparison.name ? 'comparison__nav-item--active' : ''
                }`}
                onClick={() =>
                  setSelectedComparison({
                    name: comparison.name,
                    image: comparison.image,
                    distance: comparison.distance,
                    intermediate: comparison.intermediate,
                    near: comparison.near,
                    keyDifferences: comparison.keyDifferences,
                    price: comparison.price,
                    isShowRemark: comparison.isShowRemark,
                    remark: comparison.remark,
                  })
                }
              >
                <strong>{comparison.label}</strong>
              </div>
            ))}
          </nav>

          <div className="comparison__desc">
            <img
              alt="enhanced/prestige/prestige gold/prestige platinum lenses"
              className="comparison__desc__header-img"
              src={`${baseImgUrl}/${selectedComparison.image}`}
              onError={e => onImageLoadError(e)}
            />

            <div className="comparison__desc__detail">
              <div className="comparison__desc__detail__rank">
                <span>Distance Vision</span>
                {renderRankCircle(selectedComparison.distance)}
              </div>

              <div className="comparison__desc__detail__rank">
                <span>Intermediate Vision</span>
                {renderRankCircle(selectedComparison.intermediate)}
              </div>

              <div className="comparison__desc__detail__rank">
                <span>Near Vision</span>
                {renderRankCircle(selectedComparison.near)}
              </div>

              <div className="comparison__desc__detail__key-differences">
                <h3>KEY DIFFERENCES</h3>
                <ul>
                  {selectedComparison.keyDifferences.map(({ name }) => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              </div>

              <div className="comparison__desc__detail__price">
                <h3>PRICE</h3>
                <div
                  className={`comparison__desc__detail__price__value-box ${
                    selectedComparison.price.value === 0 &&
                    'comparison__desc__detail__price__value-box--zero'
                  } ${
                    selectedComparison.price.value === 600 &&
                    'comparison__desc__detail__price__value-box--600'
                  }`}
                >
                  {selectedComparison.price.isPlus && (
                    <span className="comparison__desc__detail__price__plus">+</span>
                  )}

                  <span
                    className={`comparison__desc__detail__price__value ${
                      selectedComparison.price.value === 0 &&
                      'comparison__desc__detail__price__value--zero'
                    }`}
                  >
                    {selectedComparison.price.value}
                  </span>

                  {selectedComparison.price.suffix && (
                    <span className="comparison__desc__detail__price__value--suffix">
                      {selectedComparison.price.suffix}
                    </span>
                  )}
                </div>
              </div>

              {selectedComparison.isShowRemark && (
                <span
                  className={`comparison__desc__detail__remark ${
                    selectedComparison.price.value === 600 &&
                    'comparison__desc__detail__remark--600'
                  }`}
                >
                  {selectedComparison.remark}
                </span>
              )}
            </div>

            <span className="comparison__desc__image-text">
              * Images for illustration purposes only
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderLensFeatures = () => {
    return (
      <div className="od-body-owndays-progressive-cp__container__lens-features lens-features">
        <h2>LENS FEATURES</h2>
        <div className="lens-features__list">
          <div className="lens-features__item">
            <img
              alt="High index aspheric lenses"
              className="lens-features__item-img"
              src={`${baseImgUrl}/feature-1.svg`}
              onError={e => onImageLoadError(e)}
            />
            <span className="lens-features__item-text">
              {`High index `}
              <br />
              aspheric lenses
            </span>
          </div>

          <div className="lens-features__item">
            <img
              alt="99% UV protection"
              className="lens-features__item-img"
              src={`${baseImgUrl}/feature-2.svg`}
              onError={e => onImageLoadError(e)}
            />
            <span className="lens-features__item-text">99% UV protection</span>
          </div>

          <div className="lens-features__item">
            <img
              alt="Hard multi-coat"
              className="lens-features__item-img"
              src={`${baseImgUrl}/feature-3.svg`}
              onError={e => onImageLoadError(e)}
            />
            <span className="lens-features__item-text">
              {`Hard `}
              <br />
              multi-coat
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="od-body-owndays-progressive-cp">
      {renderMeaning()}
      {renderBenefits()}
      {renderPersons()}
      {renderTypes()}
      {renderComparison()}
      {renderLensFeatures()}
    </div>
  );
};
