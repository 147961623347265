import React, { Fragment } from 'react';

import { CARDS, HOW_TO_SEND, HOW_TO_USE } from './constants';
import './BodyGiftTicket.scss';

export const BodyGiftTicket = ({ baseImgUrl }) => {
  const link = '/sg/en/gift-cards';

  const renderHowToSection = (title, steps) => (
    <div className="body-gift-ticket__how-to__container">
      <h3 className="body-gift-ticket__how-to__title">{title}</h3>
      <ul className="body-gift-ticket__how-to__list">
        {steps.map(({ imgAlt, imgSrc, heading, description }, index) => (
          <li key={index} className="body-gift-ticket__how-to__item">
            <img alt={imgAlt} src={`${baseImgUrl}/${imgSrc}`} />
            <h5>{heading}</h5>
            <p>{description}</p>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <Fragment>
      <section className="body-gift-ticket__concept__header">
        <h2 className="body-gift-ticket__concept__header__title">
          It's a great way to send a gift to your loved ones!
        </h2>
        <p className="body-gift-ticket__concept__header__desc">
          A digital gift card that can be used at both the physical stores and the online store.{' '}
          <br />
          You can choose between $50 and $1000 denominations, add a personalised message and send it
          to your friend via email or Facebook.
        </p>
        <a
          className="body-gift-ticket__concept__btn body-gift-ticket__concept__btn--gray"
          href={link}
        >
          Send an eGift now
        </a>
      </section>

      <section className="body-gift-ticket__concept__body-bg">
        <div className="body-gift-ticket__concept__body">
          <div className="body-gift-ticket__concept__body__img">
            <img alt="mobile" src={`${baseImgUrl}/mobile.webp`} />
          </div>

          <div>
            <p className="body-gift-ticket__concept__body__desc">
              All you need to do is to share the eGift URL with the recipient.
              <br />
              There is no need for the recipient to sign up as member to use the gift card at
              OWNDAYS retail stores.
            </p>
            <p className="body-gift-ticket__concept__body__remark">
              You must sign up an account to make a purchase at the online store.
            </p>
          </div>
        </div>
      </section>

      <section className="body-gift-ticket__concept__footer">
        <div>
          <div className="body-gift-ticket__concept__footer__container">
            <div className="body-gift-ticket__concept__footer__title">
              <h2>The perfect gift for any occasion</h2>
              <p>
                Select a design that fits the occasion and add a personalised <br />
                message.
              </p>
            </div>

            <ul className="body-gift-ticket__concept__footer__list">
              {CARDS.map((card, index) => (
                <li key={index}>
                  <img alt={card.alt} src={`${baseImgUrl}/${card.src}`} />
                </li>
              ))}
            </ul>
          </div>

          <div className="body-gift-ticket__concept__footer__btn">
            <a
              className="body-gift-ticket__concept__btn body-gift-ticket__concept__btn--white"
              href={link}
            >
              Send eGift
            </a>
          </div>
        </div>
      </section>

      <section className="body-gift-ticket__how-to">
        {renderHowToSection('How to send eGift', HOW_TO_SEND)}
        {renderHowToSection('How to use eGift', HOW_TO_USE)}
      </section>
    </Fragment>
  );
};
