import React from 'react';
import './CorporateInformationTable.scss';

import { connect } from 'react-redux';
import { v4 } from 'uuid';

const CorporateInformationTable = props => {
  const { dataLocale, executivesList } = props;

  const {
    COMPANY_NAME = 'Company Name',
    HEAD_OFFICE = 'Head Office',
    FUNDS = 'Funds',
    EXECUTIVES = 'Executives',
    TWITTER,
    FACEBOOK,
    BLOG = 'Blog',
  } = dataLocale;

  return (
    <div className="corporate-information-table">
      <div className="corporate-information-table__cell corporate-information-table__cell--title od-font-bold">
        {COMPANY_NAME}
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--info od-font-reg">
        OWNDAYS Inc.
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--title od-font-bold">
        {HEAD_OFFICE}
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--info od-font-reg">
        〒900-0015 沖縄県那覇市久茂地2-8-7 久茂地KMビル 3F
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--title od-font-bold">
        {FUNDS}
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--info od-font-reg">
        JPY 100,000,000
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--title od-font-bold">
        {EXECUTIVES}
      </div>
      <div className="corporate-information-table__cell corporate-information-table__cell--info od-font-reg">
        <ul className="corporate-information-table__list">
          {executivesList?.map(
            ({ executive, twitter, facebook, blog }, executiveIndex, executivesList) => (
              <li key={v4()} className="corporate-information-table__list-item od-font-reg">
                {executive}
                {(twitter || facebook || blog) && (
                  <>
                    <br />
                    <br />
                    <ul className="corporate-information-table__list">
                      {twitter && (
                        <li className="corporate-information-table__list-item corporate-information-table__list-item--link">
                          <a
                            className="corporate-information-table__link od-font-reg"
                            href={twitter}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {TWITTER}
                          </a>
                          <br />
                          <br />
                        </li>
                      )}
                      {facebook && (
                        <li className="corporate-information-table__list-item corporate-information-table__list-item--link">
                          <a
                            className="corporate-information-table__link od-font-reg"
                            href={facebook}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {FACEBOOK}
                          </a>
                          <br />
                          <br />
                        </li>
                      )}
                      {blog && (
                        <li className="corporate-information-table__list-item corporate-information-table__list-item--link">
                          <a
                            className="corporate-information-table__link od-font-reg"
                            href={blog}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {BLOG}
                          </a>
                        </li>
                      )}
                    </ul>
                  </>
                )}
                {executiveIndex !== executivesList.length - 1 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    dataLocale: state.locale,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateInformationTable);
