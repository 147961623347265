import React from 'react';
import { Link } from 'react-router-dom';
import CategoryMenuOD from './CategoryMenuOD/CategoryMenuOD';
import './GenderMenuOD.scss';
import SubMenuOD from './SubMenuOD/SubMenuOD';
import { v4 } from 'uuid';

// set dtm value parent and key
const onGenderHover = context => {
  context.stopPropagation();
  const siblingContext = context.currentTarget.parentNode.childNodes;
  siblingContext.forEach(element => {
    element.classList.remove('active');
  });
  context.currentTarget.classList.add('active');
};

const GenderMenuOD = props => {
  const { genderMenuData, updateNavValueDtm, parentKey, menuData, section, dataLocale } = props;
  const { SELECT_GENDER } = dataLocale;
  return (
    <div className="od-gender-menu">
      {genderMenuData.url && genderMenuData.url?.indexOf('lenskart.com') === -1 ? (
        <Link
          className="nav-head od-font-bold"
          style={{ visibility: 'hidden' }}
          to={genderMenuData.url}
          onClick={e => updateNavValueDtm(menuData, parentKey, null, null, e, section)}
        >
          {SELECT_GENDER}
        </Link>
      ) : (
        <a
          className="nav-head od-font-bold"
          href={genderMenuData.url}
          style={{ visibility: 'hidden' }}
          onClick={e => updateNavValueDtm(menuData, parentKey, null, null, e, section)}
        >
          {SELECT_GENDER}
        </a>
      )}
      {genderMenuData.submenu &&
        genderMenuData.submenu.map(genderMenu => {
          return (
            <div key={v4()} className="od-gender-menu-section menu-line-section">
              {Object.keys(genderMenu).map((key, count) => {
                return (
                  <div
                    key={key}
                    className={`od-gender ${count === 0 ? ' active' : ''}`}
                    onFocus={ev => onGenderHover(ev)}
                    onMouseOver={ev => onGenderHover(ev)}
                  >
                    <div className="od-gender__info">
                      <div className="od-gender__img">
                        <img
                          alt={key}
                          className="img lazy-load-image"
                          height="50"
                          src={genderMenu[key].img}
                          title={key}
                          width="50"
                        />
                      </div>
                      <span className="od-font-bold">{key}</span>
                      <i className="fa fa-angle-right"></i>
                    </div>
                    {genderMenu[key].category && (
                      <div className={`display-none od-gender__category od-gender-${key}`}>
                        <CategoryMenuOD
                          categoryMenuData={genderMenu[key]?.category}
                          dataLocale={dataLocale}
                          parentKey={parentKey}
                          section={section}
                          subSection={key}
                          updateNavValueDtm={updateNavValueDtm}
                        />
                      </div>
                    )}
                    {!genderMenu[key].category && genderMenu[key].submenu && (
                      <div
                        className={`display-none od-gender__category od-submenu od-gender-${key} ${
                          !genderMenu[key].category ? 'category-condition' : ''
                        }`}
                      >
                        <SubMenuOD
                          parentKey={parentKey}
                          section={section}
                          subSection={key}
                          submenu={genderMenu[key]?.category?.submenu}
                          updateNavValueDtm={updateNavValueDtm}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default GenderMenuOD;
