import React from 'react';
import MetaTagsManager from '../../CommonComponents/Helmet/MetaTagsManager';

const html = `<!DOCTYPE html>
<html lang="en-SG">

<head>
    
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3">
    <script src="https://www.owndays.com/web/js/line-liff-sdk.js?v=1.3" type="text/javascript"></script>

    
    <script>
        if (window.location.href.indexOf('jp.owndays.com') != -1) {
            window.location.href = window.location.href.replace('jp.owndays.com', 'www.owndays.com')
        }
    </script>

                            
    <!-- Rtoaster -->
        <!-- /Rtoaster -->
    <!-- CleverTap -->
        <!-- /CleverTap -->
    
    <meta charset="utf-8">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, minimal-ui">
        <meta name="format-detection" content="telephone=no">
    <link rel="apple-touch-icon" href="https://www.owndays.com/images/apple-touch-icon.png">
    <link rel="shortcut icon" type="image/x-icon" href="https://www.owndays.com/images/favicon.ico">
    <link rel="alternate" href="https://www.owndays.com/sg/en/" hreflang="en-SG">

<link rel="alternate" href="https://www.owndays.com/jp/ja/" hreflang="ja-JP">
<link rel="alternate" href="https://www.owndays.com/jp/en/" hreflang="en-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_cn/" hreflang="zh-Hans-JP">
<link rel="alternate" href="https://www.owndays.com/jp/zh_tw/" hreflang="zh-Hant-JP">
<link rel="alternate" href="https://www.owndays.com/tw/zh_tw/" hreflang="zh-TW">
<link rel="alternate" href="https://www.owndays.com/th/th/" hreflang="th-TH">
<link rel="alternate" href="https://www.owndays.com/th/ja/" hreflang="ja-TH">
<link rel="alternate" href="https://www.owndays.com/th/en/" hreflang="en-TH">
<link rel="alternate" href="https://www.owndays.com/th/zh_cn/" hreflang="zh-Hans-TH">
<link rel="alternate" href="https://www.owndays.com/kh/km/" hreflang="km-KH">
<link rel="alternate" href="https://www.owndays.com/ph/en/" hreflang="en-PH">
<link rel="alternate" href="https://www.owndays.com/au/en/" hreflang="en-AU">
<link rel="alternate" href="https://www.owndays.com/my/en/" hreflang="en-MY">
<link rel="alternate" href="https://www.owndays.com/vn/vi/" hreflang="vi-VN">
<link rel="alternate" href="https://www.owndays.com/vn/en/" hreflang="en-VN">
<link rel="alternate" href="https://www.owndays.com/id/in/" hreflang="in-ID">
<link rel="alternate" href="https://www.owndays.com/hk/zh_hk/" hreflang="zh-HK">

    <!-- CSRF Token -->
    <meta name="csrf-token" content="3l2mZSSvEL3gN135hvod784zzYjCtQfEszb9JnUv">
    <meta property="og:locale" content="en_SG" />
    <meta name="locale-country" content="sg" />
    <meta name="locale-language" content="en" />
<meta name="keywords" content="Kuromi,Baku,sanrio,Character,Sanrio Characters,Collaboration,glasses,character,OWNDAYS" />
<meta property="og:site_name" content="OWNDAYS ONLINE STORE - OPTICAL SHOP">
    <meta property="og:type" content="website">
        <meta property="og:url" content="https://www.owndays.com/sg/en/news/kuromi">
          <meta property="og:image" content="https://www.owndays.com/images/specials/products/kuromi/ogp_1200x630.jpg?1663602225">
        <meta property="fb:app_id" content="221140811388378">
        <meta name="twitter:card" content="summary_large_image">
    <link rel="preconnect" href="https://ka-f.fontawesome.com">
    <link rel="preconnect" href="https://use.typekit.net">
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <!-- Fonts -->
    <link rel="dns-prefetch" href="https://fonts.gstatic.com">
    <link rel="preload" href="https://www.owndays.com/fonts/owndays-icons.ttf?yotsy1" as="font">
    <link rel="preload" href="https://use.typekit.net/qdz8arm.css" as="style">
    <link rel="stylesheet" href="https://use.typekit.net/qdz8arm.css">
    <!-- Images -->
    <link rel="preload" as="image" href="https://www.owndays.com/images/svg/owndays.svg">
    <!-- Styles -->
    <link rel="preload" href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" as="style">
    <link href="https://www.owndays.com/web/css/app.css?id=469db1961b4079b04603?20191018" rel="stylesheet">
    <link rel="preload" href="/fonts/Corporate-Logo-Rounded/Corporate-Logo-Rounded.woff2" as="font" type="font/woff2" crossorigin>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.css" />
<link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet">
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Bayon&display=swap" rel="stylesheet">
<link rel="preload" href="https://www.owndays.com/web/css/specials-kuromi-full-ver.css" as="style">
<link href="https://www.owndays.com/web/css/specials-kuromi-full-ver.css" rel="stylesheet">
        <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <link rel="preload" href="https://www.owndays.com/web/css/sg/en.css" as="style">
        <link href="https://www.owndays.com/web/css/sg/en.css" rel="stylesheet">
            <!-- fontawesome 5.x -->
    <link rel="preload" href="https://kit.fontawesome.com/2f45761fc6.js" as="script">
    <script src="https://kit.fontawesome.com/2f45761fc6.js" crossorigin="anonymous"></script>
        <link rel="preload" as="script" href="https://www.owndays.com/web/js/apis.google-platform.js">
    <script src="https://www.owndays.com/web/js/apis.google-platform.js" type="text/javascript"></script>
    <link rel="preload" as="script" href="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2">
    <script src="https://www.owndays.com/web/js/google-pay-sdk.js?v=1.2" type="text/javascript" onload="window.google_pay_loaded = true"></script>
        <!-- If open with LINE show the title only name "OWNDAYS" -->
</head>

<!-- If open with LINE add class ".is-line-mini" to body tag -->

<body id="main-body" style="display: block" class="is-sale-time">

                <div id="app" data-nosnippet>


<!-- header - SP電話モーダル中身 -->
<modal class="l-header__modal" id="contact" style="display: none;">
    <p class="l-header__modal-title">Contact Us</p>
    <div class="contact-information"><a id="header_modal_contact" href="tel:6222-0588"><span class="icon-tel"></span>6222-0588</a></div>
    <p><span>Operating Hours</span> <span>10:00 - 19:00</span></p>
        <div class="c-btn c-btn--tertiary c-btn--tertiary-center u-mt__10">
        <a href="/sg/en/faq">FAQs</a>
    </div>
        <p class="c-attention"></p>
    </modal>

                <main class="l-content
        
        
        
        
        
        ">
            <div class="od-c-breadcrumb">
    <ul id="breadcrumb-list">
                <li>
                                                 
                                                    <a href="/sg/en/news">News</a>
                                                                                </li>
                <li>
                            Kuromi × OWNDAYS                    </li>
        
            </ul>
</div>



<section class="l-kuromi">
    <!--  -->
    <div class="l-kuromi__checkered-pattern">
        <!-- MAIN -->
        <section class="main l-kuromi__container">
            <div class="main__content" data-aos="fade-up">
                <h1><img src="https://www.owndays.com/images/specials/products/kuromi/kuromi-x-owndays.svg" alt="Kuromi × OWNDAYS"></h1>
                <figure data-aos="fade-up" data-aos-delay="100"><img src="https://www.owndays.com/images/specials/products/kuromi/main-image.svg" alt="Kuromi × OWNDAYS"></figure>
                <div class="main__release-date">
                                                            <p class="u-sp-only">In-Store and Online Release on</p>
                    <h3 class="u-sp-only">21 Oct 2022</h3>
                    <p class="u-pc-only">In-Store and Online Release on 21 Oct 2022</p>
                                        <!-- <p>店頭 / オンラインストア同時販売</p> -->
                </div>
                <img class="main__hashtag" src="https://www.owndays.com/images/specials/products/kuromi/main-hashtag.svg" alt="#KUROMItheWorld">
            </div>
            <div class="u-text-center" data-aos="fade-up">
                <a class="main__scroll-button" href="#intro">Scroll</a>
            </div>
        </section>
        <!-- INTRO -->
        <section id="intro" class="intro">
            <div class="l-kuromi__container">
                <div class="intro__content-1" data-aos="fade-up">
                    <div class="intro__content-1-inner">
                        <img class="intro__content-1-top lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/wire-notebook.svg" alt="">
                        <img class="intro__content-1-title lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/sg/intro-1-title.svg" alt="Let’s #KUROMIfy girls who wear spectacles!">
                        <p>An OWNDAYS x Kuromi crossover in support of the <img class="intro__content-1-highlight-text lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/sg/intro-1-highlight-text.svg" alt=""> project. Transform into the best self you can ever be with these cute, accessory-like eyewear that are inspired by Kuromi and Baku!</p>
                        <img class="intro__content-1-character lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/intro-1-character.svg" alt="Kuromi & Baku" data-aos="fade-left" data-aos-delay="200">
                    </div>
                </div>
                <div class="intro__content-2" data-aos="fade-up">
                    <div class="intro__content-2-inner item-corner-top">
                        <img class="intro__content-2-img lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/sg/intro-2-img.svg" alt="What is #KUROMIfy the World?">
                        <div class="intro__content-2-detail">
                            <h2 class="intro__content-2-title">What is #KUROMIfy the World?</h2>
                            <p>A project to fill the world with KUROMIES – anyone who supports Kuromi and wants to be their best self like Kuromi. “Come on, don’t you want to be your best self too?” Let’s spread Kuromi’s message and fill the world with KUROMIES!</p>
                            <picture>
                                <source media="(min-width:1024px)" data-srcset="https://www.owndays.com/images/specials/products/kuromi/intro-2-character-pc.svg">
                                <img class="intro__content-2-character lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/intro-2-character.svg" alt="KUROMIES">
                            </picture>
                        </div>
                        <div class="item-corner-bottom"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- LINEUP -->
    <section class="lineup">
        <h2 class="l-kuromi__title">LINEUP</h2>
        <!-- PINK -->
        <div class="lineup__content lineup--pink">
            <div class="lineup__top l-kuromi__container">
                                <h3 class="lineup__top-name" data-aos="fade-right" data-aos-delay="200">
                    <span>
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/kuromi-name.svg" alt="<span>クロミ</span><small>モデル</small>">
                    </span>
                    <small>Model</small>
                </h3>
                                <picture class="lineup__top-character" data-aos="fade-left">
                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/kuromi/kuromi-pc.svg">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/kuromi-sp.svg" alt="Kuromi">
                </picture>
                <p class="lineup__top-detail" data-aos="fade-right" data-aos-delay="200">She looks punk but is actually quite girly!?<br>Spectacle frames modelled after Kuromi, with motifs that draw reference from characteristics such as her jester hat and black devil tail featured across the frame body.  </p>
            </div>
            <!-- 01 -->
            <div class="lineup__slider">
                <div class="lineup__slider-header l-kuromi__container" data-aos="fade-up">
                    <div>
                        <div class="lineup__slider-product-no">
                            <h3>#kuromi 01</h3>
                        </div>
                        <div class="lineup__slider-product-title">
                            <h4>Polygon</h4>
                        </div>
                        <p class="lineup__slider-product-code">SR1005B-2A</p>
                        <p class="lineup__slider-product-price">S$178</p>
                    </div>
                    <!-- 01 COLOR SELECT PC -->
                    <ul class="nav lineup__slider-color u-pc-only" role="tablist" data-title="COLOUR">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="pill" href="#product1-cl1" style="--cl:#000"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="pill" href="#product1-cl2" style="--cl:#e8c4e4"></a>
                        </li>
                    </ul>
                </div>

                <div class="lineup__slider-product tab-content" data-aos="fade-up">
                    <!-- COLOR 1 -->
                    <div class="tab-pane fade show active" id="product1-cl1">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_01.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_02.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_03.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_04.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_05.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_06.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_07.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C1_07.jpg" alt="SR1005B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- COLOR 2 -->
                    <div class="tab-pane fade" id="product1-cl2">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_01.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_02.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_03.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_04.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_05.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_06.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_07.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-C2_07.jpg" alt="SR1005B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 01 COLOR SELECT SP -->
                <div class="u-text-center u-sp-only">
                    <ul class="nav lineup__slider-color u-sp-only" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="pill" href="#product1-cl1" style="--cl:#000"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="pill" href="#product1-cl2" style="--cl:#e8c4e4"></a>
                        </li>
                    </ul>
                </div>
                <ul class="lineup__point l-kuromi__container">
                    <li data-aos="fade-up" data-aos-delay="100">
                        <div class="lineup__point-img-wrap">
                            <h4 class="lineup__point-title"><small>POINT</small> 01</h4>
                            <picture class="lineup__point-img">
                                <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-point-1.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-point-1.png" alt="SR1005B-2A POINT 01">
                            </picture>
                        </div>
                        <p>Metal frame in polygonal shape inspired by Kuromi’s well-defined eyes</p>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="200">
                        <div class="lineup__point-img-wrap">
                            <h4 class="lineup__point-title"><small>POINT</small> 02</h4>
                            <picture class="lineup__point-img">
                                <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-point-2.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-point-2.png" alt="SR1005B-2A POINT 02">
                            </picture>
                        </div>
                        <p>Intricate engravings in the shape of Kuromi’s ears featured around the rims</p>
                    </li>
                    <li data-aos="fade-up" data-aos-delay="300">
                        <div class="lineup__point-img-wrap">
                            <h4 class="lineup__point-title"><small>POINT</small> 03</h4>
                            <picture class="lineup__point-img">
                                <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-point-3.webp" type="image/webp">
                                <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1005B-2A-point-3.png" alt="SR1005B-2A POINT 03">
                            </picture>
                        </div>
                        <p>Temple tips designed to resemble Kuromi’s ears</p>
                    </li>
                </ul>
                                <div class="l-kuromi__container">
                    <a class="l-kuromi__button" href="/sg/en/products/SR1005B-2A"><span>VIEW ITEM</span></a>
                </div>
                            </div>

            <!-- 02 -->
            <div class="lineup__slider">
                <div class="lineup__slider-header l-kuromi__container" data-aos="fade-up">
                    <div>
                        <div class="lineup__slider-product-no">
                            <h3>#kuromi 02</h3>
                        </div>
                        <div class="lineup__slider-product-title">
                            <h4>Boston</h4>
                        </div>
                        <p class="lineup__slider-product-code">SR2001B-2A</p>
                        <p class="lineup__slider-product-price">S$178</p>
                    </div>
                    <!-- 02 COLOR SELECT PC -->
                    <ul class="nav lineup__slider-color u-pc-only" role="tablist" data-title="COLOUR">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="pill" href="#product2-cl1" style="--cl:#e3e3e3"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="pill" href="#product2-cl2" style="--cl:#e8c4e4"></a>
                        </li>
                    </ul>
                </div>

                <div class="lineup__slider-product tab-content" data-aos="fade-up">
                    <!-- 02 COLOR 1 -->
                    <div class="tab-pane fade show active" id="product2-cl1">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_01.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_02.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_03.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_04.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_05.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_06.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_07.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C1_07.jpg" alt="SR2001B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 02 COLOR 2 -->
                    <div class="tab-pane fade" id="product2-cl2">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_01.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_02.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_03.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_04.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_05.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_06.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_07.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-C2_07.jpg" alt="SR2001B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 02 COLOR SELECT SP -->
                    <div class="u-text-center u-sp-only">
                        <ul class="nav lineup__slider-color u-sp-only" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="pill" href="#product2-cl1" style="--cl:#e3e3e3"></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="pill" href="#product2-cl2" style="--cl:#e8c4e4"></a>
                            </li>
                        </ul>
                    </div>
                    <ul class="lineup__point l-kuromi__container">
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 01</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-point-1.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-point-1.png" alt="SR2001B-2A POINT 01">
                                </picture>
                            </div>
                            <p>Boston frame with motifs inspired by Kuromi’s tail added as accents</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="200">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 02</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-point-2.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-point-2.png" alt="SR2001B-2A POINT 02">
                                </picture>
                            </div>
                            <p>Kuromi and skull engravings featured on metal wire cores with a heart-shaped mark added on each side for a touch of cuteness</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="300">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 03</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-point-3.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2001B-2A-point-3.png" alt="SR2001B-2A POINT 03">
                                </picture>
                            </div>
                            <p>Temple tips designed to resemble Kuromi’s ears</p>
                        </li>
                    </ul>
                                        <div class="l-kuromi__container">
                        <a class="l-kuromi__button" href="/sg/en/products/SR2001B-2A"><span>VIEW ITEM</span></a>
                    </div>
                                    </div>
            </div>
        </div>
        <!-- PURPLE -->
        <div class="lineup__content lineup--purple">
            <div class="lineup__top l-kuromi__container">
                                <h3 class="lineup__top-name" data-aos="fade-right" data-aos-delay="200">
                    <span>
                        <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/baku-name.svg" alt="<span>バク</span><small>モデル</small>">
                    </span>
                    <small>Model</small>
                </h3>
                
                <picture class="lineup__top-character" data-aos="fade-left" data-aos-delay="200">
                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/kuromi/baku-pc.svg">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/baku-sp.svg" alt="Baku">
                </picture>
                <p class="lineup__top-detail" data-aos="fade-right" data-aos-delay="200">Spectacle frames inspired by Baku, Kuromi’s sidekick who is always by her side.<br>The frame design incorporates references to the striking shape of Baku’s eyes and ears as well as his favourite food, Takoyaki.</p>
            </div>
            <!-- 01 -->
            <div class="lineup__slider">
                <div class="lineup__slider-header l-kuromi__container" data-aos="fade-up">
                    <div>
                        <div class="lineup__slider-product-no">
                            <h3>#baku 01</h3>
                        </div>
                        <div class="lineup__slider-product-title">
                            <h4>POLYGON</h4>
                        </div>
                        <p class="lineup__slider-product-code">SR1006B-2A</p>
                        <p class="lineup__slider-product-price">S$178</p>
                    </div>
                    <!-- 01 COLOR SELECT PC -->
                    <ul class="nav lineup__slider-color u-pc-only" role="tablist" data-title="COLOUR">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="pill" href="#product3-cl1" style="--cl:#cdb4d8"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="pill" href="#product3-cl2" style="--cl:#e8c4e4"></a>
                        </li>
                    </ul>
                </div>

                <div class="lineup__slider-product tab-content" data-aos="fade-up">
                    <!-- 01 COLOR 1 -->
                    <div class="tab-pane fade show active" id="product3-cl1">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_01.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_02.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_03.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_04.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_05.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_06.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_07.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1_07.jpg" alt="SR1006B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 01 COLOR 2 -->
                    <div class="tab-pane fade" id="product3-cl2">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_01.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_02.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_03.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_04.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_05.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_06.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_07.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C2_07.jpg" alt="SR1006B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 01 COLOR SELECT SP -->
                    <div class="u-text-center u-sp-only">
                        <ul class="nav lineup__slider-color u-sp-only" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="pill" href="#product3-cl1" style="--cl:#cdb4d8"></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="pill" href="#product3-cl2" style="--cl:#e8c4e4"></a>
                            </li>
                        </ul>
                    </div>
                    <ul class="lineup__point l-kuromi__container">
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 01</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1-point-1.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1-point-1.png" alt="SR1006B-2A POINT 01">
                                </picture>
                            </div>
                            <p>Oversized polygonal metal frame that hints at Baku’s massive body size</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="200">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 02</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1-point-2.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1-point-2.png" alt="SR1006B-2A POINT 02">
                                </picture>
                            </div>
                            <p>Front of the frame is emblazoned on each side with a decorative feature in the shape of Baku’s eyes</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="300">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 03</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1-point-3.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR1006B-2A-C1-point-3.png" alt="SR1006B-2A POINT 03">
                                </picture>
                            </div>
                            <p>Temple tips shaped like Baku’s ears</p>
                        </li>
                    </ul>
                                        <div class="l-kuromi__container">
                        <a class="l-kuromi__button" href="/sg/en/products/SR1006B-2A"><span>VIEW ITEM</span></a>
                    </div>
                                    </div>
            </div>
            <!-- 02 -->
            <div class="lineup__slider">
                <div class="lineup__slider-header l-kuromi__container" data-aos="fade-up">
                    <div>
                        <div class="lineup__slider-product-no">
                            <h3>#baku 02</h3>
                        </div>
                        <div class="lineup__slider-product-title">
                            <h4>Wellington</h4>
                        </div>
                        <p class="lineup__slider-product-code">SR2002B-2A</p>
                        <p class="lineup__slider-product-price">S$158</p>
                    </div>
                    <!-- 02 COLOR SELECT PC -->
                    <ul class="nav lineup__slider-color u-pc-only" role="tablist" data-title="COLOUR">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="pill" href="#product4-cl1" style="--cl:#e3e3e3"></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="pill" href="#product4-cl2" style="--cl:#e8c4e4"></a>
                        </li>
                    </ul>
                </div>

                <div class="lineup__slider-product tab-content" data-aos="fade-up">
                    <!-- 02 COLOR 1 -->
                    <div class="tab-pane fade show active" id="product4-cl1">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_01.jpg" alt="SR2002B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_02.jpg" alt="SR2002B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_03.jpg" alt="SR2002B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_04.jpg" alt="SR2002B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_05.jpg" alt="SR2002B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1_06.jpg" alt="SR2002B-2A C1">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 02 COLOR 2 -->
                    <div class="tab-pane fade" id="product4-cl2">
                        <div class="swiper swiper-lineup">
                            <ul class="swiper-wrapper">
                                <li class="swiper-slide swiper-slide-active">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_01.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_01.jpg" alt="SR2002B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_02.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_02.jpg" alt="SR2002B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_03.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_03.jpg" alt="SR2002B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_04.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_04.jpg" alt="SR2002B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_05.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_05.jpg" alt="SR2002B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                                <li class="swiper-slide">
                                    <div class="lineup__slider-product-inner item-corner-top">
                                        <picture>
                                            <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_06.webp" type="image/webp">
                                            <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C2_06.jpg" alt="SR2002B-2A C2">
                                        </picture>
                                        <span class="item-corner-bottom"></span>
                                    </div>
                                </li>
                            </ul>
                            <div class="u-text-center u-pc-only">
                                <div class="lineup__slider-pagination"></div>
                            </div>
                        </div>
                    </div>
                    <!-- 02 COLOR SELECT SP -->
                    <div class="u-text-center u-sp-only">
                        <ul class="nav lineup__slider-color u-sp-only" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="pill" href="#product4-cl1" style="--cl:#e3e3e3"></a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="pill" href="#product4-cl2" style="--cl:#e8c4e4"></a>
                            </li>
                        </ul>
                    </div>
                    <ul class="lineup__point l-kuromi__container">
                        <li data-aos="fade-up" data-aos-delay="100">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 01</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1-point-1.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1-point-1.png" alt="SR2002B-2A POINT 01">
                                </picture>
                            </div>
                            <p>Wellington frame inspired by Baku’s large body and his two-toned body colour</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="200">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 02</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1-point-2.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1-point-2.png" alt="SR2002B-2A POINT 02">
                                </picture>
                            </div>
                            <p>A motif bearing inspiration from Baku’s favourite food, Takoyaki, featured on each temple</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="300">
                            <div class="lineup__point-img-wrap">
                                <h4 class="lineup__point-title"><small>POINT</small> 03</h4>
                                <picture class="lineup__point-img">
                                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1-point-3.webp" type="image/webp">
                                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/products/SR2002B-2A-C1-point-3.png" alt="SR2002B-2A POINT 03">
                                </picture>
                            </div>
                            <p>Temple tips shaped like Baku’s ears</p>
                        </li>
                    </ul>
                                        <div class="l-kuromi__container">
                        <a class="l-kuromi__button" href="/sg/en/products/SR2002B-2A"><span>VIEW ITEM</span></a>
                    </div>
                                    </div>
            </div>
        </div>
    </section>

    <!-- CASE & CLEANING CLOTH SET -->
    <section class="accessory">
        <div class="l-kuromi__container">
            <div class="accessory__inner item-corner-top" data-aos="fade-up">
                <img class="accessory__character lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/accessory-character.svg" alt="Kuromi & Baku" data-aos="fade-right" data-aos-delay="100">
                <h3 class="accessory__title">Exclusive<br>Merchandise Set</h3>
                <picture class="accessory__img">
                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/kuromi/accessory-img-pc.webp" type="image/webp">
                    <source media="(min-width:768px)" data-srcset="https://www.owndays.com/images/specials/products/kuromi/accessory-img-pc.jpg" type="image/jpeg">
                    <source media="(max-width:767px)" data-srcset="https://www.owndays.com/images/specials/products/kuromi/accessory-img-sp.webp" type="image/webp">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/accessory-img-sp.jpg" alt="Exclusive Merchandise Set">
                </picture>
                <p class="accessory__detail">Every Kuromi collaboration frame comes with an exclusive merchandise set consisting of a custom-designed spectacle case and a lens cleaning cloth.</p>
                <span class="item-corner-bottom"></span>
            </div>
        </div>
    </section>

    <!-- NOVELTY -->
    <section class="novelty">
        <h2 class="l-kuromi__title">Gift with<br>Purchase</h2>
        <div class="l-kuromi__container">
            <div class="novelty__inner item-corner-top" data-aos="fade-up">
                <small>Limited Quantities</small>
                <h3 class="novelty__title">Kuromi Stickers<small>with iridescent pouch</small></h3>
                <picture class="novelty__img">
                    <source data-srcset="https://www.owndays.com/images/specials/products/kuromi/novelty-img.webp" type="image/webp">
                    <img class="lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/novelty-img.jpg" alt="Kuromi Stickers">
                </picture>
                <p class="novelty__detail">
                    Receive a complimentary set of Kuromi stickers in an iridescent pouch when you purchase any Kuromi collaboration frame, while stocks last!                    <span class="novelty__note"><i>Limited to one redemption per transaction</i></span>
                </p>
                <img class="novelty__character lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/novelty-character.svg" alt="バク" data-aos="fade-left" data-aos-delay="100">
                <img class="novelty__goforit u-pc-only lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/novelty-go-for-it.svg" alt="Go For It!">
                <span class="item-corner-bottom"></span>
            </div>
                        <div class="l-kuromi__button-group">
                <a href="/sg/en/products?productLines[]=48" class="l-kuromi__button"><span>ALL ITEMS</span></a>
                <a href="/sg/en/shops?autoSearchCurrentLocation=true" class="l-kuromi__button"><span>SEARCH FOR NEARBY STORES</span></a>
            </div>
                    </div>
    </section>

    <!-- ABOUT -->
    <section class="about">
        <div class="l-kuromi__container">
            <div class="about__inner" data-aos="fade-up">
                <img class="about__character lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/about-character.svg" alt="Kuromi & Baku" data-aos="fade-left" data-aos-delay="200">
                <h3 class="about__title">Popular Sanrio Characters, Kuromi & Baku</h3>
                <p class="about__detail">Kuromi is a Sanrio character introduced in 2005. She is one of the top fan favourites, having secured 3rd place in the 2022 Sanrio Character Ranking. Kuromi is a self-proclaimed rival of My Melody. Her best features are her black jester hat and the pink skull.  Baku is Kuromi’s sidekick who flies around with Kuromi on his back. His favourite food is Takoyaki.</p>
                <a class="l-kuromi__button" href="https://www.sanrio.co.jp/characters/kuromi/" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer"><span>Official Website</span></a>
                <img class="about__el about__el--1 lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/about-el-1.svg" alt="">
                <img class="about__el about__el--2 lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/about-el-2.svg" alt="">
                <img class="about__el about__el--3 lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/about-el-2.svg" alt="">
                <img class="about__el about__el--4 lazyload" data-src="https://www.owndays.com/images/specials/products/kuromi/about-el-3.svg" alt="">
            </div>
        </div>
    </section>

    <!-- SHOPS -->
        <section class="shop">
        <h2 class="l-kuromi__title">Available<br>shop</h2>
        <div class="l-kuromi__container">
            <ul class="l-kuromi__shop-list c-list-decoration c-list-decoration--dot">
                <li><a href="/sg/en/shops/905">OWNDAYS Plaza Singapura</a></li>
                <li><a href="/sg/en/shops/907">OWNDAYS 313@somerset</a></li>
                <li><a href="/sg/en/shops/909">OWNDAYS nex</a></li>
                <li><a href="/sg/en/shops/920">OWNDAYS ION Orchard</a></li>
                <li><a href="/sg/en/shops/924">OWNDAYS Suntec City</a></li>
                <li><a href="/sg/en/shops/927">OWNDAYS White Sands</a></li>
                <li><a href="/sg/en/shops/928">OWNDAYS Jurong Point</a></li>
                <li><a href="/sg/en/shops/931">OWNDAYS Vivo City</a></li>
                <li><a href="/sg/en/shops/933">OWNDAYS Waterway Point</a></li>
                <li><a href="/sg/en/shops/948">OWNDAYS Compass One</a></li>
                <li><a href="/sg/en/shops/968">OWNDAYS Northpoint City</a></li>
                <li><a href="/sg/en/shops/1005">OWNDAYS Causeway Point</a></li>
            </ul>
        </div>
    </section>
    
    <!--  -->

    <!-- SHARE -->
    <section class="share">
        <h3 class="l-kuromi__title">SHARE</h3>
        <ul class="share__social">
            <li class="share__social-item share__social-item--fb">
                <a href="https://www.facebook.com/sharer/sharer.php?u=https://www.owndays.com/sg/en/news/kuromi" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">facebook</a>
            </li>
            <li class="share__social-item share__social-item--tw">
                <a href="https://twitter.com/intent/tweet?url=https://www.owndays.com/sg/en/news/kuromi" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">twitter</a>
            </li>
            <li class="share__social-item share__social-item--line">
                <a href="https://social-plugins.line.me/lineit/share?url=https://www.owndays.com/sg/en/news/kuromi" target="_blank" rel="noopener noreferrer" rel="noopener noreferrer">line</a>
            </li>
        </ul>
        <p class="share__copyright">©2022 SANRIO CO., LTD. APPROVAL<br class="u-sp-only">NO. L633449</p>
    </section>
</section>
        </main>
            </div>


    
    <!-- /top-return -->
<div id="top-btn" class="c-top-btn">
    <img class="lazyload" data-src="https://www.owndays.com/images/svg/arrow-top.svg" alt="TOP">
    <p>TOP</p>
</div>


<!-- Modal / Country & Language -->
<div class="modal fade l-modal__country-lang" tabindex="-1" id="modalCountryLang">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="lazyload" data-src="https://www.owndays.com/images/icons/close-1.png" alt="Close">
                </button>
                <p class="modal-title">Choose your country</p>
                <div id="accordionCountryLang" class="modal-accordion accordion">
                    <!-- For Mobile -->
                    <ul class="modal-list u-sp-only">
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                            </ul>

                    <!-- For Desktop -->
                     <ul class="modal-list u-pc-only">
                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryjp" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/jp.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/jp.png" alt="jp">
                                </picture>
                                日本
                            </button>
                            <ul class="modal-list-lang collapse" id="countryjp" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/jp/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/jp/zh_tw" class="">
                                        中文 (繁體)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/tw/zh_tw" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/tw.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/tw.png" alt="tw">
                                </picture>
                                台灣
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/kh/km" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/kh.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/kh.png" alt="kh">
                                </picture>
                                ព្រះរាជាណាចក្រ
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/au/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/au.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/au.png" alt="au">
                                </picture>
                                AUSTRALIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryvn" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/vn.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/vn.png" alt="vn">
                                </picture>
                                Việt Nam
                            </button>
                            <ul class="modal-list-lang collapse" id="countryvn" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/vn/vi" class="">
                                        Tiếng Việt
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/vn/en" class="">
                                        English
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/hk/zh_hk" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/hk.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/hk.png" alt="hk">
                                </picture>
                                香港
                            </a>
                                                    </li>
                                                </ul>
                         <ul class="modal-list u-pc-only">
                                                                                                                                                                                                <li class="modal-list-item">
                                                        <button type="button" data-target="#countrysg" class="modal-list-country active" data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/sg.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/sg.png" alt="sg">
                                </picture>
                                SINGAPORE
                            </button>
                            <ul class="modal-list-lang collapse" id="countrysg" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/sg/en" class="active">
                                        English
                                    </a>
                                </li>
                                                                
                                                                
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <button type="button" data-target="#countryth" class="modal-list-country " data-toggle="collapse" aria-expanded="false">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/th.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/th.png" alt="th">
                                </picture>
                                ไทย
                            </button>
                            <ul class="modal-list-lang collapse" id="countryth" data-parent="#accordionCountryLang">
                                                                <li>
                                    <a href="https://www.owndays.com/th/th" class="">
                                        ภาษาไทย
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/en" class="">
                                        English
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/ja" class="">
                                        日本語
                                    </a>
                                </li>
                                                                <li>
                                    <a href="https://www.owndays.com/th/zh_cn" class="">
                                        中文 (简体)
                                    </a>
                                </li>
                                                            </ul>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/ph/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/ph.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/ph.png" alt="ph">
                                </picture>
                                PHILIPPINES
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/my/en" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/my.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/my.png" alt="my">
                                </picture>
                                MALAYSIA
                            </a>
                                                    </li>
                                                                                                                                                                        <li class="modal-list-item">
                                                        <a href="https://www.owndays.com/id/in" class="modal-list-country ">
                                <picture>
                                    <source srcset="https://www.owndays.com/images/flags/id.webp" type="image/webp">
                                    <img class="lazyload" data-sizes="auto" data-src="https://www.owndays.com/images/flags/id.png" alt="id">
                                </picture>
                                INDONESIA
                            </a>
                                                    </li>
                                                                                                                        </ul>
                                        </div>
            </div>
        </div>
    </div>
</div>

    <!-- Scripts -->
    <script src="https://polyfill.io/v3/polyfill.min.js?features=Object.values%2CObject.entries%2CArray.prototype.find%2CString.prototype.includes%2CArray.prototype.includes%2CArray.prototype.flatMap"></script>
    <script src="https://www.owndays.com/web/js/active-nav-menu.js?v=1.1"></script>
    <script src="https://www.owndays.com/web/js/lazysizes.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        <script>
        lazySizes.init();
        const baseUrl = "https://www.owndays.com"

        //IF IN LIFF CLIENT CANNOT ACCESS WEB NAMESPACE

        if (!Object.entries) {
            Object.entries = function(obj) {
                var ownProps = Object.keys(obj),
                    i = ownProps.length,
                    resArray = new Array(i); // preallocate the Array
                while (i--)
                    resArray[i] = [ownProps[i], obj[ownProps[i]]];

                return resArray;
            };
        }
        if (!Array.prototype.find) {
            Object.defineProperty(Array.prototype, 'find', {
                value: function(predicate) {
                    // 1. Let O be ? ToObject(this value).
                    if (this == null) {
                        throw new TypeError('"this" is null or not defined');
                    }

                    var o = Object(this);

                    // 2. Let len be ? ToLength(? Get(O, "length")).
                    var len = o.length >>> 0;

                    // 3. If IsCallable(predicate) is false, throw a TypeError exception.
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }

                    // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    var thisArg = arguments[1];

                    // 5. Let k be 0.
                    var k = 0;

                    // 6. Repeat, while k < len
                    while (k < len) {
                        // a. Let Pk be ! ToString(k).
                        // b. Let kValue be ? Get(O, Pk).
                        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                        // d. If testResult is true, return kValue.
                        var kValue = o[k];
                        if (predicate.call(thisArg, kValue, k, o)) {
                            return kValue;
                        }
                        // e. Increase k by 1.
                        k++;
                    }

                    // 7. Return undefined.
                    return undefined;
                },
                configurable: true,
                writable: true
            });
        }

        window.site_url = ''
    </script>
        <script src="https://www.owndays.com/web/js/jquery.easing.1.3.js" defer></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3.2/jquery.easing.min.js" integrity="sha512-qJw3EXcEMHpFae2o6eQmiDc1TACjjT7xMGnMcsmDFB55XiL/6SdZEsRv08wRR+OLJ7sijqHksLHDAlbB94glxg==" crossorigin="anonymous"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.2/picturefill.min.js" integrity="sha512-Nh/FthCqOFq56kGp1DsNb6GKK29iIv2ZJc7Fylln1WRrWpeE5LjSBWeAp61dkBASDxZT12aL9mZyIrhsAqgRpw==" crossorigin="anonymous"></script>
    <script src="https://www.owndays.com/web/js/app.js?id=05db6b893b895cfb2435?20230222"></script>
    
    <script src="https://www.owndays.com/web/js/helper.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery.sticky/1.0.4/jquery.sticky.min.js" integrity="sha512-QABeEm/oYtKZVyaO8mQQjePTPplrV8qoT7PrwHDJCBLqZl5UmuPi3APEcWwtTNOiH24psax69XPQtEo5dAkGcA==" crossorigin="anonymous"></script>
    <script src="https://cdn.xapping.com/libs.js"></script>
    <script>
        var uri = window.location.toString();
        if (uri.indexOf("#") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("#_=_"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        // Header Sticky
        // $("header.l-header").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999
        // });

        // Header Sticky
        // $(".c-information__sale-time").sticky({
        //     topSpacing: 0,
        //     zIndex: 9999,
        //     wrapperClassName: 'sticky-wrapper--sale-time'
        // });

        $('#main-body').show();

        function openMenu(evt, menuName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tab__content");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tab__link");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(menuName).style.display = "flex";
            evt.currentTarget.className += " active";
        }

        function supportWebP() {
            var rv = $.Deferred(),
                img = new Image();
            img.onload = function() {
                rv.resolve();
            };
            img.onerror = function() {
                rv.reject();
            };
            img.src = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAIAAkA4JaQAA3AA/vshgAA=";
            return rv.promise();
        }

        supportWebP().then(function() {
            $('body').addClass('webp');
        }, function() {
            $('body').addClass('no-webp');
        });

        $('.l-header__navbar__inner.u-pc-only, #breadcrumb-list').one('mouseover', function() {
            $('#menu_products_glasses_toggle img').each(function () {
                lazySizes.loader.unveil($(this)[0])
            });
        });
    </script>
        <script>
        // handle suffix breadcrumb in profile page
        appendBreadcrumb( $('.is-active'))

        $('.profile-nav').on('click', function(){
            appendBreadcrumb(this)
        }) 
        function appendBreadcrumb(element) {
            var breadcrumb = $(element).data('breadcrumb')
            $('#suffix-breadcrumb').remove();
            if (breadcrumb) {
                $('#breadcrumb-list').append("<li id='suffix-breadcrumb'>"+ breadcrumb +"</li>")
            }
        }
    </script>
<script src="https://cdn.jsdelivr.net/npm/swiper@8/swiper-bundle.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/smooth-scroll/16.1.3/smooth-scroll.min.js"></script>
<script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>
<link rel="preload" href="https://www.owndays.com/web/js/specials-kuromi.js" as="script">
<script src="https://www.owndays.com/web/js/specials-kuromi.js"></script>
 
</body>

</html>`;

const KuromiCollectionHTML = () => {
  return (
    <>
      <MetaTagsManager
        canonical="/news/kuromi"
        description="Explore Kuromi x OWNDAYS stylish and cute eyewear collection, inspired by Kuromi and Baku. Find unique and expressive spectacle frames to express your style!"
        title="Kuromi x OWNDAYS | OWNDAYS Singapore Online Store"
      />
      <div dangerouslySetInnerHTML={{ __html: html }} id="external-pages" />
    </>
  );
};

export default KuromiCollectionHTML;
